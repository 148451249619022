@import '../../../styles/variables.scss';

.ModalHeader {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .modal-close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background: color(light, secondaryBg);
    border-radius: 22px;
    transition: all 0.2s;
    margin-right: 40px;
    margin-top: 20px;

    &:hover {
      cursor: pointer;
      background: color(grey, pale);
    }
  }

  .header-container {
    display: flex;
    flex-flow: column nowrap;
    min-height: 80px;
    justify-content: center;
    padding: 20px 40px 0px;
    flex: 1;

    .header-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
    }

    .header-description {
      padding: 20px 0px 0px;
      text-align: center;
      color: color(grey, base);
      letter-spacing: 0px;
      font-weight: 300;
      margin: 0;
      box-sizing: border-box;
      max-width: 350px;
      line-height: 24px;
    }
  }

  .close-button {
    display: none;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 18px;
    font-size: 30px;
    cursor: pointer;
    z-index: 99;
  }

  .back-button {
    display: none;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 18px;
    font-size: 30px;
    cursor: pointer;
  }

  .back-button.right,
  .close-button.right {
    right: 10px;
  }

  .back-button.left,
  .close-button.left {
    left: 10px;
  }
}

@media (max-width: 680px) {
  .ModalHeader {
    .header-container {
    }

    .header-title {
      font-size: 20px;
    }

    .close-button {
      display: initial;
    }

    .back-button {
      display: initial;
    }
  }
}
