@import '../../styles/variables.scss';

.Individual {
  flex: 1;
  padding-bottom: 60px;
  position: relative;
  color: color(light, fontColor);
  background-color: color(light, primaryBg);

  .BackSplash {
    position: relative;
    overflow: hidden;
  }

  &.content-creator {
    .sub-header {
      position: absolute;
      top: 50px;
      left: 0px;
      height: 90px;
      width: 100%;
      z-index: 3;
      transition: background-color 100ms ease-in-out;

      &.fixed {
        position: fixed;
        top: 80px;
        background-color: #fff;
        box-shadow: $box-shadow;

        .sub-header-inner {
          box-shadow: none;
        }
      }

      .sub-header-inner {
        max-width: $wrap-size;
        margin: 0px auto;
        height: 100%;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        box-shadow: $box-shadow;

        .row {
          flex: 1;
          display: flex;

          &.left {
            padding-left: 40px;

            .streamer-details {
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-left: 10px;

              .user-type {
                font-size: 13px;
                font-weight: 700;
                letter-spacing: 0.5px;
                display: flex;
                align-items: center;

                i {
                  margin-right: 4px;
                  font-size: 10px;
                  color: #88949a;
                }
              }

              .name {
                padding: 0px;
                margin: 0px;
                font-size: 24px;
              }
            }
          }

          &.right {
            padding-right: 40px;
            justify-content: flex-end;

            .text-buttons {
              min-width: 200px;
              display: flex;
              margin-left: 10px;
              align-items: center;

              .Button {
                flex: 1;
                display: flex;

                button {
                  display: flex;
                  flex: 1;
                  box-shadow: $box-shadow;
                  min-width: 140px;
                }
              }

              a {
                font-weight: 500;
                text-decoration: none;
                display: flex;
                border: 0px;
                font-size: 16px;
                border-radius: 20px;
                justify-content: center;
                align-items: center;
                color: color(dark, fontColor);
                background-color: color(purple);
                overflow: hidden;
                min-height: 40px;
                box-shadow: $box-shadow;
                min-width: 140px;
                background-color: color(purple, dark);

                &.manage {
                  background-color: color(purple, dark);
                  margin-left: 10px;
                }

                &:hover {
                  background-color: color(purple);
                }
              }
            }

            .Button {
              &.suspend {
                margin-right: 10px;
              }
              &.share {
                button {
                  min-width: 40px;
                  height: 40px;
                  width: 40px;
                  border-radius: 50%;
                  padding: 0px;
                  margin: 0px;
                  background-color: #eeeff0;
                  color: #52646c;
                  justify-content: center;
                  align-items: center;

                  .icon {
                    width: 14px;
                    height: 16px;
                    margin-right: 0px;
                  }

                  &:hover {
                    background-color: color(purple, dark);
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }

    .suspended-stream {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 100px 50px;
      .suspend-heading {
        font-size: 37px;
        color: #fff;
        mix-blend-mode: difference;
        margin-bottom: 10px;
      }
      .suspend-sub-heading {
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        mix-blend-mode: difference;
        margin-bottom: 25px;
      }
      .suspend-link {
        background-color: color(purple, dark);
        color: #fff;
        text-decoration: none;
        padding: 8px 28px;
        border-radius: 20px;
      }
    }

    .stream-embed {
      position: relative;
      margin: 80px auto;
      max-width: 1200px;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      margin-top: -360px;
      margin-bottom: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      overflow: hidden;
      box-shadow: $box-shadow;
    }
  }
}

@media (max-width: 1240px) {
  .Individual {
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0px;
    border-bottom: 1px solid color(grey, lighter);

    .BackSplash {
      height: 250px;

      img {
        filter: blur(0px);
      }
    }

    .IndividualDefault {
      pointer-events: none;
    }

    .Individual-content {
      flex-direction: column;
      padding: 0px 20px;
      margin-top: -300px;
      padding-bottom: 450px;

      .sidebar {
        width: auto;
        margin-right: 0px;

        .sidebar-inner {
          background-color: transparent;
          max-width: 500px;
          box-shadow: none;
          margin: 90px auto 0px;
          padding: 0px;
          pointer-events: all;

          .sidebar-content {
            .sidebar-info {
              text-align: center;

              .name {
                i {
                  display: none;
                }
              }
            }

            .sidebar-actions {
              display: flex;
              flex: 1;

              a,
              .Button {
                flex: 1;
                margin-right: 10px;
                height: 44px;

                button {
                  margin-top: 0px;
                }
              }
            }
          }
        }
      }

      .main {
        pointer-events: all;

        .CoverImage {
          display: none;
        }

        .Individual-main-content {
          border-radius: 0px;
          margin-top: 0px;
        }
      }
    }
  }
}

@media (max-width: 825px) {
  .text-buttons {
    .manage {
      display: none;
    }
  }
}

@media (max-width: 540px) {
  .Individual {
    .Individual-content {
      .main {
        .Individual-main-content {
          .inner-content {
            padding: 10px;
          }

          .members .members-wrap,
          .donations .donations-wrap {
            .member,
            .donation {
              width: auto;
              flex: 1;
              border-right: 0px !important;
              border-left: 0px !important;
            }
          }

          .team-info {
            flex-direction: column;
            width: auto;

            .sect {
              width: auto;
              border-right: 0px;
              justify-content: center;
              margin-bottom: 20px;

              &:last-child {
                margin-bottom: 0px;
              }

              .team-content {
                width: 100px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .Individual {
    .Individual-content {
      .sidebar {
        .sidebar-inner {
          .sidebar-content {
            .sidebar-actions {
              flex-direction: column;

              a,
              .Button {
                margin-left: 0px;
                margin-right: 0px;
              }

              a {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}
