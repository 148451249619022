@import '../../../styles/variables.scss';

.DefaultLink {
  font-size: 16px;
  text-decoration: none;

  &.grey {
    color: color(grey, text);
  }

  &.with-underline {
    text-decoration: underline;
  }
}
