@import '../../styles/variables.scss';

.AvailabilitySettings {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .mobile-container {
    display: none;
  }
  .section-inner {
    flex-direction: column;
  }

  @mixin tbth {
    height: 50px;
    width: 180px;
    border: 1px solid color(light, tertiaryBg);
  }

  .table-container {
    table {
      border-collapse: collapse;
      width: 100%;

      tbody {
        text-align: center;

        th {
          &.time-th {
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;

            span {
              color: color(grey, text);
              font-size: 12px;
            }
          }

          &.empty {
            background-color: unset;
            border: unset;
          }

          background-color: color(light, primaryBg);
          font-weight: 700;
          text-transform: capitalize;
          @include tbth;
        }

        td {
          background-color: color(light, secondaryBg);
          @include tbth;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .AvailabilitySettings {
    .table-container {
      display: none;
    }
    .section-title {
      display: none;
    }

    .mobile-container {
      display: grid;
      grid-template-columns: 1fr;
      color: color(light, fontColor);
      .mini-title {
        height: 55px;
        line-height: 55px;
        font-size: 17px;
        font-weight: 700;
        background-color: color(light, primaryBg);
        padding-left: 10px;
      }
      .day {
        height: 77px;
        border-top: 1px solid color(light, tertiaryBg);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        .i-available {
          color: color(green, soft);
        }
        .i-not-available {
          color: color(red, soft);
        }
        .select {
          color: color(blue, soft);
        }
        .not-available {
          color: color(blue, lighter);
        }
        i {
          margin-right: 12px;
        }
      }
      .save {
        color: color(light, secondaryBg);
        background: color(purple, dark);
        border-radius: 100px;
        text-align: center;
        height: 50px;
        margin: 50px 15px;
        button {
          width: 100%;
        }
      }
    }

    .selected-day-container {
      display: flex;
      flex-direction: column;
      .desc {
        padding-left: 20px;
        font-size: 16px;
        font-weight: 500;
      }
      .btn-options-container {
        margin: 25px 0 30px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        button {
          border: 1px solid color(grey, base2);
          color: color(grey, base2);
          background-color: color(light, secondaryBg);
          height: 50px;
          width: 120px;
          font-size: 16px;
          font-weight: 500;
          border-radius: 3px;
          &.active {
            color: color(purple, dark);
            border: 1px solid color(purple, dark);
          }
        }
      }
      .not-available {
        margin: 0 20px;
        height: 50px;
        button {
          border: 1px solid color(grey, base2);
          color: color(grey, base2);
          border-radius: 3px;
          background-color: color(light, secondaryBg);
          width: 100%;
          max-width: unset;
          &.active {
            color: color(purple, dark);
            border: 1px solid color(purple, dark);
          }
        }
      }

      .confirm {
        margin-top: 50px;
        margin-bottom: 28px;
      }

      .confirm,
      .cancel {
        border-radius: 100px;
        text-align: center;
        height: 50px;
        margin-left: 15px;
        margin-right: 15px;

        button {
          width: 100%;
          max-width: unset;
        }
      }
    }
  }
}
