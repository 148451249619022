@import '../../../../styles/variables.scss';

.CheckboxCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 25px;
  position: relative;
  background-color: #fff;
  border: 1px solid #b0b0b0;
  border-radius: 12px;
  min-width: 150px;
  min-height: 80px;
  height: 85px;
  max-height: 85px;
  margin: 10px;
  flex: 1;

  &.with-image {
    padding-left: 85px;
  }

  &::after {
    opacity: 0;
    left: 0;
    top: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: color(grey, pale);
    border: 1px solid #898989;

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 1;
      border-radius: 12px;
      transition: opacity 0.3s ease-in-out;
    }
  }

  &:active {
    &:hover {
      box-shadow: none;
    }
  }

  &.advanced {
    max-height: unset;
    min-height: unset;
    height: auto;
    flex: 0 1;
  }

  &.selected {
    border: 1px solid color(purple, dark);

    &::after {
      content: '';
      background: transparent;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 13px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.18);
      animation: scaleIn 100ms ease-in-out forwards;
      transition: opacity 100ms ease-in;
    }
  }

  .title {
    font-family: $raleway;
    color: color(grey, dark);
    font-size: 16px; //1.2rem;
    font-weight: 700;
    line-height: 19px;
    padding-right: 8px;
    .secondary-title {
      margin-left: 10px;
      color: #898989;
    }
  }

  .subtext {
    font-family: $lato;
    margin-top: 5px;
    opacity: 0;
    color: #898989;
    animation: slide-in 200ms ease-in-out forwards;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  .advanced-list {
    padding-left: 0;
    color: #616161;
    font-size: 12px;
    margin: 10px 0;
    .advanced-list-item {
      list-style-type: none;
      margin-bottom: 10px;
      i {
        margin-right: 5px;
      }
    }
  }

  .Checkbox {
    position: absolute;
    right: 6px;
    top: 13px;

    label {
      .fa-square {
        color: #ededec;
      }
      .fa-check-square,
      .fa-square {
        font-size: 26px;
        opacity: 1;
      }

      .fa-check-square {
        animation: wiggle 350ms cubic-bezier(0, -0.55, 0.27, 1.55) forwards;
        backface-visibility: hidden;
      }
    }
  }

  .portrait-container {
    position: absolute;
    left: 20px;
  }
}

@media (max-width: 1000px) {
  .CheckboxCard {
    margin: 5px;
  }
}

@media (max-width: 500px) {
  .CheckboxCard {
    max-height: 75px;
    height: 75px;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

//put animations in folder and export
@keyframes slide-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

// simple 'wiggle' animation for on checkbox click
@keyframes wiggle {
  0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(20deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
