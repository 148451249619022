@import '../../../../styles/variables.scss';

.PickPaymentMethod {
  position: fixed;
  top: 0px;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: auto;
  transition: left 150ms ease-in-out;
  z-index: 3;

  &.active {
    left: 0px;
  }

  .pm-wrap {
    .sub-title {
      padding: 0px 40px 30px;
    }

    .pm-inner {
      padding: 0px 40px;

      .alt-methods-container {
        img {
          max-width: 45px;
          height: auto;
        }

        svg {
          max-width: 45px;
          height: auto;
        }
      }

      .pm {
        display: flex;
        flex: 1;
        align-items: center;
        border-top: 1px solid #e8f1f2;
        padding: 0px 10px;
        height: 60px;

        i {
          &:first-child {
            font-size: 40px;
          }

          &.fa-cc-visa {
            color: #214dba;
          }

          &.fa-cc-mastercard {
            color: #343a47;
          }

          &.fa-cc-amex {
            color: #6ca0da;
          }

          &.fa-cc-discover {
            color: #ef7e23;
          }

          &.fa-cc-diners-club {
            color: #01084c;
          }

          &.fa-cc-jcb {
            color: #0867ad;
          }

          &.fa-credit-card {
            color: color(plum, dark);
          }

          &.fas {
            color: color(plum, dark);
          }
        }

        &:hover {
          cursor: pointer;
          background-color: #fbfbfb;
        }

        span {
          flex: 1;
          padding-left: 10px;
          font-size: 14px;
        }

        &.new {
          span {
            font-weight: 700;
            padding-left: 0px;
            font-size: 16px;
          }

          i {
            width: 16px;
          }
        }

        &:last-child {
          border-bottom: 1px solid #e8f1f2;
        }
      }
    }
  }

  .overlay-actions {
    padding: 0px 40px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;

    .Button {
      padding-top: 20px;
      margin: 0px 10px;

      button {
        min-width: 200px;
        box-shadow: $box-shadow;
      }

      &:last-child {
        margin-left: 40px;

        button {
          background-color: #fff;
          color: #52646c;
          border: 2px solid #52646c;

          &:hover {
            border: 2px solid color(plum, dark);
            color: color(plum, dark);
          }
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .PickPaymentMethod {
    .overlay-inner {
      padding-top: 30px;
    }
    .overlay-actions {
      flex-direction: column;
      .Button {
        &:last-child {
          margin-left: 10px;
        }
        width: 100%;
        button {
          min-width: unset;
          max-width: unset;
          width: 100%;
        }
      }
    }
  }
}
