@import '../../../../styles/variables.scss';

.PaymentMethodPreview {
  &.row-overlay {
    display: flex;
    flex: 1;
    border-top: 1px solid #e8f1f2;
    border-bottom: 1px solid #e8f1f2;
    padding: 20px 10px;

    .selected-alt-method-logo {
      width: 45px;
      height: auto;
      margin-right: 10px;
    }

    &:hover {
      cursor: pointer;
      background-color: #fbfbfb;
    }

    i {
      &.fa-angle-right {
        font-size: 24px;
        color: #b1b6bb;
      }
    }

    div {
      flex: 1;
      display: flex;
      align-items: center;

      span {
        font-weight: 700;

        &.optional {
          font-size: 14px;
          font-weight: 300;
          margin-left: 8px;
        }
      }

      i {
        margin-right: 8px;
      }

      .payment-label {
        margin-left: auto;
        margin-right: 15px;
        color: color(grey, font);
      }
    }

    .cc {
      font-weight: 400;
      display: flex;
      color: #52646c;
      font-size: 14px;
      padding: 0px 10px 0px 0px;
      justify-content: flex-end;
      flex: 1;
    }

    .fa-plus {
      height: 17px;
    }
  }
}
