@import '../../../../../styles/variables.scss';

.DateRangePickerFieldContainer {
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  gap: 10px;
}

.DateRangePickerDialogPortal {
  display: flex;
  background-color: white;
  border: #d0d1d1 1px solid;
  border-radius: 8px;
  box-shadow: $box-shadow;

  .date-picker-selected {
    background-color: #2f2956;
    color: white;
  }
}
