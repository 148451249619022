@import '../../styles/variables.scss';

@keyframes slideIn {
  0% {
    transform: translateX(275px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes menuSlideDown {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.Conversation {
  position: relative;
  width: 100%;
  height: 100%;
  background: color(light, secondaryBg);
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  transition-property: all;
  transition-duration: 5;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

  .conversation-top {
    color: color(light, fontColor);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    height: 42px;
    font-weight: 700;
    background-color: color(purple, lightest);
    justify-content: space-between;
    margin-bottom: 5px;
    z-index: 2;

    .new-conversation-prompt {
      padding: 10px;
      height: 100%;
      margin-top: auto 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      text-align: center;

      span {
        padding: 10px;
        border-radius: 8px;
        margin-top: 15px 0;
      }

      .stack {
        display: flex;
        flex-direction: row;
        margin-left: 10px;

        .Portrait {
          margin: 0px -30px;
          opacity: 0.9;

          &:hover {
            z-index: 2;
            transform: scale(1.1);
            opacity: 1;
          }
        }

        .user-info {
          display: none;
        }

        &:hover {
          .user-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
