@import '../../styles/variables.scss';

.Notification-Center {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  .inner {
    flex: 1 1;
    width: 700px;
    margin: 0px auto;
  }
  .load-more {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
