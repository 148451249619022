@import '../../../styles/variables.scss';

@keyframes content-fadein {
  0% {
    opacity: 0;
    transform: scale(0, 1);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes content-fadeout {
  0% {
    opacity: 1;
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    transform: scale(0, 1);
  }
}

@keyframes textFadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes textFadeOut {
  0% {
    opacity: 1;
  }

  35% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.AlertBoxWidget {
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
  padding: 0px 1em 1em 1em;

  justify-content: center;
  align-content: center;

  font-size: 20px;

  // Main styling
  .content {
    box-sizing: border-box;
    width: 17em;
    height: 6em;
    border-radius: 0.8em;
    display: grid;
    position: relative;
    opacity: 0;

    .new-donation {
      color: #10ceea;
      font-size: 0.6em;
      letter-spacing: 0.9px;
    }

    .name {
      font-weight: bold;
      font-size: 0.9em;
      color: #171717;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 180px;
      max-height: 40px;
    }

    .amount {
      font-size: 1.2em;
    }
  }

  // Styling for animation states.
  &.display {
    .content {
      animation: content-fadein 500ms ease-in-out forwards !important;

      .new-donation,
      .name,
      .amount {
        animation: textFadeIn 500ms ease-in-out forwards !important;
      }
    }
  }

  &.hiding {
    .content {
      animation: content-fadeout 500ms ease-in-out forwards !important;

      .new-donation,
      .name,
      .amount {
        animation: textFadeOut 500ms ease-in-out forwards !important;
      }
    }
  }

  &.hide {
    .content {
      opacity: 0;
    }
  }

  // Styling for widget styles
  &.large-box {
    .content {
      box-sizing: border-box;
      grid-template-rows: 1em 1.5em;
      grid-template-columns: 7.5em 1.5em 5em;
      justify-content: center;
      align-content: center;
      grid-gap: 0px;
      padding: 1em 1.5em;

      width: 20em;
      height: 5em;

      font-size: 100%;

      .new-donation {
        grid-column-start: 1;
        grid-row-start: 1;
      }

      .name {
        grid-column-start: 1;
        grid-row-start: 2;
      }

      .amount {
        grid-column-start: 3;
        grid-row-start: 1;
        grid-row-end: 3;
        align-self: center;

        font-size: 150%;
      }
    }
  }

  &.slim-box {
    .content {
      height: 3em;
      width: 25em;

      .new-donation {
        grid-column-start: 1;
        grid-row-start: 1;
        text-align: center;
        align-self: center;
      }

      .name {
        grid-column-start: 2;
        grid-row-start: 1;
        align-self: center;
        text-align: center;

        &:after {
          content: ' : ';
        }
      }

      .amount {
        grid-column-start: 3;
        grid-row-start: 1;
        align-self: center;
        text-align: start;
      }
    }
  }

  &.rounded-box {
    .content {
      justify-content: center;
      box-shadow: 0px 0px 6px #00000040;
      border-radius: 2.5em;
      grid-gap: 0px;
      box-sizing: border-box;
      height: 4.5em;
      grid-template-columns: 7.5em 5em;

      .new-donation {
        text-align: start;
        align-self: end;
        margin-bottom: 0.4em;
        grid-column-start: 1;
        grid-row-start: 1;
      }

      .name {
        text-align: start;

        &:after {
          content: ' : ';
        }

        grid-column-start: 1;
        grid-row-start: 2;
      }

      .amount {
        grid-row-start: 2;
        grid-row-end: 2;
        font-size: 1em;

        grid-column-start: 2;
        margin-left: 1em;
      }
    }
  }

  &.large-box,
  &.slim-box {
    .content {
      border: 1px solid color(purple, dark);

      &:before {
        content: ' ';
        position: absolute;
        top: 0px;
        left: 50%;
        width: 80%;
        height: 0.3em;
        transform: translate(-50%, 0);
      }

      &:after {
        content: ' ';
        position: absolute;
        bottom: 0px;
        left: 50%;
        width: 80%;
        height: 0.3em;
        transform: translate(-50%, 0);
      }
    }

    &.light {
      .content {
        &:before {
          background: transparent
            linear-gradient(270deg, #ffffff 0%, color(purple) 52%, #ffffff 100%) 0% 0% no-repeat
            padding-box;
        }

        &:after {
          background: transparent
            linear-gradient(270deg, #ffffff 0%, color(purple) 52%, #ffffff 100%) 0% 0% no-repeat
            padding-box;
        }
      }
    }

    &.dark {
      .content {
        background: radial-gradient(
          38.1% 116.87% at 50.13% 124.54%,
          color(purple) 0%,
          color(purple, darker) 100%
        );

        &:before {
          background: transparent
            linear-gradient(
              270deg,
              color(purple, darker) 0%,
              color(purple, light) 52%,
              color(purple, darker) 100%
            )
            0% 0% no-repeat padding-box;
        }

        &:after {
          background: transparent
            linear-gradient(
              270deg,
              color(purple, darker) 0%,
              color(purple, light) 52%,
              color(purple, darker) 100%
            )
            0% 0% no-repeat padding-box;
        }
      }
    }
  }

  // Styling for widget themes
  &.light {
    .content {
      background-color: white;
      background: #ffffff 0% 0% no-repeat padding-box;

      .new-donation {
        color: color(purple);
      }
    }
  }

  &.dark {
    .content {
      background: transparent
        radial-gradient(closest-side at 50% 159%, color(purple) 0%, color(purple, darker) 100%) 0%
        0% no-repeat padding-box;
      border: 1px solid color(purple, light);

      .new-donation {
        color: color(purple, light);
      }

      .name {
        color: #fff;
      }

      .amount {
        color: #fff;
      }
    }
  }

  // Preview needs to be smaller.
  &.preview {
    font-size: 15px;
  }
}
