@import '../../styles/variables.scss';

@keyframes loader-animate {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.SearchResultSkeleton {
  position: relative;
  box-shadow: $box-shadow;
  border-radius: 10px;
  max-height: 290px;
  min-width: 340px;
  width: 100%;
  z-index: 1;
  .top-section {
    border-radius: 10px;
    height: 159px;
    background-color: #f5f5f5;

    div:first-of-type {
      top: 18px;
      left: 18px;
      border-radius: 50%;
      position: absolute;
      background: #e9e9e8;
      width: 60px;
      height: 60px;
    }
  }
  .bottom-section {
    height: 62px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > span {
      background-color: #e5e5e5;
      width: 100%;
      border-radius: 10px;
      height: 12px;
      margin-top: 10px;

      &:last-of-type {
        background-color: #f5f5f5;
      }
    }
  }

  &:after {
    content: '';
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: loader-animate;
    animation-timing-function: linear;
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1 );
  }
}
