@import '../../../styles/variables.scss';

.HubMetrics {
  display: flex;
  flex-flow: row wrap;
  padding: 30px 60px;
  align-items: center;
  justify-content: center;
  gap: 15px;

  .Metric {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-width: 150px;

    .data-label {
      color: black;
      font-weight: 800;
      font-size: 30px;
    }
    .title {
      color: color(purple, greyTxt);
      font-weight: 500;
    }
  }
}
