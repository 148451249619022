@import '../../../../styles/variables.scss';

.ModalManageField {
  display: flex;
  flex-flow: column nowrap;
  min-width: 450px;
  min-height: 550px;
  padding: 15px 0px;
  background-color: color(light, secondaryBg);

  .header {
    display: flex;
    padding: 25px;
    font-size: 18px;
    color: color(light, fontColor);
    border-bottom: #edf1f2 solid 1px;

    .header-label {
      flex-grow: 1;
      text-align: center;
    }

    .icon:hover {
      cursor: pointer;
    }
  }

  .inner-container {
    display: flex;
    flex-flow: column nowrap;
    padding: 15px;

    .TextField {
      // flex-grow: 1;
    }
  }

  .button-save {
    display: flex;
    justify-content: center;
    align-self: center;
    margin-top: auto;
    width: 100%;

    button {
      width: 90%;
    }
  }
}

@media (max-width: 680px) {
  .ModalManageField {
    min-width: unset;
  }
}
