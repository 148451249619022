@import '../../../styles/variables.scss';

.CardCommon:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);

  .cover-image-holder {
    transform: scale(1.1);
  }
}

.CardCommon {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 363px;
  height: 392px;
  background: color(light, secondaryBg);
  margin-bottom: 10px;
  margin-right: 32px;
  border-radius: 10px;
  transition: all 0.2s;
  box-shadow: 0 16px 28px rgba(0, 0, 0, 0.08);

  .card-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

@media (max-width: 790px) {
  .CardCommon {
    min-width: 363px;
  }
}

@media (max-width: 430px) {
  .CardCommon {
    min-width: 215px;
  }
}

@media (max-width: 330px) {
  .CardCommon {
    min-width: 165px;
  }
}
