@import '../../../styles/variables.scss';

.TableContextMenuIcon {
  position: relative;

  .icon {
    color: color(blue, dark);
    font-size: 24px;
    padding: 10px;
    border-radius: 50%;
    border: 2px solid color(grey, lightester);
    transition: all 150ms linear;
    cursor: pointer;

    &.no-items {
      color: color(blue, lighter);
      cursor: default;
    }
  }
}

.TableContextMenu {
  white-space: nowrap;
  position: fixed;
  width: 240px;
  z-index: 100;
  box-shadow: $box-shadow;
  border-top-right-radius: $border-radius;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  .title {
    background-color: color(grey, lightest);
    padding: 5px 20px;
    border-bottom: 1px solid color(grey, light);
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;

    &.title-cart {
      display: flex;
      flex: 1;

      span {
        flex: 1;

        &:last-child {
          display: flex !important;
          justify-content: flex-end;
          align-items: flex-end;
          color: color(purple, dark) !important;
        }
      }
    }
  }

  &.hidden {
    display: none;

    &:after {
      content: none;
    }
  }

  &:before {
    content: ' ';
    position: absolute;
    top: -18px;
    right: 40px;
    border: 10px solid transparent;
    border-bottom: 10px solid color(grey, lightest);
  }

  &:after {
    content: ' ';
    position: absolute;
    top: -60px;
    right: 0;
    width: 90px;
    height: 60px;
  }

  &.no-title {
    &:before {
      border-bottom: 10px solid color(light, secondaryBg);
    }

    .ContextMenu-ul {
      li {
        &:first-child {
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
      }
    }
  }

  .ContextMenu-ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: 350px;
    overflow: auto;

    li {
      padding: 5px 20px;
      background-color: color(light, secondaryBg);
      border-bottom: 1px solid color(grey, lighter);
      min-height: 40px;
      line-height: 40px;

      &.empty-cart {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          text-align: center;
        }
      }

      &.view-cart {
        background-color: color(purple, dark);

        &:hover {
          background-color: color(purple);

          a {
            color: color(dark, fontColor) !important;

            span {
              color: color(dark, fontColor) !important;
            }

            &:hover {
              color: color(dark, fontColor) !important;

              span {
                color: color(dark, fontColor) !important;
              }
            }
          }
        }

        a {
          justify-content: center !important;
          align-items: center !important;

          &:hover {
            span {
              color: color(dark, fontColor) !important;

              &:hover {
                color: color(dark, fontColor) !important;
              }
            }
          }

          span,
          i {
            color: color(dark, fontColor) !important;
          }

          i {
            margin-right: 8px;
          }
        }
      }

      &.store-item {
        padding: 20px;

        .store-item-price,
        .portrait {
          flex: 1;
        }

        .store-item-content {
          flex: 2;
        }

        &:hover {
          .store-item-price {
            span {
              &:last-child {
                &:hover {
                  color: color(grey) !important;
                }
              }
            }
          }
        }
      }

      .remove-item {
        display: flex;
        margin-left: 10px;
        align-self: flex-start;

        i {
          color: color(red, dark) !important;

          &:hover {
            color: color(purple) !important;
          }
        }
      }

      .store-item-content {
        padding-left: 10px;
        min-width: 140px;

        span {
          line-height: 20px;
          position: relative;

          span {
            position: absolute;
            top: 0;
            right: 0;
          }

          &:first-child {
            color: color(light, fontColor) !important;
            font-weight: 500;
          }

          &:last-child {
            font-size: 12px;
          }

          &:hover {
            color: color(light, fontColor) !important;
          }
        }
      }

      .store-item-price {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        span {
          color: color(purple, dark) !important;
          font-weight: 500;
          line-height: 20px;

          &:last-child {
            font-size: 12px;
            font-weight: 300;
            color: color(grey) !important;
          }
        }
      }

      a {
        color: color(light, fontColor);
        text-decoration: none;
        display: flex;
        flex: 1;

        &:hover {
          cursor: pointer;

          span {
            color: color(purple, dark) !important;
          }
        }
      }

      &:not(.noClick):hover {
        a,
        span {
          color: color(purple, dark) !important;
        }
      }

      a,
      span {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;

        &:before {
          content: none !important;
        }

        i {
          font-size: 16px !important;
          height: 40px;
          line-height: 40px !important;
          margin-right: 10px;
        }
      }

      &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border-bottom: 0;
      }

      &.blue {
        background-color: color(purple, dark);

        a {
          display: flex;
          justify-content: center;

          span,
          i {
            color: color(dark, fontColor) !important;
          }

          i {
            font-size: 16px !important;
          }
        }
      }

      &.toggle-container {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          flex: 1;
        }

        i {
          font-size: 16px;
          margin-right: 10px;

          &.fa-toggle-on {
            color: color(purple, dark);
          }
        }
      }

      span {
        font-size: 15px;
      }
    }

    .sub-menu-wrap {
      .sub-menu {
        display: none;
      }
    }
  }
}
