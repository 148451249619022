@import '../../../styles/variables.scss';

.components-wrap {
  &.dragging {
    .PageComponent {
      .GigListItem {
        &.admin {
          &:hover {
            border: 0px;

            .list-admin-actions {
              display: none;
            }
          }
        }
      }
    }
  }
}

.dark {
  .GigListItem {
    background-color: color(dark, secondaryBg);

    .content {
      background-color: color(dark, secondaryBg);

      .info {
        .start-date,
        .group-name {
          color: color(dark, fontColor);
          opacity: 0.5;
        }
      }
    }
  }
}

.GigListItem {
  display: flex;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0);
  position: relative;
  background-color: color(light, secondaryBg);
  margin-bottom: 20px;

  &.hidden {
    .cover,
    .Portrait,
    .start-date,
    .title,
    .group-name,
    .action {
      opacity: 0.5;
    }
  }

  .list-admin-actions {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: color(purple);
    height: 30px;
    line-height: 30px;

    ul {
      display: flex;
      list-style-type: none;
      padding: 0px;
      margin: 0px;

      li {
        color: color(dark, fontColor);
        padding: 0px 10px;

        &:hover {
          cursor: pointer;
          background-color: color(light, secondaryBg);

          i,
          span {
            color: color(purple);
          }
        }

        span {
          margin-left: 8px;
        }
      }
    }
  }

  &.admin {
    &:hover {
      border: 1px solid color(purple);

      .list-admin-actions {
        display: block;
      }
    }
  }

  .Portrait {
    min-height: 84px;
  }

  .content {
    width: 300px;
    display: flex;
    flex-direction: column;
    background-color: color(light, secondaryBg);

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .start-date {
        font-size: 14px;
        color: color(grey);
        font-weight: 700;
      }

      .group-name {
        font-size: 15px;
        line-height: 21px;
        font-weight: 300;
        color: color(grey);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0px 20px 0px 0px;
      }

      .title {
        font-size: 18px;
        font-weight: 700;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding: 0px 20px 0px 0px;
      }

      .change-status-dropdown {
        margin-bottom: 10px;
      }
    }
  }

  .cover {
    flex: 1;
    min-height: 220px;

    .CoverImage {
      width: 100%;
      height: 100%;
      border-radius: $border-radius;
    }
  }

  .action {
    margin-right: 20px;
    a {
      text-decoration: none;
      font-weight: 500;
      display: flex;
      border: 0px;
      padding: 0px;
      font-size: 16px;
      border-radius: $border-radius;
      justify-content: center;
      align-items: center;
      color: color(dark, fontColor);
      background-color: color(purple, dark);
      overflow: hidden;
      min-width: 80px;
      min-height: 40px;

      &:disabled {
        background-color: color(dark, tertiaryBg);
      }

      &:focus {
        outline: 0px;
      }

      &:hover {
        cursor: pointer;
        background-color: color(purple, darker);
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        position: relative;
        width: 20px;
        height: 20px;
      }

      .text {
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 930px) {
  .GigListItem {
    flex-direction: column-reverse;
    border: 2px solid color(grey, lighter);
    padding: 0px;
    border-radius: 22px;

    &.admin {
      &:hover {
        border: 2px solid color(purple);
      }

      .list-admin-actions {
        border-top-right-radius: 18px;
      }
    }

    .content {
      width: auto;
      background-color: transparent;
      padding: 10px;
      flex-direction: row;
      min-height: 0px;
      align-items: center;
      position: relative;

      .info {
        margin-left: 20px;
      }

      .info,
      .Portrait {
        padding-bottom: 60px;
      }

      .action {
        position: absolute;
        bottom: 20px;
        left: 0px;
        width: 100%;
        margin: 0px;
        display: flex;
        flex: 1;

        a {
          display: flex;
          margin: 0px 20px;
          flex: 1;
        }
      }
    }

    .cover {
      .CoverImage {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }
}
