@import '../../../styles/variables.scss';

.card-actions {
  .actions-container {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 38px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 32px;
    padding-top: 32px;

    button,
    a {
      background-color: unset;
      border: none;
      color: color(grey, dark);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $lato;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      padding: 12px 16px;
      cursor: pointer;
      transition: all 0.2s;
      border-radius: 22px;
      width: 143px;
      border: 2px solid #bdbebf;

      &:hover {
        color: color(plum, dark);
        background-color: color(grey, pale);
      }

      i {
        font-size: 14px;
        margin-right: 15px;
      }
    }

    div {
      &:nth-of-type(1) {
        margin-left: 32px !important;
      }

      &:nth-of-type(2) {
        margin-right: 32px !important;
      }
    }
  }
}
