@import '../../../../../styles/variables.scss';

.EditComponentModal {
  display: flex;
  flex-flow: column nowrap;

  &.Modal {
    .close-button {
      display: none;
    }

    &.large {
      .content {
        min-width: 800px;
      }
    }
    &.medium {
      .content {
        min-width: 600px;
      }
    }
    .content {
      .ModalHeader {
        margin: 20px 40px;
        border-bottom: 1px solid color(grey, lightest);

        .header-container {
          padding: 0;
        }
      }
    }
  }

  .edit-modal-content {
    display: flex;
    flex-flow: column nowrap;
    max-height: 500px;
    padding: 0 40px 40px 40px;
    overflow-y: auto;

    @include thin-scroller-y;
  }

  .edit-modal-actions {
    display: flex;
    padding: 20px 0;
    gap: 15px;
    border-top: 1px solid color(grey, lightest);
    justify-content: flex-end;
    margin: 0 40px;
    margin-top: auto;
  }
}

@media (max-width: 1070px) {
  .EditComponentModal {
    &.Modal {
      &.large,
      &.medium {
        .content {
          width: 100%;
          min-width: unset;
        }
      }
    }
  }
}
