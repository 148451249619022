@import '../../styles/variables.scss';

.dark {
  .GetStarted {
    .inner {
      p {
        color: color(dark, fontColor);

        span {
          &:hover {
            color: color(dark, fontColor);
          }
        }
      }

      .row,
      .dob {
        .col {
          .TextField {
            input {
              background-color: color(dark, primaryBg);
            }
          }
        }
      }
    }
  }
}

.light {
  .GetStarted {
    .inner {
      p {
        color: color(light, fontColor);

        span {
          &:hover {
            color: color(light, fontColor);
          }
        }
      }

      .row,
      .dob {
        .col {
          .TextField {
            input {
              background-color: color(light, primaryBg);
            }
          }
        }
      }
    }
  }
}

.GetStarted {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-image: url('../../assets/overview.png');
  background-size: cover;

  .curve-wrap {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0.1;

    .curve,
    .bottom-fill {
      flex: 1;
      background-color: color(dark, fontColor);
    }

    .curve {
      position: static;
    }
  }

  &:before {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #008599 0%, #4d56c7 84%, #734fd5 100%) 0% 0%
      no-repeat padding-box;
    opacity: 0.75;
  }

  .GetStarted-inner {
    position: relative;
    background-color: color(dark, fontColor);
    width: 100%;
    max-width: 800px;
    margin: 40px auto;
    padding: 40px;
    border-radius: $border-radius;

    .title-section {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        padding: 0px;
        margin: 0px 0px 20px 0px;
        text-align: center;
        font-size: 32px;
      }

      .instructions {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: color(grey);
        padding: 0px 80px 20px;
        font-weight: 400;
        max-width: 320px;
        margin: 0px auto;
      }
    }

    .options {
      display: flex;
      flex-direction: column;

      .option-row {
        display: flex;

        &:first-child {
          padding-bottom: 20px;
        }

        .option {
          flex: 1;

          &:hover {
            cursor: pointer;

            .option-inner {
              border: 2px solid color(purple, dark);
              -webkit-box-shadow: -2px 4px 5px -1px rgba(0, 0, 0, 0.32);
              -moz-box-shadow: -2px 4px 5px -1px rgba(0, 0, 0, 0.32);
              box-shadow: -2px 4px 5px -1px rgba(0, 0, 0, 0.32);
            }
          }

          &:first-child {
            padding-right: 10px;
          }

          &:last-child {
            padding-left: 10px;
          }

          .option-inner {
            display: flex;
            overflow: hidden;
            border: 2px solid color(grey, light);
            border-radius: $border-radius;
            height: 200px;

            .option-title {
              font-size: 21px;
              padding: 20px 20px 0px 20px;
              font-weight: 700;
              color: color(purple, dark);
            }

            .option-desc {
              font-size: 14px;
              padding: 20px 25px 0px 20px;
              line-height: 21px;
              color: color(grey);
              margin-bottom: 10px;
            }

            .option-img-container {
              .option-img {
                height: 100%;
                width: 125px;
                background-position: center center;
                background-size: cover;
              }

              &:first-child {
                .option-img.group {
                  background-image: url('../../assets/group.jpg');
                }
                .option-img.community {
                  background-image: url('../../assets/teamwork_3.png');
                }
                .option-img.non-profit {
                  background-image: url('../../assets/create-a-team.png');
                }
                .option-img.profile {
                  background-image: url('../../assets/join-as-individual.png');
                }
              }
            }
          }
        }
      }
    }

    .actions-row {
      display: flex;
      flex-direction: row;
      padding: 40px 0px 0px;
      color: #a0aaae;
      font-size: 14px;
      justify-content: space-between;

      .login {
        align-self: center;

        a {
          color: color(purple, dark);
          text-decoration: none;

          &:hover {
            color: color(blue, dark);
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .GetStarted {
    .GetStarted-inner {
      margin: 20px;

      .actions-row {
        display: flex;
        justify-content: center;
      }

      .title-section {
        .instructions {
          padding: 0px 20px 25px;
        }
      }

      .options {
        flex-direction: column;

        .option-row {
          flex-direction: column;

          .option {
            &:first-child {
              padding-right: 0px;
              padding-bottom: 20px;
            }

            &:last-child {
              padding-left: 0px;
            }

            .option-inner {
              min-height: 200px;
              height: auto;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .GetStarted {
    .GetStarted-inner {
      padding: 25px;

      .options {
        .option-row {
          .option {
            .option-inner {
              flex-direction: column;

              .option-img-container {
                height: 120px;
                width: 100%;

                .option-img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
