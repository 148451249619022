@import '../../../../../styles/variables.scss';

.RequestMatchModal {
  .content {
    width: 1022px;
    display: flex;
    flex-direction: row;

    .ModalHeader,
    .modal-close-circle {
      display: none;
    }

    .thank-you {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      height: 358px;
      align-items: center;
      justify-content: center;

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
          font-family: $raleway;
          font-style: normal;
          font-weight: 800;
          font-size: 32px;
          color: color(grey, dark);
          text-align: center;
          margin: 0 0 27px 0;
        }

        p {
          text-align: center;
          font-family: $lato;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;

          .price {
            color: color(blue, soft2);
            font-weight: 700;
          }

          &:first-of-type {
            font-size: 18px;
            margin-bottom: 17px;
          }
        }
      }

      .close-icon {
        z-index: 3;
        width: 44px;
        height: 44px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 22px;
        right: 22px;
        font-size: 20px;
        font-weight: bold;
        color: color(grey, dark);
        padding: 4px;
        border-radius: 50%;
        transition: all 0.2s;
        cursor: pointer;

        &:hover {
          background-color: color(grey, pale);
        }
      }
    }

    .donaiton-summary {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 400px;
      background-color: color(grey, lightester);

      .portrait-img {
        position: absolute;
        top: 96px;
        place-self: center;
      }

      .cover-img {
        max-height: 140px;
        max-width: 400px;
        width: 100%;
        height: 100%;
      }

      .info {
        display: flex;
        flex-direction: column;
        padding: 38px;
        align-items: center;

        .title {
          font-family: $lato;
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          text-align: center;
          margin: 23px 0 18px 0;
        }

        .subtitle {
          font-family: $lato;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          text-align: center;
          margin-bottom: 18px;
        }

        .status {
          margin-bottom: 24px;
          padding: 6px 12px;
          border-radius: 8px;
          font-family: $lato;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          background: color(grey, pale);
          color: color(dark, border);

          &.paid {
            background: color(green, fade);
            color: color(green, soft);
          }
        }

        .amounts {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-bottom: 21px;

          &.with-border {
            border-top: 1px solid color(grey, border);
            border-bottom: 1px solid color(grey, border);
            padding: 20px 0;
          }
        }

        .item {
          display: flex;
          justify-content: space-between;

          span {
            font-family: $lato;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 6px;
          }

          &.total-amount {
            span {
              font-family: $lato;
              font-style: normal;
              font-weight: 900;
              font-size: 14px;
            }
          }
        }
      }

      .close-icon {
        z-index: 3;
        width: 44px;
        height: 44px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 18px;
        right: 18px;
        font-size: 24px;
        color: color(light, secondaryBg);
        padding: 4px;
        border-radius: 50%;
        transition: all 0.2s;
        cursor: pointer;

        &:hover {
          background-color: color(grey, pale);
        }
      }
    }

    .main-info {
      flex-grow: 0;
      width: 622px;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 48px 48px 0 48px;

      .top {
        width: 100%;
        height: 440px;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;

        h2 {
          font-family: $raleway;
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
        }

        h1 {
          font-family: $raleway;
          font-style: normal;
          font-weight: 800;
          font-size: 21px;
          letter-spacing: 0.280123px;
        }

        .note {
          text-align: center;
          margin: 0;
          width: 100%;
          font-weight: 400;
          margin-top: 96px;
          color: color(grey, dark);
        }

        .pick-program {
          .title {
            display: flex;
            justify-content: space-between;
            border: 1px solid color(grey, border);
            border-radius: 10px;
            padding: 10px 16px;
            color: color(grey, base);

            .menu-btn {
              transition: transform 0.3s ease-in-out;

              &.show {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      .btn-container {
        display: flex;
        height: 70px;
        align-items: center;
        justify-content: flex-end;

        .Button {
          &:first-of-type {
            margin-right: 16px;
          }
        }
      }
    }
  }
}
