@import '../../../../styles/variables.scss';
.InfoSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: color(dark, fontColor);
  background-color: color(purple, dark);
  background-repeat: no-repeat;
  background-position: center center;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  flex: 1;
  position: relative;

  .logo-container {
    position: absolute;
    top: 60px;
    left: 60px;
  }

  .info-wrapper {
    animation: slideUp 200ms ease-in forwards;
    margin-top: 210px;
  }

  .title {
    font-family: $raleway;
    color: #fff;
    padding: 10px 15px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 800;
    text-align: center;

    &.mobile {
      display: none;
    }
  }

  .text {
    font-family: $raleway;
    padding: 15px 80px;
    font-size: 34px;
    line-height: 40px;
    font-weight: 900;
    text-align: center;
  }

  .tips {
    font-family: $lato;
    font-size: 16px;
    line-height: 24px;
    padding: 15px 20%;
    text-align: center;
  }

  .login {
    font-family: $raleway;
    text-align: center;
    margin-top: 30px;
    .login-link {
      color: #fff;
      text-decoration: none;
      font-weight: 800;
    }
  }

  .claim-charities-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0 100px;

    div:first-of-type {
      margin-bottom: 20px;
    }
  }

  .step-indicator {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      opacity: 0.2;
      margin: 0 5px;
      font-size: 8px;

      &.active {
        opacity: 1;
      }
    }
  }

  .group {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(200%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 1000px) {
  .InfoSection {
    justify-content: center;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: 25px;
    height: 200px;
    max-height: 200px;
    .title {
      display: none;

      &.mobile {
        display: block;
        font-size: 14px;
        opacity: 0.7;
        padding-bottom: 0;
      }
    }

    .text {
      font-size: 24px;
      padding: 13px 0;
    }

    .tips,
    .step-indicator {
      display: none;
    }

    .info-wrapper {
      margin-top: 0;
      .group {
        .Portrait {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .InfoSection {
    .text {
      font-size: 18px;
    }
  }
}
