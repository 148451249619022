@import '../../../../styles/variables.scss';

.AuctionItemDetailsModal {
  background-color: color(light, secondaryBg);
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 700px;

  .modal-scollcontainer {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: min-content;
  }

  .modal-content {
  }

  .cover-image {
    width: 100%;
    height: 325px;
    max-height: 325px;
    min-height: 325px;
    object-fit: cover;
  }

  .album-controls {
    display: flex;
    align-self: center;

    .notch-empty {
      border: 1px solid #88949a;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      cursor: pointer;
      margin-left: 10px;
    }

    .notch-filled {
      border: 1px solid #88949a;
      background-color: #88949a;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin-left: 10px;
    }
  }

  .name {
    font-size: 24px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-right: 124px;
    margin-top: 10px;
  }

  .current-bid {
    padding-top: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #354a53;
    margin-top: 10px;
  }

  .details-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    flex: 1;
  }

  .desc-label {
    color: color(light, fontColor);
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }

  .description {
    .ql-editor {
      padding-left: 0px;
      font-size: 16px;
      line-height: 24px;
      color: color(grey);
    }
  }

  .price-and-date-details {
    display: flex;
    flex-direction: column;

    .minimum,
    .retail,
    .close {
      font-size: 13px;
      font-weight: 700;
      display: flex;
      margin-top: 20px;

      .icon-wrap {
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        width: 30px;
        background-color: color(grey, light);
        border-radius: 50%;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .info {
        font-weight: 400;
        color: color(grey);
      }
    }

    .minimum {
      padding-right: 20px;
    }
  }

  .status {
    justify-content: center;
    align-items: center;

    span {
      color: color(grey);
      font-size: 18px;
    }

    i {
      margin-right: 4px;
      font-size: 18px;

      &.fa-times-circle {
        color: color(red, dark);
      }

      &.fa-check-circle {
        color: color(green, dark);
      }
    }
  }

  .action-buttons {
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    margin-top: 20px;

    width: 100%;

    .action-button {
      width: 100%;
    }

    .buy-now-button {
      margin-top: 20px;

      button {
        color: color(purple, dark);
        background-color: #ffffff;
        border: 2px solid color(purple, dark);

        &:hover {
          color: color(purple);
          border: 2px solid color(purple);
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .AuctionItemDetailsModal {
    .modal-scollcontainer {
      min-height: unset; // GIG-1619: Needed to remove min-content. Scrolling seems to break on Safari Mobile when using min-content.
      min-height: calc(100% - 50px);

      .modal-content {
        padding-bottom: 50px;
      }
    }
  }
}
