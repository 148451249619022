@import '../../../styles/variables.scss';

.TeamDefault {
  .Team-content {
    position: relative;
    margin: 0px auto;
    max-width: $wrap-size;
    min-height: 70vh;
    display: flex;
    flex: 1;

    .sidebar {
      width: 340px;
      margin-right: 25px;

      .load-wrap {
        margin: 60px 0 40px;

        .Loader {
          i {
            font-size: 31px;
          }
        }
      }

      .sidebar-inner {
        background-color: color(light, secondaryBg);
        padding: 20px;
        border-radius: $border-radius;
        min-height: 300px;
        box-shadow: $box-shadow;
        display: flex;
        align-items: center;
        flex-direction: column;

        &.fixed {
          position: fixed;
          top: 80px;
          width: 300px;
          z-index: 98;
        }

        .Portrait {
          margin-top: 20px;
        }

        .sidebar-content {
          flex: 1;
          margin-top: 40px;
          width: 100%;

          .donation-matching-message {
            display: flex;
            flex-direction: column;
            background: color(purple, light);
            flex: 1;
            justify-content: center;
            align-items: center;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 15px;

            h3 {
              margin: 0;
              font-size: 17px;
              color: color(purple, base);
            }

            p {
              text-align: center;
              color: color(blue, dark);
              margin: 5px 0;
              font-size: 15px;
              font-weight: 500;
            }

            span {
              color: color(purple, base);
              font-size: 14px;
              margin-top: 5px;
            }
          }

          .sidebar-info {
            padding: 0px 20px;

            .type {
              color: color(grey);
              font-weight: 500;

              i {
                margin-right: 8px;
              }
            }

            .name {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 1;
              margin: 5px 0px 0px;
              padding: 0px;
              font-size: 28px;

              &:hover {
                i {
                  opacity: 1;
                }
              }

              span {
                flex: 1;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }

              i {
                opacity: 0;

                &:hover {
                  cursor: pointer;
                  color: color(purple, dark);
                }
              }
            }

            .team-title {
              .TextField {
                margin-top: 5px;

                input {
                  background-color: color(grey, lighter);
                  font-size: 21px;
                }
              }
            }

            .handle {
              margin-top: 5px;
              color: color(grey);
              font-weight: 500;

              a {
                text-decoration: none;
                color: color(light, fontColor);

                &:hover {
                  color: color(purple, dark);
                }
              }
            }
          }

          .sidebar-actions {
            margin: 40px 0px 20px 0px;
            flex: 1;
            padding: 0px 20px;

            a {
              text-decoration: none;
              display: flex;
              border: 0px;
              font-size: 16px;
              border-radius: 20px;
              justify-content: center;
              align-items: center;
              color: color(dark, fontColor);
              background-color: color(purple);
              overflow: hidden;
              min-width: 80px;
              min-height: 40px;

              &.manage {
                background-color: color(purple, dark);
                margin-top: 15px;
              }
              &:hover {
                background-color: color(purple, dark);
              }
            }

            .Button {
              &:first-child {
                button {
                  background-color: color(purple);
                }
              }

              &:last-child {
                button {
                  background-color: color(light, secondaryBg);
                  color: color(purple, dark);
                  border: 2px solid color(purple, dark);
                }
              }

              button {
                margin-top: 15px;
                flex: 1;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .main {
      flex: 1;

      .CoverImage {
        flex: 1;
        height: 380px;
        box-shadow: $box-shadow;
      }

      .Team-main-content {
        margin-top: 25px;
        background-color: color(light, secondaryBg);
        padding: 20px;
        border-radius: $border-radius;
        min-height: 300px;
        box-shadow: $box-shadow;
        min-height: 60vh;

        .team-info {
          display: flex;
          border-top: 1px solid color(grey, lighter);
          border-bottom: 1px solid color(grey, lighter);
          padding: 20px 0px;
          margin: 40px 0px;

          .circle-wrap {
            .circle {
              display: flex;
              width: 40px;
              height: 40px;
              background-color: color(grey, lighter);
              border-radius: 50%;
              justify-content: center;
              align-items: center;
            }
          }

          .sect {
            flex: 1;
            display: flex;
            border-right: 1px solid color(grey, lighter);
            justify-content: center;
            align-items: center;
            width: 25%;

            &:last-child {
              border-right: 0px;
            }

            .team-content {
              display: flex;
              flex-direction: column;
              padding-left: 8px;

              span {
                font-size: 12px;

                &:first-child {
                  font-weight: 700;
                }

                &:last-child {
                  color: color(grey);
                  padding-top: 4px;
                }
              }
            }
          }
        }

        .story {
          .quill {
            .ql-disabled {
              .ql-editor {
                padding: 0px;
              }
            }
          }

          .story-title {
            font-size: 24px;
            font-weight: 700;
            border-bottom: 1px solid color(grey, lighter);
            height: 40px;
            margin-bottom: 20px;
            display: flex;
            flex: 1;

            span {
              flex: 1;
            }

            i {
              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }
          }

          p {
            font-size: 16px;
            color: color(grey);
            line-height: 28px;
            padding: 0px 0px 20px;
            margin: 0px;
          }
        }

        .members,
        .donations {
          padding-top: 40px;

          .members-title {
            font-size: 24px;
            font-weight: 700;
            border-bottom: 1px solid color(grey, lighter);
            height: 40px;
            margin-bottom: 20px;
          }

          .empty {
            color: color(grey);

            span {
              color: color(purple, dark);
              cursor: pointer;
            }
          }

          .members-wrap,
          .donations-wrap {
            display: flex;
            flex-wrap: wrap;

            .member,
            .donation {
              display: flex;
              justify-content: center;
              width: 30%;
              margin: 0px 0px 20px;
              padding: 0px 10px;

              &:nth-child(3n + 2) {
                border-left: 1px solid color(grey, lighter);
                border-right: 1px solid color(grey, lighter);
              }

              &:last-child {
                border-right: 0px;
              }

              .member-info,
              .donation-info {
                display: flex;
                flex-direction: column;
                margin-left: 10px;
                width: 120px;
                overflow: hidden;
              }

              .member-info {
                justify-content: center;
              }

              .donation-info {
                justify-content: flex-start;
              }

              .member-info,
              .donation-info {
                .displayName {
                  font-size: 18px;
                  font-weight: 500;
                  margin-bottom: 3px;
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  a {
                    text-decoration: none;
                    color: color(light, fontColor);

                    &:hover {
                      color: color(purple, dark);
                    }
                  }
                }

                .raised,
                .donated {
                  font-size: 14px;
                  color: color(grey);
                }

                .note {
                  padding: 10px 0px;
                  font-size: 16px;
                  line-height: 24px;
                }
              }
            }
          }
        }

        .donations {
          padding-top: 40px;

          .donations-title {
            font-size: 24px;
            font-weight: 700;
            border-bottom: 1px solid color(grey, lighter);
            height: 40px;
            margin-bottom: 20px;
          }

          .empty {
            color: color(grey);

            span {
              color: color(purple, dark);
              cursor: pointer;
            }
          }
        }

        .inner-content {
          padding: 40px;

          .details {
            display: flex;
            justify-content: center;
            align-items: center;

            .amount {
              flex: 1;
              display: flex;
              flex-direction: column;

              span {
                &:first-child {
                  font-size: 31px;
                  font-weight: 700;
                }

                &:last-child {
                  font-size: 16px;
                  padding-top: 5px;
                  font-weight: 500;
                  color: color(grey);
                }
              }
            }

            .percentage {
              .circle {
                background-color: color(grey, lighter);
                height: 90px;
                width: 90px;
                overflow: hidden;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                span {
                  &:first-child {
                    font-size: 24px;
                    font-weight: 700;
                  }

                  &:last-child {
                    color: color(grey);
                  }
                }
              }
            }
          }

          .bar-wrap {
            padding-top: 20px;

            .bar {
              display: flex;
              align-items: center;
              position: relative;
              height: 16px;
              background-color: color(grey, light);
              border-radius: 8px;
              width: 100%;

              .bar-inner {
                background: linear-gradient(90deg, color(purple, darkest) 0%, color(purple) 100%);
                margin-left: 2px;
                border-radius: 6px;
                width: 10%;
                height: 12px;
                background-color: color(grey);
              }
            }
          }
        }

        .load-wrap {
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;

          .Loader {
            margin-top: 40px;
            i {
              font-size: 31px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1240px) {
  .Team {
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0px;
    border-bottom: 1px solid color(grey, lighter);

    .back-splash {
      height: 250px;

      img {
        filter: blur(0);
        height: 100%;
        width: auto;
      }
    }

    .Team-content {
      flex-direction: column;

      .sidebar {
        width: auto;
        margin-right: 0px;

        .sidebar-inner {
          background-color: transparent;
          max-width: 500px;
          box-shadow: none;
          margin: 90px auto 0px;
          padding: 0px;

          .sidebar-content {
            .sidebar-info {
              text-align: center;

              .name {
                i {
                  display: none;
                }
              }
            }
          }
        }
      }

      .main {
        .CoverImage {
          display: none;
        }

        .Team-main-content {
          border-radius: 0px;
          margin-top: 0px;
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .Team {
    .Team-content {
      .main {
        .Team-main-content {
          .inner-content {
            padding: 10px;
          }

          .member-info,
          .donation-info {
            width: 100% !important;
          }

          .members .members-wrap,
          .donations .donations-wrap {
            .member,
            .donation {
              width: auto;
              flex: 1;
              border-right: 0px !important;
              border-left: 0px !important;
            }
          }

          .team-info {
            flex-direction: column;
            width: auto;

            .sect {
              width: auto;
              border-right: 0px;
              justify-content: center;
              margin-bottom: 20px;

              &:last-child {
                margin-bottom: 0px;
              }

              .team-content {
                width: 100px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 825px) {
  .TeamDefault {
    .Team-content {
      .sidebar {
        .sidebar-inner {
          .sidebar-content {
            .sidebar-actions {
              a,
              button {
                &.manage {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .Team {
    .Team-content {
      .sidebar {
        .sidebar-inner {
          .sidebar-content {
            .sidebar-actions {
              flex-direction: column;

              a,
              .Button {
                margin-left: 0px;
                margin-right: 0px;
              }

              a {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}
