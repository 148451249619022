@import '../../../../styles/variables.scss';

.HubStoreDetailItemModal {
  .content {
    display: flex;
    flex-direction: column;
    row-gap: 68px;
    padding: 18px 34px;

    .ModalHeader {
      right: 20px;
      position: absolute;
    }

    .header {
      display: flex;
      column-gap: 16px;
      align-items: flex-start;
      justify-content: flex-start;

      .hub-logo {
        width: 48px;
        height: 48px;
        filter: drop-shadow(0 8px 16px rgba(0, 0, 0, 0.12));
        border-radius: 60px;
      }

      .head-content {
        display: flex;
        flex-direction: column;
        row-gap: 2px;
        align-items: flex-start;
        justify-content: center;

        .title {
          display: flex;
          align-items: center;
          justify-content: center;

          h3 {
            font-style: normal;
            font-weight: 800;
            font-size: 28px;
            font-feature-settings:
              'pnum' on,
              'lnum' on;
            color: black;
            margin: 0;
          }

          .checked {
            height: 24px;
            width: 24px;
            margin-left: 12px;
          }
        }

        .category {
          font-weight: 600;
          font-size: 20px;
          font-feature-settings:
            'pnum' on,
            'lnum' on,
            'ss09' on;
          color: color(grey, text2);
        }
      }
    }

    .btn-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      column-gap: 17px;
    }

    .main-container {
      display: flex;
      column-gap: 34px;
      width: 100%;
      justify-content: center;

      .media-container {
        width: 301px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        .main {
          width: 301px;
          height: 301px;
          border-radius: 10px;
          overflow: hidden;
        }

        .pick-img-container {
        }
      }

      .desc-container {
        width: 470px;

        p {
          margin: 0;
        }

        h4 {
          font-style: normal;
          font-weight: 800;
          font-size: 22px;
          color: black;
          margin: 0;
        }

        .owner {
          margin: 8px 0 10px 0;
          display: block;
          font-weight: 700;
          font-size: 16px;
          color: color(grey, dark);

          a {
            color: color(grey, dark);
            text-decoration: none;
            transition: color 0.2s;

            &:hover {
              color: color(plum, dark);
            }
          }
        }

        .price {
          display: block;
          font-weight: 900;
          font-size: 18px;
          color: color(grey, dark);
          margin: 32px 0;

          &.locked {
            color: color(grey, text);

            i {
              margin-left: 10px;
            }
          }
        }

        .note {
          margin: 32px 0;
          font-weight: 600;
          font-size: 14px;
          color: color(grey, dark);
        }

        .desc-title {
          display: block;
          margin: 32px 0 10px 0;
          font-weight: 800;
          font-size: 14px;
          color: black;
        }

        p.desc {
          font-weight: 500;
          font-size: 14px;
          color: color(grey, base);
          text-shadow: 0 16px 24px rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .HubStoreDetailItemModal {
    .content {
      overflow: auto;
      row-gap: 16px;
      padding: unset;

      .header {
        padding: 42px 16px 0 16px;
      }

      .main-container {
        flex-wrap: wrap;
        row-gap: 24px;
        padding: 0 16px;
        width: auto;
        text-align: center;
      }

      .btn-container {
        flex-wrap: wrap;
        row-gap: 24px;
        padding: 0 16px;
        margin: 16px 0;
        width: auto;
        text-align: center;
      }
    }
  }
}
