@import '../../../styles/variables.scss';

.ChangeStripeAccount {
  width: 784px;
  height: 568px;
  display: flex;
  flex-direction: column;

  h2 {
    margin: 30px 0 13px 0;
    font-family: $raleway;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    padding: 0 48px;
    color: color(grey, dark);
  }

  &-list {
    display: flex;
    flex-direction: column;
    padding: 0 48px;
    flex-grow: 1;
    max-height: 430px;
    overflow-y: auto;
    overflow-x: hidden;
    @include thin-scroller-y;

    &--add {
      height: 78px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;
      border-bottom: 1px solid color(cream, border);
      padding: 0 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: color(grey, dark);
      font-family: 'Roboto';
      cursor: pointer;

      i {
        margin-right: 14px;
      }
    }

    &--item {
      padding: 0 24px;
      height: 78px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;
      border-bottom: 1px solid color(cream, border);
      cursor: pointer;

      .value {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: color(grey, dark);

        i {
          color: color(plum, dark);
          margin-right: 30px;
          font-size: 16px;
        }
      }

      .status {
        height: 30px;
        line-height: 30px;
        background: color(green, fade);
        border-radius: 8px;
        font-family: $lato;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: color(green, text);
        padding: 0 12px;
      }
    }
  }

  &-actions {
    flex-grow: 0;
    margin-bottom: 24px;
    display: flex;
    padding: 0 48px;
    justify-content: flex-end;

    .Button {
      margin-right: 16px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
