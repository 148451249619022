@import '../../../../styles/variables.scss';

.AuctionItemPayModal {
  .auction-payment {
    display: flex;
    flex: 1;

    .details {
      border-radius: $border-radius;
      background-color: color(light, secondaryBg);
    }

    .summary {
      margin-left: 20px;
      min-width: 320px;

      .Loader {
        display: flex;
        justify-content: center;
        flex: 1;
        padding: 20px;

        i {
          font-size: 24px;
        }
      }

      .summary-inner {
        border-radius: $border-radius;
        background-color: color(cream, dark);

        .title {
          padding: 30px 40px;
          font-size: 28px;
          font-weight: 700;
          text-align: center;
          border-bottom: 1px solid color(grey, light);
          margin: 0px 30px;
        }

        .sub-price {
          padding: 30px 40px 0px;
          display: flex;
          flex: 1;

          span {
            flex: 1;

            &:first-child {
              font-weight: 700;
            }

            &:last-child {
              text-align: right;
              color: color(grey);
            }
          }
        }

        .total {
          background-color: color(grey);
          margin: 20px 0px 0px;
          padding: 20px 40px;
          color: color(dark, fontColor);
          display: flex;
          flex: 1;
          font-size: 21px;
          font-weight: 700;

          span {
            flex: 1;

            &:last-child {
              text-align: right;
            }
          }
        }

        .actions {
          padding: 40px 40px 20px;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          display: flex;
          flex-direction: column-reverse;
          background-color: color(light, secondaryBg);

          .Button {
            flex: 1;
            margin-bottom: 20px;

            &:last-child {
              button {
                background-color: color(light, secondaryBg);
                color: color(grey);
                border: 2px solid color(grey, light);

                &:hover {
                  color: color(purple);
                  border: 2px solid color(purple);
                }
              }
            }

            button {
              flex: 1;
              width: 100%;
            }
          }
        }
      }
    }

    .details {
      flex: 1;
      min-width: 600px;
      max-width: 600px;
      max-height: 600px;
      overflow: auto;

      .title {
        padding: 20px 40px 0px;
        font-size: 28px;
        font-weight: 700;
        text-align: center;
      }

      .sub-title {
        text-align: center;
        color: color(grey);
        font-size: 14px;
        padding: 10px 40px 0px;
      }

      .item-details {
        margin-top: 30px;
        background-color: color(cream, dark);
        display: flex;
        padding: 20px;

        .image {
          max-width: 200px;
          min-width: 200px;

          img {
            width: 100%;
            height: auto;
            border-radius: 8px;
          }
        }

        .item-content {
          padding-left: 20px;
          position: relative;
          flex: 1;

          .item-title {
            font-weight: 700;
            font-size: 21px;
            padding-right: 80px;
          }

          .item-description {
            .ql-editor {
              padding: 20px 0px 10px;
              font-size: 14px;
              line-height: 21px;
              color: color(grey);
            }
          }

          .winner {
            position: absolute;
            top: 0px;
            right: 0px;
            background-color: color(green, light);
            color: color(green, dark);
            padding: 4px 12px;
            border-radius: 20px;
            border: 1px solid color(green, dark);
          }
        }
      }

      .form-title {
        font-size: 21px;
        margin: 40px 40px 20px;
        padding-bottom: 10px;
        color: color(purple, dark);
        border-bottom: 1px solid color(grey, light);
      }

      .sub-form-title {
        margin: 0px 40px 20px;
        padding: 0px;
        font-weight: 700;
        font-size: 14px;
        display: flex;
        align-items: center;

        &.click {
          color: color(grey);
          font-weight: 400;

          &:hover {
            cursor: pointer;
          }
        }

        i {
          margin-right: 5px;
          font-size: 17px;

          &.fa-check-circle {
            color: color(purple, dark);
          }
        }
      }

      .shipping-options {
        margin: 0px 40px 20px;
        flex: 1;

        .pickup-locations {
          margin-top: 15px;
        }

        .shipping-option {
          padding-bottom: 20px;
        }

        .ship-title {
          font-weight: 700;
        }

        .shipping-label {
          margin: 10px 0px 0px 22px;
          font-size: 16px;
          font-weight: 300;

          span {
            font-weight: 700;
            margin-right: 8px;
          }

          &:hover {
            cursor: pointer;
          }

          i {
            margin-right: 5px;

            &.fa-check-circle {
              color: color(purple, dark);
            }
          }
        }
      }

      .row {
        display: flex;
        flex: 1;
        margin: 0px 40px 20px;

        &.dbl {
          &.address {
            .TextField {
              width: 45%;
            }
          }
          .TextField {
            &:first-child {
              margin-right: 10px;
            }

            &:last-child {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 823px) {
  .AuctionItemPayModal {
    overflow: auto;
    background-color: color(light, primaryBg);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1) inset;

    .auction-payment {
      display: flex;
      flex-direction: column;
      overflow: auto;

      .details {
        border-radius: 0;
        min-width: 320px;
        overflow: unset;
        max-height: none;
        background: #eaeff0;

        .TextField {
          .input-wrap {
            background: #fff;
          }
        }

        .CreditCard-wrap {
          background: #fff;
        }

        .form-title {
          margin: 40px 15px 20px;
        }

        .mobile-wrap {
          background: #fff;
          border-radius: 8px;
          min-height: 150px;
          margin: 40px 15px;
          box-shadow: $box-shadow;
          .form-title {
            background: #f5f6f7;
            color: color(blue, dark);
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            margin: 0;
            padding-bottom: 0;
            border-bottom: 0;
            padding: 15px;
          }

          .shipping-options {
            margin-top: 15px;
          }
        }
        .row {
          margin: 0px 15px 20px;
          flex-direction: column;

          &.dbl {
            &.address {
              .TextField {
                width: 100%;
                margin: 10px 0;
              }
            }
          }
        }

        .row.dbl.info,
        .row.dbl.names {
          .TextField:first-child {
            margin-right: 0px;
          }
          .TextField:last-child {
            margin-left: 0px;
            margin-top: 20px;
          }
        }

        .item-details {
          padding: 15px;
          margin: 20px 15px;
          border-radius: 8px;
          max-height: 140px;
          box-shadow: $box-shadow;

          .image {
            min-width: 100px;
            max-width: 100px;
          }
          .item-content {
            padding-left: 10px;

            .item-title {
              font-size: 18px;
            }

            .item-description {
              display: none;
            }
          }
        }
      }

      .summary {
        margin-left: 0;

        .summary-inner {
          border-radius: 0;
          background-color: #fff;
        }
      }
    }

    .modal-close-circle {
      right: -50px;
      top: 10px;
      z-index: 2;
      color: #000 !important;
    }
  }
}
