@import '../../../styles/variables.scss';

.SocialImpactBenefitsComponent {
  display: flex;
  flex-flow: row wrap;
  list-style-type: none;
  margin: 0;
  margin-top: 30px;
  padding: 0;
  column-gap: 33px;
  row-gap: 80px;

  .list-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-width: 350px;
    max-width: 350px;

    .icon {
      margin-right: 40px;
    }

    .description-container {
      display: flex;
      flex-flow: column nowrap;

      .title {
        font-size: 16px;
        font-weight: 700;
        color: color(grey, dark);
        margin-bottom: 6px;
      }

      .description {
        font-size: 14px;
        font-weight: 500;
        color: color(grey, light);
      }
    }
  }

  .empty-state {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    flex: 1;

    .link {
      color: color(grey, text);
      text-decoration: underline;
      font-weight: 600;
      font-size: 16px;
      margin-top: 8px;

      &:hover {
        cursor: pointer;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: color(grey, base4);
      border-radius: 50%;
      height: 150px;
      width: 150px;
      margin-bottom: 32px;
    }

    .title {
      color: color(grey, dark);
      font-size: 16px;
      font-weight: 900;
    }
  }
}

@media (max-width: 650px) {
  .SocialImpactBenefitsComponent {
    justify-content: center;
    row-gap: 0;
  }
}
