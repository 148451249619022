@import '../../../../../styles/variables.scss';

.PopupMenu.community-feed-donation-popupMenu-filter-by {
  min-width: 217px;
}

.CommunityFeedDonationModal {
  .content {
    width: 911px;
    height: 563px;
    padding: 48px;
    display: flex;

    .ModalHeader {
      .header-container {
        padding: 0;

        .header-title {
          text-align: left;
        }
      }
    }

    .top-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 22px 0;

      .switch-btn-container {
        display: flex;
        border-radius: 8px;
        overflow: hidden;

        span {
          padding: 0 20px;
          transition: all 0.2s;
          height: 40px;
          line-height: 40px;
          text-align: center;
          display: block;
          color: color(grey, dark);
          background-color: color(grey, pale);
          cursor: pointer;

          &.active {
            color: color(light, secondaryBg);
            background-color: color(plum, dark);

            &:hover {
              color: color(light, secondaryBg);
              background-color: color(plum, dark);
            }
          }

          &:hover {
            color: color(light, secondaryBg);
            background-color: color(purple, dark);
          }
        }
      }

      .count {
        font-weight: 700;
        font-size: 14px;
        color: color(grey, light);
      }
    }

    .search-container {
      display: flex;
      align-items: flex-end;
      column-gap: 12px;

      .filter-dropdown {
        min-width: 205px;

        .input-wrap {
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 700;
          font-size: 16px;
          padding: 0 23px 0 16px;
          border: 1px solid color(blue, lighter);

          .menu-btn {
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease-in-out;

            &.show {
              transform: rotate(180deg);
            }
          }

          .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            color: color(grey, dark);
          }

          &:hover {
            cursor: pointer;
          }
        }

        label {
          z-index: 1;
          bottom: -11px;
          left: 9px;
          background-color: white;
          font-weight: normal;
          position: relative;
          padding: 5px;
          width: fit-content;
          color: color(grey, dark);
        }

        &:hover {
          cursor: pointer;
        }
      }

      .TextField {
        height: 44px;
        flex-grow: 1;

        .input-wrap {
          border-radius: 10px;
          height: 44px;
          border: 1px solid color(blue, lighter);
          background-color: color(light, secondaryBg);

          i {
            height: 44px;
            color: color(grey, light);
          }

          input {
            line-height: 44px;
            height: 44px;
            background-color: color(light, secondaryBg);
          }
        }
      }

      .Button {
        button {
          background: color(otherblue, link);
          color: color(light, secondaryBg);
          border: 1px solid color(otherblue, link);
          transition: all 0.2s;

          &:hover {
            background: color(light, secondaryBg);
            color: color(otherblue, link);
          }
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding: 24px 2px;
      overflow-y: auto;
      max-height: 269px;

      @include thin-scroller-y;

      .list-item-container {
        text-decoration: none;

        .item {
          padding: 0 28px 0 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 16px;
          height: 80px;
          background: color(light, secondaryBg);
          border: 2px solid color(light, shadow);
          box-sizing: border-box;
          border-radius: 10px;
          transition: all 0.2s;

          &:hover {
            cursor: pointer;
            border: 2px solid color(plum, dark);
          }

          img {
            flex-grow: 0;
            border: 1px solid color(grey, border);
            box-sizing: border-box;
            border-radius: 20px;
            overflow: hidden;
          }

          .info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            row-gap: 4px;

            .title {
              font-weight: 800;
              font-size: 16px;
              color: color(grey, dark);
            }

            .charity-id,
            .group-name {
              font-weight: 500;
              font-size: 12px;
              color: color(grey, text3);
            }
          }

          .load-more {
            place-self: center;
          }
        }
      }
    }

    .page-indicator {
      color: color(plum, dark);
      background-color: color(light, secondaryBg);

      &.current {
        background: color(plum, dark) 0 0 no-repeat padding-box;
        color: color(light, secondaryBg);
      }
    }
  }

  .Loader {
    display: flex;
    align-items: center;
    justify-content: center;
    place-self: center;
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      text-align: center;
    }
  }
}

@media (max-width: 1070px) {
  .CommunityFeedDonationModal {
    .content {
      width: auto;
      height: 100%;
      padding: 0 16px;

      .close-button {
        display: none;
      }

      .search-container {
        flex-wrap: wrap;

        .Dropdown {
          width: 100%;
        }
      }

      .list {
        max-height: calc(100vh - 460px);
        padding: 24px 8px;
      }
    }
  }
}
