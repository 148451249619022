@import '../../../styles/variables.scss';

.BackSplash {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 440px;
  background-color: color(light, tertiaryBg);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .CoverImage {
    filter: blur(40px);
    transform: scale(1.3);
    width: 100%;
    height: auto;
  }

  .curve {
    background-color: color(light, shadow);
  }

  .floating-actions {
    display: none;
  }
}

@media (max-width: 1240px) {
  .BackSplash {
    height: 250px;

    .CoverImage {
      filter: none;
      transform: none;
      border-radius: 0px;
      height: inherit;
      background-color: #d6d6d6;

      .overlay {
        background-color: transparent;
      }
    }

    .curve {
      pointer-events: none;
    }

    .floating-actions {
      display: flex;
    }

    .mobile-context {
      display: block;
      position: absolute;
      top: 10px;
      right: 20px;
      z-index: 2;

      .fa-ellipsis-h-alt {
        color: color(dark, fontColor);
        font-size: 30px;
        text-shadow: 1px 1px 10px color(light, fontColor);
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        &.active {
          background-color: color(light, secondaryBg);
          text-shadow: none;
          color: color(purple, dark);
        }
      }

      .ContextMenu {
        position: absolute;
        top: 55px;
        right: -0px;

        i {
          font-size: 14px;
          margin-right: 10px;
        }

        &:before {
          right: 15px;
        }

        &:after {
          content: none;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .BackSplash {
    height: 225px;
  }
}

@media (max-width: 530px) {
  .BackSplash {
    height: 210px;

    .CoverImage {
      height: 100%;
      width: auto;

      .overlay {
        background-color: transparent;
      }
    }
  }
}
