@import '../../styles/variables.scss';

.hub-container {
  &.DISCOVER {
    margin-top: 45px;
  }

  &.LIST {
    &:not(:first-child) {
      padding-top: 42px;
    }
    &:hover {
      box-shadow: none;
    }

    width: auto !important;
    height: 129px !important;
    display: flex;
    flex-direction: row;
    align-items: start;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 0px;
    border-radius: 0px;
    box-shadow: none;
    padding-left: 0px;

    .card-header {
      flex-grow: 0;
      width: 185px;
      height: 81px;
      border-radius: 10px;
      margin-top: 24px;

      .profile-img {
        width: 30px;
        height: 30px;
        top: 12px;
        left: 12px;
      }
    }

    .card-content {
      padding-top: 0px;
      margin-top: 24px;
      .state-label {
        position: absolute !important;
        right: 24px;
        bottom: 24px;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }

      .header-menu {
        right: 60px;
      }

      .menu {
        top: 24px;
        right: 0px;
      }

      .one-line {
        border-bottom: 1px solid #ededec;
        width: 100%;
        position: absolute;
        top: 99%;
      }
    }
  }

  &.MANAGE {
    height: 281px;
    width: 320px;

    &.GRID {
      margin-bottom: 32px;
    }

    .card-header {
      .no-cover {
        background-image: url('../../assets/no-cover-image-manage-hubs.svg') !important;
      }
    }

    .hub-content {
      position: initial;
      padding-left: 24px;

      .hub-causes {
        right: 0;
        top: 24px;
      }

      .created-date {
        font-size: 12px;
        font-weight: 600;
        font-family: $lato;
        color: #6f7072;
      }

      .state-label {
        position: relative;
        border-radius: 8px;
        margin-top: 24px;
        margin-bottom: 24px;
        padding: 6px 12px;
        font-weight: 700;
        text-align: center;
        font-size: 14px;
        cursor: default;
        width: fit-content;

        &.active,
        &.running {
          background-color: #eef9f7;
          color: #57c0ac;
        }

        &.published {
          background-color: #eaeaf8;
          color: #6565d3;
        }

        &.draft,
        &.pending {
          background-color: #fef8ed;
          color: #f1b94d;
        }

        &.undefined {
          display: none;
        }
        //any unknown states

        background-color: color(light, primaryBg);
        color: color(grey, base);
      }
    }

    .menu {
      border-radius: 50%;
      cursor: pointer;
      margin: 0;
      flex-grow: 0;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.2s;
      position: absolute;
      right: 0;
      top: 163px;
      margin-right: 24px;

      &:hover {
        background: color(grey, pale);
      }

      i {
        font-size: 16px;
        font-weight: normal;
      }
    }

    .header-menu {
      position: absolute;
      top: 24px;
      right: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        &:nth-of-type(1) {
          margin-right: 8px;
        }

        img {
          cursor: pointer;
        }

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }

  .hub-content {
    .card-title {
      display: flex;

      a {
        text-decoration: none;
        font-family: $raleway;
        margin-bottom: 2px;
        margin-right: 10px;
        font-weight: 800;
        font-size: 16px;
        color: color(grey, dark);
        transition: color 0.2s;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 300px;

        &:hover {
          color: color(plum, dark);
        }
      }
    }

    p {
      &:first-of-type {
        font-size: 12px;
        font-weight: 600;
        font-family: $lato;
        color: #6f7072;
        margin-top: 0px;
        margin-bottom: 20px;
      }
    }

    .hub-causes {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 90px;

      .title {
        color: color(purple, grey);
        font-family: $lato;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
      }

      .causes-container {
        display: flex;
        flex-direction: row;
        margin-right: 10px;
        margin-left: 10px;

        &:nth-child(1) {
          z-index: 1;
        }
        &:nth-child(2) {
          z-index: 2;
        }
        &:nth-child(3) {
          z-index: 3;
        }
        &:nth-child(4) {
          z-index: 4;
        }

        .Portrait,
        .empty-portrait {
          margin-left: -10px;
        }

        .empty-portrait {
          background: #ffffff;
          border: 2px solid #f5f5f5;
          box-sizing: border-box;
          width: 34px;
          height: 34px;
          border-radius: 15px;
          display: flex;
          align-items: center;
          font-family: $lato;
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          justify-content: center;
          color: color(grey, light);
          z-index: 5;
        }

        .no-causes {
          border-radius: 50%;
          width: 30px;
          height: 30px;
          background-color: #e9e9e8;
          margin-left: -10px;
          border: 2px solid #f5f5f5;

          &:nth-of-type(1) {
            z-index: 1;
          }
          &:nth-of-type(2) {
            z-index: 2;
          }
          &:nth-of-type(3) {
            z-index: 3;
          }
          &:nth-of-type(4) {
            z-index: 4;
          }
          &:nth-of-type(5) {
            z-index: 5;
          }
        }
      }
    }
  }

  .hub-actions {
    .fa-level-down-alt {
      transform: rotate(-90deg);
    }
    &.fr,
    &.es {
      width: fit-content;
    }
  }
}
