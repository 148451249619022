@import '../../../../../styles/variables.scss';

.CommunityFeedAsideCard {
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: flex-start;
  justify-content: space-evenly;
  background: color(light, secondaryBg);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 8px;
  width: 320px;
  // margin-top: 16px;
  transition: all 0.2s;
  margin-bottom: 20px;

  .Loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .empty-state {
    margin-top: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 8px;
    align-items: center;
    justify-content: center;
    cursor: default;

    .title {
      text-align: center;
      color: color(grey, dark);
      font-weight: 700;
      font-size: 14px;
    }

    .subtitle {
      text-align: center;
      color: color(grey, light);
      font-weight: 700;
      font-size: 14px;
    }

    .action {
      text-align: center;
      color: color(grey, light);
      text-decoration: underline;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }

    i {
      color: color(grey, light);
      font-size: 20px;
      margin: 20px;
      padding: 20px;
      background-color: color(grey, lightester);
      border-radius: 50%;
    }
  }

  .title {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    font-feature-settings:
      'pnum' on,
      'lnum' on,
      'salt' on,
      'ss09' on;
    color: color(grey, dark);
    margin: 0 0 8px 0;
  }

  .content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .invite-counter {
      position: absolute;
      right: 24px;
      top: 24px;
      background: red;
      color: #fff;
      width: 20px;
      height: 20px;
      font-size: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
    }

    .feed-invites {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 70px;
      align-items: center;

      &.recommended {
        margin: 10px 0;

        .invite-title {
          align-items: center;

          span:hover {
            cursor: pointer;
            color: color(purple, base);
          }
        }
      }

      &.applications {
        margin-bottom: 10px;
        margin-top: 10px;
        align-items: flex-start;
      }

      .invite-img {
        background: color(purple, base);
        width: 40px;
        height: 40px;
        border-radius: 20px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
      }

      .invite-title {
        display: flex;
        align-items: flex-start;
        font-weight: 800;
        font-size: 14px;
        color: color(grey, dark);

        .tag {
          height: 30px;
          border-radius: 8px;
          display: block;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 30px;
          text-align: center;
          padding: 3px 12px;
          margin-right: 6px;
          margin-top: 10px;

          &.default {
            background: color(grey, pale);
            color: color(dark, border);
          }

          &.approved {
            background: color(green, fade);
            color: color(green, text);
          }

          &.pending {
            background: color(yellow, fade);
            color: color(yellow, text);
          }

          &.rejected,
          &.cancelled {
            background: color(red, fade);
            color: color(red, soft);
          }
        }

        &.shift {
          flex-direction: column;
          width: calc(100% - 89px);
        }

        .shift-details {
          font-weight: 600;
          font-size: 12px;
          color: color(grey, light);
        }

        span {
          flex: 1 1;
        }

        span:not(.tag) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          display: inline-block;
          max-width: 181px;

          a {
            color: color(grey, dark);
            text-decoration: none;
            transition: all 0.2s;

            &:hover {
              color: color(purple, dark);
            }
          }
        }
      }
    }

    .invite-actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      column-gap: 16px;
      margin-top: 24px;

      .Button {
        flex-grow: 1;

        &.log-button {
          width: 100%;

          button {
            width: 100%;
          }
        }

        button {
          max-width: unset;
          width: 100%;
          padding: 10px 24px;
        }
      }
    }
  }

  .view-more {
    margin-top: 11px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings:
      'pnum' on,
      'lnum' on,
      'salt' on,
      'ss09' on;
    color: color(purple, dark);

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
