@import '../../../styles/variables.scss';

.Sponsors {
  display: block;
  padding: 0 20px;

  .title {
    h3 {
      margin: 0;
    }
  }

  .tier {
    border: 1px solid color(light, secondaryBg);
    position: relative;
    border-bottom: 1px solid #eaeff0;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;

    .tier-title {
      color: color(light, fontColor);
      font-size: 20px;
      font-weight: bold;
    }

    .component-sponsors-box {
      display: flex;
      justify-content: space-around;
    }

    .carousel-container {
      display: flex;
      flex-direction: row;
      justify-self: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;

      .notch-empty {
        //border: 1px solid #88949A;
        background-color: #cad5d9;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        cursor: pointer;
        margin-left: 10px;
      }

      .notch-filled {
        //border: 1px solid #88949A;
        background-color: color(purple, dark);
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-left: 10px;
      }
    }

    .sponsor {
      display: flex;
      border: 1px solid color(light, secondaryBg);
      position: relative;
      padding: 10px;

      &.admin {
        &:hover {
          border: 1px solid color(purple);

          .list-admin-actions {
            display: block;
          }
        }
      }

      .Portrait {
        .inner {
          border: 3px solid #eaeff0;
        }
      }

      &.vertical {
        flex-direction: column;

        .Portrait {
          align-self: center;
        }

        .info {
          margin-top: 10px;

          .title {
            text-align: center;
          }

          .link {
            text-align: center;
          }
        }
      }

      &.horizontal {
        flex-direction: row;
        align-self: center;

        .info {
          align-self: center;
          margin-left: 10px;
        }
      }

      .info {
        display: flex;
        flex-direction: column;

        .title {
          color: color(light, fontColor);
          font-size: 17px;
        }

        .link {
          margin-top: 10px;
          font-weight: 400;
          padding-top: 5px;
          font-size: 14px;
          text-align: center;
          color: color(grey);
          text-decoration: underline;
          margin-top: 0px;

          &:hover {
            color: color(purple, dark);
          }
        }
      }
    }
  }

  .list-admin-actions {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: color(purple);
    height: 30px;
    line-height: 30px;

    ul {
      display: flex;
      list-style-type: none;
      padding: 0px;
      margin: 0px;

      li {
        color: color(dark, fontColor);
        padding: 0px 10px;

        &:hover {
          cursor: pointer;
          background-color: color(light, secondaryBg);

          i,
          span {
            color: color(purple);
          }
        }

        span {
          margin-left: 8px;
        }
      }
    }
  }

  .create-modal {
    background-color: color(light, secondaryBg);
    max-width: 500px;
    min-width: 500px;
    padding: 15px 20px 20px;
    border-radius: $border-radius;

    .row {
      margin-bottom: 10px;

      .time-fields {
        display: flex;

        .TextField,
        .Dropdown {
          flex: 1;
        }

        .TextField {
          margin-right: 5px;
        }

        & > div {
          flex: 1;
        }

        & > div:last-of-type {
          margin-right: 0px;
        }
      }

      &.hide {
        display: none;
      }
      .sub-title {
        padding-bottom: 10px;
        color: color(purple, dark);
        border-bottom: 1px solid color(grey, light);
        font-size: 22px;
      }

      .campaign-selection-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        div:first-of-type {
          margin-right: 10px;
        }

        div:last-of-type {
          margin-left: 10px;
        }

        div {
          cursor: pointer;
          flex: 1;
          border-radius: 5px;
          color: color(grey, light);
          border: 1px solid color(grey, light);
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;

          &.active {
            color: color(light, fontColor);
            border: 2px solid color(purple, dark);
          }
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }

      .label {
        font-size: 13px;
        margin-bottom: 4px;
        font-weight: 700;
      }

      &.options-row {
        margin-bottom: 15px;
      }

      .options {
        display: flex;

        .option {
          margin-right: 20px;
          height: 40px;
          line-height: 40px;

          &:hover {
            cursor: pointer;
          }

          i {
            margin-right: 6px;
            color: color(purple, dark);
          }

          span {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }

    .form-actions {
      display: flex;
      justify-content: center;
    }

    &.create-media-album {
      .form-actions {
        justify-content: space-between;
      }
    }
  }

  .edit-sponsorship-modal {
    background-color: color(light, secondaryBg);
    max-width: 500px;
    min-width: 500px;
    min-height: 500px;
    padding: 20px 20px 20px;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;

    .row {
      margin-bottom: 20px;
      width: auto;
    }

    .image-label {
      font-size: 13px;
      font-weight: 700;
    }

    .form-actions {
      display: flex;
      justify-content: center;
      margin-top: auto;

      .cancel-button {
        background-color: #ffffff;
        color: #52646c;
        box-shadow: 0px 3px 6px #7c7c7c41;
        border: 1px solid #e3e3e3;
        border-radius: 23px;

        &:hover {
          background-color: color(light, tertiaryBg);
          color: color(light, fontColor);
        }
      }

      .save-button {
        margin-left: 25px;
      }
    }
  }

  .tier-visibility-modal {
    max-width: 600px;
    min-width: 600px;

    background-color: color(light, secondaryBg);
    max-width: 500px;
    min-width: 500px;
    padding: 15px 20px 20px;
    border-radius: $border-radius;

    .table {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-template-rows: 40px 70px;
      margin: 0 50px 0 50px;

      overflow-y: auto;
      height: 400px;
    }

    .header {
      background-color: #b6c5cb;
      line-height: 40px;
      font-size: 16px;
      color: color(light, fontColor);
      font-weight: bold;
    }

    .item {
      border-bottom: 1px solid #b3c6cb;
      line-height: 70px;
      margin-top: 0;
      margin-bottom: 0;
    }

    .col0 {
      text-align: start;
      padding-left: 20px;
    }

    .col1 {
      text-align: center;
    }

    .col2 {
      text-align: end;
      padding-right: 20px;
    }

    /* TODO: Turn toggle into separate component? */
    .toggle {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
      align-self: center;
      margin-left: auto;
    }

    .toggle input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }

    input:checked + .slider {
      background-color: color(purple, dark);
    }

    input:focus + .slider {
      box-shadow: 0 0 1px color(purple, dark);
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    .toggle-container {
      padding: 0;

      .visibility {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-size: 31px;
        font-weight: 900;
        cursor: pointer;

        .fa-toggle-on {
          color: color(purple, dark);
        }
      }
    }

    .form-actions {
      display: flex;
      justify-content: center;
      margin-top: 70px;

      .cancel-button {
        button {
          border: 1px solid #b6c5cb;
          background-color: #ffffff;
          color: #52646c;
        }
      }

      .save-button {
        margin-left: 25px;
      }
    }
  }
}
