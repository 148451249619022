@import '../../../../styles/variables.scss';

.ProgramDonations {
  display: flex;
  flex-flow: column nowrap;
  padding: 50px;
  height: 100%;
  gap: 30px;

  .header-section {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    .header-actions {
      display: flex;
      flex-flow: row nowrap;
      gap: 15px;
      align-items: center;

      .DatePickerFieldContainer {
        max-width: 300px;
      }

      .Button {
        margin-top: 15px;
      }
      .export-button {
        margin-left: auto;
      }
    }
  }

  .TextField label span {
    color: color(light, fontColor);
  }

  .table-section {
    min-height: 400px;

    .TableComponent {
      .item-name-col {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 15px;
        min-height: 60px;

        .image-container {
          object-fit: cover;
          border-radius: 50%;
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}
