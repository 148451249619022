@import '../../styles/variables.scss';

body.company {
  .Header {
    display: none;
  }
}

.Hub {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin-top: 80px;

  .hub-loader-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    background-color: white;
    z-index: 100000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    padding-top: 270px;
    transition: opacity linear 0.2s;

    &.show {
      opacity: 1;
    }

    &.remove-from-dom {
      display: none;
    }
  }

  .edit-overlay {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    background: rgba(94, 150, 235, 0.1);
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    button {
      width: 44px;
      height: 44px;
      border-radius: 50px;
      background: color(otherblue, mid);
      color: color(light, secondaryBg);
      padding: 0;
      margin: 18px 9px 0 9px;
      min-height: unset;
      min-width: unset;
      place-items: center;

      span {
        margin: 0;
      }
    }

    .custom-actions-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .hub-header {
    position: relative;

    .test {
      &.fixed {
        padding-top: 94px;
      }
    }

    .edit-container {
      width: 100%;
      background: color(otherblue, mid);
      height: 0;
      overflow: hidden;
      transition: all 0.2s;
      color: color(light, secondaryBg);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      top: 0;
      position: fixed;
      z-index: 15;

      &.active {
        height: 80px;
      }

      .edit-title {
        margin-left: 40px;
      }

      .actions-container {
        margin-right: 40px;
        display: flex;
        flex-direction: row;

        button {
          border: 1px solid color(light, secondaryBg);
          color: color(light, secondaryBg);
          background-color: color(otherblue, mid);
          box-sizing: border-box;
          border-radius: 50px;
          margin-right: 16px;
          transition: all 0.2s;

          i {
            margin-right: 14px;
          }

          &:hover {
            color: color(otherblue, mid);
            background-color: color(light, secondaryBg);
          }
        }
      }
    }

    .main-image-container {
      .inner-header-content {
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: 1200px;
        max-height: 1200px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: color(light, secondaryBg);

        &.left {
          align-items: flex-start;

          .desc {
            justify-content: flex-start;
          }

          h1,
          p {
            text-align: left;
          }

          .btn-container {
            justify-content: flex-start;
          }
        }

        &.center {
          align-items: center;

          .desc {
            justify-content: center;
            word-wrap: break-word;
          }

          h1,
          p {
            text-align: center;
          }

          .btn-container {
            justify-content: center;
          }
        }

        &.right {
          align-items: flex-end;

          .desc {
            justify-content: flex-end;
          }

          h1,
          p {
            text-align: right;
          }

          .btn-container {
            justify-content: flex-end;
          }
        }
      }
    }

    .main-image-container {
      &.editable {
        position: relative;

        &:hover {
          border: 2px solid rgba(0, 102, 255, 1);
          box-sizing: border-box;

          & > .edit-overlay {
            display: flex;
          }
        }
      }
    }

    .hub-title-container {
      height: 80px;
      padding-left: 160px;
      padding-right: 20px;
      background-color: color(light, secondaryBg);
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      z-index: 13;

      .user-search-container {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        padding: 0px 20px;
      }

      .detail-container {
        display: flex;
        flex-flow: row nowrap;
        color: black;
        margin-right: auto;
        max-width: 400px;

        .top {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          flex-direction: column;

          h1 {
            margin: 0;
            font-weight: 800;
            font-size: 26px;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 400px;
            overflow: hidden;
          }
        }

        .bottom {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          color: black;
          padding-top: 5px;
          align-items: flex-start;

          .hub-context-menu-container {
            display: none;
          }

          .checked {
            margin: 0 15px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .live {
            flex-grow: 1;
            font-weight: 700;
            font-size: 18px;
            font-feature-settings:
              'pnum' on,
              'lnum' on,
              'salt' on,
              'ss09' on;

            i {
              display: inline-block;
              width: 14px;
              height: 14px;
              background: color(red, soft);
              border: 1px solid color(light, secondaryBg);
              box-sizing: border-box;
              margin-right: 9px;
              border-radius: 24px;
            }
          }
        }

        .hub-handle {
          display: flex;
          flex-flow: row nowrap;
          gap: 10px;
          font-weight: 500;
          font-size: 14px;
          font-feature-settings:
            'pnum' on,
            'lnum' on;
          color: color(dark, border);

          .follow-link {
            &:hover {
              cursor: pointer;
            }
          }

          .Dropdown {
            .input-wrap {
              display: flex;
              min-width: 60px;
              flex-direction: row;
              justify-content: space-between;
              border: none;

              .label {
                // TODO MAKE HUB STATUS VARS
                &.active {
                  color: #57c0ac;
                }
                &.draft {
                  color: #f1b94d;
                }
                &.archived {
                  color: #5e96eb;
                }
                text-transform: capitalize;
                margin-right: 5px;
              }

              i {
                padding-top: 3px;
              }
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .actions-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .cart {
          margin: 0 18px 0 30px;
        }

        button,
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 12px;
          transition: all 0.2s;
          height: 44px;
          line-height: 44px;
          flex-direction: row;
          padding: 13px 15px;
          background: color(light, secondaryBg);
          border: 1px solid color(plum, dark);
          box-sizing: border-box;
          border-radius: 50px;
          font-weight: 700;
          font-size: 14px;
          text-align: center;
          text-decoration: none;
          color: color(grey, dark);
          cursor: pointer;

          i {
            margin-right: 10px;
          }

          &:hover {
            background: color(grey, dark);
            color: color(light, secondaryBg);
          }
        }
      }
    }

    .hub-menu {
      height: 94px;
      display: flex;
      align-items: center;
      width: 100%;
      background: color(grey, imgBg);
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.16);
      position: relative;
      transition: top 0.3s ease-in;
      z-index: 14;

      &.fixed-logo {
        .hub-logo-container {
          top: 15px;
          width: 60px;
          height: 60px;

          .inner {
            height: 60px !important;
            width: 60px !important;
          }

          img {
            width: 60px !important;
            height: 60px !important;
          }
        }
      }
      &.fixed-header {
        position: fixed;
        top: 0;

        &.slide-out {
          top: 80px;
        }

        &.edit-mode {
          top: 80px;
        }
      }

      &.is-showing-user-menu {
        z-index: inherit;
      }

      .hub-logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        transition:
          height 0.4s,
          width 0.4s,
          top 0.4s;
        position: absolute;
        background: color(grey, pale);
        top: -78px;
        left: 38px;
        border: 2px solid color(light, secondaryBg);
        box-sizing: border-box;
        border-radius: 50%;
        width: 94px;
        height: 94px;
        overflow: hidden;
        z-index: 14;

        img {
          transition:
            height 0.4s,
            width 0.4s;
          filter: drop-shadow(0 8px 16px rgba(0, 0, 0, 0.12));
          background: color(grey, pale);
          width: 94px;
          height: 94px;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        padding-left: 160px;
        height: 100%;
        display: flex;
        align-items: center;

        li {
          float: left;
          padding: 0;
          margin: 0 45px 0 0;
          color: rgba(243, 241, 236, 0.7);
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          transition: all 0.2s;
          font-feature-settings:
            'pnum' on,
            'lnum' on,
            'salt' on,
            'ss09' on;
          opacity: 0.7;

          &.active,
          &:hover {
            cursor: pointer;
            opacity: 1;
            // font-size: 18px;
            color: color(light, secondaryBg);
          }
        }
      }
    }
  }

  .main-image-container {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 450px;
    max-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: color(light, secondaryBg);
    transition: max-height 0.3s;
    overflow: hidden;

    .inner-header-content {
      transition:
        opacity 0.3s linear,
        max-height 0.3s linear;
      opacity: 1;
    }

    &.shrink {
      max-height: 100px;

      .inner-header-content {
        opacity: 0;
        max-height: 0px;
      }
    }

    &.fixed {
      margin-top: 94px;
    }

    h1 {
      margin: 0;
      font-weight: 800;
      font-size: 36px;
      line-height: 48px;
      text-align: center;
    }

    .desc {
      margin-top: 18px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        max-width: 625px;
        margin: 0;
        width: 100%;
        text-align: center;
      }
    }

    a {
      font-weight: bold;
      text-decoration: none;
      color: color(light, secondaryBg);
      transition: all 0.2s;

      &:hover {
        color: color(red, primaryBg);
      }
    }

    .btn-container {
      margin-top: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      column-gap: 18px;

      .header-button {
        transition: all 0.2s;
        width: 126px;
        height: 44px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 28px 13px;
        background: color(light, secondaryBg);
        border-radius: 22px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: color(grey, dark);

        &:hover {
          cursor: pointer;
          box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
        }

        &.fr,
        &.es {
          height: 60px;
          align-items: center;
        }
      }
    }
  }

  .main-hub-content-container {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
  }

  .hub-footer {
    background-color: color(light, secondaryBg);
    height: 100px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: solid 1px rgba(255, 255, 255, 0.2);
    padding: 0 40px;
    color: color(grey, dark);
    font-weight: 600;
    font-size: 14px;

    .made-by {
      i {
        margin: 0 5px;
      }
    }

    .social-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      a {
        margin-right: 34px;
        color: white;
        font-size: 16px;

        i {
          &:last-of-type {
            margin: unset;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .Hub {
    .hub-header {
      .main-image-container {
        .inner-header-content {
          // width: calc(100% - 64px);
        }
      }
    }
  }
}

@media (max-width: 1285px) {
  .Hub {
    .hub-header {
      .hub-title-container {
        .detail-container {
          max-width: 340px;
          .top {
            h1 {
              max-width: 340px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1215px) {
  .Hub {
    .hub-header {
      .hub-title-container {
        .detail-container {
          max-width: 300px;
          .top {
            h1 {
              max-width: 300px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1175px) {
  .Hub {
    .hub-header {
      .hub-title-container {
        .detail-container {
          max-width: 275px;
          .top {
            h1 {
              max-width: 275px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1070px) {
  .Hub {
    .hub-header {
      .edit-container.active {
        top: 0;
        flex-direction: column;
        height: max-content;
        row-gap: 15px;
        padding: 10px 0;
        z-index: 8;

        span {
          margin: 0;
        }

        .actions-container {
          align-items: center;
          justify-content: center;
          flex-direction: column;
          row-gap: 15px;
          margin: 0;

          .Button {
            width: 100%;

            button {
              width: 100%;
            }
          }
        }
      }

      .hub-title-container {
        // height: 100px;
        padding-left: 102px;
        padding-right: 10px;

        .right-header-container {
          width: unset;
        }

        &.editable {
          margin-top: 98px;
        }

        .actions-container {
          display: none;
        }

        .user-search-container {
          display: none;
        }

        .detail-container {
          flex-direction: column;
          width: 100%;
          max-width: unset;

          .top {
            margin-bottom: 14px;

            h1 {
              font-size: 18px;
              margin-bottom: 6px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          .bottom {
            justify-content: flex-end;
            align-items: unset;
            padding-top: unset;

            .hub-context-menu-container {
              display: flex;
              align-items: center;
            }

            .checked {
              margin: 0 15px 0 0;
            }
          }
        }
      }

      .hub-menu {
        height: 94px;
        padding-left: unset;

        &.fixed-header {
          top: 0;

          &.edit-mode {
            top: 198px;
          }
        }

        &.fixed-logo {
          ul {
            padding-left: 112px;
          }

          .hub-logo-container {
            height: 60px;
            width: 60px;
            top: 15px;
          }
        }

        .hub-logo-container {
          width: 60px;
          height: 60px;
          border: 2px solid color(light, secondaryBg);
          box-sizing: border-box;
          border-radius: 106.145px;
          left: 20px;
          top: -75px;

          img {
            width: 60px;
            height: 60px;
          }
        }

        ul {
          transition: all 0.2s;
          display: flex;
          overflow: auto;
          align-items: center;
          height: 100%;
          padding-left: 32px;

          li {
            white-space: nowrap;
          }
        }
      }
    }

    .main-image-container {
      &.fixed {
        margin-top: 0;
      }

      h1 {
        margin: 0 7px 23px 7px;
      }

      p {
        max-width: 356px;
      }
    }

    .hub-footer {
      padding: 50px 0;

      div {
        margin-top: 36px;
      }

      span,
      div {
        width: 100%;
      }
    }
  }
}

@media (max-width: 680px) {
  .Hub {
    .hub-header {
      .inner-header-content {
        .btn-container {
          flex-wrap: wrap;
          row-gap: 18px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .Hub {
    .hub-header {
      .hub-title-container {
        .detail-container {
          .top {
            .hub-handle {
              width: 100%;
            }
            .follow-link {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .Hub {
    .hub-header {
      .hub-title-container {
        .detail-container {
          max-width: 250px;

          .top {
            h1 {
              max-width: 250px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 385px) {
  .Hub {
    .hub-header {
      .hub-title-container {
        .detail-container {
          max-width: 180px;

          .top {
            h1 {
              max-width: 180px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .Hub {
    .hub-header {
      .hub-title-container {
        .detail-container {
          .top {
            span {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 300px) {
  .Hub {
    .hub-header {
      .hub-title-container {
        .detail-container {
          max-width: 100px;
          .top {
            h1 {
              max-width: 100px;
            }
          }
        }
      }
    }
  }
}
