@import '../../../../styles/variables.scss';

.ToggleSwitch {
  label {
    display: flex;
    flex: 1;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 4px;
  }

  .options-container {
    display: flex;
    gap: 10px;

    .option {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      text-align: center;

      border: 2px solid color(light, shadow);
      border-radius: 5px;
      padding: 10px;
      color: #88949a;
      font-weight: 700;

      &.active {
        border: 2px solid color(plum, dark);
        color: color(light, fontColor);
        box-shadow: $box-shadow;
      }

      &:hover {
        cursor: pointer;
        color: #757f84;
        border: 2px solid #757f84;
      }
    }
  }
}
