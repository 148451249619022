@import '../../styles/variables.scss';
.CreateAnEvent {
  display: flex;
  flex: 1;
  position: relative;
  box-sizing: border-box;

  .form-page {
    .TextField {
      position: relative;

      .input-wrap {
        p {
          color: color(grey, text);
          font-size: 12px;
          position: absolute;
          bottom: -35px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .CreateAnEvent {
    flex-direction: column;
    height: 100vh;
    overflow: hidden;

    .FormSection {
      min-height: unset;
      padding-bottom: 90px;

      .form-page {
        max-height: calc(100vh - 340px);

        .GroupSelection {
          #scrollableDiv {
            max-height: unset;
            overflow-y: unset;

            .filter-wrapper {
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
}
