.ContactPersonalInfo {
  height: 100%;
  max-height: 700px;
  overflow-y: auto;

  .data-container:first-of-type {
    margin-bottom: 50px;
  }

  .SortableTable {
    .table-header,
    .table-row {
      .col:first-of-type {
        text-transform: capitalize;
        max-width: 200px;
        font-weight: 700;
        display: flex;
        justify-content: space-around;
      }
      .moments,
      .account {
        border-right: 15px solid #fff;
      }
    }
  }
}
