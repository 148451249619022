@import '../../../styles/variables.scss';

.Auction {
  position: relative;
  h3 {
    margin: 0px;
    padding: 0px 20px 20px;
  }

  .loader {
    display: flex;
    .Loader {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .title {
    .TextField {
      padding: 0px 20px 20px;
    }
  }
}

@media (max-width: 560px) {
  .Auction {
    .ComponentPlaceholder {
      .action-container {
        display: flex;
        flex-direction: column;

        .Button,
        .Button button {
          margin: 0px;
          font-size: 14px;
          min-width: 200px;
          flex: 1;
          width: 100%;
        }

        .Button button {
          &:first-child {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
