@import '../../../../styles/variables.scss';

.HubVolunteer.Modal {
  .content {
    .close-button {
      display: none;
    }

    .modal-close-icon {
      top: 5px;
      right: 10px;
      position: absolute;
      z-index: 3;
    }
  }
}

.HubVolunteerModal {
  max-width: 566px;
  width: 566px;
  max-height: 521px;
  height: 521px;

  .TitleSection {
    display: flex;
    padding: 0px;
    margin: 0px 30px 0px 0px;

    .tabs-container {
      .tab {
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 36px;

        hr {
          width: 100%;
          border: unset;
          border-top: 1px color(dark, borderLight) solid;
        }

        .title {
          font-size: 16px;
          font-weight: 700;
          color: black;
        }
      }
    }
  }

  .settings {
    .text {
      display: flex;
      flex-direction: row;
      padding: 10px;
    }

    .applications {
      display: flex;
      flex-direction: column;
      min-width: 425px;

      .heading {
        font-size: 14px;
        font-weight: 700;
        color: black;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        color: black;
      }
    }

    .ToggleButton {
      margin-left: 50px;
    }

    .expansion {
      margin-left: 10px;
      display: flex;

      .application-url {
        max-width: 400px;

        span {
          font-size: 14px;
          font-weight: 700;
          color: black;
        }
      }
    }

    .actions {
      padding-top: 25px;
      padding-left: 30px;

      button {
        padding: 0;
      }
    }
  }

  .heading-post {
    display: flex;
    flex-direction: row;
    width: 96%;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    .min-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      .empty-img,
      img {
        padding: 0px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        overflow: hidden;
        background: color(dark, borderLight);
        border: 2px solid color(grey, pale);
        margin-right: 20px;
      }
      a {
        text-decoration: unset;
        color: color(grey, dark);
        transition: 0.2s;

        &:hover {
          color: color(purple, dark);
        }
      }

      .dots {
        margin-right: 30px;
      }

      .type {
        i {
          margin-right: 10px;
        }
      }
    }

    .menu {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s;
      display: flex;
      flex-grow: 0;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: color(grey, pale);
      }

      i {
        font-size: 20px;
        color: color(grey, font);
        font-weight: lighter;
      }
    }

    .owner-info-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: 12px;
      max-width: 435px;
      margin-right: 8px;

      h5 {
        font-weight: 800;
        font-size: 16px;
        margin: 0 0 3% 0;

        a {
          text-decoration: none;
          transition: 0.2s;
          color: color(grey, dark);

          &:hover {
            opacity: 0.8;
          }
        }
      }

      span {
        color: color(grey, secondary);
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: color(grey, light);
      }
    }

    .delete-opportunity {
      background-color: red;
      color: color(red, secondary);
    }

    .type {
      max-height: 30px;
      padding: 8px 18px;
      background: color(grey, pale);
      border-radius: 8px;
      color: color(grey, text);
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      font-feature-settings:
        'pnum' on,
        'lnum' on,
        'salt' on,
        'ss09' on;
    }

    .logo {
      border-radius: 61.6667px;
      filter: drop-shadow(0px 4.44444px 8.88889px rgba(0, 0, 0, 0.12));
      width: 50px;
      height: 50px;
    }

    .dots {
      background: color(light, secondaryBg);
      transition: 0.2s;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
      border-radius: 24px;

      &:hover {
        background: color(grey, pale);
        cursor: pointer;
      }
    }
  }

  .list-view {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;

    .application-card {
      cursor: default;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      transition: all 0.2s;
      padding: 5px 0px;
      width: 98%;

      .status {
        height: 30px;
        line-height: 30px;
        padding: 6px 12px;
        text-align: center;
        border-radius: 8px;
        width: 90px;

        &.Confirmed {
          color: color(green, soft);
          background-color: color(green, fade);
        }

        &.Interested {
          color: color(blue, soft2);
          background-color: color(blue, whiteBlue);
        }
      }

      .card-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;

        .labels {
          display: flex;
          flex-direction: column;
          text-align: left;

          .table-link {
            width: 100px;
            font-weight: 400;
            font-size: 14px;
            color: color(grey, dark);
          }
        }

        .icon {
          margin-right: 18px;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 35px;
            height: 35px;
            border: 1px solid color(light, shadow);
            box-sizing: border-box;
            border-radius: 111px;
          }
        }
      }

      .vol-button {
        display: flex;
        width: 160px;
        justify-content: right;

        Button {
          background-color: unset;
          color: color(grey, dark);
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          margin-right: 8px;
          padding: 10px 24px;
          height: 44px;
          line-height: 44px;
          cursor: pointer;
          transition: all 0.2s;
          border-radius: 22px;
          text-decoration: none;
          border: 1px solid color(grey, empty2);
          box-shadow: unset;

          &:hover {
            color: color(plum, dark);
            background-color: color(grey, pale);
          }
        }
      }
    }

    .menu {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s;
      display: flex;
      flex-grow: 0;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: color(grey, pale);
      }

      i {
        font-size: 20px;
        color: color(grey, font);
        font-weight: lighter;
      }
    }
  }

  .volunteer-content {
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    .image-container {
      padding: 0px;
      margin: 0px;

      img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 157px;
        width: 100%;
      }
      .empty-cover-image {
        position: relative;
        background-color: color(cream, dark);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow: hidden;
        height: 157px;
      }
    }

    .ModalContent {
      overflow-y: scroll;
      width: 100%;
      padding: 20px;
    }

    .header-section {
      flex-grow: 0;
      position: relative;
      width: 100%;
      overflow: hidden;
      min-height: 44px;
      display: flex;
      margin-bottom: 20px;

      .profile-img {
        height: 44px;

        .empty-img,
        img {
          padding: 0px;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          overflow: hidden;
          background: color(dark, borderLight);
          border: 2px solid color(grey, pale);
          margin-right: 20px;
        }
      }

      .owner-info-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 12px;
        max-width: 435px;
        margin-right: 8px;

        h5 {
          font-weight: 800;
          font-size: 16px;
          margin: 0 0 3% 0;

          a {
            text-decoration: none;
            transition: all 0.2s;
            color: color(grey, dark);

            &:hover {
              opacity: 0.8;
            }
          }
        }

        span {
          color: color(grey, secondary);
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;

          b {
            margin-left: 4px;
            font-weight: bold;
            color: color(grey, base);
          }
        }
      }

      .volunteer-match {
        height: 44px;
        overflow: hidden;
        display: grid;
        place-items: center;
        background: color(light, secondayBg);
      }
    }

    .volunteer-title {
      font-size: 16px;
      font-weight: 800;
      max-width: 480px;
      height: 48px;
      margin: 0;
      @include truncate(2);
    }

    .volunteer-info {
      margin-top: 18px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow-y: auto;
      @include thin-scroller-y;
      margin-bottom: 50px;

      .volunteer-main-info {
        display: flex;
        flex-direction: row;
        column-gap: 200px;

        .basic {
          font-weight: 600;
          font-size: 14px;
          color: color(purple, grey);
          row-gap: 8px;
          display: flex;
          flex-direction: column;

          .item {
            display: flex;
            height: 18px;
            align-items: center;

            a {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 180px;
            }

            &.owner {
              a {
                text-decoration: none;
                color: color(purple, grey);
                transition: color 0.2s;

                &:hover {
                  color: color(purple, dark);
                }
              }
            }

            .icon {
              margin-bottom: 0;
              margin-right: 12px;
              align-items: center;
              justify-content: center;
              display: flex;
              width: 18px;

              img {
                width: 18px;
                height: 18px;
                color: black;
              }

              i {
                margin-top: 3px;
                color: black;
              }
            }
          }
        }
      }
    }

    .volunteer-actions {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 16px;
      row-gap: 16px;

      .interested-button i {
        padding: 10px;
      }

      .interested-container {
        padding: 10px 0;
        width: 250px;
        text-align: center;
        background: color(light, secondaryBg);
        border: 1px solid color(grey, empty2);
        border-radius: 22px;
        color: color(grey, dark);
        transition: all 0.2s;

        i {
          margin-right: 8px;
        }

        &:hover {
          background: color(grey, pale);
        }
      }

      .volunteer-action,
      button {
        background-color: unset;
        color: color(grey, dark);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 16px;
        margin-right: 8px;
        padding: 0 35px;
        height: 44px;
        line-height: 44px;
        cursor: pointer;
        transition: 0.2s;
        border-radius: 22px;
        text-decoration: none;
        border: 1px solid color(grey, empty2);
        box-shadow: unset;
        min-width: 250px;

        &:hover {
          color: color(plum, dark);
          background-color: color(grey, pale);
        }

        &.apply {
          color: color(light, secondaryBg);
          background: color(plum, dark);

          &:hover {
            color: color(light, secondaryBg);
            background: color(purple, dark);
            border-color: color(purple, dark);
          }
        }
      }
    }

    .section-title {
      font-weight: 800;
      font-size: 16px;
      color: color(grey, dark);
    }

    .volunteer-about {
      margin-top: 30px;

      .about-content {
        .QuillTextEditor {
          .ql-editor {
            font-weight: 500;
            font-size: 14px;
            color: color(grey, base);
            line-height: 24px;
            padding-right: 40px;
            padding-left: 0px;

            a {
              color: color(light, fontColor);
              text-decoration: none;

              &:hover {
                color: color(purple, dark);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .HubVolunteerModal {
    max-width: unset;
    width: unset;
    max-height: unset;
    height: 100%;

    .volunteer-content {
      .volunteer-title {
        @include truncate(2);
      }

      .volunteer-actions-mobile {
        display: flex;
        column-gap: 8px;

        button,
        a {
          background-color: unset;
          color: color(grey, dark);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          font-size: 16px;
          margin-right: 8px;
          padding: 0 35px;
          height: 44px;
          line-height: 44px;
          cursor: pointer;
          transition: all 0.2s;
          border-radius: 22px;
          text-decoration: none;
          border: 1px solid color(grey, empty2);
          box-shadow: unset;

          &:hover {
            color: color(plum, dark);
            background-color: color(grey, pale);
          }

          i {
            margin-top: 3px;
            font-size: 14px;
            margin-right: 15px;
          }
        }
      }
    }
  }

  .toggle-row {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    padding-top: 30px;
    span {
      font-weight: 700;
    }
  }

  .expansion {
    padding-top: 28px;

    .application-url {
      color: color(grey, secondary);
      padding: 4px;
    }

    .Button {
      color: color(plum, dark);
      padding: 4px;
    }
  }
}

.benefitting-charity {
  .charity {
    display: flex;
  }

  .empty-img,
  img {
    padding: 0px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    overflow: hidden;
    background: color(dark, borderLight);
    border: 2px solid color(grey, pale);
    margin-right: 20px;
  }

  .charity-name {
    margin-left: 10px;
  }

  .section-title {
    font-weight: 900;
    font-size: 16px;
    color: black;
    padding-bottom: 10px;
  }

  .logo {
    border-radius: 61.6667px;
    filter: drop-shadow(0px 4.44444px 8.88889px rgba(0, 0, 0, 0.12));
    width: 40px;
    height: 40px;
  }

  .charity-title {
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    color: black;
  }

  .charity-id {
    font-weight: 600;
    font-size: 10px;
    color: color(grey, light);
  }

  .benefitting-charity-content {
    color: color(grey, base);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding-right: 40px;
  }
}
