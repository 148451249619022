@import '../../../../styles/variables.scss';

.StoriesMain {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 25px 0;

  .story-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.9;

    .gradient-cover {
      position: absolute;
      top: 0;
      left: 0;
      transition: all 200ms ease-in-out;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  .StoryProgressWrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 5px;
    height: 2px;
    display: grid;
    grid-gap: 4px;
    padding: 8px;
    max-width: 99%;
    .StoryWrapper {
      position: relative;
      background-color: #6a6a6a;
      border-radius: 2px;
      height: 3px;
    }

    .bar {
      width: 0;
      position: relative;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 2px;
      background-color: #fff;
      height: 3px;
    }
  }
  .StoryContainer {
    position: relative;
    width: 258px;
    height: 470px;
    border-radius: 10px;
    background-color: purple;
    box-shadow: $box-shadow;
    z-index: 2;

    video,
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .left {
    display: flex;
    place-content: center;
    place-items: center;
    position: absolute;
    left: 100px;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background: #fff;
  }
  .right {
    display: flex;
    position: absolute;
    place-content: center;
    place-items: center;
    right: 100px;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background: #fff;
  }

  .left,
  .right {
    z-index: 2;
    &:hover {
      cursor: pointer;
    }
  }
}
