@import '../../../styles/variables.scss';

.PageHeader {
  // GIG-1433: Removing fixed because the sidebar can clip below page when screen is too short.
  position: relative;
  width: 340px;
  height: auto;

  padding: 25px;
  box-sizing: border-box;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;

  .powered-by-icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .header-content {
    display: flex;
    flex-direction: column;

    &.content-loading {
      opacity: 0;
    }
  }

  .page-type {
    color: color(grey, dark);
    margin-top: 31px;
    margin-bottom: 12px;
    font-weight: 700;
    top: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    i,
    img {
      margin-right: 7px;
    }
  }

  .display-name-container {
    display: flex;
    flex: 1;
    margin-top: 10px;

    .display-name {
      padding: 0px;
      margin: 0px;
      font-family: $raleway;
      font-weight: 700;
      font-size: 26px;
      line-height: 30px;
      color: color(grey, dark);
    }

    .edit-display-name {
      opacity: 0;
      display: flex;
      font-size: 21px;
      margin-left: 10px;

      &:hover {
        cursor: pointer;
        color: color(purple, dark);
      }
    }

    &:hover {
      .edit-display-name {
        opacity: 1;
      }
    }
  }

  .display-handle-follow {
    display: flex;
    justify-content: start;
    align-items: center;

    span {
      margin-right: 5px;
      font-size: 14px;
      color: #828486;
    }

    .follow-link {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .handle {
    display: block;
    line-height: 21px;
    font-size: 15px;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 12px;
    color: color(grey, dark);
  }

  .location {
    color: color(grey, base);
    margin-top: 16px;
    display: flex;
    align-items: center;
    font-family: $lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    justify-content: flex-start;

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      margin-right: 11px;
    }
  }

  .img-container {
    display: flex;
    justify-content: center;
    width: 20px;
    margin-right: 11px;
    margin-top: 4px;
  }

  .from-to-date {
    margin-top: 16px;
    font-family: $lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    p {
      margin: 0;
      line-height: 24px;
    }
  }

  .verified-charity-container {
    margin-left: 10px;
    position: relative;
    padding-top: 6px;

    img:hover ~ .more-info {
      z-index: 3;
      opacity: 1;
    }

    .more-info {
      opacity: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 130px;
      transition: all 0.2s;
      height: 40px;
      z-index: -3;
      position: absolute;
      top: 30px;
      left: -55px;
      background: color(light, secondaryBg);
      border: 1px solid color(light, secondaryBg);
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      font-family: $lato;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: black;
    }
  }

  .posted-on {
    margin-bottom: 23px;
    margin-top: 7px;
    color: color(grey, base);
    font-family: $lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    font-family: $lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding-top: 5px;
    color: color(grey, dark);
    width: max-content;
    text-decoration: none;

    .img-container {
      margin-top: 1px;
    }

    i {
      margin-right: 11px;
    }

    &:hover {
      color: color(purple, dark);
    }
  }

  .desc {
    padding-top: 10px;
    color: #52646c;
    font-size: 16px;

    .ql-editor {
      padding: 0px;

      p,
      a {
        font-size: 14px;
        line-height: 24px;
        font-family: $lato;
        font-weight: 400;
      }

      a {
        color: color(purple, dark);

        &:hover {
          color: color(purple);
        }
      }
    }
  }

  .loading {
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;

    .Loader {
      i {
        font-size: 40px;
      }
    }
  }

  .charity {
    margin-bottom: 14px;
    margin-top: 24px;

    .charity-status {
      display: inline-block;
      background-color: color(cream, light2);
      color: color(dark, dark);
      padding: 4px 12px;
      border-radius: 16px;
      font-size: 12px;
      font-weight: 700;
    }

    .charity-id {
      font-family: $lato;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: color(grey, base);
    }
  }
}

@media (max-width: 680px) {
  .PageHeader {
    .header-content {
      margin: 0 10px;
    }
  }
}

@media (max-width: 1240px) {
  .PageHeader {
    position: relative;
    background: transparent;
    box-shadow: none;
    padding: 0;

    .header-content {
      align-items: center;
    }

    .page-type {
      display: none;
    }

    .display-name-container {
      margin-top: 0;

      .display-name {
        text-align: center;
      }

      .edit-display-name {
        display: none;
      }

      &:hover {
        .edit-display-name {
          display: none;
        }
      }
    }

    .floating-actions {
      display: none;
    }
  }
}

@media (max-width: 680px) {
  .PageHeader {
    width: auto;
  }
}
