@import '../../../../styles/variables.scss';

.NewPaymentMethod {
  .form-row {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;

    .col {
      flex: 1;

      input {
        width: 100%;
      }
    }
  }
  &.active {
    left: 0px;
  }

  &.new-cc {
    .overlay-inner {
      height: 100%;
      display: flex;
      flex-direction: column;

      .input-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        @include thin-scroller-y;

        .form-row {
          padding: 0px 40px;
        }

        overflow: auto;
        padding-bottom: 30px;

        .sub-title {
          padding: 10px 40px 0 40px;
        }

        .form-inputs {
          &:last-child {
            padding-bottom: 20px;
          }
        }
      }

      .overlay-actions {
        border-top: 1px solid #e8f1f2;
        background-color: #fff;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: center;
        height: 80px;

        .Button.StripeElement {
          min-width: 200px;
          border-radius: 20px;
          overflow: hidden;

          iframe {
            // Fix for Apple pay button border radius not being changed by changing the StripeElement border radius.
            // Solution is to force the iframe to have the border-radius we want.
            // Solution found here: https://wordpress.org/support/topic/change-styling-shape-of-apple-pay-buttons/
            border-radius: 20px;
          }
        }

        .Button {
          margin: 0px 10px;

          button {
            min-width: 200px;
            box-shadow: $box-shadow;
          }

          &:last-child {
            margin-left: 40px;

            button {
              background-color: #fff;
              color: #52646c;
              border: 2px solid #52646c;

              &:hover {
                border: 2px solid color(plum, dark);
                color: color(plum, dark);
              }
            }
          }

          &:first-child {
            margin-right: 40px;
          }
        }
      }
    }

    .overlay-actions {
      border-top: 1px solid #e8f1f2;
      background-color: #fff;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      align-items: center;
      height: 80px;

      .Button.StripeElement {
        min-width: 200px;
        border-radius: 20px;
        overflow: hidden;

        iframe {
          // Fix for Apple pay button border radius not being changed by changing the StripeElement border radius.
          // Solution is to force the iframe to have the border-radius we want.
          // Solution found here: https://wordpress.org/support/topic/change-styling-shape-of-apple-pay-buttons/
          border-radius: 20px;
        }
      }

      .Button {
        margin: 0px 10px;

        button {
          min-width: 200px;
          box-shadow: $box-shadow;
        }

        &:last-child {
          margin-left: 40px;

          button {
            background-color: #fff;
            color: #52646c;
            border: 2px solid #52646c;

            &:hover {
              border: 2px solid color(plum, dark);
              color: color(plum, dark);
            }
          }
        }

        &:first-child {
          margin-right: 40px;
        }
      }
    }

    &.overlay {
      position: absolute;
      top: 0px;
      left: -100%;
      width: 100%;
      height: 100%;
      background-color: white;
      overflow: auto;
      transition: left 150ms ease-in-out;
      z-index: 3;
    }

    label {
      display: block;
      color: #52646c;
      font-size: 14px;
      font-weight: 700;
    }

    .TextField {
      label {
        span {
          color: color(otherblue, grey);
        }
      }
    }

    .card-wrap {
      padding: 0px 40px;

      label {
        margin-bottom: 4px;
      }

      .card-inner {
        height: 44px;
        border: 1px solid color(grey, light);
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;

        .StripeElement {
          flex: 1;
        }
      }
    }
  }
}
