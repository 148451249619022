@import '../../../styles/variables.scss';

.AddToCalendarModal {
  padding: 50px;

  .add-to-calendar-head {
    padding-bottom: 22px;
    font-size: 22px;
    font-weight: 700;
    border-bottom: 2px solid rgba(23, 23, 23, 0.1);
  }

  .calendar-options {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 10px;
      padding: 10px;
      width: 80px;
      height: 80px;

      a {
        text-decoration: none;
        color: black;
      }

      .button-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .button-label {
        text-align: center;
        position: relative;
        bottom: -10px;
        padding: 10px 0;
        font-weight: 700;
        font-family: $lato;
        line-height: 17px;
        color: #171717;
      }
    }

    .button-container:hover {
      box-shadow: $box-shadow;
      border-radius: 15px;
    }

    .button-container.Gmail a,
    .button-container.Outlook a {
      padding-top: 10px;
    }

    .button-container.Outlook .button-label {
      bottom: -5px;
    }
  }
}
