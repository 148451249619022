@import '../../../styles/variables.scss';

.SearchResultPreview {
  display: flex;
  flex-direction: column;
  background-color: color(light, secondaryBg);
  border-radius: 7px;
  overflow: hidden;
  flex: 1;
  animation: filterSlideIn 200ms ease-in-out;
  box-shadow: $box-shadow;
  z-index: 3;
  @include kambeoScroll;

  .ql-editor {
    padding: 0;

    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: color(grey, lightest);
      border-radius: 8px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background-color: color(grey, lighter);
      border-radius: 8px;
    }
  }

  .back-to-results {
    padding: 10px 15px;
    background: color(purple, dark);
    color: #fff;
    font-weight: 700;

    &:hover {
      background: color(purple, darker);
      cursor: pointer;
    }

    i {
      margin-right: 5px;
    }
  }

  .CoverImage {
    border-radius: 0;
    min-height: 193px;
    max-height: 193px;
  }

  .Portrait {
    display: flex;
    justify-content: center;
    margin-top: -75px;
  }

  .info {
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
    align-items: center;

    .type {
      font-size: 14px;
      color: color(grey);
      font-weight: 700;
      margin: 10px;
    }

    .title {
      font-size: 20px;
      font-weight: 800;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      color: color(light, fontColor);
      padding: 0 10px;

      &:hover {
        color: color(purple, dark);
      }
    }
  }

  .description {
    flex: 1;
    position: relative;
    max-height: 200px;
    overflow-y: auto;

    .description-inner {
      p {
        padding: 0 25px;
        font-size: 14px;
        line-height: 21px;
        color: color(grey);
        text-align: center;
        font-weight: 500;
      }
    }
  }

  .action {
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > a {
      &:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-left: 5px;
      }
    }

    a {
      display: flex;
      flex: 1;
      border: 0;
      padding: 0 28px;
      font-size: 16px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      color: color(light, primaryBg);
      background-color: color(purple, dark);
      overflow: hidden;
      min-width: 80px;
      min-height: 44px;
      text-decoration: none;
      font-weight: 700;
      max-width: 200px;

      &:hover {
        background-color: color(purple, darkest);
      }
    }
  }
}
