@import '../../styles/variables.scss';

.CreateEventModal {
  background-color: color(light, secondaryBg);
  max-width: 500px;
  min-width: 500px;
  padding: 15px 20px 20px;
  border-radius: $border-radius;

  .row {
    margin-bottom: 10px;

    .time-fields {
      display: flex;

      .TextField,
      .Dropdown {
        flex: 1;
      }

      .TextField {
        margin-right: 5px;
      }

      & > div {
        flex: 1;
      }

      & > div:last-of-type {
        margin-right: 0px;
      }
    }

    .sub-title {
      padding-bottom: 10px;
      color: color(purple, dark);
      border-bottom: 1px solid color(grey, light);
      font-size: 22px;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    .label {
      font-size: 13px;
      margin-bottom: 4px;
      font-weight: 700;
    }

    &.options-row {
      margin-bottom: 15px;
    }

    .options {
      display: flex;

      .option {
        margin-right: 20px;
        height: 40px;
        line-height: 40px;

        &:hover {
          cursor: pointer;
        }

        i {
          margin-right: 6px;
          color: color(purple, dark);
        }

        span {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }

  .form-actions {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 640px) {
  .CreateEventModal {
    min-width: 0px;

    .row {
      .time-fields {
        // Attempt at aligning time fields with date fields
        .TextField {
          min-width: 111px;
          max-width: 111px;
          margin-right: 10px;

          &.minute {
            min-width: 101px;
            max-width: 101px;
          }
        }

        .Dropdown {
          min-width: 101px;
          max-width: 101px;
        }
      }
    }
  }
}
