@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

@include skeletonLoaderKeyframes;

.CommunityFeedAsideCardSkeleton {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 275px;
  box-shadow: $box-shadow;
  border-radius: 10px;
  margin-bottom: 20px;

  .card-title {
    display: flex;
    height: 24px;
    max-width: 275px;
    background: #e9e9e8;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .card-content {
    display: flex;
    flex-direction: row;

    .card-img {
      margin-top: 10px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #e9e9e8;
    }

    .card-content-detail {
      position: relative;
      top: 22px;
      left: 10px;
      background: #e9e9e8;
      max-height: 24px;
      width: 200px;
      border-radius: 10px;
    }
  }

  .card-button {
    min-width: 80px;
    min-height: 40px;
    border-radius: 20px;
    background: #e9e9e8;
    position: relative;
    justify-content: center;
    margin-top: 10px;
  }

  @include skeletonLoaderAnimation;
}
