@import '../../styles/variables.scss';

.GroupSummary {
  &.loading {
    .section-inner {
      .metric-wrap {
        .row {
          .metric {
            &.graph {
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .section-inner {
    display: flex;
    flex-direction: column;

    .actions {
      display: flex;
      margin-bottom: 20px;

      .DatePicker {
        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }

        .dp-inner {
          border: 1px solid color(grey, lightest);
          border-radius: 7px;
          overflow: hidden;
          box-shadow: $box-shadow;
        }

        .month,
        .day,
        .year {
          .Dropdown,
          .TextField {
            .input-wrap {
              border: 0px;

              .fa-angle-down {
                display: none;
              }
            }
          }

          .Dropdown {
            width: 100px;
          }
        }

        .day {
          .TextField {
            width: 50px;
          }
        }

        .year {
          .TextField {
            width: 70px;
          }
        }
      }
    }

    .metric-wrap {
      display: flex;
      flex-direction: column;
      flex: 1;

      .row {
        display: flex;
        flex-direction: row;
        flex: 1;
        margin-bottom: 20px;

        &.main-row {
          min-height: 300px;
          max-height: 300px;
        }

        &:last-child {
          margin-bottom: 0px;
        }

        .metric {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1;
          background-color: color(cream, lightest);
          border-radius: 7px;
          margin-right: 20px;

          .give-back-overlay {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: color(cream, lightest);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .msg {
              padding: 0px 60px 20px;
              text-align: center;
              font-size: 21px;
            }
          }

          .Loader {
            i {
              font-size: 31px;
            }
          }

          &:last-child {
            margin-right: 0px;
          }

          .give-back-date {
            color: color(purple, light);
            font-weight: 700;
            margin-top: 10px;
          }

          &.graph {
            display: flex;
            flex-direction: column;
            flex: 2 1;
            justify-content: initial;
            align-items: initial;

            .graph-wrap {
              flex: 1;
              padding: 10px 20px;
              margin: 10px 20px;
              background-color: color(light, secondaryBg);
              border-radius: 7px;
            }

            span {
              &:first-child {
                font-size: 16px;
                color: color(grey, lighter);
                text-transform: uppercase;
                margin-top: 8px;
                font-weight: 700;
                letter-spacing: 1px;
                padding: 10px 20px 0px;
              }
            }
          }

          span {
            &.amt {
              font-size: 28px;
              font-weight: 700;
              color: color(light, fontColor);
            }

            &.label {
              font-size: 16px;
              color: color(grey, lighter);
              text-transform: uppercase;
              margin-top: 8px;
              font-weight: 700;
              letter-spacing: 1px;
            }
          }
        }
      }
    }
  }
}
