@import '../../../../styles/variables.scss';

.PopupMenu {
  &.HubManagementBenefitingCauses-popup-menu {
    margin-left: -100px;
  }

  &.hubManagementBenefitingCauses-popupMenu {
    width: 130px;
    top: 0;
    position: absolute;
    z-index: 999;
  }
}

.HubManagementBenefitingCauses {
  padding: 60px;

  .header-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    h2 {
      font-weight: 700;
      font-size: 39px;
      color: color(grey, dark);
      margin: 0;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }
  }

  .filter-section {
    display: flex;
    justify-content: space-between;

    .TextField {
      max-width: 619px;
      height: 50px;

      .input-wrap {
        border: 1px solid color(blue, lighter);
        background-color: color(grey, lightester);

        i {
          height: 50px;
        }

        input {
          height: 50px;
          background-color: color(grey, lightester);
        }
      }
    }

    .sort {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: color(grey, lightester);
      width: 48px;
      height: 48px;
      border-radius: 24px;
      color: color(grey, dark);
      font-size: 18px;
      cursor: pointer;
      border: 1px solid color(grey, lightester);
      transition: all 0.2s;

      &:hover {
        border: 1px solid color(blue, lighter);
      }
    }
  }

  .section-title {
    height: 50px;
    line-height: 50px;
    padding-left: 97px;
    background: color(cream, grey2);
    color: color(light, fontColor);
    margin-bottom: 38px;

    h3 {
      margin: unset;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
    }
  }

  .table-section {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
    justify-content: center;
    min-height: 400px;

    .TableComponent {
      .item-name-col {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 15px;
        min-height: 60px;

        .image-container {
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }

    .Loader {
      place-self: center;
      font-size: 24px;
    }

    .partner-item {
      position: relative;
      border-bottom: 1px solid color(blue, lighter);
      display: flex;
      column-gap: 24px;
      align-items: center;
      justify-content: center;
      padding: 0 16px 24px 16px;

      .title {
        flex-grow: 1;

        a {
          text-decoration: none;
          color: color(blue, grey);
          transition: all 0.2s;
          font-weight: 400;
          font-size: 18px;

          &:hover {
            color: color(purple, dark);
          }
        }
      }

      img {
        border-radius: 30px;
        overflow: hidden;
        flex-grow: 0;
      }

      .context-menu-container {
        flex-grow: 0;

        i {
          cursor: pointer;
          font-size: 24px;
        }
      }
    }
  }

  .add-causes-modal {
    width: 688px;
    height: 568px;
    background: color(light, secondaryBg);
    border-radius: 10px;
    padding: 48px;

    .ModalHeader {
      position: absolute;
      right: 30px;
    }

    .modal-header {
      border-bottom: 1px solid color(dark, borderLight);
      padding-bottom: 20px;
      margin-bottom: 26px;

      h2 {
        margin: 0 0 10px 0;
        font-weight: 700;
        font-size: 22px;
        color: black;
      }

      span {
        font-weight: 600;
        font-size: 12px;
        color: color(grey, text);
      }

      .filter-container {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        align-items: self-end;
        position: relative;

        .filter-dropdown {
          margin-right: 20px;
          min-width: 170px;

          .input-wrap {
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 700;
            font-size: 16px;
            padding: 0 23px 0 16px;
            border: 1px solid color(grey, border);

            .menu-btn {
              align-items: center;
              justify-content: center;
              transition: transform 0.3s ease-in-out;

              &.show {
                transform: rotate(180deg);
              }
            }

            .label {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            }

            &:hover {
              cursor: pointer;
            }
          }

          label {
            z-index: 1;
            bottom: -11px;
            left: 9px;
            background-color: white;
            font-weight: normal;
            position: relative;
            padding: 5px;
            width: fit-content;
            color: color(grey, dark);
          }

          &:hover {
            cursor: pointer;
          }
        }

        .TextField {
          max-width: 450px;

          .input-wrap {
            width: 100%;
            background: color(light, secondaryBg);
            border: 1px solid color(grey, border);
            box-sizing: border-box;
            border-radius: 30px;
            height: 44px;

            i {
              color: color(grey, text);
            }
          }
        }
      }
    }

    .btn-container {
      margin-top: 21px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      column-gap: 15px;
    }

    .modal-main {
      display: flex;
      flex-direction: column;
      max-height: 662px;
      overflow: auto;
      width: 100%;
      height: 100%;
      border-bottom: 2px solid color(dark, borderLight);
      scrollbar-width: thin;
      scrollbar-color: color(grey, border) color(grey, base3);

      &::-webkit-scrollbar-track {
        background: color(grey, base3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: color(grey, border);
        border-radius: 20px;
      }
      &::-webkit-scrollbar {
        width: 8px;
      }

      .Loader {
        place-self: center;
      }

      .partners-container {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        padding: 24px 0;

        .empty-state {
          display: flex;
          position: relative;
          justify-content: center;
        }

        .to-add-partner {
          padding: 0 28px 0 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 16px;
          max-width: 640px;
          height: 80px;
          background: color(light, secondaryBg);
          border: 1px solid color(light, shadow);
          box-sizing: border-box;
          border-radius: 10px;

          img {
            flex-grow: 0;
            border: 1px solid color(grey, border);
            box-sizing: border-box;
            border-radius: 20px;
            overflow: hidden;
          }

          .info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            row-gap: 4px;

            .title {
              font-weight: 800;
              font-size: 16px;
              color: color(grey, dark);
            }

            .charity-id {
              font-weight: 500;
              font-size: 12px;
              color: color(grey, text3);
            }
          }

          .Checkbox {
            flex-grow: 0;
          }
        }
      }
    }
  }

  .invite-causes-modal {
    width: 688px;
    height: 568px;
    background: color(light, secondaryBg);
    border-radius: 10px;
    padding: 48px;

    .ModalHeader {
      position: absolute;
      right: 0px;
      top: 0px;
    }

    .modal-header {
      border-bottom: 1px solid color(dark, borderLight);
      padding-bottom: 20px;
      margin-bottom: 26px;

      h2 {
        margin: 0 0 10px 0;
        font-weight: 700;
        font-size: 22px;
        color: black;
      }

      span {
        font-weight: 600;
        font-size: 12px;
        color: color(grey, text);
      }
    }

    .btn-container {
      margin-top: 21px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      column-gap: 15px;
    }

    .modal-main {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      max-height: 662px;
      overflow: auto;
      width: 100%;
      height: 100%;
      border-bottom: 2px solid color(dark, borderLight);
      scrollbar-width: thin;
      scrollbar-color: color(grey, border) color(grey, base3);
      padding-bottom: 26px;

      &::-webkit-scrollbar-track {
        background: color(grey, base3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: color(grey, border);
        border-radius: 20px;
      }
      &::-webkit-scrollbar {
        width: 8px;
      }

      .TextField {
        flex: 0 1;

        label {
          span {
            color: black !important;
          }
        }

        .input-wrap {
          width: 100%;
          background: color(light, secondaryBg);
          border: 1px solid color(grey, border);
          box-sizing: border-box;
          border-radius: 30px;
          height: 44px;

          i {
            color: color(grey, text);
          }
        }
      }
    }
  }

  hr {
    height: 0;
    border: 1px solid #e7e5e0;
  }
}
