@import '../../../styles/variables.scss';

.PopupMenu.cause-selection-popupMenu-filter-by {
  min-width: 217px;
}

.CauseSelectionModal {
  .content {
    width: 784px;
    height: 568px;
    max-height: 100%;
    display: flex;
    padding: 48px;

    .ModalHeader {
      .header-container {
        padding: 0;

        .header-title {
          text-align: left;
          font-family: $raleway;
          font-weight: 700;
        }
      }

      .modal-close-icon {
        margin-right: 0;
        margin-top: 0;
      }
    }

    .search-container {
      display: flex;
      align-items: flex-end;
      column-gap: 12px;
      padding: 0 20px 20px 20px;

      .TextField {
        flex: 2;
      }

      .filter-dropdown {
        // min-width: 205px;
        flex: 1;

        .input-wrap {
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 700;
          font-size: 16px;
          padding: 0 23px 0 16px;
          border: 1px solid color(blue, lighter);

          .menu-btn {
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease-in-out;

            &.show {
              transform: rotate(180deg);
            }
          }

          .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            color: color(grey, dark);
          }

          &:hover {
            cursor: pointer;
          }
        }

        label {
          z-index: 1;
          bottom: -11px;
          left: 9px;
          background-color: white;
          font-weight: normal;
          position: relative;
          padding: 5px;
          width: fit-content;
          color: color(grey, dark);
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding: 24px 20px;
      overflow-y: auto;
      max-height: 269px;

      @include thin-scroller-y;

      .list-item-container {
        text-decoration: none;
        .item {
          padding: 0 28px 0 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 16px;
          height: 80px;
          max-width: 740px;
          background: color(light, secondaryBg);
          border: 2px solid color(light, shadow);
          box-sizing: border-box;
          border-radius: 10px;
          transition: all 0.2s;

          &:hover {
            cursor: pointer;
            border: 2px solid color(plum, dark);
          }

          img {
            flex-grow: 0;
            border: 1px solid color(grey, border);
            box-sizing: border-box;
            border-radius: 20px;
            overflow: hidden;
          }

          .info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            row-gap: 4px;

            .title {
              font-weight: 800;
              font-size: 16px;
              color: color(grey, dark);
            }

            .charity-id {
              font-weight: 500;
              font-size: 12px;
              color: color(grey, text3);
            }
          }

          &.selected {
            border: 2px solid color(plum, dark);
          }
        }
      }
    }

    .actions {
      display: flex;
      grid-gap: 20px;
      gap: 20px;
      justify-content: flex-end;
      margin-top: 30px;
      margin-right: 30px;
      position: sticky;
      bottom: -100px;
      right: 0;
    }
  }

  .Loader {
    display: flex;
    align-items: center;
    justify-content: center;
    place-self: center;
  }

  .empty-list {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 269px;

    p {
      text-align: center;
    }
  }
}
