@import '../../styles/variables.scss';

.Share {
  flex: 1;
  padding: 50px;
  .share-head {
    padding-bottom: 22px;
    font-size: 22px;
    font-weight: 700;
    border-bottom: 2px solid rgba(23, 23, 23, 0.1);
    span:hover {
      cursor: pointer;
    }
  }
  .share-content.embed {
    flex-direction: column;
    .embed-selection {
      width: 100%;
    }
    .embed-code {
      display: flex;
      margin-top: 20px;
      margin-bottom: 20px;
      max-width: 520px;

      .TextField {
        .input-wrap {
          border-right: 0px;
          border-radius: 20px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }

      .Button {
        button {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          height: 44px;
        }
      }
    }
    .color-options {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      min-width: 200px;
      z-index: 9;

      .color-option {
        display: flex;
        position: relative;
        width: calc(50% - 40px);
        margin: 20px;
        flex: 1 1;
        .picker-wrap {
          position: absolute;
          bottom: 25px;
          left: 0px;
          padding-left: 5px;
        }

        &:last-child {
          margin-bottom: 0px;
        }

        .color-swatch {
          height: 25px;
          width: 25px;
          border-radius: 15px;
          border: 2px solid color(grey, light);
        }

        .color-label {
          flex: 1;
          margin-left: 25px;
          .color-name {
            font-size: 16px;
            padding: 0px 0px 5px 0px;
            margin: 0px;
            font-weight: 700;
          }

          .color-hex {
            font-size: 14px;
          }
        }
      }
    }
  }
  .share-content.social-media {
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 500px;
    .qr-code-section {
      width: 100%;
      text-align: center;
    }
    .share-type {
      width: calc(25% - 48px);
    }
  }
  .share-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
    max-width: 500px;
    .share-type {
      padding: 10px;
      margin: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      &:hover {
        cursor: pointer;
      }
      i {
        font-size: 25px;
      }
      .icons-grouped {
        height: 48px;
        width: 48px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        img {
          width: 18px;
          height: 18px;
          margin: 3px;
        }
      }
      img {
        height: 48px;
        width: 48px;
      }
      .share-label {
        color: #171717;
        text-decoration: none;
        font-size: 16px;
        font-weight: 700;
        margin-top: 10px;
      }

      &.url {
        width: fit-content;
        align-items: flex-start;
        margin-left: 10px;
      }
    }
  }
  .copy-link {
    width: 465px;
    padding-top: 10px;
    display: flex;

    .TextField {
      .input-wrap {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border: 1px solid color(grey, lighter);

        input {
          background-color: color(cream, lighter);
        }
      }
    }

    .Button {
      button {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        height: 44px;
      }
    }
  }

  .qr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;

    .Button {
      margin-top: 20px;
    }
  }

  ul {
    padding: 40px 40px 20px;
    margin: 0px;
    list-style-type: none;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: space-between;

    li,
    a {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-decoration: none;
      color: color(light, fontColor);

      &:hover {
        cursor: pointer;

        .icon {
          i {
            color: color(purple, dark);
          }
        }
      }

      .icon {
        background-color: color(cream, lighter);
        height: 60px;
        width: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 32px;
        }
      }

      span {
        display: flex;
        padding-top: 12px;
        flex: 1;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 910px) {
  .Share {
    .share-content.create-post {
      max-width: calc(100vw - 140px);
    }
  }
}

@media (max-width: 680px) {
  .Share {
    padding: 25px;
    .share-type.embed {
      display: none;
    }
    .share-content.social-media {
      width: 100%;
      max-width: unset;
      .share-type {
        flex: 1 1;
      }

      .share-type.url {
        padding-left: 20px;

        .copy-link {
          width: -webkit-fill-available;
        }
      }
    }
    .share-content.create-post {
      width: 100%;
      max-width: unset;
      flex-direction: column;
      position: relative;
      height: calc(100% - 70px);
      .post-preview,
      .post-description {
        width: auto;
        margin: 0;
      }
      .post-description {
        height: 100%;
        margin-top: 34px;
      }
    }
  }
}
