@import '../../../../../styles/variables.scss';

.PayoutModal {
  .content {
    max-width: 1022px;
    width: 100%;
    height: 580px;
    overflow: hidden;
    position: relative;

    .ModalHeader {
      .modal-close-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        margin: 0;
        z-index: 5;
        color: white;
        background-color: unset;

        &:hover {
          background-color: color(grey, pale);
          color: black;
        }
      }
    }

    .dmp {
      width: 100%;
      height: 100%;
      display: flex;

      &-main {
        flex: 1;
        padding: 48px 0 0 48px;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        min-height: min-content;

        @include thin-scroller-y;
        .add-payment-method {
          z-index: 4;
        }

        .pick-payment-method {
          z-index: 3;
        }

        .pick-payment-method,
        .add-payment-method {
          position: absolute;
          height: 100%;
          width: 0;
          left: 0;
          top: 0;
          background: white;
          transition: all 0.2s;

          &.active {
            width: 100%;
            .PickPaymentMethod {
              position: relative;
              left: 0;
              top: 30px;
            }
          }
        }

        h1 {
          margin: 0 0 28px 0;
          font-family: $raleway;
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          color: black;
        }

        &--info {
          flex: 1;
          padding-right: 45px;
          display: flex;
          flex-direction: column;
          max-height: 406px;
          overflow-y: auto;
          overflow-x: hidden;
          @include thin-scroller-y;

          .title {
            font-family: $raleway;
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            letter-spacing: 0.280123px;
            color: color(grey, dark);
            margin-bottom: 30px;
          }

          .info {
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            padding: 16px;
            border: 1px solid color(grey, border);
            margin-bottom: 30px;

            &--program-name {
              font-family: $raleway;
              font-style: normal;
              font-weight: 800;
              font-size: 18px;
              color: color(grey, dark);
              margin-bottom: 16px;
            }

            &--group {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 18px;

              .name {
                margin-left: 12px;
                font-family: $lato;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: color(grey, dark);
              }
            }

            progress {
              margin-bottom: 18px;
              width: 100%;
              height: 14px;
              -webkit-appearance: none;
              appearance: none;
              border-radius: 16px;
              overflow: hidden;
              background-color: white;
              border: unset;
              box-shadow: unset;
            }

            progress[value]::-webkit-progress-bar {
              background-color: color(grey, pale);
            }

            progress[value]::-webkit-progress-value {
              background-color: color(purple, dark);
            }

            .progress-info {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-family: $lato;

              &--item {
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:first-of-type {
                  align-items: flex-start;
                }

                &:last-of-type {
                  align-items: flex-end;
                }

                .value {
                  margin-bottom: 4px;
                  color: black;
                  font-weight: bold;
                }

                .name {
                  color: color(grey, light);
                }
              }
            }
          }

          .payment-type-popupmenu {
            margin-bottom: 40px;
            width: 100%;
            display: flex;
            flex-direction: column;
            color: color(grey, dark);
            font-size: 14px;

            label {
              font-family: $lato;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              font-feature-settings:
                'pnum' on,
                'lnum' on;
            }

            .title-popupmenu {
              margin-top: 2px;
              display: flex;
              justify-content: space-between;
              background: white;
              border: 1px solid color(grey, border);
              border-radius: 10px;
              padding: 10px 16px;
            }
          }

          .section {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;

            &--title {
              font-family: $lato;
              font-style: normal;
              font-weight: 900;
              font-size: 16px;
              color: color(grey, dark);
              margin-bottom: 16px;
            }

            &--content {
              padding: 16px 0;
              border-top: solid 1px color(grey, border);
              border-bottom: solid 1px color(grey, border);
              text-align: center;
            }
          }
        }

        &--actions {
          border-top: solid 2px color(grey, border);
          height: 76px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 48px;

          .Button {
            &:last-of-type {
              margin-left: 16px;
            }
          }
        }
      }

      &-summary {
        height: 100%;
        width: 400px;
        background: color(grey, lightester);
        display: flex;
        flex-direction: column;
        position: relative;

        .CoverImage {
          width: 100%;
          height: 140px;
          flex: unset;
        }

        .Portrait {
          position: absolute;
          top: 96px;
          right: 155px;
        }

        .name {
          margin-top: 68px;
          text-align: center;
          font-family: $lato;
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: 0.280123px;
          margin-bottom: 18px;
        }

        .charity {
          text-align: center;
          font-family: $lato;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.280123px;
          color: color(grey, dark);
          margin-bottom: 16px;
        }

        @mixin row {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        @mixin name-value {
          font-family: $lato;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: color(grey, base);
        }

        .row {
          border-top: solid 1px color(grey, border);
          margin: 0 38px;
          padding-top: 20px;
          margin-top: 20px;
          @include row;

          &.total {
            .row--name,
            .row--value {
              @include name-value;
              font-weight: 700;
            }
          }
        }

        .amounts {
          padding-top: 20px;
          margin-top: 20px;
          border-top: solid 1px color(grey, border);
          margin: 0 38px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .amounts--row {
            @include row;
            border: unset;

            .amounts--name,
            .amounts--value {
              @include name-value;
            }
          }
        }
      }
    }
  }
}
