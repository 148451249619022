$lato: 'Lato', sans-serif;
$raleway: 'Raleway', sans-serif;
$wrap-size: 1200px;
$border-radius: 20px;
$border-radius-minimal: 8px;
$box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
$box-shadow-darker: 0px 3px 6px rgba(0, 0, 0, 0.2);
$box-shadow-darkest: 0px 3px 6px rgba(0, 0, 0, 0.5);
$overlay-gradient: linear-gradient(180deg, rgba(1, 41, 57, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
$mobile-footer-height: 90px;
$header-height: 75px;
$SAFARI_MOBILE_BOTTOM_MENU_HEIGHT: 44px;
$loading-shimmer-size: 800px;
$loading-shimmer-bg: linear-gradient(
  to right,
  color(grey, lightest) 10%,
  color(grey, lighter) 18%,
  color(grey, lightest) 33%
);
$loading-shimmer-animation: loadingShimmer 1s forwards linear infinite;

@import './mixins.scss';

@keyframes loadingShimmer {
  0% {
    background-position: -400px 0px;
  }

  100% {
    background-position: 400px 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@mixin kambeoScroll {
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: color(grey, lightest);
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: color(grey, lighter);
    border-radius: 8px;
  }
}

$colors: (
  dark: (
    primaryBg: #262b2d,
    secondaryBg: #32393c,
    tertiaryBg: #1a1c1d,
    fontColor: #fbfbfb,
    border: #828486,
    borderLight: #e9e9e8,
  ),
  light: (
    primaryBg: #eaeff0,
    secondaryBg: #ffffff,
    tertiaryBg: #d6d6d6,
    // Got rid of font color #012939 which changed it to default #3A3A3A (GIG-6585)
    // Re added fontColor #012939 back GIG-6585
    fontColor: #012939,
    shadow: #e5e5e5,
    box: #f1f1f0,
    purple: #efeef6,
  ),
  blue: (
    whiteBlue: #eff5fd,
    soft2: #5e96eb,
    space: #424243,
    lightest: #eaeff0,
    lighter: #b6c5cb,
    light: #0c9fb5,
    soft: #52afd7,
    default: #087f94,
    dark: #003349,
    darker: #095fc4,
    baby: #baecfc,
    gray: #484b4d,
  ),
  teal: (
    base: #eaf8fa,
  ),
  green: (
    light: #eafcf5,
    soft: #57c0ac,
    dark: #067f5c,
    neon: #2abd7e,
    online: #24d03e,
    fade: #eef9f7,
    text: #57c0ac,
  ),
  grey: (
    imgBg: #c4c4c4,
    secondary: #9d9d9d,
    text: #a9aaac,
    text2: #5a5b5d,
    text3: #959799,
    txt: #a9aaac,
    smallTxt: #757575,
    empty: #ececec,
    empty2: #bdbebf,
    empty3: #cecece,
    empty4: #eaeaea,
    primary: #f9f8f6,
    pale: #f5f5f5,
    lightester: #f5f6f7,
    lightest: #d8d8d8,
    lighter: #b0b0b0,
    light: #898989,
    base: #616161,
    almostdark: #4e4e4e,
    base2: #88949a,
    base3: #ededec,
    base4: #fafbfb,
    border: #d0d1d1,
    dark: #3a3a3a,
    darker: #2e2e2e,
    darkest: #232323,
    activeGray: #eaeaee,
  ),
  pink: (
    base: #ed418a,
  ),
  plum: (
    primary: #201e3a,
    lightest: #d5d4dc,
    lighter: #aba9ba,
    light: #817f98,
    base: #585575,
    dark: #2f2956,
    darker: #252243,
    darkest: #1b1a32,
    gray: #171717,
  ),
  purple: (
    link: #6b64ae,
    lightest: #dedcec,
    lighter: #bebada,
    light: #9d98c9,
    base: #7b75b7,
    grey: #6f7072,
    greyTxt: #5a5b5d,
    dark: #5e51ab,
    darker: #484385,
    darkest: #373264,
    base2: #29264a,
  ),
  cream: (
    lightest: #fdfcfb,
    lighter: #faf9f7,
    light: #f8f7f4,
    light2: #e7f6f8,
    base: #f5f4f0,
    gray: #e9e9e8,
    grey2: #f4f4f4,
    dark: #f3f1ec,
    darker: #edeae3,
    darkest: #dfdbd1,
    border: #aaaaaa,
  ),
  brand: (
    logo: #e24d41,
    red: #e24d41,
    green: #a4bb6d,
  ),
  orange: (
    lightest: #f9e9e3,
    lighter: #f4d3c8,
    light: #efbdac,
    base: #e9a891,
    dark: #f38e70,
    darker: #b7765f,
    darkest: #8a5847,
    darkest2: #b23000,
  ),
  yellow: (
    light: #fff2d8,
    dark: #9f6000,
    warning: #ee7100,
    text: #f1b94d,
    fade: #fef8ed,
  ),
  red: (
    primaryBg: #d12025,
    light: #ffe3ef,
    dark: #c80959,
    error: #c80909,
    fade: #fdf0ef,
    soft: #eb645e,
    soft2: #ffc1be,
  ),
  otherblue: (
    light: #dedef4,
    dark: #3e3ee0,
    mid: #0066ff,
    grey: #52646c,
    link: #2c73d6,
  ),
  volunteer: (
    base: #c13128,
  ),
  work: (
    base: #a4bb6d,
  ),
  services: (
    base: #f17474,
  ),
  spaces: (
    base: #f26b26,
  ),
  events: (
    base: #b272bd,
  ),
  groups: (
    base: #2295ba,
  ),
  creditCards: (
    visa: #214dba,
    mastercard: #343a47,
    amex: #6ca0da,
    discover: #ef7e23,
    diners-club: #01084c,
    jcb: #0867ad,
    credit-card: color(blue, light),
  ),
);

@function color($color, $tone: 'base') {
  @return map-get(map-get($colors, $color), $tone);
}
