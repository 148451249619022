@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

@include skeletonLoaderKeyframes;

.CommunityFeedPostSkeleton {
  width: 100%;
  min-height: 400px;
  box-shadow: $box-shadow;
  border-radius: 10px;
  margin: 10px 0;

  .post-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .header-left {
      display: flex;
      width: 100%;
      min-height: 50px;

      .header-img {
        top: 18px;
        left: 18px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: relative;
        background: #e9e9e8;
      }

      .header-text {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        margin-left: 12px;
        top: 20px;
        left: 24px;
        position: relative;

        div {
          height: 16px;
          width: 360px;
          background: #e9e9e8;
          border-radius: 10px;
          margin-bottom: 10px;
        }
      }
    }

    .header-right {
      display: flex;
      margin-right: 75px;
      margin-top: 18px;

      div {
        height: 30px;
        width: 80px;
        background: #e9e9e8;
        border-radius: 10px;
      }
    }
  }

  .post-content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    min-height: 200px;
    width: 100%;

    span {
      display: flex;
      height: 16px;
      max-width: 625px;
      background: #e9e9e8;
      border-radius: 10px;
      margin-bottom: 10px;
      margin: 10px 16px;
    }

    div {
      display: flex;
      height: 120px;
      max-width: 625px;
      background: #e9e9e8;
      border-radius: 10px;
      margin: 0 16px;
    }
  }

  .post-bottom {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    min-height: 30px;

    span {
      background: #e9e9e8;
      display: flex;
      height: 30px;
      border-radius: 10px;
      margin: 0 16px;
      max-width: 625px;
    }

    span:last-of-type {
      margin-top: 20px;
    }
  }

  @include skeletonLoaderAnimation;
}
