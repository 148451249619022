@import '../../styles/variables.scss';
.story {
  overflow: hidden;
  @include kambeoScroll;
  main {
    padding-top: 0;
  }

  .Toast {
    z-index: 100;
  }

  .PopupMenu.avatar-menu-dropdown {
    width: 345px;
    max-height: 300px;
    overflow: auto;
    .MenuItem {
      min-height: 80px;
      height: 80px;
      font-weight: 800;
      padding: 8px;
      border-radius: $border-radius-minimal;

      &:hover {
        cursor: pointer;
        background-color: color(grey, lightester);
      }
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 0 15px;
      }
    }
  }
}

@mixin buttonAlt {
  .Button {
    width: 100%;
    height: 44px;

    button {
      width: 100%;
      background-color: transparent;
      color: color(purple, base2);
      border: 1px solid color(purple, base2);
      font-weight: 700;
    }
  }
}

@mixin buttonRegular {
  width: auto;
  &:last-of-type {
    margin-left: 12px;
    button {
      background-color: color(purple, darker);
      color: #fff;
      border: 0px;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.CreateStory {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: color(grey, lightester);
  z-index: 99;
  animation: 250ms scaleIn ease-in-out;

  p {
    margin: 0;
  }

  .FormTopBar {
    background: #fff;
  }

  .heading {
    align-self: flex-start;
    justify-self: flex-start;
    text-align: start;
    margin: 130px 100px;

    &.company,
    &.cause {
      margin-bottom: 15px;
    }

    h1,
    p {
      margin: 0;
    }

    h1 {
      font-family: $raleway;
      font-size: 14px;
      line-height: 24px;
      font-weight: 800;
      margin-bottom: 5px;
    }

    p {
      font-family: $lato;
      color: #616161;
    }
  }

  .entity-selection {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
    width: 800px;
    margin: 0 auto;
    .search-input-container {
      .TextField {
        .input-wrap {
          border: 1px solid color(grey, lightest);
          border-radius: $border-radius;
        }

        i {
          color: color(grey, base);
          background-color: #fff;
          padding-left: 5px;
        }
      }
    }
    .results {
      margin-top: 15px;
      overflow-y: auto;
      overflow-x: hidden;
      height: 480px;

      ::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: color(grey, lightest);
        border-radius: 8px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background-color: color(grey, lighter);
        border-radius: 8px;
      }
    }
  }

  .build-story {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    .story-actions-mobile {
      position: absolute;
      top: 0;
      right: 10px;
      z-index: 2;

      ul {
        display: flex;
        list-style-type: none;
        padding: 0;

        li {
          padding: 5px;
          display: flex;
          align-items: center;
          font-weight: 800;
          margin: 10px 0;
          border-radius: $border-radius-minimal;
          animation: 250ms slideDown ease-in-out;

          &:hover {
            background: color(grey, lightester);
            cursor: pointer;
          }

          svg {
            border-radius: 50%;
          }
        }
      }
    }
    .story-mobile-drawer,
    .story-drawer {
      flex: 1 1;
      margin-top: 90px;
      background: #fff;
      border-right: 1px solid color(grey, lightester);
      max-width: 400px;
      padding: 25px 35px;
      height: 100%;
      z-index: 2;
      div {
        .CustomDropdown {
          height: 100%;
          width: 100%;
          border-radius: $border-radius-minimal;

          &:hover {
            cursor: pointer;
            background: color(grey, lightester);
          }
          .avatar-dropdown {
            display: flex;
            flex-direction: row;
            border: none;
            padding: 12px;
            align-items: center;

            .Portrait {
              margin-right: 15px;
            }
          }
          .label {
            flex: 1;
            font-weight: 800;
          }
          .icon-container {
            justify-self: flex-end;
          }
        }
      }

      .add-metric,
      .add-cta,
      .add-text,
      .add-background {
        height: 100%;

        form {
          display: flex;
          flex-direction: column;
          height: 100%;

          .row {
            margin: 15px 0;

            label {
              color: color(grey, darkest);
            }
          }
          .upload-media-container {
            margin: 25px 0;
            @include buttonAlt;

            .ImageUpload {
              .FileUpload {
                .custom-file-upload {
                  color: color(grey, dark);
                  font-weight: 700;
                  background-color: transparent;
                  border: 1px solid color(grey, dark);
                }
              }
            }

            .media-preview {
              width: 348px;
              height: 68px;
              display: flex;
              margin: 15px 0;
              border: 1px solid grey;
              border-radius: 8px;
              align-items: center;
              padding: 10px 25px;

              img {
                width: 70px;
                height: 46px;
              }
              div {
                flex: 1;
              }

              .file-info {
                padding-left: 15px;
                flex: 4;

                .size,
                .name {
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 700;
                }
                .size {
                  color: #898989;
                }
              }

              .action {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;

                i {
                  color: #eb645e;

                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }

      .drawer-title {
        display: flex;
        align-items: center;
        font-weight: 800;
        padding-bottom: 20px;
        border-bottom: 2px solid color(grey, lightester);

        .Portrait {
          margin-right: 20px;
        }
      }

      ul {
        list-style-type: none;
        margin: 20px 0 0 0;
        padding: 15px;

        li {
          padding: 5px;
          display: flex;
          align-items: center;
          height: 66px;
          font-weight: 800;
          margin: 10px 0;
          border-radius: $border-radius-minimal;
          animation: 250ms slideDown ease-in-out;

          &:hover {
            background: color(grey, lightester);
            cursor: pointer;
          }

          svg {
            border-radius: 50%;
            margin-right: 25px;
          }
        }
      }

      .actions {
        align-self: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex: 1;
        min-height: 225px;
        @include buttonAlt;
        .Button {
          @include buttonRegular;
        }
      }
    }
    .story-mobile-drawer {
      box-sizing: border-box;
      position: absolute;
      height: 70%;
      width: 100%;
      bottom: 0;
      left: 0;
      max-width: 100%;
      border-top-right-radius: 24px;
      border-top-left-radius: 24px;

      .notch {
        width: 48px;
        height: 8px;
        margin: 0 auto;

        background: #c4c4c4;
        border-radius: 4px;
      }

      .drawer-title {
        padding: 15px 0;
      }

      .add-background {
        form {
          .upload-media-container {
            .media-preview {
              width: auto;
              height: auto;
            }
          }

          .custom-color-picker {
            max-height: 244px;
          }
        }
      }

      .actions {
        min-height: 0;
      }
    }

    .story-edit-area {
      flex: 2 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .story-card {
        position: relative; //absolute;
        height: 470px; //480px;
        width: 258px; //258px;
        background: color(purple, dark);
        color: #fff;
        font-family: $raleway;
        border-radius: $border-radius-minimal;
        background-repeat: no-repeat, repeat;

        .fg-content {
          top: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }

        .call-to-action-container {
          padding: 10px;
          font-weight: 800;

          &:hover {
            border: 1px solid #fff;
            .action {
              display: flex;
            }
          }
        }

        .metric-box {
          text-align: center;
        }
        .metric-box,
        .call-to-action-container,
        .textbox {
          position: absolute;
          top: 10px;
          left: 10px;
          z-index: 10;
          .text-inner {
            position: relative;
            line-height: 24px;
            font-family: $raleway;
            padding: 10px;
            font-weight: 800;

            &[contenteditable] {
              outline: 0px solid transparent;
            }

            p {
              white-space: pre-wrap;
            }
          }
          .action {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            color: black;
            z-index: 3;
            width: 24px;
            height: 24px;
            background: #fff;
            border-radius: 50%;
            top: -10px;

            i {
              font-size: 12px;
            }

            &:hover {
              background: color(grey, lightester);
            }
          }
          .delete {
            right: 20px;
            color: color(red, soft);
          }
          .drag-handle {
            right: -11px;
          }

          &:hover {
            .action {
              display: flex;
            }

            .text-inner {
              border: 1px solid #fff;
            }
          }

          button {
            border: none;
            line-height: 24px;
            font-family: $lato;
            padding: 10px;
            font-weight: 800;
            color: #fff;
            border-radius: $border-radius;
            padding: 8px 28px;
          }
        }

        // Video needs a toggle to switch object fit between cover/contain for diff size vids
        video {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          max-width: 100%;
          border-radius: 8px;
          object-fit: cover;
        }
      }

      .crop-container {
        position: absolute;
        height: 100%;
        width: 100%;
        max-height: 513px;
        max-width: 767px;

        .zoom-container {
          position: absolute;
          bottom: 2.5rem;
          width: 300px;
          left: 18rem;
          z-index: 4;
          display: flex;
          flex-direction: column;
          max-width: 200px;

          input[type='range'] {
            border-radius: $border-radius-minimal;
            -webkit-appearance: none;
            margin: 15px 0;
            background: color(purple, dark);
          }

          input[type='range']::-webkit-slider-runnable-track {
            height: 7px;
            background: #fff;
            border: none;
            border-radius: 8px;
            cursor: pointer;
          }

          input[type='range']::-webkit-slider-thumb {
            -webkit-appearance: none;
            border: none;
            height: 18px;
            width: 18px;
            border-radius: 50%;
            background: color(purple, dark);
            margin-top: -5px;
            cursor: pointer;
          }

          input[type='range']:focus {
            outline: none;
          }
        }
      }
    }

    .custom-color-picker {
      flex: 3;
      overflow: auto;
      .color-container {
        label {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
        }
        .color {
          width: 184px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: color(light, secondaryBg);
          border: 1px solid color(grey, border);
          box-sizing: border-box;
          border-radius: 8px;
          cursor: pointer;

          .inner {
            width: 141px;
            height: 28px;
            border: 1px solid color(grey, border);
            box-sizing: border-box;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;

    .selections {
      display: flex;
      flex-direction: row;
      justify-self: center;
      align-self: center;
      margin: 0 auto;
      margin-top: 60px;
      .story-select-card {
        background: #fff;
        border-radius: $border-radius-minimal;
        width: 130px;
        height: 196px;
        margin: 0 8px;
        box-shadow: $box-shadow;
        overflow: hidden;
        transition: all 0.25s ease-in-out;
        position: relative;
        &:hover {
          box-shadow: $box-shadow-darker;
          cursor: pointer;
          transform: scale(1.1);
          .image-section {
            .overlay {
              background-color: rgba(0, 0, 0, 0.2);
            }
          }
        }

        &.selected {
          border: 2px solid color(purple, base);
        }

        .image-section {
          height: 150px;
          background-repeat: no-repeat, repeat;
          background-size: 100% 100%;
          overflow: hidden;
          background-color: color(purple, base);
          position: relative;
          &.Me {
            background-image: url('../../assets/hub-story-user.png');
            background-color: color(purple, lighter);
          }
          &.Cause {
            background-image: url('../../assets/hub-story-cause.png');
            background-color: color(purple, light);
          }
          &.story-hub-selection {
            background-image: url('../../assets/hub-story-hub.png');
            background-color: color(purple, base);
          }

          .overlay {
            width: 100%;
            height: 100%;
          }

          img {
            position: absolute;
            top: 12px;
            left: 12px;
          }
        }

        .type {
          height: 48px;
          padding: 15px;
          font-family: $lato;
          font-weight: 800;
          line-height: 18px;
        }
      }
    }
  }

  .discard-modal {
    text-align: center;
    border-radius: $border-radius-minimal;
    padding: 30px;
    max-width: 520px;
    max-height: 267px;
    h1 {
      font-weight: 700;
      font-size: 22px;
      line-height: 20px;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin: 0 auto;
      padding: 20px;
      max-width: 344px;
    }
    .actions {
      align-self: flex-end;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 75%;
      margin-top: 15px;
      @include buttonAlt;
      .Button {
        width: auto;
        &:last-of-type {
          margin-left: 12px;
          button {
            background-color: color(purple, darker);
            color: #fff;
            border: 0px;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .FormBottomBar {
    // this effects onboarding fixme
    background: #fff;
    @include buttonAlt;
    .Button {
      @include buttonRegular;
    }
  }
}

@media (max-width: 680px) {
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .Gigit {
      min-height: calc(100vh - 75px) !important;
    }

    body,
    html,
    #root {
      min-height: calc(100vh - 75px) !important;
    }

    .story {
      overflow-y: auto;
    }
  }

  .CreateStory {
    overflow: auto;

    .story-drawer {
      display: none;
    }

    .discard-modal-container {
      height: 100%;
      max-height: unset;

      .discard-modal {
        max-width: unset;
        width: 75%;
        top: unset;

        .actions {
          width: 100%;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;

          .Button {
            margin: 10px 4px;

            button {
              max-width: unset;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .build-story {
      .story-edit-area {
        .story-card {
          width: 100%;
          height: 100%;
          border-radius: 0;

          .textbox {
            touch-action: none;

            .action {
              width: 30px;
              height: 30px;
            }

            .drag-handle {
              left: -15px;
            }

            .delete {
              right: -11px;
            }
          }
        }

        .crop-container {
          max-width: 100%;
          max-height: 100%;

          .zoom-container {
            position: absolute;
            bottom: 100px;
            left: 100px;
            z-index: 4;
            display: flex;
            flex-direction: column;
            max-width: 200px;

            .Button {
              button {
                margin: 0 auto;
              }
            }
          }

          .container {
            width: 100%;
            height: 100%;

            .reactEasyCrop_CropArea {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .entity-selection {
      max-width: 100%;
    }
    .search-input-container {
      display: none;
    }
    .heading {
      margin: 25px 15px;
    }
    .selection {
      max-height: 100%;
      max-width: 100%;
      .selections {
        margin-top: 0;
        flex-direction: column;
        .story-select-card {
          height: 145px;
          margin: 5px 0;
          p {
            height: auto;
            position: absolute;
            bottom: -35px;
            color: #fff;
          }
        }
      }
    }

    .FormTopBar {
      display: none;

      .header-wrap {
        i {
          color: black;
        }
      }
    }

    .FormBottomBar {
      bottom: 20px;
      background: none;

      .Button {
        &:first-of-type {
          button {
            color: color(light, secondaryBg);
            border-color: color(light, secondaryBg);
          }
        }
      }
    }
  }
}
