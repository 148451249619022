@import '../../../../../styles/variables.scss';

.HubManagementAnalyticsSupportedCauses {
  display: flex;
  flex-direction: column;

  .chart-container {
    h4 {
      font-family: $lato;
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      font-feature-settings:
        'pnum' on,
        'lnum' on,
        'salt' on,
        'ss09' on;
      color: black;
    }

    .date-container {
      display: flex;
      width: 100%;

      .context-menu-container {
        display: flex;
        flex-flow: row nowrap;
        color: color(light, fontColor);
        height: 44px;
        background: color(light, secondaryBg);
        border: 1px solid color(grey, border);
        transition: color 0.3s ease-in-out;
        padding: 0 21px;
        border-radius: 8px;

        .title {
          font-size: 16px;
          margin-right: 10px;
          height: 44px;
          line-height: 44px;
        }

        .menu-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.3s ease-in-out;

          &.show {
            transform: rotate(180deg);
          }
        }

        &.show {
          color: color(purple, darker);
        }

        &:hover {
          color: color(purple, darker);
          cursor: pointer;
        }
      }

      .date {
        width: 302px;
        height: 44px;
        background: color(light, secondaryBg);
        border: 1px solid color(grey, border);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 21px;
        border-radius: 8px;
        margin-right: 14px;

        label {
          margin-right: 14px;
          font-size: 16px;
        }

        .DateTimePickerContainer {
          width: 100%;
        }

        .dPicker {
          flex-grow: 1;

          input {
            width: 100%;
            border: none;
            text-align: left;
            outline: unset;
            font-size: 16px;

            &:focus {
              outline: unset;
            }
          }
        }

        i {
          flex-grow: 0;
          color: color(grey, border);
          font-size: 20px;
        }
      }
    }

    .chart {
      margin-top: 40px;

      &.Bar {
        max-height: 450px;
      }

      &.Line {
        max-height: 315px;
      }
    }
  }
}
