@import '../../../../styles/variables.scss';

.LivestreamManagement {
  position: relative;

  .no-streamers {
    color: color(grey);
    text-align: center;
    padding-bottom: 20px;
  }

  .add-stream-modal {
    .title,
    .subtitle,
    .description,
    .TextField {
      padding: 0px 40px;
    }

    .title {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      padding-top: 40px;
      padding-bottom: 20px;
    }

    .subtitle {
      font-weight: 500;
      padding-bottom: 5px;
    }

    .description {
      font-size: 14px;
      padding-bottom: 20px;
      color: #52646c;
    }

    .TextField {
      padding-bottom: 40px;
    }
  }

  .add-stream {
    padding: 0px 40px 20px;

    .add-stream-inner {
      background-color: color(cream, lighter);
      height: 50px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        margin-left: 5px;
        font-weight: 500;
      }

      i {
        color: color(red, dark);
        font-size: 17px;
        height: 17px;
      }

      &:hover {
        cursor: pointer;

        i,
        span {
          color: color(purple, dark);
        }
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0px 40px;
    margin: 0px 0px 20px;
    max-height: 300px;
    min-height: 300px;
    overflow: auto;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      background-color: #f5f6f7;
      padding: 0px 15px;
      border-radius: 8px;
      margin-bottom: 8px;

      &.streamer-inner {
        display: flex;
      }

      i {
        font-size: 31px;
        height: 31px;

        &.fa-grip-lines {
          font-size: 27px;
          height: 24px;
        }

        &.fa-trash-alt {
          font-size: 23px;
          height: 23px;
          color: #829096;

          &:hover {
            cursor: pointer;
            color: color(red, dark);
          }
        }

        &.fa-toggle-on {
          color: color(purple, dark);

          &:hover {
            cursor: pointer;
          }
        }

        &.fa-toggle-off {
          color: #829096;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .streamer {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 0px 10px;

        .displayName {
          font-weight: 500;
          font-size: 18px;
        }

        .twitch {
          margin-left: 5px;
          color: #484b4d;
          font-size: 14px;
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: row-reverse;
    padding: 20px 40px;
    border-top: 1px solid #c6d5d8;

    .Button {
      margin-left: 20px;

      &:last-child {
        button {
          background-color: #fff;
          border: 2px solid #b6c5cb;
          color: #52646c;

          &:hover {
            border: 2px solid color(purple, dark);
            color: color(purple, dark);
          }
        }
      }
    }
  }
}
