@import '../../../styles/variables.scss';

.AssignedUsersModal {
  .assigned-user-list {
    overflow-y: auto;
    padding: 40px;
    height: 200px;

    .assigned-user {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      align-items: center;

      .name {
        flex: 1;
        margin-left: 10px;
      }

      .content-menu {
        flex-basis: 40px;
      }
    }
  }
}
