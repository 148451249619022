@import '../../../styles/variables.scss';

.Livestream {
  .subtitle {
    padding: 20px 0px 0px;
    font-weight: 700;
    font-size: 18px;
  }

  .total-online {
    color: #809198;
    font-size: 14px;
  }

  .livestream-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #70a3b7;

    i {
      font-size: 64px;
      color: #fff;
    }

    .details {
      color: #fff;
      max-width: 380px;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      margin-top: 15px;
    }
  }

  .livestream-streamers {
    list-style-type: none;
    padding: 20px 0px 0px;
    margin: 0px;

    li {
      display: flex;
      flex: 1;
      border-radius: 6px;
      padding: 8px;
      opacity: 0.5;

      &.live {
        opacity: 1;
      }

      &:hover {
        background-color: #eaeff0;
        cursor: pointer;

        .play {
          i {
            opacity: 1;
          }
        }
      }

      .play {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 20px;
        border-right: 2px solid #dae0e1;

        &.is-watching {
          i {
            opacity: 1 !important;
            color: color(purple, dark);
          }
        }

        i {
          width: 14px;
          height: 16px;
          opacity: 0;
          color: #809198;
        }
      }

      .streamer-info {
        display: flex;
        align-items: center;
        margin-left: 20px;
        flex: 1;

        .streamer-actions {
          display: flex;
          flex-flow: row nowrap;

          .Button {
            &.view {
              margin: 0 10px 0 10px;
            }
          }
        }

        .Portrait {
          margin-right: 8px;
        }

        .streamer-text {
          flex: 1;
          display: flex;
          flex-direction: column;

          .streamer-raised {
            font-size: 12px;
            font-weight: 500;
            color: #012939;
          }

          .streamer-name {
            display: flex;

            .name-inner {
              flex: 1;
              color: #343d43;
              font-weight: 500;
            }

            .status {
              display: flex;
              align-items: center;
              justify-content: center;
              color: #354a53;
              font-weight: 700;
              letter-spacing: 0.5px;
              font-size: 11px;
              background-color: #fff;
              padding: 0px 3px;
              border-radius: 4px;

              i.fa-circle {
                color: #eb3224;
                margin-right: 2px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .Livestream {
    .livestream-streamers {
      li {
        .play {
          display: none;
        }

        .streamer-info {
          margin-left: 0px;
        }
      }
    }
  }
}
