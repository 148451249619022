@import '../../styles/variables.scss';

.Toaster {
  max-width: 600px;
  position: fixed;
  top: 0px;
  left: 50%;
  margin-left: -300px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 25px;
  z-index: 100;
  padding-bottom: 20px;

  a {
    color: white;
    font-weight: bold;

    &:hover,
    &:active {
      color: white;
    }
  }
}

@media (max-width: 600px) {
  .Toaster {
    width: 100%;
    max-width: auto;
    top: 0px;
    left: 0px;
    margin-left: 0px;
  }
}
