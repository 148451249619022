@import '../../styles/variables.scss';

.GigManagement {
  flex: 1;
  display: flex;

  .GigManagement-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    .search {
      display: flex;
      align-items: center;

      .TextField {
        .input-wrap {
          input,
          i {
            background-color: color(light, secondaryBg);
          }
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      flex: 1;

      .headers,
      .row {
        .col {
          display: flex;
          flex: 1;
          font-weight: 700;

          &:first-child {
            text-indent: 8px;
          }

          &.gig-title {
            flex: 1 0 120px;
            min-width: 250px;
          }

          &.date {
            flex: 0 1 160px;
          }

          &.status {
            flex: 0 1 180px;
            min-width: 175px;
          }

          &.event {
            flex: 0 1 220px;

            a {
              color: color(purple, dark);
              text-decoration: none;
              font-weight: 500;

              &:hover {
                color: color(purple, base);
              }
            }
          }

          &.actions {
            justify-content: flex-end;
            padding-right: 40px;
            position: relative;
            flex-basis: 80px;
            flex-grow: 0;
            box-sizing: border-box;

            .ContextMenu {
              display: none;
              position: absolute;
              top: 55px;
              right: 10px;
              box-shadow: $box-shadow;
              border-radius: 20px;

              i {
                font-size: 16px;
                padding-right: 8px;
              }

              &:hover {
                display: block;
              }
            }

            i.fa-ellipsis-h-alt {
              font-size: 24px;
              height: 40px;
              width: 40px;
              background-color: color(blue, lightest);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }

            i.fa-ellipsis-h-alt:hover + .ContextMenu {
              display: block;
            }
          }
        }
      }

      .headers {
        background-color: color(blue, lightest);
        display: flex;
        flex: 0;
        min-height: 40px;
        height: 40px;
        line-height: 40px;
      }

      .list-inner {
        flex: 1;
        max-height: 100%;
        overflow: auto;
        position: relative;

        .list-rows {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;

          .empty {
            flex: 1;
            padding: 30px 20px;
            text-align: center;
            color: color(grey);
            font-size: 16px;
          }
        }

        .row {
          display: flex;
          height: 60px;
          border-bottom: 1px solid color(grey, light);

          .col {
            font-weight: 400;
            align-items: center;
          }

          .gig-title {
            color: #354a53;
            font-size: 15px;
            min-width: 250px;
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
}
