@import '../../styles/variables.scss';

body.embed {
  background-color: transparent !important;
  overflow: hidden !important;

  main {
    background-color: transparent !important;
    padding-top: 0px;
    height: auto;
    display: block;
  }

  .Header,
  .Footer,
  .mobile-footer-wrapper {
    display: none !important;
  }

  .hs-cookie-notification-position-bottom {
    display: none;
  }
}

.Embed {
  padding: 20px;

  a {
    display: flex;
    border: 0px;
    padding: 8px 28px;
    font-size: 16px;
    border-radius: $border-radius;
    justify-content: center;
    align-items: center;
    color: color(dark, fontColor);
    background-color: color(purple, dark);
    overflow: hidden;
    box-shadow: $box-shadow;
    text-decoration: none;
    font-weight: 500;
  }
}
