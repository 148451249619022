@import '../../../styles/variables.scss';

.PageContent {
  padding: 10px;
  flex: 1;
  border-radius: $border-radius;
  border-top-left-radius: 0px;
  display: flex;
  flex-direction: column;

  .add-component {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: color(pink, base);
    background-color: color(cream, dark);
    height: 60px;
    max-height: 60px;
    line-height: 60px;
    cursor: pointer;
    border-color: transparent;
    border-radius: 10px;
    margin-bottom: 30px;
    font-family: $lato;

    i {
      padding-right: 10px;
      font-size: 20px;
    }

    span {
      font-size: 16px;
      font-weight: 700;
      color: color(grey);
    }

    &:hover {
      color: color(purple, dark);

      span {
        color: color(purple, dark);
      }
    }
  }

  .loading {
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;

    .Loader {
      i {
        font-size: 40px;
      }
    }
  }
}

@media (max-width: 1240px) {
  .PageContent {
    margin-top: 60px;
  }
}
