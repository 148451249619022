@import '../../../styles/variables.scss';

.PageSidebar {
  flex: 1;
  max-width: 360px;
  min-width: 360px;

  /* GIG-1037: This fixes visual bug where Modals on sidebar appear under main page content.
    TODO: Proper fix would be to use React Portals in Modal Component. */
  /*  you can use position:relative; to fix this, z-index doesn't completely solve the problem -- SR */
  /*z-index: 2;*/
  position: relative;
}
