@import '../../../styles/variables.scss';

.ConversationHeader {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  min-height: 58px;
  border-bottom: 1px solid #d0d1d1;

  .ConversationTile {
    .convo-li {
      flex: 1;
      .convo-li-title {
        font-size: 16px;

        &:hover {
          color: color(purple, dark);
        }
      }
    }
    .action-menu-target {
      flex: 0;
    }
  }

  &.stack {
    margin-left: 10px;

    .user-info {
      display: none;
    }

    .Portrait {
      margin: 0px -8px;

      &:hover > {
        ~ .user-info {
          display: flex;
          position: relative;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
