@import '../../../styles/variables.scss';

.BlockSettings {
  width: 100%;
  .blocked-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 35px;
    margin: 10px 0;
    justify-content: space-between;

    .unblock-user {
      .Button {
        margin-left: 8px;
        button {
          border-radius: 8px;
          min-height: 0;
        }
      }
    }
  }
}
