@import '../../../../styles/variables.scss';

.HubManagementSpotlights {
  display: flex;
  flex-direction: column;

  .add-spotlight {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    .title {
      display: flex;
      flex-direction: column;

      p {
        font-weight: 600;
        margin: 5px 0;
        color: #3a3a3a;
      }

      span {
        font-size: 14px;
        display: flex;
        color: #a9aaac;
      }
    }
  }

  .spotlight-causes {
    display: flex;
    flex-direction: column;
    padding: 25px;

    .spotlight-cause {
      display: flex;
      height: 60px;
      align-items: center;
      border-radius: 8px;

      &:hover {
        cursor: pointer;
      }

      .Portrait {
        margin-left: 5px;
      }

      .cause-info {
        display: flex;
        align-items: center;
        flex: 2;

        p {
          font-weight: 600;
          margin-left: 10px;
          line-height: 16px;
          font-size: 14px;
          color: #3a3a3a;
        }
      }

      .program-start-date,
      .program-end-date {
        flex: 1;
        font-weight: 400;
        color: #3a3a3a;
        font-size: 14px;
      }

      .spotlight-actions {
        display: flex;
        justify-content: space-between;
        flex: 1;
        max-width: 65px;

        svg {
          &:hover {
            path {
              fill: color(purple, dark);
            }
          }
        }
      }
    }
  }

  .no-spotlights {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    p {
      font-size: 16px;
      font-weight: 600;
      color: #3a3a3a;
    }

    span {
      font-size: 14px;
      color: #a9aaac;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .add-spotlight-modal {
    width: 688px;
    min-height: 500px;
    background: color(light, secondaryBg);
    border-radius: 10px;
    padding: 48px;
    .ModalHeader {
      position: absolute;
      right: 30px;
    }

    .modal-header {
      border-bottom: 1px solid color(dark, borderLight);
      padding-bottom: 20px;

      h2 {
        margin: 0 0 10px 0;
        font-weight: 700;
        font-size: 22px;
        color: black;
      }

      span {
        font-weight: 600;
        font-size: 12px;
        color: color(grey, text);
      }

      .filter-container {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        align-items: self-end;
        position: relative;

        .filter-dropdown {
          margin-right: 20px;
          min-width: 170px;

          .input-wrap {
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 700;
            font-size: 16px;
            padding: 0 23px 0 16px;
            border: 1px solid color(grey, border);

            .menu-btn {
              align-items: center;
              justify-content: center;
              transition: transform 0.3s ease-in-out;

              &.show {
                transform: rotate(180deg);
              }
            }

            .label {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            }

            &:hover {
              cursor: pointer;
            }
          }

          label {
            z-index: 1;
            bottom: -11px;
            left: 9px;
            background-color: white;
            font-weight: normal;
            position: relative;
            padding: 5px;
            width: fit-content;
            color: color(grey, dark);
          }

          &:hover {
            cursor: pointer;
          }
        }

        .TextField {
          max-width: 450px;

          .input-wrap {
            width: 100%;
            background: color(light, secondaryBg);
            border: 1px solid color(grey, border);
            box-sizing: border-box;
            border-radius: 8px;
            height: 44px;

            i {
              color: color(grey, text);
            }
          }
        }
      }
    }
    .btn-container {
      margin-top: 21px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      column-gap: 15px;
    }
    .modal-main {
      display: flex;
      flex-direction: column;
      max-height: 662px;
      overflow: auto;
      width: 100%;
      height: 100%;
      border-bottom: 2px solid color(dark, borderLight);
      scrollbar-width: thin;
      scrollbar-color: color(grey, border) color(grey, base3);

      &::-webkit-scrollbar-track {
        background: color(grey, base3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: color(grey, border);
        border-radius: 20px;
      }
      &::-webkit-scrollbar {
        width: 8px;
      }

      .Loader {
        place-self: center;
      }

      .partners-container {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        padding: 24px 0;

        .empty-state {
          display: flex;
          position: relative;
          justify-content: center;
        }

        .to-add-partner {
          padding: 0 28px 0 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 16px;
          max-width: 640px;
          height: 80px;
          background: color(light, secondaryBg);
          border: 1px solid color(light, shadow);
          box-sizing: border-box;
          border-radius: 10px;

          img {
            flex-grow: 0;
            border: 1px solid color(grey, border);
            box-sizing: border-box;
            border-radius: 20px;
            overflow: hidden;
          }

          .info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            row-gap: 4px;

            .title {
              font-weight: 800;
              font-size: 16px;
              color: color(grey, dark);
            }

            .charity-id {
              font-weight: 500;
              font-size: 12px;
              color: color(grey, text3);
            }
          }

          .Checkbox {
            flex-grow: 0;
          }
        }
      }
    }

    .DateSelection {
      padding: 0 3px;

      label {
        font-weight: 700;
        margin: 10px 0;
      }

      div {
        margin-top: 8px;
      }

      .date-row {
        margin: 15px 0;
        .date-container {
          .custom-dtp {
            input {
              text-indent: 5px;
              border: 1px solid color(grey, lightest);
            }
          }
        }
      }
    }
  }
}
