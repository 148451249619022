@import '../../../../../styles/variables.scss';

.ContentBlockLayoutModal {
  &.Modal {
    .content {
      min-height: 500px;
    }
  }

  .content-block-list-container {
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;
    max-width: 350px;
    align-items: center;
    justify-content: center;

    .content-block-option {
      position: relative;
      display: flex;
      border: 1px solid color(grey, lightest);
      border-radius: 10px;

      i.selected {
        position: absolute;
        right: 0;
        margin: 15px;
        padding: 5px;
        color: white;
        background-color: color(plum, dark);
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        z-index: 1;
      }

      &.selected {
        border-color: color(plum, dark);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
