@import '../../styles/variables.scss';

.LogVolunteerHours {
  display: flex;
  flex-direction: column;
  padding: 25px 50px;
  width: 753px;
  max-height: 600px;
  height: 600px;

  .time-input {
    max-width: 100px;
  }

  &.editing {
    .row {
      margin: 10px 0;
      &.actions {
        margin-top: 20px;
      }
    }
  }

  @include kambeoScroll;
  h2 {
    font-family: $raleway;
    color: #3a3a3a;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
  }

  .row {
    margin: 48px 0;
    padding-right: 25px;

    label {
      span {
        color: #000;
      }
    }

    &.date {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      label {
        span {
          color: #000;
        }
      }

      .Button {
        margin-top: 20px;
        button {
          min-width: 100px;
          background-color: #2f2956;
        }

        &:hover {
          button {
            background-color: #433f64;
          }
        }
      }
    }

    &.actions {
      flex-grow: 0;
      display: flex;
      gap: 20px;
      justify-content: flex-end;
      position: sticky;
      right: 0;
      bottom: 0;
      background: #fff;
      margin: 0;
      padding-right: 0px;
    }
  }

  .Tabs {
    flex-grow: 1;

    .LogHoursContent,
    .HistoryTable {
      max-height: 400px;
      max-width: 750px;
      margin-right: -40px;
      overflow: auto;

      .select-cause-container {
        display: flex;
        flex: 1 1;
        flex-direction: column;

        .select-cause-label {
          font-size: 14px;
          font-weight: 700;
          padding-bottom: 4px;
        }
        span {
          cursor: pointer;
          min-height: 38px;
          box-sizing: border-box;
          border-width: 1px;
          border-style: solid;
          border-radius: 4px;
          border-color: #d0d1d1;
          display: grid;
          -webkit-box-align: center;
          align-items: center;
        }
        .select-cause-empty {
          color: color(hsl(0, 0, 50%));
          padding: 2px 8px;
        }
        .select-cause-selected {
          padding-left: 16px;
          display: flex;
          flex-direction: row;
          font-family: $lato;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;

          i {
            color: color(grey, lighter);
            font-size: 24px;
            padding-right: 10px;
          }

          .selected-cause-name {
            padding-left: 8px;
            border: none;
            width: fill;
          }

          &.disabled {
            opacity: 0.5;
          }
        }

        &.disabled {
          pointer-events: none;
        }
      }
    }

    .TabsRoot {
      height: 100%;
    }

    .TabsList {
      position: sticky;
      top: 0;
      z-index: 10;

      .TabsTrigger[data-state='active'] {
        color: #3a3a3a;
        box-shadow:
          inset 0 0px 0 0 currentColor,
          0 2px 0 0 currentColor;
      }
    }
  }
}

// Kind of hacky we can avoid media queries with better base styling
@media (max-width: 880px) {
  .LogVolunteerHours {
    display: flex;
    width: unset;
    height: unset;
    overflow: auto;
  }
}

@media (max-width: 670px) {
  .LogVolunteerHours {
    padding: 15px;
    margin-top: 5px;
    padding-bottom: calc(1rem + env(safe-area-inset-bottom));
    .row {
      &.date {
        flex-direction: column;
        align-items: unset;

        .Button {
          align-self: end;
        }
      }
    }
  }

  // hack to deal with style hell
  .Modal.LogVolunteerHoursModal {
    .ModalHeader {
      .close-button {
        left: unset;
        right: 10px;
      }
    }
  }
}
