@import '../../../../styles/variables.scss';
.EntityBaseInfo {
  display: flex;
  flex-direction: column;
  width: 100%;

  .FormSlider {
    flex: 2;
  }
  label {
    font-size: 14px;
    font-weight: bold;
    color: #012939;
  }
  .row {
    &.slider-custom {
      margin: 8px 0;
      display: flex;
      align-items: center;

      .TextField {
        max-width: 100px;
        margin-left: 15px;

        .input-wrap {
          border: 1px solid color(grey, dark);
          input {
            text-align: center;
            max-width: 100%;
            color: color(grey, light);
            font-weight: 400;
          }
        }
        label {
          display: none;
        }
      }
    }
  }
}
