@import '../../../styles/variables.scss';

.DonationListExpandedCell {
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 400px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 769px;
  @include thin-scroller-y;

  .main-sec {
    margin-bottom: 30px;
    width: 340px;

    .main-title {
      display: block;
      text-align: left;
      font-family: $raleway;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 16px;
      font-feature-settings:
        'pnum' on,
        'lnum' on,
        'salt' on,
        'ss09' on;
      color: color(grey, dark);
      padding-bottom: 12px;
      margin-bottom: 24px;
      border-bottom: 1px solid rgba(169, 170, 172, 0.25);
    }

    .item-container {
      margin-bottom: 24px;

      .title {
        font-family: $lato;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        font-feature-settings:
          'pnum' on,
          'lnum' on,
          'salt' on,
          'ss09' on;
        color: color(grey, dark);
        margin-bottom: 10px;
      }

      .items {
        font-family: $lato;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        font-feature-settings:
          'pnum' on,
          'lnum' on,
          'salt' on,
          'ss09' on;
        color: color(grey, base);
        display: grid;
        grid-template-columns: 1fr;

        .item {
          margin: 4px 0;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 3px;

          &.status {
            width: max-content;
            height: 30px;
            text-transform: capitalize;
            line-height: 30px;
            display: inline-flex;
            justify-content: center;
            padding: 0 12px;
            border-radius: 8px;

            &.payed_out {
              background-color: color(yellow, fade);
              color: color(yellow, text);
            }

            &.paid,
            &.matched,
            &.send {
              background-color: color(green, fade);
              color: color(green, soft);
            }

            &.unpaid,
            &.declined,
            &.refused {
              background-color: color(red, fade);
              color: color(red, soft);
            }

            &.refunded,
            &.pending,
            &.not_created {
              background-color: color(grey, pale);
              color: color(dark, border);
            }
          }

          img {
            &.small {
              width: 40px;
              height: 40px;
              border-radius: 20px;
              margin-right: 10px;
            }
          }

          i {
            margin: 0 4px;

            &.card {
              font-size: 32px;

              &.visa {
                color: color(creditCards, visa);
              }
              &.mastercard {
                color: color(creditCards, mastercard);
              }

              &.amex {
                color: color(creditCards, amex);
              }

              &.discover {
                color: color(creditCards, discover);
              }

              &.diners-club {
                color: color(creditCards, diners-club);
              }

              &.jcb {
                color: color(creditCards, jcb);
              }
            }
          }

          .more {
            font-style: italic;
            cursor: pointer;
            color: color(purple, dark);
          }
        }
      }
    }
  }
}
