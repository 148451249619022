@import '../../styles/variables.scss';

.dark {
  .ForgotPassword {
    .inner {
      p {
        color: color(dark, fontColor);

        span {
          &:hover {
            color: color(dark, fontColor);
          }
        }
      }

      .row,
      .dob {
        .col {
          .TextField {
            input {
              background-color: color(dark, primaryBg);
            }
          }
        }
      }
    }
  }
}

.light {
  .ForgotPassword {
    .inner {
      p {
        color: color(light, fontColor);

        span {
          &:hover {
            color: color(light, fontColor);
          }
        }
      }

      .row,
      .dob {
        .col {
          .TextField {
            input {
              background-color: color(light, primaryBg);
            }
          }
        }
      }
    }
  }
}

.ForgotPassword {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: color(cream, dark);

  .curve-wrap {
    position: absolute;
    background-color: color(cream, light);
    width: 70vw;
    height: 70vw;
    min-height: 450px;
    min-width: 450px;
    margin: 0px auto;
    padding: 40px 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ResetPassword-inner {
    position: relative;
    background-color: color(cream, light);
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
    padding: 40px 20px;
    border-radius: $border-radius;

    form {
      padding: 0px 20px;
    }

    h2 {
      margin: 0 0 20px 0;
      text-align: left;
      font-size: 34px;
      font-weight: 500;
    }

    .logo {
      max-width: 150px;
      margin: 0px auto 30px;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .Button,
      .Button button {
        flex: 1;
        width: 100%;
      }
    }

    .links {
      margin: 30px 0px 0px;
      display: flex;
      flex: 1;
      justify-content: space-between;

      a {
        color: color(grey);
        text-decoration: none;
        font-weight: 500;
        font-size: 15px;
      }
    }

    .row {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 620px;
      margin: 0px auto;

      .col {
        flex: 1;
        padding-bottom: 25px;

        .TextField,
        .Dropdown {
          .input-wrap {
            background-color: color(grey, lighter);
          }
        }
      }
    }

    .dob {
      display: flex;
      flex: 1;
      max-width: 620px;
      margin: 0px auto;
      padding-bottom: 20px;

      .col {
        flex: 1;

        .TextField {
          margin-right: 20px;
        }

        &:last-child {
          .TextField {
            margin-right: 0px;
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .ResetPassword {
    .ResetPassword-inner {
      margin: 20px;
    }
  }
}
