@import '../../../styles/variables.scss';

.TableComponent {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  margin-bottom: 100px;

  .table-header-container {
    display: flex;
    flex-flow: row nowrap;
    padding: 10px 0;

    .searchField {
      height: 44px;
      background-color: transparent;
      margin-bottom: 15px;
      max-width: 350px;

      .input-wrap {
        max-width: unset;
        width: 100%;
        height: 44px;
        line-height: 44px;
        background: #f5f5f5;
        border: 0;
        border-radius: 10px;
        display: flex;

        input {
          background-color: transparent;
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;

          &::placeholder {
            color: #a9aaac;
          }
        }

        i {
          background-color: unset;
          font-size: 14px;
          line-height: 44px;
          margin: 2px 0 0 15px;
          color: color(grey, border);
          height: 40px;
          width: 30px;
          min-width: 30px;
        }
      }
    }

    .header-actions-container {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      gap: 10px;

      button {
        max-width: 250px;
      }
    }
  }

  .table-wrap {
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    @include thin-scroller-y;
  }

  .pagination-container {
    display: flex;
    margin: 10px 0 10px 0;

    .pagination-metrics-container {
      display: flex;
      color: #6f7072;
      font-size: 14px;
      font-weight: 700;
    }

    .buttons-container {
      display: flex;
      margin-left: auto;
      gap: 20px;

      i {
        &.disabled {
          color: color(grey, light);

          &:hover {
            cursor: not-allowed;
            color: color(grey, light);
          }
        }
        &:hover {
          cursor: pointer;
          color: color(purple, darker);
        }
      }
    }
  }

  table {
    height: 100%;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-top: 1px solid color(light, shadow);

    thead {
      height: 50px;
      border-bottom: 1px solid color(grey, lighter);
    }

    tr {
      td {
        height: 30px;
        max-width: 300px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        font-size: 14px;
        font-weight: 600;

        & > * {
          overflow: hidden;
          text-overflow: ellipsis;
          color: #3a3a3a;
        }

        .show-full-cell > * {
          height: auto;
          position: absolute;
          top: 2px;
          left: 3px;
          padding: 5px;
          border: 1px solid color(light, shadow);
          background-color: white;
          box-shadow: $box-shadow;
          z-index: 1000;
        }

        &.show-full-cell {
          overflow: visible;
        }

        &.action-menu-button-column {
          .button-action-container {
            display: flex;
            justify-content: flex-end;
          }
        }

        &.action-menu-column {
          position: sticky;
          right: 0;
          width: 15px;

          .action-menu-target {
            i {
              max-width: 15px;
              color: color(grey, dark);
              font-weight: 400;
              font-size: 22px;

              &.show-menu {
                color: #616161;
              }

              &:hover {
                color: #616161;
                cursor: pointer;
              }
            }

            &.disabled {
              i {
                color: color(grey, light);
                cursor: not-allowed;
              }
            }
          }
        }

        &.selectable-column {
          width: 20px;

          i {
            color: color(grey, light);
          }

          input:disabled {
            & ~ i {
              cursor: no-drop;
            }
          }
        }

        &.cell-link {
          color: color(purple, dark);

          &:hover {
            color: color(purple, darker);
            cursor: pointer;
          }
        }

        .cell-status {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 3px 12px;
          border-radius: 8px;
          width: fit-content;

          &.GREEN {
            color: color(green, text);
            background-color: color(green, fade);
          }

          &.GREY {
            color: color(grey, text3);
            background-color: color(grey, pale);
          }

          &.YELLOW {
            color: color(yellow, text);
            background-color: color(yellow, fade);
          }

          &.RED {
            color: color(red, soft);
            background-color: color(red, fade);
          }
        }
      }

      th {
        font-size: 14px;
        color: color(grey, dark);
        text-align: left;
        font-weight: 700;

        &.sortable {
          i {
            margin-left: 8px;
          }
          &:hover {
            color: color(grey, darkest);
            cursor: pointer;
          }
        }
      }

      th,
      td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid color(light, shadow);
      }

      th {
        border-bottom: 0px;
      }

      &.table-filler {
        height: 100%;

        td {
          border-bottom: 1px solid color(grey, lighter);

          & > .empty-state {
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            padding: 50px;

            .title {
              margin-bottom: 8px;
              font-weight: 800;
              font-size: 16px;
              color: #5a5b5d;
            }

            .description {
              font-weight: 600;
              font-size: 14px;
              color: #a9aaac;
            }

            .icon {
              margin-bottom: 30px;
            }
          }

          & > .Loader {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 40px;
          }
        }
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
}

@media (max-width: 768px) {
  .TableComponent {
    table {
      width: calc(100vw - 60px);
      overflow: hidden;
      tr {
        td {
          &.action-menu-column {
            background: #fff;
            padding: 0.75rem;
          }
        }
      }
    }
  }
}
