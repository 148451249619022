@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

@include skeletonLoaderKeyframes;

.StoryCarouselSkeleton {
  max-height: 215px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .stories-container {
    display: flex;
    margin-bottom: 25px;

    .story {
      width: 130px;
      display: flex;
      flex-direction: column;
      margin-right: 10px;

      .background-img {
        width: 100%;
        height: 196px;
        background: #e9e9e8;
        border-radius: 10px;
        box-shadow: $box-shadow;
      }

      .profile-img {
        position: absolute;
        margin: 10px;
        width: 30px;
        height: 30px;
        border: 1px solid #ffffff;
        border-radius: 50%;
        background: #e9e9e8;
      }
    }
  }

  @include skeletonLoaderAnimation;
}
