@import '../../styles/variables.scss';

.InfoAdminTab {
  .forms-sub-title {
    flex: 1 1;
    font-size: 24px;
    font-weight: 500;
    margin-top: 15px;
  }

  .sub-section {
    padding-left: 20px;

    & > * {
      margin-top: 5px;
    }
  }
}
