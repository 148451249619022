@import '../../styles/variables.scss';

.LandingDiscoverHeader {
  height: 65px;
  place-self: flex-start center;

  .discover-subheader {
    position: static;
    top: 80px;
    background: color(light, secondaryBg);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    height: 70px;
    border-top: 1px solid color(grey, pale);
  }

  .subheader-section-mobile {
    display: none;
  }

  .subheader-section {
    display: flex;
  }

  .subheader-section,
  .subheader-section-mobile {
    margin: 0 auto;
    width: 1284px;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px 0;
    column-gap: 28px;

    li {
      list-style: none;
    }

    .subheader-item {
      transition: all 0.2s;
      font-family: $raleway;
      font-weight: 700;
      font-size: 14px;
      background: color(light, secondaryBg);
      border: 1px solid color(light, shadow);
      border-radius: 100px;
      color: color(grey, dark);
      text-decoration: none;
      padding: 13px 25px;
      white-space: nowrap;
      height: 44px;

      &:hover,
      &.active {
        color: color(light, secondaryBg);
        background-color: color(plum, dark);
        border-color: color(plum, dark);
        border-radius: 28px;
      }
    }
  }
}

@media (max-width: 1070px) {
  .LandingDiscoverHeader {
    .discover-subheader {
      width: 100vw;
      box-shadow: unset;
      top: 0;
      position: relative;

      .subheader-section {
        display: none;
      }

      .subheader-section-mobile {
        display: flex;
        width: auto;
        max-width: calc(100vw - 20px);
        overflow-x: auto;

        .page-tab-container {
          li {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;

            a {
              line-height: 44px;
              padding: 0 25px;
              border-color: color(light, secondaryBg);
              transition: all 0.2s;
              background-color: color(light, secondaryBg);
              color: color(grey, dark);

              &.active {
                background-color: color(light, secondaryBg);
                border-color: color(grey, dark);
                color: color(grey, dark);
              }
            }
          }
        }
      }
    }
  }
}
