@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

@include skeletonLoaderKeyframes;

.CommunityFeedLeftSkeleton {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  box-shadow: $box-shadow;
  align-items: center;

  .cover-img {
    width: 100%;
    height: 100px;
    background: #e9e9e8;
    border-radius: 10px 10px 0 0;
  }

  .profile-img {
    position: absolute;
    top: 40px;
    left: 95px;
    width: 80px;
    height: 80px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    background: #e9e9e8;
  }

  .profile-name {
    position: relative;
    margin: 35px 60px;
    width: 148px;
    height: 24px;
    background: #e9e9e8;
    border-radius: 10px;
  }
  .metrics {
    width: 90%;
    border-top: 1px solid #d8d8d8;
    padding-top: 10px;

    .metric-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 15px 0;
      .metric-title {
        display: flex;
        height: 20px;
        width: 150px;
        border-radius: 10px;
        background: #e9e9e8;
      }

      .metric-amount {
        display: flex;
        height: 20px;
        width: 40px;
        border-radius: 10px;
        background: #e9e9e8;
      }
    }
  }

  @include skeletonLoaderAnimation;
}

.StartingButtons {
  min-height: 320px;

  .title {
    left: -60px;
    position: relative;
    height: 20px;
    width: 100px;
    border-radius: 10px;
    background: #e9e9e8;
    margin: 20px 0;
  }

  span {
    margin: 10px;
    border-radius: 20px;
    width: 85%;
    height: 40px;
    background: #e9e9e8;
  }
}
