@import '../../../../styles/variables.scss';

.VolunteerForDollarsDetails {
  .donation-matching-details,
  .donation-matching-inner {
    border-radius: 7px;
  }

  .match-summary {
    display: flex;
    flex-direction: column;
    border-top: 2px solid color(light, shadow);
    flex: 1;

    .ms-row {
      display: flex;
      flex: 1;
      padding-bottom: 15px;
      font-weight: 700;
      color: #52646c;
      font-size: 16px;

      &.ms-total {
        color: color(light, fontColor);
      }

      &:first-child {
        padding-top: 30px;
      }

      span {
        flex: 1;

        &.ms-value {
          text-align: right;
        }
      }
    }

    .ms-cta-subtext {
      color: #52646c;
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 30px;

      a {
        color: color(blue, soft2);
      }
    }

    .ms-cta {
      display: flex;
      background-color: #fafbfb;
      border: 2px solid #ebebeb;
      padding: 20px 40px;
      border-radius: 14px;
      margin: 30px 0px 20px;

      .ms-cta-text {
        font-size: 16px;
        font-weight: 700;
        color: #76868d;
        line-height: 24px;
        padding-left: 20px;

        span {
          color: color(light, fontColor);
        }
      }
    }
  }

  .match-text {
    padding: 20px 0px 0px;
    font-size: 16px;
    font-weight: 700;
    color: #52646c;
    line-height: 26px;
  }

  .match-slider {
    display: flex;
    padding-bottom: 30px;

    .FormSlider {
      flex: 1;
      display: flex;
      align-items: center;
    }

    &.slider-custom {
      margin: 8px 0;
      display: flex;
      align-items: center;

      .TextField {
        max-width: 100px;
        margin-left: 15px;

        .input-wrap {
          border: 1px solid color(grey, dark);
          input {
            text-align: center;
            max-width: 100%;
            color: color(grey, light);
            font-weight: 400;
          }
        }
        label {
          display: none;
        }
      }
    }

    .option {
      display: flex;
      padding-left: 20px;

      .option-inner {
        padding: 10px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        max-width: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        background-color: color(light, shadow);
        color: #fff;
      }

      .TextField {
        .input-wrap,
        input {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          width: 75px;
        }
      }
    }
  }

  .label {
    color: #52646c;
    font-size: 14px;
    padding: 30px 0px 10px 0px;
    font-weight: 700;
  }

  .donation-matching-details {
    border: 1px solid #b6c5cb;
    margin: 20px 0px 30px;

    .donation-matching-inner {
      border: 1px solid #e6e9eb;
      padding: 30px;

      .dm-title {
        padding: 0px;
        margin: 0px;
        border: 0px;
        font-size: 19px;
        font-weight: 700;
      }

      .donation-matching-content {
        display: flex;
        padding: 25px 0 25px 0;

        .dm-icon-wrap {
          max-width: 80px;
          min-width: 80px;
          display: flex;
          justify-content: center;
          align-items: center;

          .dm-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            background-color: color(purple, lightest);

            i {
              font-size: 26px;
              color: color(purple, dark);
            }
          }
        }

        .dm-text {
          padding-left: 10px;
          font-size: 16px;
          font-weight: 700;
          color: color(otherblue, grey);
          line-height: 26px;
        }
      }

      .dm-progress {
        .dm-bar {
          height: 16px;
          border-radius: 8px;
          background-color: color(grey, lightest);
          overflow: hidden;

          .dm-bar-fill,
          .dm-bar-fill-user {
            display: inline-block;
            height: 16px;
            transition: 1s all ease-in-out;
          }

          .dm-bar-fill {
            background-color: color(purple, dark);
            transition: 1s all ease-in-out;
          }

          .dm-bar-fill-user {
            background-color: color(orange, dark);
          }
        }

        .dm-amounts {
          display: flex;
          flex: 1;
          padding-top: 20px;

          .dm-remaining,
          .dm-contributed {
            flex: 1;
            color: color(grey, light);
            font-weight: 700;
          }

          .dm-remaining {
            text-align: right;
          }

          span {
            display: block;

            &:first-child {
              color: color(grey, dark);
            }
          }
        }
      }
    }
  }
}
