@import '../../../../../styles/variables.scss';

.ContentBlockManageModal {
  display: flex;

  .edit-text-container {
    max-width: 350px;
  }

  .manage-image-block-container {
    min-height: 100px;

    .CoverImage {
      min-height: 100px;
    }
  }

  .component-layout-container {
    border: 1px solid color(dark, border);
    border-radius: 10px;
  }

  // TODO: Move to generic component for ModalSectionRow
  .manage-text-block-container {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;

    .edit-text-container {
      display: flex;
      flex-flow: column nowrap;
      font-size: 14px;
      gap: 4px;

      .label {
        font-weight: 700;
        color: color(grey, text2);
      }
      .value {
        color: #828486;

        a {
          text-decoration: none;

          &:hover {
            cursor: pointer;
            color: color(purple, dark);
          }
        }
      }
    }
  }
}
