@import '../../styles/variables.scss';

.BalanceAndPayoutsManage {
  .context-menu-container {
    display: flex;
    flex-flow: row nowrap;
    color: color(light, fontColor);
    height: 44px;
    background: color(light, secondaryBg);
    border: 1px solid color(grey, border);
    transition: color 0.3s ease-in-out;
    padding: 0 21px;
    border-radius: 8px;

    .title {
      display: flex;
      font-size: 14px;
      margin-right: 10px;
      height: 44px;
      line-height: 44px;
    }

    .menu-btn {
      margin-left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s ease-in-out;

      &.show {
        transform: rotate(180deg);
      }
    }

    &.show {
      color: color(purple, darker);
    }

    &:hover {
      color: color(purple, darker);
      cursor: pointer;
    }
  }

  background-color: white;

  .account-selector {
    margin-left: 16px;
    min-width: 250px;
    text-align: center;

    .account-select {
      font-size: 14px;
    }
  }

  .forms-create {
    margin-left: auto;
  }

  .section-kpi {
    .KpiDisplay {
      padding: 0px !important;
      border-radius: 5px;

      .kpi-card {
        flex-direction: column-reverse !important;
        align-items: start;
        padding-left: 30px;
        display: flex;
        flex: 1;

        &:not(:first-of-type) {
          border-left: 1px solid #d0d1d1;
        }

        .kpi-text {
          font-size: 14px !important;
          color: #898989;
          font-weight: 600;
          margin-top: 10px;
        }

        .kpi-value {
          color: black;
          margin-bottom: 0px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .balance-section-inner {
    margin: 0px 30px 30px;
    border-radius: 15px;
    background-color: color(light, secondaryBg);
    flex: 1;
    flex-direction: column;
    display: flex;
    position: relative;

    .tab-selector {
      width: 100%;
      border-bottom: 1px solid #cad5d9;
      height: 60px;
      display: flex;
      flex-direction: row;

      .tab {
        font-family: $lato;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        padding: 20px 20px;
        cursor: pointer;

        &.selected {
          color: color(plum, dark);
          border-bottom: 2px solid color(plum, dark);
        }
      }
    }

    .tab-content {
      height: 100%;
      margin-top: 60px;

      .stripe-banner {
        margin-top: 40px;
        display: flex;
        align-items: center;
        height: 72px;
        width: auto;
        padding: 0 40px;

        img {
          margin-right: 20px;
        }

        span {
          font-family: $lato;
          font-style: normal;
          font-weight: 700;
          font-size: 21px;
          color: color(grey, dark);

          &.change {
            cursor: pointer;
            margin-left: 8px;
            color: color(dark, border);
          }
        }
      }

      &.overview {
        display: flex;
        border-bottom: 1px solid color(cream, border);

        .details-card {
          flex: 1;
          background: #ffffff;

          .banner {
            margin-bottom: 16px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }

          .horizontal-divider {
            border: 1px solid #cad5d9;
          }

          .card-info {
            height: 50px;
            display: flex;
            align-items: center;
            padding-left: 20px;
            background-color: #f4f4f4;
            font-weight: 600;
          }

          .card-info--inner {
            padding-bottom: 44px;
            margin-bottom: 30px;
            border-bottom: 1px solid color(cream, border);

            .capitalize {
              text-transform: capitalize;
            }

            h3 {
              margin: 35px 0 10px 0;
              font-family: $raleway;
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              color: color(grey, dark);
            }

            p {
              margin: 0;
              font-family: $lato;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              color: color(grey, text2);
            }
          }

          .cards {
            padding: 0 40px;
            display: flex;
            justify-content: space-between;
          }

          .card-detail-area {
            height: 410px;
            display: flex;
            width: 100%;
            flex-grow: 1;
            flex-direction: column;
            padding: 20px 0 30px 0;
            align-items: flex-start;
            justify-content: flex-start;

            .stripe-actions {
              display: flex;

              .Button {
                margin-right: 8px;

                &:last-of-type {
                  margin-right: 0;
                }
              }
            }

            .row {
              display: flex;
              flex-direction: row;
              margin-bottom: 20px;

              & > * {
                margin-right: 30px;
              }

              .overview-button {
                margin-left: auto;
              }
            }

            .column {
              display: flex;
              flex-direction: column;
            }

            p {
              line-height: 26px;

              &:first-of-type {
                font-size: 18px;
                font-weight: 600;
              }

              &:nth-of-type(2) {
                font-size: 16px;
              }
            }

            .details {
              flex-grow: 1;

              p {
                font-size: 17px;
                font-weight: 700;
                margin-bottom: 25px;
              }

              .warning {
                font-family: $lato;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                color: color(red, soft);

                i {
                  margin-right: 10px;
                }
              }
            }

            .add-edit-btn {
              flex-grow: 0;

              i {
                margin-top: 3px;
              }

              button {
                max-width: unset;
              }
            }
          }

          .field {
            min-height: 40px;
            flex-basis: 60px;
            display: flex;
            justify-content: space-between;

            .column {
              flex-basis: 60px !important;
            }

            .label {
              font-family: $lato;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              color: color(dark, border);
            }

            .value {
              min-width: 220px;
              margin-left: 20px;
              font-family: $lato;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              color: color(grey, dark);
            }

            .account-number {
              display: flex;
              flex-direction: row;

              i {
                margin-left: 5px;
                color: #57c0ac;
                cursor: pointer;
              }
            }
          }
        }
      }

      &.payout-history {
        display: flex;

        .list {
          display: flex;
          flex-direction: column;
          flex: 1;

          .section-inner-crumb {
            font-size: 1rem;
            display: block;
            .section-crumb-root.active-link:hover {
              cursor: pointer;
              text-decoration: underline;
            }

            .section-crumb-next {
              .fa {
                margin: 0 15px 10px;
              }
            }
          }

          .list-rows {
            width: 100%;

            .empty {
              padding: 0 20px;
              text-align: center;
              color: color(grey);
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .confused-section {
    display: flex;
    height: 98px;
    align-items: center;
    justify-content: space-between;
    background: color(light, purple);
    border-radius: 10px;
    padding: 0 36px;
    margin: 40px 0 0 0;

    &--text {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 14px;
      }

      p {
        margin: 0;
        font-family: $lato;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: color(grey, text2);

        b {
          margin-right: 13px;
        }
      }
    }
  }
}

@media (max-width: 1135px) {
  .BalanceAndPayoutsManage {
    .section-title {
      margin-bottom: 25px;
    }
    .account-selector {
      margin-right: 8px;
    }
  }
}

@media (max-width: 823px) {
  .BalanceAndPayoutsManage {
    .account-selector {
      display: none;
    }
  }
}
