.FlowInfo {
  display: flex;
  flex-direction: column;
  flex: 1;

  .features {
    span.subpoint {
      font-size: 10px;
    }
  }
}
