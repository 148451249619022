@import '../../../styles/variables.scss';

.PageBody {
  max-width: $wrap-size;
  min-width: 840px;
  margin: 0px auto;
  position: relative;
  flex: 1;
}

@media (max-width: 1240px) {
  .PageBody {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
