@import '../../../styles/variables.scss';

.time-picker,
.DateTimePickerContainer {
  //Added generic styles for DateTimePicker component
  input {
    background-color: color(light, secondaryBg);
    border-radius: 8px;
    font-size: 14px;
    font-family: $lato;
    padding: 0px;
    border: 1px solid color(grey, light);
    height: 42px;
    display: flex;
    flex: 1 1;
    font-weight: 500;
    text-align: center;
  }
  //TODO: Find a way to prevent datepickers from being clipped & staying inside modal, while also preventing datepickers from going
  // off screen and being un clickable https://app.clickup.com/t/8404472/GIG-1630?comment=458171307.
  // Disabled for now until better solution is found since it goes off smaller screens.
  //For modals, this prevents the datepicker from being clipped when inside a small modal.
  // .rdt {
  //     &.rdtOpen {
  //         .rdtPicker {
  //              position: fixed;
  //          }
  //      }
  //  }
}
