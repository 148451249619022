@import '../../../../styles/variables.scss';
.FormSlider {
  display: flex;
  .slider-custom {
    -webkit-appearance: none;
    width: 100%;
    height: 3px;
    background: color(purple, darkest);
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      border: 3px solid color(purple, darkest);
      border-radius: 50%;
      background: color(purple, dark);
      cursor: pointer;
    }
  }
}
