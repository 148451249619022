@import '../../styles/variables.scss';

.success-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 140px;
  margin-bottom: 80px;

  .success-title {
    letter-spacing: 0.32px;
    font-size: 32px;
    color: color(purple, dark);
    margin-bottom: 40px;
    font-weight: 700;
  }

  .succes-check {
    width: 140px;
    height: 140px;
    border: 2px solid color(purple, dark);
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    i {
      font-size: 65px;
      color: color(purple, dark);
    }
  }

  .success-state {
    font-size: 27px;
    margin-bottom: 22px;
  }

  .success-options {
    display: flex;
    flex-direction: row;

    .Button {
      margin: 10px;
    }
  }
}
.gig-list {
  padding: 0 40px;
  padding-bottom: 80px;

  .no-active-gigs {
    text-align: center;
  }

  .gig-list-title {
    padding-left: 20px;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 60px;
  }
}

@media (max-width: 1200px) {
  .Volunteer {
    .Volunteer-content {
      .main {
        .Volunteer-main-content {
          .success-block {
            margin-bottom: 0;
            padding-bottom: 60px;
            background: #f5f6f7;

            .succes-check {
              width: 120px;
              height: 120px;

              i {
                font-size: 55px;
              }
            }

            .success-state {
              font-size: 20px;
            }
          }

          .gig-list {
            padding: 0;
            padding-bottom: 60px;

            .gig-list-title {
              padding-top: 40px;
              text-align: center;
              padding-bottom: 40px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .Volunteer {
    .Volunteer-content {
      .main {
        .Volunteer-main-content {
          border-radius: 0;
          padding: 15px;
          background: #fff;

          .success-block {
            padding-top: 60px;
          }
        }
      }
    }
  }
}
