@import '../../../../styles/variables.scss';

.HubSelection {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  .filter-wrapper {
    margin: 25px 0;

    .TextField {
      .input-wrap {
        padding: 5px;
        border-radius: 38px;

        input {
          height: 32px;
        }
      }
    }
  }

  .hubs-selection {
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    .empty-hub-list {
      align-self: center;
      margin: 25px;
      span {
        color: color(grey, text);
        text-decoration: underline;

        &:hover {
          cursor: pointer;
          color: color(grey, text2);
        }
      }
    }

    .hub-selection-item {
      flex: 1;
      margin-right: 10px;
      padding: 20px 30px;
      margin-bottom: 14px;
      border: 1px solid #e9e9e8;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
      .item-hub-list {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        .hub-image:hover {
          cursor: pointer;
        }
        .hub-counter {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          font-size: 11px;
          color: #898989;
          border: 2px solid #f5f5f5;
          border-radius: 15px;
        }
      }
      .title-section {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        .title {
          font-weight: 800;
          font-size: 16px;
          margin-bottom: 4px;
        }
        .charity {
          font-weight: 500;
          font-size: 12px;
          color: #959799;
        }
      }
      &.selected {
        border: 1px solid color(purple, dark);
      }
    }
  }
}
