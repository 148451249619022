@import '../../styles/variables.scss';

.dark {
  .SetPassword {
    .inner {
      p {
        color: color(dark, fontColor);

        span {
          &:hover {
            color: color(dark, fontColor);
          }
        }
      }

      .TextField {
        input {
          background-color: color(dark, primaryBg);
        }
      }
    }
  }
}

.light {
  .SetPassword {
    .inner {
      p {
        color: color(light, fontColor);

        span {
          &:hover {
            color: color(light, fontColor);
          }
        }
      }

      .TextField {
        input {
          background-color: color(light, primaryBg);
        }
      }
    }
  }
}

.SetPassword {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-size: cover;

  &:before {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: color(cream, light);
  }

  .SetPassword-inner {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
    padding: 40px 20px;

    hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #ccc;
      margin: 1em 0;
      padding: 0;
    }

    p {
      font-size: 15px;
      margin: 20px auto 30px;
      font-weight: 500;
      color: color(grey, dark);
    }

    .TextField {
      i {
        background-color: white;
      }

      &:nth-of-type(1n + 2) {
        margin-top: 25px;
      }
    }

    .error-message {
      margin-top: 10px;
      font-size: 13px;
      min-height: 17px;
      color: transparent;
      text-shadow: 0 0 0 red;
    }

    .action-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .Button,
      button {
        width: 100%;

        &:nth-of-type(1n + 2) {
          margin-left: 60px;
        }

        &.cancel {
          background-color: white;
          color: color(light, fontColor);

          &:hover {
            background-color: color(dark, fontColor);
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .SetPassword {
    .SetPassword-inner {
      margin: 20px;

      .action-buttons {
        margin-top: 20px;
        flex-direction: column;
        .Button,
        button {
          &:nth-of-type(1n + 2) {
            margin: 0px;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
