@import '../../styles/variables.scss';

.Location {
  display: flex;
  width: 600px;
  flex-direction: column;
  position: relative;

  .Loader {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 22px;
  }

  .fa-times-circle {
    position: absolute;
    top: 12px;
    right: 10px;
    font-size: 22px;
    color: color(volunteer);
  }

  .Location-info {
    display: flex;
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    justify-content: center;
    align-items: center;

    .Location-info-inner {
      display: flex;
      padding: 20px;
      flex: 1;
    }

    .TextField {
      flex: 1;
      margin-left: 20px;

      .input-wrap {
        background-color: color(light, secondaryBg);
      }

      &:first-child {
        margin-left: 0px;
      }

      .main-icon {
        border-bottom-left-radius: 0px;
      }

      input {
        border-bottom-right-radius: 0px;
      }
    }
  }

  .mapboxgl-marker {
    i {
      width: auto !important;
      color: color(purple, dark);
      font-size: 28px;
      text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
    }
  }

  .mapboxgl-map {
    .mapboxgl-control-container {
      display: none;
    }
  }
}
