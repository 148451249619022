@import '../../styles/variables.scss';
.CreateAGig {
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  box-sizing: border-box;

  .toggle-row {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 18px;
    }
  }

  .RangeSlider {
    margin-top: 30px;
    margin-bottom: 30px;

    .rc-slider-rail {
      height: 8px;
      background-color: color(grey, lightest);
    }

    .rc-slider-track {
      height: 8px;
      background-color: color(purple, dark);
    }

    .rc-slider-handle {
      height: 26px;
      width: 26px;
      margin-top: -8px;
      background-color: color(purple, base);
      border: solid 3px color(purple, dark);
    }

    .rc-slider-tooltip-content {
      background: none;

      .rc-slider-tooltip-inner {
        background: none;
        border: none;
        color: color(grey, base);
        box-shadow: none;
      }
    }
  }
}

@media (max-width: 1000px) {
  .CreateAGig {
    flex-direction: column;
    height: 100vh;
    overflow: hidden;

    .FormBottomBar {
      .submit-btn {
        .submit {
          .text {
            font-size: 13px;
          }
        }
      }
    }

    .FormSection {
      min-height: unset;
      padding-bottom: 90px;

      .form-page {
        max-height: calc(100vh - 340px);

        #scrollableDiv {
          max-height: unset;
          overflow-y: unset;

          .filter-wrapper {
            flex-wrap: wrap;
          }
        }

        .CustomApplication {
          .ApplicationFormBuilder .Modal.AddFormOptionModal .add-option-modal {
            display: flex;
            flex-direction: column;
            margin-top: 80px;
            padding: 0 24px;
            text-align: center;

            .TextField {
              margin-bottom: 16px;
              text-align: left;
            }
          }

          .question {
            .row {
              flex-direction: column;

              .col {
                margin: 0 0 10px 0;
              }
            }
          }
        }
      }
    }
  }
}
