@import '../../../styles/variables.scss';

.Fundraisers {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  padding: 0px 40px 40px;
  max-height: 850px;
  overflow-y: auto;

  .progress-bar-wrap {
    text-align: center;
    span {
      font-size: 12px;
      color: color(grey, base);
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}
