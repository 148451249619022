@import '../../styles/variables.scss';

.AuctionTransactions {
  .AuctionTransactions-list {
    display: flex;
    flex-direction: column;
    padding-top: 7px;
    flex: 1;

    .list {
      padding-top: 26px !important;
      padding-bottom: 30px;

      .list-inner {
        flex: 1;
        max-height: 100%;
        overflow: auto;
        position: relative;

        .list-rows {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;

          .row {
            .row-inner {
              flex: 1;
              display: flex;
            }
          }
        }

        .Loader {
          display: flex;
          flex: 1;
          font-size: 31px;
          justify-content: center;
          align-items: center;
        }

        .SortableTable {
          .table-row {
            .col {
              &.transaction_amounts_payment_method {
                span {
                  text-transform: capitalize;
                }
              }

              &.user_first_name {
                font-weight: 500;
              }

              &.payment_status {
                span {
                  border-radius: $border-radius;
                  padding: 8px;
                  text-transform: capitalize;
                  min-width: 75px;
                  display: inline-flex;
                  justify-content: center;

                  &.paid {
                    background-color: #eafcf5;
                    color: #067f5c;
                  }

                  &.unpaid {
                    background-color: #f3f4f9;
                    color: #686c8b;
                  }

                  &.refunded {
                    background-color: #fff2d8;
                    color: #9f6000;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .Modal {
    .content {
      .select-payment-header {
        padding-bottom: 18px;
        padding-top: 18px;
        margin-bottom: 10px;
        border-bottom: 1px solid lightgray;
      }

      .select-payment {
        padding: 35px 50px 55px 50px;

        .payment-info {
          font-size: 1.5em;
          color: color(purple, dark);
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid lightgray;
        }
      }

      .controls {
        border-top: 1px solid lightgray;
        display: flex;
        justify-content: center;

        button {
          margin: 25px;
        }

        .cancel-button {
          background-color: #ffffff;
          color: #52646c;
          box-shadow: 0px 3px 6px #7c7c7c41;
          border: 1px solid #e3e3e3;
          border-radius: $border-radius;

          &:hover {
            background-color: color(light, tertiaryBg);
            color: color(light, fontColor);
          }
        }
      }
    }
  }
}
