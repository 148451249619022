@import '../../styles/variables.scss';

.LocationManagement {
  flex: 1;
  display: flex;

  .section-title {
    .Button {
      button {
        box-shadow: $box-shadow;
      }
    }
  }

  .confirm-delete {
    padding: 20px 40px;

    p {
      padding: 20px 0px;
      margin: 0px;
      max-width: 450px;
      line-height: 28px;
    }

    .delete-actions {
      display: flex;
      justify-content: flex-end;

      .Button {
        margin-left: 10px;

        &:last-child {
          button {
            background-color: color(volunteer);
          }
        }
      }
    }
  }

  .LocationManagement-form {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;

    .form-actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      max-height: 40px;
      padding: 0px 40px;

      .Button {
        margin-left: 20px;
      }
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      max-height: 44px;

      .title-inner {
        flex: 1;
        margin: 0px 16px;
        font-weight: 500;

        .title-wrap {
          font-size: 24px;
        }

        .TextField {
          max-width: 300px;

          input {
            font-size: 24px;
          }
        }
      }

      i.fa-arrow-left {
        height: 40px;
        width: 40px;
        background-color: color(blue, lightest);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        &:hover {
          color: color(purple, dark);
          cursor: pointer;
        }
      }
    }

    .permissions-wrap {
      list-style-type: none;
      margin: 0px;
      padding: 60px 0px 0px;
      display: flex;
      flex-wrap: wrap;

      li {
        display: flex;
        padding: 0px 0px 40px 0px;
        margin: 0px 80px 0px 0px;
        max-width: 400px;

        .permission-description {
          .permission-title {
            font-size: 18px;
            font-weight: 500;
          }

          p {
            font-size: 14px;
            color: color(grey);
            line-height: 21px;
          }
        }

        i {
          font-size: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 0px 0px 40px;

          &:hover {
            cursor: pointer;
          }

          &.fa-toggle-off {
            color: color(grey);
          }

          &.fa-toggle-on {
            color: color(purple, dark);
          }
        }
      }
    }
  }

  .LocationManagement-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    .search {
      display: flex;
      align-items: center;
      height: 44px;

      .create {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }

      .TextField {
        max-width: 50%;

        i {
          background-color: color(light, secondaryBg);
          color: color(light, fontColor);
        }

        input {
          background-color: color(light, secondaryBg);
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      flex: 1;

      .headers,
      .row {
        .col {
          display: flex;
          flex: 1;
          font-weight: 700;

          &:first-child {
            text-indent: 8px;
          }

          &.title {
            flex: 1;
          }

          &.created,
          &.updated {
            width: 400px;

            span {
              font-weight: 400;
              margin-right: 8px;

              &:last-child {
                color: color(grey);
                font-weight: 400;
              }
            }
          }

          &.actions {
            justify-content: flex-end;
            padding-right: 40px;
            position: relative;

            .ContextMenu {
              display: none;
              position: absolute;
              top: 55px;
              right: 10px;
              box-shadow: $box-shadow;
              border-radius: 20px;

              li {
                cursor: pointer;

                &.default-role {
                  display: none;
                }
              }

              i {
                font-size: 16px;
                padding-right: 8px;
              }

              &:hover {
                display: block;
              }
            }

            i.fa-ellipsis-h-alt {
              font-size: 24px;
              height: 40px;
              width: 40px;
              background-color: color(blue, lightest);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }

            i.fa-ellipsis-h-alt:hover + .ContextMenu {
              display: block;
            }
          }
          .default-status {
            padding: 5px 8px;
            border-radius: 8px;
            font-weight: 600;
            &.default {
              background: #b3f7dd;
              color: #4abea9;
              // #fad2cf
            }
            &.not-default {
              background: #fad2cf;
              color: #ec564e;
              // #fad2cf
            }
          }
        }
      }

      .headers {
        background-color: color(blue, lightest);
        display: flex;
        flex: 0;
        min-height: 40px;
        height: 40px;
        line-height: 40px;
      }

      .list-inner {
        flex: 1;
        max-height: 100%;
        overflow: auto;
        position: relative;

        .Loader {
          justify-content: center;
          display: flex;
          flex: 1;
          padding: 40px 0px;

          i {
            font-size: 30px;
          }
        }

        .not-found {
          margin-top: 30px;
        }

        .list-rows {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;

          .empty {
            flex: 1;
            padding: 30px 20px;
            text-align: center;
            color: color(grey);
            font-size: 16px;
          }
        }

        .row {
          display: flex;
          height: 60px;
          border-bottom: 1px solid color(grey, light);

          .col {
            font-weight: 400;
            align-items: center;
          }
        }
      }
    }
  }

  .LocationManagement-inner {
    max-width: $wrap-size;
    margin: 0px auto;

    .form-name {
      margin-top: 20px;

      h1 {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: 2px 9px;
        margin: 0px;
        font-size: 32px;
        height: 40px;
        font-weight: 700;

        span {
          flex: 1;
        }

        i {
          display: none;
          font-size: 21px;

          &:hover {
            color: color(purple, dark);
            cursor: pointer;
          }
        }

        &:hover {
          i {
            display: block;
          }
        }
      }

      .TextField {
        input {
          font-size: 32px;
          font-weight: 700;
        }
      }

      .edit-wrap {
        position: relative;

        i {
          position: absolute;
          top: 11px;
          right: 15px;
          font-size: 21px;

          &:hover {
            cursor: pointer;
            color: color(purple, dark);
          }
        }
      }
    }

    .question {
      display: flex;
      flex: 1;
      padding: 20px 8px;

      .label {
        flex: 1;
        margin-right: 20px;

        .options {
          padding-top: 20px;
        }
      }

      .type {
        .Dropdown {
          min-width: 300px;
        }

        .Button {
          margin-top: 20px;
        }
      }

      .option {
        padding-bottom: 10px;
        position: relative;

        i {
          position: absolute;
          top: 15px;
          right: 15px;

          &:hover {
            cursor: pointer;
            color: color(purple, dark);
          }
        }
      }
    }

    .actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      padding: 0px 8px;

      .Button {
        margin-left: 10px;
        padding-top: 40px;
      }
    }
  }
}
