@import '../../../../../styles/variables.scss';

.HubManagementAnalyticsVolunteering {
  display: flex;
  flex-direction: column;

  h3 {
    font-family: $lato;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    font-feature-settings:
      'pnum' on,
      'lnum' on,
      'salt' on,
      'ss09' on;
    color: black;
  }

  .HubManagementMetricContainer {
    gap: unset;

    .metric {
      flex-direction: column-reverse;
      align-items: flex-start;
      border-radius: unset;

      &:first-of-type {
        -moz-border-radius-topleft: 10px;
        -moz-border-radius-bottomleft: 10px;
        border-radius: 10px 0 0 10px;
      }

      &:last-of-type {
        -moz-border-radius-topright: 10px;
        -moz-border-radius-bottomright: 10px;
        border-radius: 0 10px 10px 0;
      }

      .label {
        font-family: $lato;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: color(grey, light);
      }

      .value {
        font-family: $raleway;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 35px;
        text-transform: capitalize;
        font-feature-settings:
          'pnum' on,
          'lnum' on;
        color: color(grey, dark);
      }
    }
  }

  .chart-container {
    margin-top: 20px;

    h4 {
      font-family: $lato;
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      font-feature-settings:
        'pnum' on,
        'lnum' on,
        'salt' on,
        'ss09' on;
      color: black;
    }

    .date-container {
      display: flex;
      width: 100%;

      .date {
        width: 302px;
        height: 44px;
        background: color(light, secondaryBg);
        border: 1px solid color(grey, border);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 21px;
        margin-right: 14px;

        label {
          margin-right: 14px;
          font-size: 16px;
        }

        .DateTimePickerContainer {
          width: 100%;
        }

        .dPicker {
          flex-grow: 1;

          input {
            width: 100%;
            border: none;
            text-align: left;
            outline: unset;
            font-size: 16px;

            &:focus {
              outline: unset;
            }
          }
        }

        i {
          flex-grow: 0;
          color: color(grey, border);
          font-size: 20px;
        }
      }
    }

    .chart {
      margin-top: 40px;
      max-height: 315px;
    }
  }
}
