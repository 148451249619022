@import '../../../../styles/variables.scss';

// Weird name cuz we don't have isolated scss
.PageContainerComponent {
  display: flex;
  min-height: 82vh;
  // padding: 60px 40px;

  .components-wrap {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;

    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      padding-bottom: 20px;
    }
  }
}
