@import '../../styles/variables.scss';

.PlanManagement {
  .card-container {
    display: flex;
    flex: 1;
    gap: 30px;
    flex-direction: column;

    .card {
      display: flex;
      flex-direction: column;
      box-shadow: $box-shadow;
      border-radius: 10px;

      .card-header {
        display: flex;
        width: auto;
        align-items: center;
        padding: 0 15px 0 15px;
        min-height: 60px;
        max-height: 60px;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .title,
        .price {
          color: white;
        }

        .title {
          font-weight: 900;
          font-size: 18px;
        }

        .price {
          margin-left: auto;
          font-weight: 500;
          font-size: 22px;
        }

        .current-plan {
          margin-left: 20px;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          padding: 8px;
          background-color: white;
          font-size: 14px;
          color: color(grey, dark);
        }

        &.starter {
          background: linear-gradient(180deg, #3377dc 0%, #5c9dff 100%);
        }

        &.purple_plus {
          background: linear-gradient(180deg, #5e51ab 0%, #8168e7 100%);
        }

        &.concierge {
          background: linear-gradient(180deg, #f2413a 0%, #eb645e 100%);
        }
      }

      .card-content {
        display: flex;
        flex-flow: row wrap;
        gap: 25px;
        background-color: white;
        padding: 30px;
        border-radius: 10px;

        .container {
          display: flex;
          flex-flow: column nowrap;
          flex: 1;

          &.left {
            gap: 20px;
          }

          &.right {
            align-items: flex-start;
            padding-left: 25px;
            gap: 15px;
          }

          .main-description {
            max-width: 250px;
            font-weight: 900;
            font-size: 18px;
          }

          .info-container {
            .description {
              font-size: 14px;
              font-weight: 900;
            }
            .icon {
              margin-right: 10px;
            }
          }

          .bottom-container {
            display: flex;
            flex-flow: column nowrap;
            gap: 25px;
            margin-top: auto;

            .Button {
              width: 100%;

              button {
                width: 100%;
              }
            }
          }

          .disclaimer {
            color: color(grey, dark);
            font-size: 12px;
            font-weight: 500;
          }

          .metric-container {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
          }

          .metric {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;

            .title {
              font-size: 14px;
              color: color(grey, dark);
              font-weight: 500;
            }

            .description {
              font-size: 18px;
              font-weight: 900;
            }
          }
        }
      }
    }
  }

  .plan-highlight {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;

    .icon {
      color: #d0d1d1;
    }
    .description {
      color: #828486;
    }
  }

  .upgrade-plan-modal {
    .upgrade-plan-modal-content {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      height: 100%;

      .left-column {
        display: flex;
        flex: 1;
        flex-flow: column nowrap;
        padding: 40px;

        .header-container {
          display: flex;
          flex-flow: row nowrap;
          margin-bottom: 40px;
          align-items: center;
          gap: 15px;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            width: 60px;
            border-radius: 50%;
            color: white;

            &.purple_plus {
              background-color: color(purple, dark);
            }
          }

          .title-container {
            display: flex;
            flex-flow: column nowrap;

            .title {
              font-size: 22px;
              font-weight: 700;
              color: black;
            }
            .description {
              font-size: 14px;
              font-weight: 500;
              color: color(grey, text);
            }
          }
        }

        .body {
          .plan-highlight-list {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            padding-left: 25px;
            gap: 15px;
            margin: 0 0 40px 0;
          }
        }

        .bottom-container {
          .total-container {
            display: flex;
            justify-content: space-between;
            font-size: 30px;
            font-weight: 800;
            color: color(grey, dark);
          }
        }
      }

      .right-column {
        position: relative;
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        padding: 40px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);

        .payment-form {
          display: flex;
          flex-flow: column nowrap;
          height: 100%;
          gap: 15px;

          .user-payment-methods-container {
            display: flex;
            flex-flow: column nowrap;
            height: 350px;
            overflow-y: auto;
            overflow-x: hidden;
            gap: 15px;

            .Loader {
              align-self: center;
            }

            .title {
              font-size: 22px;
              font-weight: 700;
              margin: 0;
            }

            .user-payment-method {
              margin-left: 10px;
              display: flex;
              align-items: center;

              .card-info {
                padding-left: 10px;
                font-size: 14px;

                .last-4 {
                  margin-left: 4px;
                  font-weight: 700;
                }
              }

              &.new-payment-method {
                margin-bottom: 15px;
                .label {
                  font-weight: 700;
                }
              }

              i:last-child {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                width: 16px;
              }

              &:hover {
                cursor: pointer;
                background-color: #fbfbfb;
              }

              i {
                &:first-child {
                  font-size: 30px;
                }

                &.fa-cc-visa {
                  color: #214dba;
                }

                &.fa-cc-mastercard {
                  color: #343a47;
                }

                &.fa-cc-amex {
                  color: #6ca0da;
                }

                &.fa-cc-discover {
                  color: #ef7e23;
                }

                &.fa-cc-diners-club {
                  color: #01084c;
                }

                &.fa-cc-jcb {
                  color: #0867ad;
                }

                &.fa-credit-card {
                  color: color(purple, dark);
                }

                &.fas {
                  color: color(purple, dark);
                }
              }
            }

            .new-payment-method-overlay {
              padding: 40px 0 40px 20px;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: white;
              border-radius: 10px;

              .overlay-header {
                display: flex;
                align-items: center;
                gap: 15px;
                margin-bottom: 15px;

                .go-back {
                  font-size: 20px;
                  &:hover {
                    cursor: pointer;
                    filter: brightness(130%);
                  }
                }
              }

              .overlay-content {
                display: flex;
                flex-flow: column nowrap;
                gap: 15px;
                overflow-x: auto;
                max-height: 100%;
                width: 100%;

                .PaymentMethodsForm {
                  padding: 0 15px 15px 0;
                }

                .card-wrap {
                  label {
                    margin-bottom: 6px;
                    color: #5e51ab;
                    font-weight: 500;
                  }

                  .card-inner {
                    height: 44px;
                    border: 1px solid color(grey, light);
                    border-radius: 7px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0px 10px;

                    .StripeElement {
                      flex: 1;
                    }
                  }
                }
              }

              .overlay-footer {
                display: flex;
                gap: 15px;
                margin-top: 40px;
              }
            }
          }

          .CreditCard {
            flex: unset;
          }

          .TextField {
            flex: unset;
          }

          .bottom-container {
            display: flex;
            flex-flow: column nowrap;
            margin-top: auto;
            gap: 15px;

            Button {
              width: 100%;
              max-width: 300px;
            }
          }
        }
      }
    }
  }

  .payment-success-modal {
    &.Modal {
      .content {
        min-width: 300px;
      }
    }
    .payment-success-modal-content {
      display: flex;
      flex-flow: column nowrap;
      max-width: 350px;
      padding: 50px;

      .purple-plus-banner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 35px;
        margin-bottom: 50px;
        background: linear-gradient(180deg, #5e51ab 0%, #8168e7 100%);
        border-radius: 10px;

        .title {
          display: flex;
          color: white;
          font-size: 32px;
          margin: 0;
          font-weight: 900;
        }

        &.show {
          i {
            &:nth-child(2) {
              left: 20px;
              bottom: 10px;
            }

            &:nth-child(3) {
              left: 60px;
              bottom: 65px;
            }

            &:nth-child(4) {
              left: 90px;
              bottom: 25px;
            }

            &:nth-child(5) {
              right: 20px;
              bottom: 15px;
            }

            &:nth-child(6) {
              right: 60px;
              bottom: 60px;
            }

            &:nth-child(7) {
              right: 95px;
              bottom: 35px;
            }
          }
        }

        i {
          position: absolute;
          color: white;
          transition: bottom 2s;

          &:nth-child(n) {
            transition: bottom 2s;
          }

          &:nth-child(2) {
            left: 20px;
            bottom: 15px;
            font-size: 35px;
            font-weight: 700;
            color: white;
            opacity: 0.4;
          }

          &:nth-child(3) {
            left: 60px;
            bottom: 60px;
            font-size: 30px;
            font-weight: 700;
            color: white;
            opacity: 0.2;
          }

          &:nth-child(4) {
            left: 90px;
            bottom: 30px;
            font-size: 25px;
            font-weight: 700;
            color: white;
            opacity: 0.1;
          }

          &:nth-child(5) {
            right: 20px;
            bottom: 10px;
            font-size: 35px;
            font-weight: 700;
            color: white;
            opacity: 0.4;
          }

          &:nth-child(6) {
            right: 60px;
            bottom: 65px;
            font-size: 30px;
            font-weight: 700;
            color: white;
            opacity: 0.2;
          }

          &:nth-child(7) {
            right: 95px;
            bottom: 30px;
            font-size: 25px;
            font-weight: 700;
            color: white;
            opacity: 0.1;
          }
        }
      }

      .success-body {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        .title {
          margin: 0 0 8px 0;
          font-size: 22px;
          font-weight: 700;
          text-align: center;
        }

        .description {
          font-size: 14px;
          font-weight: 500;
          margin: 0;
          color: color(grey, text);
          text-align: center;
        }
      }

      .actions-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        margin-top: 50px;

        .Button {
          width: 300px;

          button {
            width: 100%;
          }
        }
      }
    }
  }
}
