@import '../../styles/variables.scss';

.QuickEditFieldContainer {
  font-family: $lato;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto;
  place-self: center;
  .input-wrap {
    display: flex;
    border: 1px solid color(grey, light);
    border-radius: 7px;
    overflow: hidden;

    &.error {
      border: 1px solid red;
    }

    i {
      height: 42px;
      min-width: 42px;
      max-width: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: color(purple, dark);
      &.pointer {
        cursor: pointer;
      }
    }

    input {
      font-family: $lato;
      padding: 0px;
      border: 0px;
      height: 42px;
      display: flex;
      flex: 1;
      font-size: 16px;
      font-weight: 500;
      text-indent: 8px;

      &:focus {
        outline: 0px;
      }

      &:disabled {
        background-color: color(grey, lighter);
        cursor: not-allowed;
      }

      &:focus + .placeholder,
      &:not([value='']) + .placeholder {
        color: color(grey);
        font-size: 13px;
        top: -19px;
        left: 0px;
      }
    }
  }
}
