@import '../../../styles/variables.scss';

.PopupMenu {
  &.HubVolunteering-popupMenu-sortBy {
    min-width: 165px;
  }
}

.HubVolunteering {
  min-height: 700px;

  .result-count {
    margin: 30px 0;
    color: color(grey, dark);
    font-size: 14px;

    .tag {
      color: color(otherblue, link);
    }
  }

  hr {
    border: 1px solid color(grey, lightester);
    margin-bottom: 25px;
  }

  .Loader {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    font-size: 24px;
  }

  .load-more-container {
    margin-top: 80px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .list-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 32px;
    align-items: center;
    justify-content: space-between;

    .empty-card {
      height: 340px;
      border-radius: 10px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
      overflow: hidden;
      transition: all 0.25s ease-in-out;
      background: color(grey, pale);
      width: 100%;

      &.gig {
        max-width: 362px;
        height: 383px;
      }
    }

    .CardLoader {
      .wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 16px;
        row-gap: 32px;
        align-items: center;
        justify-content: space-between;

        .gig-loader-card-item {
          max-width: 362px;
          height: 367px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .HubVolunteering {
    .gig-loader-card-item {
      max-width: 313px;
    }

    .empty-card {
      .gig {
        max-width: 298px;
      }
    }
  }
}
