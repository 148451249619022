@import '../../../../styles/variables.scss';

.AuctionItemLeaderDisplay {
  display: flex;
  margin: 20px 0px;

  .lead {
    padding-right: 20px;
    border-right: 1px solid color(grey, light);
  }

  .leaderboard {
    margin-left: 20px;

    &:hover {
      cursor: pointer;
    }

    .stack {
      display: flex;
      margin-right: 10px;
      position: relative;

      .Portrait {
        margin: 0px -8px;
        position: relative;
      }
    }
  }

  .lead,
  .leaderboard {
    display: flex;

    .leaders-inner {
      margin-left: 10px;

      p {
        font-size: 13px;
        padding: 0px;
        margin: 0px;
        font-weight: 700;
      }

      .user-name {
        font-size: 13px;
        color: color(grey);
      }
    }
  }
}
