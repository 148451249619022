@import '../../styles/variables.scss';

.AttendeeManagement {
  flex: 1;
  display: flex;

  .section-title {
    display: flex;
    align-items: center;
    padding: 20px 30px;

    .forms-title {
      flex: 1 1;
      font-size: 32px;
      font-weight: 700;
    }

    .forms-controls {
      display: flex;

      .Button {
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }

  .AttendeeManagement-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    .search {
      display: flex;
      align-items: center;

      .create {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }

      .TextField {
        max-width: 50%;

        i {
          background-color: color(light, secondaryBg);
          color: color(light, fontColor);
        }

        input {
          background-color: color(light, secondaryBg);
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      flex: 1;

      .headers,
      .row {
        .col {
          display: flex;
          flex: 1;
          font-weight: 700;

          &.check-in {
            ul {
              padding: 20px 0px;
              margin: 0px;
              list-style-type: none;

              li {
                padding-bottom: 5px;
                display: flex;
                align-items: center;

                &:hover {
                  cursor: pointer;
                }

                span {
                  margin-left: 10px;
                  color: color(grey);
                  font-size: 18px;
                  line-height: 30px;
                  height: 30px;
                  display: inline-block;
                }

                i {
                  font-size: 30px;

                  &.fa-toggle-on {
                    color: color(purple, dark);
                  }

                  &.fa-toggle-off {
                    color: color(grey);
                  }
                }
              }
            }
          }

          &.portrait-td {
            padding: 5px;
            max-width: 120px;
            display: flex;
            justify-content: center;
            flex: 1;
          }

          &.actions {
            justify-content: flex-end;
            padding-right: 40px;
            position: relative;

            .ContextMenu {
              display: none;
              position: absolute;
              top: 55px;
              right: 10px;
              box-shadow: $box-shadow;
              border-radius: 20px;

              li {
                cursor: pointer;

                &.default-role {
                  display: none;
                }
              }

              i {
                font-size: 16px;
                padding-right: 8px;
              }

              &:hover {
                display: block;
              }
            }

            i.fa-ellipsis-h-alt {
              font-size: 24px;
              height: 40px;
              width: 40px;
              background-color: color(blue, lightest);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }

            i.fa-ellipsis-h-alt:hover + .ContextMenu {
              display: block;
            }
          }
        }
      }

      .headers {
        background-color: color(blue, lightest);
        display: flex;
        flex: 0;
        height: 40px;
        line-height: 40px;

        .col {
          display: flex;
          flex: 1;
          font-weight: 700;

          &.actions,
          &.portrait-td {
            max-width: 120px;
            display: flex;
            flex: 1;
            justify-content: center;
          }

          &:first-child {
            text-indent: 8px;
          }

          &.title {
            flex: 1;

            &.portrait-title {
              max-width: 80px;
            }
          }
        }
      }

      .list-inner {
        flex: 1;
        max-height: 100%;
        overflow: auto;
        position: relative;

        .list-rows {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;

          .empty {
            flex: 1;
            padding: 30px 20px;
            text-align: center;
            color: color(grey);
            font-size: 16px;
          }
        }

        .row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          border-bottom: 1px solid color(grey, light);
          position: relative;
          min-height: 60px;

          &.active {
            margin-top: 10px;
            .portrait-td,
            .actions,
            .date,
            .title {
              background-color: #f5f6f7;
              &:first-of-type {
                border-top-left-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
              }
              &.actions {
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
              }
            }
          }

          &.no-border {
            border-bottom: none;
            height: 100px;
          }

          .detail-row {
            background-color: #fff;
            display: flex;
            flex-direction: row;
            width: 100%;
            z-index: 2;

            .detail {
              display: flex;
              position: relative;
              flex-direction: column;
              background: #f5f6f7;
              border-radius: $border-radius;
              flex: 1;
              min-height: 250px;
              margin: 8px;
              box-shadow: 0px 3px 6px #00000029;

              .info-section {
                display: flex;
                flex-direction: row;
                padding: 10px;
                flex: 1;

                .info-square {
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                  background-color: #fff;
                  border-radius: $border-radius;
                  min-height: 135px;
                  margin: 4px;
                  flex: 1;
                  position: relative;

                  &:first-of-type {
                    .year {
                      color: color(purple, dark);
                    }
                  }

                  &:last-of-type {
                    .year {
                      color: #6565d3;
                    }
                  }

                  .spec {
                    margin-bottom: 4px;

                    .year {
                      position: absolute;
                      top: 25px;
                      left: 35%;
                      display: block;
                      justify-self: center;
                      margin: 0 auto;
                      font-size: 16px;
                    }

                    &.group-applications,
                    &.approved-volunteer {
                      color: color(purple, dark);
                      font-size: 22px;
                    }

                    &.accepted-volunteer,
                    &.completed-gigs {
                      color: #6565d3;
                      font-size: 22px;
                    }
                  }

                  .message {
                    width: 60px;
                    font-size: 10px;
                    text-align: center;
                  }
                }
              }

              .info {
                padding: 10px;
                height: 100%;
                display: flex;
                flex-direction: column;
                position: relative;

                .contact {
                  border-bottom: 1px solid #829096;
                  font-weight: 700;
                  padding-bottom: 5px;
                }

                .contact-info {
                  font-size: 14px;
                  margin-top: 5px;
                }

                .view-application {
                  display: flex;
                  position: absolute;
                  bottom: 15px;
                  width: 95%;

                  .Button {
                    width: 100%;
                    button {
                      width: 100%;
                      background-color: color(grey, light);
                      color: color(grey, base);
                    }
                  }
                }
              }

              .CoverImage {
                min-height: 160px;
              }

              .Portrait {
                position: absolute;
                justify-self: center;
                top: 80px;
                align-self: center;
              }

              .row {
                display: flex;
                flex-direction: row;
                justify-content: center;

                .volunteer-handle {
                  display: flex;
                  flex-direction: column;
                  text-align: center;
                  justify-content: center;

                  div:first-of-type {
                    font-weight: 700;
                    font-size: 18px;
                  }

                  div:last-of-type {
                    font-size: 14px;
                    color: #52646c;
                  }
                }
              }

              .actions-row {
                display: flex;
                flex-direction: row;
                align-content: space-around;
                justify-content: center;
                padding: 0 15px;

                .Button button {
                  margin: 0 5px;
                  min-height: 20px;
                  font-size: 14px;
                }

                .Button:first-of-type {
                  button {
                    background-color: #6565d3;
                  }
                }
              }

              .action-last-row {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 10px;

                .link-btn {
                  display: flex;
                  border: 0px;
                  padding: 0px 28px;
                  font-size: 16px;
                  border-radius: 20px;
                  justify-content: center;
                  align-items: center;
                  color: #829096;
                  text-decoration: none;
                  border: 1px solid #829096;
                  overflow: hidden;
                  min-width: 80px;
                  min-height: 36px;
                  margin: 10px 0;
                }

                .Button button {
                  margin: 8px 0;
                  min-height: 20px;
                  font-size: 14px;
                }

                .Button:first-of-type {
                  button {
                    background-color: #f5f6f7;
                    border: 1px solid #829096;
                    color: #829096;
                  }
                }
              }
            }
          }

          .col {
            font-weight: 400;
            align-items: center;

            &.actions,
            &.portrait-td {
              max-width: 120px;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .add-volunteer {
    padding: 20px;
    width: 640px;

    .title-row {
      font-size: 22px;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 15px;
    }

    .subheader {
      padding: 5px;
      border-bottom: 1px solid #f5f6f7;
      color: color(purple, dark);
      font-size: 18px;
      margin-bottom: 15px;
    }

    form {
      div {
        margin: 5px 0px;
      }
      .form-2-1 {
        display: flex;
        flex-direction: row;
        div {
          flex: 1;
        }
        div:first-of-type {
          flex: 2;
          margin-right: 8px;
        }
      }
      .form-1-1 {
        display: flex;
        div:first-of-type {
          margin-right: 8px;
        }

        .Dropdown {
          flex: 1;
        }
      }

      .actions {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .content {
    max-height: 90vh;
    min-width: 600px;
  }
}
