@import '../../styles/variables.scss';

.AuctionItemForm {
  flex: 1;
  display: flex;
  overflow-y: hidden;

  .add-auction-item {
    max-height: calc(90vh - 60px - 80px);
    overflow: auto;
    padding: 40px;

    .actions {
      display: flex;
      flex-direction: row-reverse;
      flex: 1;

      .Button {
        &:first-child {
          margin-left: 20px;
        }

        &:last-child {
          button {
            background-color: color(light, secondaryBg);
            border: 2px solid color(grey, light);
          }

          .text {
            color: color(grey);
          }
        }
      }
    }

    .sub-title {
      color: color(purple, dark);
      font-size: 21px;
      border-bottom: 1px solid color(grey, lighter);
      padding-bottom: 10px;
      margin-bottom: 10px;
      margin-top: 20px;
    }

    .tip {
      margin: 10px 0px;
      font-size: 14px;
      color: color(grey);
    }

    .row {
      display: flex;
      justify-content: center;
      align-items: center;

      .Dropdown {
        flex: 1;
        margin-bottom: 20px;
      }

      &.add-location {
        display: block;
        position: relative;

        a {
          position: absolute;
          top: -2px;
          right: 0px;
          color: color(purple, dark);
          font-size: 14px;
        }

        .Button {
          margin-top: 20px;

          button {
            border-radius: $border-radius;
            padding: 0px 12px;
            background-color: color(purple);

            &:hover {
              background-color: color(purple, dark);
            }

            span {
              font-size: 14px;
            }

            i {
              font-size: 18px;
            }
          }
        }

        .empty {
          margin-top: 20px;
          font-size: 14px;
          color: color(grey);
        }

        .selected-locations {
          margin: 20px 0px 0px;
          padding: 0px;
          list-style-type: none;

          li {
            padding-left: 8px;
            font-size: 14px;
            font-weight: 700;

            span {
              font-weight: 400;
              margin-left: 8px;
            }

            i {
              margin-left: 8px;
              color: color(red, dark);

              &:hover {
                cursor: pointer;
                color: color(purple);
              }
            }
          }
        }
      }

      &.taxes {
        flex-direction: column;
        flex: 1;
        width: 100%;

        .tax-eligible {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .Checkbox {
            margin-right: 20px;

            label {
              display: flex;
              justify-content: center;
              align-items: center;
              padding-bottom: 0px;

              p {
                padding-bottom: 0px;
              }
            }
          }

          .TextField {
            margin-bottom: 0px;
          }
        }

        .warning-msg {
          padding: 10px 0px 0px;
          font-size: 12px;
          color: color(grey);
          margin-bottom: 30px;
        }

        label {
          position: relative;
          padding-bottom: 4px;
          font-size: 13px;
          font-weight: 700;
          width: 100%;
        }
      }

      &.image-upload {
        margin-bottom: 20px;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        p {
          font-weight: 300;
          color: color(grey);
          padding: 0px;
          margin: 10px 0px;
        }

        .images {
          margin-bottom: 10px;
          display: flex;
        }

        .image-thumb {
          width: 80px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          margin-right: 10px;
          position: relative;

          i {
            display: none;
            position: absolute;
            top: 0px;
            right: 0px;
            font-size: 16px;
            color: color(light, fontColor);
            height: 25px;
            width: 25px;
            justify-content: center;
            align-items: center;

            &:hover {
              color: color(red, dark);
            }
          }

          img {
            width: 100%;
            height: auto;
            display: block;
            transform: scale(2);
          }

          &:hover {
            i {
              display: flex;
            }

            img {
              opacity: 0.5;
            }
          }
        }
      }

      &.quill-wrap {
        display: flex;
        flex-direction: column;

        .QuillTextEditor {
          width: 100%;
        }

        label,
        .quill {
          width: 100%;
        }

        label {
          font-size: 13px;
          font-weight: 700;
          margin-bottom: 4px;
        }

        .quill {
          margin-bottom: 20px;
          max-width: 550px;

          .ql-toolbar {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }

          .ql-container {
            border-radius: 8px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            min-height: 100px;

            .ql-editor {
              min-height: 100px;
            }
          }
        }
      }

      .DatePicker {
        .month {
          min-width: 140px;
        }

        .day,
        .year {
          max-width: 90px;
        }

        .time {
          .TextField {
            min-width: 50px;
          }
        }
      }

      > p {
        flex: 1;
        font-weight: 700;
        padding-bottom: 10px;
      }

      .fa-toggle-off,
      .fa-toggle-on {
        padding-bottom: 10px;
        font-size: 28px;

        &:hover {
          cursor: pointer;
        }
      }

      .fa-toggle-on {
        color: color(purple, dark);
      }

      &.dbl {
        flex-direction: row;
        flex-wrap: wrap;

        .TextField {
          &:first-child {
            margin-right: 10px;
          }

          &:last-child {
            margin-left: 10px;
          }
        }
      }

      .TextField {
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 486px) {
  .AuctionItemForm {
    .add-auction-item {
      .row {
        &.image-upload {
          margin-bottom: 40px;
        }

        &.dbl {
          .TextField {
            &:first-child {
              margin-right: unset;
            }

            &:last-child {
              margin-left: unset;
            }
          }
        }

        .DatePicker {
          margin-bottom: 15px;
          width: 100%;

          .dp-inner {
            flex-wrap: wrap;
          }

          .day {
            margin: 0 10px;
          }

          .time {
            margin: 10px 0 0 0;

            .TextField {
              margin: unset;
            }
          }
        }
      }
    }
  }
}
