@import '../../../../styles/variables.scss';

.HubStoreSettingsModal-delete-item {
  .delete-store-item {
    margin: 16px 0;

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;

      .Button {
        margin: 0 8px;
      }
    }
  }
}

.HubStoreSettingsModal-add-edit-item {
  &.content {
    padding: 16px;

    .ModalHeader {
      .header-container {
        padding: 0;
        align-items: flex-start;

        .header-title {
          margin: 0;
          font-weight: 700;
          font-size: 22px;
          color: black;
        }
      }
    }
  }
}

.HubStoreSettingsModal {
  height: 612px;
  width: 1088px;

  &.content {
    padding: 48px;

    .ModalHeader {
      .header-container {
        padding: 0;
        align-items: flex-start;

        .header-title {
          margin: 0;
          font-weight: 700;
          font-size: 22px;
          color: black;
        }
      }
    }

    hr {
      border: 1px solid color(dark, borderLight);
      width: 100%;
    }

    .menu {
      list-style: none;
      text-decoration: unset;
      display: flex;
      flex-direction: row;
      padding: 0;

      li {
        border-bottom: 2px solid color(dark, borderLight);
        transition: all 0.2s;
        flex-grow: 0;
        padding: 0 15px 10px 15px;
        height: 64px;
        line-height: 64px;
        cursor: pointer;
        font-weight: 700;
        font-size: 14px;
        color: color(grey, text);

        &.active {
          color: color(plum, dark);
          border-bottom: 2px solid color(plum, dark);
        }

        &:last-of-type {
          flex-grow: 1;
        }
      }
    }

    .store-settings {
      .TextField {
        margin-bottom: 26px;

        label {
          span {
            color: color(grey, dark);
          }
        }

        .input-wrap,
        label {
          width: 530px;
        }
      }
    }

    .partner-items,
    .hub-items {
      .search-container {
        .TextField {
          .input-wrap {
            background: color(light, secondaryBg);
            border: 1px solid color(grey, border);
            box-sizing: border-box;
            border-radius: 30px;
            width: 496px;

            i {
              color: color(grey, text);
            }
          }
        }
      }
    }

    .hub-items {
      .search-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 8px;
      }

      .list-container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 16px;
        row-gap: 30px;
        margin-top: 32px;

        .HubStoreSingleItem {
          .ListCard {
            width: 152px;
            height: 242px;
            border: 1px solid color(grey, border);
            box-sizing: border-box;
            border-radius: 10px;
            position: relative;

            .Portrait {
              display: none;
            }

            .cover-img {
              height: 152px;
              max-width: 152px;
            }
          }
        }
      }
    }

    .items-container {
      margin-top: 32px;

      .Checkbox {
        margin-right: 32px;
      }

      .item,
      .header {
        padding: 0 22px;
        display: flex;
        height: 60px;
        border-top: 1px solid color(light, shadow);
        border-right: 1px solid color(light, shadow);
        border-left: 1px solid color(light, shadow);
        background-color: color(light, secondaryBg);
        align-items: center;
      }

      .item:last-of-type {
        border-radius: 0 0 10px 10px;
        border-bottom: 1px solid color(light, shadow);
      }

      .item {
        justify-content: space-between;

        .visible-icon {
          cursor: pointer;
        }

        .left {
          display: flex;
          width: max-content;
        }
      }

      .header {
        background: color(grey, base4);
        border-radius: 10px 10px 0 0;

        span {
          cursor: pointer;
          transition: color 0.2s;

          &:hover {
            color: color(purple, dark);
          }

          i {
            transition: all 0.4s;
            margin-left: 10px;

            &.rotate {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1088px) {
  .HubStoreSettingsModal {
    height: unset;
    width: unset;
  }
}
