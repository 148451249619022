@import '../../styles/variables.scss';

.Gigit {
  &.embedded {
    .Donate {
      padding: 0px;

      .back-splash {
        display: none;
      }

      .Donate-content {
        max-width: 100%;
        border-radius: 0px;

        .side {
          display: none;
        }
      }
    }
  }
}

.Donate {
  flex: 1;
  padding: 60px 0px;
  position: relative;
  color: color(light, fontColor);
  background-color: color(light, primaryBg);
  position: relative;

  &.loading {
    .back-splash {
      opacity: 0;
    }

    .Donate-content {
      opacity: 0;
      transform: translateY(80px);
    }
  }

  .Loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 45px;
    }
  }

  .back-splash,
  .Donate-content {
    transition: all 200ms ease-in-out;
  }

  .back-splash {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 520px;
    background-color: color(light, tertiaryBg);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      filter: blur(40px);
      transform: scale(1.3);
      width: 100%;
      height: auto;
    }

    .curve {
      background-color: color(light, primaryBg);
    }
  }

  .Donate-content {
    max-width: 1280px;
    margin: 0px auto;
    height: 100%;
    position: relative;
    border-radius: 15px;
    display: flex;
    overflow: hidden;
    transition-delay: 300ms;

    .main,
    .side {
      flex: 1;
    }

    .main {
      background-color: #fff;

      .main-inner {
        display: flex;
        flex-direction: column;
        height: 100%;

        .title {
          height: 70px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f5f6f7;

          h1 {
            margin: 0px;
            font-size: 21px;
            flex: 1;
            padding: 0px 40px;
          }
        }

        .main-form {
          flex: 1;
          position: relative;

          .form-inner {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;

            .campaign-error {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin: 20px 40px;
              background-color: color(red, light);
              color: color(red, dark);
              border-radius: 15px;
              padding: 40px 20px;

              i {
                font-size: 40px;
                margin-bottom: 20px;
              }

              span {
                text-align: center;
              }
            }
          }
        }
      }
    }

    .side {
      max-width: 400px;
      display: flex;
      flex-direction: column;
      position: relative;

      .cover {
        position: relative;
        min-height: 114px;
        height: auto;
        background-color: color(purple, dark);

        .cover-img {
          width: 100%;
          height: auto;
          display: block;
        }

        .Portrait {
          position: absolute;
          bottom: 0px;
          left: 50%;
          margin: 0px 0px -40px -60px;
        }

        i {
          line-height: normal;
          &:hover {
            transform: scale(1.2);
            color: color(gray, dark);
            -webkit-transition: all 0.2s ease;
            -ms-transition: all 0.2s ease;
            transition: all 0.2s ease;
          }
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        background-color: #f5f6f7;
        flex: 1;
        padding-top: 60px;

        .created-by {
          background-color: #fff;
          border-radius: 10px;
          padding: 15px;
          margin: 20px 20px 0px;
          display: flex;
          justify-content: center;
          align-items: center;

          .created-info {
            flex: 1;

            .created-title {
              font-weight: 700;
              font-size: 15px;
            }

            .created-name {
              color: #484b4d;
              font-size: 18px;
            }
          }

          .Portrait {
            margin-right: 10px;
          }
        }

        .donation-matching {
          padding: 20px;
          margin: 20px 20px 0px;
          background-color: #e7f6f8;
          border-radius: 10px;

          .matching-title,
          .matching-description,
          .matched-to-date {
            text-align: center;
          }

          .matching-title {
            color: color(purple, dark);
            margin-bottom: 10px;
            font-weight: 700;
          }

          .matching-description {
            font-size: 14px;
            line-height: 19px;
            padding: 0px 30px;
            color: #484b4d;
            font-weight: 400;
          }

          .matched-to-date {
            color: #0c9fb5;
            margin-top: 20px;
          }
        }

        .progress-bar-wrap {
          margin: 20px 0px 0px;
          padding: 0px 20px;

          .progress-bar-bg {
            position: relative;
            border: 2px solid #416aae;
            background-color: #012939;
            border-radius: 10px;
            height: 40px;
            display: flex;
            align-items: center;
            padding: 0px 5px;

            .progress-bar {
              background: transparent linear-gradient(88deg, #008599 0%, #734fd5 100%) 0% 0%
                no-repeat padding-box;
              width: 0%;
              height: 30px;
              border-radius: 5px;
            }

            .amt,
            .goal {
              position: absolute;
              color: #fff;
              font-weight: 700;
              font-size: 19px;
            }

            .amt {
              top: 9px;
              left: 12px;
            }

            .goal {
              top: 9px;
              right: 12px;
            }
          }
        }

        .description {
          position: relative;
          overflow: auto;
          flex: 1;
          color: #52646c;

          .QuillTextEditor {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;

            .quill {
              padding: 20px 40px;
            }
          }

          .ql-editor {
            padding: 0px;
            line-height: 24px;
            font-size: 15px;
            text-align: center;
            overflow: hidden;
          }
        }

        .pledge {
          display: flex;
          padding: 20px 0px 0px;
          justify-content: center;
          align-items: center;

          a {
            min-width: 150px;
            background-color: color(purple, dark);
            color: color(dark, fontColor);
            text-decoration: none;
            text-align: center;
            min-height: 35px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            font-weight: 700;

            &:hover {
              background-color: color(purple);
            }
          }
        }

        .type,
        .title,
        .charity {
          text-align: center;
        }

        .type {
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            font-size: 16px;
            color: #829096;
            margin-right: 4px;
          }

          span {
            font-size: 14px;
            font-weight: 700;
          }
        }

        .title {
          font-size: 24px;
          font-weight: 700;
          padding: 5px 40px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;

          a {
            text-decoration: none;
            color: color(light, fontColor);

            &:hover {
              color: color(purple, dark);
            }
          }
        }

        .charity {
          .verified {
            display: inline-block;
            background-color: #e7f6f8;
            color: color(purple, dark);
            padding: 4px 12px;
            border-radius: 16px;

            i,
            span {
              font-size: 12px;
            }

            i {
              margin-right: 4px;
            }

            span {
              font-weight: 700;
            }
          }

          .charity-id {
            font-size: 14px;
            color: #52646c;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .Donate {
    padding: 0px;

    .Donate-content {
      border-radius: 0px;
    }
  }
}

@media (max-width: 1075px) {
  .Donate {
    .DonateForm {
      .overlay {
        position: fixed;
        z-index: 7;
      }
    }

    .back-splash {
      height: 253px;

      img {
        filter: blur(0px);
        transform: scale(1.6);
      }
    }

    .Donate-content {
      flex-direction: column-reverse;
      align-items: center;

      .side {
        max-width: 100%;
        width: 100%;

        .cover {
          background-color: transparent;
          min-height: 193px;

          .cover-img {
            display: none;
          }
        }

        .details {
          background-color: transparent;

          .description {
            display: none;

            .QuillTextEditor {
              position: static;
            }
          }

          .pledge {
            padding-bottom: 0px;
          }
        }
      }

      .main {
        width: 100%;
        margin-top: 20px;

        .main-inner {
          .title {
            display: none;
          }

          .main-form {
            .form-inner {
              position: static;
              height: auto;
              width: auto;

              .DonateForm {
                .DonateForm-inner {
                  .options {
                    flex-wrap: wrap;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .Donate {
    .main {
      .main-inner {
        .main-form {
          .form-inner {
            .DonateForm {
              .overlay {
                .overlay-inner {
                  .overlay-actions {
                    justify-content: space-around;
                    align-items: center;
                    .Button {
                      margin: 0px;

                      button {
                        min-width: 170px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 725px) {
  .Donate {
    .Donate-content {
      .main {
        .main-inner {
          .main-form {
            .form-inner {
              .DonateForm {
                .DonateForm-inner {
                  .options {
                    &.with-descriptions {
                      .option {
                        min-width: 100%;
                        max-width: 100%;

                        .option-inner {
                          margin-right: 0px;
                          margin-left: 0px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .Donate {
    .Donate-content {
      .side {
        .details {
          .description {
            padding: 20px;
          }
        }
      }
    }

    .main {
      .main-inner {
        .main-form {
          .form-inner {
            .DonateForm {
              form {
                .DonateForm-actions {
                  .Button {
                    &:first-child {
                      margin-right: 20px;
                    }
                  }
                }
              }

              .DonateForm-inner {
                padding: 20px;

                // Make anonymouse flag look better on mobile.
                .anonymous {
                  &.Checkbox {
                    margin-top: 10px;
                    label {
                      justify-content: start;
                    }
                  }
                }

                .options {
                  .option {
                    min-width: 70px;
                    margin-bottom: 10px;
                  }
                }

                .form-inputs {
                  flex-direction: column;
                  padding-top: 0px;

                  .TextField,
                  .Dropdown {
                    margin-right: 0px;
                    margin-top: 20px;
                  }
                }
              }

              .overlay {
                .overlay-inner {
                  .sub-title {
                    padding: 30px 20px;
                  }

                  .review-line,
                  .review-msg,
                  .review-title,
                  .review-column {
                    margin-left: 20px;
                    margin-right: 20px;
                  }

                  .TextField,
                  .card-wrap {
                    padding: 0px 20px;
                  }

                  .pm-inner {
                    padding: 0px 20px;
                  }

                  .options {
                    flex-direction: column;
                    padding: 0px 20px;

                    .option {
                      &:first-child {
                        margin-right: 0px;
                        margin-bottom: 10px;
                      }
                    }
                  }

                  .form-inputs {
                    flex-direction: column;
                    padding: 0px 20px;

                    .TextField,
                    .Dropdown {
                      margin-right: 0px;
                      margin-top: 20px;
                      padding: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 510px) {
  .Donate {
    .back-splash {
      height: 213px;

      img {
        transform: scale(1.6);
      }
    }

    .main {
      .main-inner {
        .main-form {
          .form-inner {
            .DonateForm {
              .row-overlay {
                div,
                span {
                  font-size: 14px;
                }
              }

              .overlay {
                .overlay-inner {
                  .overlay-actions {
                    justify-content: space-around;
                    align-items: center;

                    .Button {
                      margin: 0px;

                      button {
                        min-width: 170px;
                      }
                    }
                  }
                }
              }

              .DonateForm-inner {
                .options {
                  &.donor-type,
                  &.donation-type {
                    flex-direction: column;

                    .option {
                      &:first-child {
                        margin-right: 0px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .Donate {
    .main {
      .main-inner {
        .main-form {
          .form-inner {
            .DonateForm {
              .overlay {
                .overlay-inner {
                  .overlay-actions {
                    .Button {
                      button {
                        min-width: 120px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
