@import '../../../styles/variables.scss';

.Messages {
  padding: 10px 20px;
  /* max-height: 500px; */
  height: calc(100% - 190px);
  overflow: auto;
  overflow-x: hidden;
  cursor: default;

  .Loader {
    position: absolute;
    top: 39%;
    left: calc(50% - 21px);
    font-size: 42px;
  }

  .typing {
    display: flex;
    width: max-content;
    height: 1em;
    position: relative;
    padding: 10px;
    margin: 10px 0 0 57px;
    background: #e6e6e6;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    align-self: center;

    .dot {
      float: left;
      width: 6px;
      height: 6px;
      margin: 0 8px;
      background: #8d8c91;
      border-radius: 50%;
      opacity: 0;
      animation: loadingFade 1s infinite;

      &:nth-child(1) {
        animation-delay: 0s;
      }

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }

    @keyframes loadingFade {
      0% {
        opacity: 0;
        scale: (1);
      }
      50% {
        opacity: 0.8;
        scale: (3);
      }
      100% {
        opacity: 0;
        scale: (1);
      }
    }
  }

  .messages-wrap {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    flex: 1;

    .message-container {
      display: flex;
      flex-direction: row-reverse;
      position: relative;
      word-break: break-word;

      &:first-of-type {
        animation: 200ms slideIn ease-out;
      }

      &.align-left {
        align-self: flex-start;
        max-width: 75%;
      }

      &.align-right {
        align-self: flex-end;
        max-width: 75%;
        flex-direction: row;
      }

      .Portrait {
        align-self: flex-start;

        i {
          font-size: 24px;
        }
      }

      .message {
        padding: 15px;
        border-radius: 10px;
        margin-left: 15px;
        margin-bottom: 10px;
        font-size: 12px;

        p {
          cursor: default;
        }

        .ql-editor {
          font-weight: 500;
          padding: 0;
          font-size: 12px;
        }

        .message-header {
          font-weight: 700;
          font-size: 12px;
          color: #5a5b5d;
          margin-bottom: 8px;
          span {
            margin-left: 20px;
            color: #828486;
          }
        }
      }

      .sender {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        background-color: #2f2956;
        color: #fff;
        width: fit-content;
        border-top-right-radius: 0;
        margin-right: 15px;

        .message-header {
          color: #fff;
        }
      }

      .receiver {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        background-color: #f5f5f5;
        color: #5a5b5d;
        width: fit-content;
        border-top-left-radius: 0;

        .Portrait {
          border-radius: 50%;
        }
      }
    }
  }
}
