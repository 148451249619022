@import '../../styles/variables.scss';

.GigAdmin {
  display: flex;
  flex-direction: row;
  background-color: #eaeff0;
  width: 100%;

  .section {
    width: 100%;
    flex: 1;
    padding: 25px;

    &.schedule-wrap {
      padding: 0;

      .section-wrap {
        .section-title {
          margin: 25px 25px 0 25px;
        }

        .section-inner {
          margin: 0 55px 55px 55px;
        }
      }
    }

    .section-wrap {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 100%;
      padding: 0;

      .section-title {
        display: flex;
        padding: 20px 30px;
        font-size: 32px;
        font-weight: 700;
      }

      .section-inner-title {
        flex: 1 1;
      }

      .section-inner {
        margin: 0 30px 30px;
        padding: 40px;
        border-radius: 15px;
        background-color: color(light, secondaryBg);
        flex: 1;
        display: flex;

        .settings-item {
          &.external-applications {
            display: flex;
            flex-direction: column;

            .main-item {
              display: flex;
              width: 100%;
              min-height: 90px;
              align-items: center;
              padding: 0 40px;
            }

            .expansion {
              min-height: 72px;
              display: flex;
              width: 100%;
              align-items: flex-start;
              justify-content: center;
              padding-bottom: 18px;

              .actions {
                margin-top: 24px;
              }

              .TextField {
                margin-right: 24px;
              }
            }
          }

          .Dropdown {
            .input-wrap {
              border-radius: 8px;
              background-color: color(light, secondaryBg);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 823px) {
  .GigAdmin {
    .section,
    .admin-panel {
      display: none;
    }
  }
}
