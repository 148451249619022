@import '../../../../styles/variables.scss';

.HubEventSingleItem {
  display: flex;
  flex: 3;
  border-radius: 9px;
  height: 300px;

  .cover-image-container {
    display: flex;

    .CoverImage {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .description-section {
    min-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 30px 0 30px 40px;
    border-radius: 9px;
    border: 1px solid #ececec;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex: 1;

    .top-section {
      display: flex;
      justify-content: center;
      align-items: center;

      .top-section-description {
        display: flex;
        flex-direction: column;
        padding-left: 20px;

        p {
          &:first-of-type {
            margin-top: 0px;
            margin-bottom: 3px;
            font-size: 16px;
            color: #3a3a3a;
            font-weight: 600;
          }

          &:last-of-type {
            margin: 0px;
            font-size: 14px;
            color: #9d9d9d;
          }
        }
      }
    }

    .bottom-section {
      display: flex;
      flex-flow: column nowrap;
      .title {
        font-size: 21px;
        color: #3a3a3a;
        font-weight: 800;
      }
      .description {
        margin: -15px 0px 0px -15px;
        p {
          font-size: 16px;
          color: #3a3a3a;
          font-weight: 400;
          word-break: break-all;
          white-space: normal;
        }
      }
    }

    .causes {
      display: flex;
      flex-wrap: wrap;

      p {
        margin: 0px 1px 6px 0px;
        background: #f5f5f5;
        color: #828486;
        font-size: 14px;
        min-width: 123px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 8px;
        padding-right: 18px;
        padding-left: 18px;
      }
    }
  }
}

@media (max-width: 900px) {
  .HubEventSingleItem {
    flex-direction: column;

    .cover-image-container {
      .CoverImage {
        border-bottom-left-radius: 0;
        border-top-right-radius: 9px;
      }
    }

    .description-section {
      border: 1px solid #dddddd;
      border-radius: 9px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 0;
    }
  }
}
