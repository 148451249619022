@import '../../../../styles/variables.scss';

@keyframes slide-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.FormSection {
  display: flex;
  flex-flow: column;
  padding: 0 20px 90px 20px;
  flex: 1;
  box-sizing: border-box;
  animation: slide-in 200ms ease-in-out forwards;
  margin-top: 80px;
  overflow-y: auto;
  overflow-x: hidden;

  // text field resets
  .Dropdown,
  .TextField {
    font-size: 14px;
    box-sizing: border-box;
    .input-wrap {
      border-radius: 10px;
      border: 1px solid color(grey, lightest);
      padding: 5px;

      input {
        text-indent: 15px;
        &::placeholder {
          color: color(grey, lighter);
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    span,
    label {
      span {
        color: color(light, fontColor);
        font-weight: 700;
      }
    }
  }

  .Dropdown {
    .input-wrap {
      .fa-angle-down {
        top: 6px;
      }
    }
  }

  .DateTimePickerContainer {
    input {
      border: 1px solid color(grey, lightest);
      text-indent: 15px;
      &::placeholder {
        color: color(grey, lighter);
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .TextArea {
    label {
      .placeholder {
        left: -9px;
      }
      textarea {
        font-size: 14px;
        height: 100px;
        border: 1px solid color(grey, lightest);
        border-radius: 10px;
        padding: 15px;
        text-indent: 4px;
        line-height: normal;
        &::placeholder {
          color: color(grey, lighter);
          font-size: 14px;
          font-weight: 400;
        }
      }
      textarea:not([value='']) + .placeholder {
        transform: translateY(-35px);
        font-size: 14px;
        color: color(light, fontColor);
        opacity: 1;
      }
    }
  }

  .row {
    margin: 30px 0;
    span {
      font-size: 14px;
    }
    span.small {
      font-size: 12px;
      color: color(grey, light);
    }
  }

  .form-page {
    display: flex;
    flex-wrap: wrap;
    height: 78vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px 40px;
    box-sizing: border-box;

    &.column {
      flex-wrap: nowrap;
      flex-direction: column;
    }

    &.center {
      justify-content: center;
    }
  }
}

@media (max-width: 1000px) {
  .FormSection {
    margin-top: 0;
    padding: 0;
    // max-height: calc(100% - 340px);
    overflow-x: hidden;
    box-sizing: unset;
    padding-bottom: 90px;

    .row {
      margin: 30px 0;
    }

    .ImageCropper {
      .row {
        margin: 5px 0;
      }
    }

    .form-page {
      padding: 15px 20px;
      min-height: 100%;
      height: unset;
    }
  }
}

@media (max-width: 680px) {
  .FormSection {
    // padding-bottom: 35px;

    .form-page {
      padding: 15px 12px 40px 12px;
    }
  }
}
