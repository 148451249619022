@import '../../../styles/variables.scss';

.PopupMenu {
  &.HubEvents-popupMenu-sortBy {
    min-width: 165px;
  }
}

.HubEvents {
  max-width: 1600px;
  margin: 0 auto;
  min-height: 500px;

  hr {
    border: 1px solid color(grey, lightester);
    margin-bottom: 25px;
  }

  .empty-state {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 360px;

    p,
    a {
      font-family: $lato;
      text-align: center;
      margin: 0 0 8px 0;
      color: color(grey, dark);
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
    }

    a {
      color: color(grey, text);
      text-decoration-line: underline;

      &:hover {
        color: color(grey, text);
      }
    }

    .img-container {
      padding: 55px;
      margin-bottom: 32px;
      border-radius: 50%;
      background-color: color(grey, base4);

      img {
        width: 66px;
        height: 66px;
      }
    }
  }

  .Loader {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    font-size: 24px;
  }

  .grid-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 32px;
    align-items: center;
    justify-content: space-between;

    .empty-card {
      height: 340px;
      border-radius: 10px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
      overflow: hidden;
      transition: all 0.25s ease-in-out;
      background: color(grey, pale);
      width: 100%;

      &.event {
        height: 474px;
        max-width: 330px;
        margin-bottom: 10px;
        margin-top: 45px;
        margin-right: 32px;
      }
    }
  }
}
