@import '../../../../styles/variables.scss';

.HubAuctionSingleItem {
  &.low-opacity {
    .ListCard {
      .cover-img {
        opacity: 0.4;
      }

      .added-icons {
        span {
          color: color(grey, text);
        }
      }
    }
  }

  .ListCard {
    margin: 0;
    width: 268px;
    height: 392px;
    max-width: unset;
    flex: unset;
    cursor: pointer;
    box-shadow: unset;
    border: 1px solid color(grey, border);
    box-sizing: border-box;
    position: relative;

    .added-icons {
      position: absolute;
      left: 12px;
      top: 12px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      span {
        overflow: hidden;
        border-radius: 19px;
        width: 37px;
        height: 37px;
        background-color: color(light, secondaryBg);
        color: black;
        display: flex;
        font-size: 18px;
        align-items: center;
        justify-content: center;

        &.pined {
          i {
            margin-top: 3px;
          }
        }
      }
    }

    .edit-menu {
      overflow: hidden;
      border-radius: 19px;
      width: 37px;
      height: 37px;
      position: absolute;
      right: 12px;
      top: 12px;
      background-color: color(light, secondaryBg);
      display: flex;
      align-items: center;
      justify-content: center;

      .PopupMenu {
      }
    }

    .time-remaining {
      position: absolute;
      top: -45px;
      right: 140px;
      background: color(light, secondaryBg);
      border-radius: 8px;
      height: 37px;
      width: 105px;
      line-height: 37px;
      font-weight: 900;
      font-size: 14px;
      text-align: center;
      color: color(red, soft);
      transition: color 0.2s;

      .passed {
        color: color(red, soft2);
      }
    }

    .cover-img {
      height: 268px;
      max-width: 268px;
    }

    .auction-item-content {
      display: flex;
      flex-direction: column;

      .title {
        font-weight: 800;
        font-size: 16px;
        color: color(grey, dark);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .owner {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 2px 0 18px 0;
        font-weight: 700;
        font-size: 14px;
        color: color(grey, light);

        a {
          color: color(grey, dark);
          text-decoration: unset;
          transition: all 0.2s;

          &:hover {
            color: color(purple, dark);
          }
        }
      }

      .buy-now {
        margin-top: 7px;
      }

      .price,
      .buy-now {
        color: color(grey, dark);
        font-weight: 500;
        font-size: 16px;

        .red {
          color: color(red, soft);
        }

        &.locked {
          color: color(grey, text);

          i {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .HubAuctionSingleItem {
    .ListCard {
      width: 324px;
      height: 470px;

      .cover-img {
        height: 324px;
        max-width: 324px;
        max-height: 324px;
      }
    }
  }
}
