@import '../../../../styles/variables.scss';

.HubVolunteerEmptyState {
  display: flex;
  flex-direction: column;
  align-items: center;

  .blank-circle {
    width: 212px;
    height: 212px;
    background: color(grey, empty);
    position: relative;
    margin-bottom: 40px;
    border-radius: 106px;

    .empty-cart-container {
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      column-gap: 16px;
      width: 100%;
      height: 100%;

      .empty-cart {
        background: color(light, secondaryBg);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        padding: 10px;
        row-gap: 8px;

        .i-container {
          background: color(grey, empty2);
          border-radius: 8px;
          height: 72px;
          width: 72px;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            color: color(light, secondaryBg);
            font-size: 28px;
          }
        }

        .empty1 {
          background: color(grey, empty3);
          border-radius: 6px;
          width: 57px;
          height: 8px;
        }

        .empty2 {
          background: color(grey, empty4);
          border-radius: 6px;
          height: 8px;
          width: 33px;
        }
      }
    }
  }

  .no-items-txt {
    margin-bottom: 8px;
    font-weight: 900;
    font-size: 16px;
    color: color(grey, dark);
  }

  .check-later {
    font-weight: 500;
    font-size: 16px;
    color: color(grey, dark);
    text-align: center;
    margin-bottom: 8px;
  }
}
