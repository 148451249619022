@import '../../styles/variables.scss';

.SettingsManagement {
  display: flex;
  flex: 1;

  .Modal {
    &.charity-verification-modal {
      .content {
        max-width: 400px;
        min-width: 400px;

        .modal-close-circle {
          z-index: 3;
        }

        .charity-verification {
          position: relative;
          overflow: hidden;
          border-radius: 8px;

          &.show-verify {
            .thank-you {
              transform: translateX(-100%);
            }
          }

          .c-email,
          .c-phone {
            position: absolute;
            top: 0;
            left: 100%;
            width: 100%;
            height: 100%;
            background-color: #fff;
            transition: all 150ms ease-in-out;
            border-radius: 8px;
            display: flex;
            flex: 1;
            flex-direction: column;

            form {
              flex: 1;
              display: flex;
              flex-direction: column;

              .fields {
                flex: 1;
                padding: 20px;
              }

              .actions {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 70px;
                border-top: 1px solid color(grey, lighter);
                padding: 0px 20px;

                .Button,
                .Button button {
                  display: flex;
                  flex: 1;
                }
              }
            }

            .c-header {
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              height: 70px;
              border-bottom: 1px solid color(grey, lighter);

              i {
                position: absolute;
                top: 0px;
                left: 0px;
                height: 70px;
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;

                &:hover {
                  cursor: pointer;
                }
              }

              span {
                font-weight: 500;
              }
            }

            &.active {
              z-index: 2;
              transform: translateX(-100%);
            }
          }

          .thank-you {
            transition: all 150ms ease-in-out;

            .c-msg {
              color: #484b4d;
              text-align: center;
              padding: 20px;
              font-size: 14px;
              line-height: 19px;
            }

            .c-actions {
              padding: 30px 20px;

              .Button,
              .Button button {
                flex: 1;
                width: 100%;
              }
            }

            .c-info {
              list-style-type: none;
              padding: 0px;
              margin: 0px;
              border-top: 1px solid color(grey, lighter);

              li {
                display: flex;
                align-items: center;
                height: 50px;
                border-bottom: 1px solid color(grey, lighter);
                padding: 0px 20px;

                &:hover {
                  cursor: pointer;
                  background-color: color(cream, dark);
                }

                i {
                  &:first-child {
                    padding-right: 10px;
                  }

                  &.fa-angle-right {
                    color: color(grey, font);
                  }
                }

                span {
                  font-weight: 500;
                }

                .c-info-detail {
                  font-size: 14px;
                  font-weight: 300;
                  display: flex;
                  justify-content: flex-end;
                  flex: 1;
                  padding-right: 20px;
                  color: color(grey, font);
                }
              }
            }

            .c-header {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              background-color: color(grey, lightest);
              border-top-left-radius: 8px;
              padding: 40px 0px 74px;
              position: relative;

              .c-title {
                text-align: center;
                font-weight: 500;
                font-size: 24px;
                padding: 0 30px 20px;
              }

              .curve {
                background-color: #fff;
                height: 34px;
                bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .cancel-button {
    background-color: #ffffff;
    color: #52646c;
    box-shadow: 0 3px 6px #7c7c7c41;
    border: 1px solid #e3e3e3;
    border-radius: 23px;

    &:hover {
      background-color: color(light, tertiaryBg);
      color: color(light, fontColor);
    }
  }

  .save-button {
    margin-left: 25px;
  }

  .section-inner {
    display: flex;
    flex-direction: column;

    .settings-title {
      background-color: color(purple, lightest);
      height: 50px;
      text-indent: 20px;
      display: flex;
      align-items: center;
      font-weight: 700;

      &.disabled {
        color: color(grey, light);
      }
    }

    .donor-levels {
      display: flex;
      flex-flow: column;

      .donor-level-action {
        margin-top: 10px;
        display: flex;
        .donor-level-clear {
          margin-left: 10px;
        }
      }
      .range-container {
        display: flex;
        flex-flow: column;
        margin-bottom: 20px;

        .field-row {
          display: flex;
          flex-flow: row nowrap;
          width: 50%;

          .TextField {
            margin-right: 5px;
          }
        }
      }
    }

    .settings-item {
      display: flex;
      min-height: 90px;
      align-items: center;
      padding: 0 40px;
      border-bottom: 1px solid color(grey, lighter);

      &.external-applications {
        display: flex;
        flex-direction: column;

        .main-item {
          display: flex;
          width: 100%;
          min-height: 90px;
          align-items: center;
          padding: 0 40px;
        }

        .expansion {
          min-height: 72px;
          display: flex;
          width: 100%;
          align-items: flex-start;
          justify-content: center;
          padding-bottom: 18px;

          .actions {
            margin-top: 24px;
          }

          .TextField {
            margin-right: 24px;
          }
        }
      }

      .expansion {
        &.banner-view {
          padding: 20px 0px;
        }
      }

      &.disabled:hover {
        cursor: no-drop;
        .settings-control {
          .fad {
            cursor: no-drop;
          }
        }
      }

      .toggle-view {
        display: flex;
        flex-direction: row;
        flex: 1 1;

        .settings-control {
          display: flex;
          align-items: center;
          height: 41.5px;
          padding-top: 22px;
        }
      }

      &.connection {
        position: relative;

        .details {
          padding: 20px 0px;

          .details-title {
            font-size: 18px;
            font-weight: 700;
          }

          .details-subtitle {
            font-size: 16px;
            color: #52646c;
            padding: 10px 0px;
            font-weight: 400;
          }

          p {
            font-size: 14px;
            color: #52646c;
            font-weight: 300;
            line-height: 21px;
          }
        }

        .icon {
          font-size: 72px;
          min-width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 72px;
            width: 72px;
          }

          .fab {
            &.fa-twitch {
              color: #5a3e85;
            }

            &.fa-stripe-s {
              color: #6672e5;
            }
          }
        }

        .action {
          position: absolute;
          top: 45px;
          right: 40px;

          a,
          span {
            background-color: color(purple, dark);
            color: #fff;
            padding: 10px 18px;
            border-radius: 6px;
            box-shadow: $box-shadow;
            font-weight: 500;

            &.disconnect {
              color: color(light, fontColor);
              background-color: #eaeff0;
              box-shadow: none;

              &:hover {
                color: color(light, fontColor);
                background-color: #eaeff0;
              }
              &.remove {
                margin-left: 10px;
                background-color: color(red, dark);
                color: color(blue, lightest);
              }
            }

            &:hover {
              cursor: pointer;
              background-color: color(purple);
            }
          }
        }
      }

      &.settings-item-column {
        flex-direction: column;
        align-items: initial;

        .settings-item-row {
          align-items: center;
          align-content: center;
          display: flex;
          flex-direction: row;

          .details {
            margin-top: 22px;
          }
        }

        .minimum-tax-receipt-amount {
          margin-top: 22px;
          margin-bottom: 22px;

          .actions {
            margin-top: 20px;
          }
        }
      }

      .verify {
        button {
          background-color: color(green, dark);
        }
      }

      .charity-status {
        height: 44px;
        background-color: color(orange, light);
        color: color(orange, dark);
        margin-top: 19px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 28px;
        border-radius: 20px;
        font-weight: 500;
        text-transform: capitalize;

        &.unverified,
        &.pending {
          background-color: color(orange, light);
          color: color(orange, dark);
        }

        &.verified {
          background-color: color(green, light);
          color: color(green, dark);
        }

        &.declined {
          background-color: color(red, light);
          color: color(red, dark);
        }
      }

      .charity-error {
        display: flex;
        align-items: center;
        color: color(red, dark);
        margin-top: 6px;
        font-size: 12px;

        i {
          margin-right: 2px;
        }
      }

      .quill {
        background-color: color(light, secondaryBg);
      }

      &.prefix {
        .TextField {
          max-width: 250px;
          margin-right: 25px;
        }

        .current-receipt-format {
          margin: 10px 2px;
          font-size: 12px;
        }
      }

      &.hover-item {
        flex-direction: column;
        align-items: stretch;

        .details,
        .expansion {
          flex: 1;
        }

        .details {
          padding-top: 22px;
          position: relative;
        }

        .expansion {
          padding: 20px 0px 20px 40px;

          &.banner-view {
            padding: 20px 0px;
          }

          &.contact {
            .TextField {
              padding-bottom: 20px;
              width: 50%;
              min-width: 500px;
            }

            .actions {
              display: flex;
            }
          }

          .DatePicker {
            margin-bottom: 20px;
            max-width: 600px;

            .dp-inner {
              .month {
                max-width: 180px;
              }

              .day,
              .year {
                max-width: 80px;
              }
            }
          }

          &.amounts {
            .donation-amount {
              display: flex;
              margin-bottom: 10px;
              max-width: 200px;

              .donation-description {
                margin-left: 15px;

                .TextField {
                  .input-wrap {
                    input {
                      min-width: 400px;
                    }
                  }
                }
              }

              .donation-amount-text {
                .TextField {
                  flex: 1;

                  .input-wrap {
                    border-left: 0px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;

                    &:before {
                      content: '$';
                      height: 42px;
                      width: 22px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }

                    input {
                      text-indent: 4px;
                    }
                  }
                }
              }

              .index {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                height: 42px;
                min-width: 42px;
                max-width: 42px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: color(light, primaryBg);
                color: color(grey);
                border: 1px solid color(grey, light);
              }
            }

            .amounts-actions {
              display: flex;

              .Button {
                margin-right: 10px;
              }
            }
          }

          &.fees {
            .fee-option {
              display: flex;
              margin-bottom: 20px;

              &:last-child {
                margin-bottom: 0px;
              }

              .fee-radio {
                margin-right: 10px;

                .fas {
                  &.fa-circle {
                    color: color(purple, dark);
                  }
                }

                .far {
                  &.fa-circle {
                    color: color(grey);
                  }
                }
              }

              .fee-content {
                .fee-title {
                  font-weight: 500;
                  font-size: 16px;
                }

                .fee-description {
                  padding-top: 5px;
                  font-size: 14px;
                  color: color(grey);
                }
              }
            }
          }

          &.progress-bar {
            display: flex;
            flex-flow: column;

            .toggle-row {
              display: flex;
              flex-flow: row nowrap;
              flex: 1;
              max-width: 350px;
              align-items: center;
              margin-bottom: 10px;

              .toggle-label {
                flex-grow: 1;
                font-weight: 700;
              }
            }
          }

          &.fiscal-year {
            .inputs {
              display: flex;
              flex-direction: row;

              .day {
                width: 150px;
                margin-left: 10px;

                .TextField {
                  label {
                    input {
                      border-radius: 0px;
                    }
                  }
                }
              }

              .month {
                width: 150px;

                select {
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 0px;
                }
              }
            }

            .actions {
              margin-top: 20px;
            }
          }

          .expansion-actions {
            padding-top: 20px;
          }

          &.logo {
            .logo-wrap {
              height: 200px;
              width: 200px;
              border-radius: 20px;
              border: 1px solid color(grey, light);
              background-color: color(light, secondaryBg);
              overflow: hidden;

              img {
                display: block;
                width: 100%;
                height: auto;
              }
            }

            .logo-actions {
              margin-top: 20px;

              .ImageUpload {
                display: flex;

                .FileUpload {
                  width: auto;
                }
              }
            }
          }

          &.message {
            .message-actions {
              margin-top: 20px;
            }

            .web-address-actions {
              display: flex;
              flex-direction: row;

              :first-child {
                margin-right: 10px;
              }
            }

            .web-address-line {
              display: flex;
              flex-direction: row;
              align-items: center;

              .web-address {
                color: #52646c;
                letter-spacing: 0px;
                font-size: 18px;
                cursor: default;
              }

              :first-child {
                margin-right: 6px;
              }
            }

            .success {
              color: #2abd7e;
              margin-bottom: 24px;
            }
          }

          &.signature {
            canvas {
              background-color: color(light, secondaryBg);
              border: 1px solid color(grey, light);
              border-radius: 20px;
            }

            .signature-actions {
              display: flex;
              margin-top: 20px;

              .ImageUpload,
              .Button {
                margin-right: 20px;

                &:last-child {
                  button {
                    background-color: color(light, secondaryBg);
                    color: color(purple, dark);
                    border: 2px solid color(purple, dark);

                    &:hover {
                      color: color(purple);
                      border: 2px solid color(purple);
                    }
                  }
                }
              }
            }

            .form-wrap {
              form {
                display: flex;
                flex-direction: column;
                max-width: 400px;
                margin-top: 20px;

                .Button,
                .TextField {
                  margin: 10px 0;
                }
              }
            }
          }

          .TextField,
          .Dropdown {
            .input-wrap {
              overflow: hidden;

              input {
                background-color: color(light, secondaryBg);
              }
            }
          }

          .prefix-wrap {
            display: flex;
            flex: 1;
            justify-content: flex-start;
            align-items: center;

            .Button {
              margin-left: 20px;

              button {
                height: 44px;
              }
            }
          }
          .email-wrap {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;

            .Button {
              padding-top: 19px;
              margin-left: 20px;

              button {
                height: 44px;
              }
            }
          }
        }

        .localization > *:not(:first-child) {
          margin-top: 25px;
        }

        &:hover {
          background-color: color(cream, lightest);
          cursor: pointer;
        }
      }

      p {
        font-size: 14px;
        padding: 0px 0px 5px 0px;
        margin: 0px;
        font-weight: 700;
      }

      .ql-editor {
        p {
          color: color(grey);
          font-size: 14px;
          line-height: 24px;
        }
      }

      a {
        color: color(purple, dark);
        text-decoration: none;

        &:hover {
          color: color(purple);
        }
      }

      .settings-control {
        .fad {
          font-size: 30px;
          color: color(purple, dark);

          &:hover {
            cursor: pointer;
          }

          &.fa-toggle-off {
            color: color(grey);
          }
        }
      }

      .details {
        flex: 1;

        .detail {
          font-size: 14px;
          color: color(grey);
          padding-right: 20px;

          .create {
            margin-left: 5px;
            color: color(purple, dark);

            &:hover {
              color: color(purple);
            }
          }
        }
      }

      .TextField .bottom-label {
        text-align: end;
      }
    }
  }
}
