@import '../../../styles/variables.scss';

.Tooltip {
  font-size: 14px;
  padding: 15px;
  position: fixed;
  top: -23px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  border-radius: 8px;
  border: 1px solid color(purple, dark);
  background-color: #ffffff;
  width: 300px;
  color: #012939;
  animation: infoMessage 300ms ease-out forwards;
  display: none;
  pointer-events: none;
  font-weight: 700;

  span {
    color: #012939;
  }

  &.visible {
    display: block;
  }
}

.AuctionItem {
  display: flex;
  padding: 10px 20px;
  margin-bottom: 20px;
  position: relative;

  .list-admin-actions {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: color(purple);
    height: 30px;
    line-height: 30px;

    ul {
      display: flex;
      list-style-type: none;
      padding: 0px;
      margin: 0px;

      li {
        color: color(dark, fontColor);
        padding: 0px 10px;

        &:hover {
          cursor: pointer;
          background-color: color(light, secondaryBg);

          i,
          span {
            color: color(purple);
          }
        }

        span {
          margin-left: 8px;
        }
      }
    }
  }

  &.admin {
    &:hover {
      border: 1px solid color(purple);

      .list-admin-actions {
        display: block;
      }
    }
  }

  .action {
    position: absolute;
    top: 0px;
    right: 0px;

    .Button {
      button {
        box-shadow: $box-shadow;
      }
    }

    .closed {
      .Button {
        button {
          background-color: color(grey, light);
          color: color(light, fontColor);
          box-shadow: none;
        }
      }
    }
  }

  .Modal {
    &.auction-images {
      .content {
        background-color: color(light, secondaryBg);
      }
    }

    .no-background {
      background-color: unset;
    }

    .content {
      .modal-header-mobile {
        display: none;
      }

      .modal-header-desktop {
        padding-bottom: 20px;
      }

      .auction-payment {
        display: flex;
        flex: 1;
        max-height: 90%;

        .details {
          border-radius: $border-radius;
          background-color: color(light, secondaryBg);
          display: block;
          margin-top: 0px;
        }

        .summary {
          margin-left: 20px;
          min-width: 320px;

          .Loader {
            display: flex;
            justify-content: center;
            flex: 1;
            padding: 20px;

            i {
              font-size: 24px;
            }
          }

          .summary-inner {
            border-radius: $border-radius;
            background-color: color(cream, dark);

            .title {
              padding: 30px 40px;
              font-size: 28px;
              font-weight: 700;
              text-align: center;
              border-bottom: 1px solid color(grey, light);
              margin: 0px 30px;
            }

            .sub-price {
              padding: 30px 40px 0px;
              display: flex;
              flex: 1;

              span {
                flex: 1;

                &:first-child {
                  font-weight: 700;
                }

                &:last-child {
                  text-align: right;
                  color: color(grey);
                }
              }
            }

            .total {
              background-color: color(grey);
              margin: 20px 0px 0px;
              padding: 20px 40px;
              color: color(dark, fontColor);
              display: flex;
              flex: 1;
              font-size: 21px;
              font-weight: 700;

              span {
                flex: 1;

                &:last-child {
                  text-align: right;
                }
              }
            }

            .actions {
              padding: 40px 40px 20px;
              border-bottom-left-radius: 20px;
              border-bottom-right-radius: 20px;
              display: flex;
              flex-direction: column-reverse;
              background-color: color(light, secondaryBg);

              .Button {
                flex: 1;
                margin-bottom: 20px;
                &:last-child {
                  button {
                    background-color: color(light, secondaryBg);
                    color: color(grey);
                    border: 2px solid color(grey, light);

                    &:hover {
                      color: color(purple);
                      border: 2px solid color(purple);
                    }
                  }
                }

                button {
                  flex: 1;
                  width: 100%;
                }
              }
            }
          }
        }

        .details {
          flex: 1;
          min-width: 600px;
          max-width: 600px;
          overflow: auto;

          .title {
            padding: 20px 40px 0px;
            font-size: 28px;
            font-weight: 700;
            text-align: center;
          }

          .sub-title {
            text-align: center;
            color: color(grey);
            font-size: 14px;
            padding: 10px 40px 0px;
          }

          .item-details {
            background-color: color(cream, dark);
            display: flex;
            padding: 20px;

            .image {
              max-width: 200px;
              min-width: 200px;

              img {
                width: 100%;
                height: auto;
                border-radius: 8px;
              }
            }

            .item-content {
              padding-left: 20px;
              position: relative;
              flex: 1;

              .item-title {
                font-weight: 700;
                font-size: 21px;
                padding-right: 80px;
              }

              .item-description {
                .ql-editor {
                  padding: 20px 0px 10px;
                  font-size: 14px;
                  line-height: 21px;
                  color: color(grey);
                }
              }

              .winner {
                position: absolute;
                top: 0px;
                right: 0px;
                background-color: color(green, light);
                color: color(green, dark);
                padding: 4px 12px;
                border-radius: 20px;
                border: 1px solid color(green, dark);
              }
            }
          }

          .form-title {
            font-size: 21px;
            margin: 40px 40px 20px;
            padding-bottom: 10px;
            color: color(purple, dark);
            border-bottom: 1px solid color(grey, light);
          }

          .sub-form-title {
            margin: 0px 40px 20px;
            padding: 0px;
            font-weight: 700;
            font-size: 14px;
            display: flex;
            align-items: center;

            &.click {
              color: color(grey);
              font-weight: 400;

              &:hover {
                cursor: pointer;
              }
            }

            i {
              margin-right: 5px;
              font-size: 17px;

              &.fa-check-circle {
                color: color(purple, dark);
              }
            }
          }

          .shipping-options {
            margin: 0px 40px 20px;
            flex: 1;

            .shipping-option {
              padding-bottom: 20px;
            }

            .ship-title {
              font-weight: 500;
            }

            .shipping-label {
              margin: 10px 0px 0px 22px;
              font-size: 16px;
              font-weight: 300;

              span {
                font-weight: 500;
                margin-right: 8px;
              }

              &:hover {
                cursor: pointer;
              }

              i {
                margin-right: 5px;

                &.fa-check-circle {
                  color: color(purple, dark);
                }
              }
            }
          }

          .row {
            display: flex;
            flex: 1;
            margin: 0px 40px 20px;

            &.dbl {
              .TextField {
                &:first-child {
                  margin-right: 10px;
                }

                &:last-child {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }

      .header {
        display: flex;
        flex-flow: row nowrap;
        padding: 0 15px;
        height: 70px;
        min-height: 70px;
        align-items: center;
        border-bottom: 1px solid color(grey, darkest);

        .header-label {
          display: flex;
          justify-content: center;
          flex: 3;
          color: color(light, fontColor);
          font-size: 18px;
        }

        .back {
          flex: 1;
          color: color(grey, darkest);
          font-size: 25px;
          font-weight: 500;
          &:hover {
            cursor: pointer;
          }
        }

        .spacer {
          flex: 1;
        }
      }

      .show {
        display: flex;
      }

      .hidden {
        display: none;
      }
    }
  }

  .auction-image-gallery {
    padding: 40px;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 1;

    .image-title {
      padding-bottom: 20px;

      .total {
        font-size: 14px;
        color: color(grey);
        padding-bottom: 5px;
      }

      .title {
        font-size: 21px;
        font-weight: 700;
      }
    }

    img {
      &.landscape {
        width: 500px;
        height: 500px;
        max-height: 70vh;
        max-width: 70vw;
      }

      &.portrait {
        height: 70vh;
        width: auto;
        max-height: 70vh;
        max-width: 70vw;
      }

      &.square {
        height: 100%;
        width: auto;
        max-height: 50vh;
        max-width: 70vw;
      }
    }

    .left,
    .right {
      position: absolute;
      top: 50%;
      height: 50px;
      width: 50px;
      background-color: #fff;
      margin-top: -25px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;

        i {
          color: color(purple, dark);
        }
      }

      i {
        font-size: 24px;
      }
    }

    .left {
      left: 40px;
    }

    .right {
      right: 40px;
    }
  }

  .images {
    min-width: 230px;
    max-width: 230px;
    position: relative;
    padding-right: 20px;

    .image-placeholder {
      height: 100%;
      background: color(light, primaryBg);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      font-size: 5em;
      color: color(light, tertiaryBg);
    }

    .main {
      height: 250px;
      width: 230px;
      background-color: color(light, primaryBg);
      border-radius: 8px;
      background-position: center center;
      background-size: cover;

      &:hover {
        cursor: pointer;
      }
    }

    .media-nav {
      padding: 10px 0px 0px;
      margin: 0px;
      display: flex;
      list-style-type: none;
      justify-content: center;
      align-items: center;
      flex: 1;

      li {
        margin-left: 8px;

        &:hover {
          color: color(grey);
          cursor: pointer;
        }

        &.active {
          i {
            color: color(grey);
          }
        }

        i {
          font-size: 12px;
          color: color(grey, light);
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .content {
    position: relative;
    height: 100%;

    &.auction-item-content {
      flex: 1;

      .view-more-less {
        cursor: pointer;
      }
    }

    .placeholder-portrait {
      display: none;
      position: absolute;
      top: -90px;
      left: 50%;
      width: 120px;
      height: 120px;
      background-size: cover;
      background-position: center;
      border-radius: 50%;
      margin-left: -60px;
      border: 2px solid color(light, primaryBg);
      background-color: white;
    }

    .auction-portrait {
      display: none;
      position: absolute;
      top: -90px;
      left: 50%;
      width: 120px;
      height: 120px;
      background-size: cover;
      background-position: center;
      border-radius: 50%;
      margin-left: -60px;
      border: 2px solid color(light, primaryBg);
      background-color: white;
    }

    .name {
      font-size: 24px;
      font-weight: 700;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding-right: 124px;
    }

    .status {
      display: none;
      justify-content: center;
      align-items: center;

      span {
        color: color(grey);
        font-size: 12px;
      }

      i {
        margin-right: 4px;
        font-size: 12px;

        &.fa-times-circle {
          color: color(red, dark);
        }

        &.fa-check-circle {
          color: color(green, dark);
        }
      }
    }

    .current-bid {
      padding-top: 4px;
      font-size: 16px;
      font-weight: 400;
    }

    .description {
      .ql-editor {
        padding: 0px;
        font-size: 16px;
        line-height: 24px;
        color: color(grey);
        font-family: $lato;
      }
    }

    .details {
      display: flex;
      margin-top: 20px;

      .minimum,
      .retail,
      .close {
        font-size: 13px;
        font-weight: 700;
        display: flex;

        .icon-wrap {
          height: 30px;
          min-width: 30px;
          min-height: 30px;
          width: 30px;
          background-color: color(grey, light);
          border-radius: 50%;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .info {
          font-weight: 400;
          color: color(grey);
        }
      }

      .minimum {
        padding-right: 20px;
      }

      .retail {
        padding: 0px 20px;
      }

      .close {
        padding-left: 20px;
      }

      .minimum,
      .retail {
        border-right: 1px solid color(grey, light);
      }
    }
  }
}

@media (max-width: 680px) {
  .AuctionItem {
    .Modal {
      .modal-header {
        display: flex;
        width: 100%;
        height: auto;
        background-color: #ffffff;
      }

      .content {
        .modal-header-mobile {
          display: flex;
          width: 100%;
          height: auto;
          background-color: #ffffff;
          padding-bottom: 20px;
        }

        .modal-header-desktop {
          display: none;
        }

        .auction-payment {
          max-height: unset;
        }
      }
    }
  }
}

@media (max-width: 730px) {
  .AuctionItem {
    flex-direction: column;
    box-shadow: $box-shadow;
    padding: 0px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 30px;

    .images {
      min-width: 0px;
      max-width: none;
      overflow: hidden;
      padding-right: 0px;

      .image-placeholder {
        height: 120px;
        filter: blur(15px);
      }

      .main {
        width: 100%;
        height: 120px;
        border-radius: 0px;
        filter: blur(15px);
        transform: scale(1.5);
      }

      .media-nav {
        display: none;
      }
    }

    .content {
      padding: 60px 20px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .status {
        display: flex;
      }

      .auction-portrait {
        display: block;
      }
      .placeholder-portrait {
        display: flex;
        align-items: center;
        justify-content: center;
        background: color(light, primaryBg);
        font-size: 2em;
        color: color(light, tertiaryBg);
      }

      .AuctionItemLeaderDisplay-hide,
      .description,
      .details,
      .action {
        display: none;
      }

      .name {
        padding-right: 0;
        text-align: center;
      }
    }

    .Modal {
      .content {
        .desc-label {
          padding-bottom: 10px;
        }

        .description {
          display: block;
          padding-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 1030px) {
  .AuctionItem {
    .Modal {
      .content {
        max-width: 600px;

        .auction-payment {
          flex-direction: column;
          overflow: auto;
          background-color: color(light, secondaryBg);
          max-width: 100%;

          .details {
            min-width: auto;
            max-width: 100%;
            overflow: visible;

            .item-details {
              flex-direction: column;

              .image {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                min-width: auto;
                max-width: 100%;
                margin-bottom: 20px;
                min-width: 0px;
                max-width: 100%;
              }
            }

            .row {
              flex-direction: column;

              &.dbl {
                .TextField {
                  &:first-child {
                    margin-right: 0px;
                  }

                  &:last-child {
                    margin-left: 0px;
                    margin-top: 20px;
                  }
                }
              }
            }
          }

          .summary {
            min-width: auto;
            margin-left: 0px;

            .summary-inner {
              border-radius: 0px;
              margin-top: 20px;

              .title {
                display: none;
              }

              .actions {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
              }
            }
          }
        }
      }
    }
  }
}
