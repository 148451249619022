.DonationMatchingDetailsModal-Modal-Container {
  .item-name-col {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 15px;
    min-height: 60px;

    .image-container {
      object-fit: cover;
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }
  }

  .content {
    .ModalHeader {
      display: none;
    }

    .DonationMatchingDetailsModal {
      display: flex;
      flex-flow: column;
      padding: 20px 40px 0px 40px;

      .modal-content {
        display: flex;
        flex-flow: column nowrap;
        gap: 20px;
        min-width: 650px;

        .TableComponent {
          margin-bottom: 0;

          .table-wrap {
            table {
              border-top: none;
            }
          }
        }
      }
    }

    .ModalFooterActionContainer {
      padding: 20px 40px;
    }
  }
}
