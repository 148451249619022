@import '../../styles/variables.scss';

.ManagementSidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 225px;
  padding: 20px;
  background-color: color(light, secondaryBg);
  transition:
    all 200ms ease-in-out,
    padding 0ms;
  -moz-transition:
    all 200ms ease-in-out,
    padding 0ms;
  -webkit-transition:
    all 200ms ease-in-out,
    padding 0ms;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));

  &.collapse {
    width: 70px;
    min-width: 70px;
    padding: 0px;

    .Portrait {
      margin-top: 20px;
      margin-left: 5px;
      cursor: pointer;
    }

    .title,
    .target-link,
    .role {
      display: none;
    }

    .menu-sections {
      display: none;
    }

    .collapsed-menu {
      display: flex;
    }
  }

  .collapsed-menu {
    height: 100%;
    color: color(gray, light);
    display: none;
    padding-top: 50px;
    flex-direction: column;
    align-items: center;
    i {
      font-size: 24px;
      display: inline-block;
      padding: 10px;
      border: 1px solid transparent;
      margin-bottom: 10px;
      cursor: pointer;

      &:hover {
        transform: scale(1.2);
        color: color(gray, dark);
        -webkit-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }

      &.active {
        color: color(purple, dark);
        font-weight: 500;
      }
    }
  }

  .show-hide-toggle {
    position: absolute;
    right: -15px;
    top: 35px;
    z-index: 1;
    cursor: pointer;

    i {
      padding: 5px;
      display: inline-block;
      font-size: 15px;
      line-height: 18px;
      background-color: white;
      color: gray;
      width: 15px;
      height: 15px;
      text-align: center;
      vertical-align: bottom;
      border-radius: 50%;
      box-shadow: 2px 1px 10px -5px #000;
      -moz-transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;

      &:hover {
        background-color: color(dark, fontColor);
      }

      &.collapse {
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  .target-link {
    color: color(grey);
    font-size: 14px;
    font-weight: 700;
    padding: 5px 0px 0px;
    margin: 0px 0px 20px 0px;

    a {
      color: color(grey);
      text-decoration: none;
      line-height: 19px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        color: color(purple, dark);
      }
    }
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    padding: 20px 0px 0px;
    color: color(light, fontColor);
  }

  .menu-section {
    border-top: 1px solid color(blue, lighter);
    margin: 0px;
    padding: 20px 0px;

    .expand-menu-icon {
      display: inline-block;
      text-indent: 0.1px;
      line-height: 0.1px;
      font-size: 12px;
      margin-left: 10px;
      -moz-transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;

      &.turn-down {
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }

    .section-menu-list {
      overflow: hidden;
      max-height: 0;
      -webkit-transition: max-height 200ms ease;
      -moz-transition: max-height 200ms ease;
      -o-transition: max-height 200ms ease;
      transition: max-height 200ms ease;

      &.show {
        max-height: 250px;
      }
    }

    .submenu-options-list {
      padding-left: 20px;
      overflow: hidden;
      max-height: 0;
      -webkit-transition: max-height 200ms ease;
      -moz-transition: max-height 200ms ease;
      -o-transition: max-height 200ms ease;
      transition: max-height 200ms ease;

      &.show {
        max-height: 250px;
      }

      li {
        &:hover {
          background-color: color(light, primaryBg);
        }
      }
    }

    ul {
      list-style-type: none;
      padding: 0px;
      margin: 0px;

      li {
        text-transform: capitalize;
        padding: 0px;
        margin: 0px 0px 4px 0px;
        cursor: pointer;
        border-radius: 6px;
        color: color(grey);
        line-height: 30px;
        text-indent: 12px;
        font-size: 16px;
        font-weight: 700;
        white-space: break-spaces;

        &.sidebar-section-title {
          font-size: 17px;
          text-indent: 0px;
          padding-left: 12px;
          color: color(light, fontColor);
          display: inline-flex;
          align-items: center;

          span {
            font-weight: 700;
          }

          i {
            padding-right: 6px;
            font-weight: 700;
            font-size: 16px;
          }

          &.collapsible {
            &:hover {
              cursor: pointer;
            }
          }

          &:hover {
            cursor: default;
            background-color: white;
          }
        }

        &:last-child {
          margin-bottom: 0px;
        }

        &:not(.submenu-option-list, .sidebar-section-title):hover {
          background-color: color(light, primaryBg);
          color: color(light, fontColor);
        }

        &.active {
          color: color(dark, fontColor);
          background-color: color(purple, dark);
        }
      }
    }
  }
}
