@import '../../styles/variables.scss';

@keyframes slideDown {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.Toast {
  display: flex;
  flex-direction: row;
  width: 500px;
  z-index: 4;
  justify-content: center;
  align-items: center;
  animation: 300ms slideDown ease-in-out;
  border-radius: 4px;
  -webkit-box-shadow: 5px 5px 19px -10px rgba(0, 0, 0, 1);
  -moz-box-shadow: 5px 5px 19px -10px rgba(0, 0, 0, 1);
  box-shadow: 5px 5px 19px -10px rgba(0, 0, 0, 1);
  margin-bottom: 10px;
  padding: 7px;
  color: white;

  &.success {
    background-color: #00bb97;
  }

  &.error {
    background-color: #eb645e;
  }

  &.info {
    background-color: #ffa800;
  }

  &:hover .inner .message i {
    display: block;
    cursor: pointer;
  }

  .inner {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .icon-block {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 75px;
      justify-content: flex-start;

      i {
        font-size: 24px;
        margin-top: 14px;
        margin-right: 6px;
      }

      i.success {
        color: color(brand, white);
      }

      i.error {
        color: color(brand, white);
      }
    }

    .message {
      position: relative;
      width: 100%;
      padding: 6px 25px 0 0;
      color: color(brand, white);
      font-weight: 400;
      font-family: $lato;

      .title {
        font-size: 18px;
        margin-top: 3px;
      }

      p {
        color: color(brand, white);
        margin: 7px 0px;
        font-size: 16px;
        line-height: 26px;
      }

      a {
        text-decoration: none;
      }

      i {
        position: absolute;
        top: 0;
        flex: 0;
        width: 0px;
        right: 25px;
        padding: 3px;
        font-size: 24px;

        &:hover.success {
          cursor: pointer;
        }

        &:hover.error {
          cursor: pointer;
        }
      }
    }
    .actions {
      display: flex;
      justify-content: center;
      padding: 10px 0;
    }
  }
}

@media (max-width: 600px) {
  .Toast {
    width: auto;
    margin: 20px 20px 0px;
  }
}
