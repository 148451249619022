@import '../../styles/variables.scss';

.light {
  .DatePicker {
    .dp-icon {
      i {
        color: color(dark, fontColor);
      }
    }

    .dp-label {
      color: color(light, fontColor);
    }

    .dp-inner {
      .month {
        select {
          color: color(light, fontColor);
        }
      }
    }
  }
}

.DatePicker {
  .dp-icon {
    max-width: 42px;
    max-height: 42px;
    background-color: color(purple, dark);
    font-size: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .dp-label {
    font-size: 13px;
    font-weight: 700;
    padding-bottom: 4px;
    display: block;

    &.error {
      color: red;
    }
  }

  .dp-inner {
    display: flex;
    flex-direction: row;

    .TextField {
      margin-left: 10px;
    }

    & > div {
      flex: 1;
    }

    & > div:last-of-type {
      margin-right: 0px;
    }

    select,
    input {
      flex: 1 1;
      width: 100%;
    }

    .day {
      .TextField {
        label {
          input {
            border-radius: 0px;
          }
        }
      }
    }

    .month {
      select {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    .time {
      display: flex;
      margin-left: 10px;

      .TextField {
        transform: translateY(-19px);
      }

      .Dropdown {
        min-width: 80px;
        margin-left: 10px;
      }
    }
  }
}
