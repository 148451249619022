@import '../../../../../styles/variables.scss';

.TableSideDrawer {
  display: none;
  position: absolute;
  flex-direction: column;
  right: 0;
  bottom: 0;
  top: 0;
  min-width: 400px;
  background-color: white;
  border: 1px solid color(light, shadow);
  box-shadow: $box-shadow;

  &.show-drawer {
    display: flex;
  }

  .header-container {
    display: flex;
    height: 50px;
    align-items: center;
    padding: 0 20px 0 20px;
    border-bottom: 1px solid color(light, shadow);
    background-color: #fafbfb;

    .title {
      margin-right: 15px;
      max-width: 400px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      color: color(grey, dark);
      font-weight: 600;
    }

    .action-container {
      display: flex;
      gap: 25px;
      margin-left: auto;
      align-items: center;

      i {
        color: color(grey, dark);

        &.action-menu-target {
          font-size: 14px;
        }

        &.close-table-drawer {
          margin-top: 2px;
          font-size: 19px;
        }

        &:hover {
          cursor: pointer;
          color: color(grey, light);
        }
      }
    }
  }

  .content-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    @include thin-scroller-y;
  }
}
