@import '../../styles/variables.scss';

.QuillTextEditor {
  .max-length {
    &.exceeded {
      color: color(red, dark);
    }

    .warning {
      color: color(yellow, warning);
    }
  }
}
