@import '../../../../styles/variables.scss';

.HubModalTheme {
  display: flex;
  flex-flow: column nowrap;
  gap: 15px;

  .theme-content-container {
    display: flex;
    gap: 20px;
    min-height: 340px;
  }
}
