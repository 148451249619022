@import '../../../styles/variables.scss';

.Description {
  h3 {
    margin: 0px;
    padding: 0px 20px 20px;
  }

  p,
  ol,
  ul {
    font-size: 16px;
    line-height: 26px;
    color: color(grey);

    li {
      &.ql-indent-1 {
        padding-left: 10px !important;
      }
      &.ql-indent-2 {
        padding-left: 20px !important;
      }
      &.ql-indent-3 {
        padding-left: 30px !important;
      }
      &.ql-indent-4 {
        padding-left: 40px !important;
      }
      &.ql-indent-5 {
        padding-left: 50px !important;
      }
      &.ql-indent-6 {
        padding-left: 60px !important;
      }
      &.ql-indent-7 {
        padding-left: 70px !important;
      }
      &.ql-indent-8 {
        padding-left: 80px !important;
      }
    }
  }

  a {
    color: color(purple, dark) !important;
    text-decoration: none !important;
    font-weight: 700;

    &:hover {
      color: color(purple);
    }
  }

  .ql-bubble .ql-editor {
    a {
      color: color(purple, dark);
      text-decoration: none;
      font-weight: 700;

      &:hover {
        color: color(purple);
      }
    }

    ol,
    ul {
      padding: 0px;
      margin: 0px 0px 0px 20px;

      li {
        margin: 0px;
        padding: 0px 0px 5px 0px;
      }
    }

    p {
      word-break: break-word;
    }
  }

  .quill {
    .ql-container {
      word-break: break-word;

      .ql-editor[contenteditable='true'] {
        padding: 20px;
      }

      .ql-editor[contenteditable='false'] {
        padding: 0px 20px;
      }

      .ql-editor {
        ol,
        ul {
          padding-left: 0px;
        }
      }
    }
  }

  .TextField {
    margin-bottom: 10px;

    input {
      font-size: 22px;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
  }
}

@media (max-width: 930px) {
  .Description {
    h3 {
      padding: 0px 0px 20px;
    }

    .quill .ql-container .ql-editor[contenteditable='false'] {
      padding: 0px;
    }
  }
}
