@import '../../../styles/variables.scss';

.MediaAlbum {
  h3 {
    margin: 0px;
    padding: 0px 20px 20px;
  }

  .title {
    .TextField:first-of-type {
      padding: 0px 20px 20px;
    }
  }

  .albums {
    padding: 0px 20px;
    display: flex;
    flex-wrap: wrap;

    .MediaAlbumItem {
      margin-right: 30px;
      margin-bottom: 30px;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .gallery {
    display: flex;
    flex-direction: column;
    width: 800px;

    .info {
      padding: 20px;
      display: flex;
      flex-direction: column;
      margin-top: 40px;

      .count {
        color: color(grey);
        font-size: 14px;
      }

      .title {
        margin-top: 2px;
        font-size: 21px;
        font-weight: 700;
      }
    }

    .items {
      padding: 0px 20px 20px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        display: block;

        &.landscape {
          width: 100%;
          height: auto;
        }

        &.portrait {
          height: 100%;
          width: auto;
          max-width: 800px;
          max-height: 70vh;
        }

        &.square {
          width: 100%;
          height: 100%;
          max-height: 70vh;
          object-fit: contain;
        }
      }

      .media-action {
        height: 40px;
        width: 40px;
        background-color: color(light, secondaryBg);
        position: absolute;
        top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          i {
            color: color(purple);
          }

          cursor: pointer;
        }

        i {
          font-size: 32px;
          color: color(purple, dark);
        }

        &.left {
          left: 20px;

          i {
            margin-right: 4px;
          }
        }

        &.right {
          right: 20px;

          i {
            margin-left: 4px;
          }
        }
      }
    }
  }

  .album {
    display: flex;
    flex-direction: column;

    .top-bar {
      display: flex;
      padding: 0px 20px 20px;

      .back {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        overflow: hidden;
        background-color: color(purple, dark);
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          color: color(dark, fontColor);
          font-size: 31px;
          margin-right: 3px;
        }

        &:hover {
          background-color: color(purple);
          cursor: pointer;
        }
      }

      .info {
        padding-left: 15px;
        display: flex;
        flex-direction: column;

        .count {
          color: color(grey);
          font-size: 14px;
        }

        .title {
          margin-top: 2px;
          font-size: 21px;
          font-weight: 700;
        }
      }
    }

    .media-items {
      display: flex;
      flex-wrap: wrap;
      padding: 0px 20px;

      .media-item {
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 141px;
        height: 141px;
        overflow: hidden;
        margin-right: 20px;
        -webkit-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.46);
        -moz-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.46);
        box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.46);
        margin-bottom: 20px;

        &:hover {
          cursor: pointer;

          img {
            transform: scale(2.25);
          }
        }

        img {
          transition: all 150ms ease-in-out;
          transform: scale(2);
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 930px) {
  .MediaAlbum {
    h3 {
      padding: 0px 0px 20px;
    }

    .albums {
      padding: 0px;
    }
  }
}

@media (max-width: 800px) {
  .MediaAlbum {
    .gallery {
      width: auto;
    }
  }
}
