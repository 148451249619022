@import '../../../styles/variables.scss';

.PageSelector {
  position: relative;
  border-bottom: 1px solid #cad5d9;
  border-radius: 15px 15px 0px 0px;
  height: 50px;
  width: 100%;
  display: flex;

  .scroller {
    flex-grow: 1;
    position: relative;
    width: calc(100% - 42px);
  }

  .add-button {
    border-radius: 50%;
    background-color: color(pink, base);
    width: 32px;
    height: 32px;
    margin-left: auto;
    align-self: center;
    margin-right: 10px;
    cursor: pointer;

    svg {
      width: 32px;
      height: 32px;
    }

    &:hover {
      background-color: color(purple, dark);
    }
  }

  .page-item {
    position: relative;
    scroll-snap-align: center;
    font-size: 16px;
    line-height: 47px;
    height: 50px;
    box-sizing: border-box;
    width: auto;
    padding: 0 40px;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
    font-weight: 700;

    &:hover:not(.selected) {
      background-color: color(purple, lightest);
      color: color(purple, dark);
      border-bottom: 3px solid color(purple, dark);
    }

    &.selected {
      color: color(purple, dark);
      border-bottom: 3px solid color(purple, dark);
    }
  }
}

@media (max-width: 1240px) {
  .PageSelector {
    position: relative;
    overflow: none;
    width: 100vw;
    border-radius: 0;
    border: none;

    .scroller {
      width: calc(100% - 32px);
    }

    .page-item {
      padding: 0;
      margin: 0 20px;

      &.selected {
        color: initial;
      }

      &:hover:not(.selected) {
        color: initial;
      }
    }

    .add-button {
      width: 22px;
      height: 22px;

      button {
        width: 22px;
        height: 22px;
      }

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}
