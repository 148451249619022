@import '../../styles/variables.scss';

.Component-Library {
  max-height: 500px;
  max-width: 620px;
  display: flex;
  flex: 1;
  flex-direction: column;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    max-height: 90px;
    min-height: 90px;
  }

  .inner {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 15px;
    overflow-y: auto;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: 1px solid #e7eeef;
    border-bottom: 1px solid #e7eeef;

    .component-wrap {
      display: flex;
      width: 50%;

      &:nth-child(odd) {
        .component-item {
          margin-right: 12px;
        }
      }

      &:nth-child(even) {
        .component-item {
          margin-left: 12px;
        }
      }

      .component-item {
        width: 100%;
        display: flex;
        cursor: pointer;
        flex-direction: row;
        margin: 12px 0px;
        border-radius: 15px;
        border: 1px solid #e7eeef;
        overflow: auto;

        &:hover {
          background-color: color(cream, dark);
        }

        &.active {
          border: 1px solid color(purple, dark);

          .icon-slot {
            color: color(purple, dark);
          }
        }

        .icon-slot {
          width: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 42px;
          padding: 20px;
        }

        .info {
          padding: 10px;

          .component-title {
            font-size: 14px;
            margin: 3px 0px;
            font-weight: 700;
          }

          .desc {
            font-size: 12px;
            line-height: 1.5;
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-around;
    padding: 20px 0px;

    .Button:first-of-type button {
      background-color: color(dark, fontColor);
      color: color(grey);
      border: 1px solid color(grey, light);
    }

    .Button button[disabled] {
      color: color(dark, fontColor);
    }
  }
}

.Modal {
  .content {
    padding: 0;
  }
}

@media (max-width: 640px) {
  .Component-Library {
    .inner {
      .component-wrap {
        width: 100%;

        .component-item {
          flex: 1;
          margin-right: 0px !important;
          margin-left: 0px !important;
        }
      }
    }
  }
}
