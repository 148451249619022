@import '../../../../styles/variables';

.HubManagementInvoiceModal {
  .container {
    display: flex;
    flex-direction: column;
    height: 842px;
    max-width: 595px;
    width: 100%;
    color: color(grey, dark);

    .top {
      background: url('../../../../assets/hub/hub_seats_invoice_bg.svg') no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      flex-grow: 0;
      height: 127px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 47px 52px 37px 52px;
      color: color(light, secondaryBg);

      .logo-info {
        display: flex;
        justify-content: space-between;

        .logo {
          img {
            width: 127px;
            height: 19px;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-family: $raleway;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;

          .email {
            margin-top: 18px;
          }
        }
      }

      .invoice-info {
        display: flex;
        justify-content: flex-start;

        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          b {
            margin-bottom: 8px;
          }
        }

        .invoice {
          margin-right: 68px;
          min-width: 102px;
        }
      }
    }

    .mid {
      padding: 0 57px;
      flex-grow: 1;
      margin-top: 44px;

      h3 {
        margin: 0 0 30px 0;
        font-family: $raleway;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
      }

      .info {
        display: flex;

        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          font-size: 14px;

          b {
            margin-bottom: 8px;
          }

          &.send_to {
            min-width: 170px;
            margin-right: 24px;
          }

          &.product {
            min-width: 175px;
            margin-right: 24px;
          }

          &.status {
            span {
              border-radius: 6px;
              padding: 6px 8px;
              display: inline-flex;
              justify-content: center;
              text-align: center;
              font-family: $lato;
              font-style: normal;
              font-weight: 700;
              font-size: 14px;

              &.paid {
                background-color: color(green, light);
                color: color(green, dark);
              }

              &.unpaid {
                background-color: color(cream, light);
                color: color(cream, dark);
              }

              &.refunded,
              &.pending,
              &.waiting {
                background-color: color(yellow, light);
                color: color(yellow, dark);
              }
            }
          }
        }
      }

      h5 {
        font-family: $raleway;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        margin: 30px 0 10px 0;
      }

      .card-info {
        display: flex;
        font-family: $lato;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        align-items: center;
        justify-content: flex-start;

        img {
          width: 20px;
          height: 20px;
          margin-right: 13px;
        }
      }

      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        margin-top: 53px;
      }

      tr.head {
        background-color: color(light, box);
      }

      th {
        font-weight: 700;
      }

      td,
      th {
        border: unset;
        text-align: left;
        font-size: 12px;
        white-space: nowrap;
        padding: 8px;
      }
    }

    .bottom {
      padding: 0 40px 47px 40px;
      flex-grow: 0;
      height: 30px;
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 16px;
        margin-right: 35px;
      }

      .info {
        width: 245px;

        p {
          text-align: center;
          margin: 0;
          font-family: $lato;
          font-style: normal;
          font-weight: 400;
          font-size: 8px;
        }
      }

      .page {
        height: 16px;
        text-align: right;
        font-size: 12px;
        flex-grow: 1;
      }
    }
  }
}
