@import '../../styles/variables.scss';

.Construction {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: color(teal);
  display: none;
  flex-direction: column;
  z-index: 1;

  .Construction-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    flex: 1;
    max-width: 500px;
    margin: 0px auto;

    .title {
      color: color(purple, dark);
      font-size: 24px;
      font-weight: 700;
      text-align: center;
    }

    .sub-title {
      padding: 20px 0px;
      text-align: center;
      line-height: 24px;
      font-size: 16px;
    }

    a {
      background-color: color(purple, dark);
      color: color(dark, fontColor);
      font-weight: 700;
      padding: 10px 20px;
      border-radius: 20px;
      text-decoration: none;
      margin: 0px auto;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        margin-right: 8px;
      }
    }
  }

  .image-wrap {
    flex: 1;
    max-height: 40vh;
    overflow: hidden;
    margin-top: 76px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: auto;
      height: 100%;
      max-width: 812px;
    }
  }
}

@media (max-width: 823px) {
  .Construction {
    display: flex;
  }
}
