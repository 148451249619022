@import '../../../styles/variables.scss';

.IWantToMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f6f7;
  z-index: 6;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 5px 0;

  h2 {
    align-self: center;
    margin-left: 10px;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      min-height: 124px;
      border-radius: 8px;
      background: #fff;
      font-size: 16px;
      padding: 10px;

      i {
        font-size: 24px;
        padding: 0 25px;
        margin-bottom: 5px;
        font-weight: 500;
      }

      .title {
        text-align: center;
      }
    }
  }
}

// Fix for I want to menu not showing. TODO: better way of doing this?
@media (max-width: 920px) {
  .IWantToMenu {
    width: 100vw;
    bottom: 0;
  }
}
