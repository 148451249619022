@import '../../../styles/variables.scss';

.Popup {
  display: flex;
  flex-flow: column;
  position: absolute;

  .close {
    position: absolute;
    right: 0;
    top: 0;
    color: black;
    font-size: 16px;
    font-weight: 300;
    margin-right: 10px;
    margin-top: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  .popup-content {
    background-color: white;
    min-width: 225px;
  }

  .popup-anchor {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    align-self: center;

    &.up {
      border-bottom: 10px solid white;
    }

    &.down {
      border-top: 15px solid white;
    }
  }

  &:hover {
    cursor: default;
  }
}
