@import '../../../styles/variables.scss';

.ContactVolunteer {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  padding: 0 40px 40px;
  max-height: 850px;
  overflow: auto;

  .add-shift-form-container {
    .add-shift-form {
      .title {
        margin: 20px 40px 40px 40px;
        font-weight: 700;
        font-size: 28px;
        text-align: center;
      }

      .sub-title {
        font-weight: 500;
        margin: 30px 40px 0 40px;
        padding: 0 0 10px;
        border-bottom: 1px solid #898989;
        line-height: 20px;
        font-size: 20px;
      }

      .dropdown-select {
        margin: 10px 40px;
        max-height: 300px;
        overflow: auto;
      }

      .action {
        padding: 0 40px;
        display: flex;
        border-top: 1px solid #898989;
        height: 80px;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  .key-skill {
    margin: 2px;
    padding: 1px;
    color: color(purple, dark);
    border: color(purple, dark) solid 1px;
  }

  .contact-main {
    .stat {
      text-align: center;
    }

    .col {
      white-space: pre-wrap;

      &.check {
        flex: 0;
        margin: 0 10px;
      }
    }
  }

  .Contact-content .contact-main .SortableTable .table-row .col.verify span {
    &.text {
      text-align: center;
      font-weight: unset;
    }

    padding: 0;
    min-height: unset;
    color: color(light, secondaryBg);
  }

  .Contact-content .contact-main .SortableTable .table-row .col.status span {
    padding: 4px 8px;
    border-radius: 20px;
  }

  .pending-tag {
    color: color(gray, dark);
    border: color(gray, light) solid 1px;
  }

  .verified-tag {
    color: color(green, dark);
    background-color: color(green, light);
    border: color(green, dark) solid 1px;
  }

  .selected-shifts-container {
    display: grid;
    column-gap: 6px;
    grid-template-columns: repeat(3, auto);
    width: max-content;
    align-items: center;
    margin: 10px 0;

    i {
      font-size: 18px;
      color: color(green, neon);
      background-color: color(green, light);
    }
  }
}
