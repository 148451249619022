@import '../../styles/variables.scss';

.FilterData {
  min-width: 700px;

  .title {
    font-weight: 700;
    font-size: 28px;
    text-align: center;
  }

  .sub-title {
    font-weight: 500;
    margin: 30px 0px 0px;
    padding: 0px 0px 10px;
    border-bottom: 1px solid color(grey, light);
    line-height: 20px;
    font-size: 20px;
  }

  .empty {
    color: color(grey);
  }

  .add-filter {
    margin: 10px 0px 0px;

    .Button {
      button {
        background-color: transparent;
        color: color(grey);

        .icon {
          margin-right: 5px;
        }

        &:hover {
          color: color(purple, dark);
        }
      }
    }
  }

  .title,
  .sub-title,
  .filter-wrap {
    margin-left: 40px;
    margin-right: 40px;
  }

  .title {
    margin: 20px 40px 40px;
  }

  .filters {
    padding: 20px 0px;
    max-height: 300px;
    overflow: auto;

    .filter-wrap {
      .filter {
        padding-bottom: 20px;

        &.error {
          .filter-inputs {
            .filter-value {
              .TextField {
                .input-wrap {
                  border: 1px solid color(red, dark);
                  background-color: color(red, light);
                }
              }
            }
          }
        }

        &:nth-last-child(2) {
          padding-bottom: 10px;
        }

        .filter-title {
          font-size: 14px;
          font-weight: 700;
          padding-bottom: 8px;
        }

        .filter-inputs {
          display: flex;

          .filter-name {
            .Dropdown {
              min-width: 200px;
              margin-right: 10px;
            }
          }

          .filter-value {
            flex: 1;

            .Dropdown {
              flex: 1;
            }

            .filter-value-inner {
              display: flex;

              .Dropdown {
                max-width: 80px;
                margin-right: 10px;
              }
            }

            .rdt.custom-dtp {
              &.rdtOpen {
                .rdtPicker {
                  position: fixed;
                }
              }
            }
          }

          .filter-actions {
            margin-left: 10px;

            .Button {
              button {
                background-color: transparent;
                color: color(grey);
                height: 44px;
                width: 44px;
                border: 1px solid color(grey, light);
                border-radius: 7px;
                padding: 0px;
                min-width: 44px;

                .text {
                  display: none;
                }

                .icon {
                  margin-right: 0px;
                }

                &:hover {
                  .icon {
                    color: color(red, dark);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .filters-actions {
    display: flex;
    border-top: 1px solid color(grey, light);
    height: 80px;
    justify-content: flex-end;
    align-items: center;

    .Button {
      button {
        margin-right: 40px;
      }
    }
  }
}
