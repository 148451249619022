@import '../../styles/variables.scss';

.DetailedToggle {
  .label {
    font-weight: bold;

    .required {
      color: color(purple, dark);
    }
  }

  .option {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    cursor: pointer;

    i {
      color: color(grey);
      font-size: 21px;

      &.selected {
        color: color(purple, dark);
      }
    }

    label {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  span.error_text {
    color: red;
    font-size: 14px;
  }
}
