@import '../../../styles/variables.scss';

.SetupExternalAccountModal {
  .external-account-content {
    padding: 40px;

    .form {
      .form-input-field {
        margin-bottom: 10px;
      }

      .submit-button {
        margin-top: 20px;
      }
    }
  }
}
