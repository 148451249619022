@import '../../../styles/variables.scss';

.FocusAreas {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  margin-left: 10px;

  &:nth-child(1) {
    z-index: 2;
  }
  &:nth-child(2) {
    z-index: 3;
  }
  &:nth-child(3) {
    z-index: 4;
  }
  &:nth-child(4) {
    z-index: 5;
  }

  .Portrait,
  .empty-portrait {
    margin-left: -7px;
  }

  .Portrait {
    .inner {
      background-color: white;

      span {
        display: flex !important;
        justify-content: center;
        align-items: center;

        img {
          position: relative !important;
          width: 17px !important;
          height: 17px !important;
          // left: 5px;
          // top: 5px;
        }
      }
    }
  }

  .empty-portrait {
    background: #ffffff;
    border: 2px solid #f5f5f5;
    box-sizing: border-box;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    font-family: $lato;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    justify-content: center;
    color: color(grey, light);
    z-index: 5;
  }

  .no-causes {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: #e9e9e8;
    margin-left: -10px;
    border: 2px solid #f5f5f5;

    &:nth-of-type(1) {
      z-index: 1;
    }
    &:nth-of-type(2) {
      z-index: 2;
    }
    &:nth-of-type(3) {
      z-index: 3;
    }
    &:nth-of-type(4) {
      z-index: 4;
    }
    &:nth-of-type(5) {
      z-index: 5;
    }
  }
}
