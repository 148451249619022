@import '../../styles/variables.scss';

.Event {
  flex: 1;
  background-color: color(light, primaryBg);
  max-width: 100%;

  .PageDetails {
    .cover-image {
      .CoverImage {
        img {
          height: unset;
        }
      }
    }
  }

  .BackSplash {
    position: relative;
    overflow: hidden;
  }

  .inner-wrap {
    margin-top: -400px;
    padding-bottom: 400px;
    position: relative;

    .donation-matching-mobile {
      display: none;
      max-width: 1400px;
      margin: 0px auto;

      .donation-matching-mobile-wrap {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 20px;
        background-color: #e7f6f8;
        border-radius: 10px;
      }

      .label {
        align-self: center;
        color: #0c9fb5;
        margin-bottom: 10px;
        font-weight: 500;
      }

      .description {
        align-self: center;
        text-align: center;
      }

      .matched-to-date {
        align-self: center;
        color: #0c9fb5;
        margin-top: 20px;
      }
    }

    .first-page-widgets {
      margin: 0px auto;
      display: flex;
      max-width: 1400px;
      flex: 1;

      .progress,
      .main-event {
        flex: 1;
      }

      .fp-widget:only-child {
        margin-left: 0px;
        margin-right: 0px;
      }

      .main-event {
        display: flex;
        background-color: color(light, secondaryBg);
        border-radius: 15px;
        min-height: 128px;
        margin-left: 10px;
        overflow: hidden;

        .Portrait {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 128px;
          width: 128px;

          .inner,
          .inner img {
            border-radius: 0px;
          }
        }

        .main-event-info {
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
          padding-left: 20px;

          .me-tag {
            display: flex;
            align-items: center;
            font-size: 16px;
            text-transform: uppercase;
            font-size: 14px;

            i {
              transform: rotateZ(-45deg);
              font-size: 12px;
              margin-top: -2px;
              color: #a7b0b4;
            }

            span {
              display: inline-block;
              margin-top: -2px;
              padding-left: 8px;
              font-weight: 700;
            }
          }

          .me-title {
            font-size: 21px;
            color: #354a53;
            font-weight: 700;
            padding: 5px 0px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .me-goal {
            font-size: 16px;
            color: #354a53;

            span {
              font-weight: 500;
            }
          }
        }

        .main-event-action {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 20px;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: color(purple, dark);
            font-size: 16px;
            font-weight: 500;
            border: 2px solid color(purple, dark);
            border-radius: 20px;
            height: 40px;
            padding: 0px 20px;
            min-width: 100px;

            &:hover {
              border: 2px solid color(purple);
              color: color(purple);
            }
          }
        }
      }

      .progress {
        margin: 0px auto;
        max-width: 1400px;
        background-color: color(light, secondaryBg);
        padding: 20px 0px;
        border-radius: 15px;
        box-shadow: $box-shadow;
        margin-right: 10px;

        .progress-title {
          font-size: 24px;
          padding: 0px 20px 20px;
          font-weight: 700;
          color: #354a53;
        }

        .progress-bar {
          position: relative;
          background-color: #012939;
          border: 2px solid #416aae;
          margin: 0px 20px;
          height: 35px;
          border-radius: 10px;
          display: flex;
          align-items: center;

          .progress-inner {
            transition: 500ms all ease-in-out;
            background: transparent linear-gradient(88deg, #008599 0%, #734fd5 100%) 0% 0% no-repeat
              padding-box;
            height: 29px;
            border-radius: 5px;
            margin: 0px 4px;
            width: 0%;
            transition-delay: 300ms;
          }

          .raised,
          .goal {
            position: absolute;
            top: 7px;
            color: color(dark, fontColor);
            font-weight: 700;
            font-size: 18px;
          }

          .raised {
            left: 10px;
          }

          .goal {
            left: auto;
            right: 10px;
          }
        }
      }
    }

    .pages {
      padding: 20px 0px;
      background-color: color(light, secondaryBg);
      max-width: 1400px;
      margin: 20px auto;
      border-radius: 15px;
      box-shadow: $box-shadow;
      min-height: 100vh;
      margin-bottom: -360px;
      display: flex;

      .full-width {
        min-width: 100%; // Make room for sidebar
      }

      .page-content {
        flex: 1;
        max-width: calc(100% - 400px); // Make room for sidebar

        .components-wrap {
          .Donations {
            .ComponentPlaceholder {
              margin: 0px;
            }
          }
        }
      }

      .sidebar {
        max-width: 400px;
        min-width: 400px;

        .Donations {
          padding: 10px 20px;

          h3 {
            font-size: 22px;
            padding: 0px 0px 15px;
            margin: 0px;
          }

          .ComponentPlaceholder {
            margin: 5px 0px 0px;
          }

          .donation {
            background-color: #fafafa;
            padding: 20px;
            margin: 0px 0px 20px;
            border-radius: 15px;

            .donation-details {
              flex: 1;

              .name {
                position: relative;
                width: 100%;
                font-size: 14px;

                span {
                  color: #888d90;
                }
              }

              .note {
                font-size: 14px;
                color: #88949a;
                line-height: 18px;
              }
            }
          }
        }
      }

      .components-wrap {
        padding: 0px 20px;
      }

      .ComponentPlaceholder {
        margin: 20px 30px;
      }

      .PageComponent {
        .ComponentPlaceholder {
          margin: 0px 20px;
        }
      }

      .add-component {
        flex: 1;
        padding: 0px 50px;
        margin: 10px 0px 10px;

        button {
          flex: 1;
          width: 100%;
          background-color: color(light, primaryBg);
          border-radius: 8px;
          min-height: 45px;

          .text {
            color: color(light, fontColor);
          }

          .icon {
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: color(purple, dark);
          }

          &:hover {
            .text,
            .icon {
              color: color(purple, dark);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1420px) {
  .Event {
    .inner-wrap {
      padding: 0px 20px;
      padding-bottom: 400px;
    }
  }
}

@media (max-width: 1240px) {
  .Event {
    .BackSplash {
      .CoverImage {
        filter: blur(40px);
        .overlay {
          display: none;
        }
      }
    }

    .inner-wrap {
      margin-top: -220px;
    }
  }
}

@media (max-width: 1120px) {
  .Event {
    .inner-wrap {
      .first-page-widgets {
        flex-direction: column;

        .progress {
          width: 100%;
          margin-bottom: 20px;
        }

        .main-event {
          margin-left: 0px;
        }
      }

      .pages {
        flex-direction: column;

        .page-content {
          max-width: 100%;
        }

        .sidebar {
          min-width: 0px;
          max-width: 100%;

          .Donations {
            display: block;
            padding: 20px 50px 0px;
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .Event {
    .inner-wrap {
      .pages {
        .sidebar {
          .Donations {
            padding: 20px;
          }
        }

        .page-content {
          .add-component {
            padding: 0 20px;
          }
        }
      }
      .first-page-widgets {
        .progress {
          .progress-bar {
            margin: 0 8px;
          }
        }
      }

      .progress {
        .progress-bar {
          margin: 0 8px;
        }
      }
    }
  }
}

@media (max-width: 530px) {
  .Event {
    .BackSplash {
      .CoverImage {
        filter: none;
        .overlay {
          display: contents;
          background-color: transparent;
          i {
            display: none;
          }
        }
      }
    }
    .inner-wrap {
      margin-top: -120px;
      pointer-events: all;
      .donation-matching-mobile {
        display: flex;
      }

      .first-page-widgets {
        .main-event {
          padding: 20px 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .Portrait {
            border-radius: 50%;
            overflow: hidden;
          }

          .main-event-info {
            justify-content: center;
            align-items: center;
            padding: 15px 0px;
          }
        }
      }

      .pages {
        .components-wrap {
          padding: 0px 10px;

          .components-wrap {
            padding: 0px;
          }
        }

        .sidebar {
          .Donations {
            padding: 20px;
          }
        }
      }
    }
  }
}
