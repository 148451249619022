@import '../../../../styles/variables.scss';

.AuctionItemBuyNowModal {
  .Modal {
    &.auction-images {
      .content {
        background-color: color(light, secondaryBg);
      }
    }

    .no-background {
      background-color: unset;
    }

    .content {
      position: relative;
      height: 100%;

      .modal-header-mobile {
        display: none;
      }

      .modal-header-desktop {
        padding-bottom: 20px;
      }

      .auction-payment {
        display: flex;
        flex: 1;
        max-height: 90%;

        .details {
          border-radius: $border-radius;
          background-color: color(light, secondaryBg);
          display: block;
          margin-top: 0px;
        }

        .summary {
          margin-left: 20px;
          min-width: 320px;

          .Loader {
            display: flex;
            justify-content: center;
            flex: 1;
            padding: 20px;

            i {
              font-size: 24px;
            }
          }

          .summary-inner {
            border-radius: $border-radius;
            background-color: color(light, secondaryBg);

            .title {
              padding: 30px 40px;
              font-size: 28px;
              font-weight: 700;
              text-align: center;
              border-bottom: 1px solid color(grey, light);
              margin: 0px 30px;
            }

            .sub-price {
              padding: 30px 40px 0px;
              display: flex;
              flex: 1;

              span {
                flex: 1;

                &:first-child {
                  font-weight: 700;
                }

                &:last-child {
                  text-align: right;
                  color: color(grey);
                }
              }
            }

            .total {
              background-color: color(grey);
              margin: 20px 0px 0px;
              padding: 20px 40px;
              color: color(dark, fontColor);
              display: flex;
              flex: 1;
              font-size: 21px;
              font-weight: 700;

              span {
                flex: 1;

                &:last-child {
                  text-align: right;
                }
              }
            }

            .actions {
              padding: 40px 40px 20px;
              border-bottom-left-radius: 20px;
              border-bottom-right-radius: 20px;
              display: flex;
              flex-direction: column-reverse;
              background-color: color(light, secondaryBg);

              .Button {
                flex: 1;
                margin-bottom: 20px;

                &:last-child {
                  button {
                    background-color: color(light, secondaryBg);
                    color: color(grey);
                    border: 2px solid color(grey, light);

                    &:hover {
                      color: color(purple);
                      border: 2px solid color(purple);
                    }
                  }
                }

                button {
                  flex: 1;
                  width: 100%;
                }
              }
            }
          }
        }

        .details {
          flex: 1;
          min-width: 600px;
          max-width: 600px;
          overflow: auto;

          .title {
            padding: 20px 40px 0px;
            font-size: 28px;
            font-weight: 700;
            text-align: center;
          }

          .sub-title {
            text-align: center;
            color: color(grey);
            font-size: 14px;
            padding: 10px 40px 0px;
          }

          .item-details {
            background-color: #f3f1ec;
            display: flex;
            padding: 20px;

            .image {
              max-width: 200px;
              min-width: 200px;

              img {
                width: 100%;
                height: auto;
                border-radius: 8px;
              }
            }

            .item-content {
              padding-left: 20px;
              position: relative;
              flex: 1;

              .item-title {
                font-weight: 700;
                font-size: 21px;
                padding-right: 80px;
              }

              .item-description {
                .ql-editor {
                  padding: 20px 0px 10px;
                  font-size: 14px;
                  line-height: 21px;
                  color: color(grey);
                }
              }

              .winner {
                position: absolute;
                top: 0px;
                right: 0px;
                background-color: color(green, light);
                color: color(green, dark);
                padding: 4px 12px;
                border-radius: 20px;
                border: 1px solid color(green, dark);
              }
            }
          }

          .form-title {
            font-size: 21px;
            margin: 40px 40px 20px;
            padding-bottom: 10px;
            color: color(purple, dark);
            border-bottom: 1px solid color(grey, light);
          }

          .sub-form-title {
            margin: 0px 40px 20px;
            padding: 0px;
            font-weight: 700;
            font-size: 14px;
            display: flex;
            align-items: center;

            &.click {
              color: color(grey);
              font-weight: 400;

              &:hover {
                cursor: pointer;
              }
            }

            i {
              margin-right: 5px;
              font-size: 17px;

              &.fa-check-circle {
                color: color(purple, dark);
              }
            }
          }

          .shipping-options {
            margin: 0px 40px 20px;
            flex: 1;

            .shipping-option {
              padding-bottom: 20px;
            }

            .ship-title {
              font-weight: 500;
            }

            .shipping-label {
              margin: 10px 0px 0px 22px;
              font-size: 16px;
              font-weight: 300;

              span {
                font-weight: 500;
                margin-right: 8px;
              }

              &:hover {
                cursor: pointer;
              }

              i {
                margin-right: 5px;

                &.fa-check-circle {
                  color: color(purple, dark);
                }
              }
            }
          }

          .row {
            display: flex;
            flex: 1;
            margin: 0px 40px 20px;

            &.dbl {
              .TextField {
                &:first-child {
                  margin-right: 10px;
                }

                &:last-child {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }

    .date-wrapper {
      flex: 1;
    }

    .custom-dtp {
      input {
        width: 100%;
        margin-right: 20px;
        margin-top: 5px;
      }
    }
  }

  .Dropdown {
    width: 100%;
  }
}

@media (max-width: 1030px) {
  .AuctionItemBuyNowModal {
    .Modal {
      .content {
        .auction-payment {
          width: 100%;

          .details {
            overflow: visible;
            min-width: 0px;
            max-width: 100%;

            .item-details {
              padding: 0px;

              .image {
                min-width: 0px;
                max-width: 100%;

                img {
                  border-radius: 0px;
                }
              }

              .item-content {
                padding: 10px 40px;

                .winner {
                  right: 40px;
                }
              }
            }

            .row.dbl .TextField {
              &:first-child {
                margin-right: 0px;
              }

              &:last-child {
                margin-left: 0px;
              }
            }
          }
        }

        .auction-payment {
          .summary {
            margin-left: 0px;

            .summary-inner {
              border-radius: 0px;

              .summary-wrap {
                .sub-price {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 0px 40px;
                  height: 40px;
                  border-top: 1px solid #eee;
                  background-color: #fff;
                }

                .total {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-top: 0px;
                  height: 40px;
                  padding: 0px 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .AuctionItemBuyNowModal {
    .Modal {
      .content {
        .auction-payment {
          .details {
            .item-details {
              .item-content {
                padding: 10px 20px;

                .winner {
                  right: 20px;
                }
              }
            }

            .form-title {
              margin: 20px;
            }

            .sub-form-title {
              margin: 0px 20px 20px;
            }

            .row {
              margin: 0px 20px 20px;
            }

            .shipping-options {
              margin: 0px 20px 20px;
            }
          }

          .summary {
            .summary-inner {
              .summary-wrap {
                .sub-price {
                  padding: 0px 20px;
                }

                .total {
                  padding: 0px 20px;
                }
              }

              .actions {
                padding: 40px 20px 20px;
              }
            }
          }
        }

        .modal-header-mobile {
          display: flex;
        }
      }
    }
  }
}
