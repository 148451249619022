@import '../../../../../styles/variables.scss';

.CommunityFeedPost {
  width: 100%;
  padding: 24px;
  background: color(light, secondaryBg);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
  overflow: hidden;
  position: relative;
  min-height: 290px;

  &.story {
    min-height: 100px;
    max-height: 200px;
  }

  .Modal {
    .share-modal {
      &.content {
        display: unset;
        flex-direction: unset;
        align-items: unset;
        justify-content: unset;
        margin-top: unset;
        width: unset;
      }
    }
  }

  &.options-modal {
    width: 25% !important;
    display: flex;
    justify-content: space-between;

    p {
      text-align: center !important;
    }

    .buttons {
      display: flex;
      justify-content: space-between;

      .Button {
        &:nth-of-type(2) {
          button {
            background-color: #c13128 !important;
          }
        }
      }
    }
  }

  &.modal {
    padding: 24px;
    max-width: 567px;
    height: 573px;
    max-height: calc(100vh - 71px);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: color(grey, text) color(grey, pale);
    scroll-behavior: smooth;

    &::-webkit-scrollbar-track {
      background: color(grey, pale);
    }

    &::-webkit-scrollbar-thumb {
      background-color: color(grey, text);
      border-radius: 20px;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }

    &.content {
      justify-content: unset;
      margin: unset;
    }

    .CommunityFeedPostComments {
      height: max-content;
      overflow-y: unset;
    }

    .social-container {
      border: unset;
    }

    .comment-input-container {
      position: relative;
      top: unset;
      left: unset;
      background: unset;
      max-height: 52px;
      height: 52px;
      overflow: unset;
      border: unset;
      box-sizing: unset;
      box-shadow: unset;

      .TextField,
      .TextArea {
        overflow: hidden;
        border-radius: 26px;
      }

      .input-wrap {
        background: color(grey, pale);
        padding-left: 8px;

        input {
          padding-left: 8px;
          background: color(grey, pale);
        }
      }
    }

    .modal-back-section {
      width: 100%;
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid color(light, shadow);

      span {
        i {
          margin-right: 18px;
        }

        font-weight: 800;
        font-size: 14px;
        cursor: pointer;
        color: color(grey, dark);
      }
    }

    .post-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid color(light, shadow);

      .content-img {
        max-height: 321px;
        background-color: color(grey, imgBg);
        border-radius: 8px;
        width: 100%;
      }

      p {
        width: 100%;
        text-align: left;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin: 24px 0 0 0;
        color: color(grey, dark);

        .story {
          color: color(purple, link);
          cursor: pointer;
        }

        a {
          color: color(purple, link);
          text-decoration: unset;
        }
      }
    }
  }

  &.archived {
    height: 200px;
    min-height: 150px !important;
  }

  &:first-of-type {
    margin: 0 0 10px 0;
  }

  .triggered-by-action {
    border-bottom: 1px solid color(light, shadow);
    width: 100%;
    margin-bottom: 24px;

    p {
      margin: 0 0 24px 0;
      text-align: left;
      font-style: normal;
      font-size: 14px;
      color: color(grey, base);

      a {
        margin-left: 10px;
        text-decoration: none;
        font-weight: 800;
        color: color(grey, base);
        transition: all 0.2s;

        &:hover {
          color: color(purple, dark);
        }
      }
    }
  }

  .view-more {
    span {
      color: color(grey, dark);
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      transition: all 0.2s;
      margin-top: 24px;
      cursor: pointer;

      &:hover {
        color: color(purple, dark);
      }
    }
  }

  .show-comment {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    font-feature-settings:
      'pnum' on,
      'lnum' on;
    font-style: normal;

    .CommunityFeedPostComments {
      padding: unset;
      margin: unset;
      width: 100%;
      height: max-content;
    }

    &.at-bottom {
      padding-top: 24px;
      border-top: 1px solid color(light, shadow);
      margin-top: 24px;
    }

    .parent-comment-container {
      margin-top: 16px;
    }

    .user-avatar,
    img {
      width: 40px;
      height: 40px;
      border-radius: 24px;
    }

    .user-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px color(grey, dark);
      font-size: 24px;
    }

    a {
      text-decoration: none;
      color: color(grey, dark);
      transition: all 0.2s;

      &:hover {
        color: color(purple, dark);
      }
    }

    .comment-content-container {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      color: color(grey, text2);

      .content-container {
        background: color(grey, pale);
        border-radius: 8px;
        padding: 12px 0 0 12px;

        .comment-title {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 4px;

          a {
            text-decoration: none;
            transition: all 0.2s;

            &:hover {
              color: color(purple, dark);
            }
          }
        }

        .comment-content {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
        }
      }

      .reply-container {
        margin-top: 10px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: color(dark, border);

        .reply {
          transition: all 0.2s;
          cursor: pointer;

          &:hover {
            color: color(purple, dark);
          }
        }
      }
    }
  }

  .comments-container-mobile {
    display: none;
  }

  .comment-input-container,
  .comment-input-container-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    background: color(light, secondaryBg);
    border-radius: 0;
    max-height: 0;
    overflow: hidden;
    width: 100%;
    transition: all 0.2s;

    .reply-to {
      font-size: 12px;
      place-self: center;
      color: color(purple, dark);
      padding: 0 4px;
    }

    &.active {
      max-height: 52px;
      border: 1px solid color(light, shadow);
      box-sizing: border-box;
      box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.12);
    }

    .TextField {
      .input-wrap {
        border: unset;

        input {
          height: 52px;
        }
      }
    }
  }

  .heading-post {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .min-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;

      a {
        text-decoration: unset;
        color: color(grey, dark);
        transition: all 0.2s;

        &:hover {
          color: color(purple, dark);
        }
      }

      .type {
        i {
          margin-right: 10px;
        }
      }
    }

    .menu {
      position: absolute;
      width: 308px;
      top: 50px;
      right: 0;
      margin: 0;
      list-style-type: none;
      background: color(light, secondaryBg);
      box-shadow: 0 16px 24px rgba(0, 0, 0, 0.12);
      border-radius: 10px;
      overflow: hidden;
      max-height: 0;
      transition: all 0.2s ease;

      &.show {
        max-height: 206px;
        overflow: unset;
        padding: 28px 27px;
        z-index: 1;
      }

      .hr {
        border: 1px solid color(grey, lightest);
        height: 0;
        width: 100%;
        margin: 14px 0;
      }

      .item {
        margin: 10px 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        font-feature-settings:
          'pnum' on,
          'lnum' on,
          'salt' on,
          'ss09' on;
        color: color(grey, base);

        &:hover {
          cursor: pointer;
        }

        i {
          margin-right: 17px;
        }
      }
    }

    .owner-info-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: 12px;
      max-width: 435px;
      margin-right: 8px;

      h5 {
        font-weight: 800;
        font-size: 16px;
        margin: 0 0 3% 0;

        a {
          text-decoration: none;
          transition: all 0.2s;
          color: color(grey, dark);

          &:hover {
            opacity: 0.8;
          }
        }
      }

      span {
        color: color(grey, secondary);
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;

        b {
          margin-left: 4px;
          font-weight: bold;
          color: color(grey, base);
        }
      }
    }

    .type {
      max-height: 30px;
      padding: 8px 18px;
      background: color(grey, pale);
      border-radius: 8px;
      color: color(grey, text);
      font-family: Raleway, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      font-feature-settings:
        'pnum' on,
        'lnum' on,
        'salt' on,
        'ss09' on;
    }

    .logo {
      border-radius: 61.6667px;
      filter: drop-shadow(0px 4.44444px 8.88889px rgba(0, 0, 0, 0.12));
      width: 50px;
      height: 50px;
    }

    .dots {
      background: color(light, secondaryBg);
      transition: all 0.2s;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
      border-radius: 24px;

      &:hover {
        background: color(grey, pale);
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 27px;
    width: 100%;

    .content-img {
      max-height: 321px;
      background-color: color(grey, imgBg);
      border-radius: 8px;
      width: 100%;
    }

    p {
      width: 100%;
      text-align: left;
      font-family: Lato, serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: color(grey, dark);

      .story {
        color: color(purple, link);
        cursor: pointer;
      }

      a {
        color: color(purple, link);
        text-decoration: unset;

        &:hover {
          opacity: 0.8;
        }
      }

      .view-more-description {
        color: color(purple, dark);

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }

  .tags-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 16px 0;
    overflow-x: auto;

    &::-webkit-scrollbar-track {
      background: color(grey, pale);
    }

    &::-webkit-scrollbar-thumb {
      background-color: color(grey, text);
      border-radius: 20px;
    }
    &::-webkit-scrollbar {
      height: 4px;
    }

    .tag {
      background: color(grey, pale);
      color: color(dark, border);
      border-radius: 8px;
      text-align: center;
      margin: 0 10px;
      font-family: Lato, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      padding: 8px 18px;
      white-space: nowrap;

      &.active {
        background: color(blue, whiteBlue);
        color: color(blue, soft2);
      }
    }
  }

  .social-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-top: 1px solid color(cream, gray);
    position: relative;

    .social-buttons {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      span {
        margin: 0 10px;
        cursor: pointer;

        i {
          margin-right: 10px;
        }

        &.like {
          &.liked {
            color: color(purple, dark);
          }
        }

        &.comment {
          &.commented {
            color: color(purple, dark);
          }
        }
      }
    }

    .comments-container {
      width: 100%;
      max-height: 0;
      transition: all 0.3s;
      padding-bottom: 24px;
    }
  }
}

@media (max-width: 1070px) {
  .CommunityFeedPost {
    margin: 10px 0;
    max-width: 336px;
    position: unset;

    &.modal {
      height: 100%;
    }

    &.options-modal {
      height: 25%;
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;

      .buttons {
        display: flex;
      }
    }

    .comments-container-mobile {
      display: flex;
      width: 100%;
      height: 0;
      background: rgba(0, 0, 0, 0.6);
      overflow: hidden;
      transition: all 0.2s;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 8;

      &.active {
        height: 100%;
      }

      .comments-holder {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 79px;
        width: 100%;
        background: #ffffff;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
        border-radius: 12px 12px 0 0;
        max-height: calc(100vh - 226px);
      }
    }

    .heading-post {
      .menu {
        width: 200px;
      }

      .type {
        display: none;
      }

      .owner-info-container {
        span {
          overflow: hidden;
          max-width: calc(100vw - 216px);
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .social-container {
      height: max-content;

      span {
        margin-right: 6px;

        &.txt {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .CommunityFeedPost {
    padding: 24px 10px;
  }
}
