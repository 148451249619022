@import '../../../../styles/variables.scss';

.Attachments {
  width: 100%;
  position: relative;

  .action-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0;

    input[type='file'] {
      // opacity is used to hide the file input instead of visibility: hidden or display: none, because assistive technology interprets the latter two styles to mean the file input isn't interactive.
      opacity: 0;
      position: absolute;
      top: 128px;
      width: 100%;
      // Increases the droppable area of the file input to give some margin of error
      height: 90px;
      z-index: 1;

      &:disabled {
        cursor: not-allowed;
      }
    }

    p {
      font-size: 14px;
      font-weight: 700;
    }

    label {
      display: flex;
      font-weight: 700;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      color: #000;
      border: 1px solid color(grey, border);
      padding: 8px 28px;
      border-radius: 20px;
      min-height: 24px;
      transition: background-color 150ms ease-in-out;

      span {
        margin: 0 5px 0 0;
      }
      &:hover {
        background: color(plum, darker);
        color: #fff;
        cursor: pointer;
      }

      &.disabled {
        background-color: color(grey, border);
        color: color(cream, lighter);

        cursor: not-allowed;
      }
    }
  }
  .files {
    display: flex;
    flex-flow: row wrap;
    padding: 10px;
    border-radius: 10px;
    border: 1px dashed color(grey, border);
    min-height: 48px; // A bit bigger than design to account for easier file dropzone experience (dragging files over the file input)
    gap: 12px;
    .file {
      max-height: 40px;
      display: flex;
      justify-content: center;
      background-color: #f5f5f5;
      border-radius: 10px;
      align-items: center;
      color: #fff;
      width: fit-content;
      padding: 10px 15px;
      color: #424243;
      animation: 200ms slideIn ease-out;
      z-index: 2;
      i {
        margin-right: 10px;
        color: #5e51ab;
        margin-bottom: 2px;
      }

      .file-name {
        margin: 0;
        margin-right: 10px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }

      span {
        cursor: pointer;
        color: #6f7072;

        &:hover {
          color: color(red, soft);
        }
      }
    }

    .center {
      margin: 0 auto;
      align-self: center;
    }
  }

  & > p:last-of-type {
    font-size: 12px;

    span {
      font-weight: 700;
    }
  }
}
