@import '../../../../styles/variables.scss';

.HubModalManageComponents {
  .content {
    padding: 48px;

    .ModalHeader {
      display: none;
    }

    h2 {
      text-align: center;
      margin: 0;
    }

    .components-wrap {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      row-gap: 16px;
      padding: 25px 0;
      margin-top: 22px;
      margin-bottom: 38px;
      border-bottom: 2px solid color(dark, borderLight);
      border-top: 2px solid color(dark, borderLight);

      .item {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 16px;
        color: black;
        column-gap: 16px;
        height: 19px;

        i {
          cursor: pointer;
        }

        .name {
          flex-grow: 1;
        }

        .mini-actions {
          display: flex;
          column-gap: 16px;
        }
      }
    }

    .actions {
      display: flex;
      column-gap: 16px;
      justify-content: flex-end;
    }
  }
}

.Modal-edit-content-block-name {
  .content {
    padding: 24px;

    .ModalHeader {
      display: none;
    }

    h2 {
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      row-gap: 32px;

      .actions {
        display: flex;
        column-gap: 16px;
        justify-content: flex-end;
      }
    }
  }
}

@media (max-width: 1070px) {
  .HubModalManageComponents {
    .content {
      padding: unset;

      h2 {
        margin-top: 24px;
      }

      .components-wrap {
        padding: 25px 16px;
        width: unset;
      }

      .actions {
        padding: 0 16px;
      }
    }
  }
}
