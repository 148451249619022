@import '../../../../styles/variables.scss';

.CategoriesFilterBar {
  overflow-x: auto;

  margin-bottom: 40px;
  width: calc(100% - 20px);

  .categories-container {
    display: flex;
    flex-direction: row;
  }

  .filter-item {
    height: 40px;
    color: #88949a;
    margin-right: 15px;
    margin-left: 15px;
    cursor: pointer;
    line-height: 40px;
    padding: 0 10px;
    white-space: nowrap;
    box-sizing: border-box;
    position: relative;

    &:hover {
      background: #f5f6f7 0% 0% no-repeat padding-box;
      border-radius: 7px;
    }

    &.selected {
      background: #f5f6f7 0% 0% no-repeat padding-box;
      color: #000000;
      border-radius: 7px;
    }
  }
}

@media (max-width: 1240px) {
  .CategoriesFilterBar {
    // Use view-width cause otherwie this div will cause the page to get bigger than it should.
    max-width: calc(90vw - 20px);
  }
}
