@import '../../styles/variables.scss';

.SortableTable {
  position: relative;

  .Loader {
    z-index: 2;
    position: absolute;
    top: 150px;
    left: 50%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin: -20px 0px 0px -20px;

    i {
      font-size: 40px;
    }
  }

  .empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    color: color(grey, almostdark);
  }

  .Checkbox {
    margin: 0 5px;
  }

  div.custom_select {
    margin-left: 10px;
    cursor: pointer;
    color: color(light, secondaryBg);
    transition: all 0.2s;

    &.selected {
      color: color(purple, dark);
    }
  }

  .table-row {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    height: max-content;
    min-width: 0;
    align-items: center;
    background-color: color(gray, lightester);
    color: color(light, fontColor);
    border-radius: 8px;
    margin: 10px 0;
    transition: all 0.2s;

    &.pointer {
      cursor: pointer;
    }

    &.table-header {
      background-color: color(gray, almostdark);
      font-weight: 500;
      color: white;
    }

    &.expandable {
      cursor: pointer;
      &:hover {
        background-color: #e0e4e8;
      }
    }

    a {
      text-decoration: none;

      &:visited {
        color: initial;
      }

      &:hover {
        color: color(purple, dark);
        cursor: pointer;
      }
    }

    .col {
      flex: 1;
      align-items: center;
      padding: 10px;
      min-width: 0;

      //remove 3 lines below if don't want to elipsis text for each line
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .table-header-content {
        display: flex;
        min-width: 0;
        padding: 5px;
        align-items: center;
        border-radius: 5px;
        height: 20px;
        padding: 10px 0;

        &:hover {
          cursor: pointer;
          background-color: rgba(255, 255, 255, 0.15);
        }

        .header-content-name {
          min-width: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 5px;
          padding-left: 5px;
          font-weight: 700;
        }

        .header-content-sort {
          .sort {
            flex: 1;
            display: flex;
            flex-direction: column;
            opacity: 0;

            &.active {
              opacity: 1;
            }

            i {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 21px;
              height: 12px;
              width: 21px;
              color: color(light, fontColor);
              &.active {
                color: #fff;
              }
            }
          }
        }
      }

      &.portrait,
      &.actions {
        max-width: 90px;
        text-align: center;

        &:hover {
          .ContextMenu {
            display: block;
          }
        }

        .table-header-content {
          color: transparent;
          pointer-events: none;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          &:hover {
            background-color: transparent;
          }
        }

        .ContextMenu {
          display: none;
          position: absolute;
          top: 62px;
          right: 5px;

          .ContextMenu-ul {
            border-radius: 20px;
            box-shadow: $box-shadow;

            li {
              &:hover {
                cursor: pointer;

                &.toggle-container {
                  i {
                    color: color(purple, dark);
                  }
                }
              }

              &.toggle-container {
                i {
                  font-size: 16px;
                }
              }
            }
          }

          i {
            margin-right: 10px;
          }
        }
      }

      &.progress {
        min-width: 250px;
      }

      .progress-bar-wrap {
        margin: 20px 0px 0px;
        width: 100%;

        .progress-bar-bg {
          position: relative;
          border: 2px solid #416aae;
          background-color: #012939;
          border-radius: 10px;
          height: 40px;
          display: flex;
          align-items: center;
          padding: 0px 5px;

          .progress-bar {
            background: transparent linear-gradient(88deg, #008599 0%, #734fd5 100%) 0% 0% no-repeat
              padding-box;
            width: 0%;
            height: 30px;
            border-radius: 5px;
          }

          .amt,
          .goal {
            position: absolute;
            color: #fff;
            font-weight: 700;
            font-size: 19px;
          }

          .amt {
            top: 9px;
            left: 12px;
          }

          .goal {
            top: 9px;
            right: 12px;
          }
        }
      }
    }
  }

  .PageComponentPaginator {
    margin-top: 30px;
  }

  .active-expended-row {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  .expended-container {
  }

  #hoverOver {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
