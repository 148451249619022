@import '../../styles/variables.scss';

@keyframes slideDown {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.dark {
  .CoverImage {
    .overlay {
      i {
        color: color(dark, fontColor);
      }
    }
  }
}

.CoverImage {
  flex: 1;
  position: relative;
  background-color: color(cream, dark);
  border-radius: $border-radius;
  overflow: hidden;

  &.transparent {
    background-color: transparent;
  }

  &:hover {
    cursor: pointer;

    .overlay {
      display: flex;

      .fa-camera {
        transform: translateY(0px);
      }
    }
  }

  span {
    display: block !important;
    width: 100%;
    height: 100%;

    img:not(.ReactCrop__image) {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .img-bg {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
  }

  .overlay {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;

    .ImageUpload {
      opacity: 0;
      display: flex;
      position: absolute;
      flex: 1 1;
      width: 100%;
      height: 100%;
    }

    &:hover {
      .fa-camera {
        display: block;
        cursor: pointer;
      }
    }

    .fa-camera {
      animation: 150ms slideDown ease-out;
      transform: translateY(-20px);
      color: color(dark, fontColor);
      display: none;
      position: absolute;
      top: 15px;
      left: 15px;
      font-size: 38px;
    }
  }
}
