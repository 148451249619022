@import '../../../../styles/variables.scss';

.BulkErrors {
  .error-messages-modal {
    flex: 1;
    padding: 40px;

    .title {
      font-size: 24px;
      margin-bottom: 20px;
      font-weight: 500;
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .error-messages {
      max-height: 80vh;
      overflow-y: auto;
    }

    .error-actions {
      margin-top: 20px;
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }
}
