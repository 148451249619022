@import '../../styles/variables.scss';

.DonorManagement-title-actions {
  display: flex;
  flex-direction: row;
  margin-left: auto;

  .export-button {
    margin-left: 15px;

    button {
      background-color: #ffffff;
      color: color(purple, dark);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

      &:hover {
        color: color(purple, base);
      }
    }
  }

  .filter-button {
    margin-right: 15px;
  }
}

.DonorManagement {
  flex: 1;
  display: flex;

  .confirm-delete {
    padding: 20px 40px;

    .title {
      font-size: 24px;
      font-weight: 700;
    }

    p {
      padding: 20px 0px;
      margin: 0px;
      max-width: 450px;
      line-height: 28px;
    }

    .delete-actions {
      display: flex;
      justify-content: flex-end;

      .Button {
        margin-left: 10px;

        &:last-child {
          button {
            background-color: color(volunteer);
          }
        }
      }
    }
  }

  .ContactDetailsModal {
    justify-content: flex-start;
    align-items: stretch;

    .content {
      position: relative;
      flex: 1;
      max-width: 100%;
      max-height: 100%;
      margin: 40px 30px;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;

      .modal-close-circle {
        right: 15px;
        top: 15px;
      }
    }

    .ModalHeader {
      display: none;
    }
  }

  .DonorManagement-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    .search {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .create {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }

      .TextField {
        max-width: 50%;

        i {
          background-color: color(light, secondaryBg);
          color: color(light, fontColor);
        }

        input {
          background-color: color(light, secondaryBg);
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      flex: 1;

      .SortableTable {
        .table-row {
          .col {
            &.user_last_name {
              font-weight: 500;
            }
          }
        }
      }

      .empty {
        flex: 1;
        padding: 30px 20px;
        text-align: center;
        color: color(grey);
        font-size: 16px;
      }

      .row-details {
        display: flex;
        margin: 12px 0px;
        transition: height 150ms ease-in-out;
        justify-content: space-between;

        .third {
          background-color: color(blue, lightest);
          flex: 1;
          border-radius: 12px;
          margin-right: 12px;
          overflow: hidden;
          position: relative;
          display: flex;
          flex-direction: column;

          &:last-child {
            margin-right: 0px;
          }

          &.metrics {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 20px;
          }

          .add-donation-action {
            flex: 1 1;
            padding: 10px;

            .Button {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: flex-end;
              padding-bottom: 10px;

              button {
                max-height: 42px;
                width: 98%;
              }
            }
          }

          .metric {
            display: flex;
            flex-direction: column;
            width: 50%;
            margin-bottom: 20px;

            &.long {
              width: 100%;

              .metric-inner {
                margin-left: 0px !important;
                justify-content: center;
                align-items: center;
                padding-top: 0px;

                div {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding-top: 0px;

                  i {
                    margin-right: 10px;
                    color: color(purple);
                    font-size: 21px;
                  }
                }

                span {
                  color: color(light, fontColor);
                  font-size: 14px;
                }
              }
            }

            &:nth-child(odd) {
              .metric-inner {
                margin-right: 0px;
                margin-left: 10px;
              }
            }

            .metric-inner {
              border-radius: 8px;
              background-color: color(light, secondaryBg);
              margin-right: 10px;
              display: flex;
              flex: 1;
              flex-direction: column;
            }

            span,
            div {
              display: flex;
              justify-content: center;
            }

            span {
              color: color(purple);
              font-size: 19px;
            }

            div {
              font-weight: 500;
              font-size: 14px;
              padding-top: 10px;
            }
          }

          .CoverImage {
            border-radius: 0px;
            min-height: 190px;
          }

          .Portrait {
            justify-content: center;
            display: flex;
          }

          .title {
            font-weight: 700;
            padding: 10px 0px;
            border-bottom: 1px solid color(grey, light);
            margin: 0px 20px;
            padding-top: 20px;
          }

          .email {
            padding: 10px 20px 0px;
            font-size: 14px;
            color: color(grey);
            line-height: 21px;
          }

          .phone {
            padding: 10px 20px 0px;
            font-size: 14px;
            color: color(grey);
            line-height: 21px;
          }

          .address,
          .dob {
            padding: 10px 20px;
            font-size: 14px;
            color: color(grey);
            line-height: 21px;
          }

          .inner-user-details {
            position: relative;
            padding-top: 70px;
            padding-bottom: 8px;
            display: flex;
            flex-direction: column;
            flex: 1;

            .Portrait {
              position: absolute;
              left: 50%;
              margin-left: -62px;
              top: -60px;
            }

            .user-actions {
              display: flex;
              flex: 1;
              justify-content: center;
              align-items: center;
              flex-direction: column;

              a {
                display: flex;
                justify-content: center;
                border: 0px;
                padding: 0px 28px;
                font-size: 16px;
                border-radius: $border-radius;
                justify-content: center;
                align-items: center;
                color: color(dark, fontColor);
                background-color: color(purple, dark);
                overflow: hidden;
                min-width: 80px;
                min-height: 40px;
                text-decoration: none;
                font-weight: 500;
                font-size: 16px;

                i {
                  padding-right: 8px;
                }

                &:hover {
                  background-color: color(purple);
                }

                &.faded {
                  background-color: color(blue, lightest);
                  border: 3px solid color(grey, light);
                  color: color(grey);

                  &:hover {
                    color: color(purple);
                    border: 3px solid color(purple);
                  }
                }
              }

              .sub-actions {
                display: flex;
                padding-bottom: 20px;

                .Button {
                  &:first-child {
                    margin-right: 10px;
                  }
                }

                a {
                  margin-left: 10px;
                }
              }
            }

            .user-name,
            .user-handle {
              display: flex;
              justify-content: center;
            }

            .user-handle {
              font-size: 16px;
              color: color(grey);
              padding-top: 4px;
            }

            .user-name {
              font-size: 21px;
              padding-top: 10px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .add-donor {
    padding: 20px;
    width: 640px;
    overflow: auto;
    position: relative;

    .summary {
      position: fixed;
      top: 0;
      right: -295px;
      min-height: 350px;
      min-width: 250px;
      display: flex;
      flex-direction: column;
      background: color(light, primaryBg);
      border-radius: $border-radius;

      .summary-info {
        padding: 25px;
        padding-bottom: 0;
        .title {
          font-size: 24px;
          display: flex;
          font-weight: 500;
          justify-content: center;
          padding-bottom: 10px;
          border-bottom: 1px solid color(grey, light);
        }
        .donation {
          display: flex;
          flex: 1;
          justify-content: space-between;
          font-weight: 400;
          font-size: 18px;
          margin: 20px 0;
        }
      }

      .Button {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          height: 42px;
        }
      }

      .total {
        display: flex;
        flex: 1;
        justify-content: space-between;
        font-weight: 500;
        font-size: 18px;
        max-height: 30px;
        align-items: center;
        padding: 10px 25px;
        background: color(blue, dark);
        color: color(dark, fontColor);
      }
    }

    .title-row {
      font-size: 22px;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 15px;
    }

    .subheader {
      padding: 5px;
      border-bottom: 1px solid #f5f6f7;
      color: color(purple, dark);
      font-size: 18px;
      margin-bottom: 15px;
    }

    form {
      .details-row {
        margin-bottom: 20px;
        display: flex;

        .DatePicker {
          width: 100%;
        }
        .Dropdown {
          flex: 1;
        }

        .Checkbox {
          label {
            color: color(grey);
            font-weight: 500;
            font-size: 14px;
          }
        }

        .details-col {
          flex: 1;
          margin-right: 20px;

          .Checkbox {
            label {
              justify-content: flex-start;
            }
          }

          &:last-child {
            margin: 0px;
          }
        }
      }

      .donation-amount-wrap {
        display: flex;
        margin-bottom: 30px;
        flex-wrap: wrap;

        .donation-amount,
        .tip {
          border-radius: 8px;
          font-weight: 500;
          color: color(grey);
          border: 2px solid color(grey, light);
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 75px;
          margin-right: 10px;
          height: 40px;
          margin-bottom: 10px;

          &.active {
            border: 2px solid color(purple, dark);
            color: color(light, fontColor);
            box-shadow: $box-shadow;
          }

          &:hover {
            cursor: pointer;
          }

          &.other {
            border: 1px solid color(grey, light);

            &.active {
              border: 1px solid color(purple, dark);

              .TextField .input-wrap {
                border: 1px solid color(purple, dark);
              }
            }
          }
        }
      }

      .donation-dedicate-wrap {
        display: flex;
        margin-bottom: 30px;
        flex-wrap: wrap;

        .donation-amount {
          border-radius: 8px;
          font-weight: 500;
          color: color(grey);
          border: 2px solid color(grey, light);
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 75px;
          flex: 1;
          margin-right: 10px;
          height: 40px;
          margin-bottom: 10px;

          &.active {
            border: 2px solid color(purple, dark);
            color: color(light, fontColor);
            box-shadow: $box-shadow;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .form-2-1 {
        display: flex;
        flex-direction: row;
        div {
          flex: 1;
        }
        div:first-of-type {
          flex: 2;
          margin-right: 8px;
        }
      }
      .form-1-1 {
        display: flex;

        .TextField {
          &:first-child {
            padding-right: 10px;
          }

          &:last-child {
            padding-left: 10px;
          }
        }

        div {
          margin: 5px 0;
        }

        .Dropdown {
          flex: 1;
        }
      }

      .actions {
        padding-top: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .content {
    max-height: 90vh;
    min-width: 600px;
  }
}

@media (max-width: 1500px) {
  .DonorManagement {
    .DonorManagement-list {
      .list {
        .list-inner {
          .row {
            &.active {
              .row-details {
                height: auto;
                flex-wrap: wrap;
                .third {
                  min-height: 400px;
                  min-width: 320px;

                  &:nth-of-type(2) {
                    margin-right: 0;
                  }

                  &:last-of-type {
                    margin-top: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
