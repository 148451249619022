@import '../../../styles/variables.scss';

.SearchControls {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  z-index: 6;
  box-shadow: 0 1px 0 color(light, shadow);
  background-color: color(light, secondaryBg);
  transition: height 250ms ease-in-out;
  .search-filter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 0 0 10px 0;

    .CategoriesFilterBar {
      overflow-x: auto;
      margin-bottom: 0;
      width: calc(100% - 20px);

      .categories-container {
        display: flex;
        flex-direction: row;
      }

      .filter-item {
        height: 40px;
        color: #88949a;
        margin-right: 15px;
        margin-left: 15px;
        cursor: pointer;
        line-height: 40px;
        padding: 0 10px;
        white-space: nowrap;
        box-sizing: border-box;
        position: relative;

        &:hover {
          background: #f5f6f7 0% 0% no-repeat padding-box;
          border-radius: 7px;
        }

        &.selected {
          background: #f5f6f7 0% 0% no-repeat padding-box;
          color: #000000;
          border-radius: 7px;
        }
      }
    }

    @media (max-width: 1240px) {
      .CategoriesFilterBar {
        // Use view-width cause otherwise this div will cause the page to get bigger than it should.
        max-width: calc(90vw - 20px);
      }
    }
  }

  .search-controls-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
    grid-gap: 15px;

    .SearchControlActions {
      .icons-container {
        position: relative;
        grid-gap: 15px;

        .filter-count {
          display: flex;
          justify-content: center;
          align-items: center;
          background: black;
          color: #fff;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          position: absolute;
          left: 30px;
          top: -5px;
          padding: 4px;
          font-size: 10px;
          font-weight: 800;
        }
      }
    }
    .SearchControlActions > .icons-container,
    .search-inputs-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .icon-container {
        cursor: pointer;
        display: grid;
        place-items: center;
        height: 44px;
        width: 44px;
        transition: background-color 200ms ease-in-out;
        border-radius: 22px;
        background: #f5f5f5;
        box-shadow: $box-shadow;

        &:hover {
          background-color: color(purple, dark);
          color: #fff;
        }

        &.search-icon {
          display: none;
        }
      }
    }

    .search-inputs-container {
      flex-grow: 1;
      grid-gap: 15px;
    }

    .search-controls-input {
      height: 44px;
      border-radius: 25px;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      transition: all 0.2s;
      background-color: #f5f5f5;

      &:last-of-type {
        margin-right: 0;
      }

      .location-icon-container {
        cursor: pointer;
        margin: 0 12px 0 22px;
      }

      .custom-placeholder {
        font-size: 14px;
        font-weight: normal;
        color: color(grey, lighter);

        &.current-location-active {
          color: color(grey, dark);
        }
      }

      &.keyword-search {
        min-width: 500px;
        flex-grow: 3;

        margin: 0;
      }

      &.type-select {
        max-width: 500px;
        flex-grow: 1;
      }

      .TextField {
        .input-wrap {
          border: none;
          position: relative;

          input {
            padding-left: 35px;
            background: transparent;
          }

          i {
            top: 1px;
            font-size: 16px;
            position: absolute;
            left: 5px;
            color: #b0b0b0;
          }
        }
      }
    }

    .filter-dropdown {
      min-width: 205px;
      position: relative;
      flex: 2;

      .input-wrap {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 16px;
        color: #012939;
        padding: 0 23px 0 16px;
        border: none;
        background: #f5f5f5;
        border-radius: 22px;

        .menu-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.3s ease-in-out;

          &.show {
            transform: rotate(180deg);
          }
        }

        .label {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .img {
            width: 12px;
            height: 12px;
            margin-right: 14px;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }

      label {
        display: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .additional-options-container {
      border-top: 1px solid color(grey, lightest);
      padding: 0 22px;

      .additional-option {
        margin: 25px 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: color(purple, grey);
        cursor: pointer;

        i {
          margin-right: 12px;
          color: color(purple, dark);
        }
      }
    }
  }
}

@media (max-width: 1251px) {
  .SearchControls {
    .address-search {
      z-index: 99;
    }
    .keyword-search {
      z-index: 99;
    }
    .type-select {
      z-index: 9;
    }
    .search-controls-row {
      align-items: center;
      .search-controls-input {
        margin: 10px 8px;

        &.type-select {
          // was w-sm
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 1128px) {
  .SearchControls {
    .address-search {
      z-index: 9;
    }
    .keyword-search {
      z-index: 99;
    }
    .type-select {
      z-index: 999;
    }
    .search-controls-row {
      padding: 0;
      height: 80px;
      justify-content: space-between;

      .type-name {
        display: grid;
        place-items: center;
        height: 100%;
        font-weight: 700;
        font-size: 26px;
        line-height: 25px;
      }

      .buttons-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-top: 18px;
        margin-bottom: 26px;

        button {
          transition: all 0.2s;
        }

        .search {
          margin-right: 26px;
        }

        .secondary-custom {
          margin-right: 20px;
          background-color: color(grey, pale);
          color: color(grey, almostdark);
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
          transition: all 0.2s;

          &:hover {
            background-color: color(grey, base3);
          }
        }
      }

      .search-inputs-container {
        .mobile-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 60px;
          width: 100%;
          color: color(grey, grey);
          border-bottom: 1px solid color(grey, border);
          margin-bottom: 16px;

          span {
            font-weight: 900;
            font-size: 17px;
            line-height: 20px;
            margin-left: 26px;
          }

          i {
            cursor: pointer;
            margin-right: 26px;

            &:hover {
              color: color(purple, dark);
            }
          }
        }

        .search-controls-input {
          max-height: 44px;
          height: 44px;
          margin: 8px 0;
          width: 363px;

          &.keyword-search {
            min-width: 0;
          }
        }

        &.active {
          top: 0;
          height: 344px;
        }
      }

      .search-controls-input {
        &.icon-container {
          margin: 0 4px;

          &:last-of-type {
            margin-right: 0;
          }
          &.search-icon {
            display: grid;
          }
        }
      }
    }
  }
}

@media (max-width: 740px) {
  .SearchControls {
    .search-controls-row {
      .search-inputs-container {
        .search-controls-input.keyword-search {
          min-width: unset;
          width: unset;
          flex: 1;
        }
        .filter-dropdown {
          min-width: unset;
          width: unset;
          flex: 1;
        }
      }
    }
  }
}
