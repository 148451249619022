@import '../../../styles/variables.scss';

.DonateForm {
  width: 100%;
  height: 100%;
  overflow: auto;

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .DonateForm-actions {
      border-top: 1px solid #e8f1f2;
      background-color: #fff;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      align-items: center;
      height: 80px;

      .donate-warning {
        color: color(yellow, text);
      }

      .Button {
        margin: 0 10px;

        button {
          min-width: 200px;
          box-shadow: $box-shadow;
        }

        &:first-child {
          margin-right: 40px;
        }
      }
    }

    .DonateForm-inner {
      padding: 30px 60px;
      flex: 1;
      overflow: auto;

      .donate-warning {
        display: flex;
        column-gap: 16px;
        align-items: center;
        justify-content: center;
        color: color(yellow, text);

        p {
          margin: 0;
        }
      }

      .payment-form {
        height: unset;

        .hosted-field {
          font-family: 'Lato', sans-serif;
          padding: 0 5px;
          height: 42px;
          display: flex;
          flex: 1 1;
          font-size: 16px;
          text-indent: 8px;
          border: 1px solid #898989;
          border-radius: 7px;
          overflow: hidden;
          margin-bottom: 20px;
        }
        .local-field {
          font-weight: 400;
        }
        .hosted-fields--label,
        .local-label {
          color: #52646c;
          font-weight: 700;
          font-size: 14px;
          display: block;
          margin-bottom: 6px;
        }
        .button-container {
          display: block;
          text-align: center;
        }
        .button {
          cursor: pointer;
          font-weight: 500;
          line-height: inherit;
          position: relative;
          text-decoration: none;
          text-align: center;
          border-style: solid;
          border-width: 1px;
          border-radius: 3px;
          -webkit-appearance: none;
          -moz-appearance: none;
          display: inline-block;
        }
        .button--small {
          padding: 10px 20px;
          font-size: 0.875rem;
        }
        .button--green {
          outline: 0;
          background-color: #64d18a;
          border-color: #64d18a;
          color: #fff;
          transition: all 0.2s ease;
        }
        .button--green:hover {
          background-color: #8bdda8;
          color: #fff;
        }
        .braintree-hosted-fields-invalid {
          border: 1px solid #ed574a;
        }
        #cardForm {
          max-width: 50.75em;
          margin: 0 auto;
          padding: 1.875em;
        }
      }

      .warning-message {
        color: #ee7100;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        font-size: 13px;

        &.non-quil {
          i {
            margin-right: 15px;
          }
        }

        i {
          align-self: center;
        }
      }
    }
  }

  &.overlay {
    .DonateForm-inner {
      form {
        height: 100%;
        overflow: hidden;
        padding: 0px;
      }
    }
  }

  .funds {
    padding-top: 30px;

    &.error {
      .Dropdown {
        .input-wrap {
          background-color: color(red, light);
          border: 1px solid color(red, dark);

          i {
            color: color(red, dark);
          }

          select {
            color: color(red, dark);
          }
        }
      }
    }
  }

  .sub-title {
    font-size: 24px;
    font-weight: 700;

    &.dedicate-title {
      padding: 30px 0px;
    }

    &.anonymous-title {
      padding-top: 30px;
      padding-bottom: 10px;
    }
  }

  .row-overlay {
    display: flex;
    flex: 1;
    border-top: 1px solid #e8f1f2;
    border-bottom: 1px solid #e8f1f2;
    padding: 20px 10px;

    .selected-alt-method-logo {
      width: 45px;
      height: auto;
      margin-right: 10px;
    }

    &:hover {
      cursor: pointer;
      background-color: #fbfbfb;
    }

    i {
      &.fa-angle-right {
        font-size: 24px;
        color: #b1b6bb;
      }
    }

    div {
      flex: 1;
      display: flex;
      align-items: center;

      span {
        font-weight: 700;

        &.optional {
          font-size: 14px;
          font-weight: 300;
          margin-left: 8px;
        }
      }

      i {
        margin-right: 8px;
      }

      .payment-label {
        margin-left: auto;
        margin-right: 15px;
        color: color(grey, font);
      }
    }

    .cc {
      font-weight: 400;
      display: flex;
      color: #52646c;
      font-size: 14px;
      padding: 0px 10px 0px 0px;
      justify-content: flex-end;
      flex: 1;
    }

    .fa-plus {
      height: 17px;
    }
  }

  .overlay {
    position: absolute;
    top: 0px;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: auto;
    transition: left 150ms ease-in-out;
    z-index: 3;

    &.review {
      .review-title,
      .review-line,
      .review-msg,
      .review-column {
        margin: 0px 40px;
      }

      .review-title {
        border-bottom: 1px solid #f5f6f7;
        font-weight: 700;
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
        margin-top: 35px;
        display: flex;

        &.first-title {
          margin-top: 0px;
        }

        .edit-link {
          margin-left: auto;
          cursor: pointer;
          color: color(plum, dark);
          font-size: 12px;

          &:hover {
            color: color(purple);
          }
        }
      }

      .review-line {
        color: #88949a;
        font-size: 14px;
        line-height: 21px;

        &.summary-message {
          margin-top: 20px;
        }
      }

      .review-column {
        color: #88949a;
        font-size: 14px;
        line-height: 21px;
        display: flex;

        .value {
          margin-left: auto;
        }

        &.total-title {
          border-bottom: 1px solid #f5f6f7;
          font-size: 22px;
          height: 30px;
          line-height: 30px;
          margin-top: 20px;
        }

        .credit-card-brand-icon {
          height: 45px;
          font-size: 35px;

          &:first-child {
            font-size: 28px;
          }

          &.fa-cc-visa {
            color: #214dba;
          }

          &.fa-cc-mastercard {
            color: #343a47;
          }

          &.fa-cc-amex {
            color: #6ca0da;
          }

          &.fa-cc-discover {
            color: #ef7e23;
          }

          &.fa-cc-diners-club {
            color: #01084c;
          }

          &.fa-cc-jcb {
            color: #0867ad;
          }

          &.fa-credit-card {
            color: color(plum, dark);
          }

          &.fas {
            color: color(plum, dark);
          }
        }
      }

      .review-msg {
        color: #88949a;
        font-size: 14px;
        margin: 15px 40px 0px;
      }
    }

    &.active {
      left: 0px;
    }

    &.new-cc {
      label {
        display: block;
        color: #52646c;
        font-size: 14px;
        font-weight: 700;
      }

      .TextField {
        padding: 0px 40px;
        margin-bottom: 20px;
      }

      .card-wrap {
        padding: 0px 40px;

        label {
          margin-bottom: 4px;
        }

        .card-inner {
          height: 44px;
          border: 1px solid color(grey, light);
          border-radius: 7px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 10px;

          .StripeElement {
            flex: 1;
          }
        }
      }
    }

    &.payment {
      .pm-wrap {
        .sub-title {
          padding-bottom: 30px;
        }

        .pm-inner {
          padding: 0px 40px;

          .alt-methods-container {
            img {
              max-width: 45px;
              height: auto;
            }
            svg {
              max-width: 45px;
              height: auto;
            }
          }

          .pm {
            display: flex;
            flex: 1;
            align-items: center;
            border-top: 1px solid #e8f1f2;
            padding: 0px 10px;
            height: 60px;

            i {
              &:first-child {
                font-size: 40px;
              }

              &.fa-cc-visa {
                color: #214dba;
              }

              &.fa-cc-mastercard {
                color: #343a47;
              }

              &.fa-cc-amex {
                color: #6ca0da;
              }

              &.fa-cc-discover {
                color: #ef7e23;
              }

              &.fa-cc-diners-club {
                color: #01084c;
              }

              &.fa-cc-jcb {
                color: #0867ad;
              }

              &.fa-credit-card {
                color: color(plum, dark);
              }

              &.fas {
                color: color(plum, dark);
              }
            }

            &:hover {
              cursor: pointer;
              background-color: #fbfbfb;
            }

            span {
              flex: 1;
              padding-left: 10px;
              font-size: 14px;
            }

            &.new {
              span {
                font-weight: 700;
                padding-left: 0px;
                font-size: 16px;
              }

              i {
                width: 16px;
              }
            }

            &:last-child {
              border-bottom: 1px solid #e8f1f2;
            }
          }
        }
      }
    }

    .overlay-inner {
      height: 100%;
      display: flex;
      flex-direction: column;

      .input-wrapper {
        flex: 1;
        overflow: auto;
        padding-bottom: 30px;

        .sub-title {
          padding: 30px 40px;
        }

        .options,
        .form-inputs {
          padding: 0px 40px 20px;
        }

        .form-inputs {
          &:last-child {
            padding-bottom: 20px;
          }
        }
      }

      .sub-title {
        padding-bottom: 30px;
      }

      .overlay-actions {
        border-top: 1px solid #e8f1f2;
        background-color: #fff;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: center;
        height: 80px;

        .Button.StripeElement {
          min-width: 200px;
          border-radius: 20px;
          overflow: hidden;

          iframe {
            // Fix for Apple pay button border radius not being changed by changing the StripeElement border radius.
            // Solution is to force the iframe to have the border-radius we want.
            // Solution found here: https://wordpress.org/support/topic/change-styling-shape-of-apple-pay-buttons/
            border-radius: 20px;
          }
        }

        .Button {
          margin: 0px 10px;

          button {
            min-width: 200px;
            box-shadow: $box-shadow;
          }

          &:last-child {
            margin-left: 40px;

            button {
              background-color: #fff;
              color: #52646c;
              border: 2px solid #52646c;

              &:hover {
                border: 2px solid color(plum, dark);
                color: color(plum, dark);
              }
            }
          }

          &:first-child {
            margin-right: 40px;
          }
        }
      }
    }
  }

  .embed-complete {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: color(light, secondaryBg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 7;

    p {
      &:first-child {
        font-size: 24px;
        font-weight: 700;
      }

      &:last-child {
        font-size: 18px;
      }

      span {
        font-weight: 700;
      }

      a {
        color: color(blue, light);
        text-decoration: none;
        font-weight: 700;
      }
    }
  }

  .overlay-back {
    position: absolute;
    top: -50px;
    left: 15px;
    font-size: 32px;
    color: #829096;

    &:hover {
      cursor: pointer;
      color: color(plum, dark);
    }

    &.back-hidden {
      display: none;
    }
  }

  .label {
    color: #52646c;
    font-size: 14px;
    padding: 30px 0px 10px 0px;
    font-weight: 700;
  }

  .tip-msg {
    color: #52646c;
    font-size: 14px;
    line-height: 22px;
    padding: 20px 0px 30px;
  }

  .form-inputs {
    padding: 20px 0px 0px;
    display: flex;
    flex: 1;
    &.details-last {
      margin-bottom: 20px;
    }

    &.long-message {
      .TextField {
        label {
          span {
            color: color(red, dark);
          }
        }
      }
    }

    .Checkbox {
      label {
        font-size: 14px;
        color: color(grey);
      }
    }

    .Dropdown {
      flex: 1;
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }

      label {
        span {
          color: #52646c;
          font-weight: 700;
          font-size: 14px;
        }
      }

      .input-wrap {
        select {
          width: 100%;
        }
      }
    }

    .TextField {
      flex: 1;
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }

      label {
        span {
          color: #52646c;
          font-weight: 700;
          font-size: 14px;

          .required {
            color: color(red, dark);
          }
        }
      }
    }

    .Dropdown {
      label {
        span {
          color: #52646c;
          font-weight: 700;
          font-size: 14px;

          .required {
            color: color(red, dark);
          }
        }
      }
    }
  }

  .options {
    display: flex;
    flex: 1;

    &.single-line {
      .option {
        margin-right: 10px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &.no-tip {
      padding-bottom: 30px;
    }

    &.with-descriptions {
      flex-wrap: wrap;

      .option {
        min-width: 50%;
        max-width: 50%;
        margin-right: 0px;
        margin-bottom: 10px;

        &:nth-child(even) {
          .option-inner {
            margin-right: 0px;
            margin-left: 5px;
          }
        }

        .TextField {
          min-height: 68px;

          .input-wrap {
            min-height: 68px;

            input {
              min-height: 68px;
            }
          }
        }

        .option-inner {
          margin-right: 5px;
          height: 50px;

          span {
            &:last-child {
              margin-left: 10px;
              flex: 1;
              font-weight: 400;
              font-size: 14px;
            }
          }
        }
      }
    }

    .option {
      flex: 1;

      &.other {
        display: flex;
        max-width: 100px;

        &.active {
          .option-inner {
            background-color: color(plum, dark);
            border: 0px !important;
            color: #fff !important;
          }

          .TextField {
            .input-wrap {
              border: 0px;

              input {
                border: 1px solid color(plum, dark);
                width: 65px;
                border-radius: 0px;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
              }
            }
          }
        }

        &:hover {
          .option-inner {
            border: 0px solid color(light, shadow);
            color: #fff;
          }
        }

        .option-inner {
          max-width: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          background-color: color(light, shadow);
          color: #fff;
        }

        .TextField {
          min-width: 20px;

          .input-wrap {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
          }
        }
      }

      &.active {
        .option-inner {
          border: 2px solid color(plum, dark);
          color: color(light, fontColor);
          box-shadow: $box-shadow;
        }
      }

      .option-inner {
        display: flex;
        border: 2px solid color(light, shadow);
        border-radius: 5px;
        padding: 10px;
        flex: 1;
        justify-content: center;
        align-items: center;
        color: #88949a;
        font-weight: 700;
      }

      &:hover {
        cursor: pointer;

        .option-inner {
          color: #757f84;
          border: 2px solid #757f84;
        }

        &.active {
          .option-inner {
            border: 2px solid color(plum, dark);
            color: color(light, fontColor);
            box-shadow: $box-shadow;
          }
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .DonateForm {
    .overlay {
      .overlay-inner {
        .input-wrapper {
          padding-bottom: 0px;
          max-height: calc(100% - (80px + 71px)); // 80px is from actions, 71px is from footer.
        }
      }
    }

    .options {
      flex-direction: column;

      .option {
        &.other {
          max-width: 98%;
        }
      }
    }
  }
}
