@import '../../../../styles/variables.scss';

.HubManagementSettings {
  padding: 60px;

  h2 {
    font-weight: 700;
    font-size: 39px;
    color: color(grey, dark);
    margin: 0 0 40px 0;
  }

  .section-title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    background: color(cream, grey2);
    color: color(grey, dark);
    margin-bottom: 38px;

    h3 {
      margin: unset;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
    }
  }

  .edit-content-container {
    border-bottom: 1px solid color(cream, border);
    padding-bottom: 40px;

    &.no-border {
      border: unset;
    }

    .section-subtitle {
      color: color(grey, dark);
      display: flex;
      flex-direction: column;
      padding-left: 54px;
      margin-bottom: 23px;
      margin-top: 23px;

      h4 {
        margin: 0 0 6px 0;
        font-weight: 600;
        font-size: 18px;
      }

      span {
        font-weight: 400;
        font-size: 14px;
      }
    }

    .notification-list {
      .notification-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #616161;
        padding: 10px 0 10px 0;
        width: 50%;
        .Button {
          margin-left: 15px;
        }
      }
    }

    .notification-email-input {
      display: flex;
      flex: row;
      align-items: center;
      justify-content: flex-start;

      .Button {
        margin-top: 20px;
        margin-left: 15px;
      }
    }

    .TextField {
      max-width: 500px;

      label {
        span {
          color: color(grey, dark);
        }
      }

      .input-wrap {
        border: 1px solid color(grey, border);
        box-sizing: border-box;
        border-radius: 10px;

        input {
          height: 44px;

          &::placeholder {
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.280123px;
            color: color(grey, border);
          }
        }
      }
    }

    .section-form {
      padding-left: 104px;

      form {
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        .web-address-container {
          label {
            font-size: 14px;
            font-weight: 700;
            color: #3a3a3a;
          }

          .web-address-line {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            .web-address {
              margin-right: 5px;
            }
          }
        }

        .TextArea {
          margin-top: 24px;
          max-width: 500px;

          label {
            .placeholder {
              color: color(grey, dark);
              font-size: 14px;
              opacity: 1;
              margin: -4px 0 0 0;
            }
          }

          textarea {
            border: 1px solid color(grey, border);
            box-sizing: border-box;
            border-radius: 10px;
            height: 172px;

            &::placeholder {
              font-weight: 400;
              font-size: 14px;
              letter-spacing: 0.280123px;
              color: color(grey, border);
            }
          }

          .remaining-characters {
            color: color(grey, dark);
          }
        }

        .Checkbox {
          width: max-content;

          label {
            .fa-square {
              opacity: 1;

              &::before {
                color: color(plum, lightest);
              }
            }

            .fa-check-square {
              color: color(purple, base2);
            }
          }
        }

        .TextField {
          max-width: 500px;

          label {
            span {
              color: color(grey, dark);
            }
          }

          .input-wrap {
            border: 1px solid color(grey, border);
            box-sizing: border-box;
            border-radius: 10px;

            input {
              height: 44px;

              &::placeholder {
                font-weight: 400;
                font-size: 14px;
                letter-spacing: 0.280123px;
                color: color(grey, border);
              }
            }
          }
        }

        .Dropdown {
          label {
            span {
              color: color(grey, dark);
            }
          }
        }

        .tab-configuration {
          display: flex;
          flex-direction: row;
          .TextField {
            max-width: 200px;
          }
          .Dropdown {
            width: 200px;
            margin-left: 50px;
          }
          button {
            margin-left: 50px;
          }
        }
      }

      .fee-option {
        display: flex;
        margin-bottom: 20px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0px;
        }

        .fee-radio {
          margin-right: 10px;

          .fas {
            &.fa-circle {
              color: color(purple, darkest);
            }
          }

          .far {
            &.fa-cicle {
              color: color(grey);
            }
          }
        }

        .fee-content {
          .fee-title {
            font-weight: 600;
            font-size: 16px;
          }

          .fee-description {
            padding-top: 5px;
            font-size: 14px;
            color: color(grey);
          }
        }
      }
    }
  }

  .logo-container {
    border: 2px solid color(grey, pale);
    box-sizing: border-box;
    border-radius: 106.145px;
    width: max-content;
  }

  .color-container {
    display: flex;
    column-gap: 30px;

    label {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 4px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      position: relative;

      .color {
        width: 184px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: color(light, secondaryBg);
        border: 1px solid color(grey, border);
        box-sizing: border-box;
        border-radius: 8px;
        cursor: pointer;

        .inner {
          width: 141px;
          height: 28px;
          border: 1px solid color(grey, border);
          box-sizing: border-box;
          border-radius: 4px;
        }
      }
    }

    .custom-picker-container {
      margin-top: 15px;
      width: 184px !important;
      position: absolute;
      top: 70px;
    }
  }

  .cover-image-container {
    display: flex;
    min-height: 250px;

    &.settings {
      min-height: 160px;
      .CoverImage {
        height: 250px;
        width: 560px;
        background-color: color(purple, base2);
      }
    }
    .CoverImage {
      max-height: 250px;
      max-width: 560px;
      background-color: color(purple, base2);
    }
  }
}
