@import '../../../../styles/variables.scss';

.SocialImpactBenefitsForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 500px;
  margin-bottom: 20px;

  .description {
    color: color(grey, base);
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 15px;
  }

  .benefit-container {
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;

    .toggle-row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      flex: 1;

      .label {
        font-weight: 700;
        font-size: 14px;
        color: color(grey, dark);
        margin-right: auto;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .input-row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 20px;
      max-width: 380px;

      .TextField {
        max-width: 220px;
        min-width: 220px;
      }

      span {
        color: color(grey, dark);
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 930px) {
  .SocialImpactBenefitsForm {
    align-self: center;
  }
}
