@import '../../../../styles/variables.scss';
.SummaryPage {
  .summary-data {
    margin: 15px 0;
    font-size: 14px;
  }

  .total-wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .total-label {
      font-size: 20px;
      color: #5a5b5d;
    }
  }

  .payment-pricing-wrapper {
    border-top: 1px solid #e9e9e8;
    padding: 15px 0;
    .pricing-subtitle {
      font-size: 14px;
      font-weight: 400;
      color: #5a5b5d;
      margin-bottom: 15px;
    }

    .pricing-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;
      color: #5a5b5d;
      margin-bottom: 5px;
    }

    .pricing-list.total {
      font-weight: 900;
    }
  }

  .summary-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    div[class*='selectedCauses'] {
      display: flex;
      border: 0px;
      padding: 8px 28px;
      font-size: 16px;
      border-radius: $border-radius-minimal;
      justify-content: center;
      align-items: center;
      color: color(dark, fontColor);
      background-color: color(purple, dark);
      overflow: hidden;
      min-height: 28px;
      margin-right: 10px;
    }

    .profileImageContainer,
    .coverImageContainer {
      margin: 10px 20px;
      img {
        &.profile {
          height: 100px;
          width: 100px;
          border-radius: 50%;
          box-shadow: 0 4px 5px 0px rgba(0, 0, 0, 0.35);
        }

        &.cover-image {
          height: 150px;
          width: 275px;
          border-radius: $border-radius-minimal;
        }
      }
    }
  }

  .additional-info {
    border-top: 1px solid #e9e9e8;
    padding: 20px 0;
    font-weight: 800;
  }
}

@media (max-width: 680px) {
  .SummaryPage {
    .summary-row {
      .coverImageContainer {
        img {
          &.profile,
          &.cover-image {
            width: 100%;
          }
        }
      }
    }
  }
}
