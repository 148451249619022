.SelectOptionContainer {
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  align-items: center;
  height: 30px;

  .image-container {
    max-width: 30px;
    max-height: 30px;

    &.has-icon {
      min-width: 30px;
    }
  }
}
