.ErrorBoundry {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-content: center;

  .error-inner {
    display: flex;
    align-items: center;
  }
}
