@import '../../styles/variables.scss';

:root {
  --rsbs-handle-bg: #b0b0b0;
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-antigap-scale-y: 0;
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0);
  --rsbs-bg: #f5f5f5;
  --rsbs-backdrop-opacity: 1;
  --rsbs-content-opacity: 1;
  --rsbs-overlay-h: 0px;
  --rsbs-overlay-rounded: 10px;
  --rsbs-overlay-translate-y: 0px;
}

[data-rsbs-scroll] {
  .bottom-sheet-container {
    .bottom-sheet-unselected {
      text-align: center;
      margin-top: 15px;
      font-weight: 700;

      .skeleton-loader {
        display: flex;
        flex: 1;
        height: 12px;
        border-radius: 10px;
        background: #ddd;
        max-width: 120px;
        margin: 0 auto;

        &:after {
          content: '';
          background-color: #333;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-name: loader-animate;
          animation-timing-function: linear;
          background: -webkit-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.6) 30%,
            rgba(255, 255, 255, 0) 81%
          );
          background: -o-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.6) 30%,
            rgba(255, 255, 255, 0) 81%
          );
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.6) 30%,
            rgba(255, 255, 255, 0) 81%
          );
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1 );
        }
      }
    }
    padding: 0 10px;
    max-height: 80vh;
    .SearchResultPreview {
      max-height: 60vh;
      position: relative;

      .Portrait {
        position: absolute;
        top: 25px;
        left: 25px;
        top: 95px;
        left: 15px;
        .inner {
          width: 60px;
          height: 60px;
        }
      }

      .info {
        .title {
          font-size: 16px;
        }
      }
    }
    .simple-preview {
      box-sizing: border-box;
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      padding: 0 25px;

      .Portrait {
        margin-right: 10px;
      }

      p {
        flex: 1;
        font-size: 16px;
        font-weight: 800;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      i {
        justify-self: flex-end;
      }
    }
  }
}

.KambeoSearch {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-height: calc(100vh - 80px);
  width: 100%;
  position: relative;
  .search-top {
    z-index: 3;
  }
  .search-views-container {
    max-height: 100%;
    display: flex;
    flex: 1 1;
    position: relative;
    box-sizing: border-box;
    transition: all 250ms ease-in-out;

    &.locked {
      max-height: 100%;
      overflow: hidden;
    }
    .search-grid-view {
      padding-top: 25px;
      position: relative;
      display: grid;
      row-gap: 36px;
      column-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(321px, 1fr));
      overflow: auto;
      transition: all 250ms ease-in-out;
      position: relative;
      &::-webkit-scrollbar {
        display: none;
      }
      &.column {
        width: 386px;
        min-width: 386px;
        max-width: 386px;
        padding: 40px 10px;
        row-gap: 16px;

        &.search-result-open {
          padding: 10px;
        }
      }
      &.full {
        width: 100%;
        padding: 40px 80px;

        .results-amount {
          position: absolute;
          top: 10px;
          left: 80px;
        }
      }
      &.empty {
        grid-template-columns: unset;
        justify-content: center;
        align-items: center;
        align-content: center;
      }
      .results-amount {
        font-weight: 800;
        position: absolute;
        top: 10px;
        left: 10px;
        transition: all 200ms ease-in;
      }
      .SearchResultPreview {
        background: #fff;
        height: 100%;
      }
      .search-empty-state {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 275px;

        .empty-state-icon-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 176px;
          height: 176px;
          background: color(grey, lightester);
          border-radius: 50%;

          svg {
            width: 62px;
            height: 62px;
            path {
              fill: color(grey, lightest);
            }
          }
        }

        p {
          font-weight: 700;
          margin-top: 20px;
        }
      }
    }
  }
  .map-wrapper {
    min-width: 0;
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    position: relative;

    .use-current-location {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      position: absolute;
      bottom: 5%;
      right: 3%;
      width: 44px;
      height: 44px;
      border-radius: 50%;

      color: black;

      &:hover {
        cursor: pointer;

        i {
          color: color(purple, dark);
        }
      }
    }

    .cluster-marker {
      color: #fff;
      background: color(purple, base);
      border-radius: 50%;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg.map-marker {
      height: auto;
      overflow: visible;
      path {
        fill: color(purple, dark);
        stroke-width: 1;
        stroke: color(purple, dark);
        scale: 1.7;
      }

      &.active {
        path {
          fill: color(purple, darkest);
          stroke-width: 1;
          stroke: color(purple, darkest);
          scale: 1.8;
        }
      }
    }

    &.offset {
      position: absolute;
      top: 0;
      right: -500%;
    }
  }

  .Search-Filter-Modal {
    .content {
      padding: 25px;
      height: 85%;
      max-width: 55%;
      .search-filters {
        max-height: 100%;
        overflow-y: auto;
        flex: 2;
        .filter-section {
          position: relative;
          padding: 15px;
          border-bottom: 1px solid #eee;
          .toggle-count {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 16px;
            width: 16px;
            font-size: 12px;
            background: #eb645e;
            border-radius: 50%;
            color: #fff;
            position: absolute;
            right: 45px;
            top: 14px;
            padding: 5px;
          }
          .clear-filter-section {
            position: absolute;
            right: 15px;

            i {
              font-size: 26px;
            }

            &:hover {
              cursor: pointer;
              color: color(purple, dark);
            }
          }
          .filter-expandable {
            font-weight: 800;
          }
          .filter-title {
            margin: 10px 0;
            font-weight: 800;

            span {
              color: color(grey, base);
              font-size: 12px;
              font-weight: 500;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }
          }
        }
        .filter-content {
          display: flex;
          flex-wrap: wrap;
          column-gap: 10px;
          row-gap: 10px;
          &.column {
            flex-direction: column;
            align-items: flex-start;
          }
          // TODO: Mixin or component we keep reusing
          .focus-area-chip {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transform-origin: right top;
            list-style: none;
            font-weight: 700;
            font-size: 14px;
            white-space: nowrap;
            text-align: center;
            border-radius: 8px;
            background: #f5f5f5;
            color: #959799;
            padding: 14px;

            &.selected {
              span {
                display: inline-flex;
                margin-right: 5px;
              }
            }
          }
          .range-filter {
            width: 50%;
          }
          .RangeSlider {
            margin-top: 20px;
            margin-bottom: 20px;
            margin-left: 2%;

            .rc-slider-rail {
              height: 2px;
              background-color: color(grey, light);
            }

            .rc-slider-track {
              background-color: color(purple, base);
            }

            .rc-slider-handle {
              height: 24px;
              width: 24px;
              margin-top: -10px;
              border: solid 2px color(purple, base);
            }

            .rc-slider-tooltip-content {
              background: none;

              .rc-slider-tooltip-inner {
                background: none;
                border: none;
                color: color(grey, base);
                box-shadow: none;
              }
            }
          }
        }
        .filter-dropdown {
          min-width: 205px;
          position: relative;
          flex: 2;

          .input-wrap {
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 700;
            font-size: 16px;
            color: #012939;
            padding: 0 23px 0 16px;
            border: none;
            background: #f5f5f5;
            border-radius: 22px;

            .menu-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              transition: transform 0.3s ease-in-out;

              &.show {
                transform: rotate(180deg);
              }
            }

            .label {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              .img {
                width: 12px;
                height: 12px;
                margin-right: 14px;
              }
            }

            &:hover {
              cursor: pointer;
            }
          }

          label {
            display: none;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .search-filter-actions {
        display: flex;
        justify-content: space-around;
        padding: 25px;

        .Button {
          button {
            min-height: 44px;
          }
        }
      }
    }
  }

  // Bottom Sheet Styles
  [data-rsbs-overlay]:focus {
    outline: none;
  }
}

@media (max-width: 500px) {
  body.search {
    overflow: auto;
    position: relative;
    .mobile-footer-wrapper {
      display: none;
    }
    .Header {
      position: sticky;
      top: 0;
      left: 0;
    }

    .KambeoSearch {
      max-height: unset;
      .search-top {
        position: fixed;
        top: 76px;
        left: 0;
        width: 100%;
      }
      .search-views-container {
        max-height: unset;
        width: 100%;
        flex-direction: column;
        .search-grid-view {
          display: flex;
          box-sizing: border-box;
          flex-direction: column;
          justify-self: center;
          align-items: center;
          overflow: unset;
          margin-top: 80px;
          background: #fff;
          z-index: 2;
          height: 100%;

          &.column {
            display: none;
          }

          .icon-container {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            position: fixed;
            top: 90%;
            background: black;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 200ms ease-in-out;
            right: 4%;
          }

          &.full {
            padding: 10px;
            grid-row-gap: 16px;
            row-gap: 16px;
          }

          .SearchResultPreview {
            width: 95vw;
          }
        }
      }
      .map-wrapper {
        // position: relative;
        right: unset;
        z-index: 1;

        .use-current-location {
          bottom: 15%;

          &:hover {
            cursor: pointer;
          }
        }
        .icon-container {
          height: 44px;
          width: 80px;
          border-radius: 24px;
          position: absolute;
          bottom: 40px;
          background: black;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 41%;
        }
      }
    }

    .Search-Filter-Modal {
      max-height: 100%;
      height: 100%;
      overflow: hidden;
      .content {
        padding: 10px;
        min-width: 100%;
        height: 100%;
        overflow: auto;
        box-sizing: border-box;

        .search-filters {
          .CollapsibleSection {
            .filter-expandable {
              font-weight: 800 !important;
            }
          }
        }
        .ModalHeader {
          i {
            left: unset;
            right: 10px;
            top: 12px;
          }
        }
      }
    }
  }
}
