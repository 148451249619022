@import '../../../../styles/variables.scss';

.HubEventsCarousel {
  .section-content {
    .Carousel {
      .ListCard {
        width: 320px;
        height: 395px;

        .desc {
          margin: 2px 0 24px 0;
        }

        .cover-img {
          height: 280px;
          max-height: 280px;
          max-width: 320px;
        }
      }
    }
  }
}
