.HubPaymentModal {
  padding: 35px 49px;
  overflow: auto;
  .header-container {
    display: flex;
    flex-flow: column nowrap;
    .title {
      color: color(light, fontColor);
      font-weight: bold;
      font-size: 32px;
      margin-bottom: 7px;
    }
  }

  .description-container {
    padding: 20px 0;

    .QuillTextEditor {
      position: relative;
      top: 0px;
      left: 0px;
      width: 100%;

      .quill {
        padding: 0;
      }
    }

    .ql-editor {
      font-family: 'Lato', sans-serif;
      padding: 0px;
      line-height: 24px;
      font-size: 15px;
      overflow: hidden;
    }
  }

  .default-payment-method {
    .PaymentComponent {
      padding: 0;
    }
    .pm {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #e8f1f2;
      padding: 0px 10px;
      height: 60px;

      i {
        &:first-child {
          font-size: 40px;
        }

        &.fa-cc-visa {
          color: #214dba;
        }

        &.fa-cc-mastercard {
          color: #343a47;
        }

        &.fa-cc-amex {
          color: #6ca0da;
        }

        &.fa-cc-discover {
          color: #ef7e23;
        }

        &.fa-cc-diners-club {
          color: #01084c;
        }

        &.fa-cc-jcb {
          color: #0867ad;
        }

        &.fa-credit-card {
          color: color(purple, dark);
        }

        &.fas {
          color: color(purple, dark);
        }
      }

      &:hover {
        cursor: pointer;
        background-color: #fbfbfb;
      }

      span {
        flex: 1;
        padding-left: 10px;
        font-size: 14px;
      }

      &.new {
        span {
          font-weight: 700;
          padding-left: 0px;
          font-size: 16px;
        }

        i {
          width: 16px;
        }
      }

      &:last-child {
        border-bottom: 1px solid #e8f1f2;
      }

      .update-action {
        font-weight: 800;
      }
    }
  }

  .action-conatiner {
    display: flex;
    padding-top: 35px;
    align-items: flex-end;
    justify-content: flex-end;
    .action-button.cancel {
      margin-right: 15px;
    }
  }
}
