@import '../../styles/variables.scss';

.prompt {
  padding: 15px;
  width: 100%;
  max-width: 500px;

  .prompt-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .prompt-inner {
      font-size: 31px;
      font-weight: 700;
      margin: 10px 0;
    }

    .prompt-message {
      line-height: 24px;
      font-size: 16px;
      color: color(grey);
      padding: 15px 40px 20px;
      a {
        color: color(purple, dark);
        text-decoration: none;
        &:hover {
          cursor: pointer;
          color: color(purple);
        }
      }
    }
  }

  .prompt-info {
    padding: 20px 40px;

    .prompt-actions {
      display: flex;
      justify-content: center;

      .prompt-button-delete {
        .Button {
          button {
            background: color(red, dark);
            color: color(dark, fontColor);

            &:hover {
              background: color(purple, base);
              box-shadow: none;
            }
          }
        }
      }

      .prompt-button-cancel {
        margin-right: 10px;

        .Button {
          button {
            background: color(grey, dark);
            color: color(dark, fontColor);

            &:hover {
              background: color(purple, base);
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}
