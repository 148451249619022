@import '../../../../styles/variables.scss';

.ListCard {
  width: 356px;
  margin: 30px 8px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
  background: #fff;
  transition: all 0.25s ease-in-out;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
    .cover-image-holder {
      transform: scale(1.1);
      transition: all 0.25s ease-in-out;
    }
  }

  .cover-img {
    background: transparent;
    position: relative;
    width: 100%;
    max-height: 250px;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 200px;

    .Portrait {
      position: absolute;
      top: 25px;
      left: 25px;
    }

    .cover-image-holder {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #bebada;
      transition: all 0.25s ease-in-out;
    }
  }
  .card-content {
    padding: 15px 25px;
    .title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        cursor: pointer;
        color: color(purple, dark);
      }

      &.charity {
        margin-bottom: 5px;
      }
    }
    .row {
      max-height: fit-content;
      padding-right: 25px;
      display: flex;
      justify-content: space-between;

      div {
        flex: 1;

        p {
          display: inline-flex;
          margin: 0;
        }

        span {
          font-weight: 700;
        }
      }

      &.charity {
        span,
        p {
          color: #6f7072;
          font-size: 14px;
        }
      }
    }
    .charity-id,
    .date,
    .content,
    .above-title-container {
      font-size: 12px;
      min-height: 28px;
      font-weight: 700;
      color: #6f7072;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      .description {
        margin: -15px 0px 0px -15px;
      }
    }
    .charity-id {
      margin-bottom: 10px;
      min-height: 0;
    }

    .above-title-container {
      display: flex;
      align-items: center;
      gap: 15px;

      .date {
        display: flex;
        align-items: center;
      }

      .separator {
        background-color: #6f7072;
        height: 6px;
        width: 6px;
        margin-right: 7px;
        border-radius: 50%;
      }

      .location-container {
        display: flex;
        align-items: center;
      }
    }
  }
}
