@import '../../../styles/variables.scss';

.Locations {
  h3 {
    font-size: 22px;
    margin: 0px;
    padding: 0px 20px 20px;
  }

  .title {
    .TextField {
      padding: 0px 20px 20px;
    }
  }

  .locations-modal {
    padding: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 700px;
    overflow: auto;

    .no-locations {
      padding: 20px 0px;
      opacity: 0.8;
      text-align: center;
    }

    ul {
      flex: 1;
      display: flex;
      flex-direction: column;
      list-style-type: none;
      padding: 0px;
      margin: 0px;

      li {
        display: flex;
        align-items: center;
        flex: 1;
        min-height: 60px;
        padding: 0px 20px 0px 0px;
        border-bottom: 1px solid color(grey, lighter);
        color: color(grey);

        &.headers {
          background-color: color(cream, lighter);
          min-height: 40px;
          font-weight: 700;
          border-bottom: 0px;
          color: color(light, fontColor);
        }

        &.manage {
          border-bottom: 0px;
          padding-left: 20px;
          padding-top: 10px;

          a {
            color: color(light, fontColor);
            text-decoration: none;

            &:hover {
              color: color(purple, dark);
            }
          }

          i {
            margin-right: 8px;
          }
        }

        .show {
          display: flex;
          flex: 1;
          justify-content: flex-end;
          align-items: flex-end;

          .fa-eye,
          .fa-eye-slash {
            cursor: pointer;
          }

          .fa-eye {
            color: color(purple, dark);
          }
        }

        .address {
          padding-left: 20px;
          white-space: nowrap;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 90px;
        }
      }
    }
  }

  .map {
    position: relative;
    display: flex;
    flex: 1;
    justify-content: center;
    height: 400px;
    border: 1px solid transparent;
    margin: 0px 20px;

    .location-popup {
      padding: 15px;

      .title {
        color: color(purple, dark);
        font-size: 18px;
        padding-bottom: 10px;
        font-weight: 500;
      }

      .address {
        max-width: 250px;
        font-size: 14px;
        line-height: 21px;
      }

      a {
        margin-top: 20px;
        justify-content: center;
        display: flex;
        flex: 1;
        background-color: color(purple, dark);
        color: color(dark, fontColor);
        padding: 8px;
        border-radius: $border-radius;
        text-decoration: none;

        &:hover {
          cursor: pointer;
          background-color: color(purple);
        }
      }
    }

    .overlay {
      background: $overlay-gradient;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        position: relative;
        font-size: 16px;
        color: color(dark, fontColor);
        font-weight: 400;

        span {
          color: color(purple, dark);

          &:hover {
            cursor: pointer;
            color: color(purple);
          }
        }
      }
    }

    .list-admin-actions {
      display: none;
    }

    &.admin {
      .list-admin-actions {
        display: none;
        position: absolute;
        top: 0px;
        right: 0px;
        background-color: color(purple);
        height: 30px;
        line-height: 30px;

        ul {
          display: flex;
          list-style-type: none;
          padding: 0px;
          margin: 0px;

          li {
            color: color(dark, fontColor);
            padding: 0px 10px;

            &:hover {
              cursor: pointer;
              background-color: color(light, secondaryBg);

              i,
              span {
                color: color(purple);
              }
            }

            span {
              margin-left: 8px;
            }
          }
        }
      }

      &:hover {
        border: 1px solid color(purple);

        .list-admin-actions {
          display: flex;
        }
      }
    }
  }
}

@media (max-width: 930px) {
  .Locations {
    h3 {
      padding: 0px 0px 20px;
    }

    .map {
      margin: 0px;

      .overlay {
        p {
          text-align: center;
          padding: 0px 20px;
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .Locations {
    .locations-modal {
      padding: 10px;

      ul {
        li {
          .address {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
