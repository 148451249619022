@import '../../../../styles/variables.scss';

.HubModalDonate {
  .content {
    width: 738px;
    height: 509px;
    padding: 16px;
    display: flex;
    position: relative;

    .ModalHeader {
      display: flex;

      .modal-close-icon {
        margin: 0;
        position: absolute;
        right: 16px;
        top: 16px;
      }

      .header-container {
        padding: 0;
        flex-grow: 1;

        .header-title {
          padding-left: 0;
          text-align: center;
        }
      }
    }

    .count {
      margin-top: 22px;
      font-weight: 700;
      font-size: 14px;
      color: color(grey, light);
      padding: 0 92px;
    }

    .search-container {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 12px;

      .TextField {
        max-width: 435px;
        height: 44px;

        .input-wrap {
          border-radius: 22px;
          width: 435px;
          height: 44px;
          border: 1px solid color(blue, lighter);
          background-color: color(light, secondaryBg);

          i {
            height: 44px;
            color: color(grey, light);
          }

          input {
            height: 44px;
            background-color: color(light, secondaryBg);
          }
        }
      }

      .Button {
        button {
          background: color(otherblue, link);
          color: color(light, secondaryBg);
          border: 1px solid color(otherblue, link);
          transition: all 0.2s;

          &:hover {
            background: color(light, secondaryBg);
            color: color(otherblue, link);
          }
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding: 24px 92px;
      overflow-y: auto;
      max-height: 245px;

      @include thin-scroller-y;

      .item {
        padding: 0 28px 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 16px;
        max-width: 640px;
        height: 80px;
        background: color(light, secondaryBg);
        border: 2px solid color(light, shadow);
        box-sizing: border-box;
        border-radius: 10px;
        transition: all 0.2s;

        &:hover {
          cursor: pointer;
          border: 2px solid color(plum, dark);
        }

        img {
          flex-grow: 0;
          border: 1px solid color(grey, border);
          box-sizing: border-box;
          border-radius: 20px;
          overflow: hidden;
        }

        .info {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          row-gap: 4px;

          .title {
            font-weight: 800;
            font-size: 16px;
            color: color(grey, dark);
          }

          .charity-id {
            font-weight: 500;
            font-size: 12px;
            color: color(grey, text3);
          }
        }

        .load-more {
          place-self: center;
        }
      }

      a {
        text-decoration: none;
      }
    }

    .page-indicator {
      color: color(otherblue, link);
      background-color: color(cream, dark);

      &.current {
        background: color(otherblue, link) 0 0 no-repeat padding-box;
        color: color(light, secondaryBg);
      }
    }
  }

  .Loader {
    display: flex;
    align-items: center;
    justify-content: center;
    place-self: center;
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      text-align: center;
    }
  }
}

@media (max-width: 1070px) {
  .HubModalDonate {
    .content {
      width: auto;
      height: 100%;
      padding: 0 16px;

      .ModalHeader {
        padding: 0 8px;
        justify-content: space-between;

        .header-container {
          max-width: 320px;

          .header-title {
            text-align: left;
            padding: 0;
          }
        }

        .close-button {
          display: none;
        }
      }

      .search-container {
        margin-top: 32px;
        flex-direction: column;
        row-gap: 16px;

        .Button {
          width: 100%;

          button {
            width: 100%;
          }
        }

        .TextField {
          max-width: unset;
          width: 100%;

          .input-wrap {
            width: unset;
          }
        }
      }

      .list {
        max-height: calc(100vh - 494px);
        padding: 24px 8px;
      }
    }
  }
}
