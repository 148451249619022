@import '../../../styles/variables.scss';

.access-denied {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    text-align: center;
    max-width: 100%;
    padding: 40px 0px;

    img {
      max-width: 100%;
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      padding: 25px 0px;
      color: #1b1931;
    }

    p {
      padding: 0px;
      margin: 0px;
      font-size: 18px;
      font-weight: 600;
      line-height: 32px;
      color: color(grey, text2);
      line-height: 24px;
    }

    .Button {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        background-color: white;
        border: 1px solid color(grey, empty2);
        border-radius: 22px;
        color: color(grey, dark);
        margin-top: 50px;
      }
      :hover {
        background-color: color(grey, empty2);
      }
    }
  }
}

@media (max-width: 500px) {
  .access-denied {
    .content {
      padding: 0px 10px;

      .title {
        font-size: 31px;
      }

      p {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
