@import '../../../../styles/variables.scss';

.HubManagementVolunteerHours {
  display: flex;
  flex-flow: column nowrap;
  padding: 50px;
  height: 100%;
  gap: 30px;

  .header-section {
    display: flex;
    // align-items: center;
    flex-direction: column;
    .header-actions {
      display: flex;
      margin-left: auto;
      gap: 15px;
    }
  }

  .table-section {
    min-height: 400px;

    .TableComponent {
      .item-name-col {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 15px;
        min-height: 60px;

        .image-container {
          object-fit: cover;
          border-radius: 50%;
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}
