@import '../../styles/variables.scss';

.Discover {
  width: 100%;

  .empty-card {
    width: 100%;
    height: 340px;
    flex: 33%;
    margin: 30px 8px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    transition: all 0.25s ease-in-out;
    max-width: calc(33% - 15px);
    background: color(grey, pale);

    &.event {
      max-width: 383px;
      height: 474px;
      margin: 45px 0px 0px 0px;
    }

    &.gig {
      max-width: 408px;
      height: 383px;
      flex: unset;
    }

    &.group {
      height: 392px;
      max-width: 363px;
      margin: 45px 35px 10px 0px;
    }

    &.hub {
      height: 392px;
      max-width: 363px;
      margin-top: 45px;
      margin-right: 32px;
    }
  }

  .discover-filters {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 10px 0;

    .HorizontalScroller {
      width: auto;
      .page-tab-container {
        padding: 2px 0;
      }
      .page-item:hover {
        cursor: pointer;
      }
      .page-item {
        margin: 5px 25px;
        font-family: $raleway;
        flex-shrink: 0;
      }
      .page-item:before {
        font-family: 'Font Awesome 5 Pro', serif;
        margin-right: 12px;
      }
    }
  }

  .discover-content {
    position: relative;
    overflow: hidden;

    .cards-background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 110px;
      z-index: 0;
    }

    .loading-wrapper {
      padding: 220px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 42px;
      position: relative;
      z-index: 1;
    }
  }
  .discover-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
    text-align: left;

    .title {
      width: 1284px;
    }

    .mob-title,
    .title {
      line-height: 1;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      color: black;
    }

    .mob-title {
      max-width: 524px;
      display: none;
    }
  }

  .discover-list {
    position: relative;
    z-index: 1;
    max-width: 1284px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;

    .gig-loader-card-item {
      height: 374px;
      margin: 30px 0;
      width: 408px;
      max-width: 408px;
    }

    .event-loader-card-item,
    .hub-loader-card-item {
      width: 381px;
      height: 392px;
      margin: 30px 0;
    }

    .CardLoader {
      .wrapper {
        justify-content: space-between;

        .gig-loader-card-item {
          width: 408px;
          max-width: 408px;
          height: 383px;
          flex: unset;
          margin: 30px 8px;
        }

        .event-loader-card-item,
        .hub-loader-card-item {
          width: 381px;
          height: 392px;
          margin: 30px 0;
        }

        .group-loader-card-item {
          width: 381px;
          height: 314px;
          margin: 30px 8px;
        }
      }
    }

    .load-section {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 60px 0;

      .explore-button:hover {
        border: 1px solid color(plum, darker);
        color: color(plum, darker);
        cursor: pointer;
      }

      .explore-button {
        background: transparent;
        border: 1px solid #3a3a3a;
        color: #3a3a3a;
        font-size: 16px;
        padding: 12px 28px;
        border-radius: 22px;
      }
    }

    .CardCommon {
      .CardContent {
        p {
          &:nth-of-type(1) {
            font-size: 16px;
            font-weight: 800;
            margin-bottom: 5px;
          }

          &:nth-of-type(2) {
            font-size: 12px;
            margin-top: 0px;
            color: #6f7072;
          }
        }

        .focus-areas {
          display: flex;
          border-radius: 50%;
          margin-top: 20px;

          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  .discover-empty {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 135px 0;
    align-items: center;
    flex-direction: column;

    .empty-label {
      margin-top: 45px;
      font-family: $raleway;
      font-weight: 500;
    }

    .empty-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 176px;
      height: 176px;
      border-radius: 88px;
      background: color(cream, lighter);
      overflow: hidden;

      img {
        width: 72px;
        height: 72px;
      }
    }

    .explore-button {
      margin-top: 8px;
      font-family: $lato;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      text-decoration-line: underline;
      color: color(grey, text);
      line-height: 19px;
      transition: all 0.2s;

      &:hover {
        cursor: pointer;
        color: color(plum, dark);
      }
    }
  }
}

.discover-shortcuts-list {
  width: 100%;

  .discover-shortcut-item.light {
    background: color(light, secondaryBg);
  }

  .discover-shortcut-item.dark {
    background: color(cream, lighter);
  }

  .discover-shortcut-item.alt {
    .discover-shortcut {
      .shortcut-text {
        padding-right: 236px;
        padding-left: 0;

        .shortcut-link {
          left: 0;

          a {
            margin-right: 16px;
          }
        }
      }
      .shortcut-image {
        padding-right: 0;
      }
    }
  }

  .discover-shortcut-item {
    width: 100%;

    .discover-shortcut {
      max-width: 1284px;
      margin: auto;
      padding: 128px 80px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .shortcut-image {
        flex: 50%;

        img {
          width: 100%;
        }
      }

      .shortcut-text {
        padding-left: 236px;
        flex: 50%;
        position: relative;
        height: 214px;
        line-height: 24px;
        font-size: 16px;
        color: color(plum, darker);

        .shortcut-title {
          font-size: 24px;
          font-family: $raleway;
          font-weight: 800;
          margin-bottom: 10px;
          line-height: 40px;
        }

        .shortcut-link {
          position: absolute;
          left: 236px;
          bottom: 0;
          font-size: 20px;
          font-family: $raleway;
          font-weight: 800;

          span {
            margin-left: 15px;
          }

          a {
            transition: all 0.2s;
            text-decoration: none;
            padding: 12px 28px;
            background-color: color(plum, dark);
            color: color(light, secondaryBg);
            border-radius: 22px;
            height: 44px;
            text-align: center;
            font-weight: 700;
            font-size: 16px;

            &:hover {
              text-decoration: none;
              background-color: color(purple, dark);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1290px) {
  .Discover {
    .discover-list {
      justify-content: flex-start;
    }

    .discover-shortcut-item.alt {
      .discover-shortcut {
        .shortcut-text {
          padding-right: 50px;
        }
      }
    }
    .discover-shortcuts-list {
      .discover-shortcut-item {
        .discover-shortcut {
          align-items: center;

          .shortcut-image {
            max-width: 50%;
            overflow: hidden;

            img {
              width: 100%;
              height: auto;
            }
          }

          .shortcut-text {
            padding-left: 50px;
            max-width: 50%;

            .shortcut-link {
              left: 50px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1151px) {
  .Discover {
    .subheader-section {
      .subheader-item {
        margin: 0 5px;
      }
    }
  }
}

@media (max-width: 991px) {
  .Discover {
    .discover-list {
      padding-left: 64px;
    }

    .discover-title {
      padding: 60px 25px 0 25px;
      width: auto;

      .mob-title {
        display: block;
      }
      .title {
        display: none;
      }
    }

    .discover-filters {
      display: block;

      .page-scroller {
        padding: 5px 0;
      }
    }

    .discover-subheader::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    .discover-subheader::-webkit-scrollbar-track {
      background-color: color(light, secondary);
    }

    .discover-subheader::-webkit-scrollbar-thumb {
      opacity: 0.2;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 8px;
    }

    .discover-subheader {
      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-track {
        background-color: color(light, secondary);
      }

      ::-webkit-scrollbar-thumb {
        opacity: 0.2;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 8px;
      }

      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
      overflow: auto;
      justify-content: unset;
    }

    .subheader-section {
      max-width: unset;
      width: unset;
      margin: unset;
      justify-content: unset;

      .subheader-item {
        margin: 0 5px;
        flex-shrink: 0;
      }
    }

    .discover-shortcuts-list {
      .discover-shortcut-item.alt {
        .discover-shortcut {
          flex-direction: column-reverse;
        }
      }

      .discover-shortcut-item {
        .discover-shortcut {
          flex-direction: column;

          .shortcut-image {
            max-width: unset;
            padding: 0 0 20px 0;
          }

          .shortcut-text {
            height: auto;
            max-width: unset;
            padding: 0 0 30px 0;

            .shortcut-link {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              left: 0;

              a {
                padding: 0 28px;
                line-height: 44px;
                margin-top: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 501px) and (max-width: 790px) {
  .Discover {
    .discover-shortcuts-list {
      .discover-shortcut-item {
        .discover-shortcut {
          margin-bottom: 70px;
        }
      }
    }
  }
}

@media (max-width: 530px) {
  .discover-shortcuts-list {
    .discover-shortcut-item {
      .discover-shortcut {
        .shortcut-text {
          .shortcut-title {
            font-size: 22px;
            line-height: 26px;
          }

          .shortcut-description {
            font-size: 16px;
          }

          .shortcut-link {
            a,
            span {
              font-size: 16px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 790px) {
  .Discover {
    .discover-subheader {
      position: relative;
      top: 0;
    }

    .discover-shortcuts-list {
      .discover-shortcut-item {
        .discover-shortcut {
          padding: 70px 25px 0 25px;

          .shortcut-text {
            flex: auto;

            .shortcut-description {
              padding-bottom: 20px;
            }
          }
        }
      }
    }

    .discover-content.empty {
      padding: 0;
    }

    .discover-content {
      overflow-x: auto;
      padding: 0 20px;

      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-track {
        background-color: color(light, secondary);
      }

      ::-webkit-scrollbar-thumb {
        opacity: 0.2;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 8px;
      }

      .cards-background {
        left: 0;
      }

      .discover-list {
        max-width: unset;
        width: 100%;
        flex-wrap: nowrap;
        padding: unset;

        .CardLoader {
          .wrapper {
            .group-loader-card-item {
              max-width: 286px;
              height: 298px;

              .two-buttons {
                display: none;
              }
            }

            .hub-loader-card-item,
            .event-loader-card-item,
            .gig-loader-card-item {
              max-width: 310px;
              height: 367px;
            }
          }
        }

        .VolunteerCard {
          flex: 100% 1;
          max-width: 250px;
          overflow: unset;

          .actions-container {
            a,
            button {
              padding: 12px 24px;
            }
          }
        }

        .load-section {
          margin: auto 20px;
        }
      }
    }
  }
}
@media (max-width: 430px) {
  .Discover {
    .discover-content {
      .discover-list {
        .CardCommon {
          min-width: 350px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .Discover {
    .discover-content {
      .discover-list {
        .CardCommon {
          min-width: 320px;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .Discover {
    .discover-content {
      .discover-list {
        .CardCommon {
          height: 360px;
          min-width: 280px;

          .card-content {
            &.group-content {
              padding-left: 25px;

              .card-title {
                a {
                  max-width: 230px;
                }
              }

              .group-causes {
                display: none;
              }
            }
          }

          .card-actions {
            &.group-actions {
              .actions-container {
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                margin-top: 20px;
                padding: 0px 0px 20px 20px;

                button {
                  width: 240px;
                }

                div:nth-of-type(1) {
                  margin-bottom: 10px;
                  margin-left: 0px !important;
                }

                div:nth-of-type(2) {
                  margin-right: 0px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
