@import '../../../styles/variables.scss';

.TitleSection {
  display: flex;
  flex-flow: column nowrap;
  margin: 15px 30px 0px 30px;

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .section-title {
      font-size: 22px;
      font-weight: 500;
      margin-right: auto;
    }

    a {
      text-decoration: none;
    }

    .cta-button {
    }
  }

  .tabs-container {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 15px;
    overflow-x: auto;

    .tab {
      transition: color 0.15s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 14px 24px;
      color: #a9aaac;
      white-space: nowrap;
      border-bottom: 1px solid #e9e9e8;

      .icon-container {
        svg,
        i {
          margin-right: 10px;

          path {
            transition: fill 0.15s ease;
            fill: #a9aaac;
          }
        }
      }

      .title {
        font-size: 14px;
        font-weight: 500;
      }

      &.active {
        border-bottom: 2px solid black;
        color: black;

        .icon-container {
          svg {
            path {
              fill: black;
            }
          }
        }
      }

      &:hover {
        cursor: pointer;
        color: black;

        .icon-container {
          svg {
            path {
              fill: black;
            }
          }
        }
      }

      .notification {
        position: relative;
        display: flex;
        margin-left: 10px;
        font-size: 12px;
        min-width: 24px;
        height: 16px;
        background: color(red, soft);
        color: color(light, secondaryBg);
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        line-height: 15px;
      }
    }
  }
}

@media (max-width: 1070px) {
  .TitleSection {
    margin: 0;
    padding: 20px;

    .title-container {
      margin-bottom: 10px;
    }

    .tabs-container {
      margin-bottom: 10px;

      .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        color: color(grey, light);
        font-size: 10px;

        .icon-container {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          width: 45px;
          height: 45px;
          margin-bottom: 5px;
          border: 1px solid color(grey, base3);
          border-radius: 50%;

          i {
            margin: unset;
            font-size: 14px;
          }

          svg {
            margin: 0;

            path {
              fill: #a9aaac;
            }
          }
        }

        &.active {
          background-color: unset;

          .title {
            font-weight: 700;
          }

          .icon-container {
            background-color: color(plum, dark);

            i {
              color: color(light, secondaryBg);
            }

            svg {
              path {
                fill: white;
              }
            }
          }
        }
      }
    }
  }
}
