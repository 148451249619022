@import '../../styles/variables.scss';

.AddDonation {
  padding: 20px;
  overflow: auto;
  position: relative;
  max-width: 600px;

  @include thin-scroller-y;

  .summary {
    position: fixed;
    top: 0;
    right: -295px;
    min-height: 350px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    background: color(light, primaryBg);
    border-radius: $border-radius;

    .summary-info {
      padding: 25px;
      padding-bottom: 0;
      .title {
        font-size: 24px;
        display: flex;
        font-weight: 700;
        justify-content: center;
        padding-bottom: 10px;
        border-bottom: 1px solid color(grey, light);
      }
      .donation {
        display: flex;
        flex: 1;
        justify-content: space-between;
        font-weight: 400;
        font-size: 18px;
        margin: 20px 0;
      }
    }

    .Button {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        height: 42px;
      }
    }

    .total {
      display: flex;
      flex: 1;
      justify-content: space-between;
      font-weight: 500;
      font-size: 18px;
      max-height: 30px;
      align-items: center;
      padding: 10px 25px;
      background: color(blue, dark);
      color: color(dark, fontColor);
    }
  }

  .title-row {
    font-size: 22px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
  }

  .subheader {
    padding: 5px;
    border-bottom: 1px solid #f5f6f7;
    font-size: 18px;
    margin-bottom: 15px;
  }

  form {
    .details-row {
      margin-bottom: 20px;
      display: flex;

      .DatePicker {
        width: 100%;
      }
      .Dropdown {
        flex: 1;
      }

      .Checkbox {
        label {
          color: color(grey);
          font-weight: 700;
          font-size: 14px;
        }
      }

      .details-col {
        flex: 1;
        margin-right: 20px;

        .Checkbox {
          label {
            justify-content: flex-start;
          }
        }

        &:last-child {
          margin: 0px;
        }
      }
    }

    .donation-amount-wrap {
      display: flex;
      margin-bottom: 30px;
      flex-wrap: wrap;

      .donation-amount,
      .tip {
        border-radius: 8px;
        font-weight: 700;
        color: color(grey);
        border: 2px solid color(grey, light);
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 75px;
        margin-right: 10px;
        height: 40px;
        margin-bottom: 10px;

        &.active {
          border: 2px solid color(purple, dark);
          color: color(light, fontColor);
          box-shadow: $box-shadow;
        }

        &:hover {
          cursor: pointer;
        }

        &.other {
          border: 1px solid color(grey, light);

          &.active {
            border: 1px solid color(purple, dark);

            .TextField .input-wrap {
              border: 1px solid color(purple, dark);
            }
          }
        }
      }
    }

    .donation-dedicate-wrap {
      display: flex;
      margin-bottom: 30px;
      flex-wrap: wrap;

      .donation-amount {
        border-radius: 8px;
        font-weight: 700;
        color: color(grey);
        border: 2px solid color(grey, light);
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 75px;
        flex: 1;
        margin-right: 10px;
        height: 40px;
        margin-bottom: 10px;

        &.active {
          border: 2px solid color(purple, dark);
          color: color(light, fontColor);
          box-shadow: $box-shadow;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .form-2-1 {
      display: flex;
      flex-direction: row;
      div {
        flex: 1;
      }
      div:first-of-type {
        flex: 2;
        margin-right: 8px;
      }
    }
    .form-1-1 {
      display: flex;

      .TextField {
        &:first-child {
          padding-right: 10px;
        }

        &:last-child {
          padding-left: 10px;
        }
      }

      div {
        margin: 5px 0;
      }

      .Dropdown {
        flex: 1;
      }
    }

    .actions {
      padding-top: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
