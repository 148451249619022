@import '../../../../styles/variables.scss';

// Weird name cuz we don't have isolated scss
.PageComponentContainer {
  padding: 0 40px;

  &.simplified-component {
    // padding: 40px unset;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &.detailed-component {
    padding: 60px 40px;
  }
  // added font-family: "Raleway", sans-serif; (GIG-6585)

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 24px;
    font-feature-settings:
      'pnum' on,
      'lnum' on,
      'ss09' on;
    color: color(grey, dark);
    font-family: 'Raleway', sans-serif;
  }

  .page-component-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      background: color(light, secondaryBg);
      border: 1px solid black;
      box-sizing: border-box;
      border-radius: 22px;
      padding: 12px 28px 13px;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      color: black;
      transition: all 0.2s;
      cursor: pointer;

      &:hover {
        background: color(purple, dark);
        color: color(light, secondaryBg);
      }
    }
  }

  &.edit-overlay-container {
    border: 2px solid rgba(0, 102, 255, 0);
    position: relative;

    &:hover {
      border: 2px solid rgba(0, 102, 255, 1);
      box-sizing: border-box;

      & > .edit-mode-overlay {
        display: flex;
      }
    }
  }

  .edit-mode-overlay {
    display: none;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    z-index: 5;
    background: rgba(94, 150, 235, 0.1);
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    button,
    .icon-button {
      width: 44px;
      height: 44px;
      border-radius: 50px;
      background: color(otherblue, mid);
      color: color(light, secondaryBg);
      padding: 0;
      margin: 18px 9px 0 9px;
      min-height: unset;
      min-width: unset;
      place-items: center;
      pointer-events: all;

      span {
        margin: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .icon-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .custom-actions-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  section,
  article {
    &.editable {
      transition: all 0.1s;
      border: 2px solid rgba(0, 102, 255, 0);
      position: relative;

      &:hover {
        border: 2px solid rgba(0, 102, 255, 1);
        box-sizing: border-box;

        & > .edit-overlay {
          display: flex;
        }
      }
    }
  }
}
