@import '../../../../styles/variables.scss';

.StoryCarousel {
  max-height: 215px;
  overflow: hidden;
  margin-bottom: 25px;

  .Loader {
    position: absolute;
    left: 50%;
    font-size: 22px;
  }

  .Portrait {
    &:hover {
      cursor: pointer;
    }
  }

  .slick-slider {
    .carousel-next,
    .carousel-prev {
      position: absolute;
      top: 45%;
      z-index: 2;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        color: #3a3a3a;
        min-width: 44px;
        min-height: 44px;
        max-width: 44px;
        max-height: 44px;
        border-radius: 50%;
        padding: 0;
        box-shadow: $box-shadow;

        &:hover {
          background-color: color(grey, lightester);
        }

        span {
          margin: 0;
        }
      }
      &::before {
        display: none;
      }
      &.slick-disabled {
        display: none !important;
      }
    }

    .carousel-prev {
      left: 5px;
    }

    .carousel-next {
      right: 30px;
    }

    .story-placeholder {
      display: flex;
      flex-direction: column;
      width: 130px;

      .background-img {
        width: 100%;
        background: #f5f5f5;
        height: 196px;
        border-radius: 10px;
      }

      .profile-img {
        position: relative;
        bottom: 197px;
        margin: 10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #e9e9e8;
        border: 1px solid #ffffff;
      }
    }
  }
  .slick-slide {
    margin-right: 8px;
  }
  .slick-slide > div {
    max-width: 130px;
    width: 130px;
    max-height: 196px;
    border-radius: 10px;
    margin: 0 5px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
  .storyPreviewCard {
    position: relative;
    font-size: 12px;
    color: #fff;
    font-weight: 800;
    max-width: 130px;
    width: 130px;
    height: 196px;
    border-radius: 10px;
    background: color(purple, light);
    box-shadow: $box-shadow;
    overflow: hidden;

    &.add-story-action {
      img {
        position: absolute;
        top: 0;
        margin: 10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }

    &:hover {
      cursor: pointer;

      video,
      img {
        transform: scale(1.1);
      }

      .story-image-overlay {
        background: rgba(0, 0, 0, 0.4);
      }
    }

    .add-story-background {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
    }

    .story-color,
    .StoryVideo,
    .StoryImage {
      position: absolute;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    .story-image-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      -webkit-transition: 0.6s ease;
      transition: 0.6s ease;
      z-index: 1;
    }

    .Portrait {
      margin: 10px;
    }
    .entity-title {
      display: flex;
      align-items: center;
      height: 35px;
      position: absolute;
      bottom: 0px;
      left: 0;
      padding: 5px 10px;
      width: 100%;
    }
  }

  .Modal {
    .StoryModal {
      border-radius: 10px;
      min-width: 700px;
      .story-header {
        height: 66px;
        display: flex;
        align-items: center;
        padding: 0 15px;

        .Portrait {
          margin-right: 15px;
        }
        .entity-title {
          font-weight: 800;
        }
      }

      .story-content {
        border-radius: 10px;
        min-height: 500px;
        background: color(grey, lightester);
        position: relative;
        overflow: hidden;
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        .story-content-background {
          width: 100%;
          height: 100%;
          position: absolute;
          transform: scale(1.2);
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .StoryCarousel {
    .Modal {
      height: 100%;
      max-height: 100%;
      .ModalHeader {
        .close-button {
          left: unset;
          right: 8px;
          top: 30px;
          color: #fff;
        }
      }
      .StoryModal {
        height: 100%;
        min-width: 100%;

        .story-header {
          position: absolute;
          top: 10px;
          color: #fff;
          z-index: 12;
        }

        .story-content {
          padding: 0;
          height: 100%;
          border-radius: 0;
          .StoriesMain {
            padding: 0;
            .StoryContainer {
              height: 100%;
              width: 100%;
              border-radius: 0;

              &:hover {
                cursor: pointer;
              }
            }
            .left,
            .right {
              display: none;
            }
          }

          .story-color,
          .StoryVideo,
          .StoryImage {
            border-radius: 0 !important; // TODO: fix specificity
          }
        }
      }
    }
  }
}
