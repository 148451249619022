@import '../../styles/variables.scss';

.PopupMenu {
  &.PopupMenuHubLng {
    margin-left: -100px;
    margin-top: 13px;

    li {
      font-size: 16px;
      color: color(grey, dark);
      transition: all 0.2s;
      font-weight: bold;

      &:hover {
        color: color(purple, dark);
      }
    }
  }

  &.PopupMenuHubEdit,
  &.PopupMenuHubUser {
    li {
      font-size: 16px;
      font-weight: 700;

      .label {
        padding-left: 15px;
        margin-right: 0;
      }

      .fa-check {
        display: none;
      }

      &:hover {
        .icon {
          color: color(purple, dark);
        }
      }
    }

    .MenuItem {
      i {
        color: black;
      }

      img {
        width: 14px;
        height: 14px;
      }
    }
  }

  &.PopupMenuHubEdit {
    margin-left: -210px;
    margin-top: 13px;
  }

  &.PopupMenuHubUser {
    margin-left: -200px;

    .MenuItem {
      &:last-child {
        .label,
        .icon {
          color: color(red, soft);
        }

        &:hover {
          .label,
          .icon {
            color: color(red, error);
          }
        }
      }
    }
  }

  &.PopupMenuHubLng,
  &.PopupMenuHubEdit,
  &.PopupMenuHubUser {
    padding: 20px 40px;
    gap: unset;

    .MenuItem {
      height: 48px;
      line-height: 48px;
    }
  }
}

.RightHeader {
  .mobile {
    display: none;
  }

  .not-mobile {
    display: flex;
  }

  .rh-menu-item {
    min-width: 44px;
    height: 44px;
    line-height: 44px;
    transition: all 0.2s;
    color: color(grey, dark);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: none;
    border-radius: 30px;
    font-family: $raleway;
    margin: 0 6px;

    &.cart,
    &.chat {
      position: relative;

      .count {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 5px;
        border: solid 2px color(light, secondaryBg);
        right: 5px;
        z-index: 12;
        height: 12px;
        width: 12px;
        font-size: 10px;
        border-radius: 50%;
        font-weight: 700;
        color: color(dark, fontColor);
        background-color: color(red, soft);
      }
    }

    &.with-txt-info {
      position: relative;
      overflow: hidden;

      &:hover {
        overflow: visible;

        .txt-info {
          opacity: 1;
        }
      }

      .txt-info {
        &::after {
          content: '';
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent color(light, secondaryBg) transparent;
        }

        top: 65px;
        height: 48px;
        line-height: 48px;
        font-weight: 600;
        font-size: 12px;
        position: absolute;
        width: max-content;
        background-color: color(light, secondaryBg);
        color: black;
        border: 1px solid color(light, secondaryBg);
        border-radius: 6px;
        text-align: center;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.4s;
        padding: 0 16px;
      }
    }

    #edit-icon-container {
      height: 44px;
      width: 44px;
      text-align: center;
    }

    i {
      height: 16px;
    }

    img {
      width: 16px;
      height: 16px;
      margin-bottom: 5px;
    }

    &.lng {
      font-size: 14px;
      font-weight: bold;
    }

    &.user-container {
      font-size: 16px;
      font-weight: 700;
      white-space: nowrap;

      &:hover {
        background-color: unset;
      }

      a {
        transition: all 0.2s;
        text-decoration: unset;
        color: color(grey, dark);

        &:hover {
          color: color(purple, dark);
        }
      }
    }

    &:hover {
      cursor: pointer;
      background-color: color(grey, pale);
    }
  }

  .menu-icon {
    width: 30px;
    height: 25px;
    position: relative;
    margin: 20px auto;
    transform: rotate(0deg);
    transition: 0.2s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: color(light, fontColor);
      border-radius: 4px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.1s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 8px;
      }

      &:nth-child(4) {
        top: 16px;
      }
    }

    &.open {
      span:nth-child(1) {
        top: 8px;
        width: 0;
        left: 50%;
      }

      span:nth-child(2) {
        transform: rotate(45deg);
      }

      span:nth-child(3) {
        transform: rotate(-45deg);
      }

      span:nth-child(4) {
        top: 8px;
        width: 0;
        left: 50%;
      }
    }
  }

  .Modal {
    &.HeaderUserMenuMobile-language {
      .content {
        display: flex;
        align-items: center;
        justify-content: center;

        .lng-container {
          width: 210px;
          background: color(light, secondaryBg);
          box-shadow: 0 16px 24px rgba(0, 0, 0, 0.12);
          border-radius: 10px;
          padding: 24px;

          .header-title {
            text-align: left;
            font-weight: 700;
            font-size: 16px;
            color: color(plum, grey);
            padding: 0 0 22px 0;
            border-bottom: 1px solid color(dark, borderLight);
            margin-bottom: 18px;
          }

          .lng-content {
            display: flex;
            flex-direction: column;
            row-gap: 16px;

            span {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-weight: 700;
              font-size: 14px;
              color: color(grey, dark);

              i {
                margin-right: 24px;
                color: color(grey, text);

                &.fa-check-circle {
                  color: color(purple, dark);
                }
              }
            }
          }
        }
      }
    }

    &.HeaderUserMenuMobile {
      margin-top: $header-height;
      border-top: 1px solid color(dark, borderLight);

      .inner-modal {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        padding: 0 24px 55px 24px;
        overflow-y: auto;
        overflow-x: hidden;

        .profile-image {
          margin-right: 10px;
        }

        .user-profile-details {
          display: flex;
          flex-flow: row nowrap;
          border-bottom: solid 1px color(grey, lightest);
          padding-bottom: 33px;
          align-items: center;

          .go-to-profile {
            display: flex;
            flex-flow: column nowrap;
            margin-right: auto;
            font-size: 14px;

            span {
              font-weight: 700;
            }

            a {
              color: #a9aaac;
            }
          }
        }

        .section {
          padding: 25px 0 25px 0;
          border-bottom: 1px solid color(grey, lightest);

          .link {
            i {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 15px;
              height: 14px;
              line-height: 14px;
              margin-right: 20px;
              font-size: 14px;
            }
          }

          .title-container {
            .title {
              display: flex;
              align-items: center;

              a {
                font-weight: 700;
                font-size: 20px;
                color: black;
                transition: all 0.2s;
                text-decoration: none;
                color: color(grey, dark);

                &:hover {
                  color: color(plum, dark);
                }
              }
            }
          }

          &.user-logged-in {
            border-bottom: unset;

            .title-container {
              .title {
                display: flex;
                align-items: center;

                a {
                  font-weight: 700;
                  font-size: 20px;
                  color: black;
                  transition: all 0.2s;
                  text-decoration: none;

                  &:hover {
                    color: color(plum, dark);
                  }
                }
              }
            }

            .dropdown-section {
              margin-left: 0;

              &.show {
                .link {
                  color: black;
                  font-weight: 600;
                  font-size: 16px;

                  &.logout {
                    color: color(red, soft);
                  }

                  i {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 15px;
                    height: 15px;
                    line-height: 15px;
                    margin-right: 20px;
                    font-size: 14px;
                  }
                }
              }
            }
          }

          &.login-action {
            display: flex;
            flex-flow: column;
            border-bottom: unset;

            .link {
              margin-top: 16px;
              text-decoration: none;

              .Button {
                width: 100%;

                button {
                  max-width: unset;
                  width: 100%;
                }
              }
            }
          }

          &.actions {
            position: relative;
            display: flex;
            flex-flow: column;
            font-size: 14px;
            padding: 15px 0 0 0;

            .link {
              font-size: 16px;

              i {
                font-weight: 500;
              }
            }

            .language-selected {
              cursor: pointer;
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              color: color(light, fontColor) !important;

              &.show {
                color: color(purple, dark);
              }

              i {
                margin-left: 10px;
              }
            }

            .language-menu {
              position: relative;
              cursor: pointer;

              .ContextMenu {
                position: absolute;
                top: 30px;
                left: 0;
                right: 0;
                min-width: unset;
              }
            }
          }

          .title-container {
            display: flex;
            flex-flow: row nowrap;
            margin-bottom: 10px;
            height: 44px;
            line-height: 44px;
            align-items: center;

            i {
              align-items: center;
              justify-content: center;
              display: flex;
              margin-left: auto;
              font-size: 25px;
              font-weight: 700;
              transition: all 0.2s linear;
              transform: rotate(-90deg);

              &.show {
                transform: rotate(90deg);
              }
            }

            .title {
              color: black;
              font-weight: 700;
              font-size: 20px;
            }
          }

          .dropdown-section {
            max-height: 0;
            transition: max-height 0.3s ease-in-out;
            overflow: hidden;
            margin-left: 5px;
            padding-left: 3px;

            .link {
              display: flex;
              position: relative;
              text-decoration: none;
              align-items: center;
              color: black;
              font-weight: 600;
              font-size: 16px;
              height: 44px;
              line-height: 44px;

              i {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15px;
                height: 15px;
                line-height: 15px;
                margin-right: 20px;
                font-size: 14px;
              }
            }

            &.show {
              max-height: 300px;
            }
          }
        }
      }
    }

    .content {
      .add-item-modal {
        flex: 1;
        overflow: hidden;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .Portrait {
          display: flex;
          margin: 40px 0;
          justify-content: center;
          align-items: center;
        }

        .item-name {
          text-align: center;
          font-size: 32px;
          font-weight: 700;
        }

        .item-group {
          margin-top: 5px;
          color: color(grey);
          text-align: center;
          font-size: 18px;
        }

        .item-price {
          text-align: center;
          margin-top: 5px;
          font-size: 24px;
          font-weight: 500;
        }

        .cart-info {
          margin: 30px 30px 0;
          border-top: 1px solid color(grey, light);
          display: flex;
          flex: 1;

          .item-count,
          .item-total {
            display: flex;
            padding: 15px 0;
            flex: 1;
            font-weight: 500;
            font-size: 14px;
          }

          .item-total {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
          }
        }

        .add-item-actions {
          display: flex;
          flex: 1;
          margin: 10px 30px 0;
          padding-bottom: 30px;

          .Button,
          button,
          a {
            display: flex;
            flex: 1;
          }

          a {
            display: flex;
            border: 0;
            text-decoration: none;
            font-size: 16px;
            border-radius: 20px;
            justify-content: center;
            align-items: center;
            color: color(dark, fontColor);
            background-color: color(purple, dark);
            overflow: hidden;
            min-width: 80px;
            min-height: 40px;
            margin-right: 10px;
            font-weight: 700;

            &:hover {
              background-color: color(purple);
            }
          }

          .Button {
            &:first-child {
              margin-right: 10px;

              button {
                background-color: color(light, secondaryBg);
                color: color(grey);
                border: 3px solid color(grey);

                &:hover {
                  border: 3px solid color(purple);
                  color: color(purple);
                }
              }
            }

            &:last-child {
              margin-left: 10px;
            }
          }
        }
      }

      .Login {
        background-image: none;

        &:before {
          content: none;
        }
      }
    }
  }
}

.Modal.contact-modal {
  .content {
    max-height: 622px;
    max-width: 420px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 20px;
    box-shadow: $box-shadow;

    .close-button {
      display: none;
    }

    .ModalHeader {
      border-bottom: 2px solid color(dark, borderLight);
      margin-bottom: 60px;

      .header-container {
        padding-left: 0;
        padding-top: 0;
      }
    }

    .contact-content-container {
      div {
        display: flex !important;
        align-items: center;
        border: 2px solid #e9e9e8;
        border-radius: 10px;
        padding-left: 38px;
        padding-top: 5px;
        padding-bottom: 5px;

        a {
          color: color(purple, dark);
          text-decoration: none;

          &:hover {
            color: color(purple, light) !important;
            cursor: pointer;
          }
        }

        &:first-of-type {
          margin-bottom: 16px;
        }

        &:last-of-type {
          margin-bottom: 60px;
        }

        i {
          margin-right: 21px;
          background: #ededec;
          border-radius: 50%;
          padding: 10px;
        }
      }
    }

    .Button {
      display: flex;
      justify-content: end;
      margin-bottom: 60px;

      button {
        background: white;
        color: black;
        border: 1px solid black;

        &:hover {
          background: black;
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1070px) {
  .PopupMenu {
    &.PopupMenuHubEdit {
      margin-left: -180px;
    }
  }

  .RightHeader {
    .not-mobile {
      .rh-menu-item {
        display: none;

        &.user-container {
          height: 0;
          display: block;
        }
      }
    }

    .mobile {
      display: block;

      .rh-menu-item {
        &:hover {
          background-color: unset;
        }
      }
    }
  }
}

@media (max-width: 659px) {
  .PopupMenu {
    &.PopupMenuHubEdit {
      margin-left: -160px;
    }
  }

  .Modal.contact-modal {
    .content {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      padding: 0;
      box-shadow: unset;
      border-radius: unset;
      max-width: unset;
      max-height: unset;

      .ModalHeader {
        width: 80%;
      }

      .contact-content-container {
        width: 80%;
      }
    }
  }

  .RightHeader {
    width: 100px;
    row-gap: 12px;
    column-gap: 8px;
  }
}
