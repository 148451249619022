@import '../../styles/variables.scss';

.light {
  .NotFound {
    background: rgb(241, 241, 241);
    background: linear-gradient(
      180deg,
      rgba(241, 241, 241, 1) 0%,
      rgba(130, 189, 203, 1) 37%,
      rgba(60, 125, 140, 1) 100%
    );
    color: color(dark, fontColor);

    .star {
      display: none;
    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(800%);
  }

  0% {
    transform: translateX(0%);
  }
}

.NotFound {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(43, 45, 48);
  background: linear-gradient(
    180deg,
    rgba(43, 45, 48, 1) 0%,
    rgba(20, 73, 85, 1) 37%,
    rgba(60, 125, 140, 1) 100%
  );
  position: relative;

  .star {
    position: absolute;
  }

  .star-1 {
    top: 5%;
    left: 12%;
    transform: scale(2) rotate(-25deg);
  }

  .star-2 {
    top: 15%;
    left: 43%;
    transform: rotate(70deg);
  }

  .star-3 {
    top: 53%;
    left: 92%;
    transform: scale(2);
  }

  .star-4 {
    top: 15%;
    left: 95%;
    transform: rotate(180deg);
    opacity: 0.2;
  }

  .star-5 {
    top: 30%;
    left: 17%;
    transform: rotate(45deg);
  }

  .star-6 {
    top: 19%;
    left: 53%;
    transform: scale(2.5);
  }

  .star-7 {
    top: 60%;
    left: 12%;
  }

  .star-8 {
    top: 40%;
    left: 70%;
    transform: scale(2) rotate(-10deg);
  }

  .star-9 {
    top: 10%;
    left: 70%;
    transform: scale(4) rotate(30deg);
    opacity: 0.5;
  }

  .star-10 {
    top: 21%;
    left: 3%;
    transform: rotate(-40deg);
    opacity: 0.2;
  }

  .star-11 {
    top: 30%;
    left: 37%;
    transform: scale(5) rotate(-30deg);
    opacity: 0.1;
  }

  .star-12 {
    top: 10%;
    left: 25%;
    transform: rotate(-67deg);
  }

  .mountain {
    position: absolute;
    font-size: 300px;
  }

  .mountain-1 {
    bottom: -10%;
    left: 10%;
  }

  .mountain-2 {
    bottom: -15%;
    left: 50%;
    opacity: 0.5;
  }

  .mountain-3 {
    bottom: -5%;
    left: 60%;
  }

  .mountain-4 {
    bottom: -20%;
    left: -10%;
  }

  .mountain-5 {
    bottom: -20%;
    left: 90%;
    opacity: 0.5;
  }

  .cloud {
    position: absolute;
    font-size: 100px;
    opacity: 0.5;
    animation: slide 80s alternate infinite;
  }

  .cloud-1 {
    font-size: 200px;
    top: 0%;
    left: -20%;
  }

  .cloud-2 {
    top: 20%;
    left: 80%;
  }

  .cloud-3 {
    top: 25%;
    left: 25%;
  }

  h1 {
    font-family: $raleway;
    padding: 0px;
    margin: 0px;
    font-size: 80px;
    opacity: 0.25;
  }

  p {
    padding: 0px;
    margin: 0px;
    font-size: 20px;
    font-weight: 700;
  }
}
