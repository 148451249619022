@import '../../styles/variables.scss';

.CreateGigModal {
  background-color: color(light, secondaryBg);
  max-width: 500px;
  min-width: 500px;
  padding: 15px 20px 20px;
  border-radius: $border-radius;

  .row {
    margin-bottom: 10px;

    .label {
      font-size: 13px;
      margin-bottom: 4px;
      font-weight: 700;
    }

    &.options-row {
      margin-bottom: 15px;
    }

    .options {
      display: flex;

      .option {
        margin-right: 20px;
        height: 40px;
        line-height: 40px;

        &:hover {
          cursor: pointer;
        }

        i {
          margin-right: 6px;
          color: color(purple, dark);
        }

        span {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }

  .form-actions {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 640px) {
  .CreateGigModal {
    min-width: 0px;

    .row {
      width: 100%;
    }
  }
}
