.EditThankYouMessage {
  .thank-you-modal {
    flex: 1;
    padding: 40px;

    .title {
      font-size: 24px;
      margin-bottom: 20px;
      font-weight: 500;
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .thank-you-actions {
      margin-top: 20px;
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }
}
