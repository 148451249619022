@import '../../styles/variables.scss';

.Notification {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  border-top: 1px solid color(grey, light);
  background-color: color(light, secondaryBg);

  .status {
    &.read {
      display: none;
    }
  }

  &:hover {
    background-color: color(cream, dark);
    cursor: pointer;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    padding: 0px;
    margin: 0px;
    font-weight: 500;
  }

  span {
    font-size: 12px;
    line-height: 10px;
    padding-top: 5px;
    color: color(grey, light);
  }

  .info {
    flex: 1;
    padding: 0px 10px;

    .timestamp {
      &:hover {
        color: color(grey, light) !important;
      }
    }
  }

  .notification-portrait {
    padding-right: 10px;
  }
}
