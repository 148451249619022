.PaymentMethodOption {
  width: 100%;
  padding: 15px 0px;
  height: 25px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex: 1 1;
  align-items: center;

  // Applied when an option is expanded.
  &.expanded {
    border-bottom: none;
  }

  // Applied to an item under an expanded section.
  &.after-expanded {
    margin-top: 20px;
    border-top: 1px solid #e9e9e9;
  }

  .credit-card {
    // Try to match the Google pay/apple pay icon sizing
    font-size: 23px;
    width: 45px;
  }

  span {
    flex: 1 1;
    padding-left: 10px;
    font-weight: 600;
  }

  img,
  svg {
    width: 45px;
    height: auto;
  }

  i:last-child {
    float: right;
    color: #5e51ab;
  }

  &:hover {
    cursor: pointer;
  }
}
