@import '../../../../../styles/variables.scss';

.FilterDrawerContent {
  min-width: 590px;
  max-width: 590px;

  .applied-filters {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 15px;
    padding: 18px 30px;
    border-bottom: color(grey, lightest) 1px solid;

    .filters-applied-label {
      font-size: 14px;
      font-weight: 700;
      margin-right: auto;
    }

    .clear-all {
      font-size: 14px;
      font-weight: 700;
      color: #b0b0b0;
      cursor: pointer;
      &:hover {
        color: #c7c7c7;
      }
    }
  }

  .filters-container {
    display: flex;
    flex-flow: column nowrap;
    padding: 30px;
    gap: 30px;

    .drawer-row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      label,
      label > span {
        color: #5a5b5d;
        font-weight: 700;
        font-size: 14px;
      }

      &.date-container {
        display: flex;
        flex-flow: column nowrap;

        input {
          width: 100%;
        }
      }

      &.dropdown-container {
        .Dropdown {
          width: 100%;
        }
      }
    }
  }
}
