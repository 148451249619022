@import '../../../styles/variables.scss';

.Sponsor {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  padding: 0px 40px 40px;
  max-height: 850px;
  overflow: auto;

  .data {
    .SortableTable {
      .table-row {
        &:hover {
          .col {
            &.status {
              a {
                color: color(purple, dark);

                .fa-external-link {
                  display: inline-block;
                }

                &:hover {
                  color: color(purple);
                }
              }
            }
          }
        }

        .col {
          &.status {
            a {
              color: color(grey, dark);

              .fa-external-link {
                display: none;
              }

              span {
                text-transform: none;
              }
            }
          }
        }
      }
    }
  }
}
