@import '../../../../styles/variables.scss';

.ReconcilePayment {
  display: flex;
  flex-direction: column;
  padding: 48px;

  .donor-cell {
    display: flex;
    align-items: center;

    .Portrait {
      margin-right: 16px;
    }
  }

  &-head {
    h2 {
      font-family: $raleway;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      margin: 0 0 18px 0;
    }

    &--container {
      height: 86px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 20px;

      &.bottom-border {
        border-bottom: 1px solid color(cream, gray);
      }

      .back-part {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $lato;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.0025em;
        color: color(grey, text);
        cursor: pointer;

        i {
          margin-left: 12px;
        }
      }

      .package-id {
        margin-left: 12px;
        color: color(grey, dark);
      }

      .TextField {
        margin-right: 16px;
        width: 350px;
        flex: unset;

        .input-wrap {
          border: 1px solid color(grey, border);
          border-radius: 10px;

          input {
            padding-left: 18px;
          }
        }
      }
    }
  }

  &-main {
    max-height: 443px;
    height: 443px;
    width: 100%;
    padding: 0 8px;
    overflow-x: hidden;
    overflow-y: auto;
    @include thin-scroller-y;
    display: flex;
    flex-direction: column;

    &--search {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      .icon-container {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        background: color(grey, base4);
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        margin: 0;
        text-align: center;
        font-family: $lato;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: color(purple, greyTxt);
      }
    }
  }

  &-actions {
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 2px solid color(cream, gray);

    .Button {
      margin: 30px 16px 30px 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
