@import '../../styles/variables.scss';

@keyframes modalFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes slideIn {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.light {
  .Modal {
    .content {
      background-color: color(light, secondaryBg);
      color: color(light, fontColor);

      .modal-close {
        color: color(dark, fontColor);
      }
    }
  }
}

.dark {
  .Modal {
    .content {
      background-color: color(dark, secondaryBg);
      color: color(dark, fontColor);

      .modal-close {
        color: color(dark, fontColor);
      }
    }
  }
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $overlay-gradient;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  &.SharedModal {
    &.volunteer-application-form-modal {
      .ModalHeader {
        .modal-close-icon {
          margin-right: 40px;
        }
      }

      .content {
        width: 100%;
        max-width: 986px;
        height: 606px;

        .VolunteerApplicationForm {
          overflow-y: auto;
          overflow-x: hidden;
          @include thin-scroller-y;
        }
      }
    }

    &.share-modal {
      .ModalHeader {
        .modal-close-icon {
          position: absolute;
          top: 43px;
          right: 50px;
        }
      }

      .content {
        .modal-close-circle {
          top: 50px;
          right: 50px;
          width: 35px;
          height: 35px;

          .cls-2 {
            stroke: #000;
          }
        }

        .ModalHeader {
          .close-button {
            right: 10px;
            top: 25px;
            left: unset;
          }
        }
      }
    }
  }

  &.tickets-modal {
    .Store {
      .store-item-wrap {
        .components-wrap {
          .StoreItem {
            width: 100%;

            .store-item-inner {
              .images {
                padding-right: 0px;
                width: 100%;
                min-width: 0px;
                max-width: 100%;

                .main {
                  width: 100%;
                }

                .media-nav {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .content {
    max-height: 90%;
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    min-width: 500px;
    max-width: 1200px;
    animation: 150ms ease-out modalFadeIn;
    animation-fill-mode: forwards;
    animation-delay: 100ms;
    opacity: 0;
    padding: 20px;

    .Register {
      padding-bottom: 0px;
    }

    .modal-inner {
      max-height: 80%;
      overflow: auto;
      flex: 1;
    }

    .modal-close-circle {
      position: absolute;
      top: -20px;
      right: -20px;
      width: 40px;
      height: 40px;

      cursor: pointer;

      &:hover {
        path {
          stroke: color(grey, dark);
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .Modal {
    height: calc(100% - #{$mobile-footer-height});
    max-height: calc(100% - #{$mobile-footer-height});

    min-width: 100vw;
    max-width: 100vw;
    width: 100vw;

    &.SelectDayMobileModal {
      height: 100%;
      max-height: 100%;
    }

    &.SharedModal {
      &.volunteer-application-form-modal {
        .ModalHeader {
          min-height: 80px;

          .modal-close-icon {
            margin-top: 10px;
          }

          .header-container {
            min-height: unset;
            padding-top: 10px;
          }

          .close-button {
            display: none;
          }
        }

        .content {
          width: 100%;
          max-width: 100vw;
          height: 100%;

          .VolunteerApplicationForm {
            overflow-y: auto;
            overflow-x: hidden;
            @include thin-scroller-y;

            .inner-content .details-row {
              flex-wrap: wrap;
              row-gap: 12px;

              &.availability {
                width: auto;
                overflow-x: hidden;
                overflow-y: auto;
              }

              .details-col {
                margin: 0;
              }
            }
          }
        }
      }

      &.share-modal {
        .ModalHeader {
          .close-button {
            display: none;
          }

          .modal-close-icon {
            position: absolute;
            top: 20px;
            right: 24px;
          }
        }
      }
    }

    .content {
      height: 100%;
      max-height: 100%;
      padding: 0;

      min-width: 100vw;
      max-width: 100vw;
      width: 100vw;
      border-radius: 0;
      top: 0;

      animation: slideIn 300ms forwards;
      animation-fill-mode: forwards;
      animation-delay: 100ms;

      .modal-close-circle {
        display: none;
      }
    }
  }
}
