@import '../../../styles/variables.scss';

.MetricsList {
  border-top: 1px solid color(grey, lightest);
  padding-top: 15px;
  cursor: default;

  ul {
    padding: 0;
    margin: 0;

    li {
      display: flex;
      flex: 1;
      justify-content: space-between;
      font-size: 16px;
      color: color(grey, lighter);
      font-weight: 800;
      list-style: none;
      line-height: 19px;
      margin: 15px 0;
      column-gap: 8px;

      span {
        color: color(grey, darker);
      }
    }
  }
}
