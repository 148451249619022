@import '../../../styles/variables.scss';

.EventList {
  position: relative;
  width: 100%;
  height: 99%;
  overflow: auto;
  padding-right: 15px;

  h3 {
    margin: 0px;
    padding: 0px 20px 20px;
  }

  .EventList-actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 930px) {
  .EventList {
    h3 {
      padding: 0px 0px 20px;
    }
  }
}

@media (max-width: 560px) {
  .EventList {
    .ComponentPlaceholder {
      .action-container {
        display: flex;
        flex-direction: column;

        .Button,
        .Button button {
          margin: 0px;
          min-width: 200px;
        }

        .Button {
          &:first-child {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
