@import '../../styles/variables.scss';

.ForOrganizations {
  width: 100%;
  position: relative;

  a {
    text-decoration: none;
  }

  .menu-items {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .item {
      font-size: 14px;
      font-weight: 700;
      font-family: $raleway;
      line-height: 16px;
      letter-spacing: 0;
      text-align: center;
      color: color(plum, gray);
      padding: 14px 24px;
      cursor: pointer;

      &.active {
        font-weight: 700;
        background-color: color(plum, dark);
        color: color(light, secondaryBg);
        border-radius: 28px;
      }
    }
  }

  .main-image-img {
    display: none;
  }

  .main-image-bg {
    margin-top: 11px;
    width: 100%;
    height: 240px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .main-container {
    padding: 0 80px;

    .header-container {
      margin-top: 78px;
      color: color(plum, darker);

      h1 {
        margin: 0;
        font-weight: 900;
        font-size: 40px;
        line-height: 47px;
        font-feature-settings:
          'pnum' on,
          'lnum' on,
          'ss09' on;
      }

      h2 {
        margin: 10px 0 0 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }

      .submenu-items {
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .item {
          padding: 12px 28px 13px;
          background: color(light, secondaryBg);
          border-radius: 22px;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: color(plum, darker);
          text-align: center;

          a {
            color: color(plum, darker);
          }

          &.highlighted {
            border: 1px solid color(grey, dark);
            color: color(grey, dark);
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .cards-container {
    margin-top: 106px;
    display: grid;
    row-gap: 71px;
    column-gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(363px, 1fr));
  }

  .collapse-container {
    margin-top: 118px;
    background-color: color(grey, primary);
    padding: 114px 80px;
  }
}

@media (max-width: 790px) {
  .ForOrganizations {
    .main-image-img {
      display: block;
      width: 100%;
    }

    .main-image-bg {
      display: none;
    }

    .menu-items {
      overflow: auto;
      position: relative;
      height: 60px;
      padding: 0 0 0 95px;
      flex-wrap: unset;

      .item {
        white-space: nowrap;
        margin: 0 11px;
      }
    }

    .main-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: unset;

      .cards-container {
        margin-top: 60px;
      }

      .header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 26px;
        margin-top: 60px;

        h1 {
          font-weight: 900;
          font-size: 32px;
          line-height: 38px;
        }

        h1,
        h2 {
          width: 100%;
          text-align: left;
        }

        h2 {
          margin: 16px 0 0 0;
        }

        .submenu-items {
          align-items: center;
          margin-top: 34px;
        }
      }
    }

    .collapse-container {
      padding: 114px 25px;
    }
  }
}

@media (max-width: 513px) {
  .ForOrganizations {
    .submenu-items {
      .item.highlighted {
        margin-bottom: 16px;
      }
    }
  }
}

@media (max-width: 394px) {
  .ForOrganizations {
    .menu-items {
      padding: 0 0 0 130px;
    }
  }
}
