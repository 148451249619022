@import '../../styles/variables.scss';

.MyPages-card {
  width: 400px;
  margin: 10px;
  background-color: white;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 3px 6px #7c7c7c41;

  .card-top {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: color(purple, lighter);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 200px;

    .card-label {
      display: flex;
      position: absolute;
      padding: 5px 10px 5px 10px;
      border-radius: 20px;
      top: 25px;
      left: 25px;
      background-color: white;
      color: color(light, fontColor);
      font-weight: 700;

      i {
        font-size: 20px;
        color: color(grey, font);
        padding-right: 10px;
      }
    }

    .card-menu-icon {
      position: absolute;
      cursor: pointer;
      top: 20px;
      right: 25px;
      font-size: 24px;
      height: 40px;
      width: 40px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: color(grey, darkest);

      &:hover {
        color: black;
      }

      &.show {
        color: black;
      }
    }

    .card-menu {
      position: absolute;
      background-color: white;
      max-width: 140px;
      top: 70px;
      right: 20px;
      margin: 0px;
      padding: 0px;
      list-style-type: none;
      border-radius: 10px;
      box-shadow: 0px 3px 6px #7c7c7c41;
      overflow: hidden;
      max-height: 0;
      -webkit-transition: max-height 200ms ease;
      -moz-transition: max-height 200ms ease;
      -o-transition: max-height 200ms ease;
      transition: max-height 200ms ease;

      &.show {
        max-height: 350px;
        overflow: unset;
        z-index: 1;
      }

      &::before {
        position: absolute;
        border: solid;
        border-color: white transparent;
        border-width: 0px 10px 12px 10px;
        content: '';
        right: 15px;
        overflow: unset;
        top: -12px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 15px 20px;
        cursor: pointer;
        font-size: 15px;
        color: color(light, fontColor);
        font-weight: 700;

        i {
          font-size: 22px;
          color: color(grey, darkest);
          width: 35px;
        }

        &:hover {
          background-color: whitesmoke;
        }

        &:first-child {
          margin-top: 0px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        &:last-child:hover {
          margin-bottom: 0px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }

      .fad {
        font-size: 30px;
        color: color(purple, dark);
        margin-left: 20px;
        font-size: 25px;
        vertical-align: middle;

        &:hover {
          cursor: pointer;
        }

        &.fa-toggle-off {
          color: color(grey);
        }
      }
    }
  }

  .card-bottom {
    position: relative;
    cursor: pointer;
    display: flex;

    .card-image {
      position: relative;
      margin-left: 20px;
      min-width: 122px;
      max-width: 122px;

      .Portrait {
        position: absolute;
        top: -40px;
        left: 0px;
      }
    }

    .card-info {
      flex: 1;
      margin: 20px 10px 20px 20px;

      .card-info-wrap {
        max-width: 218px;
        margin-right: 0px;
      }

      span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px;
        max-width: 100%;
        width: 100%;
      }

      .name {
        font-weight: bold;
        word-wrap: break-word;
        height: 16px;
        overflow: hidden;
        font-family: $raleway;

        &:hover {
          color: color(purple, dark);
        }
      }

      .created-date {
        color: color(grey);
        font-weight: 700;
      }

      .state-label {
        width: 150px;
        text-align: center;
        border-radius: 50px;
        margin-top: 5px;
        padding: 2px;
        font-weight: 700;

        &.active,
        &.running {
          background-color: #fff0eb;
          color: #f89979;
        }

        &.published {
          background-color: #eaeaf8;
          color: #6565d3;
        }

        &.draft {
          background-color: #e1ebf7;
          color: #095fc4;
        }

        &.completed {
          background-color: #e2f8ef;
          color: #2cbd7f;
        }

        &.planned {
          background-color: #e5f8fa;
          color: color(purple, dark);
        }

        //for all unknown statuses
        background-color: color(light, primaryBg);
        color: color(grey, base);
      }
    }
  }
}

@media (max-width: 550px) {
  .MyPages-card {
    width: 100%;
    margin: 10px 0px 10px 0px;
    min-height: 310px;

    .card-top {
      .card-menu {
        .manage {
          display: none;
        }
      }
    }

    .card-bottom {
      position: relative;
      display: flex;

      .card-image {
        position: absolute;
        left: 10px;
        top: -30px;
      }

      .card-info {
        margin-left: 140px;

        .state-label {
          display: none;
        }
      }
    }
  }
}
