/* Mixins storage
*
*/

// Can be used to render an orange link.
@mixin link-primary {
  color: color(purple, dark);
  text-decoration: none;

  &:hover {
    color: color(purple);
  }
}

// Can be used to render a black link.
@mixin link-secondary {
  text-decoration: none;
  font-weight: 700;
  color: color(purple, dark);

  &:hover {
    color: color(purple, dark);
  }
}

// Can be used to render a white buton.
@mixin button-secondary {
  button {
    background: color(light, secondaryBg);
    color: color(purple, dark);
    box-shadow: $box-shadow;

    &:hover {
      background: #f5f5f5;
    }
  }
}

// Can be used to styling different users types labels
@mixin userLabelColor() {
  &.none {
    background-color: color(grey, lighter);
    color: color(grey);
  }

  &.attendee {
    color: color(red, dark);
    background-color: color(red, light);
  }

  &.supporter {
    background-color: color(red, light);
    color: color(red, dark);
  }

  &.follower {
    background-color: color(otherblue, light);
    color: color(otherblue, dark);
  }

  &.volunteer {
    background-color: color(yellow, light);
    color: color(yellow, dark);
  }

  &.donor {
    background-color: color(green, light);
    color: color(green, dark);
  }

  &.fundraiser {
    background-color: color(orange, lighter);
    color: color(orange, darkest2);
  }

  &.sponsor {
    background-color: #faf8df;
    color: #a79c1f;
  }
}

@mixin thin-scroller-y {
  scrollbar-width: thin;
  scrollbar-color: color(grey, text) color(grey, pale);

  &::-webkit-scrollbar-track {
    background: color(grey, pale);
  }

  &::-webkit-scrollbar-thumb {
    background-color: color(grey, text);
    border-radius: 20px;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
}

@mixin truncate($numberOfLines: 1) {
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: $numberOfLines;
}

@mixin skeletonLoaderKeyframes {
  @keyframes loader-animate {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }
}

@mixin skeletonLoaderAnimation {
  &:after {
    content: '';
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: loader-animate;
    animation-timing-function: linear;
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1 );
  }
}
