.FocusAreaModal {
  .content {
    padding: 42px 48px;
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      .header-title {
        font-weight: 700;
        font-size: 22px;
        margin-left: 19px;
      }
    }

    .modal-content {
      margin-top: 43px;
      .cause-item {
        display: flex;
        flex-direction: row;
        margin: 13px 0;
        img {
          margin-right: 39px;
        }
      }
    }
  }
}
