@import '../../../../styles/variables.scss';

.CommunityFeed {
  width: 100%;
  // overflow: hidden;
  max-width: 1284px;
  display: flex;
  flex-direction: column;
  place-self: center flex-start;

  .matching-programs-wrap {
    margin: 30px 0;
  }
  a {
    text-decoration: none;
  }

  .bottom-boundary {
    padding-bottom: 1px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .heading {
    margin-bottom: 30px;
    width: 100%;
    padding: 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    h2 {
      margin: 0 6px 0 0;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      color: black;
    }

    .context-menu-container {
      display: flex;
      flex-flow: row nowrap;
      color: color(light, fontColor);
      transition: color 0.3s ease-in-out;

      .title {
        font-weight: 800;
        font-size: 20px;
        margin-right: 10px;
        line-height: 23px;
        color: color(purple, link);
      }

      .menu-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s ease-in-out;

        &.show {
          transform: rotate(180deg);
        }
      }

      &.show {
        color: color(purple, darker);
      }

      &:hover {
        color: color(purple, darker);
        cursor: pointer;
      }
    }
  }

  .story-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 656px;

    .story-inner {
      position: relative;
      min-height: 196px;
      width: 100%;
      overflow-x: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .main-container {
    width: 100%;
    display: grid;
    grid-template-columns: 268px 1fr 320px;
    column-gap: 16px;
    align-items: flex-start;
    // max-height: calc(100vh - 258px);
    justify-content: center;

    @include thin-scroller-y;

    .post-story-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: 656px;
    }

    .loading-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 42px;
      position: relative;
      z-index: 1;
    }

    .left-aside-container {
      aside {
        display: flex;
        flex-direction: column;
        row-gap: 14px;

        .side-bar {
          background-color: color(light, secondaryBg);
          box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
          box-sizing: border-box;
          border-radius: 8px;
          overflow: hidden;
        }

        .profile-section {
          display: flex;
          flex-direction: column;
          position: relative;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 14px;
          min-height: 400px;

          .cover-img {
            width: 100%;
            background-color: color(purple, base);
            height: 80px;
          }

          a {
            color: #000;

            .profile-avatar-container {
              position: absolute;
              top: 40px;
              left: 95px;
              width: 80px;
              height: 80px;
              border: 2px solid color(light, secondaryBg);
              box-sizing: border-box;
              border-radius: 106.145px;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: color(purple, base);

              .no-profile-img {
                font-size: 24px;
              }

              img {
                width: 80px;
                height: 80px;
              }
            }
          }

          .user-display-name {
            font-style: normal;
            text-decoration: none;
            font-weight: 800;
            font-size: 18px;
            font-feature-settings:
              'pnum' on,
              'lnum' on;
            color: black;
            margin-top: 40px;
            position: relative;
            top: 15px;

            &:hover {
              color: color(purple, darker);
              cursor: pointer;
            }
          }

          .metrics-container {
            width: 90%;
            padding-top: 15px;

            li {
              &:hover {
                cursor: pointer;
                color: color(grey, base);
              }
            }
          }
        }

        .get-started-section {
          padding: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 26px;

          h4,
          .Button {
            width: 100%;
          }

          .Button {
            button {
              background-color: color(light, secondaryBg);
              border: 1px solid color(plum, dark);
              box-sizing: border-box;
              border-radius: 22px;
              width: 100%;
              font-size: 16px;
              text-align: center;
              transition: all 0.2s;
              color: color(plum, dark);

              &:hover {
                color: color(light, secondaryBg);
                background-color: color(plum, dark);
              }
            }
          }
        }
      }
    }

    .right-aside-container {
      .Loader {
        margin: 10px 0;
      }
    }

    .right-aside-container,
    .left-aside-container {
      height: 100%;
      position: relative;
      aside {
        padding-bottom: 24px;
        width: 100%;
        height: max-content;
        position: relative;
        top: 0;
        left: 0;
      }
    }

    .community-feed-empty {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 115px 0;
      border: 1px solid color(dark, borderLight);
      box-sizing: border-box;
      border-radius: 10px;

      a {
        text-decoration: none;
        color: color(light, secondaryBg);
      }

      .empty-label {
        margin-top: 45px;
        font-family: $lato;
        font-weight: 700;
        font-size: 16px;
        padding: 0 24px;
      }
      .empty-description {
        font-family: $lato;
        font-size: 14px;
      }

      .explore-button {
        margin-top: 24px;
        padding: 12px 28px;
        background-color: color(plum, dark);
      }
    }
  }

  .follower-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;

    .title-container {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      b {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 16px;
        color: color(grey, dark);
      }

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: color(grey, lighter);
      }
    }
  }

  .pill-causes {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border: 1px solid color(grey, lightest);
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 12px;
    margin: 16px 10px 0 0;
    padding: 8px 24px 9px;
    cursor: pointer;
  }

  .Modal {
    .StoryModal {
      border-radius: 10px;
      min-width: 700px;
      .story-header {
        height: 66px;
        display: flex;
        align-items: center;
        padding: 0 15px;

        .Portrait {
          margin-right: 15px;
        }
        .entity-title {
          font-weight: 800;
        }
      }

      .story-content {
        border-radius: 10px;
        min-height: 500px;
        background: color(grey, lightester);
        position: relative;
        overflow: hidden;
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        .story-content-background {
          width: 100%;
          height: 100%;
          position: absolute;
          transform: scale(1.2);
        }
      }
    }

    &.LogVolunteerHoursModal {
      .content {
        .modal-close-circle {
          top: 0;
          right: 0;
          padding: 24px;
        }
      }
    }
  }
}

@media (max-width: 1070px) {
  .CommunityFeed {
    padding: 0;
    overflow-y: auto;
    max-width: 100%;

    .heading {
      height: max-content;
      margin: 20px 0 14px 0;
      border-bottom: unset;

      h2 {
        padding-left: 24px;
        font-size: 20px;
      }
    }

    .story-container {
      padding-left: 24px;
      margin-top: unset;
      overflow-y: unset;
      max-height: unset;
      flex-direction: column;
      max-width: 90vw;

      &.hub,
      &.company {
        padding: 0;
      }

      .story-inner {
        display: flex;
        max-width: 100%;
      }
    }

    .main-container {
      width: unset;
      align-items: center;
      justify-content: center;
      grid-template-columns: 1fr;
      padding: 0 24px;
      justify-items: center;

      .right-aside-container,
      .left-aside-container {
        aside {
          display: none;
        }
      }

      .post-story-container {
        margin-top: unset;
        overflow-y: unset;
        max-height: unset;
        flex-direction: column;
        max-width: 90vw;

        .story-container {
          display: flex;
          padding: 0;
          max-width: 100%;
        }
      }

      .posts-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .CommunityFeedPost {
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .CommunityFeed {
    .Modal {
      height: 100%;
      max-height: 100%;
      .StoryModal {
        height: 100%;
        min-width: 100%;

        .story-header {
          position: absolute;
          top: 10px;
          color: #fff;
          z-index: 12;
        }

        .story-content {
          padding: 0;
          height: 100%;
          border-radius: 0;
          .StoriesMain {
            padding: 0;
            .StoryContainer {
              height: 100%;
              width: 100%;
              border-radius: 0;

              &:hover {
                cursor: pointer;
              }
            }
            .left,
            .right {
              display: none;
            }
          }

          .story-color,
          .StoryVideo,
          .StoryImage {
            border-radius: 0 !important; // TODO: fix specificity
          }
        }
      }
    }
  }
  .Modal.prompt-modal {
    .ModalHeader {
      .close-button {
        left: unset;
        right: 8px;
        top: 30px;
        color: #fff;
      }
    }
  }
}
