@import '../../styles/variables.scss';

.ContactDetails {
  flex-direction: column;
  display: flex;
  flex: 1;

  .Modal {
    &.activity-log-modal {
      .content {
        background: #f5f6f7;
        max-width: 800px;
        min-height: 700px;
      }
    }

    &.prompt-modal {
      .content {
        min-height: 0;
        max-width: 400px;
        .prompt {
          padding: 20px 0;
        }
      }
    }
  }

  .cd-stat-row {
    display: flex;
    margin-bottom: 20px;

    .cd-stat-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      flex: 1;
      background-color: #f5f6f7;
      color: color(dark, tertiaryBg);
      border-radius: 8px;
      height: 80px;
      margin: 0 15px;
      padding: 15px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      .stat {
        line-height: 1.2;
        font-size: 28px;
        margin-bottom: 5px;
      }

      .stat-label {
        color: color(grey, base);
        font-size: 16px;
        line-height: 1.1;
        text-align: center;
      }
    }
  }

  .cd-main-menu,
  .cd-content {
    flex: 1;
  }

  .cd-content {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: auto;

    .cd-content-inner {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: flex;
    }
  }

  .cd-main-menu {
    padding: 0px;
    margin: 0px;
    max-height: 70px;
    min-height: 70px;
    border-bottom: 1px solid #e5eff0;
    display: flex;
    list-style-type: none;
    align-items: center;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 20px;
      height: 100%;

      &:first-child {
        padding-left: 40px;
      }

      span {
        position: relative;
        font-weight: 700;
        color: #96a1a6;

        &:hover {
          color: #103645;
          cursor: pointer;
        }
      }

      &.active {
        span {
          display: flex;
          color: #103645;

          &:before {
            position: absolute;
            top: 43px;
            left: 0px;
            display: block;
            content: ' ';
            height: 3px;
            width: 100%;
            background-color: #103645;
          }
        }
      }
    }
  }
}
