@import '../../styles/variables.scss';

.ChangeStatusMenu {
  .current-status {
    min-width: 120px;
    text-align: center;
    border-radius: 50px;
    margin-top: 5px;
    padding: 5px 20px 5px 20px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 14px;
    font-weight: 700;

    i {
      float: right;
      position: absolute;
      right: 15px;
      line-height: 22px;
      display: none;
      caret-color: transparent;

      &.show {
        display: inline-block;
      }
    }

    &:hover {
      i {
        display: inline-block;
      }
    }
  }

  .status {
    &.active,
    &.running {
      background-color: #fff0eb;
      color: #f89979;
    }

    &.published {
      background-color: #eaeaf8;
      color: #6565d3;
    }

    &.draft,
    &.inactive {
      background-color: #e1ebf7;
      color: #095fc4;
    }

    &.completed {
      background-color: #e2f8ef;
      color: #2cbd7f;
    }

    &.planned {
      background-color: #e5f8fa;
      color: color(purple, dark);
    }

    //for all other statuses
    background-color: color(light, primaryBg);
    color: color(grey, base);
  }

  .status-menu {
    position: absolute;
    background-color: white;
    top: 41px;
    right: 20px;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    border-radius: 10px;
    overflow: hidden;
    max-height: 0;
    -webkit-transition: max-height 200ms ease;
    -moz-transition: max-height 200ms ease;
    -o-transition: max-height 200ms ease;
    transition: max-height 200ms ease;

    &.show {
      box-shadow: 0px 0px 27px 5px #7c7c7c41;
      max-height: 500px;
      overflow: unset;
      z-index: 1;
    }

    &::before {
      position: absolute;
      background-color: transparent;
      border: solid;
      border-color: white transparent;
      border-width: 0px 10px 12px 10px;
      content: '';
      right: 15px;
      overflow: unset;
      top: -12px;
    }

    li {
      margin: 5px 0px 5px 0px;
      padding: 10px 20px 10px 20px;
      cursor: pointer;

      span {
        display: inline-block;
        min-width: 100px;
        padding: 7px;
        border-radius: 20px;
      }

      &:hover {
        background-color: whitesmoke;
      }

      &:first-child {
        margin-top: 0px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding-top: 15px;
      }

      &:last-child:hover {
        margin-bottom: 0px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding-bottom: 15px;
      }
    }
  }
}

@media (max-width: 450px) {
  .current-status {
    border: 1px color(light, tertiaryBg) solid;
  }
}
