@import '../../../styles/variables.scss';

.CardLoader {
  width: 100%;

  &.GRID {
    .wrapper {
      width: 100%;
      display: grid;
      place-items: center;
    }
  }

  &.LIST {
    .wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
}
