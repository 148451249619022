@import '../../../styles/variables.scss';

.components-wrap {
  &.dragging {
    .PageComponent {
      .StoreItem {
        &.admin {
          &:hover {
            border: 0;

            .list-admin-actions {
              display: none;
            }
          }
        }
      }
    }
  }
}

.StoreItem {
  margin-bottom: 20px;
  position: relative;
  padding: 20px;
  border: 1px solid color(light, secondaryBg);
  border-bottom: 1px solid color(grey, lighter);
  background-color: color(light, secondaryBg);

  &.hidden {
    .store-item-inner {
      opacity: 0.5;
    }
  }

  .images {
    &.no-images {
      .main {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 5em;
        color: color(light, tertiaryBg);

        &:hover {
          cursor: no-drop;
        }
      }
    }
  }
  .images {
    min-width: 230px;
    max-width: 230px;
    position: relative;
    padding-right: 20px;

    .main {
      height: 250px;
      width: 230px;
      background-color: color(light, primaryBg);
      border-radius: 8px;
      background-position: center center;
      background-size: cover;

      &:hover {
        cursor: pointer;
      }
    }

    .media-nav {
      padding: 10px 0 0;
      margin: 0;
      display: flex;
      list-style-type: none;
      justify-content: center;
      align-items: center;
      flex: 1;

      li {
        margin-left: 8px;

        &:hover {
          color: color(grey);
          cursor: pointer;
        }

        &.active {
          i {
            color: color(grey);
          }
        }

        i {
          font-size: 12px;
          color: color(grey, light);
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .store-image-gallery {
    padding: 40px;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 1;

    .image-title {
      padding-bottom: 20px;

      .total {
        font-size: 14px;
        color: color(grey);
        padding-bottom: 5px;
      }

      .title {
        font-size: 21px;
        font-weight: 700;
      }
    }

    .Loader {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 300px;
    }

    img {
      &.landscape {
        width: 100%;
        height: auto;
        max-height: 70vh;
        max-width: 70vw;
      }

      &.portrait {
        height: 70vh;
        width: auto;
        max-height: 70vh;
        max-width: 70vw;
      }

      &.square {
        height: 100%;
        width: auto;
        max-height: 50vh;
        max-width: 70vw;
      }
    }

    .left,
    .right {
      position: absolute;
      top: 50%;
      height: 50px;
      width: 50px;
      background-color: #fff;
      margin-top: -25px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;

        i {
          color: color(purple, dark);
        }
      }

      i {
        font-size: 24px;
      }
    }

    .left {
      left: 40px;
    }

    .right {
      right: 40px;
    }
  }

  .store-item-inner {
    display: flex;
    flex-direction: row;

    .portrait-wrap {
      .backdrop {
        display: none;
      }

      .Portrait {
        margin-right: 40px;
      }
    }

    .store-item-details {
      flex: 1;

      .item-top-row {
        display: flex;
        flex-direction: row;
        padding-bottom: 20px;

        .title-section {
          display: flex;
          flex: 2;
          flex-direction: column;

          .title {
            font-weight: 700;
            font-size: 21px;
            padding-bottom: 5px;
          }

          .item-price {
            font-size: 18px;
            color: color(grey);
          }

          span.quantity {
            color: color(red, dark);
            padding: 3px 0;
            font-size: 14px;
          }
        }

        .qty {
          display: flex;
          justify-content: center;
          align-items: flex-start;

          .TextField {
            min-width: 40px;

            label {
              display: none;
            }
          }

          .Button {
            margin-left: 20px;
          }
        }
      }

      .item-bottom-row {
        line-height: 28px;
        font-size: 16px;
        color: color(grey);

        .ql-editor {
          padding: 0;
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }

  .list-admin-actions {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background-color: color(purple);
    height: 30px;
    line-height: 30px;

    ul {
      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        color: color(dark, fontColor);
        padding: 0 10px;

        &:hover {
          cursor: pointer;
          background-color: color(light, secondaryBg);

          i,
          span {
            color: color(purple);
          }
        }

        span {
          margin-left: 8px;
        }
      }
    }
  }

  &.admin {
    &:hover {
      border: 1px solid color(purple);

      .list-admin-actions {
        display: block;
      }
    }
  }

  .mobile-store-item {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .store-item-actions {
      min-height: 44px;
      padding: 0 20px 20px;
      display: flex;

      .TextField {
        max-width: 60px;
      }

      .Button {
        margin-left: 10px;
        flex: 1;
        display: flex;

        button {
          border-radius: 5px;
          flex: 1;
        }
      }
    }

    .portrait-wrap {
      position: relative;
      overflow: hidden;
      min-height: 170px;

      .backdrop {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: blur(8px);
        transform: scale(1.5);
        background-color: color(purple, dark);
      }

      .Portrait {
        display: flex;
        margin: 20px 0;
        justify-content: center;
        align-items: center;
      }
    }

    .details {
      padding: 20px;

      .title {
        font-size: 21px;
        padding-bottom: 5px;
      }

      .price {
        font-size: 24px;
        font-weight: 700;
      }
    }

    .description {
      padding: 20px;
      flex: 1;

      .title {
        font-weight: 700;
        height: 30px;
        line-height: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid color(grey, lighter);
        margin-bottom: 10px;
      }

      p {
        padding: 0;
        margin: 0;
        color: color(grey);
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 930px) {
  .StoreItem {
    border: 0;
    padding: 0;
    box-shadow: $box-shadow;
    border-radius: 20px;
    width: 48%;

    .images {
      padding-right: 0;
      flex: 1;
      max-width: 100%;
      min-width: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      overflow: hidden;

      .media-nav {
        display: none;
      }

      .main {
        border-radius: 0;
        width: 100%;
        height: 100%;
        min-height: 250px;
      }
    }

    .store-item-inner {
      flex-direction: column;

      .portrait-wrap {
        position: relative;
        overflow: hidden;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        .backdrop {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: color(purple, dark);

          &.with-image {
            background-color: color(blue, lighter);
          }

          img {
            width: 100%;
            height: 100%;
            filter: blur(4px);
            transform: scale(2.5);
          }
        }

        .Portrait {
          display: flex;
          margin: 20px 0;
          justify-content: center;
          align-items: center;
        }
      }

      .store-item-details {
        .item-top-row {
          flex-direction: column;
          padding: 20px 0;
          border-top: 1px solid color(grey, lighter);

          .qty {
            display: none;
          }

          .title-section {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding-left: 20px;

            .title {
              flex: 1;
              font-weight: 500;
            }

            .item-price {
              font-weight: 700;
            }
          }
        }

        .item-bottom-row {
          display: none;
        }
      }
    }
  }

  .Modal {
    .content {
      height: 100%;
    }
  }
}

@media (max-width: 700px) {
  @supports (-webkit-touch-callout: none) {
    .mobile-store-item {
      padding-bottom: 110px;
    }
  }

  .StoreItem {
    width: 100%;
  }

  //targets safari browsers only
  _::-webkit-full-page-media,
  _:future,
  :root .safari_only {
    margin-bottom: $SAFARI_MOBILE_BOTTOM_MENU_HEIGHT;
  }
}
