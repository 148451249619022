@import '../../styles/variables.scss';

.DiscountCodeForm {
  padding: 15px;

  label {
    font-size: 14px;
    font-weight: 700;
  }

  .row {
    display: flex;
    flex-direction: row;
    margin: 10px 0;

    &.end {
      justify-content: flex-end;
    }

    &.center {
      justify-content: center;
    }

    .status-section {
      width: 50%;
      display: flex;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    padding: 25px 0;
  }

  .subheader {
    padding-bottom: 8px;
    color: color(purple, dark);
    font-size: 18px;
    border-bottom: 1px solid #b6c5cb;
    margin-bottom: 15px;
  }

  form {
    width: 100%;
  }

  .status {
    cursor: pointer;
    flex: 1;
    padding: 10px;
    border: 1px solid #a7d3d9;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #88949a;

    &.chosen {
      color: #fff;
      background-color: color(purple, dark);
    }
  }
}
