@import '../../styles/variables.scss';

.DonationSettingsModal {
  .header-desc {
    .header-description {
      max-width: 90%;
      padding-bottom: 20px;
    }
  }

  &.header-description {
    width: 90% !important;
  }

  .donation-settings-content {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    overflow-y: auto;

    .settings-item {
      display: flex;
      width: 100%;
      padding: 23px 0;
      border-bottom: 1px solid color(grey, lightest);

      &.amounts {
        padding-bottom: 0;
        border-bottom: none;
        flex-direction: column;
      }

      .details {
        padding: 0;
        background-color: transparent;
        font-size: 18px;
        font-weight: 700;
      }

      .detail {
        color: #52646c;
        font-size: 14px;
        font-weight: 400;
        margin: 5px 0;
      }

      .settings-control {
        padding-left: 20px;
        font-size: 35px;
        margin: auto;
        .fad {
          font-size: 30px;
          color: color(purple, dark);

          &:hover {
            cursor: pointer;
          }

          &.fa-toggle-off {
            color: color(grey);
          }
        }
      }

      .donation-amount {
        display: flex;
        margin-top: 20px;
        &:first-child {
          margin-top: 20px;
        }

        .donation-description {
          margin-left: 15px;
          width: 100%;
        }

        .donation-amount-text {
          .TextField {
            flex: 1;

            .input-wrap {
              border-left: 0px;
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;

              &:before {
                content: '$';
                height: 42px;
                width: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              input {
                text-indent: 4px;
                max-width: 100px;
              }
            }
          }
        }

        .index {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          height: 42px;
          min-width: 42px;
          max-width: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: color(light, primaryBg);
          color: color(grey);
          border: 1px solid color(grey, light);
        }
      }
    }

    .controls {
      width: 100%;
      border: none;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 480px) {
  .DonationSettingsModal {
    .donation-settings-content {
      padding: 0 20px;

      .settings-item {
        .donation-amount {
          margin-top: 30px;
          flex-wrap: wrap;

          .donation-description {
            margin: 10px 0 0 0;
            min-width: unset;
            width: 100%;
          }

          .donation-amount-text {
            width: calc(100% - 44px);
            .TextField {
              flex: 1;

              .input-wrap {
                border-left: 0px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;

                &:before {
                  content: '$';
                  height: 42px;
                  width: 22px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                input {
                  min-width: unset;
                }
              }
            }
          }
        }
      }

      .controls {
        width: 100%;
        border: none;
        justify-content: center;
        padding: 30px 0 50px 0;
      }
    }
  }
}
