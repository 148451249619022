@import '../../../styles/variables.scss';

.PopupMenu {
  &.HubAuction-popupMenu-sortBy,
  &.HubAuction-popupMenu-selectCauses {
    min-width: 165px;
  }
}

.HubAuction {
  max-width: 1600px;
  margin: 0 auto;
  min-height: 700px;

  hr {
    border: 1px solid color(grey, lightester);
    margin-bottom: 25px;
  }

  .Loader {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    font-size: 24px;
  }

  .load-more-container {
    margin-top: 80px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .list-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 41px;
    column-gap: 16px;
  }
}

@media (max-width: 900px) {
  .HubAuction {
    padding: 0;

    .HubToggleButtons {
      .Button {
        button {
          padding: 0 20px;
        }
      }
    }
  }
}
