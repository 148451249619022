.CollapsibleSection {
  .content-parent {
    height: 0px;
    overflow: hidden;
    transition: height ease 250ms;

    .collapsible-content {
      padding: 0.5rem;
    }
  }

  .content-show {
    height: 200px;
  }
}
