@import '../../../styles/variables.scss';

/** Extracted component from HubHome **/
.ContentBlockContainer {
  display: flex;
  padding: 40px 0;
  height: 100%;
  gap: 40px;

  &.preview-mode {
    padding: 20px;

    .content-container {
      // transform: scale(0.60);
      .title {
        font-size: 14px;
        margin-bottom: 5px;
      }
      .block-content {
        .text-block {
          font-size: 10px;
          line-height: 24px;
          text-shadow: none;
        }
      }
    }
  }

  .content-container {
    width: 100%;
    flex: 1;
    display: flex;
    flex-flow: column nowrap;

    .title {
      font-weight: 700;
      font-size: 28px;
      font-feature-settings:
        'pnum' on,
        'lnum' on,
        'salt' on,
        'ss09' on;
      color: #3a3a3a;
      margin: 0 0 20px 0;
      font-family: 'Raleway', sans-serif;
    }

    .block-content {
      flex: 1;
      display: flex;
      flex-flow: row nowrap;
      max-height: 500px;
      align-items: center;
      justify-content: center;

      &.has-title {
        align-items: unset;
        justify-content: unset;
      }
      .text-block {
        font-size: 16px;
        color: color(grey, dark);
        line-height: 24px;

        .ql-editor {
          padding: 0;
        }

        a {
          font-weight: 700;
          text-decoration: none;

          &:hover {
            cursor: pointer;
            color: color(purple, light);
          }
        }
      }

      .image-block {
        // overflow: hidden;
        width: 100%;
        min-width: 200px;
        img {
          width: 100%;
          background: color(grey, imgBg);
          border-radius: 15px;
        }
      }

      .image-block-placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        background-color: color(grey, lighter);
        border-radius: 10px;
        min-height: 200px;

        i {
          color: color(grey, dark);
          font-size: 25px;
        }
      }
    }
  }

  .metrics-container {
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    row-gap: 79px;
    column-gap: 80px;
    margin: 0;
    border: unset;

    .SingleMetric {
      align-items: flex-start;
      text-align: left;
      width: 170px;
      margin: 0;

      .title {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  .min-img-container {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 50px;
    row-gap: 32px;

    div {
      width: 114px;
      height: 114px;
      border: 2px solid color(grey, pale);
      box-sizing: border-box;
      border-radius: 100px;
      overflow: hidden;

      img {
        width: 114px;
        height: 114px;
        background-color: color(grey, pale);
      }
    }
  }
}

@media (max-width: 700px) {
  .ContentBlockContainer {
    flex-direction: column;
  }
}
