@import '../../../../styles/variables.scss';

.AuctionItemLeaderboardModal {
  flex: 1;
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;

  .leader-actions {
    padding: 0px 40px 40px;
    margin-bottom: 100px;

    .Button {
      button {
        width: 100%;
      }
    }
  }

  .leaders {
    padding: 20px 40px 0px;
    display: flex;
    flex-wrap: wrap;
    margin: 0px;

    .no-bids {
      padding: 30px 0px 60px;
      color: color(grey);
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .leader {
      display: flex;
      width: 50%;
      position: relative;
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid color(grey, light);

      &.no-border {
        border-bottom: 0px;
      }

      .leader-details {
        margin-left: 20px;

        .name {
          font-size: 18px;
          padding-right: 0px;
        }

        .bid {
          font-size: 16px;
          font-weight: 500;
          margin: 2px 0px;
        }

        .count {
          font-size: 14px;
          color: color(grey);
        }
      }

      .place {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: color(purple, dark);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        color: color(dark, fontColor);
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 680px) {
  .AuctionItemLeaderboardModal {
    .leaders {
      .leader {
        width: 100%;
      }
    }
  }
}
