@import '../../styles/variables.scss';

.CampaignManagement-ReceiptPreviewTooltip {
  .hint-title {
    text-align: center;
    font-size: 13px;
    color: color(grey);
    font-weight: 300;
  }

  .hint-example {
    display: flex;
    flex: 1;
    margin: 10px 0;
    background-color: color(grey, lighter);
    padding: 10px;
    border-radius: 8px;
    height: 15px;
    line-height: 15px;

    .prefix,
    .padding,
    .starting {
      text-align: center;
      flex: 1;
    }

    &.labels {
      background-color: transparent;
      font-size: 12px;
      color: color(grey);
      font-weight: 300;
      margin: 0;
      padding: 0;
    }
  }
}

.CampaignManagement {
  flex: 1;
  display: flex;

  .section-title {
    display: flex;

    .forms-title {
      flex: 1;
    }

    .forms-create {
      .form-buttons {
        display: flex;
        flex-direction: row;
      }
      .Button {
        button {
          box-shadow: $box-shadow;
        }
      }
    }
  }

  .modal-title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: color(purple, dark);
    border-bottom: 1px solid #eaeff0;
    padding-bottom: 8px;
  }

  .CampaignManagement-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    .search {
      display: flex;
      align-items: center;

      .create {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }

      .TextField {
        max-width: 50%;

        i {
          background-color: color(light, secondaryBg);
          color: color(light, fontColor);
        }

        input {
          background-color: color(light, secondaryBg);
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      flex: 1;

      .headers,
      .row {
        .col {
          display: flex;
          flex: 1;
          font-weight: 700;

          &.default {
            justify-content: center;
            max-width: 120px;

            i {
              color: color(grey, light);
              font-size: 18px;

              &.is-default {
                color: color(purple, dark);
              }

              &:hover {
                cursor: pointer;
                color: color(purple);
              }
            }
          }

          &.status {
            span {
              padding: 4px 8px;
              border-radius: $border-radius;
              &.active,
              &.approved {
                background-color: #eafcf5;
                color: #067f5c;
              }

              &.declined,
              &.closed,
              &.rejected {
                color: #cf2035;
                background-color: #fce9ea;
              }

              &.pending,
              &.disabled,
              &.draft {
                background-color: #f3f4f9;
                color: #686c8b;
              }
            }
          }

          &.account {
            span {
              padding: 4px 8px;
              border-radius: $border-radius;
              &.account {
                background-color: #fff2d8;
                color: #9f6000;
              }

              &.no-account {
                background-color: #f3f4f9;
                color: #686c8b;
              }
            }
          }

          &:first-child {
            text-indent: 8px;
          }

          &.title {
            flex: 2;
          }

          &.created,
          &.updated {
            width: 400px;

            span {
              font-weight: 400;
              margin-right: 8px;

              &:last-child {
                color: color(grey);
                font-weight: 400;
              }
            }
          }

          &.actions {
            justify-content: flex-end;
            padding-right: 40px;
            position: relative;
            max-width: 80px;

            .ContextMenu {
              display: none;
              position: absolute;
              top: 55px;
              right: 10px;
              box-shadow: $box-shadow;
              border-radius: 20px;

              i {
                font-size: 16px;
                padding-right: 8px;
              }

              &:hover {
                display: block;
              }
            }

            i.fa-ellipsis-h-alt {
              font-size: 24px;
              height: 40px;
              width: 40px;
              background-color: color(blue, lightest);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }

            i.fa-ellipsis-h-alt:hover + .ContextMenu {
              display: block;
            }
          }
        }
      }

      .headers {
        background-color: color(blue, lightest);
        display: flex;
        flex: 0;
        min-height: 40px;
        height: 40px;
        line-height: 40px;

        .col {
          white-space: nowrap;
        }
      }

      .list-inner {
        flex: 1;
        max-height: 100%;
        overflow: auto;
        position: relative;

        .list-rows {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          .empty {
            flex: 1;
            padding: 30px 20px;
            text-align: center;
            color: color(grey);
            font-size: 16px;
          }
        }

        .row {
          display: flex;
          height: 60px;
          border-bottom: 1px solid color(grey, light);

          .col {
            font-weight: 400;
            align-items: center;
          }
        }
      }
    }
  }

  .CreateCampaign {
    padding: 40px;
    overflow: auto;

    .form {
      .prefix {
        .TextField {
          label {
            flex: unset;
          }
        }

        .title {
          display: inline-block;
          position: relative;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 4px;

          i {
            margin-left: 6px;
            position: relative;

            &:hover {
              cursor: pointer;
              color: color(purple, dark);
            }
          }
        }

        .sub {
          font-size: 14px;
          color: color(grey);
          margin-bottom: 20px;
        }

        .empty-prefix-err {
          margin-top: 10px;
          color: color(red, error);

          i {
            margin-right: 5px;
          }
        }

        .prefix-input {
          display: flex;
          align-items: flex-start;

          .TextField {
            margin-right: 10px;
          }
        }

        .current {
          font-size: 14px;
          color: color(grey);
          padding: 5px 0 0;
        }
      }
    }

    .title-row {
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 20px;
      border-bottom: 1px solid color(blue, lighter);
      color: color(purple, dark);
      padding-bottom: 8px;

      &.add {
        margin-top: 15px;
      }
    }

    .form-field {
      margin: 20px 0;

      &.title-amt {
        display: flex;

        .TextField {
          &:first-child {
            margin-right: 10px;
          }
        }
      }

      &.status {
        label {
          font-size: 13px;
          font-weight: 700;
        }
      }

      .status-section {
        display: flex;
        flex-direction: row;
        padding-top: 4px;

        .status {
          cursor: pointer;
          flex: 1;
          padding: 10px;
          border: 1px solid #a7d3d9;
          border-right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #88949a;

          &:last-child {
            border-right: 1px solid #a7d3d9;
          }

          &.chosen {
            color: #fff;
            background-color: color(purple, dark);
          }
        }
      }

      &.campaign-dates {
        display: flex;
        flex-direction: row;

        .end-date,
        .start-date {
          margin: 5px 0;
        }

        .end-date {
          margin-left: 10px;
        }

        div {
          flex: 1;
        }
      }

      &.funds-row {
        .Button {
          align-self: flex-end;
          margin-left: -5px;
        }
      }

      &.selected-funds {
        .funds-subheader {
          font-size: 13px;
          font-weight: 700;
          padding-bottom: 4px;
        }

        .fund {
          display: inline-block;
          border-radius: 8px;
          padding: 4px 12px;
          background-color: #eaeff0;
          font-size: 14px;
          font-weight: 700;
          margin-right: 4px;

          i {
            font-size: 12px;
            margin-left: 4px;

            &:hover {
              cursor: pointer;
              color: color(purple, dark);
            }
          }
        }
      }

      &.actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    .funds-row {
      display: flex;
      flex-direction: row;
      align-content: center;

      .Button {
        margin-left: -8px;

        button {
          border-radius: 0;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          min-height: 44px;
        }
      }
    }
  }
}
