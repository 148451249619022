@import '../../../../styles/variables.scss';

.StoryContainer {
  .StoryVideo,
  .StoryImage,
  .story-color {
    .textbox {
      line-height: 24px;
      font-family: $raleway;
      padding: 10px;
      font-weight: 800;
      white-space: pre-line;

      p {
        margin: 0;
      }
    }
  }
  .sound-icon {
    z-index: 2;
    position: absolute;
    top: 6%;
    right: 11px;

    &:hover {
      cursor: pointer;
      color: color(grey, lightester);
    }
  }
  .toggle-playing-state {
    z-index: 2;
    position: absolute;
    top: 6%;
    right: 40px;
    color: #fff;
    font-size: 13px;

    &:hover {
      cursor: pointer;
      color: color(grey, lightester);
    }
  }

  .metric-box {
    text-align: center;
  }
  .metric-box,
  .call-to-action-container,
  .textbox {
    line-height: 24px;
    font-family: $raleway;
    padding: 10px;
    font-weight: 800;

    p {
      margin: 0;
    }
    .text-inner {
      line-height: 24px;
      font-family: $raleway;
      padding: 10px;
      font-weight: 800;
    }
    button {
      border: none;
      line-height: 24px;
      font-family: $lato;
      padding: 10px;
      font-weight: 800;
      color: #fff;
      border-radius: $border-radius;
      padding: 8px 28px;
    }
  }
  .panel-actions {
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;

    .panel-left,
    .panel-right {
      flex: 1;
    }
  }
}
