@import '../../styles/variables.scss';

.CommunityImpact {
  .section-inner {
    flex-direction: column;

    .date-range {
      margin-bottom: 30px;
      flex: 1;

      .date-wrap {
        display: flex;

        .divider {
          margin: 0px 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
        }

        .start,
        .end {
          display: flex;

          .Dropdown {
            min-width: 150px;
            margin-right: 8px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }

    .data-wrap {
      display: flex;
      flex: 1;

      .charts {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        .chart-wrap {
          padding: 20px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          flex: 1;
          background-color: color(cream, lightest);
          margin-bottom: 30px;
          border: 2px solid #ccd5d8;

          &:last-child {
            margin-bottom: 0px;
          }

          .chart-title {
            margin-bottom: 8px;
            color: color(grey);
            font-weight: 700;
            font-size: 14px;
          }

          .chart {
            position: relative;
            flex: 1;
          }
        }
      }

      .metrics {
        max-width: 300px;
        min-width: 300px;
        margin-left: 30px;
        display: flex;
        flex-direction: column;

        .metric {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          padding: 20px;
          border-radius: 8px;
          background-color: color(cream, lightest);
          border: 2px solid #ccd5d8;
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0px;
          }

          .number,
          .label {
            height: 29px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .number {
            font-size: 24px;
            font-weight: 700;
          }

          .label {
            margin-left: 20px;
            font-size: 16px;
            color: #55646b;
            font-weight: 700;
            width: 100px;
          }
        }
      }
    }
  }
}
