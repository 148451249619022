@import '../../../styles/variables.scss';

.conversation-input {
  position: absolute;
  bottom: 0;
  overflow-y: auto;
  width: 100%;
  max-height: 500px;
  background: color(light, secondaryBg);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  .ql-toolbar {
    background-color: color(purple, lightest);
    border: none;
    padding: 2px 8px;
  }

  .TextArea {
    label textarea {
      line-height: 30px;
      font-size: 14px;
    }
  }

  .Button {
    button {
      width: 48px;
    }
  }
}
