@import '../../../../styles/variables.scss';

.HubManagementAnalytics {
  padding: 60px;

  h2 {
    font-family: $raleway;
    font-style: normal;
    font-weight: 700;
    font-size: 39px;
    margin: 0 0 40px 0;
    color: color(grey, dark);
  }

  .analytics-tabs {
    width: 100%;

    ul {
      width: 100%;
      list-style: none;
      border-bottom: 1px solid color(grey, border);
      height: 40px;
      padding: 0;

      li {
        float: left;
        margin-right: 40px;
        height: 40px;
        border-bottom: 1px solid color(grey, border);
        transition: all 0.2s;
        cursor: pointer;

        &.active {
          border-bottom-width: 2px;
          border-bottom-color: color(plum, dark);
        }
      }
    }
  }
}
