@import '../../../styles/variables.scss';

.LinkButton {
  flex: 1;
  text-decoration: none;
  display: flex;
  border: 0px;
  padding: 0px;
  font-size: 16px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  color: color(dark, fontColor);
  background-color: color(purple, dark);
  overflow: hidden;
  min-width: 80px;
  min-height: 40px;
  font-weight: 700;

  &:hover {
    background-color: color(purple, darker);
  }

  margin-top: 14px;
}
