@import '../../../styles/variables.scss';

.CustomDropdown {
  display: flex;
  flex-direction: column;

  &:hover {
    pointer-events: none;
  }

  .input-wrap {
    position: relative;
    border: 1px solid color(grey, lighter);
    border-radius: 7px;
    display: flex;

    .label {
      pointer-events: all;
    }

    .fa-caret-down {
      pointer-events: all;
      color: #012939;
    }

    &.disabled {
      color: color(grey, lighter);

      .label {
        pointer-events: none;
      }

      .fa-caret-down {
        color: color(grey, lighter);
        pointer-events: none;
      }
    }

    &.showError {
      border: 1px solid color(red, error);
    }

    .align-icons {
      display: flex;
      flex-direction: row;

      .input-error {
        padding-right: 16px;
      }
    }

    &:hover {
      pointer-events: none;

      &.focused {
        border: 1px solid #012939;
      }
    }

    input {
      border: none;
      outline: none;
      border-width: 0px;
      font-weight: 700;
      font-size: 16px;
      color: #012939;
      font-family: 'Lato', sans-serif;

      &:hover {
        pointer-events: all;
      }

      &.disabled {
        &::placeholder {
          color: color(grey, lighter);
        }
      }
    }
  }

  .showError {
    color: color(red, error);
    font-size: 12px;
    padding-top: 5px;
  }

  .focused {
    border: 1px solid #012939;
  }

  label {
    display: flex;
    flex: 1;
    padding-bottom: 4px;
    color: #3a3a3a;

    &.disabled {
      color: color(grey, lighter);
    }

    &.showError {
      color: color(red, error);
    }

    span {
      font-size: 13px;
      font-weight: 700;
    }

    .required {
      color: #3a3a3a;

      &.disabled {
        color: color(grey, lighter);
      }
    }
  }

  img {
    &.disabled {
      opacity: 0.5;
    }

    &.hide-icon {
      display: none;
    }
  }
}
