@import '../../../../styles/variables.scss';

.HubAuctionSimplifiedView {
  padding: 0 25px;

  .section-content {
    .Carousel {
      .HubAuctionSingleItem {
        .ListCard {
          width: 256px;
          height: 340px;

          .card-content {
            padding: 0;
          }

          .auction-item-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 8px 12px 0 12px;
            row-gap: 8px;

            span {
              margin: 0;
            }
          }

          .desc {
            height: 40px;
          }

          .cover-img {
            height: 210px;
            max-height: 210px;
            max-width: 320px;
          }
        }
      }
    }
  }
}
