@import '../../styles/variables.scss';

.Team {
  flex: 1;
  padding: 60px 0px;
  position: relative;
  color: color(light, fontColor);
  background-color: color(light, primaryBg);

  &.content-creator {
    .sub-header {
      position: absolute;
      top: 50px;
      left: 0px;
      height: 90px;
      width: 100%;
      z-index: 3;
      transition: background-color 100ms ease-in-out;

      &.fixed {
        position: fixed;
        top: 80px;
        background-color: #fff;
        box-shadow: $box-shadow;

        .sub-header-inner {
          box-shadow: none;
        }
      }

      .sub-header-inner {
        max-width: $wrap-size;
        margin: 0px auto;
        height: 100%;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        box-shadow: $box-shadow;

        .row {
          flex: 1;
          display: flex;

          &.left {
            padding-left: 40px;

            .streamer-details {
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-left: 10px;

              .user-type {
                font-size: 13px;
                font-weight: 700;
                letter-spacing: 0.5px;
                display: flex;
                align-items: center;

                i {
                  margin-right: 4px;
                  font-size: 10px;
                  color: #88949a;
                }
              }

              .name {
                padding: 0px;
                margin: 0px;
                font-size: 24px;
              }
            }
          }

          &.right {
            padding-right: 40px;
            justify-content: flex-end;

            .text-buttons {
              display: flex;
              margin-left: 10px;
              align-items: center;

              .Button {
                flex: 1;
                display: flex;

                &.manage {
                  margin-right: 10px;

                  button {
                    background-color: color(purple, dark);
                  }
                }

                button {
                  display: flex;
                  flex: 1;
                  box-shadow: $box-shadow;
                  min-width: 140px;
                }
              }

              a {
                font-weight: 500;
                text-decoration: none;
                display: flex;
                border: 0px;
                font-size: 16px;
                border-radius: 20px;
                justify-content: center;
                align-items: center;
                color: color(dark, fontColor);
                background-color: color(purple);
                overflow: hidden;
                min-height: 40px;
                box-shadow: $box-shadow;
                min-width: 140px;
                background-color: color(purple, dark);

                &.manage {
                  background-color: color(purple, dark);
                  margin-right: 10px;
                }

                &:hover {
                  background-color: color(purple);
                }
              }
            }

            .Button {
              &.share {
                button {
                  min-width: 40px;
                  height: 40px;
                  width: 40px;
                  border-radius: 50%;
                  padding: 0px;
                  margin: 0px;
                  background-color: #eeeff0;
                  color: #52646c;
                  justify-content: center;
                  align-items: center;

                  .icon {
                    width: 14px;
                    height: 16px;
                    margin-right: 0px;
                  }

                  &:hover {
                    background-color: color(purple, dark);
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }

    .stream-embed {
      position: relative;
      margin: 80px auto;
      max-width: 1200px;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      overflow: hidden;
      box-shadow: $box-shadow;
    }
  }

  .Modal {
    .Login {
      background-image: none;
      background-color: transparent;

      &:before {
        content: none;
      }
    }

    .confirm {
      padding: 20px;
      flex: 1;

      p {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
      }

      .confirm-actions {
        display: flex;
        flex: 1;
        justify-content: center;

        .Button {
          &:first-child {
            margin-right: 10px;

            button {
              background-color: color(red, dark);
            }
          }

          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }

    .fundraising-registration {
      max-width: 500px;
      padding: 30px;
      display: block;
      flex: 1;
      max-height: 100%;
      overflow: auto;

      .Store {
        display: block;
        max-width: none;
      }

      .StoreItem {
        box-shadow: none;
        padding: 20px 0px;
        margin: 0px;

        .store-item-inner {
          flex-direction: column;

          .item-top-row {
            flex-direction: column;
            padding: 20px;

            .qty {
              padding: 0px !important;
            }
          }

          .item-bottom-row {
            display: none;
          }

          .portrait-wrap {
            position: relative;
            overflow: hidden;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;

            .backdrop {
              display: block;
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              background-color: color(purple, dark);

              &.with-image {
                background-color: color(blue, lighter);
              }

              img {
                width: 100%;
                height: 100%;
                filter: blur(4px);
                transform: scale(2.5);
              }
            }

            .Portrait {
              display: flex;
              margin: 20px 0px;
              justify-content: center;
              align-items: center;
            }
          }

          .store-item-details {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            background-color: color(blue, lightest);

            .item-top-row {
              .qty {
                display: flex;
                margin: 20px 0px 0px;
                padding: 0px 20px;

                .TextField {
                  max-width: 50px;
                  input {
                    background-color: color(light, secondaryBg);
                  }
                }

                .Button {
                  flex: 1;
                  margin-left: 10px;

                  button {
                    width: 100%;
                    height: 44px;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
        }
      }

      .fundraising-title {
        font-size: 24px;
        display: flex;
        justify-content: flex-start;
        font-weight: 700;
      }

      .fundraising-handle {
        color: color(grey);
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 30px;
        border-bottom: 1px solid color(grey, lighter);
      }
    }

    .content {
      .Login {
        background-image: none;

        &:before {
          content: none;
        }
      }
    }
  }

  .back-splash {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 440px;
    background-color: color(light, tertiaryBg);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      filter: blur(40px);
      transform: scale(1.3);
      width: 100%;
      height: auto;
    }

    .curve {
      background-color: color(light, primaryBg);
    }
  }
}

@media (max-width: 1240px) {
  .Team {
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0px;
    border-bottom: 1px solid color(grey, lighter);

    .BackSplash {
      height: 250px;

      img {
        filter: blur(0px);
      }
    }

    .TeamDefault {
      // Allow pointer events when join modal is open
      &:not(.allow-action) {
        pointer-events: none;
      }
    }

    .Team-content {
      flex-direction: column;

      .sidebar {
        width: auto;
        margin-right: 0px;

        .sidebar-inner {
          background-color: transparent;
          max-width: 500px;
          box-shadow: none;
          margin: 90px auto 0px;
          padding: 0px;
          pointer-events: all;

          .sidebar-content {
            .sidebar-info {
              text-align: center;

              .name {
                i {
                  display: none;
                }
              }
            }
          }
        }
      }

      .main {
        pointer-events: all;
        .CoverImage {
          display: none;
        }

        .Team-main-content {
          border-radius: 0px;
          margin-top: 0px;
        }
      }
    }
  }
}

@media (max-width: 825px) {
  .text-buttons {
    .manage {
      display: none;
    }
  }
}

@media (max-width: 540px) {
  .Team {
    .Team-content {
      .main {
        .Team-main-content {
          .inner-content {
            padding: 10px;
          }

          .members .members-wrap,
          .donations .donations-wrap {
            .member,
            .donation {
              width: auto;
              flex: 1;
              border-right: 0px !important;
              border-left: 0px !important;
            }
          }

          .team-info {
            flex-direction: column;
            width: auto;

            .sect {
              width: auto;
              border-right: 0px;
              justify-content: center;
              margin-bottom: 20px;

              &:last-child {
                margin-bottom: 0px;
              }

              .team-content {
                width: 100px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .Team {
    .Team-content {
      .sidebar {
        .sidebar-inner {
          .sidebar-content {
            .sidebar-actions {
              flex-direction: column;

              a,
              .Button {
                margin-left: 0px;
                margin-right: 0px;
              }

              a {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}
