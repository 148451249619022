@import '../../../styles/variables.scss';

.FloatingActionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background-color: color(purple, base);

  i {
    color: white;
    font-size: 20px;
    font-weight: 100;
  }
}
