@import '../../styles/variables.scss';

.not-found {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    text-align: center;
    max-width: 100%;
    padding: 40px 0px;

    img {
      max-width: 100%;
    }

    .title {
      font-size: 51px;
      font-weight: 700;
      padding: 25px 0px;
      color: #1b1931;
    }

    p {
      padding: 0px;
      margin: 0px;
      font-size: 18px;
      color: color(purple, darkest);
      line-height: 24px;

      a {
        text-decoration: none;
        color: color(purple, dark);

        &:hover {
          color: color(purple, darker);
        }
      }
    }

    .home {
      width: 140px;
      margin: 25px auto 0px;
      height: 44px;
      display: flex;
      border: 0px;
      padding: 0px 28px;
      font-size: 16px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      color: color(dark, fontColor);
      background-color: color(purple, dark);
      overflow: hidden;
      min-width: 80px;
      min-height: 40px;
      box-shadow: $box-shadow;
      text-decoration: none;
      font-weight: 700;

      &:hover {
        background-color: color(purple, darker);
      }
    }
  }
}

@media (max-width: 500px) {
  .not-found {
    .content {
      padding: 0px 10px;

      .title {
        font-size: 31px;
      }

      p {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
