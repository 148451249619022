@import '../../styles/variables.scss';

.ContactsList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .Portrait {
    &:hover {
      cursor: pointer;
    }
  }

  .convo-li {
    flex: 1;

    .convo-li-title {
      width: max-content;

      &:hover {
        cursor: pointer;
        color: color(purple, dark);
      }
    }
  }

  .Loader {
    display: flex;
    align-self: center;
    margin: auto 0;
    font-size: 24px;
  }

  .action-menu-target {
    flex: 0;
    margin-right: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  .no-contacts {
    display: flex;
    flex-direction: column;
    font-weight: 900;
    font-size: 12px;
    color: #5a5b5d;
    align-self: center;
    align-items: center;
    margin: auto 0;
  }
}
