@import '../../../styles/variables.scss';

.ConversationActions {
  position: relative;
  i {
    margin: 0 3px;
    cursor: pointer;
  }

  .actions {
    position: relative;
    margin-right: 10px;

    .ContextMenu {
      display: none;
      position: absolute;
      top: 52px;
      right: -26px;
      box-shadow: $box-shadow;
      border-radius: 20px;
      animation: 300ms menuSlideDown ease-in-out;

      i {
        font-size: 16px;
        padding-right: 8px;
      }

      &:hover {
        display: block;
      }
    }

    i.fa-ellipsis-h-alt {
      font-size: 24px;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      &:hover {
        cursor: pointer;
        color: color(purple, dark);
      }
    }

    i.fa-ellipsis-h-alt:hover + .ContextMenu {
      display: block;
    }
  }

  .context-menu {
    height: 309px;
    width: 244px;
    background: color(dark, primaryBg);
    border-radius: $border-radius;
    position: absolute;
    top: -15px;
    left: -282px;
    padding: 15px;
    z-index: 3;

    .current-conversations-list {
      .convo-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;
        height: 175px;

        ul {
          list-style-type: none;
          padding-left: 0;
          width: 100%;

          li {
            background-color: #32393c;
            display: flex;
            align-items: center;
            padding: 8px;
            margin: 3px 0;
            border-radius: 5px;
            width: 100%;

            .manage-actions {
              align-self: flex-end;
              justify-self: flex-end;
            }
          }
        }
      }
    }
  }
}
