@import '../../../styles/variables.scss';

.ChildEventList {
  position: relative;
  overflow: hidden;

  h3 {
    margin: 0px;
    padding: 0px 20px 20px;
  }

  .EventList-actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-bar {
    max-height: 45px;
    padding: 0px 20px;
  }

  .TextField.search-bar {
    margin-bottom: 20px;
  }
}

@media (max-width: 930px) {
  .ChildEventList {
    h3 {
      padding: 0px 0px 20px;
    }
  }
}

@media (max-width: 560px) {
  .ChildEventList {
    .ComponentPlaceholder {
      .action-container {
        display: flex;
        flex-direction: column;

        .Button,
        .Button button {
          margin: 0px;
          min-width: 200px;
        }

        .Button {
          &:first-child {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}

@media (max-width: 415px) {
  .ChildEventList {
    .search-bar {
      margin-right: 15px;
    }
  }
}
