@import '../../../../styles/variables.scss';

.VerifyUser {
  flex: 1 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 50px 80px;

  h1 {
    font-weight: 900;
    font-family: $raleway;
  }

  p {
    margin: 0;
    font-family: $lato;
    font-weight: 400;
  }

  .verify-input {
    margin: 50px 0;
    width: 100%;
  }

  .actions {
    position: relative;
    font-family: $lato;
    font-weight: 400;
    cursor: default;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      margin-bottom: 124px;
      transition: all 0.2s;

      &.disabled {
        opacity: 0.4;

        a {
          cursor: no-drop;

          &:hover {
            color: color(purple);
          }
        }
      }

      a {
        text-decoration: none;
        color: color(purple);
        cursor: pointer;

        &:hover {
          color: color(purple, darkest);
        }
      }
    }
  }

  .TextField {
    &.valid {
      .input-wrap {
        border: 1px solid green;
      }
    }

    &.invalid {
      .input-wrap {
        border: 1px solid red;
      }
    }
  }
}

@media (max-width: 680px) {
  .VerifyUser {
    padding: 15px;
    justify-content: flex-start;

    .verify-input {
      margin: 30px 0;
    }

    .actions {
      span {
        margin-bottom: 94px;
      }
    }
  }
}
