@import '../../styles/variables.scss';

.event-container {
  .card-header {
    .no-cover {
      background-image: url('../../assets/no-cover-img.svg') !important;
    }
  }

  .event-content {
    .card-title {
      display: flex;

      a {
        text-decoration: none;
        font-family: $raleway;
        margin-bottom: 2px;
        margin-right: 10px;
        font-weight: 800;
        font-size: 16px;
        color: color(grey, dark);
        transition: color 0.2s;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 300px;

        &:hover {
          color: color(plum, dark);
        }
      }
    }

    .event-description {
      text-decoration: none;
      font-family: $lato;
      margin-bottom: 2px;
      margin-right: 10px;
      margin-top: 12px;
      font-weight: 500;
      font-size: 12px;
      color: #6f7072;
      text-overflow: ellipsis;
      max-width: 300px;
      line-height: 18px;
      max-height: 55px;
      overflow: hidden;
    }

    .no-desc {
      color: #d0d1d1;
      font-weight: 500;
      font-size: 12px;
      font-family: $lato;
      line-height: 18px;
    }

    .date-location {
      font-family: $lato;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: color(purple, grey);
      margin-top: 10px;
      position: absolute;
      top: 117px;

      span {
        display: flex;
        margin-bottom: 8px;

        .icon {
          margin-bottom: 0;
          margin-right: 15px;
          align-items: center;
          justify-content: center;
          display: flex;
          width: 12px;
          height: 15px;

          i {
            margin-top: 3px;
          }
        }
      }

      a {
        text-decoration: none;
        color: #6f7072;
      }
    }

    .progress-bar-wrap {
      margin: 38px 0 0;

      .progress-bar-bg {
        position: relative;
        background-color: #f1f1f0;
        border-radius: 5px;
        height: 10px;
        display: flex;
        align-items: center;
        width: 300px;

        .progress-bar {
          background: linear-gradient(270deg, #3b307a -0.19%, #5e51ab 100%);
          width: 0;
          height: 10px;
          border-radius: 5px;
        }

        .amt,
        .goal {
          position: absolute;
          color: #6f7072;
          font-weight: 700;
          font-size: 12px;
          font-family: $lato;

          span {
            margin-left: 5px;
          }
        }

        .amt {
          top: -20px;
          left: 0;
        }

        .goal {
          top: -20px;
          right: 0;
        }
      }
    }

    .hub-causes {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 90px;

      .title {
        color: color(purple, grey);
        font-family: $lato;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
      }

      .causes-container {
        display: flex;
        flex-direction: row;
        margin-right: 10px;
        margin-left: 10px;

        &:nth-child(1) {
          z-index: 1;
        }
        &:nth-child(2) {
          z-index: 2;
        }
        &:nth-child(3) {
          z-index: 3;
        }
        &:nth-child(4) {
          z-index: 4;
        }

        .Portrait,
        .empty-portrait {
          margin-left: -10px;
        }

        .empty-portrait {
          background: #ffffff;
          border: 2px solid #f5f5f5;
          box-sizing: border-box;
          width: 34px;
          height: 34px;
          border-radius: 15px;
          display: flex;
          align-items: center;
          font-family: $lato;
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          justify-content: center;
          color: color(grey, light);
          z-index: 5;
        }

        .no-causes {
          border-radius: 50%;
          width: 30px;
          height: 30px;
          background-color: #e9e9e8;
          margin-left: -10px;
          border: 2px solid #f5f5f5;

          &:nth-of-type(1) {
            z-index: 1;
          }
          &:nth-of-type(2) {
            z-index: 2;
          }
          &:nth-of-type(3) {
            z-index: 3;
          }
          &:nth-of-type(4) {
            z-index: 4;
          }
          &:nth-of-type(5) {
            z-index: 5;
          }
        }
      }
    }
  }

  &.DISCOVER {
    margin-right: 0px;
    height: 474px;
    width: 383px;
    margin-top: 45px;

    .card-header {
      max-height: 171px;
    }
  }

  &.HUB {
    height: 474px;
    max-width: 330px;
    margin-top: 45px;

    .card-header {
      max-height: 171px;
    }

    .card-content {
      .progress-bar-wrap {
        width: 270px !important;

        .progress-bar-bg {
          width: inherit !important;
        }
      }
    }
  }

  &.MANAGE {
    width: 320px;
    height: 380px;

    .card-header {
      max-height: 151px;
    }

    .profile-img {
      width: 36px;
      height: 36px;
    }

    .event-content {
      padding-right: 32px;

      .date-location {
        top: 61px;
      }

      .state-label {
        position: absolute;
        top: 170px;
        border-radius: 8px;
        padding: 6px 12px;
        font-weight: 700;
        text-align: center;
        font-size: 14px;
        cursor: default;
        width: fit-content;

        &.active,
        &.running {
          background-color: #eef9f7;
          color: #57c0ac;
        }

        &.published {
          background-color: #eaeaf8;
          color: #6565d3;
        }

        &.draft,
        &.pending {
          background-color: #fef8ed;
          color: #f1b94d;
        }

        &.undefined {
          display: none;
        }
        //any unknown states

        background-color: color(light, primaryBg);
        color: color(grey, base);
      }

      // .menu {

      //     position: absolute;
      //     top:170px;
      //     right:0;

      // }
      .menu {
        border-radius: 50%;
        cursor: pointer;
        margin: 0;
        flex-grow: 0;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.2s;
        position: absolute;
        right: 0;
        top: 170px;
        margin-right: 24px;

        &:hover {
          background: color(grey, pale);
        }

        i {
          font-size: 16px;
          font-weight: normal;
        }
      }
    }

    &.GRID {
      margin-bottom: 32px;
    }
  }

  &.LIST {
    &:not(:first-child) {
      padding-top: 42px;
    }
    &:hover {
      box-shadow: none;
    }

    width: auto !important;
    height: 161px !important;
    display: flex;
    flex-direction: row;
    align-items: start;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 0px;
    border-radius: 0px;
    box-shadow: none;
    padding-left: 0px;

    .card-header {
      flex-grow: 0;
      width: 230px;
      height: 103px;
      border-radius: 10px;
      margin-top: 24px;

      .profile-img {
        width: 30px;
        height: 30px;
        top: 12px;
        left: 12px;
      }
    }

    .card-content {
      padding-top: 0px;
      margin-top: 24px;
      width: 100%;
      height: 100%;
      .state-label {
        position: absolute !important;
        right: 24px;
        bottom: 48px;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }

      .date-location {
        position: initial !important;
      }

      .header-menu {
        right: 60px;
      }

      .menu {
        top: 0px;
        right: 0px;
      }

      .one-line {
        border-bottom: 1px solid #ededec;
        width: 100%;
        position: absolute;
        top: 135px;
      }
    }
  }

  &.SCHEDULE {
    height: 455px;

    .card-header,
    .cover-image-holder {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
}
