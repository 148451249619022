@import '../../../styles/variables.scss';

.P2PSelector {
  padding: 30px 0px;

  .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding: 0px;
    margin: 0px;

    li {
      flex: 1;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      border-right: 2px solid #ffffff;
      background-color: #e2e7eb;
      color: rgba(1, 41, 57, 0.5);

      &:hover {
        cursor: pointer;
        color: #012939;
      }

      &:last-child {
        border-right: 0px;
      }

      &.active {
        background-color: #f5f6f7;
        color: #012939;
      }
    }
  }

  .P2PSelector-content {
    background-color: #f5f6f7;
    padding: 30px 20px;

    .content-title {
      color: color(purple, dark);
      font-size: 16px;
      font-weight: 500;
    }

    .content-desc {
      color: #52646c;
      font-size: 14px;
      margin-top: 10px;
    }

    .TextField {
      margin-top: 10px;

      .input-wrap {
        i,
        input {
          background-color: #fff;
        }

        i {
          color: #b6c5cb;
        }
      }
    }

    .item-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrow {
        width: 40px;
        height: 40px;
        flex: 0;

        i {
          font-size: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 40px;
          background-color: #fff;
          border-radius: 50%;
          color: #b6c5cb;

          &:hover {
            color: color(purple, dark);
            cursor: pointer;
          }
        }

        &.disabled {
          opacity: 0.5;

          &:hover {
            i {
              cursor: auto;
              color: #b6c5cb;
            }
          }
        }
      }
    }

    .p2p-item {
      list-style-type: none;
      justify-content: center;
      display: flex;
      padding: 10px 20px 0px;
      margin: 10px 0px 0px;
      overflow: hidden;
      min-height: 156px;
      flex: 1;

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: rgba(1, 41, 57, 0.5);
        width: 84px;
        margin-right: 20px;

        &.p2p-loading {
          flex: 1;

          i {
            font-size: 40px;
            margin-top: -50px;
          }
        }

        &:hover {
          cursor: pointer;
          color: rgba(1, 41, 57, 0.8);
        }

        &.active {
          .Portrait {
            border-radius: 50%;
            outline: 3px solid color(purple, dark);
            outline-offset: 3px;
          }

          .p2p-name,
          .p2p-notes {
            font-weight: 700;
          }
        }

        .p2p-name {
          margin-top: 10px;
          line-height: 18px;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 36px;
        }

        .p2p-notes {
          margin-top: 5px;
          line-height: 18px;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
