@import '../../../../styles/variables.scss';

.CausesFilter {
  display: flex;
  max-width: 100%;
  margin-bottom: 25px;
  overflow-x: auto;

  .chip {
    white-space: nowrap;
    font-size: 14px;
    font-family: $lato;
    animation: filterSlideIn 200ms ease-in-out;
    text-transform: capitalize;
    text-align: center;
    min-width: fit-content;
    font-weight: 700;
    margin: 5px;
    width: auto;
    padding: 10px 15px;
    border-radius: $border-radius-minimal;
    transition: all 250ms ease-in-out;
    line-height: 28px;

    &:hover {
      cursor: pointer;
      background: color(grey, base3);
    }

    &.selected {
      background: color(grey, base3);

      i {
        display: inline-block;
        margin: 0 10px;
      }

      &:hover {
        background: color(grey, lightest);
      }
    }
  }
}
