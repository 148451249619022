@import '../../../../styles/variables.scss';

.AddCustomField {
  position: relative;
  z-index: 2;

  .custom-fields-menu {
    display: none;
    position: absolute;
    top: 100%;
    right: 0px;
    background-color: #fff;
    margin-top: 10px;
    min-width: 200px;
    border: 1px solid #b6c0c4;
    box-shadow: $box-shadow;
    border-radius: 6px;

    &:before,
    &:after {
      content: ' ';
      position: absolute;
    }

    &:before {
      top: -55px;
      right: -2px;
      width: 100px;
      height: 60px;
    }

    &:after {
      top: -35px;
      left: 55px;
      width: 80px;
      height: 55px;
      transform: rotate(-45deg);
    }

    .cf-title {
      background-color: #42606c;
      color: #fff;
      padding: 10px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.8px;
      border-bottom: 1px solid #b6c0c4;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    ul,
    li {
      padding: 0px;
      margin: 0px;
      list-style-type: none;
    }

    ul {
      padding: 5px 15px;
    }

    li {
      display: flex;
      align-items: center;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 20px;
        max-width: 20px;
        margin-right: 10px;
      }

      span {
        font-size: 14px;
        border: 0px;
        height: auto;
        color: #103645;
      }

      &:hover {
        cursor: pointer !important;

        span {
          color: color(purple, dark);
        }
      }
    }
  }

  .add-field:hover + .custom-fields-menu,
  .custom-fields-menu:hover {
    display: block;
  }

  .Modal {
    &.add-field {
      .content {
        max-width: 500px;
        padding: 20px 40px;

        .modal-close-circle {
          right: -20px;
          top: -20px;
        }
      }

      .af {
        .af-title {
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          padding: 20px 0px 40px;
        }

        .af-subtitle {
          color: color(purple, dark);
          font-size: 21px;
          border-bottom: 1px solid #b6c5cb;
          line-height: 21px;
          margin-bottom: 10px;
          padding-bottom: 10px;
        }

        .option-wrap {
          display: flex;
          justify-content: center;
          align-items: center;

          .label-colour {
            margin-top: 20px;
            height: 65px;
            padding: 0px 10px;
            position: relative;

            &:hover {
              .colour-selector {
                display: flex;
              }
            }

            .colour-title {
              font-weight: 700;
              font-size: 14px;
              height: 17px;
              line-height: 17px;
              padding-bottom: 4px;
            }

            .active-colour {
              height: 44px;
              display: flex;
              justify-content: center;
              align-items: center;

              i {
                font-size: 31px;
                position: relative;

                &:hover {
                  cursor: pointer;

                  &:after {
                    content: ' ';
                    position: absolute;
                    top: -4px;
                    left: -3px;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    padding: 1px;
                    border: 2px solid color(grey, light);
                  }
                }
              }
            }

            .colour-selector {
              display: none;
              flex-wrap: wrap;
              border-radius: 5px;
              position: absolute;
              top: 62px;
              right: 0px;
              background-color: #fff;
              box-shadow: $box-shadow;
              padding: 10px 0px;
              width: 120px;
              z-index: 2;

              &:before {
                content: ' ';
                position: absolute;
                top: -20px;
                right: 20px;
                border: 10px solid transparent;
                border-bottom: 10px solid #fff;
              }

              i {
                position: relative;
                font-size: 21px;
                width: 33.33%;
                height: 21px;
                text-align: center;

                &.padding {
                  padding-bottom: 10px;
                }

                &:hover {
                  cursor: pointer;

                  &:after {
                    content: ' ';
                    position: absolute;
                    top: -5px;
                    left: 6px;
                    height: 22px;
                    width: 22px;
                    border-radius: 50%;
                    padding: 1px;
                    border: 2px solid color(grey, light);
                  }
                }
              }
            }
          }

          .Button {
            margin-top: 40px;
            height: 44px;
            min-width: 44px;

            button {
              height: 44px;
              min-width: 44px;
              background-color: transparent;
              border: 1px solid color(grey, light);

              .text,
              .icon {
                color: color(grey);
                margin-right: 0px;
              }

              &:hover {
                border: 1px solid color(red, dark);

                .text,
                .icon {
                  color: color(red, dark);
                }
              }
            }
          }
        }

        .add-option {
          margin-top: 20px;
          margin-left: 0px;

          button {
            background-color: transparent;
            padding: 0px;

            .text,
            .icon {
              color: color(grey);
            }

            &:hover {
              .text,
              .icon {
                color: color(purple, dark);
              }
            }
          }
        }

        .af-actions {
          display: flex;
          flex-direction: row-reverse;
          flex: 1;
          align-items: center;
          margin-top: 40px;

          .Button {
            margin-left: 20px;

            &:last-child {
              button {
                background-color: #fff;
                color: #55676e;
                border: 2px solid #55676e;

                span {
                  &.text {
                    color: #55676e;
                  }
                }

                &:hover {
                  color: color(purple);
                  border: 2px solid color(purple);

                  span {
                    &.text {
                      color: color(purple);
                    }
                  }
                }
              }
            }
          }
        }

        .Dropdown,
        .TextField {
          margin-top: 20px;
        }
      }
    }
  }
}
