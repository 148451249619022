@import '../../../../styles/variables.scss';

.HubManagementSeats {
  padding: 60px;

  .HubWarningTrial-Modal {
    .ModalHeader {
      .modal-close-icon {
        position: absolute;
        right: 20px;
        top: 20px;

        .fa-times {
          color: #3a3a3a;
        }
      }

      .header-title {
        color: #000;
      }
    }

    .content {
      max-width: 600px;

      .inner {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0px 40px 40px;

        .action {
          width: 100%;
          margin-top: 42px;
          border-top: 2px solid color(dark, borderLight);
          padding-top: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .cancel-btn {
            margin-right: 16px;

            button {
              border: 1px solid #bdbebf;

              .text {
                color: #3a3a3a;
              }

              &:hover {
                background-color: #3a3a3a;

                .text {
                  color: #fff;
                }
              }
            }
          }
        }

        p {
          line-height: 28px;
          color: #4e4e4e;
          font-family: $lato;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          margin: 0;
          padding-top: 30px;

          &:first-child {
            padding-top: 0px;
          }
        }
      }
    }
  }

  .HubPayment-Modal {
    .ModalHeader {
      .modal-close-icon {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }

    .content {
      max-width: 622px;
    }
  }

  .payment-action {
    display: flex;
    justify-content: flex-end;
    padding: 0 80px;
  }

  .PaymentComponent {
    .billing-form-wrapper {
      .row {
        margin-bottom: 20px;
      }

      .row:first-of-type {
        margin-top: 20px;
      }
    }
  }

  .section-title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    background: color(cream, grey2);
    color: color(grey, dark);
    margin-bottom: 38px;

    h3 {
      margin: unset;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
    }
  }

  .payment-action {
    margin-bottom: 60px;
  }

  .edit-content-container {
    &.collapsed {
      margin-bottom: 60px;
    }

    border-bottom: 1px solid color(cream, border);

    &:hover {
      cursor: pointer;
    }

    &.no-border {
      border: unset;
    }

    .section-subtitle {
      color: color(grey, dark);
      display: flex;
      flex-direction: column;
      padding-left: 54px;
      margin-bottom: 23px;
      margin-top: 23px;

      h4 {
        margin: 0 0 6px 0;
        font-weight: 600;
        font-size: 18px;
      }

      span {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 {
      font-size: 39px;
      font-weight: 700;
      color: color(grey, dark);
      margin: 0;
    }

    .header-actions {
      display: flex;
      align-items: center;

      .invite-button {
        margin-left: 15px;
      }
    }
  }

  .trail-banner-container {
    position: relative;
    width: 100%;

    .HubTrailWarningBanner {
      position: relative;
      bottom: unset;

      .content {
        padding: 0 24px;
        margin: 0;
      }
    }
  }

  .seats-metrics {
    display: flex;
    flex-direction: row;
    margin-bottom: 60px;
    .metric-box {
      padding: 40px;
      border: 1px solid #d0d1d1;
      width: 25%;
      .metric-label {
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 700;
      }
      .metric-data {
        font-size: 30px;
        font-weight: 800;
      }
      &.no-right-border {
        border-right: none;
      }

      &.first {
        border-right: none;
        border-radius: 10px 0 0 10px;
      }
      &.last {
        border-left: none;
        border-radius: 0 10px 10px 0;
      }
    }
  }
}

@media (max-width: 680px) {
  .HubManagementSeats {
    .HubWarningTrial-Modal {
      .content {
        .ModalHeader {
          .close-button.left {
            display: none;
          }
        }
      }
    }
  }
}
