@import '../../../../../styles/variables.scss';

.HubManagementMetricContainer {
  display: flex;
  flex-flow: row nowrap;
  gap: 15px;
  min-height: 114px;

  .metric {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    flex: 1;
    border: 1px solid color(grey, border);
    border-radius: 10px;
    padding: 30px;
    gap: 10px;

    .label {
      color: #3a3a3a;
      text-align: center;
    }
    .value {
      font-size: 18px;
      font-weight: 800;
    }
  }
}
