@import '../../../styles/variables.scss';

.TooltipOverflow {
}

.tooltip-overflow-container {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
