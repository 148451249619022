@import '../../styles/variables.scss';

.FileUpload {
  width: 100%;

  label {
    display: flex;
    color: color(dark, fontColor);
    text-decoration: none;
    background-color: color(purple, dark);
    min-height: 40px;
    border-radius: $border-radius;
    justify-content: center;
    align-items: center;
    padding: 0px 28px;
    font-size: 16px;
    font-weight: 500;
    height: 100%;

    .icon {
      display: none;
      margin-right: 8px;
      position: relative;
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: color(purple);
    }
  }

  input[type='file'] {
    display: none;
  }

  .custom-file-upload {
    cursor: pointer;
  }
}
