@import '../../styles/variables.scss';

.ImageCropper {
  padding: 0px 20px;

  .title-wrap {
    margin: 0px 0px 40px 0px;

    .ImageCropper-title {
      font-family: $raleway;
      color: color(light, fontColor);
      font-weight: 500;
      padding: 15px 20px 10px;
      position: relative;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
    }

    .rec-size {
      text-align: center;
      color: color(grey);
    }
  }

  .no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;

    span {
      font-weight: 700;
      opacity: 0.25;
    }

    &.portrait {
      max-height: 50vh;

      .ReactCrop {
        max-height: 50vh;

        div {
          &:first-child {
            max-height: 50vh;

            img {
              max-height: 50vh;
              width: auto;
            }
          }
        }
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 15px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
