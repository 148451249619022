@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.SpotlightTile {
  display: flex;
  width: 100%;
  height: 280px;
  border-radius: 20px;
  transition: all 250ms ease;
  position: relative;

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 20px;
  }
  .spotlight-container {
    display: flex;
    flex: 1;
    padding: 60px 80px;
    z-index: 2;

    .spotlight-info,
    .spotlight-by {
      flex: 1;

      .spotlight-by {
        padding-left: unset;
      }
    }

    &.entity {
      .spotlight-entity {
        margin-left: 60px;
        display: flex;
        gap: 40px;
        align-items: center;
      }

      .spotlight-entity-avatar,
      .spotlight-entity-info {
        justify-content: center;

        &:hover {
          cursor: pointer;
        }
      }

      .spotlight-entity-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        & > div:first-of-type {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 700;
          font-size: 33px;
          line-height: 39px;
          color: #fff;
          margin: 3px 0;
        }

        // maybe a bit brittle
        & > div:nth-child(2) {
          color: #fff;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 25px;
        }
      }
    }

    .spotlight-info {
      display: flex;
      flex-direction: column;

      & > div {
        height: 60px;
        justify-content: center;
      }

      .program-detail {
        flex: 1;
        display: flex;

        .detail-wrap {
          display: flex;
          flex: 1;
          flex-direction: column;

          p {
            color: #fff;
            margin: 3px 0;

            &:first-of-type {
              font-size: 14px;
            }

            &:last-of-type {
              font-size: 18px;
              font-weight: 800;
            }
          }
        }
      }

      .program-progress {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 15px;

        .progress-stats {
          display: flex;
          justify-content: space-between;

          p {
            margin: 5px;
            font-size: 12px;
            color: #fff;
          }
        }

        progress {
          -webkit-appearance: none;
          appearance: none;
          width: 100%;
        }

        progress[value]::-webkit-progress-bar {
          background-color: #f1f1f0;
          border-radius: 60px;
          height: 10px;
          width: 100%;
        }

        progress[value]::-webkit-progress-value {
          background-color: #00a8e1;
          border-radius: 60px;
        }
      }

      .action-cta {
        display: flex;
        flex: 1;
        width: 100%;
        align-items: flex-end;
        min-height: 80px;

        .Button {
          width: 100%;

          button {
            width: 100%;
            background: #f5f5f5;
            color: #3a3a3a;

            &:hover {
              background: #6b64ae;
              color: #f5f5f5;
            }
          }
        }
      }
    }

    .spotlight-by {
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-transform: capitalize;

      .by {
        display: flex;
        align-items: center;

        .Portrait {
          margin-top: 0;
          margin-right: 10px;
        }
      }

      .program-name {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 33px;
        line-height: 39px;
        color: #fff;

        &:hover {
          cursor: pointer;
        }
      }

      .Portrait {
        filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.12));
      }

      p {
        flex: 1;
        color: #ffffff;
        font-size: 14px;

        span {
          font-weight: 800;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .spotlight-causes {
        display: flex;

        .Portrait {
          margin: 0 4px;

          &:first-of-type {
            margin-left: 0;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .avatar-stack {
      display: flex;
      flex-direction: row;

      &:nth-child(1) {
        z-index: 2;
      }
      &:nth-child(2) {
        z-index: 3;
      }
      &:nth-child(3) {
        z-index: 4;
      }
      &:nth-child(4) {
        z-index: 5;
      }

      .Portrait,
      .empty-portrait {
        margin-left: -7px;
        transition: 150ms ease-in-out;
        &:hover {
          z-index: 1;
          cursor: pointer;
          scale: 1.1;
        }

        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    .cause-donate {
      flex: 1;
      display: flex;
      justify-content: bottom;
      flex-direction: column;
      justify-content: end;
      margin: 17px 0;
      padding: 0 25px;
      .Button {
        width: 100%;

        button {
          width: 100%;
          background: #f5f5f5;
          color: #3a3a3a;

          &:hover {
            background: #6b64ae;
            color: #f5f5f5;
          }
        }
      }
    }
  }

  .star {
    position: absolute;
    &.star-1 {
      top: 63px;
      left: 45px;
    }
    &.star-2 {
      top: 156px;
      left: 5px;
      opacity: 0.5;
    }
    &.star-3 {
      top: 189px;
      left: 52px;
      opacity: 0.6;
      transform: scale(0.75);
    }
    &.star-4 {
      top: 205px;
      left: 94px;
      opacity: 0.22;
      transform: scale(0.5);
    }
    &.star-5 {
      top: 109px;
      left: 65px;
      opacity: 0.5;
      transform: scale(0.75);
    }
    &.star-6 {
      top: 145px;
      left: 105px;
      opacity: 0.2;
      transform: scale(0.75);
    }
    &.star-7 {
      top: 85px;
      left: 90px;
      opacity: 0.22;
      transform: scale(0.5);
    }
    &.star-8 {
      top: 36px;
      left: 141px;
      opacity: 0.4;
      transform: scale(0.6);
    }
    &.star-9 {
      top: 50px;
      left: 210px;
      opacity: 0.22;
      transform: scale(0.45);
    }
  }
}

@media (max-width: 1000px) {
  .SpotlightTile {
    .spotlight-container {
      padding-right: unset;
      padding: 60px;
    }
  }
}

@media (max-width: 680px) {
  .SpotlightTile {
    height: unset;
    min-height: 350px;

    .spotlight-container {
      flex-direction: column;
      padding-right: unset;
      padding: 40px;

      .spotlight-by {
        padding-left: unset;
        .Portrait {
          &:hover {
            cursor: pointer;
          }
        }
        .by {
          p {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .spotlight-info {
        margin-top: 20px;
        max-width: unset;

        & > div {
          height: unset;
        }

        .action-cta {
          min-height: 60px;
        }
      }

      &.entity {
        .spotlight-entity {
          flex-direction: column;
          margin-left: unset;
          gap: 20px;
          justify-content: center;
        }
      }
    }
  }
}
