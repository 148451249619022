@import '../../../styles/variables.scss';

.DiscoverPagesFilter-popupMenu {
  width: 217px;
}

.DiscoverPagesFilter {
  display: flex;
  width: 1284px;
  column-gap: 12px;
  padding: 0 64px;
  position: relative;
  flex-wrap: nowrap;
  overflow: hidden;
  justify-content: center;

  .DiscoverPagesFilter-Dropdown {
    min-width: 217px;

    &.filter-dropdown {
      .input-wrap {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 16px;
        padding: 0 23px 0 16px;
        border: 1px solid color(grey, border);
        border-radius: 7px;

        .menu-btn {
          align-items: center;
          justify-content: center;
          transition: transform 0.3s ease-in-out;

          &.show {
            transform: rotate(180deg);
          }
        }

        .label {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        &:hover {
          cursor: pointer;
        }
      }

      label {
        z-index: 1;
        bottom: -7px;
        left: 9px;
        font-size: 12px;
        background-color: white;
        font-weight: normal;
        position: relative;
        padding: 5px;
        width: fit-content;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .focus-area-container {
    position: relative;
    width: 100%;
    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
    ::-webkit-scrollbar-button {
      width: 2px;
      height: 2px;
    }
    display: flex;
    align-items: flex-end;

    .wrapper {
      position: absolute;
      display: block;
      top: 17px;
      left: 0;
      max-height: 1054px;
      margin: 0;
      overflow-y: auto;
      overflow-x: hidden;
      transform: rotate(-90deg) translateY(-44px);
      transform-origin: right top;
      cursor: pointer;

      li {
        cursor: pointer;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transform-origin: right top;
        list-style: none;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        text-align: center;
        border-radius: 8px;
        width: 44px;
        background: color(grey, pale);
        color: color(grey, text3);
        padding: 20px 0;

        .inner {
          display: flex;
          column-gap: 12px;
          align-items: center;
          justify-content: center;
          transform: rotate(90deg);
          aspect-ratio: 1 / 1;
        }

        i {
          display: none;

          &.fa-search {
            display: block;
            margin-top: 4px;
          }
        }

        &.active {
          color: color(grey, dark);

          i {
            display: block;
            margin-top: 2px;
          }
        }

        &.vertical-line {
          width: 100%;
          height: 2px;
          padding: 0px !important;
        }
      }
    }
  }
}

@media (max-width: 790px) {
  .DiscoverPagesFilter-popupMenu {
    width: calc(100vw - 88px);
  }

  .DiscoverPagesFilter {
    flex-direction: column;
    width: auto;
    padding: 0 24px;

    .focus-area-container {
      height: 64px;

      .wrapper {
        overflow-x: auto;

        li:first-child {
          margin: 0 0 10px 0;
        }
      }
    }
  }
}
