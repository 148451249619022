@import '../../../../styles/variables.scss';
.LocationSelection {
  .location-container {
    display: flex;
    flex-direction: row;

    .CheckboxCard {
      min-width: 0;
      max-height: 50px;
      min-height: 0;
      padding: 5px 20px;
      flex: unset;

      &.selected {
        background-color: color(purple, lightest);
        color: color(purple, base);
      }

      .title {
        padding-right: 0;
      }
      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .google-attribution {
    padding: 5px 0;
    height: 18px;
  }
}
