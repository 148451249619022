@import '../../styles/variables.scss';

.Button {
  &.transitions {
    button {
      transition: background-color 500ms ease-out;
    }
  }
  button {
    transition: all 0.2s;
    font-family: $lato;
    display: flex;
    border: 0;
    padding: 8px 28px;
    font-size: 16px;
    border-radius: $border-radius;
    justify-content: center;
    align-items: center;
    color: color(dark, fontColor);
    background-color: color(purple, dark);
    overflow: hidden;
    min-width: 80px;
    min-height: 40px;
    box-shadow: $box-shadow;

    &.verify-btn {
      padding: 4px 8px;
      border-radius: 20px;
      margin: 0;
      width: unset;
      height: unset;
      background-color: color(green, neon);
      border: color(green, neon) solid 1px;
      min-height: unset;
    }

    &:hover {
      cursor: pointer;
      background-color: color(purple, darkest);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: color(grey, light);

      .text {
        color: color(cream, lighter);
      }
    }

    &:focus {
      outline: 0;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      position: relative;
      width: 20px;
      height: 20px;
    }

    .text {
      font-weight: 700;
    }
  }

  &.dark {
    button {
      background-color: color(plum, dark);
      color: color(light, secondaryBg);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
      border: solid 1px color(plum, dark);
      max-width: 200px;

      &:hover {
        background-color: color(purple, dark);
        border: solid 1px color(purple, dark);
      }
    }
  }

  &.outline-dark {
    button {
      background-color: color(light, secondaryBg);
      color: color(plum, dark);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
      border: solid 1px color(plum, dark);
      max-width: 200px;

      &:hover {
        background-color: color(purple, dark);
        color: color(light, secondaryBg);
        border: solid 1px color(purple, dark);
      }
    }
  }

  &.secondary {
    button {
      background-color: transparent;
      color: color(grey, dark);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
      border: solid 1px #bdbebf;
      max-width: 200px;

      &:hover {
        color: color(purple, base);
        border: solid 1px color(purple, base);
      }
    }
  }

  &.text {
    button {
      background-color: transparent;
      color: color(grey, dark);
      box-shadow: unset !important;
      border: none !important;

      &:hover {
        color: color(purple, base);
        border: none;
      }
    }
  }
}

@media (max-width: 500px) {
  .Button {
    button {
      height: 100%;
      min-height: unset;

      .icon {
        &.leave-only {
          margin: 0;
          & + .text {
            display: none;
          }
        }
      }
    }
  }
}
