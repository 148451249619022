@import '../../../../styles/variables.scss';

.CreatePaymentPackageModal {
  height: 350px;
  min-width: 600px;
  padding: 0 40px 40px 40px;
  display: flex;
  flex-flow: column;

  .modal-content {
    display: flex;
    flex-flow: column;
    gap: 20px;
  }

  .form-row {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;

    .col {
      flex: 1;

      input {
        width: 100%;
      }
    }
  }

  .desc {
    margin-bottom: 20px;
    padding: 0 0 20px 0;
    font-family: $lato;
    font-style: normal;
    font-weight: 600;
    color: color(grey, text);
    font-size: 12px;
    border-bottom: 1px solid color(cream, grey);
  }

  label,
  label > span,
  .date-container > label {
    color: color(grey, dark);
    font-size: 14px;
    font-weight: 700;
  }

  .date-container {
    .no-drop {
      cursor: no-drop;

      input {
        cursor: no-drop;
      }
    }
  }
}
