@import '../../../styles/variables.scss';

// -50% is used to center tooltip.
@keyframes tooltipAnimationRight {
  from {
    transform: translate(25px, -50%);
  }
  to {
    transform: translate(0px, -50%);
  }
}

@keyframes tooltipAnimationLeft {
  from {
    transform: translate(25px, -50%);
  }
  to {
    transform: translate(0px, -50%);
  }
}

@keyframes tooltipAnimationBottom {
  from {
    transform: translate(-50%, 25px);
  }
  to {
    transform: translate(-50%, 0);
  }
}

.Tooltip {
  font-size: 14px;
  padding: 15px;
  position: fixed;
  top: -23px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  border-radius: 8px;
  border: 1px solid color(purple, dark);
  background-color: #ffffff;
  width: auto;
  max-width: 300px;
  color: #012939;
  word-wrap: break-word;

  display: none;
  pointer-events: none;
  font-weight: 700;
  box-sizing: border-box;

  &.direction_right {
    animation: tooltipAnimationRight 300ms ease-out forwards;
  }

  &.direction_bottom {
    animation: tooltipAnimationBottom 300ms ease-out forwards;
  }

  &.direction_left {
    animation: tooltipAnimationLeft 300ms ease-out forwards;
  }

  span {
    color: #012939;
  }

  &.visible {
    display: block;
  }

  // Renders arrow
  &.direction_right:before {
    content: ' ';
    position: absolute;
    top: calc(50% - 10px);
    left: -20px;
    height: 0px;
    width: 0px;
    border: 10px solid transparent;
    border-right: 10px solid color(purple, dark);
  }

  // Renders arrow
  &.direction_left:before {
    content: ' ';
    position: absolute;
    top: calc(50% - 10px);
    right: -20px;
    height: 0px;
    width: 0px;
    border: 10px solid transparent;
    border-left: 10px solid color(purple, dark);
  }

  // Renders arrow
  &.direction_bottom:before {
    content: ' ';
    position: absolute;
    top: -20px;
    left: calc(50% - 10px);
    height: 0px;
    width: 0px;
    border: 10px solid transparent;
    border-bottom: 10px solid color(purple, dark);
  }
}

@media (max-width: 680px) {
  .Tooltip {
    width: 250px;
  }
}
