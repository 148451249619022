@import '../../../styles/variables.scss';

.PopupMenu {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  transition: opacity 0.15s ease-in-out;
  box-shadow: $box-shadow;
  gap: 15px;
  opacity: 0;
  z-index: 11;

  .separator {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 2px solid color(grey, base3);
  }

  .MenuItem {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: color(light, fontColor);
    transition: color 0.15s ease-in-out;
    justify-content: space-between;
    padding-right: 20px;

    .inner-main {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
    }

    .label {
      margin-right: 20px;
    }

    .fa-chevron-right {
      transition: all 0.2s;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;

      &::after {
        width: 20px;
        height: 20px;
      }

      &.active {
        transform: rotate(90deg);
      }
    }

    .metric {
      text-align: center;
      font-size: 9px;
      line-height: 17px;
      color: color(light, secondaryBg);
      display: block;
      background: color(red, soft);
      width: 27px;
      height: 16px;
      border-radius: 8px;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fa-check {
      position: absolute;
      visibility: hidden;
      right: 0;

      &.selected {
        visibility: visible;
      }
    }

    .fa-chevron-right,
    .fa-chevron-down {
      position: relative;
      left: 50px;
    }

    &.selected {
      font-weight: 700;
      color: color(purple, darker);
    }

    &:hover {
      cursor: pointer;
      color: color(purple, darker);
    }
  }

  .sub-menu {
    .lng-items {
      -webkit-transition: max-height 0.5s ease-in-out;
      -moz-transition: max-height 0.5s ease-in-out;
      -o-transition: max-height 0.5s ease-in-out;
      -ms-transition: max-height 0.5s ease-in-out;
      transition: max-height 0.5s ease-in-out;
      margin-left: 15px;
      overflow: hidden;
      max-height: 0;

      &.show {
        max-height: 300px;
      }

      .lng-item {
        line-height: 40px;
        font-family: $lato;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: color(grey, dark);

        &:hover {
          cursor: pointer;
          color: color(purple, darker);
        }
      }

      .span {
        display: inline-block;
      }
    }
  }

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &.show {
    opacity: 1;
  }

  &.display-none {
    display: none;
  }
}
