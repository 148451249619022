.SeatPurchaseModal {
  .modal-close-circle {
    display: none;
  }
  .seat-purchase-content {
    overflow: hidden;
    width: 1022px;
    display: flex;
    flex-direction: row;
    .hub-seats-actions {
      position: absolute;
      bottom: 0;
      right: 400px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 38px;
      background: #fff;
      .seat-cancel {
        margin-right: 20px;
        button {
          border: 1px solid #5e51ab;
          background: #fff;
          color: #5e51ab;
        }
      }
    }
    .seat-purchase-details {
      width: calc(100% - 400px);
      position: relative;
      overflow: auto;

      .details-title {
        padding: 48px;
        font-weight: 700;
        font-size: 27px;
      }
      .details-payment {
        margin-bottom: 40px;
        overflow: auto;
        padding: 0 48px;
        scrollbar-width: thin;
        scrollbar-color: color(grey, border) color(grey, base3);

        .PaymentComponent {
          padding: 0;
        }

        &.last {
          margin-bottom: 100px;
        }

        &.counter {
          margin-bottom: 0;
          .details-row {
            border-bottom: none;
          }
        }

        &::-webkit-scrollbar-track {
          background: color(grey, base3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: color(grey, border);
          border-radius: 20px;
        }
        &::-webkit-scrollbar {
          width: 8px;
        }
        .details-sub-title {
          font-size: 14px;
          font-weight: 900;
          margin-bottom: 16px;
        }
        .details-row.sub-change {
          background: #f5f5f5;
          padding-left: 24px;
        }
        .details-row {
          padding: 28px 0;
          border-top: 1px solid #ededec;
          border-bottom: 1px solid #ededec;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          &.sub-change {
            flex-direction: column;
            .title {
              font-weight: 700;
              font-size: 14px;
              margin-bottom: 15px;
            }
            .pricing {
              .price-number {
                font-weight: 600;
                font-size: 36px;
                margin-right: 8px;
              }
              .price-description {
                font-weight: 600;
                font-size: 14px;
                color: #757575;
              }
            }
          }
          &.bottom {
            margin-bottom: 70px;
            border: none;
          }
          .PaymentComponent {
            padding: 0;
            width: 100%;
            margin-bottom: 150px;
          }
          .counter {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .counter-input {
              max-width: 62px;
              max-height: 34px;
              .input-wrap {
                height: 100%;
                width: 100%;
                input {
                  height: 100%;
                  width: 100%;
                }
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                /* Firefox */
                input[type='number'] {
                  -moz-appearance: textfield;
                }
              }
            }
            .counter-button {
              width: 34px;
              height: 34px;
              border-radius: 17px;
              background: #f5f6f7;
              display: flex;
              align-items: center;
              justify-content: center;
              &.disabled {
                opacity: 0.6;
                cursor: no-drop;
              }
              &.disabled:hover {
                cursor: no-drop;
              }
              &:hover {
                cursor: pointer;
              }
              &.sub {
                margin-right: 16px;
              }
              &.add {
                margin-left: 16px;
              }
            }
          }
          .month-price {
            padding-right: 80px;
            font-size: 10px;
          }
          .annual-price {
            font-size: 10px;
          }
          .month-price,
          .annual-price {
            .price-amount {
              margin-right: 10px;
              font-size: 21px;
              font-weight: 700;
            }
          }
        }
      }
    }
    .seat-purchase-summary {
      background: #f5f6f7;
      width: 400px;
      position: relative;
      border-radius: 0 8px 8px 0;
      .hub-banner {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: 50px;
        max-height: 140px;
        img {
          width: 100%;
          height: auto;
        }
        .close-icon:hover {
          cursor: pointer;
        }
        .close-icon {
          position: absolute;
          top: 20px;
          right: 20px;
          color: #fff;
          background: rgba(0, 0, 0, 0.5);
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 15px;
        }
      }
      .hub-profile-img {
        width: 90px;
        height: 90px;
        border-radius: 45px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-top: -45px;
        position: relative;
        img {
          width: 100%;
          height: auto;
        }
      }
      .hub-summary-details {
        text-align: center;
        .summary-details-title {
          font-size: 24px;
          font-weight: 800;
          margin: 18px 0;
          padding: 0 38px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .summary-details-description {
          margin-bottom: 18px;
        }
        span {
          display: block;
        }
      }
      .hub-summary-price {
        padding: 20px 0;
        margin: 0 38px;
        border-top: 1px solid #d0d1d1;
        border-bottom: 1px solid #d0d1d1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;

        .hub-summary-item {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
      .hub-summary-total {
        padding: 20px 0;
        margin: 0 38px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 900;
      }
      .hub-seats-actions {
        display: flex;
        justify-content: right;
        .Button {
          padding: 15px;
        }
      }
    }
  }
}
