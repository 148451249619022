@import '../../styles/variables.scss';

.SearchableDropdown {
  position: relative;

  .selectedItem {
    .Portrait {
      // center the portrait inside the textfield when selected.
      margin-top: 5px;
      margin-left: 5px;
    }
  }

  .main-icon {
    position: absolute;
    top: 32px;
    left: 14px;
    font-size: 18px;
  }

  .listing {
    z-index: 2;
    position: absolute;
    top: 44px;
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin-top: 32px;
    background-color: color(light, secondaryBg);
    max-height: 150px;
    overflow: auto;
    box-shadow: $box-shadow;
    border-radius: 12px;
    border: 1px solid color(grey, light);

    @include thin-scroller-y;

    li {
      padding: 8px 10px;
      margin: 0px;

      &:hover {
        background-color: color(cream, dark);
        cursor: pointer;
      }

      .list-item-inner {
        display: flex;
        align-items: center;

        .Portrait {
          padding-right: 10px;
        }

        .list-item-label {
          font-size: 14px;
          color: color(grey);
          text-transform: capitalize;
        }
      }
    }

    .special-option {
      height: 34px;

      .special-option-icon {
        font-size: 34px;
        margin-right: 10px;
        color: color(purple, dark);
      }

      .special-option-label {
        color: color(purple, dark);
      }
    }
  }
}
