@import '../../../styles/variables.scss';

.ForOrganizationCard {
  .image-container {
    width: max-content;
    height: max-content;

    .image-carrier {
      border-radius: 10px;
      width: 363px;
      height: 187px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      cursor: pointer;
    }
  }

  .title-container {
    margin-top: 19px;
    color: color(plum, primary);
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    font-feature-settings:
      'pnum' on,
      'lnum' on,
      'ss09' on;
    box-sizing: border-box;

    &:hover {
      cursor: pointer;
    }

    .icon-container {
      margin-left: 16px;
    }
  }
}
