@import '../../../../styles/variables.scss';

.Navigation {
  margin: 20px 0px;
  height: 55px;

  &.loading {
    .nav-inner {
      .nav-items {
        li {
          &:before {
            content: ' ';
            display: inline-block;
            width: 100px;
            height: 22px;
            background: $loading-shimmer-bg;
            background-size: $loading-shimmer-size;
            animation: $loading-shimmer-animation;
            border-radius: 5px;
            margin: 0px 30px;
          }

          span {
            display: none;
          }
        }
      }
    }
  }

  .nav-inner,
  .nav-items {
    height: 55px;
  }

  .nav-inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    margin: 0px auto;
    overflow: hidden;

    .admin-action {
      position: relative;
      top: 2.5px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        padding: 0px;
        background-color: color(pink, base);
        color: color(dark, fontColor);
        border-radius: 50%;
        padding: 0px;

        &:hover {
          background-color: color(purple, dark);
        }

        i {
          font-size: 23px;
        }
      }
    }

    .nav-items {
      display: flex;
      align-items: center;
      padding: 0px;
      margin: 0px;
      list-style-type: none;
      flex-wrap: nowrap;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 100ms linear;
        background-color: color(light, secondaryBg);
        border-radius: 25px;
        margin-right: 20px;

        &.add-page {
          min-width: 50px;
          min-height: 50px;
          max-width: 50px;
          max-height: 50px;

          i {
            font-size: 25px;
          }

          &:hover {
            i {
              color: color(purple, dark);
            }
          }
        }

        &:hover {
          cursor: pointer;

          span {
            color: color(purple, dark);
          }
        }

        span {
          padding: 0px 20px;
          font-weight: 700;
          color: color(grey);
          margin-top: 3px;
          white-space: nowrap;
        }

        &.active {
          span {
            color: color(purple, dark);
          }
        }
      }
    }

    .add-item {
      width: 50px;
      height: 55px;
      position: relative;
      top: 2.5px;
      padding: 0;
      margin: 0 15px 0 0;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 100ms linear;
        background-color: color(light, secondaryBg);
        border-radius: 25px;

        &.add-page {
          min-width: 50px;
          min-height: 50px;
          max-width: 50px;
          max-height: 50px;

          i {
            font-size: 25px;
          }

          &:hover {
            cursor: pointer;
            i {
              color: color(purple, dark);
            }
          }
        }
      }
    }
  }

  &.edit-event {
    .nav-inner {
      &.scrolled {
        &:before {
          left: 0px;
          content: ' ';
          position: absolute;
          top: 0px;
          height: 100%;
          width: 20px;
          background: rgb(0, 0, 0);
          background: linear-gradient(
            90deg,
            rgba(234, 239, 240, 1) 0%,
            rgba(234, 239, 240, 0) 100%
          );
          transition: all 300ms ease-in-out;
        }
      }
      &:after {
        right: 115px;
        content: ' ';
        position: absolute;
        top: 0px;
        height: 100%;
        width: 20px;
        background: rgb(0, 0, 0);
        background: linear-gradient(90deg, rgba(234, 239, 240, 0) 0%, rgba(234, 239, 240, 1) 100%);
      }
    }
  }
}

@media (max-width: 825px) {
  .Navigation {
    &.edit-event {
      .nav-inner {
        .add-item {
          margin: 0px;
        }

        &:after {
          right: 50px;
        }
      }
    }
  }

  .admin-action {
    display: none !important;
  }
}
