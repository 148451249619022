@import '../../styles/variables.scss';

.FlowSuccess {
  display: flex;
  flex: 1;
  position: relative;

  .bg-capsule-outer {
    position: absolute;
    height: 400px;
    width: 200px;
    bottom: 0;
    background-color: #f8f7f4;
    z-index: 2;

    &.left {
      left: 0;
      border-top-right-radius: 200px;
    }

    &.right {
      right: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 200px;
    }
  }

  .bg-capsule-inner {
    position: absolute;
    height: 400px;
    width: 300px;
    bottom: -100px;
    background-color: #f3f1ec;
    z-index: 1;

    &.left {
      left: 160px;
      border-top-right-radius: 400px;
      transform: rotate(-30deg);
    }

    &.right {
      right: 160px;
      border-top-left-radius: 200px;
      transform: rotate(30deg);
    }
  }

  .FlowSuccess-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    gap: 60px;
    flex: 1;
    z-index: 3;

    .title-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: color(plum, dark);
      gap: 24px;
      margin-bottom: 20px;
      text-align: center;

      .title {
        font-family: $raleway;
        font-size: 44px;
        font-weight: 800;
        line-height: 60px;
      }

      .subtitle {
        font-family: $raleway;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        max-width: 60%;
      }
    }

    .main {
      display: flex;
      flex-flow: row wrap;
      gap: 100px;

      .card {
        width: 344px;
        border-radius: 8px;
        box-shadow: $box-shadow;
        background-color: white;

        .cover-image {
          position: relative;
          height: 149px;
          width: 344px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;

          .portrait {
            position: absolute;
            width: 50px;
            height: 50px;
            top: 24px;
            left: 24px;
            background-color: white;
            border-radius: 50%;
            z-index: 1;
          }

          .CoverImage {
            width: 100%;
            height: 100%;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .body {
          display: flex;
          flex-direction: column;
          padding: 22px;
          gap: 10px;

          .title {
            color: black;
            font-size: 13px;
            font-weight: 700;
          }

          .date {
            color: #a9aaac;
            font-size: 11px;
          }

          .description {
            height: 40px;
            margin-top: auto;
            color: #828486;
            font-size: 11px;
            overflow: auto;
          }
        }

        .footer {
          color: #828486;
        }
      }

      .user-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        .Portrait {
          margin-bottom: 15px;
          margin-top: 0;
        }

        div {
          font-size: 18px;
          font-weight: 700;
          margin: 5px 0;
        }
      }

      .cta-container {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        gap: 15px;

        .link-container {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          gap: 20px;
          color: color(grey, dark);
          transition: color 0.2s;

          &:hover {
            color: color(plum, dark);
          }

          .text {
            font-family: $raleway;
            font-weight: 900;
            font-size: 20px;
          }

          .icon {
            font-size: 20px;
          }
        }

        a {
          text-decoration: none;
        }

        .Button {
          margin-top: 10px;

          button {
            background-color: white;
          }
        }
      }
    }
  }
}

@media (max-width: 790px) {
  .FlowSuccess {
    .FlowSuccess-content {
      padding: 20px;

      .title-container {
        .title {
          font-size: 24px;
        }

        .subtitle {
          font-size: 16px;
          color: color(grey, light);
        }
      }

      .main {
        flex-flow: column;
        gap: 20px;

        .cta-container {
          align-items: center;

          .shareable-button {
            margin-top: 15px;
          }
        }

        .card {
          margin-bottom: 15px;
        }
      }
    }

    .bg-capsule-inner,
    .bg-capsule-outer {
      display: none;
    }
  }
}
