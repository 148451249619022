@import '../../../styles/variables.scss';

.OrganizerContact-listElement {
  color: color(grey, base);
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .contact-profile {
    display: flex;
    align-items: center;

    p {
      &:nth-of-type(1) {
        margin-left: 11px;
      }
    }
  }

  p {
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 24px;
  }

  .btn-action {
    border: 2px solid;
    background: transparent;
    cursor: pointer;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;

    &.add {
      border-color: color(green, neon);
      border: 2px solid color(green, neon);
      color: color(green, neon);
    }

    &.remove {
      border-color: color(red, primaryBg);
      color: color(red, primaryBg);
      font-size: 30px;
      padding-bottom: 5px;
    }
  }
}
