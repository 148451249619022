@import '../../styles/variables.scss';
$step: 1;
$loops: round(100 / $step);
$increment: 360 / $loops;
$half: round($loops / 2);

.GuidedSetup {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: 100%;
  padding: 0px;
  .section-title {
    padding: 20px 30px;
    font-size: 32px;
    font-weight: 500;
    display: flex;
    height: 40px;
    width: 100%;

    .forms-title {
      flex: 1;
      font-weight: 700;
    }
  }

  .section-inner {
    margin: 0px 30px 30px;
    padding: 40px;
    border-radius: 15px;
    background-color: #ffffff;
    flex: 1 1;
    display: flex;
    flex-direction: column;

    .setup-overall {
      margin-bottom: 25px;
      .setup-overall-progress {
        display: flex;
        flex-direction: row;
        align-items: center;
        .overall {
          background: #6565d3;
          border-radius: 8px;
          width: 100px;
          height: 46px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          color: #fff;
        }
        .bar-wrap {
          padding-top: 5px;
          width: 100%;
          padding-left: 10px;

          .bar {
            display: flex;
            align-items: center;
            position: relative;
            height: 10px;
            background-color: color(grey, light);
            border-radius: 8px;
            width: 100%;

            .bar-inner {
              background: linear-gradient(90deg, color(purple, darkest) 0%, color(purple) 100%);
              margin-left: 2px;
              border-radius: 6px;
              width: 10%;
              height: 6px;
              background-color: color(grey);
            }
          }
        }
      }
    }

    .setup-section {
      display: flex;
      flex-direction: row;
      background-color: color(cream, lightest);
      flex: 1;
      padding: 25px;
      max-height: 260px;
      margin: 15px 0;
      border-radius: $border-radius;
      box-shadow: $box-shadow;

      .progress-circle {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        min-width: 150px;
        max-width: 200px;

        .progress-status {
          color: color(light, fontColor);
          font-weight: 700;
          .status {
            padding: 15px 5px;
          }
          span {
            color: #88949a;
          }
        }

        .circle {
          background-color: color(grey, light);
          border-radius: 50%;
          height: 125px;
          width: 125px;
          position: relative;

          &:before {
            background-color: color(purple, dark);
            border-radius: 50%;
            content: '';
            display: inline-block;
            height: 100%;
            width: 100%;
          }

          &:after {
            align-items: center;
            background-color: #fff;
            border-radius: 50%;
            content: attr(data-progress) '%';
            display: flex;
            font-size: 18px;
            justify-content: center;
            position: absolute;
            left: 7px;
            top: 6px;
            transition: transform 0.2s ease;
            height: 90%;
            width: 90%;
          }
        }

        @for $i from 0 through $loops {
          .circle[data-progress=#{'"'+$i * $step+'"'}]:before {
            @if $i < $half {
              $next-deg: 90deg + $increment * $i;
              background-image: linear-gradient(
                  90deg,
                  color(grey, light) 50%,
                  transparent 50%,
                  transparent
                ),
                linear-gradient(
                  $next-deg,
                  color(blue, light) 50%,
                  color(grey, light) 50%,
                  color(grey, light)
                );
            } @else {
              $next-deg: -90deg + $increment * ($i - $half);
              background-image: linear-gradient(
                  $next-deg,
                  color(blue, light) 50%,
                  transparent 50%,
                  transparent
                ),
                linear-gradient(
                  270deg,
                  color(blue, light) 50%,
                  color(grey, light) 50%,
                  color(grey, light)
                );
            }
          }
        }
      }

      .setup-details {
        flex: 1;
        padding-left: 15px;
        border-left: 1px solid color(grey, light);
        .setup-title {
          flex: 2;
          font-size: 28px;
          font-weight: 700;
        }
        .setup-info {
          margin-top: 10px;
          color: #354a53;
          font-size: 14px;
        }

        .setup-warning {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 700;
        }

        .tasks {
          margin-top: 25px;

          ul {
            list-style-type: none;
            padding-inline-start: 0;
            margin-top: 5px;

            li {
              span {
                margin-left: 10px;
              }
              .complete {
                width: 10px;
                color: color(purple, dark);
              }
              .incomplete {
                width: 10px;
                color: color(grey, light);
                font-size: 12px;
              }
            }
          }

          .tasks-count {
            font-weight: 700;
          }
        }
      }
    }
  }
}
