@import '../../styles/variables.scss';

.Landing {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .home-header-container {
    width: 100%;
  }
}
