@import '../../styles/variables.scss';

.Checkout {
  flex: 1;
  background-color: color(light, primaryBg);

  .Checkout-inner {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    max-width: $wrap-size;
    margin: 40px auto;

    .cart-details,
    .checkout-details,
    .admin-store {
      background-color: color(light, secondaryBg);
      box-shadow: $box-shadow;
      border-radius: 12px;
      padding: 20px 40px;
    }

    .left-wrap {
      flex: 1;

      .steps {
        background-color: color(light, secondaryBg);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding: 20px 40px;

        .title,
        .sub-title {
          text-align: center;
        }

        .title {
          padding-top: 20px;
          font-size: 28px;
          font-weight: 700;
        }

        .sub-title {
          padding: 8px 0px 0px;
          color: color(grey);
          font-size: 14px;
        }

        .FormSteps {
          padding-top: 20px;
        }
      }

      .admin-store {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }

    .admin-store {
      padding: 0px;

      .search {
        display: flex;
        background-color: color(light, primaryBg);
        padding: 20px 40px;

        .TextField {
          .input-wrap {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;

            input {
              background-color: color(light, secondaryBg);
              font-weight: 300;
              text-indent: 18px;
            }
          }
        }

        .Button {
          button {
            height: 44px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            background-color: color(grey, dark);

            span {
              font-weight: 300;
              line-height: 20px;
              height: 20px;
            }

            &:hover {
              background-color: color(grey);
            }
          }
        }
      }

      .store-items {
        padding: 40px;

        .store-items-inner {
          height: 300px;
          overflow: auto;

          .search-error {
            text-align: center;
            padding: 20px 0px;
            color: color(grey);
          }
        }

        .store-title {
          color: color(purple, dark);
          font-size: 21px;
          font-weight: 400;
        }

        .headers,
        .row {
          border-bottom: 1px solid color(light, primaryBg);
          display: flex;
          flex: 1;

          .col {
            flex: 1;
            display: flex;
            align-items: center;

            &.select {
              max-width: 40px;
              min-width: 40px;
              padding-left: 20px;

              i {
                font-size: 24px;

                &:hover {
                  cursor: pointer;
                }

                &.fa-square {
                  color: color(grey, light);
                }

                &.fa-check-square {
                  color: color(purple, dark);
                }
              }
            }

            &.col.item-details {
              .item-name {
                text-align: center;
              }
            }
            &.price {
              max-width: 100px;
              min-width: 100px;
            }
          }
        }

        .row {
          flex-direction: column;

          .row-inner {
            display: flex;
          }

          .product-details {
            display: flex;
            flex: 1;
            background-color: color(grey, lighter);
            padding: 15px 20px;
            border-radius: 12px;
            margin-bottom: 20px;
            box-shadow: $box-shadow;

            .title {
              text-align: left;
              padding: 0px;
              display: flex;
              align-items: center;
              flex: 1;
              color: color(grey);
              font-size: 14px;
              font-weight: 500;

              span {
                flex: 1;
              }

              i {
                font-size: 19px;
              }
            }
          }

          .col {
            &.price {
              font-weight: 500;
            }

            &.quantity {
              .TextField {
                max-width: 80px;
              }
            }
          }
        }

        .headers {
          border-top: 1px solid color(light, primaryBg);
          color: color(grey);
          padding: 12px 0px;
          margin-top: 20px;
          font-weight: 500;
        }
      }
    }

    .cart-details,
    .checkout-details {
      flex: 1;

      h1,
      h2 {
        font-size: 30px;
        line-height: 30px;
        padding: 20px 0px;
        margin: 0px;
        border-bottom: 1px solid color(grey, lighter);
      }
    }

    .checkout-details {
      .checkout-details-inner {
        position: relative;

        .payment-loading {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.9);
          z-index: 4;
          display: flex;
          justify-content: center;
          align-items: center;

          .Loader {
            font-size: 30px;
          }
        }
      }

      .checkout-form {
        h2 {
          color: color(purple, dark);
          padding: 0px 0px 12px;
          font-weight: 500;
          border-bottom: 2px solid color(grey, lighter);
          font-size: 24px;
          margin: 40px 0px 20px;

          &.w-padding {
            padding-top: 30px;
          }
        }

        .same-as-billing {
          margin-bottom: 15px;

          &:hover {
            cursor: pointer;
          }

          i {
            margin-right: 4px;

            &.fas {
              color: color(purple, dark);
            }
          }
        }

        .invoice-payment {
          margin-bottom: 15px;

          &:hover {
            cursor: pointer;
          }

          i {
            margin-right: 4px;

            &.fas {
              color: color(purple, dark);
            }
          }
        }

        .details-row {
          margin-bottom: 20px;
          display: flex;

          .Dropdown {
            flex: 1;
          }

          .Checkbox {
            label {
              color: color(grey);
              font-weight: 500;
              font-size: 14px;
            }
          }

          .details-col {
            flex: 1;
            margin-right: 20px;

            .Checkbox {
              label {
                justify-content: flex-start;
              }
            }

            &:last-child {
              margin: 0px;
            }
          }
        }
      }

      .checkout-actions {
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;

        // TODO [HOTFIX/3.4.4]: Create separate component that wraps <PaymentRequestButtonElement> and adds this CSS. see: https://github.com/gigitmarket/gigit-platform/blob/6303e1e3cc431f5717802f0f297f55b2085c8791/packages/ui/gigit-ui-web/src/routes/Donate/DonateForm/DonateForm.scss#L468 for other instance.
        .Button.StripeElement {
          min-width: 150px;
          border-radius: 20px;
          overflow: hidden;

          iframe {
            // Fix for Apple pay button border radius not being changed by changing the StripeElement border radius.
            // Solution is to force the iframe to have the border-radius we want.
            // Solution found here: https://wordpress.org/support/topic/change-styling-shape-of-apple-pay-buttons/
            border-radius: 20px;
          }
        }

        .Button {
          margin-left: 20px;

          button {
            &:disabled {
              .text {
                color: color(grey, base);
              }
            }
          }

          &:first-child {
            button {
              color: color(grey);
              border: 2px solid color(grey);
              background-color: color(light, secondaryBg);

              &:hover {
                color: color(purple);
                border: 2px solid color(purple);
              }
            }
          }
        }
      }
    }

    .payment-summary {
      width: 300px;
      margin-left: 40px;

      .summary-inner {
        background-color: color(light, secondaryBg);
        box-shadow: $box-shadow;
        border-radius: 12px;
        padding: 20px 0px;

        &.fixed {
          position: fixed;
          top: 80px;
          width: 300px;
          z-index: 98;
        }
      }

      h2 {
        padding: 20px 0px 10px 0px;
        margin: 0px 30px 20px 30px;
        font-size: 21px;
        line-height: 30px;
        border-bottom: 1px solid color(grey, lighter);
        text-align: center;
      }

      .subtotal,
      .discounts,
      .donation,
      .tax {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px 20px;

        span {
          &:first-child {
            font-weight: 500;
          }

          &:last-child {
            color: color(grey);
          }
        }
      }

      .total {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        background-color: color(cream, light);
        height: 80px;
        line-height: 80px;
        padding: 0 30px;
        font-size: 22px;
        font-weight: 700;
      }

      .checkout-actions {
        padding: 0px 30px;
        margin-top: 15px;
        display: flex;
        flex: 1;
        flex-direction: column;

        a {
          display: flex;
          font-size: 16px;
          border-radius: 20px;
          justify-content: center;
          align-items: center;
          color: color(grey);
          background-color: color(light, secondaryBg);
          border: 2px solid color(grey);
          overflow: hidden;
          min-width: 80px;
          min-height: 40px;
          text-decoration: none;
          font-weight: 500;

          &:hover {
            color: color(purple);
            border: 2px solid color(purple);
          }
        }

        .Button,
        button {
          display: flex;
          flex: 1;
        }

        button {
          &:disabled {
            .text {
              color: color(grey, base);
            }
          }
        }

        &.multi {
          .Button {
            margin-bottom: 10px;

            &:last-child {
              button {
                border: 2px solid color(grey);
                background-color: color(light, secondaryBg);
                color: color(grey);

                &:hover {
                  border: 2px solid color(purple);
                  color: color(purple);
                }
              }
            }
          }
        }
      }

      .discount-codes {
        padding: 30px 30px;

        .anonymous-donation-container {
          margin-top: 20px;
          label {
            justify-content: unset;
          }
        }

        .discount-label {
          display: block;
          font-weight: 500;
          padding-bottom: 5px;
          font-size: 16px;
        }

        .donation-amount {
          margin-top: 20px;
        }

        .discount-wrap {
          display: flex;

          .TextField {
            flex: 1;

            .input-wrap {
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
            }
          }

          &.valid {
            .Button {
              button {
                background-color: color(green, dark);

                .icon {
                  color: color(light, secondaryBg);
                }
              }
            }
          }

          .Button {
            button {
              height: 44px;
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              background-color: color(purple, dark);
              padding: 8px 0px;
              min-width: 50px;

              .text {
                display: none;
              }

              .icon {
                margin-right: 0px;
              }
            }
          }
        }
      }
    }

    .cart-items {
      display: flex;
      flex-direction: column;
      flex: 1;

      .empty-cart {
        flex: 1;
        color: color(grey);
        padding: 20px;
        text-align: center;
      }

      .headers,
      .item {
        display: flex;
        flex: 1;

        .col {
          &.item-details {
            display: flex;
            flex: 1;

            .Portrait {
              margin-right: 20px;
            }

            .item-name {
              font-size: 18px;
              font-weight: 500;
            }

            .item-description {
              font-size: 14px;
              line-height: 22px;
              color: color(grey);
              padding: 10px 0px 0px;
              margin: 0px;
            }
          }

          &.quantity,
          &.price,
          &.actions {
            display: flex;
            flex: 1;
            max-width: 100px;
            justify-content: center;
            height: fit-content;
          }

          &.actions {
            max-width: 50px;
          }

          &.quantity {
            align-items: center;

            .minus {
              padding-right: 12px;
            }

            .plus {
              padding-left: 12px;
            }

            .disabled {
              color: #c8c8c8;
              cursor: auto;
            }

            .actions {
              padding-right: 15px;
              display: none;
            }

            i:active:not(.disabled) {
              color: #aaaaaa;
            }

            i {
              color: #838996;
              height: auto;
              cursor: pointer;
              font-size: 24px;
            }

            span {
              color: #2e9cc3;
              font-size: 18px;
            }
          }

          &.price {
            padding-top: 2px;
            font-weight: 700;
          }

          &.actions {
            justify-content: flex-end;

            i {
              color: color(red, dark);
              font-size: 21px;

              &:hover {
                cursor: pointer;
                color: color(purple);
              }
            }
          }
        }
      }

      .item-wrap {
        .item {
          margin-bottom: 20px;
        }

        .item-questions {
          margin-bottom: 40px;
          background-color: color(light, secondaryBg);
          box-shadow: $box-shadow;
          border-radius: 12px;
          border: 1px solid color(grey, lighter);

          &.shipping {
            padding-bottom: 30px;

            p {
              padding: 0px 20px;
              margin: 30px 0px 0px;
              font-size: 14px;
            }

            .form-name {
              display: flex;
              flex: 0;
              border-top-right-radius: 8px;
              border-top-left-radius: 8px;

              span {
                flex: 0;
                padding: 0px;
                white-space: nowrap;
              }

              i {
                padding: 0px 10px 0px 20px;

                &.fa-times-circle {
                  color: color(red, dark);
                }

                &.fa-check-circle {
                  color: color(green, dark);
                }
              }
            }

            .ship-option,
            .pickup-option {
              margin-top: 20px;
              padding: 0px 20px;

              .inner-title {
                font-weight: 500;
                margin-bottom: 10px;
              }

              .option {
                margin-left: 20px;
                margin-bottom: 5px;
                font-weight: 500;

                span {
                  font-weight: 300;
                  margin-left: 4px;
                }

                i {
                  margin-right: 10px;

                  &.fas {
                    color: color(purple, dark);
                  }
                }

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }

          .question-inner-wrap {
            &:first-child {
              .form-name {
                border-top-right-radius: 8px;
                border-top-left-radius: 8px;
              }
            }
            .question-wrap {
              .label.no-select {
                .required {
                  color: color(red, dark);
                }
              }
            }
          }

          .form-name {
            height: 40px;
            line-height: 40px;
            display: flex;
            color: color(grey);
            background-color: color(cream, dark);
            margin-bottom: 5px;

            &.no-marg {
              margin-bottom: 0;
            }

            span {
              flex: 1;
              padding: 0px 20px;
              font-weight: 500;
            }

            i {
              font-size: 21px;
              height: 40px;
              line-height: 40px;
              padding-right: 20px;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }
          }

          .question-wrap {
            padding: 20px;

            .question {
              .label {
                font-weight: 500;
                padding-bottom: 10px;

                .required {
                  color: color(purple, dark);
                }
              }

              .Checkbox {
                margin-bottom: 10px;

                label {
                  justify-content: flex-start;
                  align-items: flex-end;

                  p {
                    font-weight: 500;
                  }
                }
              }

              .answer {
                .TextField,
                .Dropdown {
                  .input-wrap {
                    overflow: hidden;
                    background-color: color(light, secondaryBg);
                  }

                  input {
                    background-color: color(light, secondaryBg);
                  }
                }
              }
            }
          }
        }
      }

      .headers {
        .col {
          color: color(grey);
          font-weight: 500;
          height: 40px;
          line-height: 40px;
          margin-bottom: 20px;
          border-bottom: 1px solid color(grey, lighter);

          &.item-details {
            text-indent: 20px;
          }

          &.quantity,
          &.price {
            padding-top: 0px;
          }

          &.price {
            font-weight: 500;
          }

          &.actions {
            min-height: 40px;
          }
        }
      }
    }
  }
}

@media (max-width: 1060px) {
  .Checkout {
    .Checkout-inner {
      .cart-details {
        .item-wrap {
          .col.item-details {
            .item-description {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 940px) {
  .Checkout {
    .Checkout-inner {
      display: block;
      padding: 20px;
      margin: 10px 0px;

      .cart-details,
      .payment-summary {
        display: block;
        width: auto;
        margin-left: 0px;
        margin-right: 0px;
      }

      .payment-summary {
        margin-top: 40px;
      }
    }
  }
}

@media (max-width: 560px) {
  .Checkout {
    .Checkout-inner {
      .checkout-details {
        .checkout-form {
          .details-row {
            display: flex;
            flex-direction: column;

            .details-col,
            .details-row {
              margin-right: 0;
              padding-top: 8px;
            }
          }

          .checkout-actions {
            display: flex;
            flex-direction: column;

            .Button {
              margin-left: 0;
              margin-top: 10px;
              flex: 1;

              button {
                width: 100%;

                &:disabled {
                  .text {
                    color: color(grey, base);
                  }
                }
              }
            }
          }
        }
      }

      .cart-details {
        padding: 20px;

        .cart-items {
          .headers {
            display: none;
          }

          .item-wrap {
            .item {
              display: flex;
              margin-top: 20px;

              .col {
                .Portrait {
                  margin-right: 10px;
                }

                &.item-details {
                  margin-right: 10px;
                }

                &.price {
                  display: none;
                }

                &.quantity {
                  .actions {
                    display: inline-block;
                    i {
                      color: color(red, dark);
                      font-size: 21px;
                    }
                  }
                }

                &.actions {
                  display: none;
                }
              }
            }

            .item-questions {
              .form-name {
                min-height: 40px;
                height: unset;
              }
            }

            .mobile-price {
              display: block;
            }
          }
        }
      }
    }
  }
}
