@import '../../../styles/variables.scss';

.Network {
  display: flex;
  gap: 20px;
  margin: 30px;
  width: 100%;

  @include kambeoScroll;

  ul {
    color: color(light, fontColor);
    text-align: left;
    list-style-type: none;
    padding-left: 0;
    max-height: 100%;
    overflow: auto;
    margin: 0;

    li {
      svg {
        width: 12px;
        height: 12px;
        margin: 0 8px;

        path {
          fill: #5a5b5d;
        }
      }
    }
  }

  .network-list-selection-container {
    padding: 12px 8px;
    max-width: 320px;
    max-height: 294px;
    border: 1px solid color(grey, light);
    border-radius: 10px;
    flex: 1;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        position: relative;
        border-radius: 10px;
        margin: 5px 0;
        color: #3a3a3a;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */
        letter-spacing: 0.280123px;
        padding: 5px;
        width: -webkit-fill-available;
        height: 30px;
        display: flex;
        align-items: center;
        &.active,
        &:hover {
          background: #f5f5f5;
          cursor: pointer;
        }

        span {
          position: absolute;
          right: 20px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #eb645e;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          padding: 5px;
        }
      }
    }
  }
  .network-list-container {
    border-radius: 10px;
    border: 1px solid color(grey, light);
    flex: 2;
    overflow: hidden;

    ul {
      li {
        display: flex;
        align-items: center;
        padding: 8px;
        position: relative;
        height: 58px;
        border-bottom: 1px solid #e5e5e5;

        &:last-of-type {
          border-bottom: none;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        &.end-infinite-scroll {
          height: 1px;
        }

        &:hover {
          background: #f5f5f5;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .Network {
    flex-direction: column;
    margin-top: 0;
    gap: 0;
    .network-list-selection-container {
      align-items: center;
      flex: unset;
      border: unset;
      ul {
        display: flex;
      }
    }
    .network-list-container {
      height: calc(100% - 80px);
    }
  }
}
