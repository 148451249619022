@import '../../../../styles/variables.scss';

.HubToggleButtons {
  margin: 30px 0;
  width: 100%;

  .container {
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    width: max-content;
  }

  .Button {
    &.active,
    &:hover {
      button {
        // color: color(light, secondaryBg);
        opacity: 0.7;
      }
    }

    button {
      height: 40px;
      background-color: color(grey, pale);
      color: color(grey, dark);
      font-weight: 700;
      font-size: 14px;
      border: unset;
      border-radius: unset;
      box-shadow: unset;
      transition: all 0.2s;
    }
  }
}
