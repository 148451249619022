@import '../../styles/variables.scss';

.VolunteerManagementChekInsModal,
.VolunteerManagementLogHoursModal {
  .modal-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 280px;
    min-width: 622px;
    max-width: 622px;

    p {
      margin: 0 0 32px 0;
      font-family: $lato;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }

    .title {
      margin-bottom: 24px;
    }

    .list {
      display: flex;
      flex-direction: column;
      width: 100%;

      .item {
        display: flex;
        margin-bottom: 12px;
        align-items: center;

        a {
          text-decoration: none;
          color: color(grey, dark);
          transition: all 0.2s;

          &:hover {
            color: color(plum, dark);
          }
        }

        .Portrait {
          margin-right: 16px;
        }
      }
    }

    h3 {
      margin-bottom: 24px;
      font-family: $raleway;
      font-style: normal;
      font-weight: 800;
      font-size: 21px;
      color: color(grey, dark);
    }

    .tab-container {
      position: relative;
      border-bottom: solid 1px color(dark, border);
      margin-bottom: 32px;
      min-height: 54px;

      .tab-item {
        position: absolute;
        left: 0;
        top: 0;
        border-bottom: solid 1px color(plum, dark);
        padding: 0 12px;
        height: 53px;
        line-height: 53px;
        z-index: 9;

        span {
          color: color(plum, dark);
          font-family: $lato;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
        }
      }
    }

    .fields-container {
      display: flex;
      align-items: flex-end;

      .rdtPicker {
        z-index: 9;
      }

      label {
        font-family: $lato;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: color(purple, greyTxt);

        input {
          border: 1px solid color(grey, border);
          border-radius: 8px;
          width: 220px;
        }
      }

      .TextField {
        margin-left: 18px;
        flex: unset;

        label span {
          font-family: $lato;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          color: color(purple, greyTxt);
        }

        .input-wrap {
          border: 1px solid color(grey, border);
          border-radius: 8px;
          width: 180px;
        }
      }
    }
  }

  padding: 12px 48px;
}

.VolunteerManagement {
  flex: 1;
  display: flex;

  .user-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: color(grey, dark);
    transition: all 0.2s;

    &:hover {
      color: color(plum, dark);
    }

    .Portrait {
      margin-right: 16px;
    }
  }

  .company-status {
    text-align: center;
    padding: 6px 12px;
    border-radius: 8px;

    &.interested {
      color: color(blue, soft2);
      background-color: color(blue, whiteBlue);
    }

    &.not_interested {
      color: color(red, soft);
      background-color: color(red, fade);
    }

    &.confirmed {
      color: color(green, soft);
      background-color: color(green, fade);
    }

    &.checked {
      color: color(purple, dark);
      background-color: color(light, purple);
    }

    &.unknown {
      color: color(dark, border);
      background-color: color(grey, pale);
    }
  }

  .section-title {
    display: flex;

    .section-inner-title {
      flex: 1 1;
    }

    .filter-button {
      margin-right: 10px;
    }

    .export-button {
      margin-left: 10px;

      button {
        background-color: #ffffff;
        color: color(purple, dark);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

        &:hover {
          color: color(purple, base);
        }
      }
    }

    .section-create {
      .Button {
        button {
          box-shadow: $box-shadow;
        }
      }
    }
  }

  .volunteer-name {
    &:hover {
      cursor: pointer;
    }
  }

  .ContactDetailsModal {
    justify-content: flex-start;
    align-items: stretch;

    .content {
      position: relative;
      flex: 1;
      max-width: 100%;
      max-height: 100%;
      margin: 40px 30px;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;

      .modal-close-circle {
        right: 15px;
        top: 15px;
      }
    }

    .ModalHeader {
      display: none;
    }
  }

  .VolunteerManagement-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    .search {
      display: flex;
      align-items: center;

      .create {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }

      .TextField {
        max-width: 50%;

        i {
          background-color: color(light, secondaryBg);
          color: color(light, fontColor);
        }

        input {
          background-color: color(light, secondaryBg);
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      flex: 1;

      .list-inner {
        flex: 1;
        max-height: 100%;
        overflow: auto;
        position: relative;

        .list-rows {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;

          .SortableTable {
            .table-row {
              .col {
                &.user_display_name {
                  font-weight: 500;
                }

                &.status_code {
                  span {
                    padding: 4px 8px;
                    border-radius: $border-radius;
                    text-transform: capitalize;

                    &.approved {
                      background-color: #eafcf5;
                      color: #067f5c;
                    }

                    &.declined,
                    &.closed,
                    &.rejected {
                      color: #cf2035;
                      background-color: #fce9ea;
                    }

                    &.pending,
                    &.draft {
                      background-color: #f3f4f9;
                      color: #686c8b;
                    }
                  }
                }
              }
            }
          }

          .row-details {
            display: flex;
            transition: height 150ms ease-in-out;
            justify-content: space-between;
            margin: 12px 0px;

            .third {
              background-color: color(blue, lightest);
              flex: 1;
              border-radius: 12px;
              margin-right: 12px;
              overflow: hidden;
              position: relative;
              display: flex;
              flex-direction: column;

              .view-application {
                display: flex;
                width: 100%;
                position: absolute;
                bottom: 10px;
                left: 0;

                .Button {
                  flex: 1;
                  display: flex;

                  button {
                    flex: 1;
                  }
                }
              }

              &:last-child {
                margin-right: 0px;
              }

              &.metrics {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                padding: 12px;
              }

              .metric {
                display: flex;
                flex-direction: column;
                width: 50%;
                flex-wrap: wrap;

                &:first-child,
                &:nth-child(2) {
                  margin-bottom: 6px;
                }

                &:last-child,
                &:nth-child(3) {
                  margin-top: 6px;
                }

                &:first-child,
                &:nth-child(3) {
                  .metric-inner {
                    margin-right: 6px;
                  }
                }

                &:nth-child(2),
                &:last-child {
                  .metric-inner {
                    margin-left: 6px;
                  }
                }

                &.long {
                  width: 100%;

                  .metric-inner {
                    margin-left: 0px !important;
                    justify-content: center;
                    align-items: center;
                    padding-top: 0px;

                    div {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding-top: 0px;

                      i {
                        margin-right: 10px;
                        color: color(purple);
                        font-size: 21px;
                      }
                    }

                    span {
                      color: color(light, fontColor);
                      font-size: 14px;
                    }
                  }
                }

                .metric-inner {
                  border-radius: 8px;
                  background-color: color(light, secondaryBg);
                  display: flex;
                  flex: 1;
                  flex-direction: column;

                  div {
                    text-align: center;
                  }
                }

                span,
                div {
                  display: flex;
                  justify-content: center;
                }

                span {
                  color: color(purple);
                  font-size: 19px;
                }

                div {
                  font-weight: 500;
                  font-size: 14px;
                  padding-top: 10px;
                }
              }

              .CoverImage {
                border-radius: 0px;
                min-height: 217px;
              }

              .Portrait {
                justify-content: center;
                display: flex;
              }

              .title {
                font-weight: 700;
                padding: 10px 0px;
                border-bottom: 1px solid color(grey, light);
                margin: 0px 20px;
                padding-top: 20px;
                font-size: 21px;
              }

              .sub-title {
                margin: 20px 20px 5px;
                font-weight: 700;
                font-size: 14px;
              }

              .phone,
              .email {
                padding: 0px 20px;
                font-size: 14px;
                color: color(grey);
                line-height: 21px;
              }

              .age-check {
                padding: 5px 20px 0px;
                color: color(yellow, warning);
                font-size: 14px;
                font-weight: 500;
                display: flex;
                align-items: center;

                i {
                  margin-right: 5px;
                  font-size: 16px;
                }
              }

              .address,
              .dob {
                padding: 0px 20px;
                font-size: 14px;
                color: color(grey);
                line-height: 21px;
              }

              .inner-user-details {
                position: relative;
                padding-top: 70px;
                display: flex;
                flex-direction: column;
                flex: 1;

                .Portrait {
                  position: absolute;
                  left: 53%;
                  margin-left: -62px;
                  top: -60px;
                }

                .user-actions {
                  display: flex;
                  flex: 1;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  padding: 20px 20px 10px;

                  a {
                    font-family: $raleway;
                    display: flex;
                    justify-content: center;
                    border: 0px;
                    padding: 0px 28px;
                    font-size: 16px;
                    border-radius: $border-radius;
                    justify-content: center;
                    align-items: center;
                    color: color(dark, fontColor);
                    background-color: color(purple, dark);
                    overflow: hidden;
                    min-width: 80px;
                    min-height: 40px;
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 16px;

                    i {
                      padding-right: 8px;
                    }

                    &:hover {
                      background-color: color(purple);
                    }

                    &.faded {
                      background-color: color(blue, lightest);
                      border: 3px solid color(grey, light);
                      color: color(grey);

                      &:hover {
                        color: color(purple);
                        border: 3px solid color(purple);
                      }
                    }
                  }

                  .main-action {
                    display: flex;
                    width: 100%;

                    .Button,
                    button {
                      flex: 1;
                      width: 100%;
                    }

                    button {
                      box-shadow: $box-shadow;
                    }
                  }

                  .sub-actions {
                    display: flex;
                    width: 100%;
                    padding-bottom: 20px;

                    .Button {
                      flex: 1;

                      button {
                        width: 100%;
                        background-color: color(light, secondaryBg);
                        color: color(purple, dark);
                        border: 2px solid color(purple, dark);
                        box-shadow: $box-shadow;

                        &:hover {
                          color: color(purple);
                          border: 2px solid color(purple);
                        }
                      }

                      &:first-child {
                        margin-right: 10px;
                      }
                    }

                    a {
                      margin-left: 10px;
                    }
                  }
                }

                .user-name,
                .user-handle {
                  display: flex;
                  justify-content: center;
                  color: color(light, fontColor);

                  a {
                    color: color(light, fontColor);
                    text-decoration: none;

                    &:hover {
                      color: color(purple, dark);
                    }
                  }
                }

                .user-handle {
                  font-size: 16px;
                  color: color(grey);
                  padding-top: 4px;
                }

                .user-name {
                  font-size: 21px;
                  padding-top: 10px;
                  font-weight: 700;
                }
              }
            }
          }

          .empty {
            flex: 1;
            padding: 30px 20px;
            text-align: center;
            color: color(grey);
            font-size: 16px;
          }
        }
      }
    }
  }

  .Modal {
    &.transparent {
      .content {
        background-color: transparent;
      }
    }
  }

  .add-volunteer {
    padding: 20px;
    width: 640px;

    .step {
      .question {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 30px;

        .Checkbox {
          margin-top: 10px;

          label {
            justify-content: flex-start;
            align-items: center;
          }
        }
      }
    }

    .FormSteps {
      margin: 40px 0px 30px;
    }

    .subheader {
      padding: 5px;
      border-bottom: 1px solid #f5f6f7;
      color: color(purple, dark);
      font-size: 18px;
      margin: 0px 20px 15px;
    }

    form {
      max-height: 400px;
      overflow: auto;
      position: relative;
      margin: 0px 20px 80px;

      div {
        margin: 5px 0px;
      }

      .form-2-1 {
        display: flex;
        flex-direction: row;
        div {
          flex: 1;
        }
        div:first-of-type {
          flex: 2;
          margin-right: 8px;
        }
      }

      .form-1-1 {
        display: flex;
        div:first-of-type {
          margin-right: 8px;
        }

        .Dropdown {
          flex: 1;
        }
      }

      .actions {
        z-index: 2;
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 80px;
        background-color: color(light, secondaryBg);
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .Button {
          &:last-child {
            margin-right: 40px;
          }
        }
      }
    }
  }

  .content {
    min-width: 600px;
  }
}
