@import '../../../../styles/variables.scss';

.HubModalManage {
  .modal-close-circle {
    top: 43px;
  }
  .ModalHeader {
    padding: 0 48px;

    .header-container {
      padding: 0;

      .header-title {
        margin-left: unset;
      }
    }
  }
  .hub-banner-content {
    padding: 0 48px;
    width: 800px;
    overflow: auto;

    @include thin-scroller-y;

    .hub-banner-row {
      padding: 48px 0;
      border-top: 1px solid #e9e9e8;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .row-content-section {
        .row-content-title {
          font-size: 14px;
          font-weight: 700;
        }
        .banner-description {
          word-break: break-word;
        }
        .row-title {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 26px;
          .row-sub-title {
            font-weight: 600;
            color: #a9aaac;
          }
        }
        .call-to-action {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        .CoverImage {
          max-height: 250px;
        }
      }
      .row-action-section {
        button {
          border: 1px solid color(light, secondaryBg);
          color: color(light, secondaryBg);
          background-color: color(otherblue, mid);
          box-sizing: border-box;
          border-radius: 50px;
          margin-right: 16px;
          transition: all 0.2s;

          i {
            margin-right: 14px;
          }

          &:hover {
            color: color(otherblue, mid);
            background-color: color(light, secondaryBg);
          }
        }
      }
    }
  }
  .layout-selection {
    display: flex;
    flex-direction: row;
    max-width: 1030px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 40px;
    .layout-container:hover {
      cursor: pointer;
    }
    .layout-container {
      position: relative;
      width: calc(50% - 10px);
      height: 160px;
      overflow: hidden;
      border-radius: 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      .Checkbox {
        position: absolute;
        top: 14px;
        right: 14px;
      }
      &.left-push {
        margin-left: 5px;
      }
      &.right-push {
        margin-right: 5px;
      }
    }
    .action-container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      padding: 10px 0;
      .Button.cancel-button {
        button {
          background: #fff;
          color: #2f2956;
          border: 1px solid #2f2956;
        }
        margin-right: 10px;
      }
      .Button.save-button {
        button {
          background: #2f2956;
        }
      }
    }
  }
  .banner-info {
    padding: 10px 40px;
    .row {
      margin-bottom: 30px;
      .TextField {
        label span {
          color: #000;
        }
      }
      .TextArea {
        border: 1px solid #000;
        border-radius: 5px;
        .placeholder {
          color: #000;
          margin-left: 0;
          font-size: 14px;
          font-weight: 700;
          opacity: 1;
          top: -5px;
        }
        textarea {
          height: unset;
        }
      }
    }
    .call-to-action {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .action-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 0;
    .Button.cancel-button {
      button {
        background: #fff;
        color: #2f2956;
        border: 1px solid #2f2956;
      }
      margin-right: 10px;
    }
    .Button.save-button {
      button {
        background: #2f2956;
      }
    }
  }
}
