@import '../../styles/variables.scss';

.HistoryTable {
  .Modal {
    .content {
      max-height: 573px;
    }
  }
  .TableComponent {
    margin-bottom: 0;
    padding-right: 12px;

    .status {
      height: 30px;
      line-height: 30px;
      padding: 6px 12px;
      text-align: center;
      border-radius: 6px;

      &.Verified {
        color: color(green, soft);
        background-color: color(green, fade);
      }

      &.Unverified {
        color: color(yellow, text);
        background-color: color(yellow, fade);
      }
    }

    .pagination-container {
      position: sticky;
      bottom: 0;
      left: 0;
      background-color: white;
      padding-top: 18px;
    }
  }
}
