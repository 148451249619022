@import '../../../styles/variables.scss';

.SideBar {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 30px 18px;
  background-color: color(purple, darkest);
  color: white;
  width: 225px;
  min-width: 225px;
  overflow: hidden;
  transition:
    width 0.5s ease-in-out,
    min-width 0.5s ease-in-out;

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .title,
  .notification-dot,
  .link {
    transition: opacity 0.5s ease-in-out;
    white-space: nowrap;
  }

  &.collapsed {
    width: 45px;
    min-width: 45px;

    .owner-container {
      .description-container {
        .title,
        .link {
          opacity: 0;
        }
      }
    }

    .tabs {
      .tab {
        .text-wrap {
          .title,
          .notification-dot {
            opacity: 0;
          }
        }
      }
    }
  }

  .owner-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 30px;
    font-size: 14px;
    gap: 15px;
    max-height: 35px;

    .Portrait {
      margin-left: 5px;
    }

    .description-container {
      display: flex;
      flex-flow: column;
      gap: 5px;
      height: 100%;

      .title {
        opacity: 1;
      }

      .link {
        color: #9694a9;
        font-size: 12px;
        text-decoration: none;
        font-weight: 500;

        &:hover {
          color: #9694a9bd;
        }
      }
    }
  }

  .tabs {
    display: flex;
    flex-flow: column nowrap;

    .tab {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      height: 30px;
      padding: 7px;
      border-radius: 60px;
      margin-top: 5px;
      font-size: 14px;
      white-space: nowrap;
      transition: background-color 0.3s ease-in-out;

      .icon-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        min-height: 30px;
        min-width: 30px;

        .icon {
          width: 15px;
          height: 15px;
        }
      }

      .text-wrap {
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        flex: 1;
        height: 40px;

        .title {
          position: absolute;
          left: 10px;
          font-weight: 500;
          opacity: 1;
          white-space: nowrap;
        }

        .notification-dot {
          position: absolute;
          right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: color(red, soft);
          font-size: 10px;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      &.active {
        background-color: color(purple, darker);
      }

      &:hover {
        cursor: pointer;
        background-color: color(purple, darker);
      }
    }
  }

  .collapse-btn {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    padding: 5px;
    border-radius: 50%;
    margin-top: 5px;
    transition: all 0.3s ease-in-out;

    &.collapsed {
      transform: rotate(180deg);
    }

    &:hover {
      background-color: color(purple, darker);
      cursor: pointer;
    }
  }
}

@media (max-width: 1070px) {
  .SideBar {
    display: none;
  }
}
