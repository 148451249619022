@import '../../../../styles/variables.scss';

@mixin row-button {
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background: none;
}

.ManageTabsModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  max-height: 500px;

  .actions {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    .Button {
      padding-top: 0;
      &:last-child {
        button {
          background-color: color(light, secondaryBg);
          color: color(grey);
          border: 2px solid color(grey);

          &:hover {
            border: 2px solid color(purple);
            color: color(purple);
          }
        }
      }
    }
  }

  .tab-scroller {
    max-height: 400px;
    overflow: auto;
    flex: 1;
  }

  .tab-container {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
  }

  .add-tab-button {
    padding-top: 40px;
  }

  .Loader {
    margin-top: 20px;
    align-self: center;
    font-size: 40px;
  }
}

.ManageTabsModal-tab {
  position: relative;
  height: 48px;
  background-color: hsl(210, 11%, 96%);
  border-radius: 7px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .drag-indicator {
    margin-left: 16px;
    font-size: 24px;
  }

  .tab-name {
    font-size: 16px;
    line-height: 48px;
    margin-left: 16px;
    font-weight: 500;
  }

  .editPageName-form {
    .TextField {
      padding-left: 8px;
    }

    .TextField,
    .input-wrap,
    input {
      height: 38px;
      line-height: 38px;
    }

    input {
      background-color: color(light, secondaryBg);
    }
  }

  .edit-button {
    @include row-button;
    justify-self: flex-end;
    margin-left: auto;

    &:hover {
      color: color(purple, dark);
    }
  }

  .delete-button {
    @include row-button;

    &:hover {
      color: color(red, dark);
    }
  }

  button {
    &:last-child {
      margin-right: 16px;
    }
  }
}

@media (max-width: 680px) {
  .ManageTabsModal {
    .tab-scroller {
      &.edit-name-enabled {
        max-height: 105px;
      }
    }
  }
}
