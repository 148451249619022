@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

@include skeletonLoaderKeyframes;

.CardCommonHubSkeleton {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 363px;
  height: 392px;
  background: color(light, secondaryBg);
  margin-bottom: 10px;
  margin-right: 32px;
  border-radius: 10px;
  box-shadow: 0px 16px 28px rgba(0, 0, 0, 0.08);

  &.DISCOVER {
    margin-top: 45px !important;
  }

  .card-header {
    flex-grow: 1;
    position: relative;
    width: 100%;
    max-height: 159px;
    overflow: hidden;

    .cover-image-holder {
      width: 100%;
      height: 100%;
      background-color: color(grey, pale);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .profile-img {
      background-color: #e9e9e8;
      position: absolute;
      top: 33px;
      left: 33px;
      width: 44px;
      height: 44px;
      border-radius: 22px;

      img {
        width: 44px;
        height: 44px;
      }
    }
  }

  .card-content {
    padding-right: 32px;

    .title,
    .description,
    .location {
      width: 100%;
      height: 12px;
      background-color: #e9e9e8;
      border-radius: 10px;
      margin-bottom: 12px;
    }

    .title,
    .description {
      &:nth-of-type(2) {
        width: 100px !important;
      }
    }

    .location {
      background-color: color(grey, pale);

      &:nth-of-type(2) {
        margin-top: 10px !important;
        background-color: black !important;
      }
    }

    .description {
      background-color: color(grey, pale);
    }
  }

  .card-actions {
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 32px;

    .button {
      background-color: color(grey, pale);
      border-radius: 22px;
      width: 143px;
      height: 44px;

      &:nth-of-type(1) {
        margin-right: 15px !important;
      }
    }
  }

  @include skeletonLoaderAnimation;
}
