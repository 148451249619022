@import '../../styles/variables.scss';

.MemberManagement {
  flex: 1;
  display: flex;

  .user-name-link {
    text-decoration: none;
    color: color(plum, dark);
    transition: color 0.2s;

    &:hover {
      color: color(purple, dark);
    }
  }

  .ContactDetailsModal {
    justify-content: flex-start;
    align-items: stretch;

    .content {
      position: relative;
      flex: 1;
      max-width: 100%;
      max-height: 100%;
      margin: 40px 30px;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;

      &.add-shift-form-container {
        max-width: 600px;
      }

      .modal-close-circle {
        right: 15px;
        top: 15px;
      }
    }

    .ModalHeader {
      display: none;
    }
  }

  .invite-button {
    margin-left: 10px;

    button {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    }
  }

  .filter-button {
    margin-left: auto;
  }

  .export-button {
    margin-left: 10px;

    button {
      background-color: #ffffff;
      color: color(purple, dark);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

      &:hover {
        color: color(purple, base);
      }
    }
  }

  .MemberManagement-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    .search {
      margin-bottom: 20px;

      .create {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }

      .TextField {
        max-width: 50%;
        margin-bottom: 10px;

        i {
          background-color: color(light, secondaryBg);
          color: color(light, fontColor);
        }

        input {
          background-color: color(light, secondaryBg);
        }
      }
      .TableComponent {
        .table-wrap {
          height: unset;
        }
      }
    }

    .SortableTable {
      .table-row,
      .table-header {
        .col {
          &.user_display_name {
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &.role_name {
            max-width: 250px;
            min-width: 250px;
          }
        }
      }

      .table-row {
        .col {
          &.role_name {
            .Dropdown {
              min-width: 250px;

              .input-wrap {
                background-color: #fff;
              }
            }
          }

          &.user_display_name {
            font-weight: 700;
          }

          &.user_capacity {
            flex-wrap: wrap;
            padding-top: 5px;
            padding-bottom: 5px;
            font-weight: 700;

            span {
              margin: 4px 8px 4px 0px;
              text-transform: capitalize;
              padding: 6px 10px;
              border-radius: 24px;

              @include userLabelColor;
            }
          }
        }
      }
    }
  }
}
