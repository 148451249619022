@import '../../styles/variables.scss';

.VolunteerApplicationForm {
  width: 100%;
  .account-error {
    display: flex;
    margin: 20px 40px 0;
    justify-content: center;
    align-items: center;
    background-color: color(red, light);
    border-radius: 20px;
    padding: 20px;

    i {
      font-size: 50px;
      color: color(red, dark);
    }

    p {
      flex: 1;
      padding: 0 40px;
      font-size: 16px;
      line-height: 24px;
      color: color(red, dark);
    }

    a {
      display: flex;
      height: 40px;
      width: auto;
      text-decoration: none;
      background-color: color(red, dark);
      justify-content: center;
      align-items: center;
      padding: 0 22px;
      border-radius: 20px;
      color: color(dark, fontColor);
      font-weight: 500;

      &:hover {
        background-color: color(purple);
      }
    }
  }

  .Volunteer-info {
    display: flex;
    border-top: 1px solid color(grey, lighter);
    border-bottom: 1px solid color(grey, lighter);
    padding: 20px 0;
    margin: 40px 0;

    .circle-wrap {
      .circle {
        display: flex;
        width: 40px;
        height: 40px;
        background-color: color(grey, lighter);
        border-radius: 50%;
        justify-content: center;
        align-items: center;
      }
    }

    .sect {
      flex: 1;
      display: flex;
      border-right: 1px solid color(grey, lighter);
      justify-content: center;
      align-items: center;
      width: 25%;

      &:last-child {
        border-right: 0;
      }

      .Volunteer-content {
        display: flex;
        flex-direction: column;
        padding-left: 8px;

        span {
          font-size: 12px;

          &:first-child {
            font-weight: 700;
          }

          &:last-child {
            color: color(grey);
            padding-top: 4px;
          }
        }
      }
    }
  }

  .TextField,
  .Dropdown {
    .input-wrap {
      background: color(light, secondaryBg);
      border: 1px solid color(grey, border);
      border-radius: 10px;
    }

    label {
      span {
        font-family: $lato;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: color(plum, dark);
      }
    }
  }

  .inner-content {
    padding: 0 20px;
    h1,
    h2 {
      width: 100%;
      padding: 40px 0 15px 0;
      color: color(plum, dark);
      font-family: $raleway;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      font-feature-settings:
        'pnum' on,
        'lnum' on,
        'salt' on,
        'ss09' on;

      &:first-child {
        padding-top: 0;
      }
    }

    // .row {
    //     display:flex;
    //     margin:20px 0;
    //     justify-content: space-between;

    //     &.2-1 {
    //         div:first-of-type {
    //             margin-right: 10px;
    //         }
    //     }
    // }

    .details-row {
      margin-bottom: 20px;
      display: flex;

      .tac-link {
        font-size: 14px;
        font-weight: 800;
        color: #5e51ab;
        text-decoration: none;
        margin-left: 2px;
        padding-top: 2px;
        &:visited {
          color: #5e51ab;
          text-decoration: none;
        }
      }

      .TextArea.comment-text-field {
        width: 100%;
        height: 150px;

        .remaining-characters {
          color: color(plum, dark);
        }

        label textarea {
          height: 105px;
          background: color(light, secondaryBg);
          border: 1px solid color(grey, border);
          border-radius: 10px;
        }

        label .placeholder {
          font-family: $lato;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          color: color(plum, dark);
          margin-left: 0;
          opacity: 1;
          top: -2px;
        }
      }
      .Dropdown {
        flex: 1;
      }

      .Checkbox {
        label {
          color: color(grey);
          font-weight: 500;
          font-size: 14px;
        }
      }

      .details-col {
        flex: 1;
        margin-right: 20px;

        .Checkbox {
          label {
            justify-content: flex-start;
          }
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    h2.add-sep {
      border-top: 1px solid color(grey, lighter);
      padding-top: 30px;
    }

    .Checkbox {
      label {
        justify-content: flex-start;
        margin-bottom: 5px;
      }
    }

    .AvailabilitySettings {
      .Checkbox {
        label {
          justify-content: center;
          margin-bottom: 0;
        }
      }
    }

    .question {
      margin-bottom: 40px;

      .label {
        padding-bottom: 5px;
        font-size: 14px;
        font-weight: 700;
      }

      .Checkbox {
        label {
          justify-content: flex-start;
          margin-bottom: 5px;

          input {
            margin: 0;
          }
        }
      }
    }

    .apply-actions {
      margin-top: 50px;
      display: flex;
      justify-content: flex-end;
      column-gap: 18px;

      .Button {
        width: 255px;

        button {
          max-width: unset;
          width: 100%;
        }
      }
    }
  }

  .load-wrap {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    .Loader {
      margin-top: 40px;
      i {
        font-size: 31px;
      }
    }
  }

  .availability {
    height: 220px;
    background: color(light, secondaryBg);
    border: 1px solid color(cream, grey);
    border-radius: 10px;
    overflow: hidden;

    .table-container {
      table {
        border: unset;

        tr {
          &:first-of-type {
            border-bottom: 1px solid color(cream, grey);
          }
        }

        th {
          &.time-th {
            align-items: flex-start;
            width: 100px;
            padding-left: 24px;
          }
        }

        td,
        th {
          border: unset;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          color: black;
          background: color(light, secondaryBg);
        }
      }
    }
  }

  .shift-wrap {
    span {
      font-size: 14px;
      display: inline-block;
      margin-bottom: 10px;
      color: color(purple, base);
    }
    .shifts {
      max-height: 650px;
      overflow: auto;

      .shift-summary {
        display: flex;

        span {
          font-size: 12px;
          padding-left: 8px;
          color: #6a6a6a;
          font-weight: 500;
          margin-top: 5px;
        }
      }
    }
    .month {
      padding-bottom: 20px;

      .month-name {
        display: inline-block;
        padding-bottom: 5px;
        font-size: 18px;
        color: color(purple, dark);
      }
    }

    ul {
      padding: 0px;
      margin: 0px;

      .day {
        list-style-type: none;
        padding: 5px 20px;
        margin-bottom: 10px;

        &:nth-child(odd) {
          background-color: color(cream, lighter);
        }

        &:last-child {
          margin-bottom: 0;
        }

        .day-name {
          display: inline-block;
          padding-bottom: 5px;
          color: color(grey, dark);
        }

        .Checkbox {
          p {
            font-size: 15px;
            color: color(grey, darker);
          }
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .VolunteerApplicationForm {
    .inner-content .details-row {
      display: flex;
      flex-direction: column;

      .details-col {
        margin-bottom: 10px;
      }
    }

    .inner-content .apply-actions {
      justify-content: flex-start;
    }
  }
}
