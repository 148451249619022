@import '../../../styles/variables.scss';

.AuctionAdminLeaderboardModal {
  .leaderboard-modal {
    background-color: color(light, secondaryBg);
    flex: 1;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;

    .leader-actions {
      padding: 0px 40px 40px;

      .Button {
        button {
          width: 100%;
        }
      }
    }

    .leaders {
      padding: 0px;
      margin: 40px 40px 60px 40px;
      display: flex;
      flex-direction: column;
      max-height: 300px;

      .no-bids {
        padding: 30px 0px 60px;
        color: color(grey);
        display: flex;
        flex: 1;
        justify-content: center;
      }

      .leader {
        display: flex;
        width: 100%;
        position: relative;
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid color(grey, light);

        &.no-border {
          border-bottom: 0px;
        }

        .leader-details {
          margin-left: 20px;

          .name {
            display: flex;
            align-items: flex-end;

            span {
              font-size: 18px;
              padding-right: 0px;
            }

            .winner,
            .set-winner {
              display: inline-block;
              margin-left: 20px;

              span {
                font-size: 14px;
              }
            }

            .winner {
              background-color: color(green, light);
              color: color(green, dark);
              padding: 2px 8px;
              border-radius: 16px;

              i {
                margin-right: 4px;
              }
            }

            .set-winner {
              text-decoration: underline;
              color: color(grey);
              font-size: 14px;

              &:hover {
                color: color(purple, dark);
                cursor: pointer;
              }
            }
          }

          .bid {
            font-size: 16px;
            font-weight: 700;
            margin: 2px 0px;

            .payment-status {
              font-weight: 400;
              margin-left: 8px;
              font-size: 12px;

              i {
                margin-right: 4px;
              }

              &.not-paid {
                color: color(red, dark);
              }

              &.paid {
                color: color(green, dark);
              }

              &.payment-pending {
                color: color(grey);
              }
            }
          }

          .count {
            font-size: 14px;
            color: color(grey);
          }
        }

        .right {
          display: flex;
          flex: 1;
          justify-content: flex-end;
          align-items: center;
          min-width: 300px;
          margin-right: 10px;

          .contact {
            width: 48px;
            height: 48px;
            box-shadow: $box-shadow;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-left: 6px;
            border: 2px solid color(light, primaryBg);
            text-decoration: none;

            i {
              font-size: 23px;
              color: color(grey);
            }

            &:hover {
              border: 2px solid color(purple, dark);
              cursor: pointer;

              i {
                color: color(purple, dark);
              }
            }

            &.context-menu {
              .ContextMenu {
                position: absolute;
                top: 60px;
                right: -15px;
                line-height: 40px;
                box-shadow: $box-shadow;
                border-radius: 20px;

                a {
                  justify-content: flex-start;
                }

                span {
                  &:hover {
                    color: color(purple, dark) !important;
                  }
                }

                i {
                  font-size: 16px;
                  padding-right: 8px;
                  width: 15px;
                  text-align: center;
                }
              }

              .ContextMenu {
                display: none;
                position: absolute;
                top: 60px;
                right: -15px;
                line-height: 40px;
                box-shadow: $box-shadow;
                border-radius: 20px;

                a {
                  justify-content: flex-start;
                }

                span {
                  &:hover {
                    color: color(purple, dark) !important;
                  }
                }

                i {
                  font-size: 16px;
                  padding-right: 8px;
                  width: 15px;
                  text-align: center;
                }

                &:hover {
                  display: block;
                }
              }

              i.fa-ellipsis-h-alt:hover + .ContextMenu {
                display: block;
              }
            }
          }
        }

        .place {
          position: absolute;
          top: 0px;
          left: 0px;
          background-color: color(purple, dark);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          height: 18px;
          width: 18px;
          color: color(dark, fontColor);
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
}
