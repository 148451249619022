@import '../../../../styles/variables.scss';

.Fundraisers .Contact-menu,
.Sponsor .Contact-menu,
.ContactSupport .Contact-menu,
.ContactDonor .Contact-menu,
.Contact .Contact-menu {
  li {
    &:hover {
      color: #012939;
      cursor: default !important;
    }
  }

  .CustomFieldsLibrary {
    .title {
      padding: 20px 40px;
      border-bottom: 1px solid color(grey, light);
      font-size: 24px;
      font-weight: 700;
    }

    .delete-msg {
      border: 0px;
      color: color(light, fontColor);
      padding: 40px;
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
    }

    .delete-actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      padding: 0px 40px 20px;

      .Button {
        &.no {
          button {
            border: 2px solid color(grey, light);
            background-color: transparent;

            .text {
              color: color(grey);
            }

            &:hover {
              border: 2px solid color(purple);

              .text {
                color: color(purple);
              }
            }
          }
        }
      }
    }

    .main-list {
      list-style-type: none;
      padding: 20px 0px;
      margin: 0px;

      li {
        height: auto;
        margin-right: 0px;
        padding: 0px;

        .label-title {
          display: flex;
          margin-right: 0px;
          padding: 10px 40px;

          &:hover {
            cursor: pointer !important;

            i,
            span {
              color: color(purple, dark);
            }
          }

          i {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            width: 20px;
          }

          span {
            padding: 0px;
            height: auto;
            border: 0px;
            color: #012939;
            text-transform: capitalize;
          }
        }

        .expanded-list {
          list-style-type: none;
          padding: 0px;

          li {
            display: flex;
            align-items: center;
            padding: 5px 30px 5px 80px;
            height: 30px;

            &:hover {
              background-color: color(cream, dark);
              cursor: pointer;

              .field-actions {
                display: flex;
              }
            }

            .field {
              flex: 1;
              font-size: 14px;
              color: color(grey);
            }

            .field-actions {
              display: none;

              .Button {
                button {
                  min-height: 30px;
                  padding: 0px;

                  .text {
                    font-size: 12px;
                    height: auto;
                  }
                }

                &.delete {
                  button {
                    background-color: color(red, dark);

                    &:hover {
                      background-color: color(red, light);

                      .text {
                        color: color(red, dark);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
