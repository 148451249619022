@import '../../../styles/variables.scss';

@keyframes slideDown {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.ApplicationFormBuilder {
  display: flex;
  flex-direction: column;

  .Modal.AddFormOptionModal {
    .content {
      padding: 16px;

      .ModalHeader {
        position: relative;
        height: 44px;

        .close-button {
          display: none;
        }

        .modal-close-icon {
          margin: 0;
          position: absolute;
          top: 0;
          right: 7px;
        }
      }
    }

    .add-option-modal {
      display: flex;
      flex-direction: column;
      padding: 0 24px;
      text-align: center;

      .TextField {
        margin-bottom: 16px;
        text-align: left;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: color(light, secondaryBg);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    opacity: 0.1;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }

  .title-row {
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid color(grey, lightest);

    &.no-border {
      border-bottom: 0;
    }

    &:hover {
      cursor: pointer;
      color: color(purple, dark);
    }

    i {
      transition: all 0.2s;
      border-radius: 50%;
      padding: 16px;
      color: color(grey, dark);

      &:hover {
        background-color: color(grey, pale);
      }
    }
  }

  .questions {
    animation: 150ms slideDown ease-out;

    &.no-border {
      border-bottom: 0;
    }

    .form-title {
      margin: 20px 0;
    }

    .question {
      background: color(light, secondaryBg);
      border: 1px solid color(light, shadow);
      border-radius: 10px;
      margin: 15px 0;
      animation: 150ms slideDown ease-out;
      padding: 8px;

      .options {
        color: color(grey, dark);

        .opt-title {
          margin-top: 16px;
          display: block;
          font-size: 14px;
          font-weight: 600;
        }

        .options-inner {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          width: 50%;
          flex-direction: column;

          .option {
            display: flex;
            justify-content: space-between;
            width: 100%;

            span {
              flex-grow: 1;
            }

            .opt-actions {
              display: flex;
              width: max-content;
              flex-grow: 0;

              .fa-pencil {
                margin-right: 24px;
                margin-left: 64px;
              }
            }
          }
        }
      }

      .question-type-container {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px 16px 8px;
        border-bottom: 1px solid color(grey, base3);

        &.no-border {
          margin-bottom: 0;
          padding-bottom: 0;
          border: unset;
        }

        .question-type-title {
          font-weight: 900;
          font-size: 16px;
          color: color(grey, dark);
        }

        .actions {
          i {
            cursor: pointer;
            transition: all 0.2s;
            border-radius: 50%;
            padding: 16px;
            color: color(grey, dark);

            &:hover {
              background-color: color(grey, pale);
            }
          }
        }
      }
    }

    .row {
      display: flex;

      .actions {
        display: none;
        min-width: 50px;
        justify-content: center;
        align-items: center;

        i {
          margin-top: 20px;
        }

        &:hover {
          cursor: pointer;
          color: color(purple, darker);
        }
      }

      &:hover {
        .actions {
          display: flex;
        }
      }

      .col {
        flex: 1;

        &:first-of-type {
          margin-right: 10px;
        }
      }
    }

    .option {
      display: flex;
      justify-items: center;
      align-items: center;
      margin: 15px 0;
      width: max-content;
      animation: 150ms slideDown ease-out;
      transition: all 0.2s;

      &:hover {
        color: color(purple, darker);
        cursor: pointer;
      }

      span {
        margin: 0 8px 0 8px;
      }

      &.add-opt {
        color: color(blue, soft2);

        i {
          display: block;
          font-size: 8px;
          margin: 5px 5px 0 0;
        }
      }
    }
    .question-actions {
      list-style-type: none;
      padding-left: 0;

      &:hover {
        cursor: pointer;
        color: color(purple, darker);
      }
    }
  }

  .add {
    position: relative;
    user-select: none;
    height: 76px;
    background: color(blue, whiteBlue);
    border: 1px solid color(blue, soft2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 18px;

    .action {
      margin-left: 24px;
      font-size: 16px;
      font-weight: 700;
      color: color(blue, soft2);

      i {
        margin-right: 5px;
      }
    }

    .options {
      display: none;
      padding: 10px;
      position: absolute;
      left: 0;
      top: 58px;
      transition: 250ms all ease-in;
      background: #fff;
      width: 250px;
      border-radius: $border-radius-minimal;
      box-shadow: $box-shadow;

      ul {
        margin: 0;
        list-style-type: none;
        padding-left: 0;

        li {
          font-weight: 500;
          color: #000;
          padding-left: 8px;
          margin: 15px 0;

          &:hover {
            color: color(purple, dark);
            cursor: pointer;
          }
        }
      }
    }

    &:hover {
      cursor: pointer;
      color: color(purple, dark);
      .options {
        display: flex;

        ul {
          li {
            &:hover {
              color: color(purple, dark);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
