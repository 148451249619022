@import '../../../styles/variables.scss';

.ChatSettings {
  display: flex;
  padding: 25px;
  flex-direction: column;
  align-items: center;

  .settings-list {
    width: 100%;
  }

  .settings-list {
    .setting {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
      border-top: 1px solid color(grey, lightest);
      border-bottom: 1px solid color(grey, lightest);
      padding: 10px 0;
    }
  }
}
