@import '../../../styles/variables.scss';

.FundraiserProgressBar {
  margin-top: 14px;
  width: 100%;

  .amt {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    span {
      &:first-child {
        font-size: 22px;
        font-weight: 900;
      }

      &:last-child {
        font-size: 14px;
        font-weight: 700;
        color: color(grey);
        text-align: end;
      }
    }
  }

  .bar-wrap {
    padding-top: 5px;

    .bar {
      display: flex;
      align-items: center;
      position: relative;
      height: 10px;
      background-color: color(grey, light);
      border-radius: 8px;
      width: 100%;

      .bar-inner {
        background: linear-gradient(90deg, color(purple, darkest) 0%, color(purple) 100%);
        margin-left: 2px;
        border-radius: 6px;
        width: 10%;
        height: 6px;
        background-color: color(grey);
      }
    }
  }
}
