@import '../../styles/variables.scss';

.contact-modal {
  .content {
    min-width: 350px;
    .ModalHeader {
      border-bottom: none !important;
      margin-bottom: 0px !important;
      margin-top: 20px;
    }

    .contact-organizer-container {
      margin-bottom: 45px;

      div {
        display: flex !important;
        align-items: center;
        border-radius: 10px;
        padding-top: 5px;
        padding-bottom: 5px;

        a {
          color: black;
          text-decoration: none;

          &:hover {
            color: color(purple, light) !important;
            cursor: pointer;
          }
        }

        i {
          margin-right: 10px;
          margin-top: 5px;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a {
        color: white;
        background: color(plum, dark);
        width: 100%;
        min-height: 40px;
        text-decoration: none;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
      }

      .Button {
        width: 100%;
        margin-bottom: 20px !important;

        button {
          width: 100%;
        }
      }
    }
  }
}
