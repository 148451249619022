@import '../../styles/variables.scss';

.ResetPassword {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: color(cream, dark);

  &:before {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 50%;
    height: 1000px;
    width: 1000px;
    background-color: color(cream, light);
    margin: -500px -500px 0px 0px;
    border-radius: 50%;
  }

  ul {
    li {
      &.valid {
        color: green;
      }
    }
  }

  .ResetPassword-inner {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
    padding: 40px 20px;

    .title {
      font-size: 28px;
      font-weight: 700;
      padding: 0px 0px 15px 0px;
      color: color(grey, dark);
    }

    .logo {
      max-width: 150px;
      margin: 0px auto 30px;
    }

    p {
      font-size: 21px;
      max-width: 320px;
      margin: 0px auto 40px;
      text-align: center;
      font-weight: 500;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .Button,
      .Button button {
        flex: 1;
        width: 100%;

        &:disabled {
          background-color: color(grey, lighter);
        }
      }
    }

    .links {
      margin: 30px 0px 0px;
      display: flex;
      flex: 1;
      justify-content: space-between;

      a {
        color: color(grey);
        text-decoration: none;
        font-weight: 500;
        font-size: 15px;
      }
    }

    .row {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 620px;
      margin: 0px auto;

      .col {
        flex: 1;
        padding-bottom: 15px;

        .TextField,
        .Dropdown {
          .input-wrap {
            border-radius: 8px;
            border: 1px solid #c6d1d5;

            input {
              background-color: #f5f6f7;
            }
          }

          label {
            span {
              color: color(grey, dark);
            }
          }
        }

        &:nth-child(2) {
          padding-bottom: 10px;
        }
      }
    }

    .error-message {
      font-size: 13px;
      min-height: 17px;
      color: color(red, dark);
    }

    .dob {
      display: flex;
      flex: 1;
      max-width: 620px;
      margin: 0px auto;
      padding-bottom: 20px;

      .col {
        flex: 1;

        .TextField {
          margin-right: 20px;
        }

        &:last-child {
          .TextField {
            margin-right: 0px;
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .ResetPassword {
    .ResetPassword-inner {
      margin: 20px;
    }
  }
}
