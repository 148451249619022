@import '../../styles/variables.scss';

.Setup {
  background-color: #f5f6f7;
  display: flex;
  justify-content: center;
  width: 100%;

  .content-wrap {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    padding: 0 285px;
    margin: 40px 0;
    flex: 1;
    .profile-cta {
      display: flex;
      flex-direction: row;
      font-size: 18px;

      a {
        color: color(purple, dark);

        &:hover {
          color: color(purple);
        }
      }
    }
    .header-wrap {
      margin-bottom: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        color: color(light, fontColor);
        font-size: 34px;
        margin-top: 0;
        margin-bottom: 10px;
      }

      span.tagline {
        font-size: 20px;
        color: #484b4d;
      }
    }
    .actions-wrap {
      display: flex;
      width: 100%;
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-left: 0;
        list-style-type: none;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-height: 315px;
          width: 22%;
          min-width: 215px;
          max-width: 275px;
          background: #fff;
          border-radius: 10px;
          margin: 12px;
          text-align: center;
          cursor: pointer;

          div {
            text-align: center;
            height: 100%;
            .list-circle {
              margin-top: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: auto;
              margin-right: auto;
              border-radius: 50%;
              height: 140px;
              width: 140px;
              margin-bottom: 30px;

              &.find-events {
                background-color: color(purple, lightest);
                svg {
                  path:nth-child(1) {
                    stroke: color(purple, dark);
                  }
                  path:nth-child(2) {
                    fill: color(purple, dark);
                    stroke: color(purple, lightest);
                  }
                }
              }
              &.donate {
                background-color: color(purple, lightest);
                svg {
                  path {
                    fill: color(purple, dark);
                    stroke: color(purple, lightest);
                  }
                }
              }

              &.donations,
              &.event-fundraiser,
              &.volunteer-paid,
              &.auction,
              &.store,
              &.volunteer {
                background-color: color(purple, lightest);
                svg {
                  path {
                    fill: color(purple, dark);
                  }
                }
              }
            }

            span:last-of-type {
              margin-top: 15px;
              font-size: 18px;
              color: color(light, fontColor);
              font-weight: 500;
              cursor: pointer;
            }

            p {
              padding: 0 15px;
              color: #88949a;
              font-size: 14px;
              line-height: 1.5;
              margin-top: 5px;
              margin-bottom: 0;
            }
          }

          &:hover {
            box-shadow: $box-shadow;
          }

          &:nth-of-type(5),
          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media (max-height: 875px) {
  .Setup .content-wrap .actions-wrap ul li {
    min-height: 238px;
  }
}
@media (max-width: 1280px) {
  .Setup {
    .content-wrap {
      padding: 0 40px;
    }
  }
}

@media (max-width: 1225px) {
  .Setup {
    .content-wrap {
      .actions-wrap {
        ul {
          li {
            width: 47%;
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .Setup {
    .content-wrap {
      padding: 0 40px;
    }
  }
}

@media (max-width: 480px) {
  .Setup {
    .content-wrap {
      .actions-wrap {
        ul {
          li {
            width: 100%;
            margin: 10px 0;
            &:nth-of-type(even) {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
