@import '../../styles/variables.scss';

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.light {
  .SearchResultCard {
    background-color: color(light, secondaryBg);
  }
}

.dark {
  .SearchResultCard {
    background-color: color(dark, secondaryBg);
    color: color(dark, fontColor);
  }
}

.MapListItem-wrap {
  &:hover {
    .SearchResultCard {
      .MapListItem-inner {
        .image-container {
          overflow: hidden;
          .bg-image-container {
            transform: scale(1.1);
          }
          .overlay {
            display: block;
          }

          .floating-actions {
            .actions {
              .follow,
              .share {
                cursor: pointer;
                display: flex;
                opacity: 1;
              }

              .virtual {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
.SearchResultCard {
  max-height: 290px;
  background-color: color(light, secondaryBg);
  border-radius: 7px;
  width: 100%;
  //max-width: 363px;
  height: 100%;
  min-height: 260px;
  border-radius: 10px;
  box-shadow: 0px 16px 28px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  aspect-ratio: 4/3;
  scroll-snap-align: center;

  &.hide {
    display: none;
  }

  &:hover {
    cursor: pointer;
    box-shadow: $box-shadow-darker;
  }
  .MapListItem-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    .vm-powered-by {
      position: absolute;
      top: 18px;
      right: 18px;
    }
    .virtual {
      display: flex;
      border: 0px;
      margin: 20px;
      padding: 10px 20px;
      font-size: 16px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      color: color(purple, dark);
      background-color: #efeef6;
      overflow: hidden;
      min-width: 40px;
      min-height: 40px;
      cursor: pointer;

      span:last-of-type {
        margin-left: 10px;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.6px;
      }
    }

    .share {
      margin: 15px;
      display: none;
      opacity: 0;
      border: 0px;
      font-size: 16px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      color: color(grey, almostdark);
      background-color: color(grey, pale);
      overflow: hidden;
      min-width: 40px;
      min-height: 40px;
      cursor: pointer;

      &:hover {
        background-color: color(purple, lightest);
        color: color(purple, dark);
      }
    }

    .follow {
      margin-right: 20px;
      margin-top: 14px;
      display: none;
      opacity: 0;
      border: 0px;
      padding: 8px 24px;
      font-size: 16px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      color: color(grey, almostdark);
      background-color: color(grey, pale);
      overflow: hidden;
      max-height: 41px;
      background-color: color(purple, lightest);
      color: color(purple, dark);
    }
    .mobile {
      display: none;
    }

    .image-container {
      position: relative;
      overflow: hidden;
      .floating-actions {
        display: flex;
        justify-content: space-between;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;

        .actions {
          display: flex;
          flex-direction: row;
          flex: 1;
          justify-content: flex-end;

          .share {
            display: none;
            border: 0px;
            font-size: 16px;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            color: color(grey, almostdark);
            background-color: color(grey, pale);
            overflow: hidden;
            cursor: pointer;
            width: 38px;
            height: 38px;
            margin-right: 10px;

            &:hover {
              background-color: color(purple, lightest);
              color: color(purple, dark);
            }
          }

          .follow {
            display: none;
            border: 0px;

            font-size: 16px;
            justify-content: center;
            align-items: center;
            color: color(grey, almostdark);
            background-color: color(grey, pale);
            overflow: hidden;
            height: 42px;
            padding: 10px 20px;
            border-radius: $border-radius;
            &:hover {
              background-color: color(purple, lightest);
              color: color(purple, dark);
            }
          }
        }
      }
    }

    .bg-image-container {
      background-color: color(purple, base);
      position: relative;
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 159px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;
      transition: all 0.25s ease-in-out;
    }

    .Portrait {
      top: 18px;
      left: 18px;
      position: absolute;
    }

    .overlay {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.15);
      z-index: 2;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      transition: all s ease-in;
    }

    &.gig {
      .MapListItem-info {
        .type {
          text-transform: capitalize;
          font-size: 14px;
          font-weight: 700;
          color: color(purple, base);
        }

        .title {
          margin-bottom: 4px;
        }
      }
    }

    &.event {
      .MapListItem-info {
        height: 100px;

        .title {
          margin-bottom: 4px;
        }
        .description {
          font-weight: normal;
        }
      }
    }

    .MapListItem-info {
      display: flex;
      flex-direction: column;
      padding: 20px;
      height: 62px;
      justify-content: center;

      .date,
      .type {
        font-size: 14px;
        color: color(grey, light);
        font-weight: 700;
        letter-spacing: 0.4px;
      }
      .created-by {
        color: color(grey);
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .title {
        font-size: 16px;
        font-weight: 800;
        line-height: 19px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8px;
      }
      .organizer {
        margin: 0;
        font-size: 14px;
        font-weight: 700;
        color: color(grey, light);
      }
      .description {
        color: color(grey, light);
        font-size: 12px;
        font-weight: 700;
        overflow: hidden;
        line-clamp: 1;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.event {
          margin-top: 12px;
          font-weight: 700;
        }
      }
    }

    &.event {
      title {
        padding-bottom: 0;
      }
    }
  }
}

@media (max-width: 500px) {
  .SearchResultCard {
    min-width: 260px;
  }
}
