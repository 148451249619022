@import '../../../styles/variables.scss';

.HubOnBoardingGuide {
  .content {
    width: 700px;
    max-height: 700px;

    .header-container {
      width: 100%;
    }

    .header-description {
      max-width: 421px;
    }

    .inner-content {
      border-top: 2px solid color(dark, borderLight);
      margin: 40px 48px 0 48px;
      overflow: auto;
      padding: 0 8px 16px 8px;
      @include thin-scroller-y;

      h4 {
        margin: 31px 0 5px 0;
      }

      p {
        margin: 0 0 39px 0;
        font-weight: 600;
        font-size: 14px;
        color: color(grey, text);
      }

      .InviteStep {
        margin: 22px 0;
        row-gap: 22px;
        display: flex;
        flex-direction: column;
        position: relative;

        .over-maximum-container {
          position: fixed;
          left: 0;
          bottom: 73px;
          background: color(yellow, fade);
          width: 100%;
          height: 82px;
          display: flex;
          align-items: center;
          column-gap: 22px;

          i {
            margin-left: 30px;
            color: color(yellow, text);
            font-size: 20px;
          }

          p {
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            color: color(grey, almostdark);
          }
        }

        .counter {
          &.over {
            color: color(red, soft);
          }
        }

        .search-container {
          .TextField {
            i {
              color: color(grey, border);
            }

            .input-wrap {
              max-width: 603px;
              height: 44px;
              background: color(light, secondaryBg);
              border: 1px solid color(grey, border);
              border-radius: 30px;
            }
          }
        }

        .user-list {
          display: flex;
          flex-direction: column;
          row-gap: 24px;
          padding: 24px 0;

          .Loader {
            place-self: center;
          }

          .item {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
          }

          .info {
            display: flex;
            column-gap: 16px;

            .display_name {
              font-weight: 400;
              font-size: 14px;
              letter-spacing: 0.280123px;
              color: black;
            }
          }
        }
      }
    }

    .form-logo {
      border: 2px dotted color(grey, pale);
      box-sizing: border-box;
      border-radius: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 0;

      .cover-image-container {
        min-height: 250px;
        min-width: 200px;
        width: 90%;
        &.empty {
          .CoverImage {
            min-height: 250px;
            max-height: 250px;
            width: 100%;
          }
        }
        .CoverImage {
          max-height: 250px;
          background-color: color(grey, imgBg);
        }
      }

      .logo-container {
        width: max-content;
      }
    }

    .BannerLayoutSettings {
      width: 100%;
      align-items: center;
      justify-content: center;

      .banner-layout-container {
        p {
          margin: 8px 0;
          font-size: 6px;
        }
      }
    }

    .bottom-container {
      height: 84px;
      border-top: 1px solid color(light, shadow);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;

      .back,
      .next {
        width: 200px;
      }

      .step-indicator {
        display: flex;
        column-gap: 8px;
        width: max-content;

        .circle {
          width: 8px;
          height: 8px;
          background: color(light, shadow);
          border-radius: 4px;
          transition: all 0.2s;

          &.active {
            background: color(purple, dark);
          }
        }
      }

      .next {
        display: flex;
        column-gap: 16px;
      }
    }

    .color-container {
      display: flex;
      column-gap: 30px;

      label {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 4px;
      }

      .wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 50%;

        .color {
          width: 100%;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: color(light, secondaryBg);
          border: 1px solid color(grey, border);
          box-sizing: border-box;
          border-radius: 8px;
          cursor: pointer;
          column-gap: 13px;

          .inner {
            width: calc(100% - 48px);
            height: 28px;
            border: 1px solid color(grey, border);
            box-sizing: border-box;
            border-radius: 4px;
          }
        }
      }

      .custom-picker-container {
        margin-top: 15px;
        width: 184px !important;
        position: absolute;
        top: 70px;
      }
    }
  }
}
