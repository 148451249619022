@import '../../../styles/variables.scss';

.ForOrganizationsCollapseItem {
  color: color(plum, darker);
  width: 100%;

  a {
    color: color(plum, darker);
  }

  hr {
    width: 100%;
    height: 0;
    opacity: 0.1;
    border: 2px solid color(plum, dark);
    margin: 62px 0;
  }

  h3 {
    margin: 0;
    font-weight: 700;
    font-size: 28px;
    line-height: 39px;

    .toggle-icon {
      margin-left: 10px;

      img {
        cursor: pointer;
        width: 12.57px;
        height: 18.55px;
        transform: rotate(0deg);
        transition: all 0.2s;
      }

      &.close > img {
        transform: rotate(180deg);
      }
    }
  }

  .description-container {
    height: 100px;
    overflow: hidden;
    transition: all 0.2s;
    margin-top: 10px;

    &.close {
      height: 0;
    }

    p {
      margin: 0 0 40px 0;
      max-width: 633px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      font-family: $lato;
    }

    span.link {
      width: 195px;
      height: 40px;
      font-weight: 700;
      font-size: 14px;
      line-height: 39px;

      i {
        margin-left: 10px;
      }
    }
  }
}
