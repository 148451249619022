@import '../../../styles/variables.scss';

.UserStats {
  display: block;
  padding: 0 20px;

  .stat-container {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0;
    padding: 0;
  }

  .title {
    h3 {
      margin: 0;
    }
  }

  .stat-box {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    width: 163px;
    height: 175px;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 7px 5px 7px 7px;
    background-color: #f5f6f7;
    align-items: center;
    margin-right: 20px;

    .icon-container {
      margin-top: 13px;
      background-color: #ffffff;
      width: 53px;
      height: 53px;
      flex-basis: 53px;
      border-radius: 50%;
    }

    .icon {
      width: 53px;
      height: 53px;
      font-size: 30px;
      line-height: 53px;
      text-align: center;
      color: color(purple, dark);
      font-weight: 300;
    }

    .stat-value {
      font-size: 25px;
      font-weight: 700;
      color: color(light, fontColor);
      margin: 15px 0 0 0;
    }

    .stat-label {
      padding: 0 10px;
      font-size: 16px;
      color: #829096;
      margin: 13px 0 0 0;
      text-align: center;
    }
  }
}

@media (max-width: 680px) {
  .UserStats {
    .stat-container {
      .stat-box {
        flex: unset;
        width: 100%;
        margin-right: unset;
        margin-bottom: 18px;
      }
    }
  }
}
