@import './../../../styles/variables.scss';

.DateDayPickerContainer.floating-label {
  label {
    position: relative;
    left: 10px;
    bottom: -7px;
    background: #fff;
    z-index: 1;
    padding: 5px;
    color: #000;
    font-size: 13px;
    font-weight: 700;
  }
}

.DateDayPickerContainer {
  label {
    padding-bottom: 10px;
    font-size: 13px;
    color: color(purple, dark);
    font-weight: 700;
  }
  .date-input-container {
    display: flex;
    flex-direction: row;
    border-radius: 7px;
    justify-content: center;
    height: 42px;
    border: 1px solid #898989;
    input {
      border: none;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      width: calc(100% - 88px);
    }
    input:focus {
      border: none;
      outline: none;
    }
    button:hover {
      cursor: pointer;
    }
    button {
      border-radius: 7px;
      border: none;
      background: #fff;
      padding: 0 20px;
    }
  }
}
