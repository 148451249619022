@import '../../styles/variables.scss';

.SearchList {
  position: relative;

  .main-icon {
    position: absolute;
    top: 32px;
    left: 14px;
    font-size: 18px;
  }

  .TextField {
    input {
      text-indent: 40px;
    }
  }

  .listing {
    z-index: 2;
    position: absolute;
    top: 62px;
    width: 100%;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    background-color: color(light, secondaryBg);
    max-height: 150px;
    overflow: auto;
    box-shadow: $box-shadow;
    border-radius: 12px;
    border: 1px solid color(grey, light);

    .list-item {
      padding: 8px 10px;
      margin: 0px;

      &.nothing-found {
        font-size: 14px;
        color: color(grey);
        &:hover {
          cursor: unset;
          background-color: color(light, secondaryBg);
        }
      }

      &:hover {
        background-color: color(cream, dark);
        cursor: pointer;
      }

      .list-item-inner {
        display: flex;
        align-items: center;

        .Portrait {
          padding-right: 10px;
        }

        .list-item-label {
          font-size: 14px;
          color: color(grey);
          text-transform: capitalize;
        }
      }
    }
  }
}
