@import '../../styles/variables.scss';

.TransactionManagement {
  flex: 1;
  display: flex;

  .management-section-inner {
    padding: 0;
    margin: 0px 30px 30px;
    border-radius: 15px;
    background-color: #ffffff;
    flex: 1 1;
    display: flex;
  }

  .Modal {
    &.add-store-purchase-modal {
      .content {
        background-color: transparent;
        display: flex;
        flex: 1;
        animation: none;
        opacity: unset;

        .Checkout {
          background-color: transparent;

          .ImageCropper {
            background-color: white;
            border-radius: 8px;
          }

          .Checkout-inner {
            margin-top: 0px;

            .cart-details,
            .checkout-details,
            .admin-store {
              min-width: 680px;
              max-height: 60vh;
              overflow: auto;
            }

            .cart-details,
            .checkout-details {
              border-top-left-radius: 0px;
              border-top-right-radius: 0px;
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .section-title {
    .forms-create {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .forms-title {
        flex: 1;
      }

      .forms-controls {
        display: flex;

        .exports-list {
          position: relative;
          cursor: pointer;
          margin-left: 15px;

          &:hover {
            .ContextMenu {
              display: block;
            }

            button {
              color: color(purple, base);
              box-shadow: none;

              span {
                color: color(purple, base);
              }
            }
          }

          .ContextMenu {
            display: none;
            position: absolute;
            top: 55px;
            right: 10px;
            box-shadow: $box-shadow;
            border-radius: 20px;

            li {
              cursor: pointer;
              font-weight: 400;

              &.default-role {
                display: none;
              }
            }

            i {
              font-size: 16px;
              padding-right: 8px;
            }
          }
        }
      }
    }
  }

  .TransactionManagement-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    .transaction-tabs {
      display: flex;

      div {
        flex: 1;
        cursor: pointer;
        padding-bottom: 5px;
        border-bottom: 1px solid color(grey, lighter);
        margin-bottom: 15px;
        text-align: center;
        font-weight: 400;
        color: color(grey, light);

        &.active {
          border-bottom: 2px solid color(purple, dark);
          color: color(light, fontColor);
          font-weight: 500;
        }
      }
    }

    .search {
      display: flex;
      align-items: center;

      .create {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }

      .TextField {
        max-width: 352px;
        width: 100%;
        background: color(grey, pale);
        border-radius: 10px;

        .input-wrap {
          border: none;
          box-shadow: inset 0px -1px 0px rgba(169, 170, 172, 0.25);
          border-radius: 10px;

          input {
            background: color(grey, pale);
          }
        }

        i {
          padding-top: 3px;
          padding-left: 8px;
          line-height: 42px;
          font-size: 14px;
          color: color(grey, text);
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      flex: 1;

      .headers,
      .row {
        .col {
          display: flex;
          flex: 1;
          font-weight: 700;

          &:first-child {
            text-indent: 8px;
          }

          &.title {
            flex: 1;
          }

          &.portrait {
            max-width: 120px;
          }

          &.created,
          &.updated {
            width: 400px;

            span {
              font-weight: 400;
              margin-right: 8px;

              &:last-child {
                color: color(grey);
                font-weight: 400;
              }
            }
          }

          &.actions {
            justify-content: flex-end;
            padding-right: 40px;
            position: relative;

            .ContextMenu {
              display: none;
              position: absolute;
              top: 55px;
              right: 10px;
              box-shadow: $box-shadow;
              border-radius: 20px;

              li {
                cursor: pointer;

                &.default-role {
                  display: none;
                }
              }

              i {
                font-size: 16px;
                padding-right: 8px;
              }

              &:hover {
                display: block;
              }
            }

            i.fa-ellipsis-h-alt {
              font-size: 24px;
              height: 40px;
              width: 40px;
              background-color: color(blue, lightest);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }

            i.fa-ellipsis-h-alt:hover + .ContextMenu {
              display: block;
            }
          }
        }
      }

      .headers {
        background-color: color(blue, lightest);
        display: flex;
        flex: 0;
        height: 40px;
        line-height: 40px;
      }

      .list-inner {
        flex: 1;
        max-height: 100%;
        overflow: auto;
        position: relative;

        .list-rows {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          .empty {
            flex: 1;
            padding: 30px 20px;
            text-align: center;
            color: color(grey);
            font-size: 16px;
          }
        }

        .row {
          display: flex;
          min-height: 60px;
          border-bottom: 1px solid color(grey, light);

          .col {
            font-weight: 400;
            align-items: center;

            &.role {
              &:hover {
                .Dropdown {
                  .input-wrap {
                    border: 1px solid color(grey, light);

                    i {
                      opacity: 1;
                    }
                  }
                }
              }

              .Dropdown {
                min-width: 200px;
                transform: translateX(-10px);

                .input-wrap {
                  border: 1px solid color(light, secondaryBg);

                  i {
                    opacity: 0;
                  }
                }
              }
            }

            &.portrait {
              justify-content: center;
              max-width: 120px;
            }

            &.capacity {
              span {
                margin-right: 8px;
                text-transform: capitalize;
                padding: 6px 10px;
                border-radius: 24px;

                @include userLabelColor;
              }
            }
          }
        }
      }
    }
  }

  .refund {
    padding: 15px;

    .refund-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      h1 {
        margin: 10px 0;
      }

      div {
        font-size: 16px;
        color: color(grey, light);
      }
    }

    .refund-info {
      .sub-header {
        padding-bottom: 10px;
        border-bottom: 1px solid #e6e6e6;
        color: color(purple, dark);
        margin-top: 25px;
        font-size: 18px;
      }

      .info {
        .info-part {
          margin: 15px 0;
          span {
            font-weight: 500;
          }
        }
      }

      .refund-details {
        margin-top: 15px;
        display: flex;
        flex-direction: row;

        .TextField {
          flex: 2;
          margin-right: 8px;
        }

        .Dropdown {
          flex: 1;
        }
      }

      .refund-actions {
        display: flex;
        justify-content: flex-end;
        margin: 10px 0;
      }
    }
  }
}
