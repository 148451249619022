@import '../../../../../styles/variables.scss';

.ModalSectionRow {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  margin: 20px 0;

  .modal-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .title-container {
      display: flex;
      flex-flow: column nowrap;

      .title {
        font-weight: 700;
        color: black;
        font-size: 14px;
        margin: 0;
      }

      .description {
        color: color(grey, text);
        margin: 0;
      }
    }

    .header-action-button {
      // align-self: flex-end;
    }
  }

  .section-content {
    max-width: 500px;
    margin-left: 15px;
  }
}
