@import '../../styles/variables.scss';

.ReportHoursModal {
  .content {
    min-height: 800px;
    width: 800px;

    .ModalHeader {
      padding: 0 36px;

      .modal-close-icon {
        margin-right: 0;
      }

      .header-container {
        padding: 0;
      }
    }
  }

  .history {
    padding: 36px;
    overflow-y: auto;
    overflow-x: hidden;
    @include thin-scroller-y;

    .TableComponent {
      margin-bottom: 0;

      .status {
        height: 30px;
        line-height: 30px;
        padding: 6px 12px;
        text-align: center;
        border-radius: 6px;

        &.Verified {
          color: color(green, soft);
          background-color: color(green, fade);
        }

        &.Unverified {
          color: color(yellow, text);
          background-color: color(yellow, fade);
        }
      }
    }
  }

  .tabs {
    display: flex;
    column-gap: 16px;
    margin: 16px 0;
    padding: 0 36px;
    font-weight: 600;

    span {
      padding-bottom: 8px;
      text-align: center;
      width: 100px;
      color: color(grey, dark);
      transition: all 0.2s;
      font-size: 16px;
      border-bottom: 1px solid color(light, secondaryBg);
      cursor: pointer;

      &:hover {
        color: color(purple, dark);
      }

      &.active {
        border-bottom: 1px solid color(plum, dark);
        color: color(plum, dark);
      }
    }
  }

  .report-hours-form {
    overflow: auto;
  }

  .form-inner {
    margin: 3em;

    .form-row {
      margin-bottom: 15px;

      label {
        color: color(grey, dark);
        font-weight: 600;
        font-size: 14px;

        span {
          font-size: 14px;
          font-weight: 600;
          color: color(grey, dark);
        }
      }

      .selected-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-top: 10px;

        .title {
          margin: 10px;
        }

        .close {
          &:hover {
            cursor: pointer;
          }
        }
      }

      .custom-dtp {
        input {
          margin-right: 20px;
          margin-top: 5px;
          width: 100%;
        }
      }
    }
  }

  .btn-actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin: 40px 20px 20px 0;

    Button {
      margin-left: 10px;
    }
  }
}

@media (max-width: 680px) {
  .ReportHoursModal {
    .ModalHeader {
      .close-button.left {
        display: none;
      }
    }

    .form-inner {
      margin-top: 5px;
    }

    .content {
      .rdtPicker {
        position: fixed;
        bottom: 280px;
      }
    }

    .tabs {
      margin-top: 0px;
    }

    .btn-actions {
      padding-bottom: 45px;
    }
  }
}
