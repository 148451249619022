@import '../../../../styles/variables.scss';

.FormBottomBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 25px 80px;
  justify-content: space-between;

  &.end {
    justify-content: flex-end;
  }

  .right-action-wrapper {
    display: flex;
    flex-direction: row;
    color: red;
    .Button.StripeElement {
      min-width: 150px;
      border-radius: 20px;
      overflow: hidden;
    }
    .Button {
      &:first-of-type {
        button {
          max-width: unset;
        }
      }
    }
  }

  .Button {
    button {
      font-size: 16px;
      font-weight: 500;
      box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.15);
      &:disabled {
        background-color: color(purple, lightest);
        opacity: 0.5;

        span {
          color: color(purple, lighter);
        }
      }
    }

    &:first-of-type {
      button {
        box-shadow: none;
        // max-width: 95px;
      }
    }

    &.loading {
      button {
        &:disabled {
          opacity: 1;
          background-color: color(purple, dark);
        }
      }
    }

    &.skip-button {
      margin-right: 10px;
      button {
        background: #fff;
        font-weight: 800;
        color: #000;

        &:hover {
          background-color: color(grey, lightest);
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .FormBottomBar {
    bottom: 0;
    padding: 0 28px;
    flex: 1;
    background-color: color(light, secondaryBg);
    height: 90px;
    align-items: center;

    .Button {
      height: max-content;

      button {
        span {
          height: 20px;
          line-height: 20px;

          .fa-angle-left {
            margin-top: 3px;
          }
        }

        &:disabled {
          background-color: color(purple);
          opacity: unset;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .FormBottomBar {
    // position: relative;
    padding: 0 10px;
    column-gap: 6px;
  }
}

@media (max-width: 380px) {
  .FormBottomBar {
    // justify-content: flex-start;
  }
}
