@import '../../../../styles/variables.scss';

@keyframes slideIn {
  0% {
    transform: translateX(-500px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

.ImageSelect {
  display: flex;
  flex-flow: column;
  width: 100%;

  .Loader {
    align-self: center;
    margin-top: 50px;
    font-size: 36px;
  }

  .header {
    display: flex;
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid color(grey, lightest);

    .info {
      display: flex;
      flex-direction: column;
      padding-right: 15px;

      .header-txt {
        color: color(grey, dark);
        font-family: $raleway;
        font-size: 21px;
        font-weight: 900;
        line-height: 24px;
        margin: 8px 0;
      }

      .header-sub-txt {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: #a9aaac;
      }
    }

    .header-action {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      justify-content: center;
      align-items: flex-end;
      position: relative;
      padding-right: 15px;

      .image-selector {
        span {
          color: #bbbcbc;
          font-weight: 700;
          background: color(cream, base);
          display: inline-flex;
          width: 80px;
          height: 40px;
          padding: 0 8px;
          border-radius: 22px;
          place-content: center;
          place-items: center;
        }

        &:hover {
          span {
            background: color(purple, dark);
            color: #fff;
          }
        }
      }
    }
  }

  .ImageUpload {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;

    .FileUpload {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }
  }

  .cover-image-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    padding-left: 0;

    .cover-image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 10px;
      box-sizing: border-box;
      position: relative;
      border: 3px solid transparent;
      overflow: hidden;

      &.selected {
        border: 3px solid color(purple, dark);
      }

      i.show {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: color(purple, dark);
        border-radius: 8px;
        color: white;
        font-size: 14px;

        &:hover {
          cursor: pointer;
        }
      }

      i.hide {
        display: none;
      }

      .cover-image {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        transform: scale(1.03);
        transition: all 0.2s ease-in-out;

        &:hover {
          cursor: pointer;
        }
      }

      .cover-image-selector {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        width: 200px;
        border-radius: 10px;
        background-color: #ffffff;
        color: #cad5d9;
        border: solid #cad5d9 2px;

        &:hover {
          cursor: pointer;
        }

        i {
          font-weight: 100;
          font-size: 40px;
        }
      }
    }
  }

  .avatar-list {
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    padding-left: 0;

    .avatar-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 115px;
      width: 115px;
      border-radius: 50%;
      margin: 0 10px 10px 0;
      box-sizing: border-box;
      position: relative;
      animation: slideIn 300ms ease-in-out forwards;
      box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.35);

      &.selected {
        border: 3px solid color(purple, dark);
      }

      i.show {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        position: absolute;
        right: 6px;
        top: 0px;
        background-color: color(purple, dark);
        border-radius: 50%;
        color: white;
        font-size: 14px;

        &:hover {
          cursor: pointer;
        }
      }

      i.hide {
        display: none;
      }

      .avatar {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;

        &:hover {
          cursor: pointer;
        }
      }

      .avatar-selector {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background-color: #ffffff;
        color: #cad5d9;
        border: solid #cad5d9 2px;

        &:hover {
          cursor: pointer;
        }

        i {
          font-weight: 100;
          font-size: 40px;
        }
      }
    }
  }
  .header-wrap {
    h2 {
      color: color(light, fontColor);
      font-size: 34px;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  .Modal {
    top: -80px;

    .ImageCropper {
      .ReactCrop,
      .no-image {
        img {
          max-height: 300px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .ImageSelect {
    .ImageCropper {
      .title-wrap {
        margin: 0px 0px 15px 0px;
      }
    }
    .Modal {
      top: 0;
      max-height: 100%;
      height: 100%;
      .content {
        height: 100%;
      }
    }
  }
}

@media (max-width: 500px) {
  .ImageSelect {
    .avatar-list {
      justify-content: space-around;
    }
    .cover-image-list {
      .cover-image-wrapper {
        .cover-image {
          width: unset;
          max-height: 100px;
        }
      }
    }
  }
}
