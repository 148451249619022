@import '../../../../../styles/variables.scss';

.ManageTextBlockModal {
  &.Modal {
    .content {
      min-height: 500px;
      max-width: 500px;
    }
  }
  .content-block-container {
    height: 100%;
  }

  .text-area-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 4px;

    label {
      font-size: 14px;
      font-weight: 700;
      color: color(purple, dark);
    }

    .QuillTextEditor {
      .ql-editor {
        a {
          text-decoration: none;
          color: color(purple, dark);

          &:hover {
            cursor: pointer;
            color: color(purple, light);
          }
        }
      }
    }
  }
}
