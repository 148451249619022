@import '../../../styles/variables.scss';

.ComponentPlaceholder {
  background-color: color(light, primaryBg);
  margin: 0 20px;
  border-radius: 8px;

  .container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    padding: 0;
    margin: 0;
    font-weight: 700;
  }

  .message {
    padding: 0;
    margin: 7px 0 0 0;
    font-weight: 400;
  }

  .action-container {
    display: flex;
    justify-content: center;
    margin-top: 18px;
  }

  .action-button {
    &:not(:first-child) {
      margin-left: 20px;
    }

    button {
      background-color: #ed418a;

      &:hover {
        background-color: #6565d3;
      }
    }
  }

  .error-message-container {
    display: flex;
    padding: 15px;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: color(red, dark);
    background-color: color(red, light);
    border-radius: 10px 10px 0 0;
    font-weight: 700;
    .icon {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

@media (max-width: 680px) {
  .ComponentPlaceholder {
    .container {
      padding: 24px;
    }
  }
}
