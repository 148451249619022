.TabsContainer {
  display: flex;
  flex-direction: row;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #cad5d9;
  margin-bottom: 20px;

  .tab {
    padding: 20px 20px;
    cursor: pointer;

    &.selected {
      color: #5e51ab;
      border-bottom: 2px solid #5e51ab;
    }
  }
}
