@import '../../styles/variables.scss';

.EventAdmin {
  display: flex;
  flex-direction: row;
  background-color: color(light, secondaryBg);
  width: 100%;

  .section {
    width: 100%;
    flex: 1;
    padding: 25px;

    &.schedule-wrap {
      padding: 0px;

      .section-wrap {
        .section-title {
          margin: 25px 25px 0px 25px;
        }

        .section-inner {
          margin: 0px 55px 55px 55px;
        }
      }
    }

    .section-wrap {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 100%;
      padding: 0px;

      .section-title {
        display: flex;
        padding: 20px 30px;
        font-size: 32px;
        font-weight: 700;
      }

      .section-inner-title {
        flex: 1 1;
      }

      .section-kpi {
        margin: 0px 30px 30px;
      }

      .section-inner {
        margin: 0px 30px 30px;
        padding: 40px;
        border-radius: 15px;
        background-color: color(light, secondaryBg);
        flex: 1;
        display: flex;

        .settings-item {
          &.disabled:hover {
            cursor: no-drop;
            .settings-control {
              .fad {
                cursor: no-drop;
              }
            }
          }

          .notification-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #616161;
            padding: 10px 0 10px 0;
            width: 50%;
            .Button {
              margin-left: 15px;
            }
          }

          .notification-email-input {
            display: flex;
            flex: row;
            align-items: center;
            justify-content: flex-start;
            width: 50%;
            .Button {
              margin-left: 15px;
            }
          }

          .Dropdown {
            margin-bottom: 10px;
            .input-wrap {
              border-radius: 8px;
              background-color: color(light, secondaryBg);
            }
          }
          &.integrations {
            .details {
              padding: 20px 0px;
              .details-title {
                font-size: 18px;
                font-weight: 700;
              }

              .details-subtitle {
                font-size: 16px;
                color: #52646c;
                padding: 10px 0px;
                font-weight: 400;
              }

              p {
                font-size: 14px;
                color: #52646c;
                font-weight: 300;
                line-height: 21px;
              }
            }

            .icon {
              font-size: 72px;
              min-width: 150px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            img {
              height: 72px;
              width: 72px;
            }
            .fab {
              &.fa-twitch {
                color: #5a3e85;
              }
            }
          }
          .container-half {
            width: 50%;
          }

          .web-address-line {
            display: flex;
            flex-direction: row;
            align-items: center;

            .web-address {
              color: #52646c;
              letter-spacing: 0px;
              font-size: 18px;
              cursor: default;
            }

            :first-child {
              margin-right: 6px;
            }
          }

          .success {
            color: #2abd7e;
            margin-bottom: 24px;
          }

          .donation-matching {
            .TextField {
              margin: 20px 0;
            }

            .inline {
              display: inline-flex;
              align-items: center;

              .TextField {
                max-width: 90px;
                margin: 0 10px;
              }

              span {
                width: auto;
              }
            }

            .matching-actions {
              display: flex;
              justify-content: flex-start;
              margin-top: 15px;

              .Button:first-of-type {
                Button {
                  background: color(grey, lighter);
                  color: color(blue, dark);
                  border: 1px solid color(grey, light);
                }
              }

              .Button {
                margin-right: 15px;
              }
            }
          }

          .quill {
            background-color: color(light, secondaryBg);
          }

          .fundraising-ticket-selection {
            .ticket-list {
              position: relative;
              max-width: 250px;

              .select-wrap {
                display: flex;
                width: 100%;
                max-width: 250px;
                justify-content: space-between;
                padding: 0 15px;
                align-items: center;
                height: 40px;
                border-radius: 8px;
                border: 1px solid color(grey, light);
              }

              .ticket-options {
                width: 100%;
                position: absolute;
                background-color: color(light, secondaryBg);
                bottom: -235px;
                display: flex;
                flex-direction: column;
                padding: 15px;
                border-radius: 8px;
                border: 1px solid color(grey, light);
                box-shadow: $box-shadow;
                height: 200px;
                overflow-y: auto;
                z-index: 1;

                .ticket-option {
                  min-height: 25px;
                  padding: 8px;

                  &.selected {
                    background-color: #eaeff0;
                  }

                  .Checkbox {
                    label {
                      justify-content: flex-start;
                    }
                  }
                }
              }
            }

            .selected-tickets {
              margin-top: 5px;
              border-radius: 8px;
              display: flex;
              flex-wrap: wrap;
              align-items: center;

              .sel-ticket {
                border-radius: 8px;
                background-color: color(purple, dark);
                margin: 5px;
                padding: 8px;
                color: color(light, secondaryBg);

                &:first-of-type {
                  margin-left: 0;
                }

                i {
                  padding: 0 8px;
                }

                &:hover {
                  cursor: pointer;
                  background-color: color(purple, base);
                }
              }
            }
          }
        }
      }
    }

    .Campaigns {
      display: flex;
      flex-direction: column;
      margin: 0 20px;

      .title-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        font-size: 24px;
        color: color(light, fontColor);
        font-weight: 500;
      }

      .campaigns-list {
        padding: 15px;
        background-color: #fff;
        height: 100%;
        flex: 1;
        border-radius: 15px;

        &.no-padding {
          padding: 0;
        }

        .empty-state {
          padding: 40px 20px;
          color: color(grey);
          text-align: center;
        }

        .inner-tabs {
          display: flex;
          flex-direction: row;
          width: 100%;

          .inner-tab {
            width: 25%;
            text-align: center;
            padding: 20px;
            font-weight: 500;
            color: #88949a;
            border-bottom: 1px solid #eaeff0;
            cursor: pointer;

            &.active {
              color: color(light, fontColor);
              border-bottom: 3px solid color(purple, dark);
            }
          }
        }

        .inner-wrapper {
          padding: 15px;
        }

        .campaign-actions-row {
          display: flex;
          margin: 15px 0px;
          flex-direction: row;
          justify-content: space-between;

          .sort {
            background-color: #f5f6f7;
            display: flex;
            color: #88949a;
            height: 48px;
            width: 48px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            text-align: center;
            cursor: pointer;
          }

          .TextField {
            .input-wrap {
              width: 250px;
              background-color: #f5f6f7;

              input {
                font-size: 14px;
              }

              i {
                color: #000;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .LocationManagement,
  .DiscountCodeManagement {
    flex: 1;
  }
}

@media (max-width: 823px) {
  .EventAdmin {
    .section,
    .admin-panel {
      display: none;
    }
  }
}
