@import '../../styles/variables.scss';

.Modal.Store-FormModal {
  .FormModal-content {
    max-height: 600px;
    max-width: 784px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include thin-scroller-y;

    .ModalHeader {
      position: relative;

      .close-button {
        display: none;
      }

      .modal-close-icon {
        position: absolute;
        right: 0;
        top: 16px;
      }
    }

    .FormManagement {
      display: block;
      flex: unset;

      .section-inner {
        margin: 24px 24px 0 24px;
        padding: 0;
        border-radius: unset;
        background-color: unset;
        flex: unset;
        display: block;

        .form-actions {
          padding-bottom: 100px;
        }
      }
    }
  }
}

.StoreItemForm {
  padding: 40px;
  overflow: auto;

  .sub-title {
    color: color(purple, dark);
    font-size: 21px;
    border-bottom: 1px solid color(grey, lighter);
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }

  .pickupAvailable {
    .row {
      position: relative;

      a {
        position: absolute;
        top: -2px;
        right: 0;
        color: color(purple, dark);
        font-size: 14px;
      }
    }
  }

  .store-item-actions {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  .row {
    margin-bottom: 20px;

    &.add-location {
      .Button {
        margin-top: 20px;

        button {
          border-radius: 4px;
          padding: 0px 12px;
          background-color: color(purple);

          &:hover {
            background-color: color(purple, dark);
          }

          span {
            font-size: 14px;
          }

          i {
            font-size: 18px;
          }
        }
      }

      .empty {
        margin-top: 20px;
        font-size: 14px;
        color: color(grey);
      }

      .selected-locations {
        margin: 20px 0 0;
        padding: 0;
        list-style-type: none;

        li {
          padding-left: 8px;
          font-size: 14px;
          font-weight: 500;

          span {
            font-weight: 300;
            margin-left: 8px;
          }

          i {
            margin-left: 8px;
            color: color(red, dark);

            &:hover {
              cursor: pointer;
              color: color(purple);
            }
          }
        }
      }
    }

    &.toggle {
      display: flex;
      align-items: center;

      p {
        flex: 1;
        font-weight: 500;
      }

      .fa-toggle-on,
      .fa-toggle-off {
        font-size: 28px;

        &:hover {
          cursor: pointer;
        }
      }

      .fa-toggle-on {
        color: color(purple, dark);
      }
    }

    &.form-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .texts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .title {
          font-size: 16px;
          font-weight: 700;
          color: color(grey, dark);
        }

        .desc {
          font-size: 14px;
          color: color(grey, text);
        }
      }
    }

    &.dbl {
      display: flex;
      flex: 1;

      .Dropdown {
        flex: 1;
      }

      &.price {
        .TextField {
          margin-right: 10px;
        }

        .Dropdown {
          margin-left: 10px;
        }
      }

      &.inventory {
        .TextField {
          &:first-child {
            margin-right: 10px;
          }

          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }

    &.discounts {
      .search-discounts {
        display: flex;

        .TextField {
          .input-wrap {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;
          }
        }

        .Button {
          margin-top: 20px;
          height: 44px;

          button {
            height: 44px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }

      .discount-error {
        color: color(red, dark);
        background-color: color(red, light);
        padding: 10px;
        border-radius: 8px;
        margin-top: 10px;

        a {
          color: color(purple, dark);
          text-decoration: none;

          &:hover {
            color: color(purple);
          }
        }
      }

      .applied-discounts {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;

        li {
          color: color(dark, fontColor);
          font-size: 14px;
          padding: 8px 0;
          margin-right: 10px;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .fa-times {
            font-size: 12px;
            padding: 0 8px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;

            &:hover {
              cursor: pointer;
            }

            &:before {
              margin-top: 2px;
            }
          }

          span {
            padding-left: 16px;
          }

          span,
          .fa-times {
            background-color: color(purple, dark);
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &:before {
            content: ' ';
            border: 15px solid transparent;
            border-right: 15px solid color(purple, dark);
            border-left: 0;
          }

          &:after {
            content: ' ';
            position: absolute;
            top: 18px;
            left: 12px;
            height: 10px;
            width: 10px;
            border-radius: 50px;
            background-color: color(light, secondaryBg);
          }
        }
      }
    }

    &.taxes {
      flex-direction: column;
      flex: 1;
      width: 100%;

      .tax-eligible {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .Checkbox {
          margin-right: 20px;

          label {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 0px;

            p {
              padding-bottom: 0px;
            }
          }
        }

        .TextField {
          margin-bottom: 0px;
        }
      }

      .warning-msg {
        padding: 10px 0px 0px;
        font-size: 12px;
        color: color(grey);
        margin-bottom: 30px;
      }

      label {
        position: relative;
        padding-bottom: 4px;
        font-size: 13px;
        font-weight: 700;
        width: 100%;
      }
    }

    &.image-upload {
      margin-bottom: 20px;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      p {
        font-weight: 300;
        color: color(grey);
        padding: 0px;
        margin: 10px 0px;
      }

      .images {
        margin-bottom: 10px;
        display: flex;
      }

      .image-thumb {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-right: 10px;
        position: relative;

        i {
          display: none;
          position: absolute;
          top: 0px;
          right: 0px;
          font-size: 16px;
          color: color(light, fontColor);
          height: 25px;
          width: 25px;
          justify-content: center;
          align-items: center;

          &:hover {
            color: color(red, dark);
          }
        }

        img {
          width: 100%;
          height: auto;
          display: block;
          transform: scale(2);
        }

        &:hover {
          i {
            display: flex;
          }

          img {
            opacity: 0.5;
          }
        }
      }
    }

    &.description {
      label {
        font-size: 13px;
        font-weight: 700;
      }

      .quill {
        margin-top: 4px;

        .ql-toolbar.ql-snow {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border: 1px solid color(grey, light);
        }

        .ql-container.ql-snow {
          border: 1px solid color(grey, light);
          border-top: 0;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }

  .error {
    color: red;
    font-size: 11px;
  }
}

@media (max-width: 900px) {
  .StoreItemForm {
    .row.dbl {
      flex-wrap: wrap;

      &.inventory {
        .TextField {
          &:first-child {
            margin-right: 0;
          }

          &:last-child {
            margin-left: 0;
          }

          margin-bottom: 10px;
        }
      }
    }

    .Modal.FormModal {
      max-height: 100vh;
      height: 100%;
      width: 100%;
      max-width: 100vw;

      .FormModal-content {
        max-height: 100vh;
        max-width: 100vw;
      }
    }
  }
}
