@import '../../styles/variables.scss';

.TeamManage {
  display: flex;
  flex-direction: row;
  background-color: #eaeff0;
  width: 100%;

  .CoverImage {
    width: 250px;
  }

  .section {
    width: 100%;
    flex: 1;
    padding: 25px;

    &.widgets-wrap {
      .section-inner {
        flex-direction: column;
      }
    }

    .section-wrap {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 100%;
      padding: 0px;

      .section-title {
        padding: 20px 30px;
        font-size: 32px;
        font-weight: 700;
        display: flex;
        height: 40px;

        .title-wrap {
          flex: 1;
        }

        .section-inner-title {
          flex: 1;
        }
      }

      .section-inner {
        margin: 0px 30px 30px;
        padding: 40px;
        border-radius: 15px;
        background-color: color(light, secondaryBg);
        flex: 1;
        display: flex;
        flex-direction: column;

        .settings-title {
          background-color: color(grey, lighter);
          height: 50px;
          text-indent: 20px;
          display: flex;
          align-items: center;
          font-weight: 700;
        }

        .settings-item {
          display: flex;
          min-height: 90px;
          align-items: center;
          padding: 0px 40px;
          border-bottom: 1px solid color(grey, lighter);

          .quill {
            background-color: color(light, secondaryBg);
          }

          &.prefix {
            .TextField {
              max-width: 250px;
              margin-right: 25px;
            }

            .current-receipt-format {
              margin: 10px 2px;
              font-size: 12px;
            }
          }

          &.hover-item {
            flex-direction: column;
            align-items: stretch;

            .details,
            .expansion {
              flex: 1;
            }

            .details {
              padding-top: 22px;
              position: relative;

              .help-info {
                position: absolute;
                font-size: 18px;
                margin-left: 5px;
                top: 20px;
                left: 246px;

                &:hover {
                  .hover-action {
                    i {
                      color: color(purple, dark);
                    }
                  }
                  .info-message {
                    display: flex;
                    flex-direction: column;
                    padding: 15px;
                    position: absolute;
                    justify-content: space-around;
                    top: -63px;
                    box-shadow: $box-shadow;
                    z-index: 2;
                    border-radius: $border-radius;
                    border: 1px solid color(purple, dark);
                    height: 125px;
                    width: 375px;
                    background-color: color(light, secondaryBg);
                    animation: infoMessage 300ms ease-out forwards;

                    .example-num {
                      display: flex;
                      justify-content: space-around;
                      align-items: center;
                    }
                    .ex {
                      display: flex;
                      justify-content: space-around;
                      align-items: center;

                      &:not(.headers) {
                        background: color(grey, lighter);
                        padding: 5px;
                        border-radius: 10px;
                      }

                      &.headers {
                        div:first-of-type {
                          padding-left: 30px;
                        }
                        div:nth-of-type(2) {
                          padding-left: 30px;
                        }
                      }
                    }
                  }
                }
                .info-message {
                  display: none;
                  animation: infoMessage 300ms ease-out forwards;
                }
              }
            }

            .expansion {
              padding: 20px 0px 20px 40px;

              .DatePicker {
                margin-bottom: 20px;
                max-width: 600px;

                .dp-inner {
                  .month {
                    max-width: 180px;
                  }

                  .day,
                  .year {
                    max-width: 80px;
                  }
                }
              }

              &.amounts {
                .donation-amount {
                  display: flex;
                  margin-bottom: 10px;
                  max-width: 200px;

                  .TextField {
                    flex: 1;

                    .input-wrap {
                      border-left: 0px;
                      border-top-left-radius: 0px;
                      border-bottom-left-radius: 0px;

                      &:before {
                        content: '$';
                        height: 42px;
                        width: 22px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      }

                      input {
                        text-indent: 4px;
                      }
                    }
                  }

                  .index {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    height: 42px;
                    min-width: 42px;
                    max-width: 42px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: color(light, primaryBg);
                    color: color(grey);
                    border: 1px solid color(grey, light);
                  }
                }

                .amounts-actions {
                  display: flex;

                  .Button {
                    margin-right: 10px;
                  }
                }
              }

              .expansion-actions {
                padding-top: 20px;
              }

              .TextField,
              .Dropdown {
                .input-wrap {
                  overflow: hidden;

                  input {
                    background-color: color(light, secondaryBg);
                  }
                }
              }

              .prefix-wrap {
                display: flex;
                flex: 1;
                justify-content: flex-start;
                align-items: center;

                .Button {
                  padding-top: 19px;
                  margin-left: 20px;

                  button {
                    height: 44px;
                  }
                }
              }

              .email-wrap {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;

                .Button {
                  padding-top: 19px;
                  margin-left: 20px;

                  button {
                    height: 44px;
                  }
                }
              }
            }

            &:hover {
              background-color: color(cream, dark);
              cursor: pointer;
            }
          }

          p {
            font-size: 16px;
            padding: 0px 0px 5px 0px;
            margin: 0px;
            font-weight: 700;
          }

          .ql-editor {
            p {
              color: color(grey);
              font-size: 14px;
              line-height: 24px;
            }
          }

          a {
            color: color(purple, dark);
            text-decoration: none;

            &:hover {
              color: color(purple);
            }
          }

          .settings-control {
            .fad {
              font-size: 30px;
              color: color(purple, dark);

              &:hover {
                cursor: pointer;
              }

              &.fa-toggle-off {
                color: color(grey);
              }
            }
          }

          .details {
            flex: 1;

            .detail {
              font-size: 14px;
              color: color(grey);
              padding-right: 20px;

              .create {
                margin-left: 5px;
                color: color(purple, dark);

                &:hover {
                  color: color(purple);
                }
              }
            }
          }
        }

        .widget {
          margin-bottom: 30px;

          &.blur {
            .widget-inner {
              .TextField {
                .input-wrap {
                  input {
                    filter: blur(5px);
                  }
                }
              }
            }
          }

          .title {
            font-weight: 700;
            margin-bottom: 5px;
          }

          .widget-inner {
            display: flex;
            position: relative;

            .TextField {
              .input-wrap {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
              }
            }

            .Button {
              button {
                height: 44px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }

            .fas {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 1px;
              right: 93px;
              height: 42px;
              width: 42px;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 823px) {
  .TeamManage {
    .section,
    .admin-panel {
      display: none;
    }
  }
}
