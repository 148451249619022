@import '../../styles/variables.scss';

.Benefiting-Charity-Tile {
  margin-top: 40px;

  .BC-title {
    font-size: 24px;
    font-weight: 700;
    border-bottom: 1px solid color(grey, lighter);
    height: 40px;
    margin-bottom: 20px;
  }

  .info {
    display: flex;
    align-items: center;

    .group-title {
      font-size: 24px;
      font-weight: 500;
      margin-left: 20px;
    }
  }

  .description {
    margin-top: 20px;

    .ql-editor {
      font-size: 14px;
      line-height: 1.8;
      padding: 0;
    }
  }
}
