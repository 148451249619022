@import '../../styles/variables.scss';

.KpiDisplay {
  display: flex;
  background-color: #ffffff;
  margin-top: 18px;
  flex-wrap: wrap;

  .kpi-card {
    height: 138px;
    place-items: center self-start;
    place-content: center;
    display: grid;
    grid-template: repeat(2, max-content) / repeat(auto-fill, minmax(338px, 1fr));
    padding-left: 40px;
    box-shadow:
      inset -1px 0px 0px color(grey, border),
      inset 0px -1px 0px color(grey, border),
      inset 0px 1px 0px color(grey, border);
    border-left: 1px solid color(grey, border);
    border-radius: 0px;

    .kpi-value {
      font-family: $raleway;
      font-style: normal;
      font-weight: 800;
      font-size: 30px;
      font-feature-settings:
        'pnum' on,
        'lnum' on;
      color: color(grey, dark);
      margin-top: 12px;
    }

    .kpi-text {
      font-family: $lato;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: color(grey, light);
    }

    &:first-child {
      border-radius: 10px 0 0 10px;
    }

    &:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
}
