@import '../../../../../styles/variables.scss';

.CommunityFeedPostComments {
  background-color: color(light, secondaryBg);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 29px;
  margin-top: 16px;
  height: 340px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: color(grey, text) color(grey, pale);

  &::-webkit-scrollbar-track {
    background: color(grey, pale);
  }

  &::-webkit-scrollbar-thumb {
    background-color: color(grey, text);
    border-radius: 20px;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }

  a {
    text-decoration: none;
    color: color(grey, dark);
    transition: all 0.2s;

    &:hover {
      color: color(purple, dark);
    }
  }

  .parent-comment-container {
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    font-feature-settings:
      'pnum' on,
      'lnum' on;
    font-style: normal;

    .parent-comment-container {
      margin-top: 16px;
    }

    .user-avatar,
    img {
      width: 40px;
      height: 40px;
      border-radius: 24px;
    }

    .user-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px color(grey, dark);
      font-size: 24px;
    }

    .comment-content-container {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      color: color(grey, text2);

      .content-container {
        background: color(grey, pale);
        border-radius: 8px;
        padding: 12px 0 0 12px;

        .comment-title {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 4px;

          a {
            text-decoration: none;
            transition: all 0.2s;

            &:hover {
              color: color(purple, dark);
            }
          }
        }

        .comment-content {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
        }
      }

      .reply-container {
        margin-top: 10px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: color(dark, border);

        .reply {
          transition: all 0.2s;
          cursor: pointer;

          &:hover {
            color: color(purple, dark);
          }
        }
      }
    }
  }
}

@media (max-width: 1070px) {
  .CommunityFeedPostComments {
    height: auto;
    padding-bottom: 72px;
  }
}
