@import '../../styles/variables.scss';

.Admin {
  display: flex;
  flex-direction: row;
  background-color: #eaeff0;
  width: 100%;

  .admin-panel {
    display: flex;
    flex-direction: column;
    width: 20%;
    padding: 20px;
    background-color: color(light, secondaryBg);
    transition: all 150ms ease-in-out;

    .Portrait {
      .inner,
      .transparent > * {
        background-color: unset;
      }
      img {
        padding-left: 5px;
        height: 84%;
        width: 92%;
        border-radius: unset;
      }
    }

    .user-name {
      color: color(grey);
      font-size: 14px;
      font-weight: 700;
      padding: 5px 0px;
    }

    .user-role {
      color: color(grey);
      font-size: 14px;
      font-weight: 700;
      padding: 0px 5px 20px 0px;
    }

    .sub-header {
      font-size: 18px;
      font-weight: 700;
      padding: 20px 0px 0px;
      color: color(light, fontColor);
    }

    .tabs {
      border-top: 1px solid color(blue, lighter);
      margin: 0px;
      padding: 20px 0px;
      font-weight: 700;

      ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;

        li {
          padding: 0px;
          margin: 0px 0px 4px 0px;
          cursor: pointer;
          border-radius: 6px;
          color: color(grey);
          line-height: 30px;
          text-indent: 12px;
          font-size: 14px;
          text-transform: capitalize;

          &.tab-title {
            font-size: 16px;
            text-indent: 0px;
            padding-left: 12px;
            color: color(light, fontColor);
            display: flex;
            align-items: center;

            span {
              font-weight: 700;
            }

            i {
              padding-right: 6px;
            }

            &:hover {
              background-color: color(light, secondaryBg);
              cursor: default;
            }
          }

          &:last-child {
            margin-bottom: 0px;
          }

          &:not(.active):hover {
            background-color: color(light, primaryBg);
            color: color(light, fontColor);
          }

          &.active {
            color: color(dark, fontColor);
            background-color: color(purple, dark);
          }
        }
      }
    }
  }

  .section {
    width: 80%;
    flex: 1 1;
    padding: 25px;

    .section-title {
      display: flex;
      align-items: center;
      padding: 20px 30px;

      .forms-title {
        flex: 1 1;
        font-size: 32px;
        font-weight: 700;
      }

      .forms-controls {
        display: flex;

        .Button {
          &:not(:first-child) {
            margin-left: 10px;
          }
        }
      }
    }

    .section-inner {
      margin: 0px 30px 30px;
      padding: 40px;
      border-radius: 15px;
      background-color: color(light, secondaryBg);
      display: flex;
      flex-direction: column;

      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1 1;

        .TextField {
          max-width: 50%;

          i {
            background-color: color(light, secondaryBg);
            color: color(light, fontColor);
          }

          input {
            background-color: color(light, secondaryBg);
          }
        }
      }

      .items-list {
        margin-top: 30px;
      }
    }

    .no-items-found {
      display: none;
      text-align: center;
      padding-top: 40px;

      &.show {
        display: block;
      }
    }

    .Modal {
      .inner {
        margin: 0 40px 20px 40px;
      }

      .TextField {
        padding-bottom: 20px;
      }
    }
  }
}
