@import '../../../styles/variables.scss';

.MediaAlbumItem {
  max-width: 249px;
  overflow: hidden;
  background-color: color(light, secondaryBg);
  -webkit-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.46);
  box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.46);
  border-radius: 15px;

  &.hidden {
    .title,
    .count,
    .cover {
      opacity: 0.5;
    }
  }

  .delete-album {
    padding: 20px;

    .title {
      padding: 0px 0px 20px;
    }

    p {
      padding: 0px;
      margin: 0px;
    }

    .actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      margin-top: 20px;

      .Button {
        margin-left: 10px;

        &:last-child {
          button {
            background-color: color(volunteer);
          }
        }
      }
    }
  }

  .title,
  .count {
    padding: 0px 20px;
  }

  .count {
    padding-top: 20px;
    font-size: 12px;
    color: color(grey);
  }

  .title {
    padding-top: 5px;
    padding-bottom: 20px;
    font-size: 22px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cover {
    position: relative;
    overflow: hidden;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      transition: all 150ms ease-in-out;
      width: 100%;
      height: auto;
      display: block;
      transform: scale(2);
    }

    &:hover {
      .admin-overlay {
        height: 100%;
        display: flex;
        opacity: 1;

        ul {
          li {
            i {
              opacity: 1;
              transform: translateY(0px);
            }
          }
        }
      }
    }

    .admin-overlay {
      transition: opacity 150ms ease-in-out;
      animation-delay: 200ms;
      position: absolute;
      display: flex;
      opacity: 0;
      height: 0px;
      overflow: hidden;
      justify-content: center;
      align-items: flex-end;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);

      ul {
        display: flex;
        position: relative;
        list-style-type: none;
        padding: 0px 0px 20px;
        margin: 0px;

        li {
          padding: 10px;

          i {
            transition:
              transform 150ms ease-in-out,
              opacity 150ms ease-in-out;
            opacity: 0;
            transform: translateY(20px);
            transition-delay: 100ms;
            color: color(dark, fontColor);
            font-size: 26px;

            &:hover {
              &.fa-trash-alt {
                color: color(volunteer);
              }

              &.fa-eye {
                color: color(purple, dark);
              }
            }
          }
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
    color: color(purple, dark);

    .cover {
      img {
        transform: scale(2.25);
      }
    }
  }
}

@media (max-width: 680px) {
  .MediaAlbumItem {
    max-width: 100%;
    margin-right: 0px !important;
  }
}
