@import '../../styles/variables.scss';

.light {
  .TextArea {
    .placeholder {
      color: color(light, fontColor);
    }

    textarea {
      background-color: color(light, secondaryBg);
      color: color(light, fontColor);

      &:-webkit-autofill {
        background-color: color(light, secondaryBg) !important;
      }
    }

    .helper {
      span {
        color: color(dark, fontColor);
      }
    }
  }
}

.dark {
  .TextArea {
    .placeholder {
      color: color(dark, fontColor);
    }

    textarea {
      background-color: color(dark, secondaryBg);
      color: color(dark, fontColor);

      &:-webkit-autofill {
        background-color: color(dark, secondaryBg) !important;
      }
    }

    .helper {
      span {
        color: color(dark, fontColor);
      }
    }
  }
}

.TextArea {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .main-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    background-color: color(purple, dark);
    color: color(dark, fontColor);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    height: 42px;
    width: 42px;
  }

  .helper {
    margin-left: 14px;
    display: flex;
    flex-direction: column;

    &:hover {
      span {
        display: block;
      }
    }

    i {
      font-size: 21px;

      &:hover {
        cursor: pointer;
      }
    }

    span {
      display: none;
      background-color: #000;
      padding: 4px 8px;
      border-radius: 6px;
      position: absolute;
      top: 100%;
      width: 250px;
      margin-left: -125px;
      text-align: center;
      font-size: 14px;
      z-index: 2;

      &:before {
        width: 0px;
        height: 0px;
        content: ' ';
        position: absolute;
        top: -16px;
        left: 50%;
        margin-left: -6px;
        border: 8px solid transparent;
        border-bottom: 8px solid #000;
      }
    }
  }

  label {
    position: relative;
    display: flex;
    flex: 1;

    .placeholder {
      top: 0px;
      left: 0px;
      height: 42px;
      opacity: 0.8;
      line-height: 42px;
      margin-left: 10px;
      position: absolute;
      transition: all ease-in-out 150ms;
      font-weight: 700;
      text-transform: capitalize;
    }

    textarea:-webkit-autofill {
      box-shadow: 0 0 0px 40px color(light, primaryBg) inset;
      border: 0px;
    }

    textarea {
      flex: 1;
      border: 0px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      height: 40px;
      line-height: 40px;
      font-family: $lato;
      font-size: 16px;
      text-indent: 8px;
      font-weight: 500;
      width: 100%;
      resize: none;

      &.no-icon {
        border-radius: 6px;
      }

      &.exceeded {
        box-shadow: 0 0 3px 1px #eb645e;
      }

      &:not([value='']):invalid + i {
        color: color(volunteer);
      }

      &:focus {
        outline: 0px;
      }

      &:focus + .placeholder {
        transform: translateY(-30px);
        font-size: 12px;
        opacity: 0.4;
      }

      &:not([value='']) + .placeholder {
        transform: translateY(-30px);
        font-size: 12px;
        opacity: 0.4;
      }
    }
  }

  .remaining-characters {
    position: absolute;
    bottom: -26px;
    right: 2px;
    font-size: 14px;
    color: color(green, soft);

    &.exceeded {
      color: color(red, soft);
    }
  }
}
