@import './../../../../styles/variables.scss';

.MyAgenda.fixed {
  overflow: hidden;
}
.MyAgenda {
  padding: 30px;
  width: 100%;
  position: relative;

  .mobile-filter-button {
    display: none;
  }
  .mobile-filter-container {
    right: 0;
    height: 100%;
    top: 80px;
    overflow: hidden;
    background-color: color(light, secondaryBg);
    width: 0;
    z-index: 6;
    transition: width 0.3s;
    position: fixed;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    font-feature-settings:
      'pnum' on,
      'lnum' on,
      'salt' on,
      'ss09' on;
    color: color(grey, dark);

    .filter-header {
      margin: 10px 0;
      padding-left: 28px;
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 64px;

      i {
        font-size: 16px;
      }

      span {
        margin-left: 17px;
      }
    }

    .filter-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 25px;

      span {
        width: 100%;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 24px;
        font-feature-settings:
          'pnum' on,
          'lnum' on;
        color: color(grey, dark);
        text-align: left;
        margin-bottom: 12px;
      }

      .filter-item {
        width: 100%;
        background: color(light, secondaryBg);
        border: 1px solid color(grey, border);
        box-sizing: border-box;
        border-radius: 10px;
        transition: all 0.2s;
        margin-bottom: 16px;
        padding: 10px 0 10px 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        &.active {
          border-color: color(grey, dark);
        }
      }
    }

    .btn-container {
      display: flex;
      flex-direction: column;
      padding: 0 25px;
      align-items: center;
      justify-content: center;
      margin: 16px 0;

      button {
        width: 100%;
        background-color: color(light, secondaryBg);
        border: 1px solid color(plum, dark);
        box-sizing: border-box;
        border-radius: 22px;
        color: color(plum, dark);
        height: 44px;

        &.submit {
          margin-top: 16px;
          color: color(light, secondaryBg);
          background-color: color(plum, dark);
        }
      }
    }
  }
  .Modal {
    .contact-options {
      padding: 20px 40px;
      a {
        padding: 20px;
        display: block;
        background: rgba(0, 0, 0, 0.12);
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        color: #000;
        margin-bottom: 10px;
      }
    }

    .actions {
      padding: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  .agenda-loader {
    .Loader {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.12);
      width: 100%;
      height: 100%;
      z-index: 9;
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 42px;
      }
    }
  }

  .header-btn-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    column-gap: 24px;
    margin: 16px 0;
  }

  .agenda-subheader {
    width: 100%;
    display: flex;
    flex-direction: row;

    .filter-dropdown {
      margin-right: 20px;
      min-width: 205px;

      .input-wrap {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 16px;
        color: color(light, fontColor);
        padding: 0 23px 0 16px;

        .menu-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.3s ease-in-out;

          &.show {
            transform: rotate(180deg);
          }
        }

        .label {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          img {
            width: 12px;
            height: 12px;
            margin-right: 14px;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }

      label {
        z-index: 1;
        bottom: -11px;
        left: 9px;
        background-color: white;
        color: color(grey, dark);
        font-weight: normal;
        position: relative;
        padding: 5px;
        width: fit-content;
      }

      &:hover {
        cursor: pointer;
      }
    }
    .Dropdown {
      width: 250px;
      margin-right: 15px;
    }
    .AgendaDatePicker {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: 15px;
    }
  }
  .agenda-content-wrapper {
    padding: 10px 0;
    width: 100%;

    .agenda-empty-state {
      width: 100%;
      height: 100%;
      padding-top: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 600;
        color: color(grey, border);
      }
    }

    .schedule-view:hover {
      cursor: pointer;
    }
    .schedule-view.shift:hover,
    .schedule-view.shift.active {
      background: #5587d4;
    }
    .schedule-view.event:hover,
    .schedule-view.event.active {
      background: #55499a;
    }
    .schedule-view.shift {
      background: #5e96eb;
    }
    .schedule-view.event {
      background: color(purple, dark);
    }
    .schedule-view {
      position: relative;
    }
    .list-separator {
      width: 100%;
      margin: 20px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      .sep-circle {
        width: 12px;
        height: 12px;
        background: color(grey, border);
        border-radius: 6px;
      }
      .sep-line {
        height: 3px;
        background: color(grey, border);
        width: 100%;
      }
    }
    .date-group {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 20px;
      .group-day {
        flex: 1 1 5%;
        font-size: 18px;
        font-weight: 700;
        .schedule-date {
          font-size: 16px;
          font-weight: 600;
          background: #eff5fd;
          padding: 9px;
          border-radius: 18px;
          margin-top: 5px;
          display: inline-block;
        }
      }
      .group-list {
        flex: 1 0 95%;
        .schedule-view {
          padding: 25px;
          display: flex;
          flex-direction: row;
          border-radius: 10px;
          margin-bottom: 15px;
          .schedule-title {
            width: 30%;
            border-right: 1px solid #3a3a3a4d;
            span {
              display: block;
              font-weight: 600;
              font-size: 14px;
              color: #fff;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .title-line {
              font-weight: 800;
              color: #fff;
              font-size: 18px;
            }
          }
          .schedule-location,
          .schedule-time {
            width: 25%;
            font-size: 14px;
            color: #fff;
            font-weight: 600;
            padding-left: 30px;
          }
          .schedule-location {
            width: 45%;
            i {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .details-container:hover {
      cursor: default;
    }

    .details-container {
      position: absolute;
      top: calc(100% + 15px);
      right: 90px;
      z-index: 9;
      filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.2));

      .mobile-close-details {
        display: none;
      }

      .details-point {
        width: 22px;
        height: 22px;
        position: absolute;
        left: 25%;
        top: -11px;
        transform: rotate(45deg);
      }

      .details-point.events {
        background: color(purple, dark);
      }

      .details-point.shifts {
        background: #5e96eb;
      }

      .details-header.events {
        background: color(purple, dark);
        width: 313px !important;
      }

      .details-header.shifts {
        background: #5e96eb;
      }

      .details-header {
        border-radius: 10px 10px 0 0;
        color: #fff;
        padding: 7px 25px;
        font-size: 16px;
        font-weight: 700;
      }

      .details-content {
        width: 100%;
        background: #fff;
        padding: 22px 24px;
        border-radius: 0 0 10px 10px;

        .Button {
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .details-heading {
          .details-menu:hover {
            cursor: pointer;
            background: color(light, shadow);
          }
          .details-menu {
            border-radius: 18px;
            float: right;
            position: relative;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .details-menu-content {
            z-index: 2;
            position: absolute;
            top: 100%;
            right: 0;
            background: #fff;
            width: 200px;
            filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.2));
            border-radius: 10px;
            overflow: hidden;
            ul {
              margin: 0;
              padding-left: 0;
              li:hover {
                cursor: pointer;
                background: color(light, shadow);
              }
              li {
                list-style-type: none;
                font-size: 16px;
                padding: 10px 15px;
              }
            }
          }
        }
        .details-cover-image {
          max-height: 190px;
          overflow: hidden;
          margin-bottom: 13px;
        }
        .details-heading {
          font-size: 18px;
          font-weight: 900;
          margin-bottom: 13px;
        }
        .details-info {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 13px;
          color: color(grey, base);
          i {
            width: 14px;
            margin-right: 15px;
          }
        }
        .details-description {
          border-top: 1px solid color(grey, base);
          padding: 13px 0;
          color: color(grey, base);
          font-size: 14px;
          font-weight: 500;
          display: flex;
          flex: 1 0 90%;
          i {
            margin-right: 15px;
          }
          .ql-editor {
            padding: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 843px) {
  .MyAgenda {
    padding: 20px;
    min-width: 0;
    position: unset;
    .Dropdown {
      display: none;
    }
    .agenda-subheader {
      .AgendaDatePicker {
        width: 100%;
        margin-right: 0;
      }
    }
    .mobile-filter-container {
      &.active {
        width: 100%;
        overflow: auto;
        max-height: calc(100vh - 147px);
      }
    }
    .mobile-filter-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid color(grey, dark);
        box-sizing: border-box;
        border-radius: 10px;
        width: 44px;
        height: 44px;

        i {
          font-size: 18px;
        }
      }
    }
    .agenda-content-wrapper {
      .details-container {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        .details-point {
          display: none;
        }
        .details-header {
          width: unset;
          border-radius: 0;
          height: 64px;
          display: flex;
          align-items: center;
          font-size: 22px;
          font-weight: 700;
          justify-content: space-between;
          .mobile-close-details {
            display: block;
            float: right;
          }
        }
        .details-content {
          height: 100%;
          width: unset;
        }
      }
      padding-bottom: 60px;
      .date-group {
        min-width: 0;
        .group-day {
          text-align: center;
        }
        .group-list {
          flex: 1 0 80%;
          min-width: 0;
          .schedule-view {
            padding: 10px 15px;
            min-width: 0;
            flex: 1 1;
            margin-bottom: 12px;
            position: unset;
            .schedule-title {
              width: 50%;
              span:not(.title-line) {
                display: none;
              }
              .title-line {
                font-size: 14px;
                font-weight: 700;
              }
              border: none;
            }
            .schedule-time {
              text-align: right;
              width: 50%;
              font-size: 14px;
              font-weight: 700;
            }
            .schedule-location {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 562px) {
  .MyAgenda {
    .agenda-content-wrapper {
      .date-group {
        .group-day {
          padding-right: 20px;
        }
        margin-bottom: 36px;
        .group-list {
          .schedule-view {
            .schedule-time,
            .schedule-title {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
