@import '../../styles/variables.scss';

.SponsorManagement-title-actions {
  margin-left: auto;
}

.SponsorManagement {
  flex: 1;

  .empty {
    text-align: center;
    margin: 20px 0px;
    color: color(grey);
  }

  .sponsor-name {
    &:hover {
      cursor: pointer;
    }
  }

  .ContactDetailsModal {
    justify-content: flex-start;
    align-items: stretch;

    .content {
      position: relative;
      flex: 1;
      max-width: 100%;
      max-height: 100%;
      margin: 40px 30px;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;

      .modal-close-circle {
        right: 15px;
        top: 15px;
      }
    }

    .ModalHeader {
      display: none;
    }
  }

  .edit-sponsorship-modal {
    background-color: color(light, secondaryBg);
    max-width: 500px;
    min-width: 500px;
    min-height: 500px;
    padding: 20px 20px 20px;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;

    .row {
      margin-bottom: 20px;
      width: auto;
    }

    .image-label {
      font-size: 13px;
      font-weight: 700;
    }

    .form-actions {
      display: flex;
      justify-content: center;
      margin-top: auto;

      .cancel-button {
        background-color: #ffffff;
        color: #52646c;
        box-shadow: 0px 3px 6px #7c7c7c41;
        border: 1px solid #e3e3e3;
        border-radius: 23px;

        &:hover {
          background-color: color(light, tertiaryBg);
          color: color(light, fontColor);
        }
      }

      .save-button {
        margin-left: 25px;
      }
    }
  }
}
