@import '../../styles/variables.scss';

.DiscountCodeManagement {
  flex: 1;
  display: flex;

  .section-title {
    .Button {
      button {
        box-shadow: $box-shadow;
      }
    }

    .section-inner-title {
      flex: 1 1;
    }
  }

  .DiscountManagement-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .create {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }

      .TextField {
        max-width: 50%;

        i {
          background-color: color(light, secondaryBg);
          color: color(light, fontColor);
        }

        input {
          background-color: color(light, secondaryBg);
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      flex: 1;

      .headers,
      .row {
        .col {
          display: flex;
          flex: 1;
          font-weight: 700;

          &:first-child {
            text-indent: 8px;
          }

          &.title {
            flex: 1;
          }

          &.portrait {
            max-width: 120px;
          }

          &.created,
          &.updated {
            width: 400px;

            span {
              font-weight: 400;
              margin-right: 8px;

              &:last-child {
                color: color(grey);
                font-weight: 400;
              }
            }
          }

          &.actions {
            justify-content: flex-end;
            padding-right: 40px;
            position: relative;

            .ContextMenu {
              display: none;
              position: absolute;
              top: 55px;
              right: 10px;
              box-shadow: $box-shadow;
              border-radius: 20px;

              li {
                cursor: pointer;

                &.default-role {
                  display: none;
                }

                span {
                  padding: 0px;
                }
              }

              i {
                font-size: 16px;
                padding-right: 8px;
              }

              &:hover {
                display: block;
              }
            }

            i.fa-ellipsis-h-alt {
              font-size: 24px;
              height: 40px;
              width: 40px;
              background-color: color(blue, lightest);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }

            i.fa-ellipsis-h-alt:hover + .ContextMenu {
              display: block;
            }
          }
        }
      }

      .headers {
        background-color: color(blue, lightest);
        display: flex;
        flex: 0;
        min-height: 40px;
        height: 40px;
        line-height: 40px;
      }

      .list-inner {
        flex: 1;
        max-height: 100%;
        overflow: auto;
        position: relative;

        .list-rows {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;

          .empty {
            flex: 1;
            padding: 30px 20px;
            text-align: center;
            color: color(grey);
            font-size: 16px;
          }
        }

        .row {
          display: flex;
          height: 60px;
          border-bottom: 1px solid color(grey, light);

          .col {
            font-weight: 400;
            align-items: center;

            span {
              padding: 4px 8px;
              border-radius: $border-radius;
              &.active,
              &.approved {
                background-color: #eafcf5;
                color: #067f5c;
              }

              &.declined,
              &.closed,
              &.rejected {
                color: #cf2035;
                background-color: #fce9ea;
              }

              &.pending,
              &.disabled,
              &.draft {
                background-color: #f3f4f9;
                color: #686c8b;
              }
            }

            &.role {
              &:hover {
                .Dropdown {
                  .input-wrap {
                    border: 1px solid color(grey, light);

                    i {
                      opacity: 1;
                    }
                  }
                }
              }

              .Dropdown {
                min-width: 200px;
                transform: translateX(-10px);

                .input-wrap {
                  border: 1px solid color(light, secondaryBg);

                  i {
                    opacity: 0;
                  }
                }
              }
            }

            &.portrait {
              justify-content: center;
              max-width: 120px;
            }

            &.capacity {
              span {
                margin-right: 8px;
                padding: 6px 10px;
                border-radius: 24px;

                @include userLabelColor;
              }
            }
          }
        }
      }
    }
  }
}
