@import '../../styles/variables.scss';

.light {
  .Group {
    color: color(light, fontColor);
    background-color: color(light, primaryBg);
  }
}

.dark {
  .Group {
    color: color(dark, fontColor);
    background-color: color(dark, primaryBg);
  }
}

.Group {
  display: flex;
  flex: 1;
  width: 100%;
  padding: 60px 0;
  position: relative;
  justify-content: center;

  .PageCoverImage {
    .CoverImage {
      img {
        height: unset;
      }
    }
  }

  .follow-button-followed {
    button {
      background-color: color(purple, dark);

      span {
        color: color(cream, lightest);
      }
    }
  }

  .Modal {
    .content {
      .Login {
        background-image: none;

        &:before {
          content: none;
        }
      }

      .VolunteerApplicationForm {
        height: 617px;
        overflow: auto;

        h2 {
          font-size: 18px;
          padding: 20px 0;
        }

        .application-form-header {
          display: flex;
          gap: 25px;
          align-items: center;
          padding: 40px 0;
          border-bottom: 1px solid color(grey, lightester);
          position: sticky;
          top: 0;
          background: #fff;
          z-index: 3;
          transition: position 250ms ease-in-out;

          .title-container {
            h1 {
              font-size: 22px;
              margin: 0;
              padding: 0;
            }
            div {
              font-size: 14px;
              margin-top: 5px;
              color: #959799;
            }
          }
        }
      }

      .details-row {
        margin-bottom: 40px;
      }

      .onboarding-container {
        padding: 40px;
        max-width: 450px;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        overflow: auto;
        font-weight: 700;

        &.group-onboard {
          .wrapper {
            .onboard-img {
              height: 350px;
              width: 100%;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: contain;
            }
            &.manage {
              .onboard-img {
                background-image: url('../../assets/gigit-group-manage.png');
              }
            }
            &.connect {
              .onboard-img {
                background-image: url('../../assets/gigit-group-connect.png');
              }
            }
            &.create {
              .onboard-img {
                background-image: url('../../assets/gigit-group-create.png');
              }
            }
          }
        }

        .onboard-title {
          font-size: 31px;
          color: color(purple, dark);
          margin: 0 40px;
          margin-bottom: 20px;
        }

        .onboard-info {
          margin: 0 40px;
          min-height: 64px;
          font-size: 18px;
          color: color(blue, dark);
        }

        .steps-select {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          text-align: center;

          i {
            padding: 5px;
            cursor: pointer;
            color: #88949a;
          }
        }

        .onboard-img {
          height: 350px;
          width: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }

        &.donation {
          .onboard-img {
            background-image: url('../../assets/gigit-setup-donation.png');
          }
        }
        &.volunteer {
          .onboard-img {
            background-image: url('../../assets/gigit-setup-volunteer.png');
          }
        }

        .onboard-actions {
          margin: 0 40px;
          width: 85%;
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .Button {
            button {
              flex: 1;
              text-decoration: none;
              display: flex;
              border: 0px;
              padding: 0px;
              font-size: 16px;
              border-radius: 20px;
              justify-content: center;
              align-items: center;
              color: color(dark, fontColor);
              background-color: color(purple, dark);
              overflow: hidden;
              width: 100%;
              min-height: 40px;
              font-weight: 500;
              margin-bottom: 30px;
              box-shadow: $box-shadow;
              span {
                color: #fff;
              }
            }
          }

          a {
            flex: 1;
            text-decoration: none;
            display: flex;
            border: 0px;
            padding: 0px;
            font-size: 16px;
            border-radius: 20px;
            justify-content: center;
            align-items: center;
            color: color(dark, fontColor);
            background-color: color(purple, dark);
            overflow: hidden;
            width: 100%;
            min-height: 40px;
            font-weight: 700;
            margin-bottom: 30px;
            box-shadow: $box-shadow;

            &:hover {
              background-color: color(purple);
            }
          }

          span {
            font-size: 17px;
            color: #88949a;
            cursor: pointer;
          }
        }
      }
    }
  }

  .profile {
    display: flex;
    max-width: $wrap-size;
    flex: 1;

    .action-row {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      width: 100%;

      .Button {
        margin-top: 12px;

        button {
          width: 100%;
        }
      }

      .action-button-disabled {
        button {
          background-color: color(light, tertiaryBg);
          .text {
            color: color(grey);
          }
        }
      }
    }

    .join-button-joined {
      button {
        background-color: color(purple, dark);

        span {
          color: color(cream, lightest);
        }
      }
    }
  }
}

@media (max-width: 1240px) {
  .Group {
    padding-bottom: 2px;

    .profile {
      flex-direction: column;
      border-radius: 0;
      margin-top: 20px;
      justify-content: center;
      align-items: center;
      width: 100%;

      .action-row {
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 930px) {
  .Group .profile .page .add-component {
    margin: 10px;
  }
}

@media (max-width: 680px) {
  .Group {
    .profile {
      .action-row {
        width: 70%;
      }
    }
  }
}

@media (max-width: 530px) {
  .Group {
    .Modal {
      .content {
        .onboarding-container {
          padding: 10px;
        }
      }
    }
  }
}
