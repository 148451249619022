@import '../../../styles/variables.scss';

.PopupMenu.applications-popupMenu-type,
.PopupMenu.applications-popupMenu-states,
.PopupMenu.applications-popupMenu-sortBy {
  min-width: 165px;
}

.PopupMenu.grid-menu-content {
  width: 165px;
}

.PopupMenu.applications-popupMenu-states {
  .iconImg {
    width: 12px;
    height: 12px;
    margin-right: 14px;
  }
}

.detail-view-container {
  right: 0;
  height: 100%;
  top: 0;
  overflow: auto;
  background-color: color(light, secondaryBg);
  width: 0;
  z-index: 6;
  transition: width 0.3s;
  position: absolute;
  display: flex;
  padding-bottom: 24px;
  @include thin-scroller-y;

  .tag {
    height: 30px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    padding: 3px 12px;
    margin-right: 6px;

    &.default {
      background: color(grey, pale);
      color: color(dark, border);
    }

    &.approved {
      background: color(green, fade);
      color: color(green, text);
    }

    &.pending {
      background: color(yellow, fade);
      color: color(yellow, text);
    }

    &.rejected,
    &.cancelled {
      background: color(red, fade);
      color: color(red, soft);
    }
  }

  &.active {
    width: 100%;
  }
}

.Applications {
  padding: 0 15px;
  margin-top: 25px;
  position: relative;
  overflow-y: auto;

  @include thin-scroller-y;

  .mobile-filter-container {
    right: 0;
    height: 100%;
    top: 80px;
    overflow: hidden;
    background-color: color(light, secondaryBg);
    width: 0;
    z-index: 6;
    transition: width 0.3s;
    position: fixed;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    font-feature-settings:
      'pnum' on,
      'lnum' on,
      'salt' on,
      'ss09' on;
    color: color(grey, dark);

    .filter-header {
      margin: 10px 0;
      padding-left: 28px;
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 64px;

      i {
        font-size: 16px;
      }

      span {
        margin-left: 17px;
      }
    }

    .filter-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 25px;

      span {
        width: 100%;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 24px;
        font-feature-settings:
          'pnum' on,
          'lnum' on;
        color: color(grey, dark);
        text-align: left;
        margin-bottom: 12px;
      }

      .filter-item {
        width: 100%;
        background: color(light, secondaryBg);
        border: 1px solid color(grey, border);
        box-sizing: border-box;
        border-radius: 10px;
        transition: all 0.2s;
        margin-bottom: 16px;
        padding: 10px 0 10px 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        &.active {
          border-color: color(grey, dark);
        }
      }
    }

    .btn-container {
      display: flex;
      flex-direction: column;
      padding: 0 25px;
      align-items: center;
      justify-content: center;
      margin: 16px 0;

      button {
        width: 100%;
        background-color: color(light, secondaryBg);
        border: 1px solid color(plum, dark);
        box-sizing: border-box;
        border-radius: 22px;
        color: color(plum, dark);
        height: 44px;

        &.submit {
          margin-top: 16px;
          color: color(light, secondaryBg);
          background-color: color(plum, dark);
        }
      }
    }
  }

  .popupCon {
    position: relative;
  }

  .menu {
    width: 36px;
    height: 36px;
    margin-right: 20px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: color(grey, pale);
    }

    i {
      font-size: 20px;
      color: color(grey, font);
      font-weight: lighter;
    }
  }

  .tag {
    height: 30px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    padding: 3px 12px;
    margin-right: 6px;

    &.default {
      background: color(grey, pale);
      color: color(dark, border);
    }

    &.approved {
      background: color(green, fade);
      color: color(green, text);
    }

    &.pending {
      background: color(yellow, fade);
      color: color(yellow, text);
    }

    &.rejected,
    &.cancelled {
      background: color(red, fade);
      color: color(red, soft);
    }
  }

  .mobile-filter-button {
    display: none;
  }

  .section-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    .filter-container {
      padding-left: 15px;

      .filter-dropdown {
        margin-right: 20px;
        min-width: 205px;

        .input-wrap {
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 700;
          font-size: 16px;
          padding: 0 23px 0 16px;

          .menu-btn {
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease-in-out;

            &.show {
              transform: rotate(180deg);
            }
          }

          .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            img {
              width: 12px;
              height: 12px;
              margin-right: 14px;
            }
          }

          &:hover {
            cursor: pointer;
          }
        }

        label {
          z-index: 1;
          bottom: -11px;
          left: 9px;
          background-color: white;
          font-weight: normal;
          position: relative;
          padding: 5px;
          width: fit-content;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .filter-container,
    .view-icons-container {
      display: flex;
      flex-direction: row;
    }

    .view-icons-container {
      Button {
        background-color: white;
        border-radius: 10px;
        border: 1px solid color(grey, border);
        padding: 0;
        min-width: 45px;
        min-height: 45px;
        margin-left: 16px;
        transition: all 0.2s;

        &.active {
          border: 1px solid color(grey, dark);

          i {
            color: color(grey, dark);
          }
        }

        .icon {
          margin-right: 0;
        }

        i {
          color: color(grey, border);
          transition: all 0.2s;
        }

        &:hover {
          background-color: white;
          cursor: pointer;
        }
      }
    }
  }

  .grid-view {
    display: flex;
    flex-wrap: wrap;
    padding-left: 15px;
    margin-top: 16px;

    .application-grid {
      cursor: default;
      width: 256px;
      height: 215px;
      margin: 16px 16px 16px 0;
      background-color: color(light, secondaryBg);
      border-radius: 10px;
      position: relative;
      border: 1px solid color(grey, border);
      display: flex;
      flex-direction: column;
      padding: 32px 0 0 32px;
      transition: all 0.2s;

      &:hover {
        box-shadow: $box-shadow-darkest;
      }

      .card-top {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: color(grey, txt);

        .group-name-content {
          margin-top: 10px;
          color: color(grey, txt);
          font-size: 14px;
          font-weight: bold;
          display: flex;
          align-items: center;
          flex-direction: row;

          .label {
            a {
              display: block;
              text-decoration: none;
              max-width: 200px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              font-style: normal;
              font-weight: bold;
              line-height: 14px;
              color: color(grey, txt);
              transition: all 0.2s;

              &:hover {
                color: color(purple, dark);
              }
            }
          }

          .logo {
            margin-right: 8px;

            img {
              border-radius: 50%;
              border: 1px solid color(light, shadow);
              box-sizing: border-box;
              width: 16px;
              height: 16px;
              margin-left: -2px;
            }
          }
        }

        .location-content {
          display: flex;
          align-items: center;
          margin-top: 8px;
          font-size: 14px;

          .icon {
            margin-right: 8px;

            i {
              font-size: 16px;
              color: color(grey, txt);
            }
          }
        }

        .date-content {
          font-size: 14px;
          margin-top: 8px;
        }

        .card-label {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          color: color(light, fontColor);
          font-weight: 700;
          font-size: larger;
          height: 36px;

          span {
            text-overflow: ellipsis;
            max-width: 190px;
            line-height: 24px;
            overflow: hidden;
            font-size: 16px;
            margin-bottom: 15px;
          }

          .label {
            @include truncate(2);
            overflow: hidden;
            -webkit-box-orient: vertical;
            display: block;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
          }
        }
      }

      .card-bottom {
        padding-top: 28px;
        padding-bottom: 32px;
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: flex-start;
      }
    }
  }

  .list-view {
    display: flex;
    flex-wrap: wrap;
    padding-left: 15px;
    padding-bottom: 16px;

    .menu {
      width: 44px;
      height: 44px;

      i {
        font-size: 26px;
      }
    }

    .application-card {
      cursor: default;
      height: 96px;
      padding: 19px 0 19px 19px;
      background: color(light, secondaryBg);
      border: 1px solid color(light, shadow);
      box-sizing: border-box;
      border-radius: 10px;
      margin-top: 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      transition: all 0.2s;

      &:hover {
        box-shadow: $box-shadow-darkest;
      }

      .location-content,
      .date-content {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: color(grey, txt);
      }

      .date-content {
        width: 84px;
      }

      .tag-container {
        display: flex;
        width: 250px;
      }

      .location-content {
        text-align: center;
        width: 350px;

        .icon {
          margin-right: 8px;

          i {
            font-size: 16px;
            color: color(grey, txt);
          }
        }
      }

      .card-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;

        .labels {
          display: flex;
          flex-direction: column;
          text-align: left;

          .title {
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 21px;
            color: color(blue, space);
          }

          .subtitle {
            a {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              color: color(grey, txt);
              text-decoration: none;
              transition: all 0.2s;

              &:hover {
                color: color(purple, dark);
              }
            }
          }
        }

        .icon {
          margin-right: 18px;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 35px;
            height: 35px;
            border: 1px solid color(light, shadow);
            box-sizing: border-box;
            border-radius: 111px;
          }
        }
      }
    }
  }

  .Modal {
    .contact-options {
      padding: 20px 40px;
      a {
        padding: 20px;
        display: block;
        background: rgba(0, 0, 0, 0.12);
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        color: #000;
        margin-bottom: 10px;
      }
    }

    .actions {
      padding: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: 1280px) {
  .Applications {
    .list-view {
      .application-card {
        .location-content {
          width: 300px;
        }

        .tag-container {
          width: 200px;
        }
      }
    }
  }
}

@media (max-width: 842px) {
  .PopupMenu.grid-menu-content {
    right: 51px;
    left: unset !important;
  }

  .Applications {
    .mobile-filter-container {
      &.active {
        width: 100%;
        overflow: auto;
        max-height: calc(100vh - 147px);
      }
    }

    .mobile-filter-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid color(grey, dark);
        box-sizing: border-box;
        border-radius: 10px;
        width: 44px;
        height: 44px;
        margin-right: 15px;

        i {
          font-size: 18px;
        }
      }
    }

    .section-wrap {
      .view-icons-container {
        display: none;
      }

      .filter-dropdown.type,
      .filter-dropdown.sortBy {
        display: none;
      }

      .filter-container {
        width: 100%;

        .filter-dropdown {
          width: 100%;
          margin-right: 15px;
        }
      }
    }
  }
}

@media (max-width: 657px) {
  .Applications {
    .grid-view {
      .application-grid {
        width: 100%;
      }
    }
  }
}
