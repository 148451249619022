@import '../../../../styles/variables.scss';

.SelectPayment {
  .select-payment-header {
    padding-bottom: 18px;
    padding-top: 18px;
    margin-bottom: 10px;
    border-bottom: 1px solid lightgray;
  }

  .select-payment {
    padding: 35px 50px 55px 50px;

    .payment-info {
      font-size: 1.5em;
      color: color(purple, dark);
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid lightgray;
    }
  }
}
