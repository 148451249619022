@import '../../../styles/variables.scss';

.PaymentMethodsForm {
  padding: 15px 40px;
  flex: 1;

  .title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 10px;
  }

  .sub-title {
    margin-top: 20px;
    font-size: 19px;
    color: color(purple, dark);
    border-bottom: 1px solid color(grey, light);
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .row {
    display: flex;
    margin-bottom: 12px;

    .Dropdown {
      max-width: 200px;

      .input-wrap {
        select {
          max-width: 200px;
          white-space: pre-wrap;
          text-overflow: ellipsis;
        }
      }
    }

    &.dbl {
      .TextField,
      .Dropdown {
        max-width: 200px;
        .input-wrap {
          select {
            max-width: 200px;
            white-space: pre-wrap;
            text-overflow: ellipsis;
          }
        }
        &:first-child {
          margin-right: 12px;
        }

        &:last-child {
          margin-left: 12px;
        }
      }
    }
  }

  .card-wrap {
    label {
      display: block;
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 4px;
    }

    .card-inner {
      height: 44px;
      border: 1px solid color(grey, light);
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;

      .StripeElement {
        flex: 1;
      }
    }
  }

  .actions {
    margin-top: 20px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    .Button {
      button {
        min-width: 130px;
      }
    }
  }
}

@media (max-width: 680px) {
  .PaymentMethodsForm {
    overflow: auto;
  }
}
