.CreateAHub {
  .details-row {
    padding: 0 80px 40px 80px;
    display: flex;
    justify-content: space-between;
    .counter {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      .counter-input {
        max-width: 62px;
        max-height: 34px;
        .input-wrap {
          height: 100%;
          width: 100%;
          input {
            height: 100%;
            width: 100%;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type='number'] {
            -moz-appearance: textfield;
          }
        }
      }
      .counter-button {
        width: 34px;
        height: 34px;
        border-radius: 17px;
        background: #f5f6f7;
        display: flex;
        align-items: center;
        justify-content: center;
        &.disabled {
          opacity: 0.6;
          cursor: no-drop;
        }
        &.disabled:hover {
          cursor: no-drop;
        }
        &:hover {
          cursor: pointer;
        }
        &.sub {
          margin-right: 16px;
        }
        &.add {
          margin-left: 16px;
        }
      }
    }
    .month-price {
      padding: 0 68px;
      font-size: 10px;
    }
    .annual-price {
      font-size: 10px;
    }
    .month-price,
    .annual-price {
      .price-amount {
        margin-right: 10px;
        font-size: 21px;
        font-weight: 700;
      }
    }
  }
}
