@import '../../../../styles/variables.scss';

.HubManagementAuction {
  display: flex;
  flex-flow: column nowrap;
  padding: 50px;
  height: 100%;
  gap: 30px;

  .header-section {
    display: flex;
    align-items: center;

    .header-actions {
      display: flex;
      margin-left: auto;
      gap: 15px;
    }
  }

  .metrics-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 15px;
    min-height: 114px;

    .metric {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;
      flex: 1;
      border: 1px solid color(grey, border);
      border-radius: 10px;
      padding: 30px;
      gap: 10px;

      .label {
        color: #3a3a3a;
      }
      .value {
        font-size: 18px;
        font-weight: 800;
      }
    }
  }

  .table-section {
    min-height: 400px;

    .TableComponent {
      .item-name-col {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 15px;
        min-height: 60px;

        .image-container {
          object-fit: cover;
          border-radius: 50%;
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}
