@import '../../../../styles/variables.scss';

.CardLoaderItem {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
  background: color(light, secondaryBg);
  box-shadow: 0 16px 28px rgba(0, 0, 0, 0.08);
  width: 363px;
  overflow: hidden;
  padding-bottom: 16px;

  &.LIST {
    width: 100%;
  }

  .container {
    width: 100%;
    min-height: 60px;
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;

    &.abs {
      position: absolute;
      top: 32px;
      left: 0.5em;
      height: auto;

      span {
        color: color(light, secondaryBg);
      }
    }

    @keyframes pulseAnimation {
      0%,
      80%,
      100% {
        box-shadow: 0 2.5em 0 -1.3em;
      }
      40% {
        box-shadow: 0 2.5em 0 0;
      }
    }

    span,
    span:before,
    span:after {
      border-radius: 50%;
      width: 2.5em;
      height: 2.5em;
      animation-fill-mode: both;
      animation: pulseAnimation 1.8s infinite ease-in-out;
    }

    span {
      color: color(light, shadow);
      font-size: 5px;
      position: relative;
      text-indent: -9999em;
      transform: translateZ(0);
      animation-delay: -0.16s;
    }

    span:before,
    span:after {
      content: '';
      position: absolute;
      top: 0;
    }
    span:before {
      left: -3.5em;
      animation-delay: -0.32s;
    }
    span:after {
      left: 3.5em;
    }
  }

  .profile-img {
    position: absolute;
    top: 32px;
    left: 32px;
    width: 40px;
    height: 40px;
    background: color(cream, gray);
    border-radius: 20px;
  }

  .cover-img {
    background: color(grey, pale);
    min-height: 140px;
  }

  .text {
    display: flex;
    flex-direction: column;

    .group {
      display: flex;
      flex-direction: column;
      margin-top: 14px;
      padding: 0 32px;
    }

    span {
      margin-top: 10px;
      width: 100%;
      height: 12px;
      border-radius: 10px;

      &.dark {
        background: color(light, shadow);
      }

      &.light {
        background: color(grey, pale);
      }

      &.short {
        width: 25%;
      }
    }
  }

  .tags {
    padding: 0 32px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .tag {
      border-radius: 6px;
      height: 28px;
      width: 90px;
      background: color(light, shadow);
    }
  }

  .one-button,
  .two-buttons {
    margin-top: 16px;
    width: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: nowrap;
    padding: 0 32px;
  }

  .two-buttons {
    column-gap: 16px;
  }

  .btn {
    background: color(grey, pale);
    height: 44px;
    flex-grow: 1;
    border-radius: 100px;
  }
}
