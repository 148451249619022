@import '../../../../styles/variables.scss';
.ConsolidatedListView {
  .header-bar {
    width: 100%;
    margin-bottom: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // changes made: color added - #3a3a3a (GIG6585)

    .title {
      font-weight: 700;
      font-size: 28px;
      display: flex;
      align-items: center;
      color: #3a3a3a;
    }

    .filters {
      display: flex;
      justify-content: flex-end;
      column-gap: 24px;

      .filter-dropdown {
        width: 200px;

        .input-wrap {
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 700;
          font-size: 16px;
          color: #012939;
          padding: 0 23px 0 16px;
          border: 1px solid color(grey, lightest);

          .menu-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease-in-out;

            &.show {
              transform: rotate(180deg);
            }
          }

          .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            i {
              margin-right: 8px;
              margin-top: 4px;
            }

            img {
              width: 12px;
              height: 12px;
              margin-right: 14px;
            }
          }

          &:hover {
            cursor: pointer;
          }
        }

        label {
          z-index: 1;
          bottom: -11px;
          left: 9px;
          background-color: white;
          color: color(grey, dark);
          font-weight: normal;
          position: relative;
          padding: 5px;
          width: fit-content;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .list-view {
    .no-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      place-self: center;

      .no-content-icon {
        width: 176px;
        height: 176px;
        background: #fafbfb;
        border-radius: 88px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 66px;
          color: #e1e1e1;
        }
      }
      .no-content-text {
        text-align: center;
        margin-top: 32px;
        font-weight: 900;
        font-size: 16px;
        color: #3a3a3a;
        .focus-area-manage:hover {
          cursor: pointer;
        }
        span,
        a {
          display: block;
          text-decoration-line: underline;
          font-weight: 600;
          font-size: 16px;
          color: #a9aaac;
          padding-top: 8px;
        }
        span {
          text-decoration-line: none;
        }
      }
    }

    .row-details {
      padding: 23px 60px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #ededec;
      .metric-item {
        margin-right: 80px;
        font-weight: 800;
        font-size: 28px;
        .metric-label {
          font-weight: 700;
          font-size: 14px;
        }

        &.causes {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .cause-list {
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          width: 30px;
          height: 30px;
          background: #ffffff;
          border: 2px solid #f5f5f5;
          border-radius: 15px;
          margin-left: -5px;

          font-weight: 700;
          font-size: 11px;
          color: #898989;
          &.first {
            margin-left: 0;
          }
        }
      }
    }

    .list-item {
      padding: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #ededec;

      &:hover {
        cursor: pointer;
      }

      &.expanded {
        background: #f5f5f5;
        border-radius: 10px;
        border: none;
      }

      &.first {
        border-top: 1px solid #ededec;
      }

      .item-section {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .item-title {
          margin-left: 17px;
          font-size: 16px;
          font-weight: 900;
        }

        .item-handle {
          display: block;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
}

@media (max-width: 1070px) {
  .ConsolidatedListView {
    .list-view {
      .row-details {
        .metric-item {
          margin-right: 30px;
        }
      }
    }
  }
}

@media (max-width: 890px) {
  .ConsolidatedListView {
    .list-view {
      .list-item {
        .item-section {
          .item-handle {
            display: none;
          }
        }
      }
      .row-details {
        flex-wrap: wrap;
        padding: 23px 10px;
        .metric-item {
          flex: 1 1 50%;
          padding: 10px 0;
          width: 25%;
          margin: 0;
        }
      }
    }
  }
}
