@import '../../../styles/variables.scss';

.Modal.share-modal.ShareModalPageContainer {
  .ModalHeader .modal-close-icon {
    top: 16px;
    right: 0;
  }
}

.PageCoverImage {
  .cover-image-wrap {
    height: 380px;
  }

  .CoverImage {
    box-shadow: 0px 3px 6px #00000029;
  }

  .float {
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: auto;
    z-index: 2;
    font-weight: 700;

    &.more-left {
      right: 180px;
    }

    .share-btn {
      cursor: pointer;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: color(grey, lightester);
      color: color(otherblue, grey);
      transition: all 0.2s;

      &:hover {
        background-color: color(plum, dark);
        color: color(light, secondaryBg);
      }
    }

    a {
      display: flex;
      border: 0px;
      padding: 0px 28px;
      font-size: 16px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      color: color(dark, fontColor);
      background-color: color(pink);
      overflow: hidden;
      min-width: 80px;
      min-height: 40px;
      text-decoration: none;
      box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.45);

      &:hover {
        background-color: color(pink);
      }

      span {
        &:first-child {
          margin-right: 8px;
        }
      }
    }
  }
}

@media (max-width: 1240px) {
  .PageCoverImage {
    .CoverImage {
      display: none;
    }

    .float {
      position: fixed;
      bottom: 20px;
      right: 20px;
      top: auto;

      a {
        min-width: 40px;
        min-height: 40px;
        width: 40px;
        height: 40px;
        padding: 0 0;
        box-sizing: border-box;

        span {
          &:first-child {
            margin-right: 0;
          }
        }
      }

      .manage-text {
        display: none;
      }
    }
  }
}
