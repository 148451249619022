@import '../../../../styles/variables.scss';

.GenerateTaxReceipt {
  width: 700px;

  &.content {
    padding: 0 46px 46px 62px;
  }

  .TextField,
  .Dropdown {
    margin-bottom: 30px;
  }

  .tip {
    margin-bottom: 40px;
    color: color(grey, base);
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    a {
      color: color(purple, dark);
      text-decoration: none;
      font-weight: 500;

      &:hover {
        color: color(purple, darker);
      }
    }
  }

  .sequence-warning {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .warning {
      color: color(yellow, warning);
    }
  }

  .toggle {
    width: max-content;
    cursor: pointer;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    i {
      font-size: 32px;
      margin-right: 8px;
      transition: all 0.2s;

      &.fa-toggle-on {
        color: color(purple, dark);
      }
    }
  }

  .three-columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 30px;

    .TextField {
      margin-bottom: unset;
      flex-grow: 1;

      &.middle {
        margin: 0 26px;
      }
    }
  }

  .two-columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    .TextField {
      margin-bottom: unset;
      flex-grow: 4;
      margin-right: 26px;
    }

    .Dropdown {
      margin-bottom: unset;
      flex-grow: 1;
    }
  }

  .controls {
    button {
      &.edit-button {
        margin-left: 16px;
        width: 161px;
      }
    }
  }
}
