@import '../../styles/variables.scss';

.dark {
  .TextField {
    input {
      color: color(dark, fontColor);
    }
  }
}

.TextField {
  font-family: $lato;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto;

  label,
  .helper {
    display: flex;
    flex: 1;
  }

  label {
    position: relative;
    padding-bottom: 4px;
    font-size: 14px;
    font-weight: 700;

    span {
      flex: 1;
      color: color(purple, dark);
    }

    .required {
      color: color(purple, dark);
    }

    .helper {
      flex: 1;
      font-size: 14px;
      color: color(grey);
      max-width: 15px;
      min-width: 15px;
      display: flex;
      position: relative;

      span {
        background-color: color(light, secondaryBg);
        box-shadow: $box-shadow;
        padding: 10px;
        border-radius: 8px;
        display: none;
        position: absolute;
        top: -13px;
        right: 150%;
        width: 1200%;
        font-weight: 400;
        line-height: 18px;
        font-size: 12px;

        &:after {
          content: ' ';
          border: 10px solid transparent;
          border-left: 10px solid color(light, secondaryBg);
          position: absolute;
          right: -20px;
          top: 10px;
        }
      }

      &:hover {
        span {
          display: block;
        }
      }
    }
  }

  &.disabled {
    label {
      span {
        color: color(grey, border) !important;
      }
    }
  }

  .input-wrap {
    display: flex;
    border: 1px solid color(grey, border);
    border-radius: 7px;
    overflow: hidden;

    &.focused {
      border-color: color(grey, dark);
    }

    &:hover:not(.disabled):not(.focused) {
      border-color: color(grey, text);
    }

    &.error {
      border: 1px solid red;
    }

    .main-icon {
      height: 42px;
      min-width: 42px;
      max-width: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: color(purple, dark);

      &.hoverable {
        &:hover {
          cursor: pointer;
          color: color(grey, darkest);
        }
      }
    }

    .clear-input-icon {
      height: 42px;
      min-width: 42px;
      max-width: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: color(red, soft);

      &:hover {
        cursor: pointer;
        color: color(red, soft2);
      }
    }

    &.disabled {
      .main-icon {
        background-color: color(cream, lighter);
        color: color(grey, lighter);

        &:hover {
          cursor: not-allowed;
        }
      }
    }

    input {
      font-family: $lato;
      padding: 0;
      border: 0;
      height: 42px;
      display: flex;
      flex: 1;
      font-size: 16px;
      text-indent: 8px;

      &:focus {
        outline: 0;
        border-color: black !important;
      }

      &:disabled {
        background-color: color(cream, lighter);
        color: color(grey, lighter);
        cursor: not-allowed;
      }

      &:focus + .placeholder,
      &:not([value='']) + .placeholder {
        color: color(grey);
        font-size: 13px;
        top: -19px;
        left: 0;
      }
    }
  }

  span.error_text {
    color: red;
    font-size: 11px;
    margin: 5px 0 0 3px;
  }

  span.bottom-label {
    font-size: 14px;
    margin-top: 4px;
    color: color(grey, base);
  }
}
