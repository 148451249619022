.Modal.HubSharedDetails {
  .content {
    .modal-close-circle {
      top: 32px;
      right: 32px;
    }
  }
}

.HubSharedDetailsModal {
  max-width: 630px;
  width: 630px;
  border-radius: 8px;
  overflow: hidden;
  .cause-description {
    padding: 20px 32px;
    max-height: 200px;
    overflow: auto;
    .cause-description-title {
      font-size: 16px;
      font-weight: 800;
    }
    .cause-description-content {
      .QuillTextEditor {
        .ql-editor {
          padding: 12px 0;
          a {
            color: color(light, fontColor);
            text-decoration: none;

            &:hover {
              color: color(purple, dark);
            }
          }
        }
      }
    }
  }
  .cause-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
    margin: 0 32px;
    border-top: 1px solid #d0d1d1;
    border-bottom: 1px solid #d0d1d1;

    .Button.cause-action {
      button {
        color: #2f2956;
        background: #fff;
        font-size: 14px;
        font-weight: 700;
        border: 1px solid #2f2956;
      }

      &.donation-loading {
        button {
          color: #fff;
          background: #2f2956;
        }
      }
    }

    a {
      width: 20%;
    }

    .cause-action:not(.Button) {
      padding: 8px 28px;
      border: 1px solid #2f2956;
      font-size: 14px;
      color: #2f2956;
      font-weight: 700;
      border-radius: 22px;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;

      &.full {
        color: #fff;
        background: #2f2956;
      }
    }
  }
  .cause-title-details {
    position: relative;
    padding: 18px 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    .cause-title-charity {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .cause-charity {
        font-size: 14px;
        font-weight: 700;
        color: #898989;
      }
      .cause-title {
        padding-right: 20px;
        font-size: 21px;
        font-weight: 800;
        margin-bottom: 2px;
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          display: inline-block;
        }
      }
    }
    .learn-more-action {
      .Button {
        button {
          background: #ededec;
          color: #3a3a3a;
          font-size: 14px;
          padding: 10px 24px;
        }
      }
    }
  }
  .hub-cause-cover {
    max-height: 280px;
    min-height: 150px;
    position: relative;
    .cause-profile.Portrait {
      position: absolute;
      top: 32px;
      left: 32px;
    }
    .cover-img-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 280px;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 480px) {
  .HubSharedDetailsModal {
    .cause-title-details {
      .cause-title-charity {
        width: 50%;
      }
    }
    .cause-actions {
      flex-direction: column;

      a {
        width: auto;

        &:nth-of-type(1) {
          margin-right: 0px;
        }

        &:nth-of-type(3) {
          margin-left: 0px;
        }
      }

      .cause-action {
        margin-bottom: 5px;
      }
      .Button.cause-action {
        button {
          width: 100%;
        }
      }
    }
  }
}
