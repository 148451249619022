@import '../../../styles/variables.scss';

.MyProfile {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 32px;

  @include thin-scroller-y;

  .tab {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 36px;

    hr {
      width: 100%;
      border: unset;
      border-top: 1px color(dark, borderLight) solid;
    }

    section {
      width: 100%;
      display: flex;
      row-gap: 20px;
      padding-bottom: 32px;

      &.avatar,
      &.cover-img {
        flex-direction: column;
      }

      &.cover-img {
        .section-content {
          height: 202px;
          max-width: 420px;
        }
      }

      .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        row-gap: 4px;

        .title-section {
          display: flex;
          flex-direction: column;

          h2 {
            font-weight: 700;
            font-size: 16px;
            margin: 0 0 4px 0;
            color: color(plum, grey);
          }

          .header-desc {
            font-weight: 500;
            font-size: 14px;
            color: color(grey, text3);

            a {
              text-decoration: none;
              color: black;
              transition: color 0.2s;

              &:hover {
                color: color(plum, dark);
              }
            }
          }
        }

        .cb-container {
          display: flex;
          column-gap: 16px;

          i {
            font-size: 20px;
            color: color(plum, dark);
            cursor: pointer;

            &.pill {
              font-size: 32px;
              transition: all 0.2s;

              &.fa-toggle-off {
                color: color(grey, lighter);
              }
            }
          }

          .Checkbox {
            label {
              column-gap: 16px;

              p {
                line-height: 20px;
                font-weight: 700;
                font-size: 14px;
                color: black;
              }
            }
          }
        }
      }

      .section-content {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.UserProfileInfoModal {
  .content {
    padding: 48px;

    .ModalHeader {
      display: none;
    }

    .header-custom {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .form-container {
      display: flex;
      flex-direction: column;

      .actions {
        display: flex;
        place-content: flex-end;
        column-gap: 16px;
        margin-top: 32px;
      }

      form {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        .dob-error {
          color: color(red, error);
          font-size: 14px;
        }

        .gender-label {
          padding-bottom: 4px;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        .gender-selector {
          margin-top: 10px;
          display: flex;

          div {
            color: color(light, fontColor);
            margin-right: 40px;

            &:hover {
              cursor: pointer;
            }

            i {
              margin-right: 10px;
              font-size: 27px;
            }

            span {
              font-size: 17px;
              line-height: 30px;
            }
          }

          .selected {
            color: color(purple, dark);
          }
        }

        .TextField {
          label {
            span {
              font-weight: 700;
              font-size: 14px;
              color: color(grey, dark);
            }
          }

          .input-wrap {
            background: color(light, secondaryBg);
            border: 1px solid color(grey, border);
            border-radius: 10px;
            padding: 0 18px;
          }
        }
      }
    }
  }
}

@media (max-width: 1070px) {
  .UserProfileInfoModal {
    .content {
      .form-container {
        padding: 0 24px;
      }
    }
  }

  .MyProfile {
    .tab {
      section {
        &.cover-img {
          height: 220px;
        }
      }
    }
  }
}
