@import '../../../styles/variables.scss';

.ToggleButton {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  .label {
    margin-right: 10px;
    font-size: 14px;
    color: color(grey, base);
    font-weight: 700;
  }

  i {
    font-size: 31px;

    &.fa-toggle-on {
      color: color(purple, dark);
    }

    &.fa-toggle-off {
    }
  }

  &:hover {
    cursor: pointer;
  }
}
