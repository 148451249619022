@import '../../styles/variables.scss';

.GroupFundraiserManage {
  flex: 1;
  display: flex;

  .section-title {
    .filter-button {
      margin-right: 10px;
    }

    .section-create {
      .Button {
        button {
          box-shadow: $box-shadow;
        }
      }
    }
  }

  .fundraiser-name-link {
    text-decoration: underline;
    color: color(light, fontColor);
  }

  .is-creator-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    min-width: 150px;
    max-width: 200px;
    color: color(purple, dark);
    background-color: color(purple, lighter);
    border-radius: 18px;
  }

  .ContactDetailsModal {
    justify-content: flex-start;
    align-items: stretch;

    .content {
      position: relative;
      flex: 1;
      max-width: 100%;
      max-height: 100%;
      margin: 40px 30px;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;

      .modal-close-circle {
        right: 15px;
        top: 15px;
      }
    }

    .ModalHeader {
      display: none;
    }
  }

  .GroupFundraiserManagement-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    .search {
      display: flex;
      align-items: center;

      .create {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }

      .TextField {
        max-width: 50%;

        i {
          background-color: color(light, secondaryBg);
          color: color(light, fontColor);
        }

        input {
          background-color: color(light, secondaryBg);
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      flex: 1;

      .list-inner {
        flex: 1;
        max-height: 100%;
        overflow: auto;
        position: relative;

        .list-rows {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;

          .SortableTable {
            .table-row {
              .col {
                &.user_display_name {
                  font-weight: 500;
                }

                &.status_code {
                  span {
                    padding: 4px 8px;
                    border-radius: $border-radius;
                    text-transform: capitalize;

                    &.approved {
                      background-color: #eafcf5;
                      color: #067f5c;
                    }

                    &.declined,
                    &.closed,
                    &.rejected {
                      color: #cf2035;
                      background-color: #fce9ea;
                    }

                    &.pending,
                    &.draft {
                      background-color: #f3f4f9;
                      color: #686c8b;
                    }
                  }
                }
              }
            }
          }

          .row-details {
            display: flex;
            transition: height 150ms ease-in-out;
            justify-content: space-between;
            margin: 10px 0px;

            .third {
              background-color: color(blue, lightest);
              flex: 1;
              border-radius: 12px;
              margin-right: 12px;
              overflow: hidden;
              position: relative;
              display: flex;
              flex-direction: column;

              .view-application {
                display: flex;
                width: 100%;
                position: absolute;
                bottom: 10px;
                left: 0;

                .Button {
                  flex: 1;
                  display: flex;

                  button {
                    flex: 1;
                  }
                }
              }

              &.metrics {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                padding: 12px;
              }

              .metric {
                display: flex;
                flex-direction: column;
                width: 48%;
                flex-wrap: wrap;
                margin: 1%;

                &.long {
                  width: 100%;

                  .metric-inner {
                    justify-content: center;
                    align-items: center;
                    padding-top: 0px;
                    margin: 5px;
                  }
                }

                .metric-inner {
                  border-radius: 8px;
                  background-color: color(light, secondaryBg);
                  display: flex;
                  flex: 1;
                  flex-direction: column;

                  .metric-label {
                    margin-bottom: 10px;
                    font-size: 16px;
                    text-align: center;
                  }

                  .metric-value {
                    color: #6565d3;
                    font-size: 30px;
                  }

                  div {
                    text-align: center;
                  }
                }

                span,
                div {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }

              .CoverImage {
                border-radius: 0px;
                min-height: 217px;
              }

              .Portrait {
                justify-content: center;
                display: flex;
              }

              .title {
                font-weight: 700;
                padding: 10px 0px;
                border-bottom: 1px solid color(grey, light);
                margin: 0px 20px;
                padding-top: 20px;
                font-size: 21px;
              }

              .sub-title {
                margin: 20px 20px 5px;
                font-weight: 700;
                font-size: 14px;
              }

              .phone,
              .email {
                padding: 0px 20px;
                font-size: 14px;
                color: color(grey);
                line-height: 21px;
              }

              .age-check {
                padding: 5px 20px 0px;
                color: color(yellow, warning);
                font-size: 14px;
                font-weight: 500;
                display: flex;
                align-items: center;

                i {
                  margin-right: 5px;
                  font-size: 16px;
                }
              }

              .address,
              .dob {
                padding: 0px 20px;
                font-size: 14px;
                color: color(grey);
                line-height: 21px;
              }

              .inner-user-details {
                position: relative;
                padding-top: 70px;
                display: flex;
                flex-direction: column;
                flex: 1;

                .Portrait {
                  position: absolute;
                  left: 53%;
                  margin-left: -62px;
                  top: -60px;
                }

                .user-actions {
                  display: flex;
                  flex: 1;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  padding: 20px 20px 10px;

                  a {
                    display: flex;
                    justify-content: center;
                    border: 0px;
                    padding: 0px 28px;
                    font-size: 16px;
                    border-radius: $border-radius;
                    justify-content: center;
                    align-items: center;
                    color: color(dark, fontColor);
                    background-color: color(purple, dark);
                    overflow: hidden;
                    min-width: 80px;
                    min-height: 40px;
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 16px;

                    i {
                      padding-right: 8px;
                    }

                    &:hover {
                      background-color: color(purple);
                    }

                    &.faded {
                      background-color: color(blue, lightest);
                      border: 3px solid color(grey, light);
                      color: color(grey);

                      &:hover {
                        color: color(purple);
                        border: 3px solid color(purple);
                      }
                    }
                  }

                  .main-action {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: center;

                    .Button,
                    button {
                      flex: 1;
                      width: 100%;
                    }

                    button {
                      box-shadow: $box-shadow;
                    }
                  }

                  .sub-actions {
                    display: flex;
                    width: 100%;
                    padding-bottom: 20px;

                    .Button {
                      flex: 1;

                      button {
                        width: 100%;
                        background-color: color(light, secondaryBg);
                        color: color(purple, dark);
                        border: 2px solid color(purple, dark);
                        box-shadow: $box-shadow;

                        &:hover {
                          color: color(purple);
                          border: 2px solid color(purple);
                        }
                      }

                      &:first-child {
                        margin-right: 10px;
                      }
                    }

                    a {
                      margin-left: 10px;
                    }
                  }
                }

                .user-name,
                .user-handle {
                  display: flex;
                  justify-content: center;
                  color: color(light, fontColor);

                  a {
                    color: color(light, fontColor);
                    text-decoration: none;

                    &:hover {
                      color: color(purple, dark);
                    }
                  }
                }

                .user-handle {
                  font-size: 16px;
                  color: color(grey);
                  padding-top: 4px;
                }

                .user-name {
                  font-size: 21px;
                  padding-top: 10px;
                  font-weight: 700;
                }
              }
            }
          }

          .empty {
            flex: 1;
            padding: 30px 20px;
            text-align: center;
            color: color(grey);
            font-size: 16px;
          }
        }
      }
    }
  }

  .Modal {
    &.transparent {
      .content {
        background-color: transparent;
      }
    }
  }

  .content {
    min-width: 600px;
  }
}
