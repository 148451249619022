.DateSelection {
  .date-row {
    display: flex;
    justify-content: space-between;
    flex: 1;

    .date-container {
      width: 100%;

      &:first-of-type {
        margin-right: 25px;
      }
      .custom-dtp {
        flex: 1;
        input {
          text-align: unset;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .date-row {
    flex-direction: column;

    .date-container {
      margin: 15px 0;
    }
  }
}
