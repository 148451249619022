@import '../../../styles/variables.scss';

.PopupMenu.mypages-popupMenu-states,
.PopupMenu.mypages-popupMenu-sortBy,
.PopupMenu.mypages-popupMenu-eventType,
.PopupMenu.mypages-popupMenu-volunteerType {
  min-width: 165px;
}

.PopupMenu.mypages-popupMenu-states {
  .iconImg {
    width: 12px;
    height: 12px;
    margin-right: 14px;
  }
}

.MyPages {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 15px 15px 15px;
  margin-top: 25px;
  position: relative;
  flex: 1;
  flex-flow: column nowrap;
  @include thin-scroller-y;

  .mobile-filter-container {
    right: 0;
    height: 100%;
    top: 80px;
    overflow: hidden;
    background-color: color(light, secondaryBg);
    width: 0;
    z-index: 6;
    transition: width 0.3s;
    position: fixed;
    // display: fixed;
    flex-direction: column;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    font-feature-settings:
      'pnum' on,
      'lnum' on,
      'salt' on,
      'ss09' on;
    color: color(grey, dark);

    .filter-header {
      margin: 10px 0;
      padding-left: 28px;
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 64px;

      i {
        font-size: 16px;
      }

      span {
        margin-left: 17px;
      }
    }

    .filter-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 25px;

      span {
        width: 100%;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 24px;
        font-feature-settings:
          'pnum' on,
          'lnum' on;
        color: color(grey, dark);
        text-align: left;
        margin-bottom: 12px;
      }

      .filter-item {
        width: 100%;
        background: color(light, secondaryBg);
        border: 1px solid color(grey, border);
        box-sizing: border-box;
        border-radius: 10px;
        transition: all 0.2s;
        margin-bottom: 16px;
        padding: 10px 0 10px 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        &.active {
          border-color: color(grey, dark);
        }
      }
    }

    .btn-container {
      display: flex;
      flex-direction: column;
      padding: 0 25px;
      align-items: center;
      justify-content: center;
      margin: 16px 0;

      button {
        width: 100%;
        background-color: color(light, secondaryBg);
        border: 1px solid color(plum, dark);
        box-sizing: border-box;
        border-radius: 22px;
        color: color(plum, dark);
        height: 44px;

        &.submit {
          margin-top: 16px;
          color: color(light, secondaryBg);
          background-color: color(plum, dark);
        }
      }
    }
  }

  .popupCon {
    position: relative;
  }

  .menu {
    width: 36px;
    height: 36px;
    margin-right: 20px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: color(grey, pale);
    }

    i {
      font-size: 20px;
      color: color(grey, font);
      font-weight: lighter;
    }
  }

  .tag {
    height: 30px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    padding: 3px 12px;
    margin-right: 6px;

    &.active,
    &.running {
      width: 50px;
      background-color: #fff0eb;
      color: #f89979;
    }

    &.published {
      width: 70px;
      background-color: #eaeaf8;
      color: #6565d3;
    }

    &.draft {
      width: 40px;
      background-color: #e1ebf7;
      color: #f1b94d;
    }
  }

  .mobile-filter-button {
    display: none;
  }

  .section-wrap {
    // display: fixed;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    .section-inner-no-data {
      text-align: center;
      flex-wrap: wrap;

      .loader-view {
        display: flex;
        flex-wrap: wrap;
        padding-left: 15px;
        margin-top: 16px;

        // TODO: change this for group/event/hub, and remove this default
        .loaderItemContainer {
          grid-template-columns: repeat(auto-fit, 320px) !important;
          row-gap: 32px;
          column-gap: 16px;

          .loaderItem {
            width: 320px;
            height: 297px;
          }
        }

        &.gig,
        &.volunteer {
          .loaderItemContainer {
            grid-template-columns: repeat(auto-fit, 320px) !important;
            row-gap: 32px;
            column-gap: 16px;

            .loaderItem {
              width: 320px;
              height: 281px;

              &.LIST {
                border-radius: unset;
                box-shadow: unset;
                padding: 0;
                width: 100%;
                height: 151px;
                flex-direction: row-reverse;
                position: relative;
                border-bottom: 2px solid color(grey, pale);

                .loader.container {
                  height: 100%;
                  margin-left: 64px;
                  justify-content: flex-start;
                }

                .text.container {
                  margin-left: 64px;
                  height: 100%;
                  justify-content: center;
                }

                .tags.container {
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  width: max-content;
                }
              }
            }
          }
        }
      }

      .empty-state {
        padding-top: 10%;
        text-align: center;
        position: relative;
        align-items: center;
        margin: auto;

        p {
          margin: 0;
          padding: 0;
          font-family: $lato;
          font-size: 16px;
          line-height: 19px;
        }

        .top-message {
          font-weight: 900;
          margin-top: 24px;
          color: #5a5b5d;
        }

        .bottom-message {
          margin-top: 8px;
          color: #a9aaac;
        }
      }
    }

    .filter-container {
      padding-left: 15px;

      .filter-dropdown {
        margin-right: 20px;
        min-width: 205px;

        .input-wrap {
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 700;
          font-size: 16px;
          color: #012939;
          padding: 0 23px 0 16px;

          .menu-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease-in-out;

            &.show {
              transform: rotate(180deg);
            }
          }

          .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .img {
              width: 12px;
              height: 12px;
              margin-right: 14px;
            }
          }

          &:hover {
            cursor: pointer;
          }
        }

        label {
          z-index: 1;
          bottom: -11px;
          left: 9px;
          background-color: white;
          color: color(grey, dark);
          font-weight: normal;
          position: relative;
          padding: 5px;
          width: fit-content;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .filter-container,
    .view-icons-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .view-icons-container {
      margin-top: 25px;
      column-gap: 16px;
      display: flex;

      .Button {
        button {
          background-color: color(light, secondaryBg);
          border-radius: 10px;
          border: 1px solid color(grey, border);
          padding: 0;
          min-width: 45px;
          min-height: 45px;
          transition: all 0.2s;

          &.active {
            border: 1px solid color(grey, dark);

            i {
              color: color(grey, dark);
            }
          }

          .icon {
            margin-right: 0;
          }

          i {
            color: color(grey, border);
            transition: all 0.2s;
          }

          &:hover {
            background-color: white;
            cursor: pointer;
          }
        }
      }
    }
  }

  .content-container {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;

    .grid-view {
      display: flex;
      flex-wrap: wrap;
      padding-left: 15px;
      margin-top: 16px;

      .MyPagesItemGrid-card-group_request,
      .MyPagesItemGrid-card,
      .MyPagesItemGrid-card-hub,
      .MyPagesItemGrid-card-event,
      .MyPagesItemGrid-card-group,
      .MyPagesItemGrid-card-team,
      .MyPagesItemGrid-card-individual {
        width: 350px;
        height: 270px;
        margin: 16px 16px 16px 0;
        background-color: white;
        border-radius: 10px;
        position: relative;
        flex-direction: column;
        box-shadow: $box-shadow-darker;

        &:hover {
          box-shadow: $box-shadow-darkest;
          transition: all 100ms ease-in-out;
          cursor: pointer;
        }

        a {
          text-decoration: none;
          color: #012939;
        }

        .card-top {
          border-radius: 10px 10px 0px 0px;
          background-color: color(purple, light);
          background-size: cover;
          background-repeat: no-repeat;
          height: 150px;

          .card-image {
            position: relative;
            min-width: 122px;
            max-width: 122px;

            .Portrait {
              position: absolute;
              margin-top: 16px;
              margin-left: 16px;
            }
          }
        }

        .card-bottom {
          .card-info-wrap {
            margin-top: 20px;
            margin-left: 24px;
            margin-right: 16px;
            cursor: default;

            .name {
              font-weight: 700;
              font-family: $raleway;
              word-wrap: break-word;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              margin-right: 30px;
              overflow: hidden;
            }
            .created-date {
              margin-bottom: 14px;
              font-size: 12px;
              color: #a9aaac;
              font-weight: 600;
              cursor: default;
            }

            .state-label {
              position: relative;
              border-radius: 8px;
              margin-top: 15px;
              padding: 6px 12px;
              font-weight: 700;
              text-align: center;
              font-size: 14px;
              cursor: default;
              width: fit-content;

              &.active,
              &.running {
                background-color: #eef9f7;
                color: #57c0ac;
              }

              &.published {
                background-color: #eaeaf8;
                color: #6565d3;
              }

              &.draft,
              &.pending {
                background-color: #fef8ed;
                color: #f1b94d;
              }

              &.undefined {
                display: none;
              }
              //any unknown states

              background-color: color(light, primaryBg);
              color: color(grey, base);
            }
          }

          .card-menu-icon {
            position: absolute;
            cursor: pointer;
            font-size: 30px;
            right: 25px;
            border-radius: 50%;
          }

          i {
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .card-menu {
            position: absolute;
            background-color: white;
            max-width: 140px;
            top: 200px;
            right: 20px;
            margin: 0px;
            padding: 0px;
            list-style-type: none;
            border-radius: 10px;
            box-shadow: 0px 3px 6px #7c7c7c41;
            overflow: hidden;
            max-height: 0;

            &.show {
              max-height: 350px;
              z-index: 1;
            }

            .delete {
              color: red;
            }

            li {
              display: flex;
              align-items: center;
              padding: 15px 20px;
              cursor: pointer;
              font-size: 15px;
              color: color(light, fontColor);
              font-weight: 500;
              font-family: $lato;

              &:hover {
                background-color: whitesmoke;
              }

              &:first-child {
                margin-top: 0px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
              }

              &:last-child {
                margin-bottom: 0px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
              }
            }
          }

          .page-type {
            position: relative;
            background-color: color(light, primaryBg);
            color: color(grey, base);
            width: fit-content;
            border-radius: 8px;
            margin-top: 15px;
            margin-left: 6px;
            padding: 6px 12px;
            font-weight: 700;
            flex-direction: row;
            text-align: center;
            font-size: 14px;
            cursor: default;
          }
        }
      }

      .MyPagesItemGrid-card-group_request {
        opacity: 0.6;

        &:hover {
          cursor: default;
        }
      }

      .MyPagesItemGrid-card-group_request,
      .MyPagesItemGrid-card-group {
        .page-type {
          display: none;
        }
      }

      .MyPagesItemGrid-card-event,
      .MyPagesItemGrid-card-individual,
      .MyPagesItemGrid-card-team {
        height: 310px;

        .card-top {
          height: 180px;
        }
        .card-bottom {
          margin-top: 26px;
        }
      }

      .MyPagesItemGrid-card-volunteer,
      .MyPagesItemGrid-card-paid {
        display: flex;
        height: 350px;
        width: 320px;
        margin: 16px 16px 16px 0;
        background-color: color(light, secondaryBg);
        border-radius: 10px;
        position: relative;
        flex-direction: column;
        box-shadow: $box-shadow-darker;

        &:hover {
          box-shadow: $box-shadow-darkest;
          transition: all 100ms ease-in-out;
          cursor: pointer;
        }

        a {
          text-decoration: none;
          color: #012939;
        }

        .card-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 32px;
          height: 40px;
          background-size: cover;
          background-repeat: no-repeat;

          .card-image {
            height: 40px;
          }

          .Portrait {
            position: absolute;
            margin-left: 32px;
          }

          .card-menu-icon {
            position: absolute;
            cursor: pointer;
            font-size: 30px;
            right: 32px;
            border-radius: 50%;
          }

          i {
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .card-menu {
            position: absolute;
            background-color: white;
            max-width: 140px;
            right: 50px;
            margin: 0px;
            padding: 0px;
            list-style-type: none;
            border-radius: 10px;
            box-shadow: 0px 3px 6px #7c7c7c41;
            overflow: hidden;
            max-height: 0;

            &.show {
              max-height: 380px;
              z-index: 1;
            }

            .delete {
              color: red;
            }

            li {
              display: flex;
              align-items: center;
              padding: 15px 20px;
              cursor: pointer;
              font-size: 15px;
              color: color(light, fontColor);
              font-weight: 500;
              font-family: $lato;

              &:hover {
                background-color: whitesmoke;
              }

              &:first-child {
                margin-top: 0px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
              }

              &:last-child {
                margin-bottom: 0px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
              }
            }
          }
        }
        .card-info-wrap {
          margin-left: 32px;
          margin-right: 32px;

          i {
            margin-right: 5px;
          }

          .name {
            margin-top: 10px;
            font-weight: 700;
            font-family: $raleway;
            word-wrap: break-word;
            overflow-wrap: break-word;
            overflow: hidden;
            font-size: 20px;
            line-height: 24px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            min-height: 48px;
          }

          .location {
            margin-top: 10px;
          }

          .created-date,
          .location,
          .cause-name {
            font-size: 12px;
            margin-top: 8px;
            margin-bottom: 6px;
            color: #a9aaac;
            font-weight: 700;
            line-height: 14px;
          }
        }

        .card-bottom {
          display: flex;
          flex-direction: column;
          position: relative;
          flex: 1;
          padding: 16px 32px 0 32px;

          .card-description {
            margin-bottom: 16px;
            flex: 1;
            max-height: 61px;
            overflow: hidden;

            .ql-editor {
              padding: 0;
            }

            p {
              cursor: pointer;
              font-family: $lato;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              word-wrap: break-word;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              color: #6f7072;
            }
          }
          .label-wrap {
            margin-top: 10px;

            .state-label {
              border-radius: 8px;
              margin-top: 15px;
              padding: 6px 12px;
              font-weight: 700;
              text-align: center;
              font-size: 14px;
              width: fit-content;

              &.active,
              &.running {
                background-color: #eef9f7;
                color: #57c0ac;
              }

              &.published {
                background-color: #eaeaf8;
                color: #6565d3;
              }

              &.draft,
              &.pending {
                background-color: #fef8ed;
                color: #f1b94d;
              }

              &.undefined {
                display: none;
              }
              //any unknown states

              background-color: color(light, primaryBg);
              color: color(grey, base);
            }

            .gig-type {
              background-color: color(light, primaryBg);
              color: color(grey, base);
              width: fit-content;
              border-radius: 8px;
              margin-top: 15px;
              margin-left: 6px;
              padding: 6px 12px;
              font-weight: 700;
              flex-direction: row;
              text-align: center;
              font-size: 14px;
            }
          }
        }
      }

      .MyInvitesGrid-card,
      .MyPagesFollowedGrid-card {
        height: 260px;
        width: 320px;
        margin: 16px 16px 16px 0px;
        background-color: white;
        border-radius: 10px;
        position: relative;
        flex-direction: column;
        box-shadow: $box-shadow-darker;

        &:hover {
          box-shadow: $box-shadow-darkest;
          transition: all 100ms ease-in-out;
        }

        a {
          text-decoration: none;
          color: inherit;
        }

        .card-top {
          border-radius: 10px 10px 0px 0px;
          background-color: color(purple, light);
          background-size: cover;
          background-repeat: no-repeat;
          height: 160px;

          .card-image {
            position: relative;
            min-width: 122px;
            max-width: 122px;

            .Portrait {
              position: absolute;
              margin-top: 16px;
              margin-left: 16px;
            }
          }

          &:hover {
            cursor: pointer;
          }
        }

        .card-bottom {
          height: 100px;

          .card-info-wrap {
            margin-top: 20px;
            margin-left: 24px;
            margin-right: 16px;

            .name {
              font-weight: 700;
              font-family: $raleway;
              word-wrap: break-word;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }

          .unfollow-btn {
            margin-left: 20px;
            margin-top: 10px;
            background-color: unset;
            border: none;
            color: color(grey, dark);
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $lato;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            padding: 10px 14px;
            cursor: pointer;
            transition: all 0.2s;
            border-radius: 22px;
            width: 143px;
            border: 2px solid #bdbebf;

            &:hover {
              color: color(plum, dark);
              background-color: color(grey, pale);
            }

            i {
              font-size: 14px;
              margin-right: 15px;
              transform: rotate(-90deg);
            }
          }
        }
      }

      .MyInvitesGrid-card {
        height: auto;
        .invite-actions {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .Button {
            width: 45%;
            button {
              width: 100%;
            }
            &.button-alt {
              button {
                background: transparent;
                border: 1px solid #5e51ab;
                color: #5e51ab;
              }
            }
          }
        }
        .name {
          font-size: 18px;
          font-weight: 800;
        }
        .card-stats {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 12px;
        }
      }
    }
    .list-view {
      display: flex;
      flex-wrap: nowrap;
      padding-left: 15px;
      margin-top: 16px;
      flex-direction: column;

      .MyPagesItemList-card,
      .MyPagesItemList-hub,
      .MyPagesItemList-card-group,
      .MyPagesItemList-card-event,
      .MyPagesItemList-card-team,
      .MyPagesItemList-card-individual,
      .MyPagesItemList-card-volunteer {
        margin: 16px 16px 16px 0;
        background-color: white;
        border-radius: 10px;
        position: relative;
        flex-direction: row;
        box-shadow: $box-shadow-darker;
        display: flex;
        align-items: stretch;

        &:hover {
          box-shadow: $box-shadow-darkest;
          transition: all 100ms ease-in-out;
          cursor: pointer;
        }

        a {
          text-decoration: none;
          color: #012939;
        }

        .card-left {
          border-radius: 10px 0px 0px 10px;
          background-color: color(purple, light);

          .card-image {
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 10px 0px 0px 10px;
            position: relative;
            min-width: 320px;
            max-width: 320px;
            height: 180px;

            .Portrait {
              position: absolute;
              margin-top: 16px;
              margin-left: 16px;
            }
          }
        }

        .card-right {
          display: flex;
          flex: 1;
          flex-flow: column nowrap;

          .card-right-top {
            display: flex;
            flex-direction: column;
            padding: 20px;
            flex: 1;
            .name {
              font-weight: 700;
              font-family: $raleway;
              word-wrap: break-word;
              overflow: hidden;
            }

            .card-menu-icon {
              font-size: 30px;
              border-radius: 50%;
            }

            i {
              height: 30px;
              width: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .card-menu {
              background-color: white;
              max-width: 140px;
              margin: 0px;
              padding: 0px;
              list-style-type: none;
              border-radius: 10px;
              box-shadow: 0px 3px 6px #7c7c7c41;
              overflow: hidden;
              max-height: 0;

              &.show {
                max-height: 350px;
                z-index: 1;
              }

              .delete {
                color: red;
              }

              li {
                display: flex;
                align-items: center;
                padding: 15px 20px;
                font-size: 15px;
                color: color(light, fontColor);
                font-weight: 500;
                font-family: $lato;

                &:hover {
                  background-color: whitesmoke;
                }

                &:first-child {
                  margin-top: 0;
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
                }

                &:last-child {
                  margin-bottom: 0;
                  border-bottom-left-radius: 10px;
                  border-bottom-right-radius: 10px;
                }
              }
            }

            .created-date {
              margin-top: 8px;
              font-size: 12px;
              color: #a9aaac;
              font-weight: 600;
              line-height: 18px;
            }
          }
          .card-right-bottom {
            .state-label {
              position: relative;
              border-radius: 8px;
              margin-top: 15px;
              padding: 6px 12px;
              font-weight: 700;
              text-align: center;
              font-size: 14px;
              width: fit-content;

              &.active,
              &.running {
                background-color: #eef9f7;
                color: #57c0ac;
              }

              &.published {
                background-color: #eaeaf8;
                color: #6565d3;
              }

              &.draft,
              &.pending {
                background-color: #fef8ed;
                color: #f1b94d;
              }
              &.undefined {
                display: none;
              }
              //any unknown states

              background-color: color(light, primaryBg);
              color: color(grey, base);
            }
            .page-type {
              position: relative;
              background-color: color(light, primaryBg);
              color: color(grey, base);
              width: fit-content;
              border-radius: 8px;
              margin-top: 15px;
              margin-left: 6px;
              padding: 6px 12px;
              font-weight: 700;
              flex-direction: row;
              text-align: center;
              font-size: 14px;
            }
          }
        }

        .card-inner {
          .Portrait {
            position: absolute;
            margin-top: 16px;
            margin-left: 16px;
          }

          .name {
            margin-top: 16px;
            margin-left: 75px;
            font-weight: 700;
            font-family: $raleway;
            word-wrap: break-word;
            overflow: hidden;
          }
          .cause-name {
            font-size: 14px;
            font-family: $lato;
            font-weight: 600;
            color: #a9aaac;
            line-height: 18px;
            margin-left: 75px;
            margin-top: 6px;
          }
          .created-date {
            position: relative;
            width: 84px;
            height: 36px;
            font-size: 12px;
            margin-bottom: 8px;
            color: #a9aaac;
            font-weight: 700;
            line-height: 14px;
            margin-left: 355px;
          }
          .location {
            position: relative;
            font-size: 12px;
            margin-bottom: 8px;
            color: #a9aaac;
            font-weight: 700;
            line-height: 14px;
          }
          .state-label {
            position: relative;
            border-radius: 8px;
            margin-top: 15px;
            padding: 6px 12px;
            font-weight: 700;
            text-align: center;
            font-size: 14px;
            width: fit-content;

            &.active,
            &.running {
              background-color: #eef9f7;
              color: #57c0ac;
            }

            &.published {
              background-color: #eaeaf8;
              color: #6565d3;
            }

            &.draft,
            &.pending {
              background-color: #fef8ed;
              color: #f1b94d;
            }
            &.undefined {
              display: none;
            }
            //any unknown states

            background-color: color(light, primaryBg);
            color: color(grey, base);
          }
          .gig-type {
            position: relative;
            background-color: color(light, primaryBg);
            color: color(grey, base);
            width: fit-content;
            border-radius: 8px;
            margin-top: 15px;
            margin-left: 6px;
            padding: 6px 12px;
            font-weight: 700;
            flex-direction: row;
            text-align: center;
            font-size: 14px;
          }
          .card-menu-icon {
            position: absolute;
            font-size: 30px;
            border-radius: 50%;
          }

          i {
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .card-menu {
            position: absolute;
            background-color: white;
            max-width: 140px;
            margin: 0px;
            padding: 0px;
            list-style-type: none;
            border-radius: 10px;
            box-shadow: 0px 3px 6px #7c7c7c41;
            overflow: hidden;
            max-height: 0;

            &.show {
              top: 10px;
              max-height: 350px;
              z-index: 1;
            }

            .delete {
              color: red;
            }

            li {
              display: flex;
              align-items: center;
              padding: 15px 20px;
              font-size: 15px;
              color: color(light, fontColor);
              font-weight: 500;
              font-family: $lato;

              &:hover {
                background-color: whitesmoke;
              }

              &:first-child {
                margin-top: 0px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
              }
              &:last-child {
                margin-bottom: 0px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
              }
            }
          }
        }
      }

      .MyPagesItemList-card-group {
        .page-type {
          display: none;
        }
      }

      .MyPagesItemList-card-volunteer {
        height: 85px;
      }
    }
  }
}
@media (max-width: 500px) {
  .MyPages {
    .filter-container {
      flex-direction: column;
    }
  }
}
