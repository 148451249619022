@import '../../../styles/variables.scss';

.ContactPledges {
  .progress-bar-wrap {
    margin: 20px 0px 0px;
    text-align: center;

    span {
      font-size: 12px;
      color: color(grey, base);
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}
