@import '../../../styles/variables.scss';

.ActivityLog {
  height: 100%;
  max-height: 700px;
  width: 100%;
  overflow-y: auto;

  .Loader {
    margin-top: 50px;
    display: flex;
    flex: 1;
    justify-content: center;

    i {
      font-size: 32px;
    }
  }

  .logs {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    margin-bottom: 100px;
    padding: 10px;

    .log {
      border-radius: 8px;
      background: #fff;
      width: auto;
      margin: 10px;
      display: flex;
      padding: 15px 0;
      max-width: 700px;

      .actions,
      .portrait {
        flex: 1 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }

      &:hover {
        box-shadow: $box-shadow;
      }
      .QuillTextEditor {
        .ql-editor {
          padding: 0;
          border: none;
        }
      }
      .created-by {
        flex: 5 1;
        display: flex;
        flex-direction: column;
        max-width: 500px;

        .name {
          margin: 6px 0;
          font-weight: bold;
          color: color(dark, SecondaryBg);
        }

        .time {
          padding: 5px 0;
          font-size: 12px;
          color: color(grey, light);
        }
      }
      .actions {
        display: flex;
        max-width: 75px;
        position: relative;
        cursor: pointer;

        i {
          font-size: 24px;

          &:hover {
            cursor: pointer;
          }
        }

        .ContextMenu {
          display: none;
          position: absolute;
          top: 30px;
          left: -80px;
          box-shadow: $box-shadow;
          border-radius: 20px;

          i {
            font-size: 16px;
            padding-right: 8px;
          }

          &:hover {
            display: block;
          }
        }

        i.fa-ellipsis-h-alt {
          font-size: 24px;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            cursor: pointer;
            color: color(purple, dark);
          }
        }

        i.fa-ellipsis-h-alt:hover + .ContextMenu {
          display: block;
          cursor: pointer;
        }
      }
    }
  }

  #AL {
    max-height: 400px;
    overflow-y: auto;
    position: absolute;
    bottom: 0px;
    width: 100%;
    box-shadow: $box-shadow;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    &.hide-toolbar {
      .QuillTextEditor {
        .ql-toolbar {
          display: none;
        }
      }

      .Button {
        display: none;
      }
    }

    .QuillTextEditor {
      background-color: #fff;
      max-height: 100%;
      width: 100%;

      .ql-container {
        border: none;

        .ql-tooltip.ql-flip {
          position: relative;
        }
      }
      .ql-toolbar {
        min-height: 50px;
        background-color: #fff;
        position: sticky;
        top: 0;
        z-index: 2;
      }

      .ql-editor {
        padding: 20px 15px;
        min-height: 65px;
      }
    }
    .cancel-edit {
      cursor: pointer;
      font-size: 12px;
      color: color(grey, base);
      position: absolute;
      top: 18px;
      right: 125px;
      z-index: 3;
    }
    .Button {
      min-height: 25px;
      min-width: 25px;
      position: absolute;
      top: 9px;
      right: 12px;
      z-index: 3;
      margin-right: 5px;

      button {
        min-width: 25px;
        min-height: 25px;
        border-radius: 8px;
        padding: 5px 10px;
        font-size: 14px;
      }
    }
  }
}
