body,
html,
#root {
  min-height: 100vh;
  padding: 0px;
  margin: 0px;
  font-family: 'Lato', sans-serif;
}

.Gigit {
  flex: 1;
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  padding-top: 80px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex: 1;
}

main.no-padding-top {
  padding-top: 0;
}

.embedded main {
  padding-top: 0px;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.react-joyride__tooltip,
.react-joyride__tooltip button {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}

.react-joyride__tooltip button[data-action='close'] {
  display: none;
}

.react-joyride__tooltip button:focus {
  outline: 0px;
}

.curve {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 440px;
  -webkit-mask: url('assets/curve.svg') no-repeat bottom;
  mask: url('assets/curve.svg') no-repeat bottom;
  mask-size: 100% auto;
  transform: translateY(1px);
}

.release-notification {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: bold;
  font-size: 18px;
  bottom: 20px;
  left: 20px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #7c7c7c41;
  border-radius: 20px;
  padding: 20px;
  min-height: 150px;
  min-width: 300px;
}

.release-subtext {
  bottom: 120px;
  font-size: 14px;
  inline-size: 285px;
  overflow: hidden;
}

.release-link {
  bottom: 90px;
  left: 40px;
  font-size: 14px;
  color: gray;
  text-decoration: underline;
}

.release-actions {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}

@media (max-width: 530px) {
  main {
    padding-top: 0px;
  }
}

@media (max-width: 500px) {
  main {
    padding-bottom: 71px;
  }

  .release-notification {
    bottom: 80px;
  }
}
