@import '../../../styles/variables.scss';

.AuctionEditOrderModal.Modal {
  .edit-order-content {
    margin: 20px;
    overflow-y: auto;

    .details {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 20px;
        align-self: center;
        margin-bottom: 10px;
      }

      .current-bid {
        align-self: center;
        color: #5e51ab;
        margin-bottom: 10px;
      }

      .input-row {
        margin-bottom: 20px;
      }

      .shipping-price-type-message {
        font-style: italic;
        margin-top: 10px;
      }
    }

    .summary {
      min-width: 320px;

      .Loader {
        display: flex;
        justify-content: center;
        flex: 1;
        padding: 20px;

        i {
          font-size: 24px;
        }
      }

      .summary-inner {
        border-radius: $border-radius;
        background-color: color(light, secondaryBg);

        .title {
          padding: 30px 40px;
          font-size: 25px;
          font-weight: 700;
          text-align: center;
          border-bottom: 1px solid color(grey, light);
          margin: 0px 30px;
        }

        .sub-price {
          padding: 30px 40px 0px;
          display: flex;
          flex: 1;

          span {
            flex: 1;

            &:first-child {
              font-weight: 700;
            }

            &:last-child {
              text-align: right;
              color: color(grey);
            }
          }
        }

        .total {
          background-color: color(grey);
          margin: 20px 0px 0px;
          padding: 20px 40px;
          color: color(dark, fontColor);
          display: flex;
          flex: 1;
          font-size: 21px;
          font-weight: 700;

          span {
            flex: 1;

            &:last-child {
              text-align: right;
            }
          }
        }

        .actions {
          padding: 40px 40px 20px;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          display: flex;
          flex-direction: column-reverse;
          background-color: color(light, secondaryBg);

          .save-button.Button {
            width: 100%;

            button {
              width: 100%;

              &:disabled {
                background-color: color(gray, light);
              }
            }
          }
        }
      }
    }
  }
}
