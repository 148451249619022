@import '../../styles/variables.scss';

.dark {
  .Footer {
    background-color: color(dark, secondaryBg);

    a {
      color: color(dark, fontColor);
    }

    .inner {
      ul {
        &.links {
          li {
            border-left: 1px solid color(dark, primaryBg);
          }
        }
      }
    }
  }
}

.Gigit {
  &.embedded {
    .mobile-footer-wrapper,
    .Footer {
      display: none;
    }
  }
}

.Footer {
  display: flex;
  width: 100%;
  background-color: color(plum, darker);
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1);
  z-index: 3;
  overflow: hidden;
  font-family: $lato;

  &.hidden {
    display: none;
  }

  .inner {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    padding: 100px 100px 0 100px;

    .gigit-logo {
      display: flex;
      align-items: center;
    }

    .documents-container-mobile {
      display: none;
    }

    .language-menu {
      height: 40px;
      position: relative;
      display: flex;
      align-items: center;

      .language-selected {
        display: flex;
        align-items: center;
        color: white;

        i {
          display: block;
          margin-left: 12px;
          font-size: 22px;
          font-weight: 500;
          transition:
            all 0.2s linear,
            color 0s;
          transform: rotate(-90deg);

          &.show {
            transform: rotate(90deg);
          }
        }

        &.show {
          color: color(purple, dark);
        }

        &:hover {
          color: color(purple, dark);
        }
      }

      .ContextMenu {
        position: absolute;
        top: -200px;
        right: 10px;
        padding: 40px;
        min-width: 100px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        background: #fff;

        &:after {
          width: 80px;
          height: 80px;
          right: auto;
          left: 132px;
          top: 180px;
        }

        .ContextMenu-ul {
          display: grid;
          gap: 10px;

          li {
            min-height: 0;
            padding: 5px 25px;
            max-height: 20px;
            border-bottom: 0;
            line-height: 20px;
            margin-right: 0;

            a,
            span {
              font-size: 15px;
              font-weight: 700;
              color: color(purple, darker) !important;

              span {
                color: color(purple, darker) !important;
              }

              &:hover {
                color: color(purple, dark) !important;
              }
            }
          }
        }

        &::before {
          display: none;
        }
      }
    }

    .row {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      border-bottom: solid 2px #3c3856;
      padding: 50px 0 50px 0;

      &.no-separator {
        border-bottom: none;
      }

      &.top-footer {
        padding: 0 0 50px 0;
      }

      &.bottom-footer {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        border: none;
        width: 100%;

        .links-list {
          li {
            margin-right: 50px;
          }
        }

        .socials {
          display: flex;
          flex-flow: row nowrap;
          margin-left: auto;
          gap: 15px;

          .icon {
            display: flex;
            height: 24px;
            width: 25px;
            background-color: white;

            &:hover {
              cursor: pointer;
              background-color: color(purple, dark);
            }

            &.facebook {
              width: 24px;
              mask-image: url(../../assets/facebook_icon-dark-bg.svg);
            }

            &.twitter {
              mask-image: url(../../assets/twitter_icon-dark-bg.svg);
            }

            &.instagram {
              mask-image: url(../../assets/instagram_icon-dark-bg.svg);
            }

            &.linkedin {
              mask-image: url(../../assets/linkedin_icon-dark-bg.svg);
            }
          }
        }
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    .links-list {
      display: flex;
      justify-content: space-between;

      &.mobile {
        display: none;
      }

      &.non-mobile {
        align-items: center;
      }

      li {
        font-size: 14px;
        font-weight: 400;
        margin-right: 0;

        a,
        span {
          color: white;
          text-decoration: none;

          &:hover {
            cursor: pointer;
            color: color(purple, base);
          }
        }
      }
    }

    .link-container {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
      color: white;
      margin-right: 15px;

      .title-container {
        display: flex;
        align-items: center;
        margin-bottom: 26px;

        .title {
          color: white;
          font-weight: 700;
        }

        i {
          display: none;
        }
      }

      .links-list {
        flex-flow: column;

        li {
          margin-bottom: 26px;
        }
      }
    }

    .gigit-logo {
      display: block;
      margin-right: 50px;

      img {
        display: block;
        width: 150px;
        height: auto;
      }
    }
  }
}

.mobile-footer-wrapper {
  z-index: 8;
}

.mobile-footer {
  height: $mobile-footer-height;
  width: 100vw;
  position: fixed;
  background: #fff;
  bottom: 0;
  left: 0;
  z-index: 7;
  border-top: 1px solid #00000029;

  ul {
    max-width: 100vw;
    display: flex;
    margin: 10px 0 0 0;
    list-style-type: none;
    padding: 0;
    justify-content: center;

    li {
      display: flex;
      min-width: 70px;
      justify-content: center;
      align-items: center;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        &.i-want-to {
          width: 50px;
          height: 50px;
          background-color: #ededec;
        }

        &.cart {
          position: relative;

          .notification-dot {
            position: absolute;
            top: 0;
            right: -5px;
            width: 12px;
            height: 12px;
            border: solid 2px white;
            border-radius: 50%;
            background-color: color(red, soft);
            z-index: 1;
          }
        }

        &.active {
          color: color(blue, dark);
          border: 2px solid color(purple, dark);
        }

        i,
        img {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #52646c;
          font-size: 24px;
          width: 20px;
          height: 20px;
          padding: 7px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .Footer {
    .inner {
      padding: 50px 30px 0 30px;
    }
  }
}

@media (max-width: 1010px) {
  .Footer {
    justify-content: center;
    align-items: center;
    padding: 40px 0 80px 0;

    .inner {
      flex-direction: column;

      .row {
        flex-flow: column;
        padding: 30px 0 20px;

        &.top-footer {
          padding: 0;
        }
      }

      .documents-container-mobile {
        display: flex;
        flex-flow: row nowrap;
        margin-top: 30px;
        align-items: center;

        li {
          padding-right: 25px;
        }
        .language-menu {
          .language-selected {
            margin-left: auto;
          }
        }
      }

      .gigit-logo {
        margin: 0;

        img {
          width: 120px;
        }
      }

      .links-list {
        justify-content: unset;

        &.dropdown-section {
          max-height: 0;
          transition: max-height 0.3s ease-in-out;
          overflow: hidden;
          margin-left: 5px;
          margin-bottom: 20px;

          .link {
            font-weight: bolder;
          }

          &.active {
            max-height: 300px;
          }
        }

        &.mobile {
          display: flex;
          width: 100%;
          align-items: center;
        }

        &.non-mobile {
          display: none;
        }
      }

      .link-container {
        width: 100%;
        margin-right: 0;

        .title-container {
          display: flex;
          margin-bottom: 10px;

          i {
            display: block;
            margin-left: auto;
            font-size: 25px;
            font-weight: 500;
            transition: all 0.2s linear;

            &.active {
              transform: rotate(90deg);
            }
          }
        }
      }

      ul.links {
        padding: 20px 80px;
        justify-content: space-around;
        flex-wrap: wrap;

        li {
          border-left: 0;
          padding: 10px 0;
          text-align: center;

          a {
            white-space: nowrap;
          }
        }
      }

      ul.social {
        justify-content: center;
        font-size: 28px;

        li {
          padding-left: 16px;

          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .Footer {
    .inner {
      .link-container {
        padding: 0 8px;
        width: unset;
      }

      .documents-container-mobile {
        padding: 0 6px;
      }

      .row.bottom-footer {
        width: unset;
        padding: 30px 6px 20px 6px;

        .socials {
          column-gap: 10px;
        }
      }
    }
  }

  .mobile-footer {
    ul {
      li {
        min-width: 56px;
      }
    }
  }
}
