@import '../../styles/variables.scss';

.DonorStatusDashboard {
  .section-inner {
    display: flex;
    flex-flow: column;

    .row {
      display: flex;
      flex-flow: row nowrap;

      .date-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        & > div,
        span {
          margin-right: 5px;
        }
        .startYear {
          width: 100px;
        }

        .endYear {
          width: 100px;
        }
      }

      .actions {
        margin-left: auto;
        margin-right: 10px;
        font-size: 20px;
        color: color(grey, base);

        &:hover {
          cursor: pointer;
        }
      }

      &:last-child {
        margin-right: 0px;
      }

      &.chart-container {
        margin-top: 10px;
      }

      .panel {
        display: flex;
        flex-flow: column;
        flex: 1;
        border: 1px solid #ccd5d8;
        border-radius: 7px;
        background-color: color(cream, lightest);
        padding: 23px 0;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        .chart-title {
          display: flex;
          flex-flow: row nowrap;
          color: color(light, fontColor);
          font-weight: bold;
          margin-bottom: 23px;
          & > .hover-action {
            margin-left: 10px;
          }

          .total-lapsed {
            margin-right: 5px;
            color: #9d0c4a;
          }

          .total-renewed {
            margin-right: 5px;
            color: #0b7383;
          }
        }
      }

      .chart-container {
        display: flex;
        flex-flow: row nowrap;
        flex: 1;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .doughnut-chart-container {
        position: relative;
        width: 100%;

        .doughnut-center-info {
          position: absolute;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .title {
            letter-spacing: 1.2;
            font-size: 1vw;
            font-weight: 700;
          }

          .info {
            color: #012939;
            font-size: 1vw;
            font-weight: 700;
          }
        }
      }
    }
  }

  .ContactDetailsModal {
    justify-content: flex-start;
    align-items: stretch;

    .content {
      position: relative;
      flex: 1;
      max-width: 100%;
      max-height: 100%;
      margin: 40px 30px;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;

      .modal-close-circle {
        right: 15px;
        top: 15px;
      }
    }

    .ModalHeader {
      display: none;
    }
  }

  .DonorListPopup {
    width: 350px;
    height: 300px;
    display: flex;
    flex-flow: column;

    .header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      height: 40px;
      color: white;
      padding: 0 10px;
      border-radius: 10px 10px 0 0;
      font-size: 18px;

      .title {
        margin-right: auto;
      }

      .action {
        :hover {
          cursor: pointer;
        }
      }
    }

    .donor-list-container {
      display: flex;
      flex-flow: column;
      background-color: white;
      flex: 1;
      padding: 0 10px;
      overflow: auto;

      .donor-row {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: 45px;
        padding: 10px 0px;
        font-size: 14px;
        color: #484b4d;

        .Portrait {
          margin-right: 10px;
        }

        .donor-name {
          &:hover {
            cursor: pointer;
          }
        }

        .donor-contribution {
          margin-left: auto;
          margin-right: 50px;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .DonorStatusDashboard {
    .section-inner {
      .row {
        flex-flow: row wrap;
      }
    }
  }
}
