@import '../../../../styles/variables.scss';

.HubStoreSimplifiedView {
  .section-content {
    .Carousel {
      .ListCard {
        min-width: unset;

        .desc {
          height: 40px;
        }

        .cover-img {
          height: 280px;
          max-height: 280px;
        }
      }
    }
  }
}
