@import '../../../styles/variables.scss';

.ContactCard {
  .user-card {
    display: flex;
    flex-direction: column;
    background-color: #f5f6f7;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;

    .images {
      position: relative;

      .CoverImage {
        min-height: 180px;
        border-radius: 0px;
      }

      .Portrait {
        position: absolute;
        bottom: -55px;
        left: 50%;
        margin-left: -50px;
      }
    }

    .display-name {
      text-align: center;
      padding-top: 70px;
      font-size: 24px;
      font-weight: 700;
    }

    .status {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      min-width: 70px;
      min-height: 30px;

      span {
        background-color: #2abd7e;
        color: #fff;
        padding: 6px 18px;
        border-radius: 20px;
        font-weight: 400;
        letter-spacing: 0.8px;
        text-align: center;

        &.recovered,
        &.renewed,
        &.new {
          background-color: color(green, neon);
        }

        &.lapsing {
          background-color: color(brand, yellow);
        }

        &.lapsed {
          background-color: color(brand, red);
        }
      }
    }

    .user-data {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 20px 20px;

      .user-location {
        position: relative;
        min-height: 100px;
        padding: 2px;
      }

      .user-location,
      .user-details,
      .user-emergency,
      .user-capacities {
        flex: 1;
        background-color: #fff;
        border-radius: 6px;
        font-size: 14px;

        .data-title {
          font-weight: 700;
          margin-bottom: 10px;
        }

        .data-row {
          margin-top: 5px;
        }
      }

      .user-details,
      .user-emergency,
      .user-capacities {
        padding: 15px;
      }

      .user-location,
      .user-details,
      .user-emergency {
        margin-bottom: 10px;
      }

      .user-location {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .user-capacities {
        span {
          display: inline-block;
          margin: 4px 8px 4px 0px;
          text-transform: capitalize;
          padding: 6px 10px;
          border-radius: 24px;

          @include userLabelColor;
        }
      }
    }
  }
}
