@import '../../../styles/variables.scss';
.DonorSummary {
  .cd-stat-row {
    .cd-stat-box {
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
  label {
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: 700;
    padding-left: 5px;
  }

  .donor-chart {
    padding: 25px;
    margin: 15px 0;
    border-radius: 8px;
    background-color: #f5f6f7;

    span {
      margin-top: 25px;
      margin-left: 60px;
      font-size: 12px;
      color: color(grey, base);
    }

    .metric-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 35px;
      margin: 20px 0;

      div {
        i {
          margin-left: 5px;
        }
      }

      i:hover {
        cursor: pointer;
        color: color(purple, dark);
      }
    }
  }
}
