.HoursTable {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  .header {
    padding-bottom: 20px;
    border-bottom: 1px solid #898989;
    color: #3a3a3a;
    font-weight: 700;
  }

  .hour-item {
    display: flex;
    border-bottom: 1px solid #e9e9e8;
    align-items: center;
    padding: 20px 0;

    &.end {
      display: flex;
      justify-content: end;
    }

    .Button {
      button {
        background: #fff;
        color: #000;
        border: 1px solid #424243;

        &:hover {
          background: #424243;
          color: #fff;
        }
      }
    }
  }
}
