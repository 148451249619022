.MatchingProgramSummaryModal {
  .content {
    max-width: 946px;
    width: 100%;
    height: 762px;

    .MatchingProgramSummaryModal-Container {
      display: flex;
      flex-flow: column;
      padding: 40px;

      .modal-content {
        display: flex;
        flex-flow: column nowrap;
        gap: 20px;

        .name-portrait {
          display: flex;
          align-items: center;

          span {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
