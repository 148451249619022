@import '../../../styles/variables.scss';

.Donations {
  padding: 0px 20px;

  .ComponentPlaceholder {
    margin: 0px;
  }

  h3 {
    margin: 0px 0px 20px 0px;
  }

  .TextField {
    margin-bottom: 10px;

    input {
      font-size: 22px;
    }
  }

  .donation {
    display: flex;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid color(grey, lighter);

    .Portrait {
      .inner {
        background-color: color(grey, light);
        border: 2px solid color(grey, light);

        .profile {
          background-color: color(dark, fontColor);
          opacity: 1;
        }
      }
    }

    .donation-details {
      padding: 8px 0px 0px 20px;

      .name {
        font-size: 18px;

        span {
          font-weight: 500;
        }
      }

      .parents {
        font-size: 15px;
        display: flex;
        padding: 10px 0px 0px;

        .parent {
          padding-right: 20px;

          a {
            text-decoration: none;
            color: color(grey, font);

            i {
              padding-right: 4px;
            }

            &:hover {
              color: color(purple, dark);
            }
          }
        }
      }

      .time {
        font-size: 12px;
        padding: 0px;
        color: color(grey, font);
      }

      .note {
        padding: 10px 0px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .controls {
    display: flex;
    justify-content: space-around;

    .load-more {
      .Button {
        button {
          background-color: color(purple, dark);
          color: color(light, secondaryBg);
          border: 2px solid color(purple, dark);
        }
      }

      &:hover {
        .Button {
          button {
            background-color: color(blue, light);
            color: color(light, secondaryBg);
            border: 2px solid color(blue, light);
          }
        }
      }
    }
    .load-more {
      .Button {
        button {
          background-color: color(light, secondaryBg);
          color: color(purple, dark);
          border: 2px solid color(purple, dark);
        }
      }

      &:hover {
        .Button {
          button {
            background-color: color(purple, dark);
            color: color(light, secondaryBg);
            border: 2px solid color(purple, dark);
          }
        }
      }
    }
  }

  .Loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;

    i {
      font-size: 30px;
    }
  }
}

@media (max-width: 500px) {
  .Donations {
    .donation {
      .parents {
        display: flex;
        flex-direction: column;

        .parent {
          margin: 5px 0;
        }
      }
    }
  }
}
