@import '../../styles/variables.scss';

.ShiftManage {
  flex: 1;
  max-height: 800px;
  overflow: auto;

  .form-inner {
    padding: 30px 25px 0px;

    .DatePicker {
      label {
        transform: translateX(-32px);
      }

      .dp-inner {
        .month {
          max-width: 90px;
          min-width: 90px;

          .Dropdown {
            .input-wrap {
              border-right: 0px;
              border-radius: 0px;
              border-left: 0px;

              select {
                color: color(grey);
                font-weight: 400;
              }

              .fa-angle-down {
                display: none;
              }
            }
          }
        }

        .day,
        .year {
          .TextField {
            margin-left: 0px;

            .input-wrap {
              border-left: 0px;
              border-right: 0px;
              border-radius: 0px;

              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              input[type='number'] {
                -moz-appearance: textfield;
              }

              input {
                color: color(grey);
                font-weight: 400;
              }
            }
          }
        }

        .day {
          max-width: 35px;
        }

        .year {
          max-width: 73px;

          .TextField {
            .input-wrap {
              border-right: 1px solid color(grey, light);
              border-top-right-radius: 7px;
              border-bottom-right-radius: 7px;
            }
          }
        }
      }
    }

    .workers {
      flex: 1;

      .worker {
        display: flex;
        align-items: center;
        padding: 5px 10px;

        .Portrait {
          padding-right: 10px;
        }

        span {
          flex: 1;
          font-size: 14px;
          color: color(grey);
          text-transform: capitalize;
        }

        i {
          color: color(grey);

          &:hover {
            color: color(purple, dark);
            cursor: pointer;
          }
        }
      }
    }

    .reoccurrance {
      padding-bottom: 20px;
    }

    .select-row {
      display: flex;

      .select-label {
        margin-top: 15px;
        font-size: 13px;
        font-weight: 700;
      }

      .select-options {
        .select-option {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .end {
            margin-left: 15px;
            font-size: 13px;
          }

          .select-radio {
            margin: 0px 20px;

            &:hover {
              cursor: pointer;
            }

            span {
              font-size: 13px;
            }

            i {
              margin-right: 10px;

              &:hover {
                cursor: pointer;
              }
            }

            .fas {
              &.fa-circle {
                color: color(purple, dark);
              }
            }

            .fal {
              &.fa-circle {
                color: color(grey);
              }
            }
          }
        }
      }
    }

    .TextField {
      .input-wrap {
        input {
          font-weight: 400;
          color: color(grey);
        }
      }
    }

    .form-row {
      padding-bottom: 20px;

      .Dropdown {
        .input-wrap {
          select {
            color: color(grey);
            font-weight: 400;
          }
        }
      }

      .TextField {
        .input-wrap {
          input {
            color: color(grey);
            font-weight: 400;
          }
        }
      }

      &:last-child {
        padding-bottom: 0px;
      }

      .weekly-days {
        display: flex;
        align-items: center;

        .label {
          font-size: 13px;
          font-weight: 700;
          padding-right: 10px;
          overflow: hidden;
          white-space: nowrap;
        }

        .week-buttons {
          list-style-type: none;
          display: flex;
          margin: 0px;
          padding: 0px;

          li {
            font-size: 12px;
            font-weight: 700;
            background-color: color(grey, lighter);
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;

            &.active {
              background-color: color(purple, dark);
              color: color(dark, fontColor);
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      &.inline {
        display: flex;
        align-items: center;

        .end {
          padding-left: 8px;
          font-size: 13px;
          font-weight: 700;
        }

        .TextField {
          flex-direction: row;
          flex: 0;
          align-items: center;
          max-width: 150px;

          label {
            padding-bottom: 0px;
            flex: 0;
            white-space: nowrap;
            padding-right: 8px;
          }

          .input-wrap {
            flex: 1;
          }
        }
      }
    }

    .date-row,
    .dbl-date-wrap {
      display: flex;

      .fa-calendar {
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        color: color(grey);
        padding-right: 7px;
      }
    }

    .date-row {
      flex-direction: column;

      .DateTimePickerContainer {
        .rdt.custom-dtp {
          width: fit-content;
        }
      }
    }

    .dbl-date-wrap {
      .fa-calendar {
        margin-top: 4px;
      }
    }

    .time-row {
      display: flex;

      .start-time,
      .end-time {
        display: flex;

        &.time-picker {
          flex-direction: column;
        }

        .fa-clock {
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top-left-radius: 7px;
          border-bottom-left-radius: 7px;
        }

        .Dropdown {
          flex: 1;
          margin-top: 19px;
          max-width: 45px;
          min-width: 45px;

          .fa-angle-down {
            display: none;
          }

          &:last-child {
            max-width: 45px;

            .input-wrap {
              border-right: 1px solid color(grey, light);
              border-top-right-radius: 7px;
              border-bottom-right-radius: 7px;
            }
          }

          .input-wrap {
            border-radius: 0px;
            border-left: 0px;
            border-right: 0px;

            select {
              color: color(grey);
              font-weight: 400;
            }
          }
        }

        .TextField {
          max-width: 32px;
          min-width: 32px;
          white-space: nowrap;

          label {
            span {
              transform: translateX(-25px);
            }
          }

          .input-wrap {
            position: relative;
            border-radius: 0px;
            border-right: 0px;
            border-left: 0px;
            overflow: visible;

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            input[type='number'] {
              -moz-appearance: textfield;
            }

            input {
              color: color(grey);
              font-weight: 400;
              width: 100%;
              text-align: center;
              text-indent: 8px;
            }

            &:after {
              right: -2px;
              top: 10px;
              position: absolute;
              content: ':';
              color: color(grey);
            }
          }
        }
      }

      .start-time {
        margin-right: 10px;
      }
    }

    label {
      padding-bottom: 4px;
      color: #5e51ab;
      font-size: 13px;
      font-weight: 700;
    }
  }

  .actions {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: color(light, secondaryBg);
    height: 100px;
  }
}
