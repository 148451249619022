@import '../../../../styles/variables.scss';
.CauseSelection {
  .heading {
    font-family: $raleway;
    font-weight: 700;
    font-size: 16px;
    margin-left: 15px;
  }

  .causes-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (max-width: 680px) {
  .CauseSelection {
    .causes-container {
      .CheckboxCard {
        min-width: 100px;
        padding: 0 15px;
      }
    }
  }
}
