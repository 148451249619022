@import '../../../styles/variables.scss';

.StorePurchasesListItemDetails {
  display: flex;
  flex-direction: column;
  margin: 12px 6px;
  height: 100%;

  .detail-section {
    border-radius: 12px;
    margin-right: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 700;
      padding: 20px 0px;
      border-bottom: 1px solid color(grey, light);
      margin: 0px 20px;
    }

    .phone,
    .email {
      padding: 10px 20px 0px;
      font-size: 14px;
      color: color(grey);
      line-height: 21px;
    }

    .address {
      padding: 10px 20px;
      font-size: 14px;
      color: color(grey);
      line-height: 21px;
    }

    &:last-child {
      margin-right: 0px;
    }

    .detail-items {
      height: 100%;
      overflow: auto;

      @include thin-scroller-y;

      .item {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        background: #fff;
        padding: 20px;
        margin: 15px;
        border-radius: 8px;

        .actions,
        .item-qty,
        .item-price,
        .portrait-container,
        .item-name {
          flex: 2;
          margin: 0 10px;
          div:first-of-type {
            font-size: 14px;
            margin-bottom: 2px;
            color: color(grey, base);

            &:last-of-type {
              font-size: 16px;
            }
          }
        }

        .item-qty {
          text-align: center;
        }

        .actions {
          flex: 1;
          cursor: pointer;

          .ContextMenu {
            z-index: 5;
            display: none;
            position: absolute;
            top: 55px;
            right: 10px;
            box-shadow: $box-shadow;
            border-radius: 20px;

            li {
              cursor: pointer;

              &.default-role {
                display: none;
              }
            }

            i {
              font-size: 16px;
              padding-right: 8px;
            }

            &:hover {
              display: block;
            }
          }

          i.fa-ellipsis-h-alt {
            font-size: 24px;
            height: 40px;
            width: 40px;
            background-color: color(blue, lightest);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            &:hover {
              cursor: pointer;
              color: color(purple, dark);
            }
          }

          i.fa-ellipsis-h-alt:hover + .ContextMenu {
            display: block;
          }
        }
      }
    }
  }
}
