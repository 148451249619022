.share-content.create-post {
  width: 800px;
  max-width: 800px;
  .owner-cover-image {
    overflow: hidden;
    margin-top: 20px;
    border-radius: 10px;
    img {
      width: 100%;
      height: auto;
    }
    .cover-placeholder {
      width: 100%;
      height: 150px;
      background: #f3f1ec;
    }
  }
  .post-description,
  .post-preview {
    width: calc(50% - 30px);
  }
  .post-description {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .TextArea {
      width: 100%;
      flex: 1 1;
      label {
        height: calc(100% - 44px);
        .placeholder {
          margin: 0;
          font-size: 14px;
          line-height: 1;
          font-weight: 700;
          color: #3a3a3a;
          opacity: 1;
          transform: translateY(-22px);
        }
      }
      textarea {
        height: 100%;
        border: 1px solid #e5e5e5;
        border-radius: 10px;
      }
    }
    .Button {
      width: 100%;
      margin-top: 44px;
      button {
        background: #5e51ab;
        width: 100%;
      }
    }
  }
  .post-preview {
    margin-right: 15px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    padding: 25px;
    .owner-details {
      display: flex;
      flex-direction: row;
      .owner-img {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        overflow: hidden;
      }
      .owner-img.placeholder {
        -webkit-mask: url('../../../assets/profile.svg') no-repeat bottom;
        mask: url('../../../assets/profile.svg') no-repeat bottom;
        background: #003349;
        opacity: 0.3;
      }
      .owner-title {
        margin-left: 12px;
        flex: 1 1;
        .main-title {
          font-size: 16px;
          font-weight: 800;
        }
      }
      .owner-type-badge {
        padding: 8px 18px;
        background: #f1f1f0;
        align-self: flex-start;
        font-size: 12px;
        border-radius: 8px;
        i {
          margin-right: 9px;
        }
      }
    }
  }
}
