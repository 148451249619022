.HubManagementLocations {
  display: flex;
  flex-direction: column;
  padding: 60px;

  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
