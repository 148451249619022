@import '../../styles/variables.scss';

@keyframes slideDown {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.dark {
  .Portrait {
    .inner {
      &.transparent {
        border: 2px solid color(dark, primaryBg);
      }
    }
  }
}

.Portrait {
  position: relative;
  &.hide-icon {
    .inner {
      &:hover {
        .fa-user {
          display: none;
        }
      }
    }
  }

  .notification-dot {
    position: absolute;
    top: 0;
    right: -5px;
    width: 12px;
    height: 12px;
    border: solid 2px white;
    border-radius: 50%;
    background-color: color(red, soft);
    z-index: 1;
  }

  .inner {
    overflow: hidden;
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: color(cream, dark);
    border: 2px solid color(cream, dark);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;

    &.transparent {
      transition: 300ms all ease-in-out;
      background-color: color(grey, lighter);
      border: 1px solid color(grey, lightest);
    }

    .profile {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: color(blue, dark);
      opacity: 0.3;
      -webkit-mask: url('../../assets/profile.svg') no-repeat bottom;
      mask: url('../../assets/profile.svg') no-repeat bottom;
    }

    img {
      position: absolute;
      border-radius: 50%;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
    }

    .ImageUpload {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;

      .FileUpload {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }
    }

    &:hover {
      .overlay {
        display: flex;
        z-index: 3;
      }
    }

    .fa-user {
      color: color(blue, dark);
      opacity: 0.5;
      font-size: 22px;
    }

    .overlay {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: rgba(0, 0, 0, 0.5);
      cursor: pointer;

      .fa-camera {
        animation: 150ms slideDown ease-out;
        font-size: 38px;
        color: color(dark, fontColor);
        text-align: center;
      }
    }
  }
}
