@import '../../../styles/variables.scss';

.Fundraiser {
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;

    .TextField {
      padding: 0px 20px;
    }

    h3 {
      flex: 1;
      margin: 0px;
      padding: 0px 20px;
    }

    .actions {
      padding-right: 20px;
    }
  }

  .search {
    padding: 0px 20px;
    margin-bottom: 10px;

    .TextField {
      input {
        background-color: white;
      }

      .input-wrap i {
        font-weight: 300;
        font-size: 18px;
        background-color: white;
        color: color(light, fontColor);
      }
    }
  }

  .columns {
    padding: 10px;
    margin: 10px;
    display: flex;
    flex: 1;

    .column {
      flex: 1;

      &:first-child {
        margin-right: 10px;
      }

      .top-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 10px;

        .empty {
          background-color: color(light, secondaryBg);
          padding: 20px 40px;
          flex: 1;
          line-height: 24px;
          width: 100%;
          text-align: center;
        }

        .title-container {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          width: 100%;

          .title {
            display: flex;
            font-size: 19px;
            font-weight: 700;
            padding: 10px 0px;
          }

          .page-info {
            font-size: 16px;
            color: #354a53;
            margin-left: auto;
          }
        }
      }

      .FundraiserItem {
        flex: 1;
        width: 100%;
      }

      .PageComponentPaginatorContainer {
        width: 100%;

        .page-container {
          padding-bottom: 10px;
        }

        .PageComponentPaginator {
          position: unset;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .Fundraiser {
    .title {
      h3 {
        padding: 0px;
      }
    }

    .search,
    .columns {
      padding-left: 0px;
      padding-right: 0px;
    }

    .columns {
      flex-direction: column;
      padding: 0;
      margin: 0;

      .column {
        width: auto;

        .top-wrap {
          padding: 0;
        }

        &:first-child {
          margin-right: 0px;

          .top-wrap {
            margin-right: 0px;
          }
        }

        &:last-child {
          .top-wrap {
            margin-top: 20px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}
