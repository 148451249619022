@import '../../styles/variables.scss';

.Scheduler {
  position: relative;
  flex-direction: row !important;

  .confirm-delete {
    padding: 20px;
    flex: 1;

    p {
      color: color(grey);
      text-align: center;
      padding: 0px;
      margin: 0px;
      font-size: 16px;
      line-height: 24px;
    }

    .confirm-delete-actions {
      display: flex;
      padding-top: 30px;
      flex: 1;
      justify-content: center;

      .Button {
        &:last-child {
          margin-left: 10px;
        }

        &:first-child {
          margin-right: 10px;

          button {
            background-color: color(light, secondaryBg);
            color: color(grey);
            border: 2px solid color(grey, light);

            &:hover {
              color: color(purple);
              border: 2px solid color(purple);
            }
          }
        }
      }
    }
  }

  .applicant-inner-modal {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 30px;

    .shifts {
      padding: 0px 0px 40px;

      .shifts-title {
        display: flex;
        justify-content: space-between;
        flex: 1;
        font-weight: 700;
        font-size: 18px;
        padding-bottom: 10px;

        .Button {
          button {
            padding: 0px;
            margin: 0px;
            background-color: transparent;
            min-height: 0px;
            min-width: 0px;
            box-shadow: none;
            border-radius: 0px;

            span {
              color: color(purple, dark);
            }
          }

          &:hover {
            button {
              span {
                color: color(purple, light);
              }
            }
          }
        }
      }

      ul,
      li {
        padding: 0px;
        margin: 0px;
        list-style-type: none;

        li {
          padding-bottom: 5px;

          &.full {
            opacity: 0.5;
          }

          .Checkbox {
            label {
              justify-content: flex-start;
            }
          }

          i {
            color: color(purple, dark);
          }
        }
      }

      .no-shifts {
        color: color(grey);
      }
    }

    .form-answers {
      background-color: color(blue, lightest);
      box-shadow: $box-shadow;
      margin-bottom: 40px;
      max-height: 400px;
      overflow: auto;

      &.closed {
        .question {
          display: none;
        }

        .title {
          border-bottom: 0px;
        }
      }

      .title {
        line-height: 16px;
        padding: 12px 20px;
        margin: 0px;
        display: flex;
        text-align: left;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid color(grey, light);

        span {
          flex: 1;
          justify-content: flex-start;
          align-items: flex-start;
          font-size: 16px;
          font-weight: 500;
          color: color(grey);
        }

        i {
          color: color(grey);

          &:hover {
            cursor: pointer;
            color: color(purple, dark);
          }
        }
      }

      .question {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 0px;

        &:last-child {
          padding-bottom: 20px;
        }

        .label {
          font-size: 16px;
          font-weight: 700;
        }

        .answer {
          padding-top: 5px;
          font-size: 14px;
        }
      }
    }

    .title {
      text-align: center;
      font-size: 24px;
      line-height: 24px;
      padding-bottom: 25px;
      font-weight: 700;
      border-bottom: 1px solid color(grey, light);
    }

    .applicant {
      padding: 40px 0px;
      display: flex;

      .applicant-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 40px;

        .applicant-cols {
          display: flex;

          .Portrait {
            margin-right: 40px;
          }

          .col {
            display: flex;
            flex-direction: column;
            padding-right: 40px;
            justify-content: center;

            &:last-child {
              padding-right: 0px;
            }

            span {
              &:first-child {
                font-size: 12px;
                font-weight: 500;
              }

              &:last-child {
                font-weight: 400;
                color: color(grey);

                &.status {
                  text-transform: capitalize;
                }
              }
            }
          }
        }

        .age-check {
          padding: 10px 0px 0px;
          color: color(yellow, warning);
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;

          i {
            margin-right: 5px;
            font-size: 16px;
          }
        }
      }
    }

    .applicant-actions {
      display: flex;
      justify-content: flex-end;

      .Button {
        margin-left: 20px;

        &:last-child {
          button {
            border: 3px solid color(purple, dark);

            &:hover {
              border: 3px solid color(purple);
            }
          }
        }

        &:first-child {
          button {
            background-color: color(light, secondaryBg);
            color: color(purple, dark);
            border: 3px solid color(purple, dark);

            &:hover {
              border: 3px solid color(purple);
              color: color(purple);
            }
          }
        }
      }
    }
  }

  .create-shift-modal {
    flex: 1;
    max-height: 800px;
    overflow: auto;

    .form-inner {
      padding: 30px 25px 0px;

      .DatePicker {
        label {
          transform: translateX(-32px);
        }

        .dp-inner {
          .month {
            max-width: 90px;
            min-width: 90px;

            .Dropdown {
              .input-wrap {
                border-right: 0px;
                border-radius: 0px;
                border-left: 0px;

                select {
                  color: color(grey);
                  font-weight: 400;
                }

                .fa-angle-down {
                  display: none;
                }
              }
            }
          }

          .day,
          .year {
            .TextField {
              margin-left: 0px;

              .input-wrap {
                border-left: 0px;
                border-right: 0px;
                border-radius: 0px;

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                input[type='number'] {
                  -moz-appearance: textfield;
                }

                input {
                  color: color(grey);
                  font-weight: 400;
                }
              }
            }
          }

          .day {
            max-width: 35px;
          }

          .year {
            max-width: 73px;

            .TextField {
              .input-wrap {
                border-right: 1px solid color(grey, light);
                border-top-right-radius: 7px;
                border-bottom-right-radius: 7px;
              }
            }
          }
        }
      }

      .workers {
        flex: 1;

        .worker {
          display: flex;
          align-items: center;
          padding: 5px 10px;

          .Portrait {
            padding-right: 10px;
          }

          span {
            flex: 1;
            font-size: 14px;
            color: color(grey);
            text-transform: capitalize;
          }

          i {
            color: color(grey);

            &:hover {
              color: color(purple, dark);
              cursor: pointer;
            }
          }
        }
      }

      .reoccurrance {
        padding-bottom: 20px;
      }

      .select-row {
        display: flex;

        .select-label {
          margin-top: 15px;
          font-size: 13px;
          font-weight: 700;
        }

        .select-options {
          .select-option {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .end {
              margin-left: 15px;
              font-size: 13px;
            }

            .select-radio {
              margin: 0px 20px;

              &:hover {
                cursor: pointer;
              }

              span {
                font-size: 13px;
              }

              i {
                margin-right: 10px;

                &:hover {
                  cursor: pointer;
                }
              }

              .fas {
                &.fa-circle {
                  color: color(purple, dark);
                }
              }

              .fal {
                &.fa-circle {
                  color: color(grey);
                }
              }
            }
          }
        }
      }

      .TextField {
        .input-wrap {
          input {
            font-weight: 400;
            color: color(grey);
          }
        }
      }

      .form-row {
        padding-bottom: 20px;

        .Dropdown {
          .input-wrap {
            select {
              color: color(grey);
              font-weight: 400;
            }
          }
        }

        .TextField {
          .input-wrap {
            input {
              color: color(grey);
              font-weight: 400;
            }
          }
        }

        &:last-child {
          padding-bottom: 0px;
        }

        .weekly-days {
          display: flex;
          align-items: center;

          .label {
            font-size: 13px;
            font-weight: 700;
            padding-right: 10px;
            overflow: hidden;
            white-space: nowrap;
          }

          .week-buttons {
            list-style-type: none;
            display: flex;
            margin: 0px;
            padding: 0px;

            li {
              height: 24px;
              width: 24px;
              font-size: 12px;
              font-weight: 700;
              background-color: color(grey, lighter);
              border-radius: 50%;
              margin-right: 10px;
              display: flex;
              justify-content: center;
              align-items: center;

              &.active {
                background-color: color(purple, dark);
                color: color(dark, fontColor);
              }

              &:hover {
                cursor: pointer;
              }
            }
          }
        }

        &.inline {
          display: flex;
          align-items: center;

          .end {
            padding-left: 8px;
            font-size: 13px;
            font-weight: 700;
          }

          .TextField {
            flex-direction: row;
            flex: 0;
            align-items: center;
            max-width: 150px;

            label {
              padding-bottom: 0px;
              flex: 0;
              white-space: nowrap;
              padding-right: 8px;
            }

            .input-wrap {
              flex: 1;
            }
          }
        }
      }

      .date-row,
      .dbl-date-wrap {
        display: flex;

        .fa-calendar {
          border: 1px solid color(grey, light);
          border-right: 0px;
          height: 42px;
          margin-top: 19px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top-left-radius: 7px;
          border-bottom-left-radius: 7px;
          padding-left: 10px;
          color: color(grey);
          padding-right: 7px;
        }
      }

      .dbl-date-wrap {
        .fa-calendar {
          margin-top: 4px;
        }
      }

      .time-row {
        display: flex;

        .start-time,
        .end-time {
          display: flex;

          .fa-clock {
            border: 1px solid color(grey, light);
            border-right: 0px;
            height: 42px;
            margin-top: 19px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            padding-left: 10px;
          }

          .Dropdown {
            flex: 1;
            margin-top: 19px;
            max-width: 45px;
            min-width: 45px;

            .fa-angle-down {
              display: none;
            }

            &:last-child {
              max-width: 45px;

              .input-wrap {
                border-right: 1px solid color(grey, light);
                border-top-right-radius: 7px;
                border-bottom-right-radius: 7px;
              }
            }

            .input-wrap {
              border-radius: 0px;
              border-left: 0px;
              border-right: 0px;

              select {
                color: color(grey);
                font-weight: 400;
              }
            }
          }

          .TextField {
            max-width: 32px;
            min-width: 32px;
            white-space: nowrap;

            label {
              span {
                transform: translateX(-25px);
              }
            }

            .input-wrap {
              position: relative;
              border-radius: 0px;
              border-right: 0px;
              border-left: 0px;
              overflow: visible;

              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              input[type='number'] {
                -moz-appearance: textfield;
              }

              input {
                color: color(grey);
                font-weight: 400;
                width: 100%;
                text-align: center;
                text-indent: 8px;
              }

              &:after {
                right: -2px;
                top: 10px;
                position: absolute;
                content: ':';
                color: color(grey);
              }
            }
          }
        }

        .start-time {
          margin-right: 10px;
        }
      }
    }

    .actions {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      padding: 0px;
      bottom: 0px;
      left: 0px;
      width: 100%;
      background-color: color(light, secondaryBg);
      height: 100px;
    }
  }

  .inner-section-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;

    .section-title {
      .action {
        .Button {
          button {
            box-shadow: $box-shadow;
          }
        }
      }
    }
  }

  &.section-wrap {
    &.open {
      .second-sidebar {
        width: 245px;
        padding: 20px;
        align-items: flex-start;
        user-select: none;
        .hamburger {
          width: 100%;
          height: min-content;
          justify-content: end;

          i {
            -webkit-transform: rotate(180deg) scale(0.7);
            -ms-transform: rotate(180deg) scale(0.7);
            transform: rotate(180deg) scale(0.7);
          }
        }

        .gig-selection {
          transition: opacity 150ms linear;
          transition-delay: 150ms;
          width: 100%;
          height: auto;
          opacity: 1;
          margin-bottom: 10px;

          .Dropdown {
            display: flex;
          }
        }

        .application-search {
          transition: opacity 150ms linear;
          transition-delay: 150ms;
          width: 100%;
          flex-basis: 44px;
          height: 44px;
          opacity: 1;
          flex-grow: 0;
          margin-bottom: 10px;
          position: relative;

          // Weird hacky position/scale fix cause textfield was having issues scaling properly.
          .input-wrap {
            width: 99%;

            input {
              width: 100%;
              flex: 1;
            }
          }
        }

        .applicant-wrap {
          display: flex;
          flex: 1;
          flex-direction: column;
          width: 100%;

          &.collapsed {
            flex: 0;
          }

          &.first {
            .applicants-title {
              border-top: 0px;
            }
          }

          .applicants-title {
            border-top: 1px solid color(grey, lighter);
            display: flex;
            font-size: 16px;
            font-weight: 700;
            padding: 15px 0px;
            align-items: center;
            justify-content: space-between;

            &:hover {
              cursor: pointer;
            }
          }

          .group-applicants {
            flex: 1;
            overflow: auto;
            max-height: 300px;

            li {
              width: 100%;
              max-width: 100%;

              &.empty {
                display: block;
                line-height: 24px;
                font-size: 14px;
                color: color(grey);

                &:hover {
                  background-color: color(light, secondaryBg);
                }
              }

              &:hover {
                cursor: pointer;
                background-color: color(grey, primary);
              }

              .gigiteer {
                .Portrait {
                  padding-right: 12px;
                }

                .status {
                  width: auto;
                  display: flex;
                  margin-bottom: 5px;
                }

                .gigiteer-info {
                  flex: 1;
                }

                .gigiteer-info,
                .status {
                  transition: opacity 150ms linear;
                  transition-delay: 150ms;
                  opacity: 1;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }

    .section-title {
      display: flex;

      .title-wrap {
        flex: 1;
      }

      .action {
        display: flex;
        flex-direction: row;

        .exports-list {
          position: relative;
          cursor: pointer;
          margin-left: 15px;

          &:hover {
            .ContextMenu {
              display: block;
            }

            button {
              color: color(purple, base);
              box-shadow: none;

              span {
                color: color(purple, base);
              }
            }
          }

          .ContextMenu {
            display: none;
            position: absolute;
            top: 55px;
            right: 10px;
            box-shadow: $box-shadow;
            border-radius: 20px;

            li {
              cursor: pointer;
              font-weight: 400;

              &.default-role {
                display: none;
              }
            }

            i {
              font-size: 16px;
              padding-right: 8px;
            }
          }

          .Button {
            button {
              background: color(light, secondaryBg);
              color: color(purple, dark);
              box-shadow: $box-shadow;

              &:hover {
                color: color(purple, base);
                box-shadow: none;
              }
            }
          }
        }
      }
    }

    .loader-overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 31px;
      border-radius: 15px;
    }

    .section-inner {
      position: relative;
      display: flex;
      flex-direction: column;

      .empty {
        color: color(grey);
        font-size: 14px;
      }

      .date {
        margin-bottom: 20px;
        font-size: 21px;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .actions {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 40px;
          border: 1px solid color(blue, lightest);
          padding: 6px 8px;
          border-radius: 8px;

          span {
            font-size: 15px;
            margin: 0px 20px;
            font-weight: 700;
          }

          i {
            font-size: 24px;

            &:hover {
              cursor: pointer;
              color: color(purple, dark);
            }
          }
        }
      }

      .calendar {
        display: flex;
        flex-direction: column;
        flex: 1;

        .headers {
          display: flex;
          flex: 1;
          background-color: color(blue, lightest);
          max-height: 40px;
          justify-content: center;
          align-items: center;

          .time {
            max-width: 100px;
          }

          .day,
          .time {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 15px;
          }
        }

        .timeslots {
          flex: 1;
          overflow: auto;
          position: relative;

          .timeslots-inner {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;

            .timeslot {
              height: 100px;
              display: flex;
              position: relative;

              &:after {
                content: ' ';
                position: absolute;
                width: 100%;
                height: 0px;
                top: 100%;
                left: 0px;
                border-bottom: 1px dashed color(blue, lighter);
              }

              .time {
                max-width: 100px;
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: flex-end;
                font-weight: 700;
              }

              .day {
                border-right: 1px solid color(blue, lighter);
                position: relative;
                flex: 1;

                &.sunday,
                &.monday,
                &.tuesday,
                &.wednesday {
                  .shift {
                    .hover {
                      left: 100%;

                      .hover-inner {
                        margin-left: 5px;
                      }
                    }
                  }
                }

                &.thursday,
                &.friday,
                &.saturday {
                  .shift {
                    .hover {
                      left: auto;
                      right: 100%;

                      .hover-inner {
                        margin-right: 5px;
                      }
                    }
                  }
                }

                .shift {
                  display: flex;
                  flex-direction: column;
                  border-radius: 8px;
                  background-color: color(light, secondaryBg);
                  border: 1px solid color(blue, darker);
                  position: absolute;
                  top: 100%;
                  left: -1px;
                  width: 100%;
                  height: 100%;
                  z-index: 2;
                  box-shadow: $box-shadow;

                  &.at-capacity {
                    border: 1px solid color(green, dark);
                  }

                  &.over-capacity {
                    border: 1px solid color(red, error);
                  }

                  &:hover {
                    z-index: 3;

                    .hover {
                      display: block;
                    }
                  }

                  &.m15 {
                    top: 125%;
                  }

                  &.m30 {
                    top: 150%;
                  }

                  &.m45 {
                    top: 175%;
                  }

                  .hover {
                    display: none;
                    position: absolute;
                    top: 0px;

                    .hover-inner {
                      border-radius: 8px;
                      padding: 20px;
                      border: 1px solid color(blue, darker);
                      background-color: color(light, secondaryBg);
                      box-shadow: $box-shadow;
                      min-width: 300px;

                      &.at-capacity {
                        border: 1px solid color(green, dark);
                      }

                      &.over-capacity {
                        border: 1px solid color(red, error);
                      }

                      .hover-actions {
                        display: flex;
                        flex: 1;
                        justify-content: flex-end;

                        i {
                          font-size: 18px;
                          margin-left: 10px;
                          color: color(light, fontColor);

                          &:hover {
                            color: color(blue, darker);
                            cursor: pointer;
                          }
                        }
                      }

                      .hover-content {
                        .title {
                          font-size: 21px;
                          font-weight: 500;
                        }

                        .type {
                          font-size: 14px;
                          color: color(grey);
                        }

                        .hover-inner-content {
                          padding-top: 20px;

                          .hic {
                            font-size: 16px;
                            font-weight: 400;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            margin-bottom: 15px;

                            i {
                              color: color(grey);
                              margin-right: 10px;
                              min-width: 20px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              font-size: 18px;
                            }

                            .see-assigned-link {
                              cursor: pointer;
                              text-decoration: none;
                              color: #095fc4;
                              font-size: 14px;
                              text-align: center;

                              &:hover {
                                color: #2976d1;
                              }
                            }

                            div {
                              font-size: 14px;
                              line-height: 18px;
                            }
                          }
                        }
                      }
                    }
                  }

                  .assigned-users {
                    flex: 1;
                  }

                  .sidebar {
                    height: 36px;
                    box-sizing: border-box;
                    padding: 10px;
                    background-color: color(blue, darker);
                    color: color(dark, fontColor);
                    overflow-wrap: break-word;

                    &.at-capacity {
                      background-color: color(green, dark);
                    }

                    &.over-capacity {
                      background-color: color(red, error);
                    }

                    .title {
                      font-weight: 500;
                      font-size: 16px;
                      margin-bottom: 4px;
                    }

                    .capacity {
                      display: flex;
                      flex-direction: column;

                      span {
                        font-size: 14px;
                        font-weight: 300;
                      }
                    }

                    .sidebar-actions {
                      flex: 1;
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;

                      i {
                        font-size: 16px;
                        margin: -8px 10px 0px 0px;

                        &:hover {
                          cursor: pointer;
                          color: color(red, dark);
                        }
                      }
                    }
                  }

                  .assigned-users {
                    list-style-type: none;
                    padding: 8px 0px 0px;
                    margin: 0px;
                    width: 100%;
                    overflow-y: hidden;
                    position: relative;

                    &.drag-over {
                      background-color: color(blue, lightest);
                    }

                    .see-assigned-link {
                      position: absolute;
                      cursor: pointer;
                      text-decoration: none;
                      color: #095fc4;
                      font-size: 14px;
                      text-align: center;
                      bottom: 0px;
                      left: 0px;
                      width: 100%;
                      height: 17px;
                      text-align: center;

                      &:hover {
                        color: #2976d1;
                      }
                    }

                    .gigiteer {
                      display: flex;
                      align-items: center;
                      padding: 0px 8px 4px;
                      transition: all 100ms ease-in-out;

                      font-size: 14px;
                      font-weight: 400;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      color: color(grey);
                      text-transform: capitalize;
                    }
                  }
                }

                &.sunday {
                  border-left: 1px solid color(blue, lighter);
                }

                &.saturday {
                  border-right: 0px;
                }
              }
            }
          }
        }
      }
    }

    .second-sidebar {
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: color(light, secondaryBg);
      width: 70px;
      border-left: 1px solid color(light, primaryBg);
      transition: all 150ms ease-in-out;

      .hamburger {
        font-size: 36px;
        height: 70px;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          i {
            color: color(purple, dark);
            cursor: pointer;
          }
        }
      }

      .applicant-wrap {
        transition: all 150ms ease-in-out;
        .applicants-title {
          display: none;
        }

        .group-applicants {
          overflow: hidden;

          li {
            &.empty {
              display: none;
            }
          }
        }
      }

      .gig-selection {
        opacity: 0;
        width: 0px;
        height: 0px;
        overflow: hidden;

        .Dropdown {
          position: relative;
          flex: 1;
          display: none;

          .input-wrap {
            max-width: 100%;
            background-color: color(grey, pale);
            border: 0px;
            border-radius: 8px;

            select {
              font-size: 14px;
              padding-right: 25px;
              text-overflow: ellipsis;
              overflow: auto;
              word-break: break-word;
            }
          }
        }
      }

      .application-search {
        opacity: 0;
        display: flex;
        width: 0px;
        height: 0px;
        overflow: hidden;
      }

      ul {
        padding: 0px;
        margin: 0px;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        width: 100%;

        li {
          background-color: color(light, secondaryBg);
          border-radius: 8px;

          .gigiteer {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            transition: all 100ms ease-in-out;
            position: relative;

            .gigiteer-hover {
              position: absolute;
              top: 0px;
              left: 100%;
            }

            .gigiteer-info,
            .status {
              opacity: 0;
              transition-delay: 0ms;
              width: 0px;
              height: 0px;
            }

            .gigiteer-info {
              display: flex;
              flex-direction: column;

              .displayName {
                font-size: 14px;
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .timeframe {
                font-size: 12px;
                color: color(grey, light);
              }
            }

            .status {
              display: flex;
              flex: 0;

              .status-inner {
                border-radius: 4px;
                padding: 4px 8px;
                font-size: 12px;
              }

              &.pending {
                .status-inner {
                  background-color: color(grey, pale);
                }
              }

              &.approved {
                .status-inner {
                  background-color: color(green, light);
                  color: color(green, dark);
                }
              }

              &.rejected {
                .status-inner {
                  background-color: color(brand, red);
                  color: color(dark, fontColor);
                }
              }
            }
          }
        }
      }
    }
  }
}
