@import '../../styles/variables.scss';

.UserDashboard {
  position: relative;
  display: flex;
  width: 100%;

  .main {
    position: absolute;
    display: flex;
    flex-flow: column;
    right: 0;
    top: 0;
    bottom: 0;
    border-top: 1px solid color(grey, pale);
    animation: sidebarOpen 1s ease-in-out;
    left: 261px;
    transition: left 0.5s ease-in-out;

    &.collapsed {
      left: 82px;
    }
  }
}

@media (max-width: 1070px) {
  .UserDashboard {
    .main {
      left: 0;
    }
  }
}
