@import '../../../styles/variables.scss';

.Store {
  h3 {
    font-size: 22px;
    margin: 0px;
    padding: 0px 20px 20px;
  }

  .loader {
    display: flex;
    .Loader {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .store-item-wrap {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 930px) {
  .Store {
    h3 {
      padding: 0px 0px 20px;
    }

    .store-item-wrap {
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}

@media (max-width: 500px) {
  .Store {
    .ComponentPlaceholder {
      .action-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .Button {
          margin: 0px;
          margin-bottom: 12px;
          min-width: 150px;

          &:last-child {
            margin-bottom: 0px;
          }

          button {
            min-width: 150px;
            margin-right: 0px !important;
          }
        }
      }
    }
  }
}
