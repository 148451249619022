@import '../../../../styles/variables.scss';

.HubTrailWarningBanner {
  position: absolute;
  bottom: 24px;
  width: 100%;

  .content {
    height: 64px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 36px;
    background-color: color(red, soft);
    color: color(light, secondaryBg);

    .x-icon {
      margin-top: 3px;
      font-size: 20px;
      margin-right: 10px;
    }

    p {
      margin: 0;
      font-family: $lato;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;

      b {
        font-weight: 700;
        margin-right: 10px;
      }
    }

    .upgrade {
      margin-left: 10px;
      width: 210px;
      text-align: center;
      font-family: $lato;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 900px) {
  .HubTrailWarningBanner {
    bottom: 18px;

    .content {
      padding: 0 8px;

      p {
        font-size: 11px;
      }

      .upgrade {
        font-size: 12px;
      }
    }
  }
}
