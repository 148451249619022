@import '../../styles/variables.scss';

.light {
  .User {
    color: color(light, fontColor);
    background-color: color(light, primaryBg);
  }
}

.dark {
  .User {
    color: color(dark, fontColor);
    background-color: color(dark, primaryBg);
  }
}

.User {
  display: flex;
  flex: 1;
  padding: 60px 0;
  width: 100%;
  position: relative;
  justify-content: center;

  .FloatingActionButton {
    display: none;
  }

  .user-inner {
    display: flex;
    max-width: $wrap-size;
    flex: 1;
  }

  .Modal {
    .content {
      .onboarding-container {
        padding: 40px;
        max-width: 450px;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        overflow: auto;

        &.user-onboard {
          .wrapper {
            .onboard-img {
              height: 350px;
              width: 100%;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: contain;
            }
            &.find {
              .onboard-img {
                background-image: url('../../assets/gigit-profile-find.png');
              }
            }
            &.connect {
              .onboard-img {
                background-image: url('../../assets/gigit-profile-connect.png');
              }
            }
            &.giveback {
              .onboard-img {
                background-image: url('../../assets/gigit-profile-giveback.png');
              }
            }
          }
        }

        .onboard-title {
          font-size: 31px;
          color: color(purple, dark);
          margin: 0 40px;
          margin-bottom: 20px;
        }

        .steps-select {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          text-align: center;

          i {
            padding: 5px;
            cursor: pointer;
            color: #88949a;
          }
        }

        .onboard-info {
          margin: 0 40px;
          font-size: 18px;
          min-height: 64px;
          color: color(blue, dark);
        }

        .onboard-img {
          height: 350px;
          width: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }

        &.donation {
          .onboard-img {
            background-image: url('../../assets/gigit-setup-donation.png');
          }
        }
        &.volunteer {
          .onboard-img {
            background-image: url('../../assets/gigit-setup-volunteer.png');
          }
        }

        .onboard-actions {
          margin: 0 40px;
          width: 85%;
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .Button {
            button {
              flex: 1;
              text-decoration: none;
              display: flex;
              border: 0px;
              padding: 0px;
              font-size: 16px;
              border-radius: 20px;
              justify-content: center;
              align-items: center;
              color: color(dark, fontColor);
              background-color: color(purple, dark);
              overflow: hidden;
              width: 100%;
              min-height: 40px;
              font-weight: 500;
              margin-bottom: 30px;
              box-shadow: $box-shadow;
              span {
                color: #fff;
              }
            }
          }
          a {
            flex: 1;
            text-decoration: none;
            display: flex;
            border: 0px;
            padding: 0px;
            font-size: 16px;
            border-radius: 20px;
            justify-content: center;
            align-items: center;
            color: color(dark, fontColor);
            background-color: color(purple, dark);
            overflow: hidden;
            width: 100%;
            min-height: 40px;
            font-weight: 500;
            margin-bottom: 30px;
            box-shadow: $box-shadow;

            &:hover {
              background-color: color(purple);
            }
          }
          span {
            font-size: 17px;
            color: #88949a;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: 1240px) {
  .User {
    .user-inner {
      flex-direction: column;
      border-radius: 0px;
      margin-top: 20px;
      justify-content: center;
      align-items: center;
      width: 100%;

      .PageSidebar {
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 930px) {
  .User .user-inner .PageSelector .PageContent .add-component {
    margin: 10px;
  }
}

@media (max-width: 790px) {
  .User {
    margin-top: -80px;
  }
}

@media (max-width: 680px) {
  .User {
    .FloatingActionButton {
      display: flex;
      position: fixed;
      bottom: $mobile-footer-height;
      right: 20px;
      margin-bottom: 20px;
      z-index: 1000;
    }
  }
}

@media (max-width: 530px) {
  .User {
    margin-top: 0px;

    .Modal {
      .content {
        .onboarding-container {
          padding: 10px;
        }
      }
    }
  }
}
