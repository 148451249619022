@import '../../styles/variables.scss';

.VerifyEmail {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  .Loader {
    font-size: 40px;
  }
}
