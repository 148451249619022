@import '../../styles/variables.scss';

.StoreItemManagement {
  .section-title {
    display: flex;

    .store-title,
    .store-action {
      display: flex;
      flex: 1;
    }

    .store-title {
      align-items: center;
      line-height: 40px;

      span {
        height: 40px;
        line-height: 36px;
      }

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        color: color(grey);
        padding: 0px 10px 0px 0px;
        line-height: 40px;
        height: 40px;

        &:hover {
          cursor: pointer;
          color: color(purple, dark);
        }
      }
    }

    .store-action {
      justify-content: flex-end;
      align-items: flex-end;

      .Button {
        button {
          box-shadow: $box-shadow;
        }
      }
    }
  }

  .edit-store-item {
    flex: 1;
  }

  .StoreItemManagement-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    .search {
      display: flex;
      align-items: center;

      .TextField {
        max-width: 50%;

        i {
          background-color: color(light, secondaryBg);
          color: color(light, fontColor);
        }

        input {
          background-color: color(light, secondaryBg);
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      flex: 1;

      .headers,
      .row {
        .col {
          display: flex;
          flex: 1;
          font-weight: 700;

          &:first-child {
            text-indent: 8px;
          }

          &.item-name {
            flex: 1.5;
          }

          &.description {
            flex: 1.5;
          }

          &.portrait {
            max-width: 120px;
            justify-content: center;
          }

          &.actions {
            max-width: 120px;
          }

          &.actions {
            justify-content: flex-end;
            padding-right: 40px;
            position: relative;

            .ContextMenu {
              display: none;
              position: absolute;
              top: 55px;
              right: 10px;
              box-shadow: $box-shadow;
              border-radius: 20px;

              &.top {
                top: -103px;

                &:before {
                  top: 98px;
                  transform: rotate(180deg);
                }
              }

              li {
                cursor: pointer;

                &.default-role {
                  display: none;
                }
              }

              i {
                font-size: 16px;
                padding-right: 8px;
              }

              &:hover {
                display: block;
              }
            }

            i.fa-ellipsis-h-alt {
              font-size: 24px;
              height: 40px;
              width: 40px;
              background-color: color(blue, lightest);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }

            i.fa-ellipsis-h-alt:hover + .ContextMenu {
              display: block;
            }
          }
        }
      }

      .row {
        .col {
          align-items: center;

          &.description {
            position: relative;

            .description-inner {
              position: absolute;
              top: 20px;
              left: 5px;
              overflow: hidden;
              text-overflow: ellipsis;
              max-height: 95px;
              line-height: 24px;
              font-size: 16px;
              color: color(grey);
              white-space: nowrap;
              width: 90%;
            }
          }
        }
      }

      .headers {
        background-color: color(blue, lightest);
        display: flex;
        flex: 0;
        min-height: 40px;
        height: 40px;
        line-height: 40px;
      }

      .list-inner {
        flex: 1;
        max-height: 100%;
        overflow: auto;
        position: relative;

        .list-rows {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;

          .empty {
            flex: 1;
            padding: 30px 20px;
            text-align: center;
            color: color(grey);
            font-size: 16px;
          }
        }

        .row {
          display: flex;
          min-height: 60px;
          border-bottom: 1px solid color(grey, light);

          .col {
            font-weight: 400;
          }
        }
      }
    }
  }
}
