@import '../../../styles/variables.scss';

.Fundraisers,
.Sponsor,
.ContactSupport,
.ContactDonor,
.ContactVolunteer,
.Contact {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 40px 40px;

  .activity-log {
    background: color(cream, dark);
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 8px;
    max-height: 700px;
  }

  .Contact-menu {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    min-height: 80px;
    max-height: 80px;
    align-items: center;

    li {
      height: 40px;
      margin-right: 20px;

      &.add {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-right: 0px;
        position: relative;

        .custom-fields-menu {
          display: none;
          position: absolute;
          top: 100%;
          right: 0px;
          background-color: #fff;
          margin-top: 10px;
          min-width: 200px;
          border: 1px solid #b6c0c4;
          box-shadow: $box-shadow;
          border-radius: 6px;

          &:before,
          &:after {
            content: ' ';
            position: absolute;
          }

          &:before {
            top: -55px;
            right: -2px;
            width: 100px;
            height: 60px;
          }

          &:after {
            top: -35px;
            left: 55px;
            width: 80px;
            height: 55px;
            transform: rotate(-45deg);
          }

          .cf-button {
            margin-top: 10px;

            button {
              background-color: transparent;
              border: 1px solid color(purple, dark);

              .text {
                color: color(grey);
              }

              &:hover {
                background-color: color(purple, light);

                .text {
                  color: color(purple, dark);
                }
              }
            }
          }

          .cf-title {
            background-color: #42606c;
            color: #fff;
            padding: 10px;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.8px;
            border-bottom: 1px solid #b6c0c4;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
          }

          ul,
          li {
            padding: 0px;
            margin: 0px;
            list-style-type: none;
          }

          ul {
            padding: 5px 15px;
          }

          li {
            display: flex;
            align-items: center;

            i {
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: 20px;
              max-width: 20px;
            }

            span {
              border: 0px;
              height: auto;
              color: #103645;
            }

            &:hover {
              span {
                color: color(purple, dark);
              }
            }
          }
        }

        .add-field:hover + .custom-fields-menu,
        .custom-fields-menu:hover {
          display: block;
        }

        .Button {
          margin-left: 10px;

          button {
            border-radius: $border-radius;
            padding: 0px 20px;

            span {
              padding: 0px;
              border: 0px;
              color: #fff;
            }
          }
        }
      }

      &:hover {
        &.add {
          .Button {
            button {
              span {
                padding: 0px;
                border: 0px;
                color: #fff;
              }
            }
          }
        }
      }

      span {
        align-items: center;
        display: flex;
        font-size: 14px;
        color: #96a1a6;
        border: 1px solid #e5eff0;
        padding: 0px 15px;
        border-radius: 6px;
        height: 38px;
        font-weight: 700;
      }

      &:first-child {
        padding-left: 0px;
      }

      &.active {
        span {
          border: 1px solid #103645;
          color: #103645;
        }
      }

      &:hover {
        cursor: pointer;

        span {
          border: 1px solid #103645;
          color: #103645;
        }
      }
    }
  }

  .Contact-content {
    display: flex;
    flex: 1;
    width: 100%;

    .Contact-side,
    .Contact-main {
      flex: 1;
    }

    .Contact-side {
      max-width: 400px;
      min-width: 400px;
    }

    .contact-main,
    .Contact-main {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      width: 100%;

      .custom-fields {
        .SortableTable {
          .table-row {
            .col {
              span {
                padding: 0px;
                min-width: 0px;
              }
            }
          }
        }

        .empty {
          text-align: center;
          padding: 40px 0px;
          font-size: 14px;
          color: color(grey);
        }
      }

      .data {
        .empty-data {
          border-radius: 8px;
          height: 300px;
          background-color: #f5f6f7;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .SortableTable {
        .table-header,
        .table-row {
          .col {
            &.field_name {
              max-width: 300px;

              span {
                display: flex;
                align-items: center;
                height: 16px;

                i {
                  font-size: 16px;
                  height: 14px;
                  width: 20px;
                  text-align: center;
                  margin-right: 10px;
                  color: color(grey);
                }

                span {
                  display: inline-block;
                  font-weight: 700;
                  height: 16px;
                }
              }
            }

            &.value {
              .none {
                text-transform: lowercase;
                font-size: 14px;
                color: color(grey);
              }

              .value-cell {
                display: flex;
                flex: 1;
                height: 60px;
                align-items: center;

                &:hover {
                  .label-value {
                    .fa-times {
                      display: block;
                    }

                    &.not-selected {
                      display: flex;
                    }
                  }
                }
              }

              .true,
              .false {
                padding: 4px 8px;
                border-radius: 6px;
                color: color(dark, fontColor);
              }

              .true {
                background-color: #1fa863;
              }

              .false {
                background-color: #de3333;
              }

              .label-value {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                padding: 0px 10px;
                color: #fff;
                margin-right: 8px;
                height: 28px;

                span {
                  font-weight: 400;
                }

                .fa-times {
                  display: none;
                  padding: 4px 0px 0px 5px;
                }

                .fa-plus {
                  padding: 2px 5px 0px 0px;
                }

                &:hover {
                  cursor: pointer;
                }

                &.not-selected {
                  display: none;
                  background-color: color(grey, light) !important;
                }
              }

              .checkbox-value {
                margin-right: 8px;
                padding: 6px 12px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;

                &:first-child {
                  margin-left: -8px;
                }

                &:hover {
                  background-color: color(cream, dark);
                  cursor: pointer;
                }

                span {
                  font-size: 16px;
                }

                i {
                  margin-right: 5px;
                  font-size: 19px;

                  &.fa-square {
                    color: color(grey, light);
                  }

                  &.fa-check-square {
                    color: color(purple, dark);
                  }
                }
              }
            }

            &.notes,
            &.Notes {
              min-width: 200px;
            }

            &.User {
              background: none;
              justify-content: unset;
            }

            &.actions {
              .ContextMenu {
                .ContextMenu-ul {
                  li {
                    span {
                      display: block;
                      padding: 0px;
                      margin: 0px;
                      font-weight: 700;
                    }
                  }
                }
              }
            }
          }
        }

        .table-row {
          .col {
            &.field-name {
              font-weight: 700;
            }

            span {
              border-radius: $border-radius;
              padding: 8px;
              text-transform: capitalize;
              min-width: 75px;
              display: inline-flex;
              justify-content: center;

              &.sent,
              &.paid {
                background-color: #eafcf5;
                color: #067f5c;
                border: 1px solid color(green, dark);
              }

              &.unpaid {
                background-color: #f3f4f9;
                color: #686c8b;
                border: 1px solid #c5c8d4;
              }

              &.refunded {
                background-color: #fff2d8;
                color: #9f6000;
              }

              &.pending {
                background-color: #f3f4f9;
                color: #686c8b;
                border: 1px solid color(dark, tertiaryBg);
              }
            }
          }

          &.actions {
            max-width: 100px;
            cursor: pointer;
            position: relative;

            i {
              justify-self: flex-end;
            }

            .ContextMenu {
              display: none;
              position: absolute;
              right: 50px;
              top: 70%;
              box-shadow: $box-shadow;
              border-radius: 20px;

              i {
                font-size: 16px;
                padding-right: 8px;
              }

              &:hover {
                display: block;
              }
            }

            i.fa-ellipsis-h-alt {
              font-size: 24px;
              height: 40px;
              width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }

            i.fa-ellipsis-h-alt:hover + .ContextMenu {
              display: block;
            }
          }
        }
      }
    }
  }

  .Modal {
    &.cf-modal,
    &.edit-field,
    &.add-field,
    &.delete-field {
      .content {
        max-width: 650px;
        padding: 20px 40px;

        .modal-close-circle {
          right: -20px;
          top: -20px;
        }

        .actions {
          .Button {
            button {
              border-radius: $border-radius;
            }
          }
        }
      }
    }

    &.cf-modal {
      .content {
        padding: 0px;
      }
    }

    &.edit-field,
    &.delete-field {
      .content {
        padding: 0px;

        .title {
          padding: 30px 40px 30px;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
        }

        .inner-content {
          padding: 0px 40px;

          .msg {
            color: color(grey);
          }
        }

        .actions {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 30px 40px;
          margin-top: 40px;
          border-top: 1px solid color(grey, light);

          .Button {
            &.no {
              margin-right: 20px;

              button {
                background-color: transparent;
                color: color(grey);
                border: 2px solid color(grey, light);

                &:hover {
                  border: 2px solid color(red, dark);
                  color: color(red, dark);
                }
              }
            }

            button {
              min-width: 120px;
            }
          }
        }
      }
    }

    &.add-field {
      .content {
        label {
          span {
            border: 0px;
            padding: 0px;
            height: auto;
            color: color(light, fontColor);
            font-weight: 700;

            .required {
              color: color(red, dark);
            }
          }
        }
      }

      .af {
        .af-title {
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          padding: 20px 0px 40px;

          &.reduce-pad-btm {
            padding-bottom: 10px;
          }
        }

        .af-subtitle {
          color: color(purple, dark);
          font-size: 21px;
          border-bottom: 1px solid #b6c5cb;
          line-height: 21px;
          margin-bottom: 10px;
          padding-bottom: 10px;
        }

        .af-blurb {
          font-size: 14px;
          color: color(grey, base);
          text-align: center;
          padding-bottom: 20px;
        }

        .custom-dtp {
          input {
            margin-right: 20px;
            margin-top: 5px;
            width: 200px;
          }
        }

        .textarea-label {
          font-size: 14px;
          display: inline-block;
          margin: 10px 0;
          font-weight: 700;
        }

        .TextArea {
          label {
            textarea {
              padding: 5px;
              height: 120px;
              line-height: 1.4;
              border: 1px solid color(grey, light);
              border-radius: 8px;
            }
          }
        }

        .af-actions {
          display: flex;
          flex-direction: row-reverse;
          flex: 1;
          align-items: center;
          margin-top: 40px;

          .Button {
            margin-left: 20px;

            &:last-child {
              button {
                background-color: #fff;
                color: #55676e;
                border: 2px solid #55676e;

                &:hover {
                  color: color(purple);
                  border: 2px solid color(purple);
                }
              }
            }
          }
        }

        .Dropdown,
        .TextField {
          margin-top: 20px;
        }
      }
    }
  }
}
