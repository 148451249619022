@import '../../../styles/variables.scss';

.ModalScrollContainer {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: min-content;

  @include thin-scroller-y;
  .modal-content {
  }
}

@media (max-width: 680px) {
  .ModalScrollContainer {
    min-height: unset; // GIG-1619: Needed to remove min-content. Scrolling seems to break on Safari Mobile when using min-content.
    min-height: calc(100% - 110px);
  }
}
