@import '../../styles/variables.scss';

.GroupAdmin {
  display: flex;
  flex-direction: row;
  width: 100%;

  .section {
    width: 100%;
    flex: 1;
    padding: 25px;

    &.schedule-wrap {
      padding: 0px;

      .section-wrap {
        .section-title {
          margin: 25px 25px 0px 25px;
        }

        .section-inner {
          margin: 0px 55px 55px 55px;
        }
      }
    }

    .section-wrap {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 100%;
      padding: 0px;

      .section-title {
        padding: 20px 30px;
        font-size: 32px;
        font-weight: 700;
        display: flex;
        height: 40px;

        .title-wrap {
          flex: 1;
        }

        .section-inner-title {
          flex: 1;
        }
      }

      .section-kpi {
        margin: 0px 30px 30px;
      }

      .section-inner {
        margin: 0px 30px 30px;
        padding: 40px;
        border-radius: 15px;
        background-color: color(light, secondaryBg);
        flex: 1;
        display: flex;

        .settings-item {
          .Dropdown {
            margin-bottom: 10px;

            .input-wrap {
              border-radius: 8px;
              background-color: color(light, secondaryBg);
            }
          }

          .quill {
            background-color: color(light, secondaryBg);
          }
        }
      }
    }

    .Campaigns {
      display: flex;
      flex-direction: column;
      margin: 0 20px;

      .title-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        font-size: 24px;
        color: color(light, fontColor);
        font-weight: 500;
      }

      .campaigns-list {
        padding: 15px;
        background-color: #fff;
        height: 100%;
        flex: 1;
        border-radius: 15px;

        &.no-padding {
          padding: 0;
        }

        .empty-state {
          padding: 40px 20px;
          color: color(grey);
          text-align: center;
        }

        .inner-tabs {
          display: flex;
          flex-direction: row;
          width: 100%;

          .inner-tab {
            width: 25%;
            text-align: center;
            padding: 20px;
            font-weight: 500;
            color: #88949a;
            border-bottom: 1px solid #eaeff0;
            cursor: pointer;

            &.active {
              color: color(light, fontColor);
              border-bottom: 3px solid color(purple, dark);
            }
          }
        }

        .inner-wrapper {
          padding: 15px;
        }

        .campaign-actions-row {
          display: flex;
          margin: 15px 0px;
          flex-direction: row;
          justify-content: space-between;

          .sort {
            background-color: #f5f6f7;
            display: flex;
            color: #88949a;
            height: 48px;
            width: 48px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            text-align: center;
            cursor: pointer;
          }

          .TextField {
            .input-wrap {
              width: 250px;
              background-color: #f5f6f7;

              input {
                font-size: 14px;
              }

              i {
                color: #000;
                font-size: 12px;
              }
            }
          }
        }

        table {
          margin-top: 40px;
          width: 100%;
          text-align: left;
          border-collapse: collapse;

          &.trans-table thead > tr {
            height: 60px;
            background-color: #eaeff0;

            th:first-of-type {
              padding-left: 8px;
            }

            td:first-of-type {
              padding-left: 8px;
            }
          }
          &:not(.trans-table) thead > tr {
            height: 60px;
            background-color: #eaeff0;

            th:first-of-type {
              padding-left: 5px;
              text-align: center;
            }
          }

          tr {
            height: 60px;
            overflow: auto;
            cursor: pointer;

            td {
              .Portrait {
                padding: 10px;
              }

              span {
                height: 25px;
                width: 110px;
                border-radius: 10px;
                padding: 5px;
                text-transform: capitalize;

                &.Approved,
                &.active {
                  background-color: #eafcf5;
                  color: #067f5c;
                }

                &.Declined,
                &.closed {
                  color: #cf2035;
                  background-color: #fce9ea;
                }

                &.Pending,
                &.draft {
                  background-color: #f3f4f9;
                  color: #686c8b;
                }
              }
            }
          }

          td {
            border-bottom: 1px solid #eaeff0;

            .is-default {
              margin: 0 auto;
              border-radius: 50%;
              height: 15px;
              width: 15px;
              border: 2px solid #809198;
              cursor: pointer;

              &.active {
                border: 3px solid color(purple, dark);
              }
            }
          }
        }
      }
    }
  }

  .LocationManagement,
  .DiscountCodeManagement {
    flex: 1;
  }
}

@media (max-width: 823px) {
}
