@import '../../styles/variables.scss';

.Gig {
  display: flex;
  width: 100%;
  flex: 1;
  padding: 60px 0;
  position: relative;
  justify-content: center;
  background-color: color(light, shadow);

  .Modal {
    .content {
      @include kambeoScroll;
      padding: 0;
      .Login {
        background-image: none;

        &:before {
          content: none;
        }
      }

      .VolunteerApplicationForm {
        height: 617px;
        overflow: auto;

        h2 {
          font-size: 18px;
          padding: 20px 0;
        }

        .application-form-header {
          display: flex;
          gap: 25px;
          align-items: center;
          padding: 40px 0;
          border-bottom: 1px solid color(grey, lightester);
          position: sticky;
          top: 0;
          background: #fff;
          z-index: 3;
          transition: position 250ms ease-in-out;

          .title-container {
            h1 {
              font-size: 22px;
              margin: 0;
              padding: 0;
            }
            div {
              font-size: 14px;
              margin-top: 5px;
              color: #959799;
            }
          }
        }
      }

      .details-row {
        margin-bottom: 40px;
      }
    }
  }

  .gig-content {
    display: flex;
    max-width: $wrap-size;
    flex: 1;

    .inactive-admin {
      padding: 20px;
      margin: 20px 40px 0;
      background-color: color(red, light);
      color: color(red, dark);
      border-radius: 24px;

      span {
        color: color(purple, dark);

        &:hover {
          cursor: pointer;
          color: color(purple);
        }
      }
    }

    .inactive-status-bar {
      text-align: center;
      padding-top: 40px;
      color: color(grey);
    }

    .actions-column {
      margin-top: 16px;
      width: 100%;

      .menu {
        width: 100%;
        cursor: pointer;

        .interested-container {
          padding: 10px 0;
          width: 100%;
          text-align: center;
          background: color(light, secondaryBg);
          border: 1px solid color(grey, empty2);
          border-radius: 22px;
          color: color(grey, dark);
          transition: all 0.2s;

          i {
            margin-right: 8px;
          }

          &:hover {
            background: color(grey, pale);
          }
        }
      }

      .Button,
      .menu {
        flex: 1;
        margin-bottom: 16px;

        &:last-of-type {
          margin-bottom: unset;
        }

        button {
          width: 100%;
          max-width: unset;
        }
      }
    }
  }
}

@media (max-width: 1240px) {
  .Gig {
    padding-bottom: 2px;

    .gig-content {
      flex-direction: column;
      border-radius: 0;
      margin-top: 20px;
      justify-content: center;
      align-items: center;
      width: 100%;

      .actions-column {
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 820px) {
  .Gig {
    .Modal .content .details-row {
      margin: 20px 0;

      .details-col {
        margin-right: 0px;
      }
    }

    .Modal .content .VolunteerApplicationForm {
      height: 100%;
    }
  }
}

@media (max-width: 680px) {
  .Gig {
    .PageHeader .display-name-container .display-name {
      width: 280px;
    }
  }
}
