@import '../../styles/variables.scss';

@keyframes tooltip {
  to {
    opacity: 0.9;
    transform: translate(5px, 0);
  }
}
.dark {
  .CreateGroup {
    .inner {
      p {
        color: color(dark, fontColor);

        span {
          &:hover {
            color: color(dark, fontColor);
          }
        }
      }

      .row,
      .dob {
        .col {
          .TextField {
            input {
              background-color: color(dark, primaryBg);
            }
          }
        }
      }
    }
  }
}

.light {
  .CreateGroup {
    .inner {
      p {
        color: color(light, fontColor);

        span {
          &:hover {
            color: color(light, fontColor);
          }
        }
      }

      .row,
      .dob {
        .col {
          .TextField {
            input {
              background-color: color(light, primaryBg);
            }
          }
        }
      }
    }
  }
}

.CreateGroup {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-image: url('../../assets/overview.png');
  background-size: cover;

  .curve-wrap {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0.1;

    .curve,
    .bottom-fill {
      flex: 1;
      background-color: color(dark, fontColor);
    }

    .curve {
      position: static;
    }
  }

  &:before {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #008599 0%, #4d56c7 84%, #734fd5 100%) 0% 0%
      no-repeat padding-box;
    opacity: 0.75;
  }

  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;

    .Loader {
      font-size: 24px;
    }
  }

  .CreateGroup-inner {
    position: relative;
    background-color: color(dark, fontColor);
    width: 100%;
    max-width: 600px;
    margin: 0px auto;
    padding: 40px;
    border-radius: $border-radius;

    .actions {
      width: 100%;
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row-reverse;
      padding: 35px 0px 0px;

      .Button {
        padding-left: 20px;
      }
    }

    .form-wrap {
      padding: 0px;
      flex: 1;

      .form-page {
        min-height: 300px;
        padding: 30px 0px 0px 0px;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h2 {
          font-weight: 400;
          color: color(purple, dark);
          flex: 1;
          width: 100%;
          margin: 0px 0px 10px 0px;
          padding: 0px;
          font-size: 24px;
          line-height: 42px;
          border-bottom: 1px solid color(blue, lightest);
        }

        .disclaimer {
          margin-top: 0.5rem;
          display: block;
          line-height: 1.5;

          a {
            text-decoration: none;
            font-weight: bolder;
            color: color(grey, base);
          }
        }

        .row {
          flex: 1;
          width: 100%;
          padding-top: 18px;

          &.names {
            display: flex;
            .TextField {
              width: 50%;
              &:last-of-type {
                margin-left: 15px;
              }
            }
          }
          &.pwd-row {
            position: relative;

            &:hover {
              i {
                display: inline-block;
              }
            }

            i {
              display: none;
              cursor: pointer;
              font-size: 24px;
              position: absolute;
              right: 15px;
              top: 10px;
            }
          }

          &.info-row {
            position: relative;

            .info-section {
              position: absolute;
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
              align-content: center;
              top: 18px;
              right: 0px;
              background-color: color(grey, lighter);

              i {
                cursor: pointer;

                &:hover + .info-text {
                  display: initial;
                  animation: tooltip 300ms ease-out forwards;
                }
              }

              .info-text {
                display: none;
                font-size: 12px;
                margin-right: 8px;
                animation: tooltip 300ms ease-out forwards;
              }
            }
          }
        }

        .TextField {
          label {
            border-radius: 7px;
          }
        }
      }
    }

    .logo {
      max-width: 150px;
      margin: 0px auto 30px;
    }

    p {
      font-size: 21px;
      max-width: 320px;
      margin: 0px auto 40px;
      text-align: center;
      font-weight: 500;
    }

    h1 {
      text-align: center;
      font-size: 32px;
      margin: 0px;
      padding-bottom: 25px;
    }

    .dob {
      display: flex;
      flex: 1;
      max-width: 620px;
      margin: 0px auto;
      padding-bottom: 20px;

      .col {
        flex: 1;

        .TextField {
          margin-right: 20px;
        }

        &:last-child {
          .TextField {
            margin-right: 0px;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .CreateGroup {
    .CreateGroup-inner {
      margin: 20px;
    }
  }
}

@media (max-width: 900px) {
  .CreateGroup {
    .CreateGroup-inner {
      .form-wrap {
        .form-page {
          .row {
            &.names {
              display: initial;
              flex: 1 1;

              .TextField {
                width: 100%;
                padding-top: 18px;

                &:last-of-type {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
