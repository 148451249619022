@import '../../styles/variables.scss';

.PopupMenu-Volunteer-Card-bottom,
.PopupMenu-Volunteer-Card {
  margin-left: -70px;
  margin-top: 4px;
}

.PopupMenu-Volunteer-Card-Company {
  max-width: 260px;
  width: 100%;
}

.VolunteerCard {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  background: color(light, secondaryBg);
  padding: 24px;

  &.MANAGE {
    margin: 16px 16px 16px 0;
    width: 272px;
    height: 249px;

    &.GRID {
      .card-content {
        margin-top: 0px;
        padding-left: 12px;
        padding-top: 10px;
      }
    }

    &.LIST {
      height: 151px;
      width: auto;
      margin: 16px 0;
      border-radius: unset;
      box-shadow: unset;
      flex-direction: row;
      padding: 0;

      .header-section {
        width: auto;
      }

      .volunteer-card-content {
        margin-top: 0;
        padding-bottom: 0;
        border-bottom: 2px solid color(grey, pale);

        .card-title {
          width: 100%;
          height: 36px;
          max-height: 36px;
          min-height: 36px;
          align-items: flex-start;
          justify-content: space-between;

          a {
            display: block;
            -webkit-line-clamp: unset;
            -webkit-box-orient: unset;
            white-space: nowrap;
            max-width: 900px;
            width: max-content;
            flex-grow: 0;
          }
        }

        .content-items {
          margin-top: 0;
        }

        .card-bottom {
          justify-content: flex-end;
          margin-top: -36px;
          position: absolute;
          right: 0;
          top: 120px;
        }
      }

      .bottom-menu {
        margin-left: 8px;

        i {
          margin-top: 4px;
        }
      }
    }
  }

  &.HUB_ONLY,
  &.HUB_PARTNER {
    max-width: 298px;
    height: 319px;
    padding: 32px;
    width: 100%;

    .volunteer-card-content {
      .content-items {
        row-gap: 7px;
      }
    }
  }

  &.DISCOVER {
    max-width: 344px;
    width: 100%;
    height: 319px;
    padding: 32px;
    margin: 30px 8px;

    .volunteer-card-content {
      .content-items {
        row-gap: 7px;
      }
    }
  }

  &.SCHEDULE {
    width: 299px;
    height: 318px;
    border-radius: 0 0 10px 10px;

    .header-section {
      justify-content: space-between;
    }

    .volunteer-card-content {
      .content-items {
        margin-top: 17px;
        row-gap: 7px;
      }
    }

    .description {
      margin-top: 22px;
    }
  }

  .header-section {
    flex-grow: 0;
    position: relative;
    width: 100%;
    overflow: hidden;
    min-height: 44px;
    display: flex;

    .profile-img {
      height: 44px;

      .empty-img,
      img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        overflow: hidden;
        background: color(dark, borderLight);
        border: 2px solid color(grey, pale);
        margin-right: 20px;
      }
    }

    .volunteer-match {
      height: 44px;
      overflow: hidden;
      display: grid;
      place-items: center;
      background: color(light, secondayBg);
      margin-left: auto;
    }
  }

  .volunteer-card-content {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .card-title {
      display: flex;
      font-family: $raleway;
      width: max-content;
      min-height: 48px;
      max-height: 48px;
      height: 48px;
      align-items: center;
      overflow: hidden;
      justify-content: space-between;

      &.two-lines {
        a {
          @include truncate(2);
        }
      }

      &.with-menu {
        display: flex;
        width: 100%;

        .menu {
          border-radius: 50%;
          cursor: pointer;
          margin: 0;
          flex-grow: 0;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 0.2s;

          &:hover {
            background: color(grey, pale);
          }

          i {
            font-size: 26px;
          }
        }
      }

      a:hover {
        text-decoration: none;
        color: color(purple, dark);
      }

      a {
        width: max-content;
        font-weight: 800;
        font-size: 16px;
        max-width: 223px;
        flex-grow: 1;
        text-decoration: none;
        color: color(blue, space);
        @include truncate(1);
      }
    }

    .group-causes {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 15px;
      // position: absolute;
      // top: 90px;

      .areas {
        color: color(purple, grey);
        font-family: $lato;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
      }

      .no-areas {
        color: #d0d1d1;
        font-family: $lato;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
      }

      .causes-container {
        display: flex;
        flex-direction: row;
        margin-right: 10px;
        margin-left: 5px;

        &:nth-child(1) {
          z-index: 1;
        }
        &:nth-child(2) {
          z-index: 2;
        }
        &:nth-child(3) {
          z-index: 3;
        }
        &:nth-child(4) {
          z-index: 4;
        }

        .Portrait,
        .empty-portrait {
          margin-left: -7px;
        }

        .Portrait {
          margin-left: -7px !important;

          .inner {
            background-color: white;

            span {
              display: flex !important;
              justify-content: center;
              align-items: center;

              img {
                position: relative !important;
                width: 17px !important;
                height: 17px !important;
                // left: 5px;
                // top: 5px;
              }
            }
          }
        }

        .empty-portrait {
          background: #ffffff;
          border: 2px solid #f5f5f5;
          box-sizing: border-box;
          width: 34px;
          height: 34px;
          border-radius: 15px;
          display: flex;
          align-items: center;
          font-family: $lato;
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          justify-content: center;
          color: color(grey, light);
          z-index: 5;
        }

        .no-causes {
          border-radius: 50%;
          width: 30px;
          height: 30px;
          background-color: #e9e9e8;
          margin-left: -10px;
          border: 2px solid #f5f5f5;

          &:nth-of-type(1) {
            z-index: 1;
          }
          &:nth-of-type(2) {
            z-index: 2;
          }
          &:nth-of-type(3) {
            z-index: 3;
          }
          &:nth-of-type(4) {
            z-index: 4;
          }
          &:nth-of-type(5) {
            z-index: 5;
          }
        }
      }
    }

    .description {
      @include truncate(2);
      margin-top: 12px;
      height: 36px;

      &.no-desc {
        p {
          color: color(grey, border);
        }
      }

      p {
        margin: 0;
        font-family: $lato;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: color(purple, grey);
      }
    }

    .content-items {
      font-family: $lato;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: color(purple, grey);
      margin-top: 19px;
      row-gap: 4px;
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        height: 18px;
        align-items: center;

        a {
          @include truncate(1);
          max-width: 180px;
        }

        &.owner {
          a {
            text-decoration: none;
            color: color(purple, grey);
            transition: color 0.2s;

            &:hover {
              color: color(purple, dark);
            }
          }
        }

        .icon {
          margin-bottom: 0;
          margin-right: 12px;
          align-items: center;
          justify-content: center;
          display: flex;
          width: 18px;

          img {
            width: 18px;
            height: 18px;
          }

          i {
            margin-top: 3px;
          }
        }
      }
    }

    .card-bottom {
      margin-top: 24px;
      flex-grow: 0;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label-wrap {
        display: flex;
        column-gap: 8px;
        align-items: center;
        height: 29px;

        .volunteer-match {
          height: 29px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid color(dark, borderLight);
          padding: 0 6px;
          border-radius: 8px;
        }

        .state-label {
          position: relative;
          border-radius: 8px;
          padding: 6px 12px;
          font-weight: 700;
          text-align: center;
          font-size: 14px;
          cursor: default;
          width: fit-content;

          &.active,
          &.running {
            background-color: color(green, fade);
            color: color(green, soft);
          }

          &.published {
            background-color: #eaeaf8;
            color: #6565d3;
          }

          &.draft,
          &.pending {
            background-color: #fef8ed;
            color: #f1b94d;
          }

          &.undefined {
            display: none;
          }

          background-color: color(light, primaryBg);
          color: color(grey, base);
        }
      }
    }

    .bottom-menu {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.2s;
      border-radius: 18px;

      &:hover {
        cursor: pointer;
        background: color(grey, pale);
      }

      i {
        font-size: 24px;
      }
    }

    .actions-container-schedule {
      display: flex;
      flex-grow: 1;
      align-items: flex-end;
      justify-content: space-between;

      .Button {
        width: 100%;

        button {
          width: 100%;
          max-width: unset;
        }
      }
    }

    .actions-container {
      display: flex;
      flex-grow: 1;
      align-items: flex-end;
      column-gap: 10px;

      .menu {
        width: 100%;

        .interested-container {
          padding: 10px 0;
          width: 300px;
          text-align: center;
          background: color(light, secondaryBg);
          border: 1px solid color(grey, empty2);
          border-radius: 22px;
          color: color(grey, dark);
          transition: all 0.2s;

          i {
            margin-right: 8px;
          }

          &:hover {
            background: color(grey, pale);
          }
        }
      }

      .Button {
        button {
          width: 100%;
          background-color: unset;
          color: color(grey, dark);
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $lato;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          padding: 12px 38px;
          cursor: pointer;
          transition: all 0.2s;
          border-radius: 22px;
          text-decoration: none;
          border: 1px solid color(grey, empty2);

          &:hover {
            color: color(plum, dark);
            background-color: color(grey, pale);
          }

          &:disabled {
            .text {
              color: color(grey, border);
            }
          }

          &.follow {
            i {
              font-size: 16px;
              transform: rotate(-90deg);
            }
          }
        }

        i {
          margin-top: 3px;
          font-size: 14px;
          margin-right: 15px;
        }
      }

      &.fr,
      &.es {
        button {
          width: 150px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .VolunteerCard {
    &.HUB {
      width: 298px;

      .actions-container {
        justify-content: flex-start;

        .Button {
          button {
            padding: 12px 24px;
          }
        }
      }
    }

    &.SCHEDULE {
      width: auto;
    }
  }
}
