@import '../../../../styles/variables.scss';

@keyframes slideIn {
  0% {
    transform: translateX(-500px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

.GroupSelection {
  #scrollableDiv {
    max-height: calc(100vh - 316px);
    overflow-y: auto;

    .no-results {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 48px 0;
      img {
        width: 176px;
        height: auto;
      }

      .no-result-message {
        font-weight: 900;
        font-size: 16px;
        text-align: center;
        margin-top: 32px;
      }
      .no-result-link {
        margin-top: 32px;
        font-weight: 600;
        font-size: 16px;
        text-decoration-line: underline;
        color: #a9aaac;
      }
    }
  }
  .filter-wrapper {
    display: flex;
    flex-direction: row;

    .TextField {
      margin-right: 20px;

      .input-wrap {
        border-radius: 38px;
        margin-top: 25px;

        input {
          height: 32px;
        }
      }
    }

    .filter-dropdown {
      margin-right: 20px;
      min-width: 205px;

      .input-wrap {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 16px;
        color: color(light, fontColor);
        padding: 0 23px 0 16px;

        .menu-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.3s ease-in-out;

          &.show {
            transform: rotate(180deg);
          }
        }

        .label {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          img {
            width: 12px;
            height: 12px;
            margin-right: 14px;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }

      label {
        z-index: 1;
        bottom: -11px;
        left: 9px;
        background-color: white;
        color: color(grey, dark);
        font-weight: normal;
        position: relative;
        padding: 5px;
        width: fit-content;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
  .list-item {
    width: 100%;
    padding: 20px 30px;
    margin-bottom: 14px;
    border: 1px solid #e9e9e8;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
    .item-hub-list {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      .hub-image:hover {
        cursor: pointer;
      }
      .hub-counter {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 11px;
        color: #898989;
        border: 2px solid #f5f5f5;
        border-radius: 15px;
      }
    }
    .title-section {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      .title {
        font-weight: 800;
        font-size: 16px;
        margin-bottom: 4px;
      }
      .charity {
        font-weight: 500;
        font-size: 12px;
        color: #959799;
      }
    }
    &.selected {
      border: 1px solid color(purple, dark);
    }
  }
  .FormSectionHeader {
    .header-txt {
      font-weight: 700;
    }
  }

  .Loader {
    display: flex;
    justify-content: center;
    font-size: 36px;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 35px;
    padding-left: 0;
    list-style: none;

    li {
      animation: slideIn 250ms ease-in-out forwards;
      position: relative;
      text-align: center;
      margin: 10px 15px;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        box-shadow: $box-shadow;
        margin: 0 auto;

        i {
          font-size: 28px;
          color: color(grey, lighter);
        }

        &:hover {
          cursor: pointer;
          background-color: color(grey, lightest);
        }
      }

      p {
        font-weight: 700;
        font-size: 14px;
        max-width: 80px;
        margin: 15px 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &.selected {
        i {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          position: absolute;
          right: -5px;
          top: 0px;
          background-color: color(purple, dark);
          border-radius: 50%;
          color: white;
          font-size: 14px;

          &:hover {
            cursor: pointer;
          }
        }
        .Portrait {
          .inner {
            transition: none;
            border: 3px solid color(purple, dark);
          }
        }
      }
    }
  }
}
