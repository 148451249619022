@import '../../../styles/variables.scss';

.Marker {
  position: absolute;
  font-size: 20px;

  &:hover {
    cursor: pointer;
  }
}
