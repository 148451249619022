@import '../../styles/variables.scss';

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.Flow {
  display: flex;
  background-color: color(cream, light);
  width: 100%;

  .google-attribution {
    margin: 5px 5px 0 0;
    align-self: flex-end;
  }

  .fundraise,
  .volunteer,
  .flow-inner,
  .createStore,
  .createAuction,
  .createEvent,
  .createGig,
  .donations,
  .findGroups,
  .findEvents,
  .createGroup {
    display: flex;
    flex-direction: row;
    padding: 10% 15%;
    padding-top: 5%;
    max-height: 80%;
    width: 100%;

    .mobile-actions {
      display: none;
      background: #fff;
      width: 100%;
      justify-content: flex-end;
      align-self: flex-end;
      justify-self: flex-end;
      padding-top: 15px;
      margin-top: auto;

      .Button {
        Button {
          width: 195px;
          box-shadow: $box-shadow;
        }

        &:last-of-type {
          Button {
            margin-left: 15px;
          }
        }
      }
    }
    .image-section {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      align-items: flex-end;
      // padding-left:9%;

      .section-img.any {
        display: none;
      }

      .section-img {
        background-size: contain;
        background-repeat: no-repeat;
        width: 410px;
        height: 304px;
        margin-top: 60px;
        margin-bottom: 20px;
        background-position-y: bottom;

        &.donations {
          background-image: url('../../assets/flows/section-images/accept-donations-flow.svg');
        }

        &.createEvent {
          background-image: url('../../assets/flows/section-images/create-event-flow.png');
        }

        &.createGig {
          background-image: url('../../assets/flows/section-images/post-gig-flow.png');
        }

        &.createStore {
          background-image: url('../../assets/flows/section-images/create-store-flow.png');
        }

        &.createAuction {
          background-image: url('../../assets/flows/section-images/create-auction-flow.png');
        }

        &.findGroups {
          background-image: url('../../assets/flows/section-images/donate-flow.png');
        }

        &.volunteer {
          background-image: url('../../assets/flows/section-images/volunteer-flow.png');
        }

        &.findEvents {
          background-image: url('../../assets/flows/section-images/find-events-flow.png');
        }
      }

      .features.mt-unset {
        margin-top: unset;
      }

      .features {
        margin-top: auto;
        width: 100%;
        h2 {
          font-family: $raleway;
          font-weight: 400;
          letter-spacing: 1.2px;
          font-size: 20px;
          color: color(light, fontColor);
        }
        ul {
          list-style-type: none;
          padding-left: 0;
          margin: 0;

          li {
            font-size: 15px;
            color: #829096;
            margin: 20px 0;
            display: flex;

            .label {
              line-height: 32px;
            }

            &:last-of-type {
              margin-bottom: 10px;
            }

            .checkmark {
              background-color: color(cream, lighter);
              border-radius: 50%;
              width: 32px;
              height: 32px;
              padding: 5px;
              box-sizing: border-box;
              margin-right: 10px;
            }
          }
        }
      }
    }

    .image-section,
    .form-section {
      flex: 1;
      width: 50%;
      display: flex;
    }

    .form-section {
      display: flex;
      align-items: center;
      flex-direction: column;
      min-width: 50%;
      margin-right: 15%;

      .description-text-field {
        textarea {
          padding: 10px 8px;
          text-indent: 0px;
        }
      }

      .TextField {
        span.error_text {
          padding-top: 5px;
          background: #f5f6f7;
        }
      }

      .TextField,
      .Dropdown {
        .input-wrap {
          background: #fff;
        }
        label {
          background-color: #f5f6f7;
          font-size: 16px;
          color: #52646c;
          span {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
      .steps-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        max-height: 150px;
        min-height: 60px;

        .entity-title {
          color: color(purple, dark);
          margin-bottom: 15px;
        }

        .step-header-title {
          min-height: 20px;
          white-space: pre;
        }

        .step-progress {
          background: #d2e6ea;
          height: 10px;
          width: 100%;
          margin: 20px 0 25px 0;

          .bar {
            display: flex;
            align-items: center;
            position: relative;
            height: 10px;
            background-color: color(purple, dark);
            width: 100%;

            .bar-inner {
              height: 100%;
              width: 10%;
              background-color: color(purple, dark);
            }
          }
        }

        div:first-of-type {
          display: flex;
          width: 100%;
          justify-content: space-between;

          h2,
          span {
            display: flex;
          }

          h2 {
            color: color(purple, dark);
            font-size: 32px;
            margin: 0;
          }

          span {
            font-weight: 700;
            color: color(light, fontColor);
            font-size: 13px;
            align-items: center;
          }
        }
      }

      form {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }
      .form-step {
        display: flex;
        flex-direction: column;
        width: 93%;
        border-radius: 8px;
        border: 1px solid #7abfc9;
        padding: 20px;
        animation: fadein 0.5s linear 1 forwards;

        &.fundraise {
          p {
            margin: 10px;
            font-weight: 500;
          }

          .TextField {
            margin: 10px 0;
          }
        }

        &.fundraiser-tickets {
          max-height: 300px;
          overflow-y: auto;

          .ql-editor {
            padding: 0;

            p {
              margin: 5px 0 0 0;
            }
          }
        }

        .fundraise-ticket {
          background: color(light, secondaryBg);
          border-radius: 8px;
          margin: 5px 0;

          &:hover {
            cursor: pointer;
            border: 1px solid color(grey, dark);
          }

          &.selected {
            border: 2px solid color(purple, dark);
          }

          .fundraise-ticket-inner {
            padding: 15px;
            display: flex;

            .portrait-wrap {
              flex: 1;
              max-width: 60px;
            }

            .fundraise-ticket-info-wrap {
              flex: 2;
              flex-direction: column;
              margin-top: 4px;

              .title {
                font-size: 16px;
                font-weight: 500;
              }

              .price {
                color: color(purple, dark);
              }
            }

            .fundraise-ticket-add-wrap {
              flex: 1;
              justify-content: flex-end;

              .Button {
                button {
                  padding: 5px;
                  background: transparent;
                  color: color(grey, base);

                  .icon {
                    margin-top: 3px;
                  }
                }
              }

              &.selected {
                .Button {
                  button {
                    color: color(purple, dark);
                  }
                }
              }
            }
          }
        }

        .TextArea {
          label {
            .placeholder {
              font-size: 16px;
              color: #52646c;
              font-weight: 700;
              margin-left: 0;
              opacity: 1;
            }

            span {
              font-size: 16px;
              color: #52646c;
              font-weight: 400;
              margin-left: 0;
              opacity: 1;
            }
          }
          textarea {
            border: 1px solid #b6c5cb;
            min-height: 75px;
            line-height: 1.5;
          }
        }

        .event-date-container {
          display: flex;
          margin: 20px 0;
          position: relative;
          z-index: 1;
          div {
            &.mr-6 {
              margin-right: 6px;
            }
            .custom-dtp {
              input {
                margin-right: 20px;
                margin-top: 5px;
                width: 100%;
              }
            }

            label {
              padding-left: 2px;
              font-size: 14px;
              color: color(grey, base);
              font-weight: 700;
            }
          }
        }

        &.hide {
          display: none;
        }

        &.group-selection {
          .Dropdown {
            margin-top: 30px;
          }
        }

        .details-row-alt {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:not(:last-of-type) {
            border-bottom: 1px solid color(grey, light);
          }
          .settings-control {
            display: flex;
            flex: unset;

            i {
              justify-self: flex-end;
            }
            span {
              font-size: 12px;
              margin-right: 5px;
              margin-top: 5px;
              color: color(grey, base);
            }
            .fad {
              font-size: 30px;
              color: color(green, neon);

              &:hover {
                cursor: pointer;
              }

              &.fa-toggle-off {
                color: color(grey, light);
              }

              &.fad.fa-toggle-on {
                &:after {
                  color: color(light, secondaryBg);
                }
              }
            }
          }
        }

        .RangeSlider {
          margin-top: 20px;
          margin-bottom: 20px;
          margin-left: 2%;
          max-width: 95%;

          .rc-slider-rail {
            height: 2px;
            background-color: color(grey, light);
          }

          .rc-slider-track {
            background-color: color(purple, base);
          }

          .rc-slider-handle {
            height: 24px;
            width: 24px;
            margin-top: -10px;
            border: solid 2px color(purple, base);
          }

          .rc-slider-tooltip-content {
            background: none;

            .rc-slider-tooltip-inner {
              background: none;
              border: none;
              color: color(grey, base);
              box-shadow: none;
            }
          }
        }

        .stripe-details {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          &.has-account {
            .Button {
              button {
                background-color: color(green, neon);
              }
            }
          }

          .Button {
            button {
              border-radius: 7px;
              background: color(purple, base);
              box-shadow: $box-shadow;
              margin-top: 4px;

              &:disabled {
                color: color(light, secondaryBg);
                opacity: 0.5;

                .text {
                  color: color(light, secondaryBg);
                }
              }

              .text {
                font-weight: 400;
                font-size: 14px;
              }

              span.icon {
                border-right: 2px solid #fff;
                padding-right: 10px;
                justify-self: flex-start;
              }
            }
          }
        }

        .details-row {
          margin: 15px 0;

          &:last-of-type {
            margin-bottom: 0;
          }

          &.margin-top {
            margin-top: 30px;
          }

          &.col {
            flex-direction: column;
          }

          .SearchableDropdown {
            &.listing {
              top: 56px;
            }
            ul {
              display: flex;
              overflow: auto;
              flex-direction: column;
              align-items: flex-start;
              flex-wrap: nowrap;
              li {
                flex: 1;
                width: 100%;
                align-items: flex-start;
              }
            }
          }
        }

        &.connect {
          p {
            font-size: 16px;
            color: #52646c;
            font-weight: 700;
            margin: 3px;
          }
        }

        .add-cause {
          flex-direction: row;
          max-height: 42px;

          .TextField {
            max-height: 42px;
            .input-wrap {
              border-radius: 0;
              border-top-left-radius: 7px;
              border-bottom-left-radius: 7px;
            }
          }
          .Button {
            button {
              border-radius: 0;
              background-color: color(purple, base);
              min-height: 43px;

              span {
                color: #fff;
              }
            }
          }
        }

        .user-causes {
          li {
            display: flex;
            justify-content: center;
            margin: 5px 15px 5px 0;
            flex-direction: row;
            background: color(light, secondaryBg);
            border-radius: 20px;
            width: auto;
            max-width: 100%;
            padding: 8px;

            span {
              display: inline-flex;
              margin-right: 5px;
            }

            i {
              color: color(brand, red);
            }
          }
        }

        .location-wrap {
          display: flex;
          flex-flow: column;
          position: relative;
          margin-top: 20px;
          font-weight: 700;

          .row {
            display: flex;
            flex-direction: row;
            margin: 5px 0;

            .TextField:first-of-type {
              margin-right: 15px;
            }
          }

          .TextField {
            i {
              color: #88949a;
            }
          }

          .search-results {
            z-index: 2;
            position: absolute;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            background: #ffffff;
            width: 97%;
            box-shadow: $box-shadow;

            .result {
              color: #88949a;
              display: flex;
              align-items: center;
              font-size: 16px;
              min-height: 30px;
              padding: 10px;

              &:last-of-type {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
              }

              &:hover {
                cursor: pointer;
                background: color(cream, dark);
              }

              i {
                padding-left: 8px;
                min-width: 35px;
              }

              &:hover {
                cursor: pointer;
              }
              &:not(:last-of-type) {
                border-bottom: 1px solid #88949a;
              }
            }
          }
        }

        &.charity {
          .TextField:not(:first-child) {
            padding-top: 12px;
          }

          flex-wrap: wrap;

          .participant-form {
            max-width: 100%;
            .detail-row {
              display: flex;

              .TextField {
                &:first-of-type {
                  flex: 2;
                }

                &:last-of-type {
                  flex: 1;
                  margin-left: 10px;
                }
              }
            }
          }
          .settings-control {
            display: flex;
            flex: unset;
            i {
              justify-self: flex-end;
            }
          }

          .details-row:first-of-type {
            max-height: 32px;

            &.strava {
              max-height: unset;
            }
          }

          .charity-wrap {
            width: 100%;

            .connected,
            a {
              background-color: color(purple, base);
              text-decoration: none;
              display: flex;
              border: 0px;
              padding: 8px 18px;
              font-size: 16px;
              border-radius: $border-radius;
              justify-content: space-around;
              align-items: center;
              color: color(dark, fontColor);
              overflow: hidden;
              width: 150px;
              min-height: 28px;
              border-radius: 7px;

              &:disabled {
                color: color(light, secondaryBg);
                opacity: 0.5;

                .text {
                  color: color(light, secondaryBg);
                }
              }

              .text {
                font-weight: 400;
                font-size: 14px;
              }

              span.icon {
                border-right: 2px solid #fff;
                padding-right: 10px;
                justify-self: flex-start;
              }
            }

            .connected {
              background-color: color(green, neon);
            }
          }

          .location-wrap {
            position: relative;

            .row {
              display: flex;
              flex-direction: row;
              margin: 5px 0;

              .TextField:first-of-type {
                margin-right: 15px;
              }
            }

            .TextField {
              i {
                color: #88949a;
              }
            }

            .search-results {
              z-index: 2;
              position: absolute;
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
              background: #ffffff;
              width: 97%;
              box-shadow: $box-shadow;

              .result {
                color: #88949a;
                display: flex;
                align-items: center;
                font-size: 16px;
                min-height: 30px;
                padding: 10px;

                &:last-of-type {
                  border-bottom-left-radius: 8px;
                  border-bottom-right-radius: 8px;
                }

                &:hover {
                  cursor: pointer;
                  background: color(cream, dark);
                }

                i {
                  padding-left: 8px;
                  min-width: 35px;
                }

                &:hover {
                  cursor: pointer;
                }
                &:not(:last-of-type) {
                  border-bottom: 1px solid #88949a;
                }
              }
            }
          }
        }

        &.is-event-step {
          .details-row {
            border-bottom: none;
          }
          div:last-of-type {
            span {
              font-size: 12px;
            }
          }
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          padding-left: 0;
          list-style-type: none;
          margin: 0;
          margin-top: 10px;
          font-weight: 700;

          &.listing {
            margin-top: 23px;
          }

          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            color: #52646c;
            width: 102px;
            margin: 5px 0;
            text-align: center;
            cursor: pointer;

            .list-circle {
              display: flex;
              border-radius: 50%;
              background: color(cream, lighter);
              height: 72px;
              width: 72px;
              justify-content: center;
              align-items: center;
              margin: 8px 0;
              position: relative;

              i {
                color: color(purple, dark);
                font-size: 28px;
              }

              img {
                position: absolute;
                top: 0;
                right: 0;
              }

              &:hover,
              &.active {
                background: color(purple, dark);

                i {
                  color: color(cream, lighter);
                }
              }
            }
          }
        }

        .add-cause {
          flex-direction: row;

          .TextField {
            .input-wrap {
              border-radius: 0;
              border-top-left-radius: 7px;
              border-bottom-left-radius: 7px;
            }
          }
          .Button {
            button {
              border-radius: 0;
              background-color: color(purple, base);
              min-height: 43px;

              span {
                color: #fff;
              }
            }
          }
        }

        .user-causes {
          li {
            display: flex;
            justify-content: center;
            margin: 5px 15px 5px 0;
            flex-direction: row;
            background: color(light, secondaryBg);
            border-radius: 20px;
            width: auto;
            max-width: 100%;
            padding: 8px;

            span {
              display: inline-flex;
              margin-right: 5px;
            }

            i {
              color: color(brand, red);
            }
          }
        }

        h2 {
          font-family: $raleway;
          font-size: 19px;
          font-weight: 500;
          margin: 5px 0;

          &:first-of-type {
            margin-top: 0;
          }
        }

        .modal-invoke {
          color: color(purple, dark);
          cursor: pointer;
        }

        &.charity {
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .details-row {
            margin-top: 10px;
          }

          &.fundraise {
            p {
              margin-left: 0;
            }
            i {
              justify-self: flex-end;
              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }
          }

          .auth-sig {
            .sig-label {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              max-width: 300px;

              i {
                padding: 0 5px;
              }
            }

            p {
              font-size: 16px;
              color: #52646c;
              font-weight: 400;
              margin-bottom: 0;
              margin-top: 7px;
              padding-bottom: 4px;
            }

            span {
              .FileUpload {
                max-height: 15px;
                label {
                  padding: 0 0 0 5px;
                  background: none;
                  color: color(purple, dark);
                  font-size: 12px;
                  margin: 0;
                  display: flex;
                  align-items: flex-start;
                  text-decoration: underline;
                  max-height: 15px;
                }
              }
            }

            .info {
              font-size: 12px;
              color: #52646c;
              display: flex;
              padding-left: 5px;
            }
            canvas {
              background: #fff;
              border: 1px solid color(grey, light);
              border-radius: 7px;
            }

            .sig-actions {
              display: flex;
              flex-direction: row;
              .Button {
                Button {
                  background: color(purple, base);
                  color: color(light, secondaryBg);
                  border-radius: 7px;
                  box-shadow: $box-shadow;
                  margin: 10px 0;
                }
              }
              .ImageUpload {
                align-self: center;
                background: color(purple, base);
                color: color(light, secondaryBg);
                border-radius: 7px;
                box-shadow: $box-shadow;
                margin: 10px 0;

                .FileUpload {
                  label {
                    background-color: color(purple, base);
                  }
                }
              }
              .ImageUpload,
              .Button {
                margin-right: 20px;
              }
            }
          }

          .details-row {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            padding-bottom: 5px;
            border-bottom: 1px solid color(grey, light);
            margin-bottom: 10px;
            justify-content: space-between;

            .details {
              p {
                color: color(light, fontColor);
                font-size: 16px;
                margin: 0;

                &.title {
                  font-size: 20px;
                }
              }
            }

            &.border-top {
              margin-bottom: 0;
              border-bottom: 0;
              border-top: 1px solid color(grey, light);
              margin-top: 0;
              padding: 8px 0 0 0;
            }

            &.border-none {
              border-bottom: 0;
            }

            .settings-control {
              span {
                font-size: 12px;
                margin-right: 5px;
                margin-top: 5px;
                color: color(grey, base);
              }
              .fad {
                font-size: 30px;
                color: color(green, neon);

                &:hover {
                  cursor: pointer;
                }

                &.fa-toggle-off {
                  color: color(grey, light);
                }

                &.fad.fa-toggle-on {
                  &:after {
                    color: color(light, secondaryBg);
                  }
                }
              }
            }
          }
        }
      }
      .actions {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-self: flex-end;
        justify-self: flex-end;
        padding-top: 15px;

        .back {
          @include button-secondary;

          span {
            color: color(light, fontColor);
          }
        }

        .Button {
          Button {
            width: 195px;
          }

          &:last-of-type {
            Button {
              margin-left: 15px;
            }
          }
        }
      }
    }

    .Modal {
      .content {
        min-width: 300px;

        .custom-dtp {
          input {
            margin-right: 20px;
            margin-top: 5px;
          }
        }

        .skip-for-later {
          max-width: 300px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 15px;

          h1 {
            margin-bottom: 0;
          }
          .Button {
            margin: 10px 0;

            Button {
              box-shadow: $box-shadow;
            }

            &:last-of-type {
              Button {
                background: color(light, secondaryBg);
                color: color(blue, dark);

                &:hover {
                  background: color(purple, base);
                  color: color(light, secondaryBg);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1380px) {
  .Flow {
    .fundraise,
    .volunteer,
    .flow-inner,
    .createStore,
    .createAuction,
    .createEvent,
    .createGig,
    .donations,
    .findGroups,
    .createGroup {
      padding-left: 12%;
    }
  }
  .Flow .flow-inner .form-section .form-step ul li,
  .Flow .CreateStoreFlow .form-section .form-step ul li,
  .Flow .DonationFlow .form-section .form-step ul li {
    width: 102px;

    span.list-circle {
      height: 64px;
      width: 64px;
    }
  }
}

@media (max-width: 1200px) {
  .Flow {
    .fundraise,
    .createStore,
    .createAuction,
    .createEvent,
    .createGig,
    .donations,
    .findGroups,
    .createGroup {
      max-height: 100%;
      flex-direction: column-reverse;

      .form-section,
      .image-section {
        width: 100%;
        padding-left: 0;
        align-items: center;
        margin-top: 0;
        margin-bottom: 40px;
      }

      .image-section {
        display: none;
      }
    }
  }
}

@media (max-width: 700px) {
  .Flow {
    .fundraise,
    .volunteer,
    .createStore,
    .createEvent,
    .createAuction,
    .donations,
    .findGroups,
    .createGig,
    .createGroup {
      position: relative;
      flex-direction: column;
      padding: 20px;
      overflow: auto;
      overflow-x: hidden;
      padding: 0;
      margin-bottom: 81px;

      .form-section,
      .image-section {
        width: auto;
        padding: 20px;
        align-items: center;
        margin: 0px;
      }

      .image-section {
        background-color: #fff;
        margin-bottom: 68px;
        margin-top: 70px;
        position: relative;
        width: 100%;

        .FlowInfo {
          z-index: 2;

          .section-img {
            margin: 0;
          }
        }

        .section-img {
          height: 140px;
          width: 180px;
          align-self: center;
        }

        &:before {
          content: '';
          background: color(light, secondaryBg);
          width: 100%;
          height: 143px;
          position: absolute;
          top: -63px;
          border-top-left-radius: 50%;
          border-top-right-radius: 50%;
          z-index: 2;
        }

        .features {
          ul {
            li {
              font-size: 14px;
            }
          }
        }
      }

      .form-section {
        margin-bottom: 0;
        .steps-header {
          max-height: none;
          div:first-of-type {
            h2 {
              font-size: 24px;
            }

            span {
              display: flex;
              align-items: center;
            }
          }
        }

        .form-step {
          z-index: 3;
          &.charity {
            .details-row {
              .settings-control {
                span {
                  display: none;
                }
              }
            }
            .participant-form {
              .detail-row {
                flex-direction: column;

                .TextField:last-of-type {
                  margin-left: 0;
                }
              }
            }
            .auth-sig {
              .sig-actions {
                flex-direction: column;

                .Button {
                  button {
                    width: 100%;
                    max-width: 300px;
                  }
                }

                .ImageUpload {
                  align-self: flex-start;
                  width: 100%;
                  max-width: 300px;
                  margin-right: 0;
                }
              }
            }

            .location-wrap {
              width: 300px;
              padding-left: 5px;
              .row,
              .TextField {
                flex: 1;
              }

              .row {
                .TextField {
                  flex: 1;
                  max-width: 48%;
                }
              }
            }
          }

          .stripe-details {
            .Button {
              button {
                font-size: 12px;
                padding: 0 10px;

                .text {
                  font-size: 12px;
                }

                &:disabled {
                  opacity: 0.5 !important;
                  color: color(light, secondaryBg);

                  .text {
                    color: color(light, secondaryBg);
                  }
                }
              }
            }
            .settings-control {
              span {
                font-size: 12px;
              }
            }
          }

          h2 {
            font-size: 16px;
            font-weight: 500;
          }
        }

        .actions {
          display: flex;

          &.participants {
            display: flex;
          }
        }

        .form-step {
          &.connect {
            h2 {
              margin: 0;
            }
          }
          &.hide {
            display: none !important;
          }
          .event-date-container {
            flex-direction: column;

            div {
              margin-top: 10px;
              position: relative;

              .custom-dtp {
                &:first-of-type {
                  z-index: 2;
                }
                input {
                  width: 100%;
                  text-align: center;
                }
              }
            }
          }
          &.group-flow {
            ul {
              li {
                margin: 0;
                max-width: none;
                // width:41%;
              }
              &.user-causes {
                li {
                  margin: 4px;
                }
              }
            }
          }
        }
      }

      .mobile-actions {
        display: flex;
        position: fixed;
        bottom: 0px;
        max-height: 84px;
        justify-content: center;
        align-items: center;
        padding-top: 0;
        padding: 20px 0px;
        border-top: 1px solid color(grey, lighter);
        z-index: 5;

        .back {
          .Button {
            button {
              @include button-secondary;
              margin-left: 0px;
            }
          }
        }
        .Button {
          button {
            max-width: 150px;

            &:disabled {
              cursor: not-allowed;
              opacity: 0.5;

              span {
                color: #fbfbfb;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .Flow {
    .fundraise,
    .volunteer,
    .createStore,
    .createEvent,
    .createAuction,
    .donations,
    .findGroups,
    .createGig,
    .createGroup {
      .mobile-actions {
        bottom: 71px;
      }
    }

    .fundraise {
      .image-section {
        display: none;
      }

      .form-section {
        .actions {
          .back {
            display: none;
          }
          .Button:last-of-type Button {
            display: flex;
          }
        }
        .form-step {
          &.team-select {
            .details-row {
              height: 300px;

              .listing {
                overflow-x: hidden;
                margin-top: 23px;
                max-height: 200px;
              }
            }
          }
        }
      }
    }
  }
}
