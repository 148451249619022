@import '../../../styles/variables.scss';

.GigList {
  h3 {
    margin: 0;
    padding: 0 20px 20px;
  }
}

@media (max-width: 930px) {
  .GigList {
    .group-gigs {
      padding: 0;
    }

    h3 {
      padding: 0 0 20px;
    }
  }
}

@media (max-width: 680px) {
  .GigList {
    .GigListItem {
      .content {
        flex-direction: column;
      }
    }
  }
}
