@import '../../styles/variables.scss';

.GigPayments {
  display: flex;
  flex: 1;
  flex-direction: column;

  .transaction-tabs {
    display: flex;

    div {
      flex: 1;
      cursor: pointer;
      padding-bottom: 5px;
      border-bottom: 1px solid color(grey, light);
      margin-bottom: 15px;
      text-align: center;
      font-weight: 500;
      color: color(grey, light);

      &.active {
        border-bottom: 2px solid color(purple, dark);
        color: color(light, fontColor);
      }
    }
  }

  .search {
    display: flex;
    align-items: center;

    .create {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }

    .TextField {
      max-width: 50%;

      i {
        background-color: color(light, secondaryBg);
        color: color(light, fontColor);
      }

      input {
        background-color: color(light, secondaryBg);
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    padding-top: 26px !important;
    flex: 1;

    .headers,
    .row {
      .col {
        display: flex;
        flex: 1;
        font-weight: 700;

        &:first-child {
          text-indent: 8px;
        }

        &.title {
          flex: 1;
        }

        &.portrait {
          max-width: 120px;
        }

        &.created,
        &.updated {
          width: 400px;

          span {
            font-weight: 400;
            margin-right: 8px;

            &:last-child {
              color: color(grey);
              font-weight: 400;
            }
          }
        }

        &.actions {
          justify-content: flex-end;
          padding-right: 40px;
          position: relative;

          .ContextMenu {
            display: none;
            position: absolute;
            top: 55px;
            right: 10px;
            box-shadow: $box-shadow;
            border-radius: 20px;

            li {
              cursor: pointer;

              &.default-role {
                display: none;
              }
            }

            i {
              font-size: 16px;
              padding-right: 8px;
            }

            &:hover {
              display: block;
            }
          }

          i.fa-ellipsis-h-alt {
            font-size: 24px;
            height: 40px;
            width: 40px;
            background-color: color(blue, lightest);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            &:hover {
              cursor: pointer;
              color: color(purple, dark);
            }
          }

          i.fa-ellipsis-h-alt:hover + .ContextMenu {
            display: block;
          }
        }
      }
    }

    .headers {
      background-color: color(blue, lightest);
      display: flex;
      flex: 0;
      min-height: 40px;
      height: 40px;
      line-height: 40px;
    }

    .list-inner {
      flex: 1;
      max-height: 100%;
      overflow: auto;
      position: relative;

      .list-rows {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        .empty {
          flex: 1;
          padding: 30px 20px;
          text-align: center;
          color: color(grey);
          font-size: 16px;
        }
      }

      .row {
        display: flex;
        height: 60px;
        border-bottom: 1px solid color(grey, light);

        .col {
          font-weight: 400;
          align-items: center;

          &.role {
            &:hover {
              .Dropdown {
                .input-wrap {
                  border: 1px solid color(grey, light);

                  i {
                    opacity: 1;
                  }
                }
              }
            }

            .Dropdown {
              min-width: 200px;
              transform: translateX(-10px);

              .input-wrap {
                border: 1px solid color(light, secondaryBg);

                i {
                  opacity: 0;
                }
              }
            }
          }

          &.portrait {
            justify-content: center;
            max-width: 120px;
          }

          &.capacity {
            span {
              margin-right: 8px;
              text-transform: capitalize;
              padding: 6px 10px;
              border-radius: 24px;

              @include userLabelColor;
            }
          }
        }
      }
    }
  }
}

.refund {
  padding: 15px;

  .refund-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    h1 {
      margin: 10px 0;
    }

    div {
      font-size: 16px;
      color: color(grey, light);
    }
  }

  .refund-info {
    .sub-header {
      padding-bottom: 10px;
      border-bottom: 1px solid #e6e6e6;
      color: color(purple, dark);
      margin-top: 25px;
      font-size: 18px;
    }

    .info {
      .info-part {
        margin: 15px 0;
        span {
          font-weight: 500;
        }
      }
    }

    .refund-details {
      margin-top: 15px;
      display: flex;
      flex-direction: row;

      .TextField {
        flex: 2;
        margin-right: 8px;
      }

      .Dropdown {
        flex: 1;
      }
    }

    .refund-actions {
      display: flex;
      justify-content: flex-end;
      margin: 10px 0;
    }
  }
}
