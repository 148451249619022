@import '../../styles/variables.scss';

.HourManagement {
  .no-opportunities {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .HourManagement-list {
    flex: 1;
    display: flex;
    flex-direction: column;

    .hours-container {
      width: 120px;
    }
    .search {
      display: flex;
      flex: 1;
      max-height: 64px;
      min-height: 64px;

      .TextField,
      .Dropdown {
        input,
        i,
        .input-wrap {
          overflow: hidden;
          background-color: color(light, secondaryBg);
        }
      }

      .Dropdown {
        min-width: 300px;
        &.ml-10 {
          margin-left: 10px;
        }
      }

      .TextField {
        margin: 20px 0 0 10px;
        flex: 1;
      }

      .approve-selected {
        margin: 20px 0px 0px 20px;

        .Button {
          button {
            height: 44px;
            background-color: color(green, neon);

            i {
              margin-top: 2px;
            }

            &:hover {
              background-color: color(purple, dark);
            }
          }
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      flex: 1;

      .headers,
      .row {
        .col {
          display: flex;
          flex: 1;
          font-weight: 700;

          &:first-child {
            text-indent: 8px;
          }

          &.select {
            min-width: 70px;
            max-width: 70px;
            justify-content: center;
            align-items: center;

            i {
              font-size: 22px;
              color: color(grey, light);

              &:hover {
                cursor: pointer;
              }

              &.fa-check-square {
                color: color(purple, dark);
              }

              &.no-drop-cursor {
                &:hover {
                  cursor: no-drop;
                }
              }
            }
          }

          &.name {
            .Portrait {
              margin-right: 8px;
            }
          }

          &.hours {
            &:hover {
              i {
                display: flex;
              }
            }

            .fa-pencil {
              display: none;
            }

            .TextField {
              max-width: 60px;
            }

            button {
              background-color: transparent;
              border: 0px;

              i {
                font-size: 16px;
              }

              &:focus {
                outline: 0px;
                border: 0px;
              }
            }

            .fa-pencil,
            .fa-save {
              margin-left: 10px;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }
          }

          &.verify-hours {
            justify-content: center;
            align-items: center;

            i {
              font-size: 32px;
              color: color(grey, light);

              &:hover {
                cursor: pointer;
              }

              &.fa-toggle-on {
                cursor: no-drop;
                color: color(purple, dark);
              }
            }
          }

          &.actions {
            justify-content: flex-end;
            padding-right: 60px;
            position: relative;

            .ContextMenu {
              display: none;
              position: absolute;
              top: 55px;
              right: 30px;
              box-shadow: $box-shadow;
              border-radius: 20px;

              li {
                cursor: pointer;

                &.default-role {
                  display: none;
                }
              }

              i {
                font-size: 16px;
                padding-right: 8px;
              }

              &:hover {
                display: block;
              }
            }

            i.fa-ellipsis-h-alt {
              font-size: 24px;
              height: 40px;
              width: 40px;
              background-color: color(blue, lightest);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }

            i.fa-ellipsis-h-alt:hover + .ContextMenu {
              display: block;
            }
          }
        }
      }

      .headers {
        background-color: color(blue, lightest);
        display: flex;
        flex: 0;
        min-height: 40px;
        height: 40px;
        line-height: 40px;

        .col {
          display: flex;
          flex: 1;
          font-weight: 700;

          &:first-child {
            text-indent: 8px;
          }
        }
      }

      .list-inner {
        flex: 1;
        max-height: 100%;
        overflow: auto;
        position: relative;

        .list-rows {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;

          .empty {
            flex: 1;
            padding: 30px 20px;
            text-align: center;
            color: color(grey);
            font-size: 16px;
          }

          .Loader {
            font-size: 31px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 40px;
          }
        }

        .row {
          display: flex;
          min-height: 60px;
          border-bottom: 1px solid color(grey, light);
          transition: all 150ms ease-in-out;

          .row-inner {
            display: flex;
            height: 60px;
            flex: 1;
            transition: all 150ms ease-in-out;

            &:hover {
              cursor: pointer;
              background-color: color(cream, dark);
            }
          }

          &.no-border {
            border-bottom: none;
            height: 100px;
          }

          .col {
            font-weight: 400;
            align-items: center;
          }
        }
      }
    }
  }

  .report-hours-button {
    margin-left: auto;
    background-color: color(purple, dark);
    border-radius: 20px;
    margin-left: auto;
  }
}
