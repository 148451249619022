@import '../../../../styles/variables.scss';

.ChildEventListItem {
  position: relative;
  height: 76px;
  background-color: #f5f6f7;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  border: 1px solid transparent;
  margin-bottom: 20px;
  display: grid;
  padding-left: 12px;

  grid-template-columns: 74px auto 100px 5px;
  grid-template-rows: 28px 22px 18px;

  column-gap: 10px;
  row-gap: 2px;

  .Portrait {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 3;
    margin-top: 3px;
  }

  .event-title {
    color: #012939;
    font-size: 16px;
    margin-top: 5px;
    text-decoration: none;

    grid-column-start: 2;
    grid-row-start: 1;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .event-raised {
    color: #52646c;
    font-size: 14px;

    grid-column-start: 2;
    grid-row-start: 2;

    .raised {
      color: #52646c;
      font-weight: bold;
    }
  }

  .progress-bar {
    background-color: white;
    border-radius: 6px;
    height: 11px;
    border: 2px solid #e9eef0;

    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;

    .progress-bar-fill {
      border-radius: 6px;
      height: 9px;
      margin: 1px;
      background: transparent linear-gradient(90deg, #008599 0%, #4d56c7 84%, #734fd5 100%) 0% 0%
        no-repeat padding-box;
    }
  }

  .view-event-button {
    border-radius: 10px;
    border: 2px solid #e9eef0;
    background-color: white;
    cursor: pointer;
    width: 100px;
    height: 28px;

    grid-column-start: 3;
    grid-row-start: 2;
  }

  .view-event-button-mobile {
    display: none;
  }

  .list-admin-actions {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: color(purple);
    height: 30px;
    line-height: 30px;

    ul {
      display: flex;
      list-style-type: none;
      padding: 0px;
      margin: 0px;

      li {
        color: color(dark, fontColor);
        padding: 0px 10px;

        &:hover {
          cursor: pointer;
          background-color: color(light, secondaryBg);

          i,
          span {
            color: color(purple);
          }
        }

        span {
          margin-left: 8px;
        }
      }
    }
  }

  &.admin {
    &:hover {
      border: 1px solid color(purple);

      .list-admin-actions {
        display: block;
      }
    }
  }
}

@media (max-width: 930px) {
  .ChildEventListItem {
    height: 115px;
    padding: 10px;

    grid-template-columns: 90px auto 35px;
    grid-template-rows: 35px 35px auto;

    .progress-bar {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 3;
      height: 15px;
      align-self: flex-end;

      .progress-bar-fill {
        height: 13px;
      }
    }

    .view-event-button {
      display: none;
    }

    .view-event-button-mobile {
      grid-column-start: 3;
      grid-row-start: 2;
      grid-row-end: 3;
      font-size: 25px;
      transform: translateY(-50%);
      display: initial;
      background-color: transparent;
      border: none;
    }
  }
}

@media (max-width: 415px) {
  .ChildEventListItem {
    height: 115px;
    padding: 10px;
    margin-right: 15px;

    grid-template-columns: 70px auto 35px;
    grid-template-rows: 35px 35px auto;
  }
}
