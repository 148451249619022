@import '../../../styles/variables.scss';

.PresenceIndicator {
  &.offline,
  &.online {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    left: 38px;
    border: 1px solid #ffffff;
    transition: all 100ms ease-out;
  }

  &.online {
    background-color: color(green, online);
    box-shadow: 0 0 100px color(green, online);
  }

  &.offline {
    background-color: #eb645e;
  }
}
