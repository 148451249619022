@import '../../../../styles/variables.scss';

.CauseSelectionStep {
  list-style-type: none;
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  min-height: 350px;

  .cause-item {
    font-weight: 600;
    display: flex;
    flex-flow: row wrap;
    gap: 15px;

    .img-container {
      width: 16px;
      height: 21px;
      img {
        min-width: 14px;
        max-width: 16px;
        max-height: 21px;
        align-self: center;
      }
    }
  }
}
