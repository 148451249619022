@import '../../styles/variables.scss';

.dark {
  .Checkbox {
    label {
      color: color(dark, fontColor);

      p {
        a {
          &:hover {
            color: color(dark, fontColor);
          }
        }
      }
    }
  }
}

.light {
  .Checkbox {
    label {
      color: color(light, fontColor);

      p {
        a {
          &:hover {
            color: color(light, fontColor);
          }
        }
      }
    }
  }
}

.Checkbox {
  position: relative;
  &.light {
    label {
      i {
        color: color(light, secondaryBg) !important;
      }
    }
  }
  &.disabled {
    label {
      cursor: no-drop !important;
    }
  }
  &.hide {
    visibility: hidden;
  }

  label {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    p {
      padding: 0px;
      margin: 0px;

      a {
        color: color(purple, dark);
        text-decoration: none;
      }
    }

    .fa-square,
    .fa-check-square,
    .fa-circle,
    .fa-check-circle {
      font-size: 21px;
      margin-right: 8px;
    }

    .fa-square,
    .fa-circle {
      opacity: 0.25;
    }

    .fa-check-square,
    .fa-check-circle {
      display: none;
      color: color(purple, dark);

      &.default {
        color: color(purple, dark);
      }

      &.volunteer {
        color: color(volunteer);
      }

      &.plum-dark {
        color: color(plum, dark);
      }

      &.work {
        color: color(work);
      }

      &.events {
        color: color(events);
      }

      &.groups {
        color: color(groups);
      }

      &.spaces {
        color: color(spaces);
      }
      &.hub-banner {
        color: color(purple, light);
      }
    }

    input {
      opacity: 0;
      height: 0px;
      width: 0px;
      margin: 0px;

      &:checked ~ .fa-check-square {
        display: block;
      }

      &:checked ~ .fa-check-circle {
        display: block;
      }

      &:checked ~ .fa-square {
        display: none;
      }

      &:checked ~ .fa-circle {
        display: none;
      }
    }
  }
}
