@import '../../styles/variables.scss';

.dark {
  .Login {
    .inner {
      p {
        color: color(dark, fontColor);

        span {
          &:hover {
            color: color(dark, fontColor);
          }
        }
      }

      .row,
      .dob {
        .col {
          .TextField {
            input {
              background-color: color(dark, primaryBg);
            }
          }
        }
      }
    }
  }
}

.light {
  .Login {
    .inner {
      p {
        color: color(light, fontColor);

        span {
          &:hover {
            color: color(light, fontColor);
          }
        }
      }

      .row,
      .dob {
        .col {
          .TextField {
            input {
              background-color: color(light, primaryBg);
            }
          }
        }
      }
    }
  }
}

.Modal {
  .content {
    .Login {
      background: color(light, secondaryBg);
      border-radius: $border-radius;
      min-height: 200px;
      height: auto;
      .tl-circle,
      .tr-circle,
      .bl-circle {
        display: none;
      }

      .Login-inner {
        position: initial;
        height: 100%;
        width: 100%;
        padding: 0;
        border-radius: $border-radius;
      }
    }
  }
}

.Login {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: color(cream, dark);

  .tl-circle,
  .tr-circle,
  .bl-circle {
    position: absolute;
  }

  .Login-inner {
    position: absolute;
    background-color: color(cream, light);
    width: 70vw;
    height: 70vw;
    min-height: 450px;
    min-width: 450px;
    margin: 0px auto;
    padding: 40px 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    form {
      padding: 0px 20px;
      min-width: 30vw;

      h2 {
        margin: 0 0 5px 0;
        text-align: left;
        font-size: 34px;
        font-weight: 700;
      }
      span.welcome {
        display: block;
        margin-bottom: 20px;
        color: #484b4d;
      }

      .row {
        .col {
          label {
            span {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .logo {
      max-width: 150px;
      margin: 0 auto 30px;
    }

    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .sign-in {
        font-size: 18px;
        width: 100%;

        &.Button,
        &.Button button {
          flex: 1;
          width: 100%;
          box-shadow: $box-shadow;
          border-radius: $border-radius;
        }
      }

      .continue-with-sso-button {
        &.Button,
        &.Button button {
          font-size: 18px;
          max-width: unset;
          width: 100%;
        }
      }

      .register-link {
        margin-top: 20px;
        font-size: 18px;

        span {
          font-weight: bold;
        }
      }

      .Button {
        margin: 10px 0;
      }
    }

    .links {
      display: flex;
      flex-flow: column;
      flex: 1;
      align-items: center;
      width: 100%;

      a {
        color: color(purple, dark);
        text-decoration: none;
        font-size: 14px;

        span {
          @include link-primary;
        }
      }
    }

    .row {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 620px;
      margin: 0px auto;

      .col {
        flex: 1;
        padding-bottom: 10px;

        &.pwd-col {
          position: relative;

          .forgot-pwd {
            position: absolute;
            right: 0;
            top: 0;
            padding-bottom: 4px;

            a {
              color: #88949a;
              text-decoration: none;
              font-size: 14px;
            }
          }

          &:hover {
            i.pwd-icon {
              display: inline-block;
            }
          }

          i.pwd-icon {
            color: color(grey, light);
            display: none;
            cursor: pointer;
            font-size: 24px;
            position: absolute;
            right: 15px;
            bottom: 20px;
          }
        }

        .TextField,
        .Dropdown {
          .input-wrap {
            background: color(grey, lighter);
          }
        }
      }
    }

    .dob {
      display: flex;
      flex: 1;
      max-width: 620px;
      margin: 0px auto;
      padding-bottom: 20px;

      .col {
        flex: 1;

        .TextField {
          margin-right: 20px;
        }

        &:last-child {
          .TextField {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .Login {
    .Login-inner {
      form {
        width: 75%;
        text-align: center;

        h2 {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .Login {
    .Login-inner {
      min-width: 375px;

      form .row .col label span {
        text-align: left;
      }
    }
  }
}

@media (max-width: 550px) {
  .Login {
    width: 100%;
    .Login-inner {
      form {
        width: 70%;
      }
    }

    .tl-circle,
    .bl-circle,
    .tr-circle {
      display: none;
    }
  }
}
