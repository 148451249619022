@import '../../styles/variables.scss';

.StorePurchases-list {
  display: flex;
  flex: 1;
  flex-direction: column;

  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 57px;

    .create {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    flex: 1;

    .list-inner {
      flex: 1;
      max-height: 100%;
      overflow: auto;
      position: relative;

      .list-rows {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }
    }
  }

  .Modal {
    .content {
      .purchase-item-form {
        padding: 40px;
        width: 100%;
        overflow-y: auto;

        .Portrait {
          margin-right: 20px;
        }

        .question {
          margin-bottom: 40px;

          .answer {
            width: 100%;
          }

          .label {
            padding-bottom: 20px;
            font-size: 19px;
            font-weight: 500;
          }

          .Checkbox label {
            justify-content: flex-start;
            margin-bottom: 20px;

            input {
              margin: 0px;
            }
          }
        }
      }

      .select-payment-header {
        padding-bottom: 18px;
        padding-top: 18px;
        margin-bottom: 10px;
        border-bottom: 1px solid lightgray;
      }

      .select-payment {
        padding: 35px 50px 55px 50px;

        .payment-info {
          font-size: 1.5em;
          color: color(purple, dark);
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid lightgray;
        }
      }

      .controls {
        border-top: 1px solid lightgray;
        display: flex;
        justify-content: center;

        button {
          margin: 25px;
        }

        .cancel-button {
          background-color: #ffffff;
          color: #52646c;
          box-shadow: 0px 3px 6px #7c7c7c41;
          border: 1px solid #e3e3e3;
          border-radius: 23px;

          &:hover {
            background-color: color(light, tertiaryBg);
            color: color(light, fontColor);
          }
        }
      }
    }
  }
}

.refund {
  padding: 15px;

  .refund-info {
    .sub-header {
      padding-bottom: 10px;
      border-bottom: 1px solid #e6e6e6;
      color: color(purple, dark);
      margin-top: 25px;
      font-size: 18px;
    }

    .info {
      .info-part {
        margin: 15px 0;
        span {
          font-weight: 500;
        }
      }
    }

    .refund-details {
      margin-top: 15px;
      display: flex;
      flex-direction: row;

      .TextField {
        flex: 2;
        margin-right: 8px;
      }

      .Dropdown {
        flex: 1;
      }
    }

    .refund-actions {
      display: flex;
      justify-content: flex-end;
      margin: 10px 0;
    }
  }
}
