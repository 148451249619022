@import '../../../../styles/variables.scss';

.FormSectionHeader {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e9e9e8;
  padding: 5px 0;

  .info {
    display: flex;
    flex-direction: column;
    padding-right: 15px;
    flex: 1;

    .header-txt {
      font-family: $raleway;
      font-size: 16px;
      font-weight: 800;
      line-height: 19px;
      color: color(grey, dark);
      margin: 5px 0;
    }

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
  }

  .form-header-action {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    padding-right: 15px;
    font-weight: 500;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
      color: color(purple, dark);
    }
  }
}
