@import '../../styles/variables.scss';

@keyframes slideIn {
  0% {
    transform: translateX(275px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

.Chat {
  z-index: 4;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  cursor: pointer;
  animation: 200ms slideIn ease-out;
  background: color(light, secondaryBg);
  border-radius: 10px;
  overflow: hidden;

  // custom scrollbar styles for chat
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: color(light, secondaryBg);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    opacity: 0.1;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }

  // /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //     background: color(grey, base);
  // }

  .conversations {
    display: flex;
    height: 100%;
    flex-direction: row;
    margin-bottom: 11px;
  }

  .search-connections {
    height: auto;
    margin: 0 20px 0 20px;

    .title {
      margin: 15px 0px;
      font-weight: 700;
    }

    .TextField .input-wrap {
      background-color: #eaeff0;
      border: none;

      input {
        font-size: 12px;
        text-indent: 0;
        font-weight: 0;
      }

      & i {
        color: #adb8bc;
      }
    }

    ul {
      color: color(light, fontColor);
      text-align: left;
      list-style-type: none;
      padding-left: 0;

      li {
        display: flex;
        align-items: center;
        padding: 8px;
        margin: 0 0;
        position: relative;
      }
    }

    .current-conversations-list {
      overflow: auto;
    }
  }

  .messages {
    padding: 0 20px;
    display: flex;
    align-content: center;
    z-index: 6;
    background: #ffffff;
    justify-content: space-between;
    border-bottom: 1px solid color(grey, lightest);
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:hover {
      color: color(purple, dark);
    }

    &.open {
      height: 52px;
      justify-content: start;

      p {
        margin: 0;
        font-weight: 700;
      }
    }

    i.close-convos {
      align-self: center;
      font-size: 16px;
      padding: 0px 15px;
      padding-left: 0;
      margin-top: 5px;
      font-weight: 900;
    }

    .chat-actions {
      .close-chat-mobile {
        display: none;
      }

      h4 {
        margin: 0;
      }

      i {
        font-size: 20px;
        padding: 0 15px;
        color: color(grey, light);

        &:nth-child(2) {
          padding-right: 0;
        }
      }

      i.fa .fa-plus {
        align-self: center;
      }
    }

    .title {
      font-weight: 600;
      color: color(light, fontColor);
      margin-bottom: 0;
      margin-top: 0px;
    }
  }

  .TextField {
    flex: none;
  }

  .title {
    font-weight: 600;
    color: color(light, fontColor);
    margin-bottom: 0;
    margin-top: 15px;
  }

  .current-conversations-list {
    overflow: auto;
    height: 100%;
  }

  .convo-list {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    &.empty {
      align-items: center;
      justify-content: center;
    }
    .no-convos {
      font-weight: 900;
      text-align: center;
      margin: 0 auto;
      font-size: 12px;
      padding: 10px;
    }
  }

  .chat-container {
    margin-top: 80px;
    width: 300px;
    height: auto;
    background: #ffffff;
    flex-direction: column;
  }

  .chat-inner,
  .menu-btn {
    display: flex;
  }

  .col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .menu-btn {
    background-color: color(purple, dark);
    height: 48px;
    width: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 4px;
    margin: 10px;

    i {
      font-size: 24px;
    }
  }
}

@media (max-width: 700px) {
  .Chat {
    .chat-container {
      width: 100%;
      margin-top: 75px;

      section {
        .chat-inner {
          width: 100%;

          .messages {
            top: 0px;
            width: 95%;
            position: absolute;

            h4 {
              align-self: flex-start;
              flex: 1;
            }

            i {
              margin-right: 10px;
              padding: 0 10px;
            }
            .new-message {
              align-self: flex-end;
              justify-self: flex-end;
            }
            .close-chat-mobile {
              display: flex;
              align-self: flex-end;
            }
          }
        }
      }
    }
  }
}
