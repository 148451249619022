@import '../../../styles/variables.scss';

.Causes {
  h3 {
    margin: 0px;
    padding: 0px 20px 20px;
  }

  .title {
    .TextField {
      padding: 0 20px 20px;
    }
  }

  .add-cause {
    padding: 0px 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .TextField {
      .input-wrap {
        border-right: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    .Button {
      button {
        height: 44px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
  }

  .user-causes {
    padding: 0px 20px 20px;
    margin: 0px;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    li {
      background-color: color(cream, dark);
      border: 1px solid color(purple, dark);
      margin-right: 16px;
      padding: 8px 21px;
      border-radius: $border-radius;
      margin-bottom: 16px;

      &:hover {
        cursor: pointer;
        border: 1px solid color(purple);
      }

      i.fa-times-circle {
        margin-left: 8px;
        color: color(volunteer);

        &:hover {
          color: color(light, fontColor);
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 930px) {
  .Causes {
    h3 {
      padding: 0px 0px 20px;
    }

    .add-cause {
      padding: 0px 0px 20px;
    }

    .user-causes {
      flex-direction: column;
      padding: 0px 0px 20px;

      li {
        margin-bottom: 10px;
        display: flex;
        margin-right: 0px;

        span {
          flex: 1;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .Causes {
    .add-cause .Button button {
      height: 80px;
    }

    .TextField .input-wrap input {
      height: 78px;
      width: 158px;
    }
  }
}
