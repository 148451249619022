@import '../../../styles/variables.scss';

.FundraiserItem {
  overflow: hidden;
  display: flex;
  background-color: color(cream, dark);
  margin-bottom: 20px;
  box-shadow: $box-shadow;
  border-radius: 7px;

  .item-inner {
    padding: 10px;
    flex: 1;
    border-radius: 7px;

    .info {
      display: flex;
      align-items: center;

      .info-inner {
        padding-left: 10px;

        .name {
          font-size: 16px;
          font-weight: 500;
          color: color(light, fontColor);

          a {
            color: color(light, fontColor);
            text-decoration: none;

            &:hover {
              color: color(purple, dark);
            }
          }
        }

        .raised {
          color: color(grey);
          padding-top: 2px;
          font-size: 14px;

          span {
            font-weight: 700;
          }
        }
      }
    }

    .bar-wrap {
      padding-top: 10px;

      .bar {
        display: flex;
        align-items: center;
        position: relative;
        height: 13px;
        background-color: white;
        border: 2px solid #e9eef0;
        border-radius: 8px;
        width: 100%;

        .bar-inner {
          background: linear-gradient(90deg, color(purple, darkest) 0%, color(purple) 100%);
          margin-left: 2px;
          border-radius: 6px;
          min-width: 5%;
          height: 9px;
          background-color: color(grey);
        }
      }
    }
  }
}
