@import '../../styles/variables.scss';

.EventManagement {
  flex: 1;
  display: flex;

  .section-title {
    display: flex;

    .forms-title {
      flex: 1;
    }

    .forms-create {
      .Button {
        button {
          box-shadow: $box-shadow;
        }
      }
    }
  }

  .EventManagement-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    .search {
      display: flex;
      align-items: center;

      .create {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }

      .TextField {
        max-width: 50%;

        i {
          background-color: color(light, secondaryBg);
          color: color(light, fontColor);
        }

        input {
          background-color: color(light, secondaryBg);
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      flex: 1;

      .headers,
      .row {
        .col {
          display: flex;
          flex: 1;
          font-weight: 700;

          &:first-child {
            text-indent: 8px;
          }

          &.title {
            flex: 1 0 120px;
          }

          &.start-date,
          &.end-date {
            flex: 0 1 160px;

            span {
              font-weight: 400;
              margin-right: 8px;

              &:last-child {
                color: color(grey);
                font-weight: 400;
              }
            }
          }

          &.total-revenue {
            flex: 0 1 160px;
          }

          &.event-status {
            flex: 0 1 180px;
          }

          &.fundraising-progress {
            flex: 0 1 220px;
          }

          &.actions {
            justify-content: flex-end;
            padding-right: 40px;
            position: relative;
            flex-basis: 80px;
            flex-grow: 0;
            box-sizing: border-box;

            .ContextMenu {
              display: none;
              position: absolute;
              top: 55px;
              right: 10px;
              box-shadow: $box-shadow;
              border-radius: 20px;

              i {
                font-size: 16px;
                padding-right: 8px;
              }

              &:hover {
                display: block;
              }
            }

            i.fa-ellipsis-h-alt {
              font-size: 24px;
              height: 40px;
              width: 40px;
              background-color: color(blue, lightest);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }

            i.fa-ellipsis-h-alt:hover + .ContextMenu {
              display: block;
            }
          }
        }
      }

      .headers {
        background-color: color(blue, lightest);
        display: flex;
        flex: 0;
        min-height: 40px;
        height: 40px;
        line-height: 40px;
      }

      .list-inner {
        flex: 1;
        max-height: 100%;
        overflow: auto;
        position: relative;

        .list-rows {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;

          .empty {
            flex: 1;
            padding: 30px 20px;
            text-align: center;
            color: color(grey);
            font-size: 16px;
          }
        }

        .row {
          display: flex;
          height: 60px;
          border-bottom: 1px solid color(grey, light);

          &:hover {
            .event-title {
              a {
                color: color(purple, dark);

                .fa-external-link {
                  display: inline-block;
                }
              }
            }
          }

          .col {
            font-weight: 400;
            align-items: center;
          }

          .event-title {
            cursor: pointer;

            a {
              font-size: 16px;
              font-weight: 500;
              text-decoration: none;
              color: #354a53;

              .fa-external-link {
                display: none;
              }

              &:hover {
                color: color(purple);

                .fa-external-link {
                  display: inline-block;
                }
              }
            }
          }

          .event-status-dropdown {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
          }

          .total-revenue-amount {
            color: #354a53;
            font-size: 14px;
          }

          .fundraising-progress {
            .fundraising-progress-bar-container {
              display: flex;
              flex-direction: column;

              .fundraising-progress-bar {
                background-color: #012939;
                width: 180px;
                height: 30px;
                border-radius: 7px;
                position: relative;

                .progress-fill {
                  height: 24px;
                  background: transparent
                    linear-gradient(75deg, #008599 0%, #4d56c7 84%, #734fd5 100%) 0% 0% no-repeat
                    padding-box;
                  border-radius: 7px;
                  margin: 3px;
                  max-width: 175px;
                }

                .raised-amount {
                  color: #ffffff;
                  font-size: 12px;
                  position: absolute;
                  line-height: 30px;
                  left: 8px;
                  top: 0;
                  font-weight: bold;
                }

                .goal-amount {
                  color: #ffffff;
                  font-size: 12px;
                  position: absolute;
                  line-height: 30px;
                  right: 8px;
                  top: 0;
                  font-weight: bold;
                }
              }

              .percent {
                margin-top: 3px;
                font-size: 11px;
                color: #55646b;
                align-self: center;
              }
            }
          }
        }
      }
    }
  }

  .FormManagement-inner {
    max-width: $wrap-size;
    margin: 0px auto;

    .form-name {
      margin-top: 20px;

      h1 {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: 2px 9px;
        margin: 0px;
        font-size: 32px;
        height: 40px;
        font-weight: 700;

        span {
          flex: 1;
        }

        i {
          display: none;
          font-size: 21px;

          &:hover {
            color: color(purple, dark);
            cursor: pointer;
          }
        }

        &:hover {
          i {
            display: block;
          }
        }
      }

      .TextField {
        input {
          font-size: 32px;
          font-weight: 700;
        }
      }

      .edit-wrap {
        position: relative;

        i {
          position: absolute;
          top: 11px;
          right: 15px;
          font-size: 21px;

          &:hover {
            cursor: pointer;
            color: color(purple, dark);
          }
        }
      }
    }

    .question {
      display: flex;
      flex: 1;
      padding: 20px 8px;

      .label {
        flex: 1;
        margin-right: 20px;

        .options {
          padding-top: 20px;
        }
      }

      .type {
        .Dropdown {
          min-width: 300px;
        }

        .Button {
          margin-top: 20px;
        }
      }

      .option {
        padding-bottom: 10px;
        position: relative;

        i {
          position: absolute;
          top: 15px;
          right: 15px;

          &:hover {
            cursor: pointer;
            color: color(purple, dark);
          }
        }
      }
    }

    .actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      padding: 0px 8px;

      .Button {
        margin-left: 10px;
        padding-top: 40px;
      }
    }
  }
}
