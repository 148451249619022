@import '../../styles/variables.scss';

.Modal.VolunteerModal {
  .content {
    .modal-close-circle {
      display: none;
    }

    section {
      width: 900px;
      min-height: 570px;
      display: flex;
      flex-direction: column;
      padding: 48px;

      &.single {
        width: 650px;
      }

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        margin-bottom: 24px;
        border-bottom: 1px solid color(grey, border);

        .Button {
          button {
            max-width: unset;
          }
        }

        .left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          row-gap: 4px;

          &.with-img {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            column-gap: 16px;

            .info {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              row-gap: 4px;

              a {
                text-decoration: none;
                color: color(grey, dark);
                transition: all 0.2s;

                span {
                  color: color(grey, text);
                  transition: all 0.2s;

                  &:hover {
                    color: color(plum, dark);
                  }
                }

                &:hover {
                  color: color(plum, dark);
                }
              }
            }
          }

          h2 {
            font-weight: 700;
            font-size: 22px;
            color: color(grey, dark);
            margin: 0;
            max-width: 353px;
          }

          span {
            font-weight: 500;
            font-size: 14px;
            color: color(grey, text);
          }
        }
      }

      .TableComponent {
        .status {
          height: 30px;
          line-height: 30px;
          padding: 6px 12px;
          text-align: center;
          border-radius: 6px;

          &.Verified {
            color: color(green, soft);
            background-color: color(green, fade);
          }

          &.Unverified {
            color: color(yellow, text);
            background-color: color(yellow, fade);
          }
        }
      }

      .list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        width: 100%;
        padding-right: 3px;
        overflow-y: auto;
        overflow-x: hidden;
        @include thin-scroller-y;

        .TableComponent {
          margin-bottom: 0;
        }

        .item {
          display: flex;
          align-items: center;
          min-height: 84px;
          border: 2px solid color(dark, borderLight);
          border-radius: 10px;
          padding: 0 19px;
          column-gap: 24px;
          transition: all 0.2s;
          cursor: pointer;

          &:hover {
            border: 2px solid color(plum, dark);
          }

          .name-con {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 400px;

            a {
              text-decoration: none;
              color: color(grey, dark);
              transition: all 0.2s;
              width: max-content;
              max-width: 350px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              &:hover {
                color: color(plum, dark);
              }

              .under-name {
                color: color(grey, text);
                transition: all 0.2s;

                &:hover {
                  color: color(plum, dark);
                }
              }
            }
          }

          .applied-on {
            width: 200px;
            display: flex;
            flex-direction: column;
            row-gap: 4px;
            position: relative;
            left: 180px;
          }

          .hours {
            text-align: left;
            font-weight: 600;
            font-size: 14px;
            color: color(plum, dark);
          }
        }
      }

      .subtext {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin: 16px 0;

        .title {
          font-weight: 700;
          font-size: 14px;
          color: color(grey, dark);
        }

        .desc {
          font-weight: 500;
          font-size: 14px;
          color: color(grey, text);
        }
      }

      form {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        row-gap: 16px;

        label {
          color: color(grey, dark);
          font-weight: 600;
          font-size: 14px;

          span {
            font-size: 14px;
            font-weight: 600;
            color: color(grey, dark);
          }
        }

        .row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;

          .col {
            flex-grow: 1;

            &.date-container {
              width: 100%;

              .custom-dtp {
                margin-top: 4px;
                flex: 1;

                input {
                  text-align: unset;
                  width: calc(100% - 12px);
                  padding-left: 12px;
                }
              }
            }
          }
        }
      }

      .btn-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 24px;
        margin-top: 18px;

        .back {
          cursor: pointer;

          i {
            font-size: 12px;
            margin-right: 12px;
          }
        }

        .right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-grow: 1;
          column-gap: 24px;
        }
      }
    }
  }
}
