@import '../../../../styles/variables.scss';

.ApplicationsDetailView {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;

  .back-to {
    margin-left: 24px;
    margin-right: 24px;
    padding-bottom: 15px;
    margin-bottom: 60px;
    border-bottom: 1px solid color(dark, borderLight);
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings:
      'pnum' on,
      'lnum' on,
      'salt' on,
      'ss09' on;
    color: black;

    span {
      cursor: pointer;

      i {
        margin-right: 21px;
      }
    }
  }

  .info {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: color(grey, base);
    margin-top: 12px;
    height: 24px;
  }

  .mid-title {
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    margin-top: 40px;

    &.with-border {
      padding-bottom: 14px;
      border-bottom: 1px solid color(grey, border);
    }
  }

  .small-title {
    font-size: 14px;
    line-height: 16px;
    margin-top: 40px;
    font-weight: 800;
  }

  .detail-view-header {
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid color(grey, border);
    flex-wrap: wrap;

    .contact-icon {
      width: 44px;
      height: 44px;
      padding: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid color(grey, border);
      color: color(grey, border);
      box-sizing: border-box;
      border-radius: 10px;
      transition: all 0.2s;
      cursor: pointer;

      &:hover {
        border: 1px solid color(grey, dark);
        color: color(grey, dark);
      }

      i {
        font-size: 16px;
      }
    }

    .main-details {
      display: flex;
      flex-direction: row;

      svg,
      img {
        width: 60px;
        height: 60px;
        border: 1px solid color(light, shadow);
        box-sizing: border-box;
        border-radius: 111px;
      }

      .names {
        margin-left: 20px;
        display: flex;
        flex-direction: column;

        h2 {
          font-style: normal;
          font-weight: 900;
          font-size: 21px;
          line-height: 25px;
          font-feature-settings:
            'pnum' on,
            'lnum' on,
            'salt' on,
            'ss09' on;
          color: color(grey, dark);
          margin: 0 0 14px 0;
        }

        span {
          font-style: normal;
          font-weight: 800;
          font-size: 14px;
          line-height: 16px;
          font-feature-settings:
            'pnum' on,
            'lnum' on,
            'salt' on,
            'ss09' on;
          color: color(grey, dark);

          &.lighter {
            color: color(grey, almostdark);
          }
        }
      }
    }
  }

  .sub-info-container {
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 36px;

    .date-info {
      place-self: center;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      font-feature-settings:
        'pnum' on,
        'lnum' on,
        'salt' on,
        'ss09' on;
      color: color(grey, smallTxt);
    }
  }

  .gig-details-container {
    margin-left: 24px;
    margin-right: 24px;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      font-feature-settings:
        'pnum' on,
        'lnum' on;
      color: color(grey, base);
      margin: 14px 0 0 0;
    }

    .pills {
      display: flex;
      flex-direction: row;

      .pill {
        padding: 12px 24px;
        border: 1px solid color(light, box);
        color: color(grey, dark);
        background-color: color(light, secondaryBg);
        box-sizing: border-box;
        height: 38px;
        border-radius: 20px;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        font-feature-settings:
          'pnum' on,
          'lnum' on,
          'salt' on,
          'ss09' on;
      }
    }
  }

  .application-details-container {
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;

    .contacts {
      display: flex;
      flex-direction: column;
    }
  }

  .availability-container {
    display: flex;
    padding-bottom: 40px;

    .day {
      display: flex;
      flex-direction: row;

      span {
        &:first-child {
          margin-right: 96px;
        }
      }
    }
  }
}
