@import './../../../../styles/variables.scss';
#context-menus {
  z-index: 99;
  position: relative;
}
.CharitiesSelection {
  padding: 0 80px;
  max-height: calc(100vh - 220px);

  &.group {
    padding: 0;
  }

  .charities-tab-wrapper {
    display: flex;
    flex-direction: row;

    .charities-tab {
      width: 50%;
      text-align: center;
      padding: 16px 0;
      font-size: 14px;
      border-bottom: 1px solid #e9e9e8;

      &.active {
        font-weight: 700;
        border-bottom: 2px solid #2f2956;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
  .charities-header {
    font-family: $raleway;
    margin: 0;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    color: #252243;
  }
  .charities-list-wrapper {
    max-height: 100%;
    overflow-y: auto;

    .filter-wrapper {
      display: flex;
      flex-direction: row;

      .TextField {
        margin-right: 20px;

        .input-wrap {
          border-radius: 38px;
          margin-top: 25px;

          input {
            height: 32px;
          }
        }
      }

      .filter-dropdown {
        margin-right: 20px;
        min-width: 205px;

        .input-wrap {
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 700;
          font-size: 16px;
          color: color(light, fontColor);
          padding: 0 23px 0 16px;

          .menu-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease-in-out;

            &.show {
              transform: rotate(180deg);
            }
          }

          .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            img {
              width: 12px;
              height: 12px;
              margin-right: 14px;
            }
          }

          &:hover {
            cursor: pointer;
          }
        }

        label {
          z-index: 1;
          bottom: -11px;
          left: 9px;
          background-color: white;
          color: color(grey, dark);
          font-weight: normal;
          position: relative;
          padding: 5px;
          width: fit-content;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
    ul {
      padding: 0;
      margin: 16px 0 0 0;
    }
    .charities-list-item {
      list-style-type: none;
      padding: 20px;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      &.selected {
        border: 1px solid #2f2956;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
      }

      .charities-item-left {
        display: flex;
        flex-direction: row;
      }
      .group-info {
        .group-title {
          font-size: 16px;
          font-weight: 800;
          color: #3a3a3a;
        }
        .group-charity-id {
          color: #959799;
          font-size: 12px;
          font-weight: 500;
          margin-top: 8px;
        }
      }
      .profile-image {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #d0d1d1;
        overflow: hidden;
        margin-right: 15px;
        img {
          width: 100%;
          height: auto;
        }
      }
      div.custom_select {
        margin-left: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        background: #fafbfb;
        border: 2px solid #ededec;
        border-radius: 4px;
        transition: all 0.2s;

        i {
          font-size: 23px;
          margin-top: -2px;

          &.fa-square {
            color: #fafbfb;
          }
        }

        &.selected {
          color: color(purple, dark);
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .CharitiesSelection {
    padding: 0;

    .charities-list-wrapper {
      .filter-wrapper {
        flex-wrap: wrap;

        .Dropdown {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .CharitiesSelection {
    padding: 0;
  }
}
