@import '../../../styles/variables.scss';

body.widget {
  background-color: transparent !important;

  main {
    background-color: transparent !important;
    padding-top: 0px;
    height: auto;
    display: block;
  }

  .Header,
  .Footer {
    display: none !important;
  }
}
