@import '../../../../styles/variables.scss';

.HubFilterBar {
  // changed .header.h1 color from #012939 (default) to #3A3A3A (GIG-6585)
  .header {
    display: flex;
    justify-content: space-between;

    h1 {
      margin: 0;
      font-family: $raleway;
      font-weight: 700;
      font-size: 28px;
      line-height: 24px;
      color: #3a3a3a;
    }
  }

  .controls {
    display: flex;
    border-top: 0;
    .search-input-container,
    .additional-filters {
      flex: 1;
    }

    .search-input-container {
      align-self: flex-end;
    }

    .additional-filters {
      display: flex;
      justify-content: flex-end;
      column-gap: 24px;

      .filter-dropdown {
        width: 200px;

        .input-wrap {
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 700;
          font-size: 16px;
          color: #012939;
          padding: 0 23px 0 16px;
          border: 1px solid color(grey, lightest);

          .menu-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease-in-out;

            &.show {
              transform: rotate(180deg);
            }
          }

          .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            i {
              margin-right: 8px;
              margin-top: 4px;
            }

            img {
              width: 12px;
              height: 12px;
              margin-right: 14px;
            }
          }

          &:hover {
            cursor: pointer;
          }
        }

        label {
          z-index: 1;
          bottom: -11px;
          left: 9px;
          background-color: white;
          color: color(grey, dark);
          font-weight: normal;
          position: relative;
          padding: 5px;
          width: fit-content;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .search-input-container {
      .TextField {
        .input-wrap {
          border: 1px solid color(grey, lightest);
          border-radius: $border-radius;

          input {
            text-overflow: ellipsis;
            padding-right: 4px;
          }
        }

        i {
          color: color(grey, base);
          padding-left: 5px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .HubFilterBar {
    .controls {
      flex-direction: column;

      .search-input-container {
        align-self: unset;
      }

      .additional-filters {
        flex-wrap: wrap;
        justify-content: center;

        .filter-dropdown {
          width: 100%;
        }
      }
    }
  }
}
