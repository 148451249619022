@import '../../../../styles/variables.scss';

.PageDetails {
  &.loading {
    .details {
      .details-info {
        .details-title {
          .details-title-inner {
            h1 {
              display: inline-block;
              width: 250px;
              height: 28px;
              background: $loading-shimmer-bg;
              background-size: $loading-shimmer-size;
              animation: $loading-shimmer-animation;
              border-radius: 5px;
            }

            .details-group {
              li {
                &:after {
                  content: ' ';
                  display: inline-block;
                  width: 250px;
                  height: 18px;
                  background: $loading-shimmer-bg;
                  background-size: $loading-shimmer-size;
                  animation: $loading-shimmer-animation;
                  border-radius: 5px;
                }

                span {
                  display: none;
                }
              }
            }
          }
        }
      }

      .cover-image {
        background-color: color(purple, dark);
      }
    }
  }

  .more-actions-modal {
    .content {
      padding: 20px 20px 10px 20px;
      justify-content: center;
      align-items: center;

      .Button {
        margin-bottom: 10px;
        width: 100%;
        max-width: 300px;

        &.last-button {
          margin-bottom: 0px;
        }

        button {
          width: 100%;
          background-color: color(light, secondaryBg);
          color: color(purple, dark);
          border: 2px solid color(purple, dark);
          box-shadow: none;

          &:hover {
            color: color(purple);
            border: 2px solid color(purple);
          }
        }
      }

      a {
        display: flex;
        width: 100%;
        max-width: 300px;
        height: 40px;
        background-color: transparent;
        border-radius: 20px;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        margin-bottom: 10px;
        text-decoration: none;
        color: color(purple, dark);
        opacity: 0;
        animation: fadeIn 1s forwards;
        border: 2px solid color(purple, dark);

        &:hover {
          border: 2px solid color(purple);
          color: color(purple);
        }

        &:first-child {
          background-color: color(purple, dark);
          color: color(light, secondaryBg);
          border: 2px solid color(purple, dark);

          &:hover {
            background-color: color(purple);
            border: 2px solid color(purple);
          }
        }
      }
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    margin: 0px auto;
    border-radius: 15px;
    box-shadow: $box-shadow;
    position: relative;

    .details-info {
      display: flex;
      background-color: color(light, secondaryBg);
      padding: 25px;
      min-height: 140px;
      border-radius: 0px 0px 15px 15px;

      .details-title {
        display: flex;
        flex: 1;

        .details-title-inner {
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .page-type {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            i {
              transform: rotateZ(-45deg);
              font-size: 12px;
              margin-top: -2px;
              color: #a7b0b4;
            }

            span {
              text-transform: uppercase;
              font-size: 14px;
              font-weight: 700;
              letter-spacing: 0.5px;
              margin-left: 5px;
            }
          }

          .page-title {
            display: flex;
            align-items: center;

            form {
              display: flex;
              flex: 1;

              .Button {
                button {
                  background-color: transparent;
                  padding: 0px;
                  min-width: 0px;

                  .icon {
                    color: color(light, fontColor);
                  }
                }
              }
            }

            h1 {
              padding: 0px;
              margin: 0px;
              font-size: 24px;
              font-weight: 700;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            i {
              padding-left: 10px;
              font-size: 17px;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }

            .TextField {
              height: 40px;

              .input-wrap {
                input {
                  height: 40px;
                }
              }
            }
          }

          .details-group {
            padding: 20px 0px 0px;
            margin: 0px;
            list-style-type: none;

            li {
              display: flex;
              padding-bottom: 12px;
              font-size: 15px;
              color: #354a53;

              a {
                display: flex;
                text-decoration: none;
                color: #354a53;

                &:hover {
                  color: color(purple, dark);
                }
              }

              i {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 8px;
                width: 20px;
              }

              span,
              .when-inner {
                display: grid;
                grid-template-columns: 1fr auto 1fr;
                column-gap: 8px;
                text-align: center;
                align-items: center;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                .two-rows {
                  display: block;
                }

                &.parent {
                  column-gap: 0;
                  grid-column: 0;
                  padding: 0;
                }
              }

              &.when {
                .when-inner {
                  span {
                    display: block;
                  }
                }
              }

              a > .organizer-spacing,
              .organizer-spacing {
                display: inline-block;
                font-weight: 700;
                padding: 0 4px;
              }
            }
          }
        }
      }

      .details-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-width: 300px;

        a {
          display: flex;
          width: 100%;
          height: 40px;
          background-color: transparent;
          border-radius: 20px;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          margin-bottom: 10px;
          text-decoration: none;
          color: color(purple, dark);
          opacity: 0;
          animation: fadeIn 1s forwards;
          border: 2px solid color(purple, dark);

          &:hover {
            border: 2px solid color(purple, darker);
            color: color(purple);
          }

          &:first-child {
            background-color: color(purple, dark);
            color: color(light, secondaryBg);
            border: 2px solid color(purple, dark);

            &:hover {
              background-color: color(purple, darker);
              border: 2px solid color(purple, darker);
            }
          }
        }

        .Button {
          flex: 0;
          width: 100%;
          margin-bottom: 10px;
          opacity: 0;
          animation: fadeIn 1s forwards;

          &:last-child {
            margin-bottom: 0px;
          }

          &.more {
            .icon {
              height: auto;
              width: auto;
              font-size: 30px;
              display: none;
            }
          }

          &.main-btn {
            button {
              background-color: color(purple, dark);
              border: 0px;

              .text {
                color: color(light, secondaryBg);
              }

              &:hover {
                border: 0px;
                background-color: color(purple);
              }
            }
          }

          &:first-child {
            button {
              background-color: color(purple, dark);
              border: 0px;
              color: color(dark, fontColor);

              &:hover {
                border: 0px;
                background-color: color(purple);
                color: color(dark, fontColor);
              }
            }
          }

          button {
            width: 100%;
            background-color: color(light, secondaryBg);
            color: color(purple, dark);
            border: 2px solid color(purple, dark);
            box-shadow: none;

            &:hover {
              color: color(purple);
              border: 2px solid color(purple);
            }
          }
        }
      }

      .donation-matching-mobile {
        display: none;

        flex-direction: column;
        padding: 20px;
        background-color: #e7f6f8;
        border-radius: 10px;

        .label {
          align-self: center;
          color: #0c9fb5;
          margin-bottom: 10px;
          font-weight: 500;
        }

        .description {
          align-self: center;
          text-align: center;
        }

        .matched-to-date {
          align-self: center;
          color: #0c9fb5;
          margin-top: 20px;
        }
      }
    }

    .contact-share {
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
      z-index: 3;

      .Button,
      .Button button {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        padding: 0px;
        margin: 0px;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .Button {
        margin-left: 10px;
      }

      .Button button {
        background-color: color(light, secondaryBg);

        .icon {
          margin: 0px;
          color: color(light, fontColor);
        }

        &:hover {
          background-color: color(purple, dark);

          .icon {
            color: color(dark, fontColor);
          }
        }
      }
    }

    .cover-image {
      flex: 1;
      background-color: color(light, secondaryBg);
      transition: 300ms linear;
      border-radius: 15px 15px 0px 0px;

      .CoverImage {
        border-radius: 15px 15px 0px 0px;
        width: 100%;
        padding-top: 28.572%;

        span {
          display: block !important;
        }
      }

      .lazy-load-image-background {
        position: absolute;
        top: 0px;
        left: 0px;
        overflow: hidden;
        display: block !important;
        width: 100%;
        height: 100%;
      }

      .donation-matching {
        height: 60px;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        line-height: 20px;

        background: #f5f5f5;

        .icon {
          height: 20px;
          margin: 20px 10px 20px 20px;
        }

        .content {
          margin: 20px 0 20px 0;
          .label {
            font-weight: 700;
          }

          span {
            margin: 20px 0 20px 0;
          }

          .matched-to-date {
            color: #0c9fb5;
          }
        }
      }
    }
  }

  .Modal {
    &.tickets-modal {
      .content {
        max-width: 500px;
        display: block;
        flex: 1;
        max-height: 100%;

        .ticket-store-wrap {
          max-height: 700px;
          overflow-x: hidden;
          overflow-y: auto;
        }

        .Store {
          display: block;
          max-width: none;

          h3 {
            display: none;
          }

          .store-item-wrap {
            .components-wrap {
              .StoreItem {
                box-shadow: none;
                padding: 20px 20px;
                margin: 0px;
                width: auto;

                .store-item-inner {
                  flex-direction: column;

                  .images {
                    .main {
                      border-bottom-left-radius: 0px;
                      border-bottom-right-radius: 0px;
                    }

                    &.no-images {
                      .main {
                        background-color: color(purple, dark);
                      }
                    }
                  }

                  .item-top-row {
                    flex-direction: column;
                    padding: 20px;

                    .qty {
                      padding: 0px !important;
                    }
                  }

                  .item-bottom-row {
                    display: none;
                  }

                  .portrait-wrap {
                    position: relative;
                    overflow: hidden;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;

                    .backdrop {
                      display: block;
                      position: absolute;
                      top: 0px;
                      left: 0px;
                      width: 100%;
                      height: 100%;
                      background-color: color(purple, dark);

                      &.with-image {
                        background-color: color(blue, lighter);
                      }

                      img {
                        width: 100%;
                        height: 100%;
                        filter: blur(4px);
                        transform: scale(2.5);
                      }
                    }

                    .Portrait {
                      display: flex;
                      margin: 20px 0px;
                      justify-content: center;
                      align-items: center;
                    }
                  }

                  .store-item-details {
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                    background-color: color(blue, lightest);

                    .item-top-row {
                      .qty {
                        display: flex;
                        margin: 20px 0px 0px;
                        padding: 0px 20px;

                        .TextField {
                          max-width: 50px;
                          input {
                            background-color: color(light, secondaryBg);
                          }
                        }

                        .Button {
                          flex: 1;
                          margin-left: 10px;

                          button {
                            width: 100%;
                            height: 44px;
                            border-radius: 5px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .fundraising-title {
          font-size: 24px;
          display: flex;
          justify-content: flex-start;
          font-weight: 700;
        }

        .ticket-handle,
        .fundraising-handle {
          color: color(grey);
          font-size: 16px;
          padding-top: 10px;
          padding-bottom: 30px;
          border-bottom: 1px solid color(grey, lighter);
        }

        .ticket-actions {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .link-btn {
            font-family: $lato;
            display: flex;
            border: 0px;
            padding: 0px 28px;
            font-size: 16px;
            border-radius: 20px;
            justify-content: center;
            align-items: center;
            color: color(dark, fontColor);
            text-decoration: none;
            overflow: hidden;
            min-width: 80px;
            min-height: 36px;
            margin: 10px 0;
            background-color: color(purple, dark);
          }

          .Button {
            button {
              margin-right: 20px;
              background: #fff;
              color: color(light, fontColor);
              border: 1px solid color(light, fontColor);
            }
          }
        }
      }

      .tickets-modal {
        flex: 1;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 970px) {
  .PageDetails {
    .details {
      .details-info {
        flex-direction: column;

        .details-title {
          .details-title-inner {
            h1 {
              font-size: 21px;
              -webkit-line-clamp: 3;
            }
          }
        }

        .details-actions {
          flex-direction: row;
          padding-left: 141px;
          margin-top: 15px;

          a,
          .Button {
            flex: 1;
            margin-bottom: 0px;
            margin-right: 20px;

            &.more {
              flex: 0;
              margin-right: 0px;

              button {
                border: 0px;
                padding: 0px;
                min-width: 0px;
                max-width: 60px;

                .text {
                  display: none;
                }

                .icon {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .PageDetails {
    .Modal {
      &.tickets-modal {
        .content {
          .ticket-store-wrap {
            height: 100%;
            .Store {
              margin-bottom: 100px;
            }
          }
        }
      }
    }
  }
}

@media (max-height: 600px) {
  .PageDetails {
    .Modal {
      &.tickets-modal {
        .content {
          .ticket-store-wrap {
            max-height: 400px;
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .PageDetails {
    .details {
      .details-info {
        padding-bottom: 20px;

        .details-actions {
          flex-direction: column;
          margin-top: 15px;
          min-width: 0px;
          padding: 0px 2px;

          a,
          .Button {
            flex: 1;
            margin-bottom: 10px;
            margin-right: 0px;
            min-height: 40px;

            &.more {
              flex: 1;

              button {
                max-width: 100%;
                border: 2px solid color(purple, dark);

                &:hover {
                  border: 2px solid color(purple);
                }

                .text {
                  display: block;
                }

                .icon {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 530px) {
  .PageDetails {
    .details {
      box-shadow: none;

      .contact-share {
        top: -5px;
        right: -2px;
      }

      .cover-image {
        display: none;
      }

      .details-info {
        background-color: transparent;
        padding: 0px;

        .donation-matching-mobile {
          display: flex;
        }

        .details-title {
          flex-direction: column;

          .Portrait {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .details-title-inner {
            text-align: center;
            margin-left: 0px;

            .page-type {
              justify-content: center;
              align-items: center;
              padding-top: 10px;
            }

            .page-title {
              justify-content: center;
            }
          }
        }
      }
    }
    .details-group {
      .when-inner {
        .two-rows {
          display: grid;
          grid-template-rows: 1fr 1fr;
          place-items: center;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .PageDetails {
    .details {
      .details-info {
        .details-actions {
          .Button.more {
            button {
              min-height: 40px;
            }
          }
        }
      }
    }
  }
}
