@import '../../../../../styles/variables.scss';

.GenerateDisbursementPackageModal {
  display: flex;
  flex-flow: column;
  padding: 20px 40px;
  max-width: 500px;

  .modal-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;

    .form-row {
      display: flex;
      flex-flow: column;
      gap: 10px;

      label {
        font-weight: 700;
      }

      .value {
        color: color(grey, base);
      }
    }
  }
}
