@import '../../styles/variables.scss';

.PopupMenuLoggedOut,
.PopupMenuLoggedIn {
  display: flex;
  margin-left: -234px;
  width: 234px;
  max-height: calc(100vh - 80px - 48px);
  overflow: auto;
  @include thin-scroller-y;
  gap: 0;

  li.MenuItem {
    min-height: 48px;
    height: 48px;
    max-height: 48px;
    font-family: $lato;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: color(grey, dark);
    text-align: left;
    transition: all 0.2s;

    &.logout {
      color: color(red, soft);

      &:hover {
        color: color(red, soft);
      }
    }

    &:hover {
      background: color(grey, pale);
      border-radius: 10px;
    }

    img {
      margin-left: 22px;
      margin-right: 18px;
      width: 16px;
      height: 16px;
    }

    i {
      margin-left: 20px;
      margin-right: 16px;
      width: 20px;
      font-size: 18px;
    }
  }
}

.UserMenu {
  .metric {
    text-align: center;
    color: color(light, secondaryBg);
    display: block;
    background: color(red, soft);
    margin-left: 18px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 9px;
    line-height: 15px;
    width: 27px;
    height: 16px;
    border-radius: 8px;
  }

  .header-context-menu {
    position: relative;
    min-width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    height: 44px;
    line-height: 44px;
    border: none;
    border-radius: 30px;
    transition: all 0.2s;
    background-color: color(light, secondaryBg);
    width: 44px;

    &:hover {
      background-color: color(grey, pale);
    }

    i {
      font-size: 21px;
      width: 21px;
      height: 16px;
      margin-bottom: 5px;
    }
  }

  .Modal {
    .content {
      border-radius: 0;
    }
    &.HeaderUserMenuMobile,
    &.HeaderUserMenuMobile-language {
      display: none;
    }
  }
}

@media (max-width: 1010px) {
  .PopupMenuLoggedIn-Dropdown,
  .PopupMenuLoggedOut {
    display: none;
  }

  .UserMenu {
    .Modal {
      &.HeaderUserMenuMobile-language {
        display: block;

        .content {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(black, 0.5);

          .ModalHeader {
            width: 300px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            .close-button {
              display: none;
            }

            .modal-close-icon {
              margin-bottom: -114px;
              z-index: 9;
            }
          }

          .lng-container {
            width: 300px;
            background: color(light, secondaryBg);
            box-shadow: 0 16px 24px rgba(0, 0, 0, 0.12);
            border-radius: 10px;
            padding: 24px;
            margin: 48px 24px;

            .header-title {
              text-align: left;
              font-weight: 700;
              font-size: 16px;
              color: color(plum, grey);
              padding: 0 0 22px 0;
              border-bottom: 1px solid color(dark, borderLight);
              margin-bottom: 18px;
            }

            .lng-content {
              display: flex;
              flex-direction: column;
              row-gap: 16px;

              span {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-weight: 700;
                font-size: 14px;
                color: color(grey, dark);
                cursor: pointer;
                white-space: pre;

                i {
                  margin-right: 14px;
                  color: color(grey, text);

                  &.fad.fa-circle {
                    color: color(purple, dark);
                  }
                }
              }
            }
          }
        }
      }

      &.HeaderUserMenuMobile {
        display: block;
        margin-top: $header-height;
        border-top: 1px solid color(dark, borderLight);

        .content {
          width: 100%;
        }

        .header-context-men {
        }

        .inner-modal {
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-start;
          padding: 0 24px 55px 24px;
          overflow-y: auto;
          overflow-x: hidden;

          .profile-image {
            margin-right: 10px;
          }

          .user-profile-details {
            display: flex;
            flex-flow: row nowrap;
            border-bottom: solid 1px color(grey, lightest);
            padding-bottom: 33px;
            align-items: center;

            .go-to-profile {
              display: flex;
              flex-flow: column nowrap;
              margin-right: auto;
              font-size: 14px;

              span {
                font-weight: 700;
              }

              a {
                color: #a9aaac;
              }
            }
          }

          .section {
            padding: 25px 0 25px 0;
            border-bottom: 1px solid color(grey, lightest);

            .link {
              i {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15px;
                height: 14px;
                line-height: 14px;
                margin-right: 20px;
                font-size: 14px;
              }
            }

            &.user-logged-in {
              border-bottom: unset;

              .title-container {
                .title {
                  display: flex;
                  align-items: center;

                  a {
                    font-weight: 700;
                    font-size: 20px;
                    color: black;
                    transition: all 0.2s;
                    text-decoration: none;

                    &:hover {
                      color: color(plum, dark);
                    }
                  }
                }
              }

              .dropdown-section {
                margin-left: 10px;

                img {
                  width: 16px;
                  height: 16px;
                  margin-right: 20px;
                }

                &.show {
                  .link {
                    color: black;
                    font-weight: 600;
                    font-size: 16px;

                    &.logout {
                      color: color(red, soft);
                    }

                    i {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 15px;
                      height: 15px;
                      line-height: 15px;
                      margin-right: 20px;
                      font-size: 14px;
                    }
                  }
                }
              }
            }

            &.login-action {
              display: flex;
              flex-flow: column;
              border-bottom: unset;

              .link {
                margin-top: 16px;

                .Button {
                  width: 100%;

                  button {
                    max-width: unset;
                    width: 100%;
                  }
                }
              }
            }

            &.actions {
              position: relative;
              display: flex;
              flex-flow: column;
              font-size: 14px;
              padding: 0;
              margin-left: 10px;

              .link {
                color: black;
                font-weight: 600;
                font-size: 16px;
                display: flex;
                position: relative;
                text-decoration: none;
                align-items: center;
              }

              .language-selected {
                cursor: pointer;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                color: color(light, fontColor) !important;

                &.show {
                  color: color(purple, dark);
                }

                i {
                  margin-left: 10px;
                }
              }

              .language-menu {
                position: relative;
                cursor: pointer;

                .ContextMenu {
                  position: absolute;
                  top: 30px;
                  left: 0;
                  right: 0;
                  min-width: unset;
                }
              }
            }

            .title-container {
              display: flex;
              flex-flow: row nowrap;
              margin-bottom: 10px;

              i {
                margin-left: auto;
                font-size: 25px;
                font-weight: 700;
                transition: all 0.2s linear;
                transform: rotate(-90deg);

                &.show {
                  transform: rotate(90deg);
                }
              }

              .title {
                color: black;
                font-weight: 700;
                font-size: 20px;
              }
            }

            .dropdown-section {
              max-height: 0;
              transition: max-height 0.3s ease-in-out;
              overflow: hidden;

              .link {
                font-weight: 600;
                font-size: 16px;
                color: color(grey, text2);
              }

              &.show {
                max-height: 300px;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-height: 800px) {
  .PopupMenuLoggedOut,
  .PopupMenuLoggedIn {
    &.show {
      top: 55px !important;
      overflow-y: auto;
      max-height: calc(100vh - 120px);
    }
  }
}
