@import '../../../styles/variables.scss';

.HorizontalScroller {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  .page-scroller {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    flex-grow: 1;

    /* Hide scrollbar. */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Chrome */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .left-scroll-indicator {
    display: none;
    font-size: 20px;
    align-self: center;
    cursor: none;
    opacity: 0;
    pointer-events: none;
    padding-left: 10px;
    padding-right: 20px;
  }

  .right-scroll-indicator {
    display: none;
    font-size: 20px;
    align-self: center;
    cursor: none;
    opacity: 0;
    pointer-events: none;
    padding-left: 20px;
    padding-right: 20px;
  }

  .left-scroll-indicator,
  .right-scroll-indicator {
    &:hover {
      color: color(purple, dark);
      font-weight: 700;
    }
  }

  .scroll-indicator-visible {
    display: initial;
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }

  .page-tab-container {
    position: relative;
    display: flex;
    margin: 0;
    list-style-type: none;
    padding: 0;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
