@import '../../styles/variables.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.Carousel {
  .carousel-title {
    font-size: 24px;
    font-weight: 700;
  }

  .carousel-container {
    display: block;
    max-width: 1200px;
    margin: 0 auto;

    .pagination-image-container {
      img {
        height: 80px;
        width: 80px;
      }
    }

    .slick-arrow {
      display: flex !important;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 44px;
      background: color(light, secondaryBg);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
      border-radius: 22px;
      z-index: 5;
      transition: all 0.2s;

      i {
        color: color(grey, dark);
        font-size: 24px;
      }

      &::before {
        display: none;
      }
    }

    .slick-dots {
      position: unset;
    }

    &.use-image-tabs {
      .slick-dots {
        margin-top: 10px;
        margin-left: -5px;
        margin-right: -5px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        bottom: unset;
        position: unset;

        li {
          list-style: none;
          display: inline-block;
          height: unset;
          width: unset;
          max-width: 112px;
          max-height: 56px;
          margin: 5px;

          img {
            opacity: 0.5;
            width: 100%;
            height: auto;
          }

          &.slick-active {
            img {
              opacity: 1;
            }
          }
        }
      }
    }

    &.use-dot-tabs {
      .slick-dots {
        margin-top: 20px;

        li {
          width: 32px;
          height: 8px;
          background: color(light, shadow);
          border-radius: 4px;
          transition: all 0.2s;

          button {
            width: 32px;
            height: 8px;
            background: color(light, shadow);
            border-radius: 4px;
            transition: all 0.2s;

            &::before {
              display: none;
            }
          }

          &.slick-active {
            button {
              // background-color: color(red, soft);
            }
          }
        }
      }
    }
  }
  .slick-list {
    margin: 0 -5px;
  }
  .slick-slide > div {
    padding: 0 5px;
  }
}

// @media (max-width: 1024px) {
//     //iPad Pro
//     .Carousel {
//         .carousel-container {
//             max-width: 950px;
//         }
//         .slick-track {
//             margin: unset;
//         }

//         .slick-slide > div {
//             height: 240px;
//         }
//     }
// }

// @media (max-width: 900px) {
//     //iPad
//     .Carousel {
//         .carousel-container {
//             max-width: 700px;
//         }
//         .slick-track {
//             margin: 0 auto;
//         }

//         .slick-slide > div {
//             height: 270px;
//         }
//     }
// }

// @media (max-width: 750px) {
//     //mobile
//     .Carousel {
//         .carousel-container {
//             max-width: 320px;
//         }

//         .slick-track {
//             margin: 0 auto;
//         }

//         .slick-slide > div {
//             height: 250px;
//         }
//     }
// }
