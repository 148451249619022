@import '../../../styles/variables.scss';

.HubActivityFeed {
  min-height: 700px;

  .CommunityFeed {
    &.not-user-cf {
      padding-top: 0;
      @include thin-scroller-y;
      overflow-y: auto;

      // color in .heading.h2 changed from #012939 to #3A3A3A (GIG-6585)

      .heading {
        padding: 0px;
        h2 {
          margin-top: 0;
          font-family: $raleway;
          font-weight: 700;
          font-size: 28px;
          line-height: 24px;
          color: #3a3a3a;
        }
      }

      .main-container {
        grid-template-columns: 2fr 1fr;

        .left-aside-container {
          display: none;
        }

        .post-story-container {
          min-width: 100%;

          .CommunityFeedPost {
            box-shadow: 0 16px 28px rgba(0, 0, 0, 0.06);
            border-radius: 10px;
            margin-top: 40px;

            &:first-of-type {
              margin-top: 0;
            }
          }
        }

        .right-aside-container {
          aside {
            position: relative;
          }
        }

        .CommunityFeedAsideCard {
          width: unset;
          box-shadow: 0 16px 28px rgba(0, 0, 0, 0.06);
          border-radius: 10px;
        }
      }
    }
  }
}

@media (max-width: 1070px) {
  .HubActivityFeed {
    .CommunityFeed {
      &.not-user-cf {
        justify-items: center;

        .heading {
          margin: 12px 0;

          h2 {
            padding: 0;
          }
        }

        .CommunityFeedAsideCard {
          box-shadow: unset;
        }

        .main-container {
          grid-template-columns: 1fr;
          padding: 0;

          .post-story-container {
            max-width: 100%;

            .CommunityFeedPost {
              box-shadow: unset;
            }
          }
        }
      }
    }
  }
}
