@import '../../../styles/variables.scss';

.register,
.onboarding {
  main {
    padding-top: 0;
  }

  .Toast {
    z-index: 100;
  }
}

.OnboardingWrapper {
  display: flex;
  min-height: 100%;
  width: 100vw;
  position: absolute;
  background-color: #fff;
  // TODO: if this needs to be 99 we should restructure our z-index more accordingly
  z-index: 99;

  overflow-x: hidden;
  overflow-y: auto;
  @include kambeoScroll;
}

@media (max-width: 1000px) {
  .onboarding,
  .register {
    .Header {
      display: none;
    }
  }

  .OnboardingWrapper {
    overflow: hidden;
    max-height: calc(100vh - 35px);

    @supports (-webkit-touch-callout: none) {
      padding-bottom: 15px;
    }
  }
}
