@import '../../../../../styles/variables.scss';

.EditVolunteerOpportunity {
  width: 100%;
  max-height: 600px;
  overflow-y: scroll;

  .EditVolunteerOpportunity-head {
    margin: 30px 40px 0px 40px;
    border-bottom: 1px solid #e9e9e8;

    h2 {
      width: 175px;
      height: 20px;
      font-weight: 700;
      font-size: 22px;
      line-height: 20px;
      color: black;
    }

    p {
      width: 307px;
      height: 17px;
      margin: 7px 0px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #a9aaac;
    }

    span {
      line-height: 3;
    }
  }

  .inner-content {
    padding: 24px 40px;

    .EditVolunteerOpportunity-from--subtitle {
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;
      color: color(grey, dark);
    }

    .details-row {
      margin-bottom: 24px;
      display: flex;

      label {
        font-weight: 700;
        font-size: 14px;
        color: color(grey, dark);
      }

      .DateTimePickerContainer input {
        width: 200px;
      }

      .TextField,
      .Dropdown {
        .input-wrap {
          background: color(light, secondaryBg);
          border: 1px solid color(grey, border);
          border-radius: 10px;
        }

        label {
          span {
            font-weight: 700;
            font-size: 14px;
            color: color(grey, dark);
          }
        }
      }

      .TextArea.description {
        width: 100%;
        height: 150px;

        label textarea {
          height: 130px;
          background: color(light, secondaryBg);
          border: 1px solid color(grey, border);
          border-radius: 10px;
        }

        label .placeholder {
          font-weight: 700;
          font-size: 14px;
          color: color(grey, dark);
          margin-left: 0;
          opacity: 1;
          top: -2px;
        }
      }

      .Dropdown {
        flex: 1;
      }

      .details-col {
        flex: 1;
        margin-right: 20px;

        &:last-child {
          margin: 0;
        }
      }
    }

    .form-buttons {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 20px;
      }
    }
  }
}

@media (max-width: 820px) {
  .EditVolunteerOpportunity {
    .inner-content .details-row {
      display: flex;
      flex-direction: column;

      .details-col {
        margin-bottom: 10px;
      }
    }

    .inner-content {
      justify-content: flex-start;
    }
  }
}
