@import '../../../../styles/variables.scss';

.MyTransactions {
  display: flex;
  flex-direction: column;
  margin: 30px;
  width: 100%;

  .Modal {
    .double-side-modal {
      background-color: transparent;
    }
  }

  .table-link {
    text-decoration: none;
    transition: all 0.2s;

    &:hover {
      color: color(purple, dark);
    }
  }

  .mobile-filter-button {
    display: none;
  }

  .mobile-filter-container {
    right: 0;
    height: 100%;
    top: 80px;
    overflow: hidden;
    background-color: color(light, secondaryBg);
    width: 0;
    z-index: 6;
    transition: width 0.3s;
    position: fixed;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    font-feature-settings:
      'pnum' on,
      'lnum' on,
      'salt' on,
      'ss09' on;
    color: color(grey, dark);

    .filter-header {
      margin: 10px 0;
      padding-left: 28px;
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 64px;

      i {
        font-size: 16px;
      }

      span {
        margin-left: 17px;
      }
    }

    .filter-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 25px;

      span {
        width: 100%;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 24px;
        font-feature-settings:
          'pnum' on,
          'lnum' on;
        color: color(grey, dark);
        text-align: left;
        margin-bottom: 12px;
      }

      .filter-item {
        width: 100%;
        background: color(light, secondaryBg);
        border: 1px solid color(grey, border);
        box-sizing: border-box;
        border-radius: 10px;
        transition: all 0.2s;
        margin-bottom: 16px;
        padding: 10px 0 10px 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        &.active {
          border-color: color(grey, dark);
        }
      }
    }

    .btn-container {
      display: flex;
      flex-direction: column;
      padding: 0 25px;
      align-items: center;
      justify-content: center;
      margin: 16px 0;

      button {
        width: 100%;
        background-color: color(light, secondaryBg);
        border: 1px solid color(plum, dark);
        box-sizing: border-box;
        border-radius: 22px;
        color: color(plum, dark);
        height: 44px;

        &.submit {
          margin-top: 16px;
          color: color(light, secondaryBg);
          background-color: color(plum, dark);
        }
      }
    }
  }

  .PopupMenu.payment-status-popup-menu {
    width: 165px;

    .iconImg {
      width: 12px;
      height: 12px;
      margin-right: 14px;
    }
  }

  .title-container {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 30px;
    align-items: flex-end;

    .donation-type-switch {
      z-index: 2;
      position: absolute;
      place-self: center;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 100%;
      font-weight: 700;

      .btn {
        width: 226px;
        height: 41px;
        line-height: 41px;
        text-align: center;
        color: color(plum, dark);
        cursor: pointer;
        background: color(light, secondaryBg);
        border: 1px solid color(grey, border);
        transition: all 0.2s;

        &.active,
        &:hover {
          background: color(grey, activeGray);
          border: 1px solid color(plum, light);
        }

        &:last-of-type {
          border-radius: 0px 10px 10px 0px;
        }

        &:first-of-type {
          border-radius: 10px 0px 0px 10px;
        }
      }
    }

    .button-export {
      button {
        height: 44px;
      }
    }

    .context-menu-container {
      z-index: 3;
      display: flex;
      flex-flow: row nowrap;
      color: color(light, fontColor);
      transition: color 0.3s ease-in-out;

      .title {
        font-weight: 800;
        font-size: 20px;
        margin-right: 10px;
        height: 44px;
        line-height: 44px;
      }

      .menu-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s ease-in-out;

        &.show {
          transform: rotate(180deg);
        }
      }

      &.show {
        color: color(purple, darker);
      }

      &:hover {
        color: color(purple, darker);
        cursor: pointer;
      }
    }

    .filter-dropdown {
      display: flex;
      justify-content: center;
      margin-left: 20px;
      min-width: 205px;

      .input-wrap {
        height: 42px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 16px;
        color: #012939;
        padding: 0 23px 0 16px;

        .menu-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.3s ease-in-out;

          &.show {
            transform: rotate(180deg);
          }
        }

        .label {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          img {
            width: 12px;
            height: 12px;
            margin-right: 14px;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }

      label {
        z-index: 1;
        bottom: -11px;
        left: 9px;
        background-color: white;
        color: color(grey, dark);
        font-weight: normal;
        position: relative;
        padding: 5px;
        width: fit-content;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .button-export {
      margin-left: auto;
    }
  }

  .content-container {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;

    .TableComponent {
      width: 100%;

      .payment-status {
        padding: 5px 8px;
        border-radius: 8px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &.paid,
        &.active {
          background-color: #b3f7dd;
          color: #4abea9;
        }

        &.won {
          //Displayed as a "Pay Now" Auction Item button
          cursor: pointer;
          background-color: color(purple, dark);
          color: #ffffff;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          -webkit-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          -moz-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

          &:hover {
            background-color: color(purple, darkest);
            transition: background-color 0.2s linear;
          }
        }

        &.unpaid,
        &.outbid,
        &.canceled {
          background-color: #fad2cf;
          color: #ec564e;
        }

        &.refunded,
        &.in-progress,
        &.none {
          background-color: #b8b8b3;
          color: #3a3a3a;
        }
      }
    }
  }
}

.DemoPanel {
  padding: 30px;

  .panel-row {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 20px;
    gap: 6px;

    .label {
      font-weight: 700;
      font-size: 14px;
      color: #3a3a3a;
    }
    .value {
      color: #616161;
      font-weight: 400;
    }
  }
}

@media (max-width: 768px) {
  .MyTransactions {
    overflow-y: auto;
    overflow-x: hidden;
    margin: 30px 0 30px 30px;
    padding-right: 30px;

    .mobile-filter-container {
      &.active {
        width: 100%;
        overflow: auto;
        max-height: calc(100vh - 147px);
      }
    }

    .mobile-filter-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid color(grey, dark);
        box-sizing: border-box;
        border-radius: 10px;
        width: 44px;
        height: 44px;
        i {
          font-size: 18px;
        }
      }
    }

    .title-container {
      .Dropdown.filter-dropdown.status {
        display: none;
      }

      .button-export {
        display: none;
      }
    }
  }
}

@media (max-width: 900px) {
  .MyTransactions {
    .title-container {
      flex-direction: column;

      .donation-type-switch {
        position: relative;
        margin: 30px 0;
        width: 100%;
        display: flex;

        .btn {
          flex-grow: 1;
          width: auto;
          padding: 0 10px;

          span {
            display: none;
          }
        }
      }
    }
  }
}
