@import '../../../styles/variables.scss';

.ContactDonor {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  padding: 0px 40px 40px;
  max-height: 850px;
  overflow: auto;

  .Modal {
    &.add-donation {
      .content {
        max-width: 640px;
      }
    }

    .content {
      &.refund-content,
      &.thank-you-content {
        flex: inherit;
      }
    }

    .thank-you-modal {
      flex: 1;
      padding: 40px;

      .title {
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: 700;
        display: flex;
        flex: 1;
        justify-content: center;
      }

      .thank-you-actions {
        margin-top: 20px;
        display: flex;
        flex: 1;
        justify-content: center;
      }
    }
  }
}
