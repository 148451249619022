@import '../../../styles/variables.scss';

.card-header:hover {
  .cover-image-holder {
    transform: scale(1.1);
    transition: all 0.25s ease-in-out;
  }
}

.card-header {
  flex-grow: 1;
  position: relative;
  width: 100%;
  max-height: 159px;
  overflow: hidden;

  .cover-image-holder {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: color(grey, pale);
    transition: all 0.25s ease-in-out;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &.no-cover {
      background-size: unset;
    }
  }

  img {
    object-fit: cover;
    object-position: center;
  }

  .profile-img {
    background-color: #e9e9e8;
    position: absolute;
    top: 33px;
    left: 33px;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    overflow: hidden;

    img {
      width: 44px;
      height: 44px;
    }

    .cls-1 {
      fill: color(grey, lighter);
    }
    .placeholder-image {
      width: 44px;
      height: 44px;
      opacity: 0.3;
      background-image: url('../../../assets/profile.svg');
    }
    svg {
      transform: translateY(5px);
    }
  }

  .card-type {
    position: absolute;
    right: 30px;
    top: 25px;
    height: 30px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    padding: 3px 12px;
    background-color: #ededec;
    display: flex;
    align-items: center;
  }
}
