@import '../../../../styles/variables.scss';

.HubStoreSingleItem {
  .ListCard {
    margin: 0;
    min-width: 268px;
    max-width: 268px;
    height: 392px;
    flex: unset;
    cursor: pointer;
    box-shadow: unset;
    border: 1px solid color(grey, border);
    box-sizing: border-box;

    .cover-img {
      height: 268px;
    }

    .store-item-content {
      display: flex;
      flex-direction: column;

      .title {
        font-weight: 800;
        font-size: 16px;
        color: color(grey, dark);
      }

      .desc {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 55px;
        font-weight: 700;
        font-size: 14px;
        color: color(grey, light);
      }

      .price {
        color: color(grey, dark);
        font-weight: 500;
        font-size: 16px;

        &.locked {
          color: color(grey, text);

          i {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .HubStoreSingleItem {
    .ListCard {
      width: 324px;
      height: 470px;

      .cover-img {
        height: 324px;
        max-width: 324px;
        max-height: 324px;
      }
    }
  }
}

@media (max-width: 680px) {
  .HubStoreSingleItem {
    .ListCard {
      height: 450px;
      max-width: unset;

      .cover-img {
        height: 205px;
        max-width: unset;
        max-height: 205px;
      }
    }
  }
}
