@import '../../styles/variables.scss';

@keyframes modalFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

main {
  max-height: 100%;
}

.light {
  main {
    background-color: color(light, secondaryBg);
    color: color(light, fontColor);
  }
}

.dark {
  main {
    background-color: color(dark, secondaryBg);
    color: color(dark, fontColor);
  }
}

.light {
  .Header {
    background-color: color(light, secondaryBg);
    color: color(light, fontColor);
  }
}

.dark {
  .Header {
    background-color: color(dark, primaryBg);
    color: color(dark, fontColor);
  }
}

.Gigit {
  &.embedded {
    .Header {
      display: none;
    }
  }
}

.Header {
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  height: 80px;
  z-index: 7;

  .cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: color(purple, dark);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;

    p {
      color: color(light, secondaryBg);
      max-width: 500px;
      padding: 0 20px;
      margin: 0 0 10px 0;
      text-align: center;
      font-size: 14px;
      line-height: 26px;
    }

    .Button {
      button {
        background-color: color(light, secondaryBg);
        color: color(purple, dark);
      }
    }
  }

  .Modal {
    .content {
      .add-item-modal {
        flex: 1;
        overflow: hidden;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .Portrait {
          display: flex;
          margin: 40px 0;
          justify-content: center;
          align-items: center;
        }

        .item-name {
          text-align: center;
          font-size: 32px;
          font-weight: 700;
        }

        .item-group {
          margin-top: 5px;
          color: color(grey);
          text-align: center;
          font-size: 18px;
        }

        .item-price {
          text-align: center;
          margin-top: 5px;
          font-size: 24px;
          font-weight: 500;
        }

        .cart-info {
          margin: 30px 30px 0;
          border-top: 1px solid color(grey, light);
          display: flex;
          flex: 1;

          .item-count,
          .item-total {
            display: flex;
            padding: 15px 0;
            flex: 1;
            font-weight: 500;
            font-size: 14px;
          }

          .item-total {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
          }
        }

        .add-item-actions {
          display: flex;
          flex: 1;
          margin: 10px 30px 0;
          padding-bottom: 30px;

          .Button,
          button,
          a {
            display: flex;
            flex: 1;
          }

          a {
            display: flex;
            border: 0;
            text-decoration: none;
            font-size: 16px;
            border-radius: 20px;
            justify-content: center;
            align-items: center;
            color: color(dark, fontColor);
            background-color: color(purple, dark);
            overflow: hidden;
            min-width: 80px;
            min-height: 40px;
            margin-right: 10px;
            font-weight: 700;

            &:hover {
              background-color: color(purple);
            }
          }

          .Button {
            &:first-child {
              margin-right: 10px;

              button {
                background-color: color(light, secondaryBg);
                color: color(grey);
                border: 3px solid color(grey);

                &:hover {
                  border: 3px solid color(purple);
                  color: color(purple);
                }
              }
            }

            &:last-child {
              margin-left: 10px;
            }
          }
        }
      }

      .Login {
        background-image: none;

        &:before {
          content: none;
        }
      }
    }
  }

  &.no-fixed {
    position: relative;
  }

  &.fluid {
    .wrap {
      max-width: 100%;
    }
  }

  &.border {
    box-shadow: none;
    border-bottom: 1px solid color(grey, lightest);
  }

  .wrap {
    height: 100%;
    max-width: $wrap-size;
    display: flex;
    align-items: center;
    margin: 0 20px;

    .user-search-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: left;
      padding-left: 60px;
    }

    .logo {
      position: relative;
      left: 15px;

      .gigit-logo {
        display: block;
        height: auto;
        width: 150px;
        background-size: cover;

        img {
          width: 150px;
          display: block;
        }
      }
    }

    .navigation {
      width: 250px;
      display: flex;
      justify-content: flex-end;
      z-index: 3;
      font-family: $raleway;

      &.left {
        justify-content: flex-start;
        margin-left: 25px;

        .main-menu-nav {
          .main-menu-item {
            margin-left: 30px;
          }
        }
      }

      .menu-profile-icon {
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        cursor: pointer;
      }

      .main-menu-nav {
        display: flex;
        list-style-type: none;
        justify-content: center;
        padding: 0;
        align-items: center;
        height: 24px;

        .log-in-btn {
          background: color(light, secondaryBg);
          color: color(grey, dark);
          font-weight: 700;
          font-size: 16px;
          padding: 0 28px;
          border-radius: 22px;
          font-family: $lato;
          height: 44px;
          line-height: 44px;
          border: 1px solid color(grey, empty2);
          display: flex;
          white-space: nowrap;
        }

        .main-menu-item.notifications {
          .Notifications {
            &:before {
              left: 150px;
            }
          }
        }

        .icon {
          width: 14px;
          height: 14px;
          background-color: #2b2b2b;

          &:hover {
            cursor: pointer;
            background-color: color(purple, dark);
          }

          &.i-want-to {
            mask-image: url(../../assets/header/add.svg);
          }

          &.search {
            mask-image: url(../../assets/header/search.svg);
          }

          &.show {
            background-color: color(purple, dark);
          }
        }

        .link {
          display: flex;
          position: relative;
          text-decoration: none;
          align-items: center;
          color: color(light, fontColor);

          i {
            color: color(grey, nav);
          }

          &.logout {
            color: color(red, soft);
          }

          &:hover {
            cursor: pointer;

            & > span,
            i {
              color: color(purple, dark) !important;
            }

            & > .icon {
              background-color: color(purple, dark);
            }
          }
        }

        .main-menu-item {
          position: relative;
          min-width: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          height: 44px;
          line-height: 44px;
          border: none;
          border-radius: 30px;
          transition: all 0.2s;
          background-color: color(light, secondaryBg);
          margin: 0 6px;

          &:hover {
            cursor: pointer;
            background-color: color(grey, pale);
          }

          i {
            font-size: 20px;
            font-weight: 500;
            transition: all 0.2s linear;

            &.show {
              transform: rotate(90deg);
            }
          }

          .title-container {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            height: 100%;

            span {
              margin-right: 12px;
            }

            &.show {
              color: color(purple, dark);
            }
          }

          &.with-text {
            border: 0;

            span {
              color: color(grey, nav);
            }

            &:last-child {
              margin-left: 10px;
            }
          }

          &.with-icon {
            &:first-child {
              margin-left: 20px;
            }

            span {
              margin-left: 10px;
            }
          }

          &.cart,
          &.chat {
            width: 44px;
            height: 44px;
            color: color(grey, dark);
            display: block;
            text-align: center;

            &:hover {
              color: color(grey, dark);
            }

            i {
              font-size: 16px;
              font-weight: 700;
              color: #2b2b2b;
            }

            .count {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              top: 5px;
              right: 5px;
              z-index: 2;
              height: 12px;
              width: 12px;
              font-size: 10px;
              border-radius: 50%;
              font-weight: 700;
              border: solid 2px color(light, secondaryBg);
              color: color(dark, fontColor);
              background-color: color(red, soft);
            }
          }

          &.dashboard {
            margin-right: 30px;
          }

          &.getting-started {
            justify-content: center;
            align-items: center;
            display: flex;

            &:last-child {
              margin-right: 0;
            }

            a {
              height: 38px;
              width: 70px;
              line-height: 35px;
              background-color: color(purple, dark);
              color: #fbfbfb;
              border-radius: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 20px;
              font-weight: 500;
              box-shadow: $box-shadow;
            }
          }

          .user-context-menu {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            max-width: 300px;

            .link {
              position: relative;
              width: 100%;
              font-weight: bold;
              font-size: 14px;

              i {
                font-size: 18px;
              }

              img,
              i {
                margin-right: 26px;
              }

              a,
              span,
              i {
                color: color(grey, darker);

                &:hover {
                  cursor: pointer;
                  color: color(purple, dark);

                  &:hover {
                    color: color(purple, dark);
                  }
                }
              }

              &.username {
                font-weight: 700;
                font-size: 20px;
              }

              .metric {
                margin-left: auto;
              }

              .red-dot {
                position: absolute;
                left: -15px;
                width: 8px;
                height: 8px;
                background-color: color(red, soft);
                border-radius: 50%;
              }
            }
          }

          .Notification {
            .status {
              &.unread {
                i {
                  color: color(pink, base);
                }
              }

              &.read {
                i {
                  color: color(grey, base);
                }
              }

              i {
                font-size: 12px !important;
              }
            }
          }

          .notification-count {
            color: color(dark, fontColor);
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            background-color: color(red, dark);
            height: 16px;
            width: 16px;
            line-height: 16px;
            font-size: 10px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
          }

          i {
            font-size: 21px;
            color: color(grey, nav);
          }

          &.header-context-menu {
            .ContextMenu {
              position: absolute;
              top: 66px;
              right: -100px;
              padding: 20px;
              width: 194px;
              border-radius: $border-radius;
              box-shadow: $box-shadow;
              background: color(light, secondaryBg);

              &:after {
                width: 70px;
                height: 80px;
                right: auto;
                left: 0;
                top: -70px;
              }

              &::before {
                display: none;
              }

              .title {
                max-height: 30px;
                padding: 10px 25px;
                background-color: color(light, secondaryBg);
                border-bottom: 0;
                color: color(grey, base);
                font-size: 24px;
                font-weight: 900;
                line-height: 40px;
              }

              ul {
                display: grid;
                gap: 10px;

                li {
                  min-height: 0;
                  padding: 5px 25px;
                  max-height: 20px;
                  border-bottom: 0;
                  line-height: 20px;

                  a,
                  span {
                    color: color(purple, darker);

                    span {
                      color: color(purple, darker);
                    }

                    &:hover {
                      cursor: pointer;

                      a,
                      span {
                        color: color(purple, dark);

                        &:hover {
                          color: color(purple, dark);
                        }
                      }
                    }
                  }
                }
              }

              a {
                justify-content: flex-start;
              }

              span {
                &:hover {
                  color: color(purple, dark) !important;
                }
              }
            }
          }

          &.i-want-to {
            i {
              font-size: 26px;
              height: 22px;
            }

            .ContextMenu {
              position: absolute;
              top: 66px;
              line-height: 40px;
              box-shadow: $box-shadow;
              border-radius: $border-radius;
              background: color(light, secondaryBg);
              min-width: 250px;
              right: 0;

              .inner {
                display: flex;
                flex-flow: row nowrap;

                .column {
                  display: flex;
                  flex-direction: column;
                  padding-right: 50px;

                  &.last {
                    padding: 0 0 0 50px;
                    border-left: 2px solid color(grey, lightest);
                  }
                }
              }

              &.fr {
                min-width: 340px;
              }

              &.es {
                min-width: 300px;
              }

              &:after {
                width: 250px;
                height: 80px;
                left: 0;
                top: -90px;
              }

              &::before {
                display: none;
              }

              .title {
                padding: 10px 25px 20px 25px;
                background-color: #fff;
                color: color(grey, base);
                border-top-right-radius: 7px;
                border-top-left-radius: 7px;
                border-bottom: 0;
                font-size: 20px;
                font-weight: 900;
                line-height: 40px;
                max-height: 30px;
              }

              ul,
              li {
                list-style: none;
              }

              ul {
                margin-bottom: 15px;
                padding: 0;

                li {
                  a {
                    text-decoration: none;
                  }
                }
              }

              a {
                justify-content: flex-start;
              }

              span {
                &:hover {
                  color: color(purple, dark) !important;
                }
              }
            }
          }

          &.discover-menu {
            .ContextMenu {
              position: absolute;
              right: -260px;
            }
          }

          &.for-organization-menu {
            .ContextMenu {
              right: -240px;

              &:after {
                width: 140px;
                height: 80px;
                right: auto;
                left: -10px;
                top: -70px;
              }
            }
          }

          &.language-menu {
            .title-container {
              &:hover {
                cursor: pointer;
              }
            }

            .ContextMenu {
              min-width: 100px;
              right: 0;
            }
          }

          &.user-menu {
            margin: 0;
            padding: 0;

            &:hover {
              background-color: unset;
            }

            .title-container {
              &:hover {
                cursor: pointer;
              }
            }

            .ContextMenu {
              right: 10px;
            }
          }
        }

        .menu-icon {
          width: 30px;
          height: 25px;
          position: relative;
          margin: 20px 35px 20px auto;
          transform: rotate(0deg);
          transition: 0.2s ease-in-out;
          cursor: pointer;

          span {
            display: block;
            position: absolute;
            height: 4px;
            width: 100%;
            background: color(light, fontColor);
            border-radius: 4px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: 0.1s ease-in-out;

            &:nth-child(1) {
              top: 0;
            }

            &:nth-child(2),
            &:nth-child(3) {
              top: 8px;
            }

            &:nth-child(4) {
              top: 16px;
            }
          }

          &.open {
            span:nth-child(1) {
              top: 8px;
              width: 0;
              left: 50%;
            }

            span:nth-child(2) {
              transform: rotate(45deg);
            }

            span:nth-child(3) {
              transform: rotate(-45deg);
            }

            span:nth-child(4) {
              top: 8px;
              width: 0;
              left: 50%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .Header {
    .wrap {
      margin: 0 10px;
    }
  }
}

@media (max-width: 1010px) {
  .Header {
    .wrap {
      .navigation {
        .main-menu-nav {
          .main-menu-item {
            &.menu-profile-icon {
              &.header-context-menu {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 790px) {
  .Header {
    position: static;
    height: auto;

    .cookies {
      position: fixed;
      bottom: 60px;
      left: 0;
      width: 100%;
      height: 150px;
      background-color: color(purple, dark);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 40px 0;

      p {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;

      .user-search-container {
        display: none;
      }

      .gigit-logo {
        padding-top: 5px;
      }

      .navigation {
        flex: 1;

        .main-menu-nav {
          .main-menu-item {
            &.getting-started {
              a {
                padding: 0 12px;
              }
            }
          }
        }
      }
    }

    .Modal {
      .add-item-modal {
        overflow: auto;
        .add-item-actions {
          flex-direction: column;

          .Button {
            &:first-child {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
