@import '../../../../styles/variables.scss';

.PageComponentPaginatorContainer {
  .page-container {
    padding-bottom: 44px;
  }

  .PageComponentPaginator.simple {
    justify-content: space-between;
  }

  .PageComponentPaginator {
    display: flex;
    flex-direction: row;
    position: absolute;

    bottom: 0;
    left: 0;
    right: 0;
    max-width: 350px;
    height: 44px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;

    border: 1px solid #f5f6f7;
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    box-sizing: border-box;
    font-weight: bold;
    text-decoration-color: blue;
    justify-content: center;

    .arrow-button {
      font-size: 16px;
      border: none;
      background: none;

      border-radius: 4px;

      width: 32px;
      height: 32px;

      align-self: center;
      cursor: pointer;
      margin: 0 10px;
      font-weight: bold;
      &.left {
        justify-self: flex-start;
      }

      &.right {
        justify-self: flex-end;
      }
    }

    .page-indicator-container {
      display: flex;
      flex-direction: row;
      margin: 0 auto;
    }

    .separator {
      align-self: center;
      margin: 0 2px;
      color: #012939;
      width: 30px;
      font-size: 16px;
      text-align: center;
    }

    .page-indicator {
      margin: 0 2px;
      align-self: center;
      cursor: pointer;
      font-size: 16px;
      width: 60px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #012939;
      &:hover {
        color: color(light, fontColor);
        background-color: #f3f1ec;
      }
      &.current {
        background: #5e51ab 0% 0% no-repeat padding-box;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
}
