@import '../../styles/variables.scss';

.DonationDestinationModal {
  .destination-content {
    padding: 0 30px;
  }
}

@media (max-width: 820px) {
  .Modal {
    .content {
      min-width: unset;
    }
  }
}
