@import '../../styles/variables.scss';

.MemberAddModal-Con {
  .modal-close-icon {
    position: absolute;
    right: 16px;
    top: 24px;
  }
}

.AddMemberModal {
  min-width: 300px;
  width: 700px;

  &.no-modal {
    max-width: unset;
    width: 100%;
    .header-container {
      padding: 30px 0;
    }
    .contact-list {
      min-height: unset;
    }
  }

  .header-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 30px;

    .title {
      color: color(light, fontColor);
      font-weight: bold;
      font-size: 32px;
      margin-bottom: 7px;
    }

    .description {
      color: color(grey, base);
      font-size: 16px;
      text-align: center;

      .highlight {
        font-weight: 700;
      }
    }

    .confirmed-invites-container {
      color: color(grey, base);
      font-weight: bold;
      font-size: 13px;

      &.margin-top {
        margin-top: 25px;
      }
    }

    .search-container {
      margin-top: 30px;
      width: 100%;
      position: relative;

      .TextField {
        width: 100%;

        i {
          color: color(grey, darkest);
          font-weight: 200;
        }

        .input-wrap:focus-within {
          border-color: color(purple, dark);

          i {
            color: color(purple, dark);
          }
        }

        // Render role dropdown picker inside textedit
        .role-dropdown-container {
          display: flex;
          flex-direction: row;

          .invite-as-label {
            align-self: center;
            margin-left: 20px;
          }

          .role-dropdown {
            border: none;
            flex-direction: row-reverse;

            select {
              padding-left: 10px;
              padding-right: 30px;
              text-indent: unset;
            }
          }
        }
      }

      .search-list {
        position: absolute;
        width: 100%;
        background-color: #ffffff;
        box-shadow: $box-shadow;
        z-index: 100;
        border-radius: 7px;

        & .contact-row:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .contact-row {
    display: flex;
    flex-flow: row nowrap;
    border-bottom: solid 1px #cfd5d6;
    padding: 15px;
    align-items: center;

    .contact-info {
      display: flex;
      flex-flow: column nowrap;
      margin: 0 15px;

      .name {
        font-size: 16px;
        color: color(light, fontColor);
      }
      .description {
        display: flex;
        flex-flow: row wrap;
        font-size: 13px;
        align-items: center;

        .status {
          padding: 0 5px;
        }
        .reminder {
          color: color(purple, dark);

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .action-button {
      margin-left: auto;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .contact-list {
    background-color: #f3f1ec;
    padding: 35px;
    max-height: 410px;
    min-height: 410px;
    overflow: auto;
  }
}

@media (max-width: 680px) {
  .AddMemberModal {
    width: unset;
  }
}
