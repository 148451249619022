@import '../../styles/variables.scss';

.Register {
  display: flex;
  flex: 1;
  position: relative;
  box-sizing: border-box;

  .info-wrapper {
    .title {
      font-weight: 700;
      font-family: $raleway;
    }
    .text {
      font-weight: 900;
      font-family: $raleway;
    }
  }
}
@media (max-width: 1000px) {
  .Register {
    display: flex;
    flex-flow: column nowrap;
    // flex-flow: column nowrap;
    // max-height: calc(100vh - 77px);
  }
}
// @media (max-width: 680px) {
//   .Register {
//     .FormSection {
//       // padding-bottom: 0;
//       // height: calc(100vh - 250px);
//     }
//   }
// }

// @media (max-height: 680px) {
//   // .Register {
//   //   .FormSection {
//   //     .form-page {
//   //       min-height: unset;
//   //       height: calc(100vh - 250px - 94px);
//   //       max-height: calc(100vh - 250px - 94px);
//   //     }
//   //   }
//   // }
// }
