@import '../../styles/variables.scss';

.FormSteps {
  width: 100%;
  flex: 1;
  display: flex;

  .step {
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    flex: 1;

    &.current,
    &.complete {
      .circle {
        transform: translateY(0px);
        height: 18px;
        width: 18px;
        border: 4px solid color(purple, dark);
        background-color: color(light, secondaryBg);
      }

      .label {
        padding-top: 5px;
      }
    }

    &.complete {
      .circle {
        i {
          opacity: 1;
        }
      }

      &:after {
        width: 100%;
      }
    }

    .circle {
      transform: translateY(2px);
      position: relative;
      transition: all 50ms linear;
      height: 0px;
      width: 0px;
      border-radius: 50%;
      border: 10px solid color(blue, lighter);
      background-color: color(light, secondaryBg);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      transition-delay: 150ms;

      i {
        transition: all 50ms linear;
        transform: translateY(1px);
        opacity: 0;
        color: color(purple, dark);
        font-size: 10px;
        transition-delay: 150ms;
      }
    }

    .label {
      transition: all 50ms linear;
      font-size: 15px;
      color: color(grey);
      padding-top: 10px;
      transition-delay: 150ms;
    }

    &:before,
    &:after {
      content: ' ';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 12px;
      left: 50%;
    }

    &:before {
      opacity: 0.25;
      background-color: color(grey);
    }

    &:after {
      transition: all 150ms ease-in;
      width: 0%;
      background-color: color(purple, dark);
    }

    &:last-child {
      &:before,
      &:after {
        content: none;
      }
    }
  }
}
