@import '../../../../styles/variables.scss';

.UserInfo {
  .pwd-row {
    position: relative;
    margin-bottom: 10px;
    &:hover {
      i {
        display: inline-block;
      }
    }

    i {
      display: none;
      cursor: pointer;
      font-size: 24px;
      position: absolute;
      right: 15px;
      top: 38px;
    }
  }

  div {
    &[class*='Too weak'] {
      .input-wrap {
        border: 1px solid color(red, soft);
      }
    }
    &[class*='Medium'] {
      .input-wrap {
        border: 1px solid color(green, soft);
      }
    }
    &[class*='Strong'] {
      .input-wrap {
        border: 1px solid color(green, neon);
      }
    }
  }
  ul {
    li {
      &.valid {
        color: green;
      }
    }
  }

  .age-verify-row {
    display: flex;
    justify-content: left;
    padding: 10px;
  }

  .user-agreement {
    .eula {
      a {
        color: color(grey, dark);
        text-decoration: none;
        font-weight: 700;
      }
    }
  }
}
