@import '../../../../styles/variables.scss';

.PopupMenu {
  &.HubManagementFocusAreas-popup-menu {
    margin-left: -150px;
    margin-top: -10px;
    width: max-content;
  }
}

.HubManagementFocusAreas {
  padding: 60px;

  .header-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    h2 {
      font-weight: 700;
      font-size: 39px;
      color: color(grey, dark);
      margin: 0;
    }
  }

  .filter-section {
    display: flex;
    justify-content: space-between;

    .TextField {
      height: 44px;
      background-color: transparent;
      margin-bottom: 15px;
      max-width: 350px;
      .input-wrap {
        max-width: unset;
        width: 100%;
        height: 44px;
        line-height: 44px;
        background: #f5f5f5;
        border: 0px;
        border-radius: 10px;
        display: flex;

        input {
          background-color: transparent;
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;

          &::placeholder {
            color: #a9aaac;
          }
        }
      }
    }

    .sort {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: color(grey, lightester);
      width: 48px;
      height: 48px;
      border-radius: 24px;
      color: color(grey, dark);
      font-size: 18px;
      cursor: pointer;
      border: 1px solid color(grey, lightester);
      transition: all 0.2s;

      &:hover {
        border: 1px solid color(blue, lighter);
      }
    }
  }

  .section-title {
    height: 50px;
    line-height: 50px;
    padding-left: 16px;
    color: color(light, dark);
    margin-bottom: 38px;
    border-top: 1px solid color(light, shadow);
    border-bottom: 1px solid color(grey, lighter);
    font-size: 14px;
    font-weight: 700;

    h3 {
      margin: unset;
      font-style: normal;
      font-weight: 400px;
      font-size: 17px;
    }
  }

  .table-section {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
    justify-content: center;

    .Loader {
      place-self: center;
      font-size: 24px;
    }

    .hub-focus-area {
      position: relative;
      border-bottom: 1px solid color(light, shadow);
      display: flex;
      column-gap: 24px;
      align-items: center;
      justify-content: center;
      padding: 0 16px 24px 16px;

      .focus-area-name {
        flex-grow: 1;
      }

      .context-menu-container {
        flex-grow: 0;
        display: flex;
        justify-content: flex-end;

        i {
          cursor: pointer;
          font-size: 24px;
        }
      }
    }
  }

  .add-focus-areas-modal {
    width: 784px;
    height: 568px;
    background-color: color(light, secondaryBg);
    border-radius: 10px;
    padding: 48px;

    .ModalHeader {
      position: absolute;
      right: 30px;
    }

    .modal-header {
      border-bottom: 1px solid color(dark, borderLight);
      padding-bottom: 20px;
      margin-bottom: 26px;

      h2 {
        margin: 0 0 10px 0;
        font-weight: 700;
        font-size: 22px;
        color: black;
      }
    }

    .button-container {
      margin-top: 21px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      column-gap: 15px;
    }

    .Loader {
      place-self: center;
      font-size: 24px;
    }

    .modal-main {
      display: flex;
      flex-direction: column;
      overflow: auto;
      border-bottom: 2px solid color(dark, borderLight);
      scrollbar-width: thin;
      scrollbar-color: color(grey, border) color(grey, base3);
      width: 100%;
      height: 100%;

      .CauseSelection {
        .form-page {
          .heading {
            display: none;
          }

          .CheckboxCard {
            min-width: 190px;
          }
        }
      }
    }
  }
}
