@import '../../styles/variables.scss';

.OrganizerContacts-list {
  &.hover {
    cursor: none;
  }

  .TextField {
    margin-bottom: 20px;
    margin-top: 40px;

    .input-wrap {
      color: #a9aaac;
      display: flex;
      align-items: center;

      .main-icon {
        color: #a9aaac;
      }

      input {
        height: 60px;
      }
    }
  }
}
