@import '../../../../../styles/variables.scss';
.CommunityFeedFocusAreas {
  padding: 24px;
  box-shadow: 0px 16px 28px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  .focus-area-title {
    font-size: 16px;
    font-weight: 800;
    font-family: $raleway;
  }
  .focus-area-list {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    flex-wrap: wrap;
    .focus-area-chip {
      flex: 0 1 auto;
      padding: 12px 20px;
      margin-right: 9px;
      margin-bottom: 17px;
      background: #f5f5f5;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      opacity: 0.6;

      &:hover {
        cursor: pointer;
      }

      &.selected {
        background: #efeef6;
        color: rgb(94, 81, 171);
        opacity: 1;
        .focus-area-icon {
          filter: invert(36%) sepia(22%) saturate(1663%) hue-rotate(209deg) brightness(89%)
            contrast(88%);
        }
        .fas {
          font-size: 13px;
          margin-right: 15px;
        }
      }

      .focus-area-icon {
        width: 13px;
        height: auto;
        margin-right: 15px;
      }
      .focus-area-name {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .focus-area-action {
    margin-top: 43px;
    .Button {
      flex-grow: 1;

      &.log-button {
        width: 100%;

        button {
          width: 100%;
        }
      }

      button {
        max-width: unset;
        width: 100%;
        padding: 10px 24px;
      }
    }
  }
}
