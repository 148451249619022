@import '../../styles/variables.scss';

.VolunteerApplicationModal {
  .content {
    width: 60%;
    .contact-options-modal {
      width: 350px;
      padding: 10px 40px;
      .contact-options {
        padding: 20px 40px;
        div {
          display: flex !important;
          align-items: center;
          border-radius: 10px;
          padding-top: 5px;
          padding-bottom: 5px;

          a {
            color: black;
            text-decoration: none;

            &:hover {
              color: color(purple, light) !important;
              cursor: pointer;
            }
          }

          i {
            margin-right: 10px;
            margin-top: 5px;
          }
        }
      }
      .actions {
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .Button {
          width: 100%;
          margin-bottom: 20px !important;

          button {
            max-width: unset;
            width: 100%;
          }
        }
      }
    }
  }
  .application-modal-wrapper {
    padding: 50px 42px;
    max-height: 755px;
    overflow: auto;
    display: block;
    .reject-reason-read-only {
      margin-top: 40px;
      .reject-reason-title {
        font-weight: 800;
        font-size: 14px;
      }
      .reject-reason {
        font-weight: 500;
        font-size: 14px;
        margin-top: 13px;
      }
    }
    .reject-reason {
      margin-top: 40px;
      width: 60%;
      .sugges-applicant-checkbox.Checkbox {
        margin-top: 45px;
        label {
          justify-content: flex-start;
          font-size: 14px;
          color: #616161;
        }
      }
      .reject-actions {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        .cancel-button {
          margin-right: 10px;
        }
      }
      textarea {
        padding: 10px 8px;
        text-indent: 0px;
        border: 1px solid #d0d1d1;
        border-radius: 10px;
        height: 170px;
      }
    }
    .user-applicant-info {
      margin-top: 40px;
      border-top: 1px solid #e7e8e8;
      .user-info {
        margin-top: 30px;

        .availability-wrapper {
          .availability-table {
            width: 533px;
            height: 220px;
            background: color(light, secondaryBg);
            border: 1px solid color(cream, grey);
            border-radius: 10px;
            overflow: hidden;

            .section-inner {
              margin: 0;
              padding: 0;
            }

            .table-container {
              table {
                border: unset;

                tr {
                  &:first-of-type {
                    border-bottom: 1px solid color(cream, grey);
                  }
                }

                th {
                  &.time-th {
                    align-items: flex-start;
                    width: 100px;
                    padding-left: 24px;
                  }
                }

                td,
                th {
                  border: unset;
                  font-weight: 500;
                  font-size: 14px;
                  text-align: center;
                  color: black;
                  background: color(light, secondaryBg);
                }
              }
            }
          }
        }
        .user-info-subheading {
          font-weight: 800;
          font-size: 14px;
          margin-top: 30px;
          margin-bottom: 20px;
        }
        .user-info-item {
          color: #616161;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
      .user-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
        .user-info-details {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .title {
          margin-left: 20px;
          font-weight: 900;
          font-size: 21px;
        }

        .status-code {
          margin-left: 10px;
          border-radius: 8px;
          padding: 10px 20px 12px;
          &.approved {
            background: #eef9f7;
            color: #57c0ac;
          }
          &.pending {
            background: #bdbebf;
            color: #3a3a3a;
          }
          &.rejected {
            background: #fdf0ef;
            color: #eb645e;
          }
        }
      }
    }
    .approval-actions {
      display: flex;
      flex-direction: row;
      margin-top: 35px;
      .Button {
        margin-right: 10px;
        button {
          border: 1px solid #bdbebf;
          background: #fff;
          color: #4e4e4e;
          box-shadow: none;
        }
        &.approved-button {
          &.active {
            button {
              border: 1px solid #57c0ac;
              background: #f7fcfb;
              color: #57c0ac;
            }
          }
        }

        &.pending-button {
        }

        &.rejected-button {
          &.active {
            button {
              border: 1px solid #eb645e;
              background: #fdf0ef;
              color: #eb645e;
            }
          }
        }
      }
    }
    .title-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      .title {
        margin-left: 20px;
        font-weight: 900;
        font-size: 21px;
        .application-date {
          display: block;
          font-size: 14px;
          color: #4e4e4e;
          font-weight: 500;
        }
      }
    }
  }
}
