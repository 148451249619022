@import '../../../../styles/variables.scss';

.AuctionItemPlaceBidModal.Modal {
  .content {
    background-color: transparent;

    .modal-header {
      display: none;
    }

    .pickup-and-shipping-options {
      display: flex;
      flex-flow: column;
      max-width: 500px;
      height: 100%;
      width: 100%;
      flex: 1;
      overflow: auto;
      background-color: color(light, secondaryBg);

      &.show {
        display: flex;
      }

      &.hidden {
        display: none;
      }

      .shipping-options {
        margin: 20px 40px 20px;
        flex: 1;

        .shipping-option {
          padding-bottom: 20px;
        }

        .ship-title {
          font-weight: 500;
        }

        .shipping-label {
          margin: 10px 0px 0px 22px;
          font-size: 16px;
          font-weight: 300;

          span {
            font-weight: 500;
            margin-right: 8px;
          }

          &:hover {
            cursor: pointer;
          }

          i {
            margin-right: 5px;

            &.fa-check-circle {
              color: color(purple, dark);
            }
          }
        }
      }
    }

    .select-card-content {
      display: flex;
      flex-flow: column;
      max-width: 500px;
      height: 100%;
      width: 100%;
      flex: 1;
      overflow: auto;
      background-color: color(light, secondaryBg);

      .row {
        display: flex;
        flex-flow: row nowrap;
        padding: 0 15px;
        height: 60px;
        align-items: center;
        border-bottom: 1px solid color(grey, darkest);

        &:hover {
          cursor: pointer;
        }

        .label-container {
          display: flex;
          flex-grow: 1;
          align-items: center;
          .label {
            font-size: 16px;
          }
        }

        .card-action {
          font-size: 24px;

          .selected {
            color: color(purple, dark);
          }
        }

        .icon {
          font-size: 36px;
          margin-right: 10px;

          &.fa-cc-visa {
            color: color(creditCards, visa);
          }

          &.fa-cc-mastercard {
            color: color(creditCards, mastercard);
          }

          &.fa-cc-amex {
            color: color(creditCards, amex);
          }

          &.fa-cc-discover {
            color: color(creditCards, discover);
          }

          &.fa-cc-diners-club {
            color: color(creditCards, diners-club);
          }

          &.fa-cc-jcb {
            color: color(creditCards, jcb);
          }

          &.fa-credit-card {
            color: color(creditCards, credit-card);
          }
        }
      }

      &.show {
        display: flex;
      }

      &.hidden {
        display: none;
      }
    }

    .new-card-form {
      display: flex;
      flex-flow: column;
      max-width: 500px;
      height: 100%;
      width: 100%;
      flex: 1;
      overflow: auto;
      background-color: color(light, secondaryBg);

      &.show {
        display: flex;
      }

      &.hidden {
        display: none;
      }
    }

    .place-bid {
      max-width: 450px;
      display: flex;
      flex-flow: column;
      border-radius: 7px;
      margin: 0px auto;
      flex: 1;
      overflow: auto; //Hotfix for GIG-2183
      width: 100%;
      height: 100%;

      &.show {
        display: flex;
      }

      &.hidden {
        display: none;
      }

      .tabs {
        display: flex;

        .tab {
          flex: 1;
          display: flex;
          height: 60px;
          justify-content: flex-end;
          align-items: flex-end;

          &:hover {
            cursor: pointer;
          }

          span {
            display: flex;
            height: 50px;
            width: auto;
            flex: 1;
            justify-content: center;
            align-items: center;
            background-color: color(grey);
            color: color(dark, fontColor);
            font-size: 24px;
            font-weight: 500;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
          }

          &.active {
            span {
              height: 60px;
              color: color(light, fontColor);
              background-color: color(light, secondaryBg);
            }
          }
        }
      }

      .place-bid-inner {
        overflow: auto;
        background-color: color(light, secondaryBg);
        overflow-y: auto;

        .details {
          .buy-now-blurb {
            text-align: center;
            padding: 10px 0;
            span {
              color: #88949a;
              font-size: 16px;
            }
          }
          .current {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            .leading-bid {
              display: flex;
              flex-direction: row;
              color: color(purple, dark);
              font-size: 18px;
              padding: 5px 0;

              .value {
                padding-left: 5px;
              }
            }

            .bid-amt {
              display: flex;
              flex-direction: row;
              font-size: 12px;
              color: color(grey, light);
              text-align: center;
              justify-content: center;

              .label {
                padding-left: 5px;
              }
            }
          }
        }
      }

      .curve {
        background-color: color(light, secondaryBg);
      }

      .actions {
        margin-top: 20px;
        margin: 20px 15px;

        .Button {
          flex: 1;

          button {
            width: 100%;
          }
        }
      }

      .cover {
        position: relative;
        width: 100%;
        max-height: 150px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
          width: 100%;
          height: auto;
          filter: blur(20px);
        }
      }

      .media-nav {
        padding: 10px 0px 0px;
        margin: 0px;
        display: none;
        list-style-type: none;
        justify-content: center;
        align-items: center;
        flex: 1;

        li {
          margin-left: 8px;

          &:hover {
            color: color(grey);
            cursor: pointer;
          }

          &.active {
            i {
              color: color(grey);
            }
          }

          i {
            font-size: 12px;
            color: color(grey, light);
          }

          &:first-child {
            margin-left: 0px;
          }
        }
      }

      .portrait-auction {
        position: relative;
        margin: -120px auto 0px;
        border-radius: 50%;
        border: 2px solid white;
        height: 120px;
        width: 120px;
        background-size: cover;
        background-position: center center;
        box-shadow: $box-shadow;
      }

      .details {
        padding: 0px 40px;
        margin-top: 12px !important;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;

        .name {
          padding: 0px 40px;
          overflow: hidden;
          text-align: center;
        }

        .description-light {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 300;
          color: #484b4d;
          text-align: center;
          margin: 15px 15px 0 15px;
        }

        .image {
          display: block;
          max-width: 300px;
          height: auto;
          padding: 15px 0px;
        }

        .title-md {
          color: color(light, fontColor);
          font-size: 18px;
          padding: 10px 0px;
        }

        .contact-details-list {
          display: flex;
          flex-flow: column nowrap;
          width: calc(100% + 80px);
          margin: 15px 0px;

          .contact-detail-item {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            height: 60px;
            border-bottom: solid #ebf3f4 1px;
            padding: 0px 15px;

            .icon {
              height: 25px;
              width: 25px;
              margin-right: 10px;
            }

            .label {
              font-size: 16px;
              color: color(light, fontColor);
              margin-right: 10px;
            }

            .item-text {
              flex: 1;
              text-align: end;
              font-size: 16px;
              color: color(grey, font);
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .icon-next {
              height: 16px;
              width: 9px;
              margin-left: 10px;
            }
          }

          .contact-detail-item:hover {
            cursor: pointer;
          }

          .contact-detail-item:first-child {
            border-top: solid #ebf3f4 1px;
          }
        }

        .button-finish {
          display: flex;
          justify-content: center;
          align-self: center;
          width: 100%;
          margin-top: auto;

          button {
            width: 100%;
            margin: 0 15px 15px 15px;

            _::-webkit-full-page-media,
            _:future,
            :root & {
              margin-bottom: $SAFARI_MOBILE_BOTTOM_MENU_HEIGHT;
            }
          }
        }

        .buy-now {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          span {
            &:first-child {
              color: color(purple, dark);
              font-weight: 500;
            }

            &:last-child {
              font-weight: 500;
              font-size: 28px;
            }
          }
        }

        .description {
          position: relative;
          padding: 20px;
          display: flex;
          flex-direction: column;
          min-width: 92%;

          .label {
            height: 10px;
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid color(grey, light);
            font-weight: 700;
            margin-bottom: 10px;
          }

          .quill {
            padding-top: 10px;
            max-height: 167px;
            min-height: 167px;
            overflow: auto;

            p {
              font-size: 14px;
            }
          }
        }

        .buy-now-actions {
          width: 100%;
          padding-bottom: 20px;

          .Button {
            padding: 0px 20px;

            button {
              width: 100%;
            }
          }
        }
      }

      .user-bid {
        .inner-details {
          display: flex;
          flex-direction: column;
          flex: 1;
          margin-top: 10px;
          background-color: color(cream, dark);
          border-top: 1px solid color(grey, darkest);
          border-bottom: 1px solid color(grey, darkest);

          .header {
            display: flex;
            background-color: color(grey, lighter);
            font-size: 14px;
            padding: 10px;
            font-weight: 500;
          }

          .current,
          .increment,
          .bid-amt {
            .label {
              font-size: 12px;
              font-weight: 500;
              display: flex;
              justify-content: center;
              align-items: center;
              color: color(grey, base);

              &.total {
                color: color(light, fontColor);
              }
            }

            .value {
              color: color(grey);
              &.total {
                color: color(light, fontColor);
              }
            }
          }

          .current {
            border-right: 2px solid color(grey, lighter);
          }

          .increment {
            padding: 0px 18px;
          }

          .bid-amt {
            width: auto;
            display: flex;
            justify-content: space-between;
            padding: 10px 15px;

            &.total {
              border-top: 1px solid color(grey, darkest);
            }
          }
        }

        .TextField {
          padding: 10px 15px;
        }

        .disclaimer {
          display: flex;
          flex-direction: column;
          flex: 1;

          div {
            padding: 10px;
            font-size: 12px;
            color: #829096;
          }
        }

        .payment-method {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          padding: 15px;
          color: color(light, fontColor);
          border-bottom: 1px solid color(grey, darkest);

          &:hover {
            cursor: pointer;
          }

          .label {
            font-size: 14px;
            font-weight: 700;
            margin-right: auto;
          }

          .visa-label {
            color: color(grey, font);
          }

          i {
            margin: 10px;

            &.fa-chevron-right {
              margin-left: 15px;
              font-weight: 500;
              font-size: 20px;
              color: color(grey, darkest);
            }
          }
        }

        .shipping-pickup {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          padding: 15px;
          color: color(light, fontColor);
          border-bottom: 1px solid color(grey, darkest);

          &:hover {
            cursor: pointer;
          }

          .label {
            font-size: 14px;
            font-weight: 700;
            margin-right: auto;
          }

          .value {
            color: color(grey, font);
          }

          i {
            margin: 10px;

            &.fa-chevron-right {
              margin-left: 15px;
              font-weight: 500;
              font-size: 20px;
              color: color(grey, darkest);
            }
          }
        }

        .toggle {
          display: flex;
          flex: 1;
          padding: 10px 15px;
          border-top: 1px solid color(grey, light);
          margin-top: 10px;
          justify-content: center;
          align-items: center;

          .label {
            display: flex;
            flex: 1;
            font-weight: 700;
            font-size: 14px;
            position: relative;

            .hover-action {
              margin-left: 5px;
              cursor: pointer;
              position: relative;

              i {
                font-size: 18px;
              }

              &:hover {
                i {
                  color: color(purple, dark);
                }
              }
            }
          }

          .icon {
            &:hover {
              cursor: pointer;
            }

            i {
              font-size: 31px;

              &.fa-toggle-on {
                color: color(purple, dark);
              }
            }
          }
        }
      }

      .timer {
        display: flex;
        flex: 1;
        justify-content: center;
        margin: 25px 0px 0px;
        color: color(grey);
        font-size: 14px;

        .days {
          padding-right: 10px;

          i {
            margin-right: 8px;
          }
        }

        .hours,
        .minutes {
          padding: 0px 10px;
        }

        .hours,
        .days {
          border-right: 1px solid color(grey, lighter);
        }

        .seconds {
          padding-left: 10px;
          border-left: 1px solid color(grey, lighter);
        }
      }
    }

    .shipping-option-label {
      display: flex;
      flex-flow: row;
      flex: 1;
      align-items: center;
      justify-content: center;
      margin-top: 10px;

      .label {
        margin-right: 10px;
        color: #343d43;
        font-size: 16px;
      }

      .option {
        .available-icon {
          font-weight: 500;
          color: color(blue, light);
        }
      }
    }
  }
}

@media (max-width: 1030px) {
  .AuctionItemPlaceBidModal.Modal {
    .content {
      .place-bid {
        .place-bid-inner {
          height: calc(100% - 60px);
        }
      }
    }
  }
}

@media (max-width: 730px) {
  .AuctionItemPlaceBidModal.Modal {
    .content {
      .place-bid {
        flex: 1;
        max-width: none;
        width: 100%;

        .details {
          display: flex;
          padding: 0px;

          .contact-details-list {
            width: 100%;
          }
        }

        .cover {
          max-height: 300px;
          overflow: hidden;

          img {
            filter: blur(0px);
          }
        }

        .media-nav {
          display: flex;
        }

        .timer {
          padding-top: 10px;
        }

        .curve,
        .portrait-auction {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .AuctionItemPlaceBidModal.Modal {
    .content {
      .ModalHeader {
        .close-button {
          z-index: 1;
        }
      }

      .pickup-and-shipping-options.show {
        z-index: 1;
      }

      .place-bid {
        border-radius: 0;
        background-color: #ffffff;

        // In Mobile view, hide tabs and show mobile header.
        .tabs {
          display: none;
        }
      }
    }
  }
}
