@import '../../../styles/variables.scss';

.PopupMenu-Settings-General-languages,
.PopupMenu-Selected-Events-fundraising-events {
  width: 272px;
}

.PopupMenu-Location-country {
  width: 430px;
}

.Settings {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 32px;

  @include thin-scroller-y;

  .tab {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 36px;

    hr {
      width: 100%;
      border: unset;
      border-top: 1px color(dark, borderLight) solid;
    }

    section {
      width: 100%;
      display: flex;
      row-gap: 20px;

      .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        row-gap: 4px;

        .title-section {
          display: flex;
          flex-direction: column;

          h2 {
            font-weight: 700;
            font-size: 16px;
            margin: 0 0 4px 0;
            color: color(plum, grey);
          }

          .header-desc {
            font-weight: 500;
            font-size: 14px;
            color: color(grey, text3);

            a {
              text-decoration: none;
              color: black;
              transition: color 0.2s;

              &:hover {
                color: color(plum, dark);
              }
            }
          }
        }

        .cb-container {
          display: flex;
          column-gap: 16px;

          i {
            font-size: 20px;
            color: color(plum, dark);
            cursor: pointer;

            &.pill {
              font-size: 32px;
              transition: all 0.2s;

              &.fa-toggle-off {
                color: color(grey, lighter);
              }
            }
          }

          .Checkbox {
            label {
              column-gap: 16px;

              p {
                line-height: 20px;
                font-weight: 700;
                font-size: 14px;
                color: black;
              }
            }
          }
        }
      }

      .section-content {
        display: flex;
        flex-direction: column;
      }

      &.language {
        justify-content: space-between;
        align-items: center;

        .languages-context-menu-container {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: color(light, secondaryBg);
          padding: 0 16px;
          width: 272px;
          height: 44px;
          border: 1px solid color(grey, border);
          border-radius: 10px;
        }
      }

      &.connections {
        flex-direction: column;

        .section-content {
          row-gap: 12px;
          padding-top: 24px;

          .settings-item {
            background: color(light, secondattBg);
            border: 1px solid color(grey, border);
            border-radius: 10px;
            display: flex;
            min-height: 90px;
            align-items: center;
            padding: 0 40px;

            .quill {
              background-color: color(light, secondaryBg);
            }

            &.connection {
              position: relative;

              .up-container {
                display: flex;
              }

              .details {
                padding: 20px 0;

                .details-title {
                  font-size: 18px;
                  font-weight: 700;
                }

                .details-subtitle {
                  font-size: 16px;
                  color: #52646c;
                  padding: 10px 0;
                  font-weight: 400;
                }

                p {
                  font-size: 14px;
                  color: #52646c;
                  font-weight: 300;
                  line-height: 21px;
                }
              }

              .icon {
                font-size: 72px;
                min-width: 150px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  height: 72px;
                  width: 72px;
                }

                .fab {
                  &.fa-twitch {
                    color: #5a3e85;
                  }

                  &.fa-stripe-s {
                    color: #6672e5;
                  }
                }
              }

              .action {
                position: absolute;
                top: 45px;
                right: 40px;

                a,
                span {
                  background-color: color(light, secondayBg);
                  color: color(plum, dark);
                  padding: 10px 18px;
                  border: 1px solid color(plum, dark);
                  border-radius: 10px;
                  font-weight: 700;
                  font-size: 14px;
                  box-shadow: $box-shadow;
                  transition: all 0.2s;

                  &:hover {
                    cursor: pointer;
                    color: color(light, primaryBg);
                    background-color: color(plum, dark);
                  }
                }
              }
            }

            &.hover-item {
              flex-direction: column;
              align-items: stretch;

              .details {
                padding-top: 22px;
              }

              &:hover {
                background-color: color(cream, dark);
                cursor: pointer;
              }
            }

            p {
              font-size: 14px;
              padding: 0 0 5px 0;
              margin: 0;
              font-weight: 700;
            }

            a {
              color: color(purple, dark);
              text-decoration: none;

              &:hover {
                color: color(purple);
              }
            }

            .settings-control {
              .fad {
                font-size: 30px;
                color: color(purple, dark);

                &:hover {
                  cursor: pointer;
                }

                &.fa-toggle-off {
                  color: color(grey);
                }
              }
            }

            .details {
              flex: 1;

              .detail {
                font-size: 14px;
                color: color(grey);
                padding-right: 20px;
              }
            }
          }
        }
      }

      &.change-password,
      &.age,
      &.updates,
      &.deactivate-acc,
      &.selected-event {
        justify-content: space-between;
        width: 100%;
      }

      &.selected-event {
        .fundraising-events-context-menu-container {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: color(light, secondaryBg);
          padding: 0 16px;
          width: 272px;
          height: 44px;
          border: 1px solid color(grey, border);
          border-radius: 10px;
        }
      }

      &.location,
      &.emergency-contacts {
        flex-direction: column;
        row-gap: 32px;

        .empty {
          font-size: 14px;
        }

        .wrap {
          display: flex;
          flex-direction: column;
          background: color(light, secondaryBg);
          border: 1px solid color(dark, borderLight);
          border-radius: 10px;

          .item {
            border-bottom: 1px solid color(dark, borderLight);
            display: flex;
            font-weight: 500;
            font-size: 14px;
            color: black;
            padding: 24px 23px;
            align-items: center;

            &:last-of-type {
              border-bottom: unset;
            }

            i {
              cursor: pointer;
              transition: all 0.2s;

              &:hover {
                color: color(purple, dark);
              }

              &:last-of-type {
                margin-left: 16px;
              }
            }

            .address {
              flex-grow: 1;
            }
          }
        }
      }

      &.emergency-contacts {
        .wrap {
          .item {
            justify-content: space-between;

            .person {
              margin-right: 220px;
            }
          }
        }
      }

      &.availability {
        flex-direction: column;
        row-gap: 32px;
        padding-bottom: 32px;

        .availability-table {
          width: 533px;
          height: 220px;
          background: color(light, secondaryBg);
          border: 1px solid color(cream, grey);
          border-radius: 10px;
          overflow: hidden;

          .table-container {
            table {
              border: unset;

              tr {
                &:first-of-type {
                  border-bottom: 1px solid color(cream, grey);
                }
              }

              th {
                &.time-th {
                  align-items: flex-start;
                  width: 100px;
                  padding-left: 24px;
                }
              }

              td,
              th {
                border: unset;
                font-weight: 500;
                font-size: 14px;
                text-align: center;
                color: black;
                background: color(light, secondaryBg);
              }
            }
          }
        }
      }

      &.payment {
        flex-direction: column;

        .card-icon {
          i {
            font-size: 24px;
          }
        }

        .payment-table-loader-con {
          display: flex;
          align-items: center;
          justify-content: center;

          .Loader {
            i {
              font-size: 38px;
            }
          }
        }

        .primary-tag-mobile {
          display: none;
        }

        .primary-tag {
          display: block;
          width: 105px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: color(green, soft);
          font-weight: 500;
          font-size: 14px;
          background: color(green, fade);
          border-radius: 8px;
        }

        .filter-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .right {
            align-self: flex-end;

            .Button {
              button {
                padding: 0;
                line-height: 44px;
                text-align: center;
                height: 44px;
                width: 206px;
              }
            }
          }
        }

        .list-container {
          margin-top: 32px;
        }
      }
    }
  }
}

.LocationFormModal {
  .country-context-menu-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: color(light, secondaryBg);
    padding: 0 16px;
    height: 44px;
    width: 100%;
    border: 1px solid color(grey, border);
    border-radius: 10px;
  }
}

.UserEmailModal {
  .verified-tag {
    width: 115px;
    height: 35px;
    background: color(green, light) 0 0 no-repeat padding-box;
    color: color(green, dark);
    border-radius: 18px;
    font-size: 16px;
    line-height: 35px;
    padding-left: 16px;
    margin-top: 16px;
    align-self: flex-end;
    margin-bottom: 5px;

    span {
      margin-left: 8px;
    }
  }
}

.UserPhoneModal,
.UserEmailModal {
  .content {
    .form-area {
      margin-top: 32px;

      .actions {
        align-items: center;
        justify-content: flex-end;
        display: flex;
        column-gap: 16px;
        margin-top: 32px;
      }

      .TextField {
        label {
          span {
            font-weight: 700;
            font-size: 14px;
            color: color(grey, dark);
          }
        }

        .input-wrap {
          background: color(light, secondaryBg);
          border: 1px solid color(grey, border);
          border-radius: 10px;
          padding: 0 18px;
        }
      }
    }
  }
}

.LocationFormModal,
.EmergencyContactsFormModal,
.UserPhoneModal,
.UserEmailModal {
  .content {
    padding: 48px;

    .ModalHeader {
      display: none;
    }

    .header-custom {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .form-container {
      display: flex;
      flex-direction: column;
      padding: 16px;

      .actions {
        margin-top: 32px;
        display: flex;
        place-content: flex-end;
        column-gap: 16px;
      }

      .details-row {
        margin-bottom: 20px;
        display: flex;

        .TextField {
          label {
            span {
              font-weight: 700;
              font-size: 14px;
              color: color(grey, dark);
            }
          }

          .input-wrap {
            background: color(light, secondaryBg);
            border: 1px solid color(grey, border);
            border-radius: 10px;
            padding: 0 18px;
          }
        }

        .Dropdown {
          flex: 1;

          label {
            span {
              font-weight: 700;
              font-size: 14px;
              color: color(grey, dark);
            }
          }
        }
      }
    }
  }
}

.PaymentMethodModal {
  .content {
    .ModalHeader {
      display: flex;
      padding: 0 40px;

      .modal-close-icon {
        margin: 0;
      }

      .header-container {
        padding: 0;
      }
    }

    .sub-title {
      color: color(plum, dark);
    }

    .Dropdown {
      label {
        span {
          font-weight: 700;
          font-size: 14px;
          color: color(grey, dark);
        }
      }
    }

    .TextField {
      label {
        span {
          font-weight: 700;
          font-size: 14px;
          color: color(grey, dark);
        }
      }

      .input-wrap {
        background: color(light, secondaryBg);
        border: 1px solid color(grey, border);
        border-radius: 10px;
        padding: 0 18px;
      }
    }
  }
}

@media (max-width: 1070px) {
  .Settings {
    padding: 16px;

    .tab {
      section {
        flex-wrap: wrap;

        .header {
          flex-wrap: wrap;
          row-gap: 16px;

          .Button {
            width: 100%;

            button {
              width: 100%;
              max-width: unset;
            }
          }
        }

        &.language {
          hr {
            display: none;
          }
        }

        &.payment {
          .primary-tag-mobile {
            display: block;
            width: 8px;
            background-color: color(green, soft);
            height: 8px;
            border-radius: 4px;
          }

          .primary-tag {
            display: none;
          }
        }

        &.availability {
          .availability-table {
            width: unset;

            .AvailabilitySettings {
              .mobile-container {
                display: none;
              }

              .table-container {
                display: block;

                table {
                  th,
                  td {
                    max-width: 35px;
                  }

                  th {
                    text-align: left;

                    &.time-th {
                      width: 80px;
                      max-width: 80px;
                      padding-left: 6px;
                    }
                  }

                  td {
                    div {
                      max-width: 35px;
                    }
                  }
                }
              }
            }
          }
        }

        &.emergency-contacts,
        &.location {
          .section-content {
            .wrap {
              border: unset;

              .item {
                padding: 10px;

                &:last-of-type {
                  border-bottom: 1px solid color(dark, borderLight);
                }

                .person {
                  margin-right: 40px;
                }
              }
            }
          }
        }

        &.deactivate-acc {
          padding-bottom: 32px;
        }

        &.connections {
          .section-content {
            padding-bottom: 32px;

            .settings-item {
              flex-direction: column;
              padding: 16px;

              &.connection {
                height: max-content;

                .up-container {
                  height: 70px;
                  column-gap: 16px;

                  .icon {
                    min-width: unset;
                    font-size: 60px;
                  }

                  .details {
                    padding: 0;

                    .details-title {
                      font-size: 16px;
                    }

                    .details-subtitle {
                      font-size: 14px;
                    }
                  }
                }

                .action {
                  margin-top: 16px;
                  position: static;
                  width: 100%;

                  span,
                  a {
                    display: block;
                    text-align: center;
                    background-color: color(plum, dark);
                    color: color(light, secondaryBg);
                    border-radius: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .UserPhoneModal,
  .UserEmailModal {
    .content {
      .form-container,
      .form-area {
        padding: 0 24px;
      }
    }
  }

  .PaymentMethodModal {
    .content {
      .ModalHeader {
        justify-content: center;

        .modal-close-icon {
          display: none;
        }
      }

      .PaymentMethodsForm {
        .row {
          .Dropdown {
            max-width: unset;
            width: 100%;

            .input-wrap {
              max-width: 100%;

              select {
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .Settings {
    .tab {
      section.language {
        .languages-context-menu-container {
          width: 200px;
        }
      }
    }
  }
}

@media (max-width: 300px) {
  .Settings {
    .tab
      section.availability
      .availability-table
      .AvailabilitySettings
      .table-container
      table
      th.time-th {
      width: 70px;
      max-width: 70px;
      padding-left: 3px;
      padding-right: 6px;
    }

    .tab section.availability .availability-table .table-container table td,
    .Settings .tab section.availability .availability-table .table-container table td {
      i {
        font-size: 17px;
        margin-right: 7px;
      }
    }
  }
}
