.FocusAreaSettings {
  .content {
    padding: 48px;
    h2 {
      margin-bottom: 4px;
    }
    .modal-description {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 22px;
    }
    .focus-area-list {
      border-top: 1px solid #e9e9e8;
      max-height: 330px;
      overflow-y: auto;
      padding-right: 10px;
      .focus-area-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 13px 0;

        &.hidden {
          opacity: 0.6;
        }

        .item-info {
          display: flex;
          flex-direction: row;
          align-items: center;
          .item-title {
            margin-left: 30px;
          }
        }

        .item-action {
          padding: 5px;
        }
        .item-action:hover {
          cursor: pointer;
        }
      }
    }
    .action-section {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 13px 0;
      .secondary {
        margin-right: 20px;
      }
    }
  }
}
