@import '../../../styles/variables.scss';

.ProfileFloatingActions {
  position: absolute;
  right: 25px;
  top: 25px;
  display: flex;

  .action-button {
    width: 38px;
    height: 38px;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: 10px;
    }

    justify-self: flex-end;

    button {
      border-radius: 50%;
      padding: 0;
      background-color: color(light, primaryBg);
      color: color(grey, base);
      min-width: 0;
      min-height: 0;
      width: 38px;
      height: 38px;
      font-size: 25px;

      .icon {
        margin-right: 0;

        i {
          font-size: 16px;

          &.fa-star.fas {
            color: color(yellow, text);
          }
        }
      }

      &:hover {
        color: color(purple, dark);
      }
    }
  }

  .action-button-first {
    margin-left: auto;
    margin-right: 10px;
  }
}

@media (max-width: 1240px) {
  .ProfileFloatingActions {
    .action-button {
      button {
        background-color: #ffffff;
      }
    }
  }
}
