@import '../../styles/variables.scss';

.AddLocationModal {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 600px;

  .gm-control-active {
    display: none;
  }

  .add-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 15px;

    .Button {
      button {
        border-radius: 8px;
        box-shadow: $box-shadow;
        background: color(purple, base);
        color: colo(light, secondaryBg);
      }
    }
  }

  .manual-add {
    form {
      max-height: 400px;
      overflow: auto;
      position: relative;
      margin: 0px 20px 80px;

      div {
        margin: 5px 0px;
      }

      .form-2-1 {
        display: flex;
        flex-direction: row;
        div {
          flex: 1;
        }
        div:first-of-type {
          flex: 2;
          margin-right: 8px;
        }
      }

      .form-1-1 {
        display: flex;
        div:first-of-type {
          margin-right: 8px;
        }

        .Dropdown {
          flex: 1;
        }
      }

      .actions {
        z-index: 2;
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 80px;
        background-color: color(light, secondaryBg);
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .Button {
          &:last-child {
            margin-right: 40px;
          }
        }
      }
    }
  }

  .title {
    font-size: 24px;
    font-weight: 700;
  }

  .actions {
    padding-top: 20px;
  }
}
