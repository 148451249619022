@import '../../../../styles/variables.scss';

.FormTopBar {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 80px;
  height: 90px;
  z-index: 2;

  .header-wrap {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 10px;
    align-items: center;
    justify-content: flex-end;
    height: 50px;

    i {
      display: flex;
      font-size: 20px;
      height: 36px;
      width: 36px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 8px;
      transition: all 200ms ease-in-out;

      &:hover {
        cursor: pointer;
        background-color: color(grey, pale);
      }
    }
  }

  .login {
    font-family: $raleway;
    position: absolute;
    left: 40%;
    top: 45%;
    .login-link {
      color: #fff;
      font-weight: 600;
      text-decoration: none;
    }
  }

  .logo-container {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;

      svg {
        path {
          fill: #000;
        }
      }
    }

    img.mobile {
      display: none;
    }

    img.desktop {
      width: 160px;
      height: 25px;
    }
  }
}

@media (max-width: 1000px) {
  .FormTopBar {
    padding: 20px 30px;
    top: -15px;
    .logo-container {
      img.desktop,
      svg {
        display: none;
      }
      img.mobile {
        display: block;
        width: 25px;
        height: 25px;
      }
    }
    .header-wrap {
      i {
        color: #fff;
        height: 16px;
        width: 16px;
        font-size: 16px;

        &:hover {
          background-color: unset;
        }
      }
    }
  }
}
