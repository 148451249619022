@import '../../../styles/variables.scss';

.ProgressBarWidget {
  position: relative;
  border-radius: 8px;

  box-sizing: border-box;
  font-size: 25px;

  margin: 1em;
  height: 6.5em;

  // Main styling.
  .pb-portrait {
    position: absolute;
    background: transparent;
    height: 6.5em;
    width: 6.5em;

    .portrait-inner {
      overflow: hidden;
      border-radius: 50%;
      box-sizing: border-box;
      border: 0.1em solid #dbeaec;
      background-color: color(purple, dark);

      height: 100%;
      transform: scale(1.2);
      position: relative;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .pb-content {
    display: flex;

    flex-direction: column;

    padding-top: 0.5em;
    padding-right: 1.5em;
    position: absolute;
    width: calc(100% - 2.5em);
    left: 2.5em;
    height: 6.5em;
    box-sizing: border-box;

    background-color: #ffffff;

    .pb-content-inner {
      height: calc(100% - 0.5em);
      margin-left: 5.5em;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: 50% 50%;
      grid-gap: 0.5em;
    }

    .title-area {
      grid-column-start: 1;
      grid-row-start: 1;

      margin-top: 0.7em;

      .title {
        color: #022c3c;
        font-size: 1em;
        font-weight: 700;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 0 !important;
      }

      .url {
        color: #022c3c;
        font-weight: 500;
        font-size: 0.8em;

        span {
          color: color(purple, dark);
        }
      }
    }

    .progress-bar {
      height: 10px;
      background: color(purple, darker) 0% 0% no-repeat padding-box;
      border-radius: 1em;
      padding: 0.1em;
      position: relative;
      box-sizing: border-box;

      grid-column-start: 1;
      grid-row-start: 2;

      .progress-bar-fill {
        background: color(purple, light) 0% 0% no-repeat padding-box;
        opacity: 1;
        height: 8px;
        border-radius: 1em;
      }
    }

    .amounts {
      font-weight: 900;
      font-size: 0.9em;
    }

    .powered-by {
      grid-column-start: 2;
      grid-row-start: 1;
      display: flex;
      flex-direction: row;
      margin-top: 0.8em;

      justify-content: flex-end;

      .powered-by-label {
        font-size: 0.6em;
      }

      .logo {
        margin-left: 0.5em;
        height: 1em;
      }
    }
  }

  &.no-profile-img {
    .pb-content {
      margin-left: 0px;
      border-radius: 8px;
    }
  }

  // Styling for widget styles.
  &.small-progress-bar {
    .pb-content {
      .amounts {
        grid-column-start: 2;
        grid-row-start: 2;
        text-align: end;
      }
    }
  }

  &.no-progress-bar {
    .pb-content {
      .progress-bar {
        display: none;
      }

      .amounts {
        grid-column-start: 1;
        grid-row-start: 2;
        font-size: 1.1em;
      }

      .powered-by {
        grid-column-start: 2;
        grid-row-start: 2;
        margin-top: 0em;
      }
    }
  }

  &.no-progress-bar,
  &.small-progress-bar {
    .pb-portrait {
      .portrait-inner {
        border-color: transparent;
      }
    }

    .pb-content {
      border-top-right-radius: 1em;
      border-bottom-right-radius: 1em;

      &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 50%;
        width: 80%;
        height: 0.24em;
        transform: translate(-50%, 0);
      }

      &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 80%;
        height: 0.24em;
        transform: translate(-50%, 0);
      }
    }
  }

  // Styling for widget themes.
  &.light {
    .pb-content {
      border: 1px solid color(purple);

      &:before {
        background: transparent linear-gradient(270deg, #ffffff 0%, color(purple) 52%, #ffffff 100%)
          0% 0% no-repeat padding-box;
      }

      &:after {
        background: transparent linear-gradient(270deg, #ffffff 0%, color(purple) 52%, #ffffff 100%)
          0% 0% no-repeat padding-box;
      }
    }
  }

  &.dark {
    .pb-content {
      background: radial-gradient(
        38.1% 116.87% at 50.13% 124.54%,
        color(purple) 0%,
        color(purple, dark) 100%
      );
      border: 1px solid color(purple);

      &:before {
        background: transparent
          linear-gradient(
            270deg,
            color(purple, dark) 0%,
            color(purple) 52%,
            color(purple, dark) 100%
          )
          0% 0% no-repeat padding-box;
      }

      &:after {
        background: transparent
          linear-gradient(
            270deg,
            color(purple, dark) 0%,
            color(purple) 52%,
            color(purple, dark) 100%
          )
          0% 0% no-repeat padding-box;
      }

      .title-area {
        .title,
        .url {
          color: #fff;
        }
      }

      .amounts {
        color: #fff;
      }

      .powered-by {
        color: #fff;
      }
    }

    .pb-portrait {
      .portrait-inner {
        border-color: color(purple, dark);
      }
    }
  }

  // Preview needs to be smaller.
  &.preview {
    font-size: 14px;
  }
}
