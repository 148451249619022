@import '../../styles/variables.scss';

.Pledge {
  flex: 1;
  padding: 60px 0px;
  position: relative;
  color: color(light, fontColor);
  background-color: color(light, primaryBg);

  .back-splash {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 440px;
    background-color: color(light, tertiaryBg);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      filter: blur(40px);
      transform: scale(1.3);
      width: 100%;
      height: auto;
    }

    .curve {
      background-color: color(light, primaryBg);
    }
  }

  .Donate-content {
    position: relative;
    margin: 0px auto;
    max-width: $wrap-size;
    min-height: 70vh;
    display: flex;
    flex: 1;

    .sidebar {
      width: 340px;
      margin-right: 25px;

      .load-wrap {
        margin: 60px 0px 40px;

        .Loader {
          i {
            font-size: 31px;
          }
        }
      }

      .sidebar-inner {
        background-color: color(light, secondaryBg);
        padding: 30px;
        border-radius: $border-radius;
        min-height: 300px;
        box-shadow: $box-shadow;
        display: flex;
        flex-direction: column;

        &.fixed {
          position: fixed;
          top: 80px;
          width: 280px;
          z-index: 98;
        }

        .sidebar-content {
          flex: 1;
          margin-top: 40px;
          width: 100%;

          .sidebar-info {
            padding: 0px 20px;

            .type {
              color: color(grey);
              font-weight: 400;
              text-transform: capitalize;

              i {
                margin-right: 8px;
              }
            }

            .name {
              margin: 5px 0px 0px;
              padding: 0px;
              font-size: 24px;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;

              a {
                text-decoration: none;
                color: color(light, fontColor);

                &:hover {
                  color: color(purple, dark);
                }
              }
            }

            .handle {
              margin-top: 8px;
              color: color(grey);
              font-weight: 400;

              &.event {
                a {
                  text-decoration: none;
                  color: color(light, fontColor);

                  &:hover {
                    color: color(purple, dark);
                  }
                }
              }
            }

            .description {
              margin-top: 30px;
              padding: 25px 0px 0px;
              border-top: 1px solid color(grey, light);

              .ql-editor {
                padding: 0px;
                margin: 0px;

                p {
                  font-size: 14px;
                  line-height: 26px;
                  color: color(grey);

                  strong {
                    font-weight: 700;
                  }

                  a {
                    color: color(purple, dark);
                    text-decoration: none;

                    &:hover {
                      color: color(purple);
                    }
                  }
                }
              }
            }
          }

          .sidebar-actions {
            margin: 40px 0px 0px 0px;
            flex: 1;
            padding: 0px 20px;

            .Button {
              &:first-child {
                button {
                  background-color: color(purple);
                }
              }

              &:last-child {
                button {
                  background-color: color(light, secondaryBg);
                  color: color(purple, dark);
                  border: 2px solid color(purple, dark);

                  &:hover {
                    border: 2px solid color(purple);
                    color: color(purple);
                  }
                }
              }

              button {
                margin-top: 15px;
                flex: 1;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .main {
      flex: 1;

      .Donate-main-content {
        background-color: color(light, secondaryBg);
        padding: 20px;
        border-radius: $border-radius;
        min-height: 300px;
        box-shadow: $box-shadow;
        min-height: 60vh;
        position: relative;

        .make-pledge {
          display: flex;
          justify-content: center;
          box-shadow: $box-shadow;
          align-items: center;
          position: absolute;
          top: 48px;
          right: 30px;
          background-color: #6565d3;
          color: #fbfbfb;
          height: 42px;
          padding: 10px;
          width: 180px;
          border-radius: 8px;

          i {
            margin: 0 5px;
            font-size: 24px;
          }

          a {
            text-decoration: none;
            color: #fbfbfb;
          }
        }

        .Donate-info {
          display: flex;
          border-top: 1px solid color(grey, lighter);
          border-bottom: 1px solid color(grey, lighter);
          padding: 20px 0px;
          margin: 40px 0px;

          .circle-wrap {
            .circle {
              display: flex;
              width: 40px;
              height: 40px;
              background-color: color(grey, lighter);
              border-radius: 50%;
              justify-content: center;
              align-items: center;
            }
          }

          .sect {
            flex: 1;
            display: flex;
            border-right: 1px solid color(grey, lighter);
            justify-content: center;
            align-items: center;
            width: 25%;

            &:last-child {
              border-right: 0px;
            }

            .Donate-content {
              display: flex;
              flex-direction: column;
              padding-left: 8px;

              span {
                font-size: 12px;

                &:first-child {
                  font-weight: 700;
                }

                &:last-child {
                  color: color(grey);
                  padding-top: 4px;
                }
              }
            }
          }
        }

        .inner-content {
          padding: 40px;

          .make-pledge {
            display: flex;
            justify-content: center;
            box-shadow: $box-shadow;
            align-items: center;
            position: absolute;
            top: 65px;
            right: 30px;
            background-color: #6565d3;
            color: #fbfbfb;
            height: 42px;
            padding: 10px;
            width: 180px;
            border-radius: 8px;
            cursor: pointer;

            &:hover {
              background-color: color(purple, dark);
            }

            i {
              margin: 0 5px;
              font-size: 24px;
            }

            a {
              text-decoration: none;
              color: #fbfbfb;
            }
          }

          h1 {
            text-align: center;
          }

          .dono-step {
            padding-top: 20px;
            position: relative;

            &.hide-step {
              height: 0px;
              width: 0px;
              overflow: hidden;
              margin: 0px;
              padding: 0px;
            }

            .payment-loading {
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              background-color: rgba(255, 255, 255, 0.9);
              z-index: 4;
              display: flex;
              justify-content: center;
              align-items: center;

              .Loader {
                font-size: 30px;
              }
            }

            .confirm-wrap {
              display: flex;
              flex: 1;

              .half {
                width: 50%;
                flex: 1;

                &:first-child {
                  margin-right: 20px;
                }

                .summary {
                  background-color: color(grey, lighter);
                  border-radius: $border-radius;
                  margin-left: 20px;

                  .sum-title {
                    padding: 20px;
                    font-weight: 500;
                    display: flex;
                    flex: 1;
                    justify-content: space-between;

                    span {
                      &:last-child {
                        font-size: 15px;
                        color: color(purple, dark);

                        &:hover {
                          cursor: pointer;
                          color: color(purple);
                        }
                      }
                    }
                  }

                  .sum-total {
                    display: flex;
                    flex: 1;
                    justify-content: space-between;
                    padding: 20px;
                    font-size: 21px;
                    font-weight: 500;
                  }

                  .sum-row {
                    display: flex;
                    flex: 1;
                    justify-content: space-between;
                    padding: 0px 20px;
                    height: 45px;
                    background-color: color(light, secondaryBg);
                    border-bottom: 2px solid color(grey, lighter);
                    line-height: 45px;
                    color: color(grey);
                    font-size: 15px;
                  }
                }

                .half-content {
                  padding-top: 10px;
                  padding-bottom: 20px;

                  div {
                    color: color(grey);
                    font-size: 14px;
                    padding: 2px 0px;
                  }
                }

                h3 {
                  font-size: 15px;
                  font-weight: 500;
                  padding: 0px;
                  margin: 0px;
                  height: 40px;
                  line-height: 40px;
                  border-bottom: 1px solid color(grey, lighter);
                  flex: 1;
                  justify-content: space-between;
                  display: flex;

                  span {
                    font-size: 12px;
                    color: color(purple, dark);

                    &:hover {
                      cursor: pointer;
                      color: color(purple);
                    }
                  }
                }
              }
            }

            h2 {
              color: color(purple, dark);
              padding: 0px 0px 12px;
              font-weight: 500;
              border-bottom: 2px solid color(grey, lighter);
              margin-top: 40px;

              &.w-padding {
                padding-top: 30px;
              }
            }

            .step-title {
              font-weight: 500;
              color: color(grey);
              margin-bottom: 12px;
            }

            .teams-selection-wrap,
            .individuals-selection-wrap {
              display: flex;
            }

            .donate-to-wrap {
              label {
                font-weight: 500;
                font-size: 16px;
                margin-bottom: 12px;
                color: #52646c;
              }

              .donate-to-selections {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 20px 0;
                height: 300px;

                .mobile-section {
                  display: none;
                }

                &.disable {
                  .donate-to-selection {
                    &.team,
                    &.individual {
                      .selection-blurb,
                      .selection-type {
                        color: color(grey, light);
                      }
                      .disable-overlay {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        z-index: 2;
                        cursor: not-allowed;
                        // background-color: rgba(0,0,0,0.2);
                      }
                    }
                  }
                }

                .donate-to-selection {
                  flex: 1;
                  margin: 10px;
                  border-radius: 8px;
                  color: color(grey);
                  display: flex;
                  flex-direction: column;
                  border: 1px solid color(grey, light);
                  position: relative;

                  &.active {
                    box-shadow: $box-shadow;
                  }

                  .selection-type {
                    color: color(blue, dark);
                    margin: 10px 0;
                    font-size: 18px;
                    font-weight: 500;
                    padding: 0 10px;
                    flex: 1;
                  }

                  .selection-blurb {
                    padding: 0 10px;
                    padding-bottom: 20px;
                    flex: 1;
                  }

                  .selection-img {
                    min-height: 135px;
                    position: relative;
                    margin: 15px;
                    margin-bottom: 0;
                    &.team {
                      background-repeat: no-repeat;
                      background-position: center center;
                      background-image: url('../../assets/team-off.png');
                      background-size: contain;

                      &.active {
                        background-image: url('../../assets/team-on.png');
                      }
                    }

                    &.individual {
                      background-repeat: no-repeat;
                      background-position: center center;
                      background-image: url('../../assets/individual-off.png');
                      background-size: contain;

                      &.active {
                        background-image: url('../../assets/individual-on.png');
                      }
                    }

                    &.event {
                      background-repeat: no-repeat;
                      background-position: center center;
                      background-image: url('../../assets/event-off.png');
                      background-size: contain;

                      &.active {
                        background-image: url('../../assets/event-on.png');
                      }
                    }
                  }

                  &:first-of-type {
                    margin-left: 0;
                  }

                  &:hover {
                    cursor: pointer;
                  }

                  &.active {
                    color: color(light, fontColor);
                    border: 2px solid color(purple, dark);
                    box-shadow: $box-shadow;
                  }
                }
              }
              .Dropdown {
                flex: 1;

                label {
                  color: #52646c;

                  span {
                    font-weight: 500;
                    font-size: 16px;
                    margin-bottom: 12px;
                  }
                }
              }
            }
            .team-selection-wrap,
            .individual-selection-wrap {
              .Dropdown {
                flex: 1;

                label {
                  color: #52646c;

                  span {
                    font-weight: 500;
                    font-size: 16px;
                    margin-bottom: 12px;
                  }
                }
              }
            }
            .fund-selection-wrap {
              display: flex;

              .Dropdown {
                flex: 1;

                label {
                  color: #52646c;

                  span {
                    font-weight: 500;
                    font-size: 16px;
                    margin-bottom: 12px;
                  }
                }
              }
            }
            .type-wrap {
              display: flex;

              .donation-type {
                flex: 1;
                display: flex;
                height: 40px;
                border-radius: 8px;
                border: 2px solid color(grey, light);
                justify-content: center;
                align-items: center;
                font-weight: 500;
                color: color(grey);
                margin-bottom: 30px;

                &:hover {
                  cursor: pointer;
                }

                &.active {
                  border: 2px solid color(purple, dark);
                  color: color(light, fontColor);
                  box-shadow: $box-shadow;
                }

                &:first-child {
                  margin-right: 10px;
                }

                &:last-child {
                  margin-left: 10px;
                }
              }
            }

            .tip-warning {
              color: color(grey);
              font-size: 12px;
              line-height: 21px;
            }

            .step-actions {
              margin-top: 40px;
              display: flex;
              justify-content: flex-start;
              flex-direction: row-reverse;

              .Button {
                margin-left: 12px;
              }
            }

            p.receive-info {
              margin: 0px;
              padding: 20px 0px;
              font-weight: 700;
              color: color(grey);
            }

            .TextArea {
              border: 1px solid #b6c5cb;
              border-radius: 7px;

              .placeholder {
                margin-bottom: 5px;
                color: #354a53;
                font-size: 16px;
              }

              textarea {
                height: 100px;
              }
            }

            .details-margin-top {
              margin-top: 20px;
            }

            .details-row {
              margin-bottom: 20px;
              display: flex;

              &.controls {
                flex-direction: column;

                .is-payroll:first-of-type {
                  border-bottom: none;
                }
              }

              .is-payroll {
                display: flex;
                justify-content: space-between;
                padding: 15px 0;
                border-top: 1px solid color(grey, lighter);
                border-bottom: 1px solid color(grey, lighter);
                width: 100%;
                align-items: center;

                .label-section {
                  display: flex;
                  flex-direction: column;
                  label {
                    color: #354a53;
                    font-size: 16px;
                    font-weight: 700;
                  }
                  span {
                    font-size: 14px;
                    color: #52646c;
                  }
                }

                .settings-control {
                  .fad {
                    font-size: 30px;
                    color: color(purple, dark);

                    &:hover {
                      cursor: pointer;
                    }

                    &.fa-toggle-off {
                      color: color(grey);
                    }
                  }
                }
              }

              &.email-phone {
                display: flex;
                .TextField:first-of-type {
                  margin-right: 20px;
                  flex: 2;
                }
              }

              .Dropdown {
                flex: 1;
              }

              .Checkbox {
                label {
                  color: color(grey);
                  font-weight: 500;
                  font-size: 14px;
                }
              }

              .details-col {
                flex: 1;
                margin-right: 20px;

                .opt-text {
                  font-size: 14px;
                  color: color(grey);
                  padding-left: 10px;
                  padding-top: 1px;
                  font-weight: 400;
                }
                &.date-row {
                  display: flex;

                  .DatePicker {
                    flex: 1;
                    align-self: flex-end;
                    label {
                      transform: translateX(-32px);
                    }

                    .dp-inner {
                      .month {
                        max-width: 90px;
                        min-width: 90px;

                        .Dropdown {
                          .input-wrap {
                            border-right: 0px;
                            border-radius: 0px;
                            border-left: 0px;

                            select {
                              color: color(grey);
                              font-weight: 400;
                            }

                            .fa-angle-down {
                              display: none;
                            }
                          }
                        }
                      }

                      .day,
                      .year {
                        .TextField {
                          margin-left: 0px;

                          .input-wrap {
                            border-left: 0px;
                            border-right: 0px;
                            border-radius: 0px;

                            input::-webkit-outer-spin-button,
                            input::-webkit-inner-spin-button {
                              -webkit-appearance: none;
                              margin: 0;
                            }

                            input[type='number'] {
                              -moz-appearance: textfield;
                            }

                            input {
                              color: color(grey);
                              font-weight: 400;
                            }
                          }
                        }
                      }

                      .day {
                        max-width: 35px;
                      }

                      .year {
                        max-width: 58px;

                        .TextField {
                          .input-wrap {
                            border-right: 1px solid color(grey, light);
                            border-top-right-radius: 7px;
                            border-bottom-right-radius: 7px;
                          }
                        }
                      }
                    }
                  }

                  .fa-calendar {
                    border: 1px solid color(grey, light);
                    border-right: 0px;
                    height: 42px;
                    margin-top: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-top-left-radius: 7px;
                    border-bottom-left-radius: 7px;
                    padding-left: 10px;
                    color: color(grey);
                    padding-right: 7px;
                  }
                }

                .dbl-date-wrap {
                  .fa-calendar {
                    margin-top: 4px;
                  }
                }

                .Checkbox {
                  label {
                    margin-bottom: 5px;
                    color: #354a53;
                    font-size: 16px;
                  }
                }

                .TextField {
                  label {
                    margin-bottom: 5px;
                    color: #354a53;
                    font-size: 16px;
                  }
                }

                .Dropdown {
                  label {
                    // Don't select the required indicator.
                    span:not(.required) {
                      margin-bottom: 5px;
                      color: #354a53;
                      font-size: 16px;
                    }
                  }
                }
                .Checkbox {
                  label {
                    justify-content: flex-start;
                  }
                }
                .DatePicker {
                  label {
                    margin-bottom: 5px;
                    color: #354a53;
                    font-size: 16px;
                  }
                }

                &:last-child {
                  margin: 0px;
                }
              }

              .fixed-col {
                flex: 0;
                margin-right: 20px;
              }
            }

            .donation-amount-wrap,
            .tip-amount-wrap,
            .fund-selection-wrap,
            .team-selection-wrap,
            .individual-selection-wrap {
              display: flex;
              margin-bottom: 30px;
              flex-wrap: wrap;

              .donation-amount,
              .tip {
                border-radius: 8px;
                font-weight: 500;
                color: color(grey);
                border: 2px solid color(grey, light);
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 80px;
                margin-right: 10px;
                height: 40px;
                margin-bottom: 10px;

                &.active {
                  border: 2px solid color(purple, dark);
                  color: color(light, fontColor);
                  box-shadow: $box-shadow;
                }

                &:hover {
                  cursor: pointer;
                }

                &.other {
                  border: 1px solid color(grey, light);

                  &.active {
                    border: 1px solid color(purple, dark);

                    .TextField .input-wrap {
                      border: 1px solid color(purple, dark);
                    }
                  }
                }
              }
            }

            .error_text {
              color: red;
              font-size: 14px;
            }

            .charityDonation {
              .Button {
                margin-top: 28px;
                button {
                  background-color: transparent;
                  color: color(red, dark);
                  font-size: 24px;
                  min-width: 25px;
                  max-width: 25px;
                  padding: 4px 15px;

                  &:hover {
                    color: color(purple, base);
                    box-shadow: none;
                  }
                }
              }

              .charityId {
                .TextField {
                  .helper {
                    max-width: 15px;
                    min-width: 15px;
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }

        .load-wrap {
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;

          .Loader {
            margin-top: 40px;
            i {
              font-size: 31px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .Pledge {
    background-color: color(light, secondaryBg);

    .back-splash {
      height: 280px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        filter: blur(0);
        transform: scale(1.7);
      }

      .curve {
        background-color: color(light, secondaryBg);
      }
    }

    .Donate-content {
      flex-direction: column;

      .sidebar {
        width: auto;
        max-width: 500px;
        margin: 50px auto 0px;

        .sidebar-inner {
          background-color: transparent;
          box-shadow: none;
          text-align: center;

          .sidebar-content {
            .sidebar-info {
              .description {
                border-top: 0px;
                margin-top: 10px;
                padding-top: 10px;
              }
            }
          }

          .Portrait {
            margin: 0px auto;
          }
        }
      }

      .main {
        .Donate-main-content {
          background-color: color(light, primaryBg);
          border-radius: 0px;
          box-shadow: none;
          padding: 0px;
          position: relative;

          .inner-content {
            padding: 20px;

            h1 {
              padding: 0px;
              align-self: center;
              margin: 0px 0px 40px 0px;
              font-size: 30px;
              text-align: center;
            }

            .dono-step {
              &.step-4 {
                h2 {
                  background-color: color(grey, lighter);
                  border-bottom: 0px;
                  margin: 0px;
                  padding: 20px;
                  border-top-left-radius: 20px;
                  border-top-right-radius: 20px;
                  font-size: 16px;
                  color: color(light, fontColor);
                }

                .step-actions {
                  margin: 0px;
                }
              }

              .confirm-wrap {
                flex-direction: column;

                .half {
                  width: auto;
                  padding: 20px 0px;

                  .summary {
                    margin-left: 0px;
                  }

                  h3,
                  .half-content {
                    background-color: color(light, secondaryBg);
                    padding: 0px 20px;
                  }

                  h3 {
                    padding-top: 20px;
                    border-bottom: 2px solid color(grey, lighter);
                  }

                  .half-content {
                    padding-top: 10px;

                    &:last-child {
                      padding-bottom: 20px;
                      border-bottom-left-radius: 20px;
                      border-bottom-right-radius: 20px;
                    }
                  }

                  &:first-child {
                    margin-right: 0px;
                    padding-top: 0px;
                    padding-bottom: 10px;
                  }
                }
              }

              .fund-selection-wrap {
                .Dropdown {
                  .input-wrap {
                    background-color: color(light, secondaryBg);
                  }
                }
              }

              .Dropdown {
                .input-wrap {
                  background-color: color(light, secondaryBg);
                }
              }

              .donation-amount-wrap {
                .donation-amount {
                  background-color: color(light, secondaryBg);
                }
              }

              .tip-amount-wrap {
                .tip {
                  background-color: color(light, secondaryBg);
                }
              }

              .type-wrap {
                .donation-type {
                  background-color: color(light, secondaryBg);
                }
              }

              .donate-to-selections {
                .donate-to-selection {
                  background-color: color(light, secondaryBg);
                }
              }

              .details-row {
                .TextField {
                  input {
                    background-color: color(light, secondaryBg);
                  }
                }

                &.email-phone {
                  .TextField:first-of-type {
                    margin-right: 0;
                    flex: 1;
                  }
                }

                .CreditCard {
                  .CreditCard-wrap {
                    background-color: color(light, secondaryBg);
                  }
                }
              }

              .payment-loading {
                background-color: color(light, primaryBg);
                opacity: 0.9;
                align-items: flex-start;
                margin-top: 40px;
              }

              h2 {
                border-bottom: 1px solid color(grey, light);
              }
            }

            h1 {
              display: none;
            }

            .FormSteps {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .Pledge {
    .Donate-content {
      .main {
        .Donate-main-content {
          .inner-content {
            .make-pledge {
              position: initial;
              margin: 0 auto;
            }
            .dono-step {
              .donate-to-wrap {
                label {
                  margin-bottom: 0;
                }

                .team-selection-wrap,
                .individual-selection-wrap,
                .fund-selection-wrap {
                  display: none;
                  margin: 10px 0;

                  .Dropdown {
                    label {
                      display: block;
                      margin-bottom: 0;
                      span {
                        margin-bottom: 0;
                      }
                    }
                  }
                }

                .donate-to-selections {
                  height: auto;

                  .mobile-section {
                    display: initial;

                    .team-selection-wrap,
                    .individual-selection-wrap,
                    .fund-selection-wrap {
                      display: block;
                    }
                  }

                  .donate-to-selection {
                    margin: 10px 0;
                  }
                }
              }
              &.step-1-event {
                flex-direction: column;

                .donate-to-selections {
                  flex-direction: column;
                }
              }
              .details-row {
                flex-direction: column;
                margin-bottom: 0px;

                .CreditCard {
                  margin: 20px 0px;
                }

                .details-col,
                .details-col:last-child {
                  margin-right: 0px;
                  margin-bottom: 20px;
                }

                &.dedicate {
                  flex-direction: row;

                  .Checkbox {
                    margin: 20px 0px;
                  }
                }
              }

              .dedicated {
                .Dropdown {
                  margin-bottom: 20px;
                  border-radius: 5px;

                  .input-wrap {
                  }

                  select {
                    border-radius: 5px;
                  }
                }

                .details-row {
                  &:last-child {
                    margin: 20px 0px;
                  }
                }
              }

              .donation-amount-wrap {
                .donation-amount {
                  width: 30%;
                  background-color: color(light, secondaryBg);

                  &:nth-child(3) {
                    margin-right: 0px;
                  }

                  &.other {
                    width: 100%;
                    margin-right: 0px;
                  }
                }
              }

              .tip-amount-wrap {
                .tip {
                  width: 30%;
                  background-color: color(light, secondaryBg);

                  &:nth-child(3) {
                    margin-right: 0px;
                  }

                  &.other {
                    width: 100%;
                    margin-right: 0px;
                  }
                }
              }

              .type-wrap {
                flex-direction: column;

                .donation-type {
                  background-color: color(light, secondaryBg);
                  margin-bottom: 10px;
                  min-height: 44px;

                  &:first-child {
                    margin-right: 0px;
                  }

                  &:last-child {
                    margin-left: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
