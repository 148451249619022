@import '../../../../../styles/variables.scss';

.MultiSelectFieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto;

  label {
    display: flex;
    flex: 1;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 4px;
  }
}
