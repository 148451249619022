@import '../../../../styles/variables.scss';

.BannerLayoutSettings {
  display: flex;
  row-gap: 16px;
  flex-direction: column;

  .options-container {
    width: 454px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;

    .option {
      cursor: pointer;
      width: 44px;
      height: 44px;
      border: 1px solid color(grey, border);
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s;

      &.active,
      &:hover {
        background-color: color(grey, border);
      }
    }
  }

  .banner-layout-container {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 160px;
    width: 422px;
    background-color: color(purple, base2);
    border-radius: 10px;
    color: color(light, secondaryBg);
    transition: all 0.4s;

    &.center {
      align-items: center;

      p {
        text-align: center;
      }
    }

    &.left {
      align-items: flex-start;

      p {
        text-align: left;
      }
    }

    &.right {
      align-items: flex-end;

      p {
        text-align: right;
      }
    }

    .title {
      font-weight: 800;
      font-size: 12px;
      margin-bottom: 7px;
    }

    p {
      font-weight: 400;
      font-size: 6px;
      margin: 0 0 15px 0;
      max-width: 170px;
      text-align: center;
    }

    .btn-container {
      display: flex;
      column-gap: 7px;

      .btn {
        width: 45px;
        height: 16px;
        background: color(light, secondaryBg);
        border-radius: 22px;
      }
    }
  }
}
