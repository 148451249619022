@import '../../styles/variables.scss';

.StravaSection {
  display: flex;
  flex-flow: column;
  margin: 25px 10px 0 0;

  .title-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .strava-icon {
      width: 25px;
      height: 25px;
      margin-left: auto;
      margin-right: 5px;
    }

    .strava-word-icon {
      width: 100px;
      height: 20px;
    }

    .title {
      font-size: 26px;
      color: color(light, fontColor);
      font-weight: 500;
      margin: 15px 0;
    }
  }

  .section-inner {
    display: flex;
    flex-flow: column;
    flex: 1;
    width: 100%;

    .warning-message {
      color: #ee7100;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      font-size: 14px;

      i {
        margin-right: 10px;
        align-self: center;
      }
    }

    .activity-card {
      display: flex;
      flex-flow: column;
      padding: 20px;
      border: solid 1px color(grey, lighter);
      box-shadow: $box-shadow;

      .card-inner {
        display: flex;
        flex-flow: row wrap;

        .column {
          display: flex;
          flex-flow: column;
          margin: 25px 0 25px 0;
          flex: 1;
        }

        .stat-container {
          display: flex;
          flex-flow: row nowrap;
          padding: 3px;
          align-items: center;

          .label {
            color: color(purple, dark);
            margin-right: 10px;
          }

          .unit {
            align-self: flex-end;
          }

          i {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
          }

          &.small {
            font-size: 20px;

            i {
              width: 20px;
            }

            .unit {
              font-size: 15px;
            }
          }

          &.large {
            font-size: 40px;

            .unit {
              font-size: 20px;
            }

            i {
              width: 35px;
              color: color(grey, font);
            }
          }
        }
      }

      .activity-header {
        display: flex;
        flex-flow: row wrap;
        flex: 1;
        align-items: center;

        .title {
          flex-grow: 1;
          font-weight: 500;
          font-size: 18px;
          color: black;
          margin-right: 5px;
        }

        .toggle-container {
          display: flex;
          flex-flow: row wrap;

          .toggle {
            display: flex;
            flex-flow: row nowrap;
            padding: 7px;
            margin-right: 10px;
            color: color(grey, light);
            border-radius: 15px;
            font-size: 14px;

            &:hover {
              cursor: pointer;
            }
            i {
              margin-right: 5px;
              font-size: 18px;
            }

            &.selected {
              color: color(purple, dark);
              background-color: #ddf2f5;
            }
          }
        }
      }
    }

    .add-activity {
      padding: 20px 0;

      .add-activity-button {
        width: 100%;
        background-color: transparent;
        color: color(purple, dark);
        border-radius: 5px;

        &:hover {
          color: white;
          background-color: color(purple, dark);
        }
      }
    }
  }
}
