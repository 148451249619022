@import '../../styles/variables.scss';

.export-popup-items-container--reconcile-payment {
  margin-left: -120px;

  li {
    white-space: nowrap;
    cursor: pointer;
  }
}

.Donation-list {
  display: flex;
  flex: 1;
  flex-direction: column;

  .ReconcilePayment-Modal {
    .content {
      position: relative;
      width: 936px;
      height: 702px;

      .ModalHeader {
        display: block;

        .modal-close-icon {
          position: absolute;
          top: 30px;
          right: 30px;
          margin: 0;
        }
      }
    }
  }

  .volunteer-name {
    &:hover {
      cursor: pointer;
    }
  }

  .ContactDetailsModal {
    justify-content: flex-start;
    align-items: stretch;

    .content {
      position: relative;
      flex: 1;
      max-width: 100%;
      max-height: 100%;
      margin: 40px 30px;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      color: color(light, fontColor);

      .modal-close-circle {
        right: 15px;
        top: 15px;
      }
    }

    .ModalHeader {
      display: none;
    }
  }

  .Modal {
    .controls {
      border-top: 1px solid lightgray;
      display: flex;
      justify-content: center;

      button {
        margin: 25px;
      }

      .cancel-button {
        background-color: #ffffff;
        color: #52646c;
        box-shadow: 0 3px 6px #7c7c7c41;
        border: 1px solid #e3e3e3;
        border-radius: $border-radius;

        &:hover {
          background-color: color(light, tertiaryBg);
          color: color(light, fontColor);
        }
      }
    }

    .controls {
      margin-top: 30px;
      border: unset;

      button {
        margin: unset;
      }

      .cancel-button {
        width: 161px;
      }

      .generate-button {
        width: 145px;
        margin: 0 20px;
      }

      .generate-send-button {
        width: 251px;
      }

      .title {
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: 700;
        display: flex;
        flex: 1;
        justify-content: center;
      }

      .regenerate-button {
        width: 145px;
        margin-left: 20px;
      }
    }
  }

  .transaction-tabs {
    display: flex;

    div {
      flex: 1;
      cursor: pointer;
      padding-bottom: 5px;
      border-bottom: 1px solid color(grey, light);
      margin-bottom: 15px;
      text-align: center;
      font-weight: 700;
      color: color(grey, light);

      &.active {
        border-bottom: 2px solid color(purple, dark);
        color: color(light, fontColor);
      }
    }
  }

  .filters {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    margin-bottom: 18px;

    .filter-btn {
      width: 158px;
      height: 41px;
      line-height: 41px;
      text-align: center;
      color: color(plum, dark);
      cursor: pointer;
      background: color(light, secondaryBg);
      border: 1px solid color(grey, border);
      transition: all 0.2s;

      &.active,
      &:hover {
        background: color(grey, activeGray);
        border: 1px solid color(plum, light);
      }

      &:last-of-type {
        border-radius: 0px 10px 10px 0px;
      }

      &:first-of-type {
        border-radius: 10px 0px 0px 10px;
      }
    }
  }

  .search {
    height: 86px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0px -1px 0px rgba(169, 170, 172, 0.25);

    .TextField {
      .input-wrap {
        input {
          width: 305px;
        }
      }
    }

    .donation-actions {
      display: flex;
      flex-direction: row;

      .Button {
        &:first-of-type {
          margin-right: 10px;
        }
      }
    }

    .create {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }

  .donation-list-items {
    display: flex;
    flex-direction: column;
    flex: 1;

    .list-inner {
      .list-rows {
        position: relative;
        width: 100%;

        .multi-select-actions {
          width: 100%;
          display: flex;
          align-items: center;

          .selected-counter {
            flex: 1 1;
            font-weight: 700;
            font-size: 20px;
          }

          .selected-actions {
            display: flex;

            div:hover {
              background: color(light, tertiaryBg);
              cursor: pointer;
            }

            div {
              width: 40px;
              height: 40px;
              border-radius: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .TableComponent {
          .selected-counter {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .user_display_name {
            flex: 1;
            font-weight: 700;
          }

          .amounts_payment_method {
            text-transform: capitalize;
          }

          .name {
            span {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          .status {
            height: 30px;
            text-transform: capitalize;
            line-height: 30px;
            display: inline-flex;
            justify-content: center;
            padding: 0 12px;
            border-radius: 8px;

            &.payed_out {
              background-color: color(yellow, fade);
              color: color(yellow, text);
            }

            &.paid,
            &.matched,
            &.send {
              background-color: color(green, fade);
              color: color(green, soft);
            }

            &.unpaid,
            &.declined,
            &.refused {
              background-color: color(red, fade);
              color: color(red, soft);
            }

            &.refunded,
            &.pending,
            &.not_created {
              background-color: color(grey, pale);
              color: color(dark, border);
            }
          }

          .portrait {
            max-width: 120px;
          }

          .created_at,
          &.updated,
          &.account_subscription_last_donation_created_at {
            width: 400px;

            span {
              margin-right: 8px;

              &:last-child {
                color: color(light, fontColor);
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  .content {
    max-height: 90vh;
    min-width: 600px;
  }
}

.refund {
  padding: 15px;

  .refund-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    h1 {
      margin: 10px 0;
    }

    div {
      font-size: 16px;
      color: color(grey, light);
    }
  }

  .refund-info {
    .sub-header {
      padding-bottom: 10px;
      border-bottom: 1px solid #e6e6e6;
      color: color(purple, dark);
      margin-top: 25px;
      font-size: 18px;
    }

    .info {
      .info-part {
        margin: 15px 0;
        span {
          font-weight: 700;
        }
      }
    }

    .full-refund {
      padding-top: 20px;
      display: flex;
      align-items: center;

      .fad {
        margin-right: 15px;
        font-size: 30px;
        color: color(purple, dark);

        &:hover {
          cursor: pointer;
        }

        &.fa-toggle-off {
          color: color(grey);
        }
      }
    }

    .full-refund {
      padding-top: 20px;
      display: flex;
      align-items: center;

      .fad {
        margin-right: 15px;
        font-size: 30px;
        color: color(purple, dark);

        &:hover {
          cursor: pointer;
        }

        &.fa-toggle-off {
          color: color(grey);
        }
      }
    }

    .refund-details {
      margin-top: 15px;
      display: flex;
      flex-direction: row;

      .TextField {
        flex: 2;
        margin-right: 8px;
      }

      .Dropdown {
        flex: 1;
      }
    }

    .refund-actions {
      display: flex;
      justify-content: flex-end;
      margin: 10px 0;
    }
  }
}
