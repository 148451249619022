.PaymentComponent {
  padding: 0 80px 60px 80px;

  .payment-title {
    font-size: 14px;
    font-weight: 700;
    padding: 15px 0;
    border-bottom: 1px solid #ededec;
  }

  .payment-pricing-wrapper {
    padding: 15px 0;
    .pricing-subtitle {
      font-size: 14px;
      font-weight: 400;
      color: #5a5b5d;
      margin-bottom: 15px;
    }

    .pricing-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;
      color: #5a5b5d;
      margin-bottom: 5px;
    }

    .pricing-list.total {
      font-weight: 900;
    }
  }
}
