@import '../../../../styles/variables.scss';

.PaymentMethodComponent {
  padding: 20px 0;
  .component-title {
    font-size: 14px;
    font-weight: 700;
    padding: 15px 0;
  }

  .new-cc {
    .confirm-payment-method {
      display: flex;
      justify-content: flex-end;
      margin: 10px 0;
    }
    label {
      display: block;
      color: #52646c;
      font-size: 14px;
      font-weight: 700;
    }

    .TextField {
      margin-bottom: 20px;
    }

    .card-wrap {
      label {
        margin-bottom: 4px;
      }

      .card-inner {
        height: 44px;
        border: 1px solid color(grey, lightest);
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;

        .StripeElement {
          flex: 1;
        }
      }
    }
  }

  .pm-inner {
    .alt-methods-container {
      img {
        max-width: 45px;
        height: auto;
      }
      svg {
        max-width: 45px;
        height: auto;
      }
    }

    .pm {
      display: flex;
      flex: 1;
      align-items: center;
      border-top: 1px solid #e8f1f2;
      padding: 0px 10px;
      height: 60px;

      i {
        &:first-child {
          font-size: 40px;
        }

        &.fa-cc-visa {
          color: #214dba;
        }

        &.fa-cc-mastercard {
          color: #343a47;
        }

        &.fa-cc-amex {
          color: #6ca0da;
        }

        &.fa-cc-discover {
          color: #ef7e23;
        }

        &.fa-cc-diners-club {
          color: #01084c;
        }

        &.fa-cc-jcb {
          color: #0867ad;
        }

        &.fa-credit-card {
          color: color(purple, dark);
        }

        &.fas {
          color: color(purple, dark);
        }
      }

      &:hover {
        cursor: pointer;
        background-color: #fbfbfb;
      }

      span {
        flex: 1;
        padding-left: 10px;
        font-size: 14px;
      }

      &.new {
        span {
          font-weight: 700;
          padding-left: 0px;
          font-size: 16px;
        }

        i {
          width: 16px;
        }
      }

      &:last-child {
        border-bottom: 1px solid #e8f1f2;
      }
    }
  }
}
