@import '../../../styles/variables.scss';

.ExternalVolunteerApplicationWarning {
  .content {
    height: 336px;
    width: 799px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ModalHeader {
    width: 100%;

    .close-button {
      display: none;
    }

    .modal-close-icon {
      right: 0;
      top: 0;
      position: absolute;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-family: $raleway;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      text-align: center;
      margin: 0 0 27px 0;
    }

    p {
      font-family: $lato;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      margin: 0 0 27px 0;
    }

    .actions {
      align-items: center;
      justify-content: center;
      display: flex;

      .Button:nth-child(1) {
        margin-right: 18px;
      }
    }
  }
}
