.HubManagementEvents {
  display: flex;
  flex-flow: column nowrap;
  padding: 50px;
  height: 100%;
  grid-gap: 30px;
  gap: 30px;
  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .table-section {
    min-height: 60vh;
    td {
      position: unset;
    }
    .fundraising-progress-bar-container {
      display: flex;
      flex-direction: column;
      .fundraising-progress-bar {
        background-color: #012939;
        height: 30px;
        border-radius: 7px;
        position: relative;

        .progress-fill {
          height: 24px;
          background: transparent linear-gradient(75deg, #008599 0%, #4d56c7 84%, #734fd5 100%) 0%
            0% no-repeat padding-box;
          border-radius: 7px;
          margin: 3px;
          max-width: 175px;
        }

        .raised-amount {
          color: #ffffff;
          font-size: 12px;
          position: absolute;
          line-height: 30px;
          left: 8px;
          top: 0;
          font-weight: bold;
        }

        .goal-amount {
          color: #ffffff;
          font-size: 12px;
          position: absolute;
          line-height: 30px;
          right: 8px;
          top: 0;
          font-weight: bold;
        }
      }

      .percent {
        margin-top: 3px;
        font-size: 11px;
        color: #55646b;
        align-self: center;
      }
    }
  }
}
