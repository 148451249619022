@import '../../../styles/variables.scss';

.SpotlightCarousel {
  .slick-slider {
    .slick-next {
      right: 10px;
    }

    .slick-prev {
      left: 10px;
    }

    .slick-next,
    .slick-prev {
      z-index: 99;
    }
  }

  .Modal {
    .Modal-Content {
      padding: 15px 25px;
      min-height: 500px;
      width: 500px;

      .spotlight-donate-tile-container {
        .spotlight-item {
          display: flex;
          gap: 20px;
          align-items: center;
          border-bottom: 1px solid color(grey, lightest);
          padding: 10px;

          > div {
            flex: 1;

            &.portrait-container {
              max-width: 42px;

              &:hover {
                cursor: pointer;
              }
            }
            &.info-container {
              font-weight: 700;
              flex: 2;

              > p:hover {
                cursor: pointer;
                color: color(purple, primary);
              }

              @include truncate;
            }
          }

          &.selected {
            background-color: color(grey, lightester);
          }
        }
      }
    }
  }
}

@media (max-width: 670px) {
  .SpotlightCarousel {
    .Modal.SpotlightDonationMatchingModal {
      .ModalHeader {
        .close-button {
          color: #000;
        }
      }
      .Modal-Content {
        width: unset;
      }
    }
  }
}
