@import '../../../styles/variables.scss';

.MyActivity {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  justify-content: flex-start;
  @include thin-scroller-y;
}

@media (max-width: 1070px) {
  .MyActivity {
    overflow-y: hidden;
  }
}
