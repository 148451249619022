@import '../../../../styles/variables.scss';

.HubManagementBilling {
  padding: 60px;
  height: 100%;

  .HubPayment-Modal {
    .ModalHeader {
      .modal-close-icon {
        position: absolute;
        right: 20px;
        top: 20px;
        margin-top: 0;
        margin-right: 0;
      }
    }
  }

  .container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .header-actions {
      display: flex;
      align-items: center;

      .invite-button {
        margin-left: 15px;
      }
    }
  }

  .tab-selector {
    width: 100%;
    border-bottom: 1px solid #cad5d9;
    height: 60px;
    display: flex;
    flex-direction: row;

    .tab {
      padding: 20px 20px;
      cursor: pointer;

      &.selected {
        color: #5e51ab;
        border-bottom: 2px solid #5e51ab;
      }
    }
  }

  .tab-content {
    &.subscriptions {
      .edit-content-container {
        border-bottom: none;
        .section-subtitle {
          margin-bottom: 40px;
        }
      }
    }
    .section-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding-left: 20px;
      background: color(cream, grey2);
      color: color(grey, dark);
      margin-bottom: 38px;

      h3 {
        margin: unset;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
      }
    }
    .payment-action {
      margin-bottom: 60px;
    }

    .edit-content-container {
      &.collapsed {
        margin-bottom: 60px;
      }

      border-bottom: 1px solid color(cream, border);

      &:hover {
        cursor: pointer;
      }

      &.no-border {
        border: unset;
      }

      .section-subtitle {
        color: color(grey, dark);
        display: flex;
        flex-direction: column;
        padding-left: 54px;
        margin-bottom: 23px;
        margin-top: 23px;

        h4 {
          margin: 0 0 6px 0;
          font-weight: 600;
          font-size: 18px;
        }

        span {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    .subscription-cards {
      display: flex;
      flex-direction: row;
      padding: 0 54px;
      column-gap: 30px;
      .Button.sub-card-button {
        button {
          width: 100%;
        }
      }
      .sub-card {
        max-width: 360px;
        flex: 1 1;
        padding: 36px;
        border: 1px solid #a9aaac;
        border-radius: 10px;
        width: auto;
        .card-title {
          font-weight: 700;
          font-size: 21px;
          margin-bottom: 11px;
        }

        .card-subtitle {
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 30px;
        }

        .price {
          font-weight: 600;
          font-size: 48px;
          margin-bottom: 10px;
        }

        .price-sub {
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 30px;
        }

        .benefits {
          font-weight: 400;
          font-size: 14px;
          div {
            margin-bottom: 16px;
          }
          i {
            margin-right: 14px;
          }
        }
      }
    }
  }
}
