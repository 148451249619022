@import '../../../styles/variables.scss';

.ColorPickerInput {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 200px;

  label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .input {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: color(light, secondaryBg);
    border: 1px solid color(grey, border);
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    column-gap: 13px;
    padding-left: 15px;
    padding-right: 15px;

    &:hover {
      cursor: pointer;
    }

    .inner {
      display: flex;
      width: 100%;
      height: 28px;
      border: 1px solid color(grey, border);
      box-sizing: border-box;
      border-radius: 4px;
    }

    .icon-container {
      height: 100%;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      .fa-times {
        font-weight: 400;
        font-size: 22px;
        color: color(red, error);
      }
    }
  }

  .chrome-picker-container {
    display: none;
    position: absolute;
    top: 75px;
    max-width: 200px;
    z-index: 1;
    &.show {
      display: block;
    }
  }
}
