@import '../../styles/variables.scss';

.AuctionManagement {
  flex: 1;
  display: flex;

  .AuctionManagement-list {
    .SortableTable {
      overflow: auto;
      .table-header {
        .col {
          &.portrait {
            width: 40px;
            flex-basis: 40px;
          }

          &.is_selected {
            flex: 0 0 30px;
            div {
              border: 2px solid #bdcacf;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              cursor: pointer;
              box-sizing: border-box;

              &.selected {
                border-width: 7px;
                border-color: color(blue, light);
              }
            }
          }
        }
      }
    }

    .table-row:not(.table-header) {
      .col {
        &.auction_status {
          div {
            flex-wrap: wrap;
            border-radius: 24px;
            padding: 10px 20px;
            box-sizing: border-box;

            &.active,
            &.closed {
              background-color: #f3f4f9;
              color: #686c8b;
            }

            &.running {
              background-color: color(green, light);
              color: color(green, dark);
            }
          }
        }

        &.payment_status {
          div {
            flex-wrap: wrap;
            border-radius: 24px;
            padding: 10px 20px;
            box-sizing: border-box;
            pointer-events: none;

            &.awaiting_payment,
            &.awaiting_payment_buy_now,
            &.unpaid {
              background-color: #f3f4f9;
              color: #686c8b;
            }

            &.paid {
              background-color: color(green, light);
              color: color(green, dark);
            }

            &.failed {
              background-color: #fce9ea;
              color: #cf2035;
              cursor: pointer;
              pointer-events: all;
            }
          }
        }

        &.is_selected {
          flex: 0 0 30px;
          div {
            border: 2px solid color(gray);
            width: 30px;
            height: 30px;
            border-radius: 50%;
            cursor: pointer;
            box-sizing: border-box;

            &.selected {
              border-width: 7px;
              border-color: color(blue, light);
            }
          }
        }
      }
    }
  }

  .Modal.charge-error-modal {
    .content-container {
      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;

      padding: 20px 40px 40px 40px;

      .x-icon {
        font-size: 80px;
        color: #cf2035;
        margin-bottom: 30px;
      }

      .title,
      .description {
        font-size: 20px;
        margin-top: 15px;
        color: #4d5052;
      }
    }
  }

  .Modal.charge-cards-progress-modal {
    .content-container {
      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;

      padding-bottom: 20px;

      .please-wait {
        color: #7d8a90;
        margin-bottom: 5px;
      }

      .progress-bar {
        height: 15px;
        background-color: #eaeff0;
        border-radius: 7.5px;
        width: 200px;
        position: relative;
        margin-top: 10px;

        .progress-bar-fill {
          background-color: #0c9fb5;
          height: 100%;
          border-radius: 7.5px;
        }
      }
    }
  }

  .delete-auction-item {
    padding: 20px;
    flex: 1;

    p {
      padding: 40px 0px;
      margin: 0px;
      text-align: center;
    }

    .actions {
      display: flex;
      justify-content: center;

      .Button {
        &:first-child {
          margin-right: 10px;

          button {
            background-color: color(red, dark);
          }
        }

        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }

  .section-title {
    display: flex;

    .title {
      flex: 1;
    }

    .action {
      margin-right: 15px;
      .Button {
        button {
          box-shadow: $box-shadow;
        }
      }
    }
  }

  .AuctionManagement-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    .search {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .create {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }

      .TextField {
        max-width: 50%;

        i {
          background-color: color(light, secondaryBg);
          color: color(light, fontColor);
        }

        input {
          background-color: color(light, secondaryBg);
        }
      }
    }
  }
}
