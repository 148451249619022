@import '../../styles/variables.scss';

.CreditCard {
  flex: 1;

  .label {
    position: relative;
    padding-bottom: 4px;
    font-size: 13px;
    font-weight: 700;
  }

  .CreditCard-wrap {
    flex: 1;
    height: 42px;
    border: 1px solid color(grey, light);
    line-height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 0px 6px;
    border-radius: 7px;

    .StripeElement {
      flex: 1;
    }
  }
}
