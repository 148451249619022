@import '../../../styles/variables.scss';

@keyframes slideIn {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.ConversationTile {
  display: flex;
  align-items: center;
  padding: 8px;
  margin: 0 0;
  position: relative;
  width: 100%;
  flex: 1 0;
  justify-content: space-between;

  .convo-info-wrapper {
    display: flex;
    flex-direction: row;
  }

  .message-time {
    font-family: $raleway;
    align-self: flex-start;
    font-size: 10px;
    color: color(grey, light);

    span {
      position: absolute;
      top: 29px;
      right: 14px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: #eb645e;
      display: flex;
      justify-content: center;
      color: #fff;
      padding: 5px;
    }
  }

  .group-conversation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: color(grey, base);
    color: color(
      light,
      secondaryBg
    ); // should avoid this incase we want secondaryBg to not be white one day
    width: 40px;
    height: 40px;
    position: relative;
  }

  .group-conter-icon {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #f3f1ec;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
  }

  .group-conversation,
  .Portrait {
    margin-right: 15px;

    &.group-convo-users {
      margin-right: -15px;
    }
  }

  .convo-li {
    display: flex;
    font-size: 12px;
    flex-direction: column;
    overflow: hidden;
    width: 80%;
    &.expanded {
      margin-left: 15px;
      width: unset;
    }

    .convo-li-title {
      color: #3a3a3a;
      font-weight: 600;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .others {
        font-weight: 800;
      }
    }
    .convo-li-preview {
      max-height: 100px;
      color: color(grey, light);
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 5px;
    }
    .unread-noti {
      position: absolute;
      right: 0;
      margin-right: 10px;
      background: color(purple, dark);
      border-radius: 50%;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 10px;
    }
    .remove-message {
      position: absolute;
      display: none;
      right: 0;
      margin-right: 10px;
      background: color(grey, base);
      border-radius: 50%;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: color(grey, dark);
      font-size: 10px;
    }
  }

  &:hover {
    .remove-message {
      display: block;
    }
  }
}
