@import '../../styles/variables.scss';

.EmbedManagement {
  .section-inner {
    .settings-item {
      .details {
        p {
          margin-top: 20px;
        }

        .button-type {
          margin-top: 20px;
          max-width: 520px;
        }

        .button-custom {
          display: flex;
          margin: 20px 0px;

          .preview-wrap {
            flex: 1;
            margin-right: 20px;
            min-width: 300px;
            max-width: 300px;

            .preview {
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid color(grey, light);
              padding: 20px 40px;
              min-height: 100px;
              border-radius: 8px;
              flex: 1;

              .Button,
              .Button button {
                flex: 1;
                width: 100%;
              }
            }
          }

          .color-options {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-width: 200px;

            .color-option {
              display: flex;
              position: relative;

              .picker-wrap {
                position: absolute;
                bottom: 0px;
                left: 100%;
                padding-left: 5px;
              }

              &:last-child {
                margin-bottom: 0px;
              }

              .color-swatch {
                height: 39px;
                width: 39px;
                border-radius: 8px;
                border: 2px solid color(grey, light);
              }

              .color-label {
                flex: 1;

                .color-name {
                  font-size: 16px;
                  padding: 0px 0px 5px 0px;
                  margin: 0px;
                  font-weight: 700;
                }

                .color-hex {
                  font-size: 14px;
                }
              }
            }
          }
        }

        .embed-code {
          display: flex;
          margin-bottom: 20px;
          max-width: 520px;

          .TextField {
            .input-wrap {
              border-right: 0px;
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
            }
          }

          .Button {
            button {
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
              height: 44px;
            }
          }
        }
      }
    }
  }
}
