@import '../../../../../styles/variables.scss';
.SpotlightForm {
  display: flex;
  flex-direction: column;
  flex: 1;
  .spotlight-container,
  .entities-container {
    .spotlight-item {
      display: flex;
      gap: 20px;
      align-items: center;
      border-bottom: 1px solid color(grey, lightest);
      padding: 10px;

      &:hover {
        cursor: pointer;
        background-color: color(grey, lightester);
      }

      &.selected {
        background-color: color(grey, lightester);
      }
    }
  }
  .focus-areas-container {
    margin-left: auto;
  }

  .modal-main {
    flex: 1;
  }
}
