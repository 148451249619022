@import '../../../styles/variables.scss';
.FormPreview {
  opacity: 0.7;
  h4 {
    color: color(grey, lighter);
  }

  .row {
    margin: 8px 0;
  }

  .TextArea {
    width: 100%;

    label {
      margin: 24px 0;

      textarea {
        width: 100%;
        min-height: 140px;
        display: flex;
        border: 1px solid color(grey, light);
        border-radius: 7px;
        overflow: hidden;
      }

      textarea:not([value='']) + .placeholder {
        font-size: 13px;
        font-weight: 700;
        color: color(grey, lighter);
        opacity: 1;
        margin-left: 0;
      }
    }
  }

  .TextField,
  .Checkbox {
    label {
      span {
        color: color(grey, lighter);
      }
    }
    .input-wrap {
      input {
        &:disabled {
          background-color: #fff;
        }
      }
    }
  }

  .Dropdown {
    label {
      span {
        color: color(grey, lighter);
      }
    }
    .input-wrap {
      select {
        &:disabled {
          background-color: #fff;
        }
      }
    }
  }
}
