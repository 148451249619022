@import '../../../../../styles/variables.scss';

.PopupMenu-DonationMatchingForm-programType,
.PopupMenu-DonationMatchingForm-causesList,
.PopupMenu-DonationMatchingForm-ratio {
  max-width: 560px;
  width: 100%;
  max-height: 140px;
  overflow-y: auto;
  overflow-x: hidden;
  @include thin-scroller-y();
}

.DonationMatchingForm {
  height: 100%;
  min-width: 600px;
  padding: 0 40px 40px 40px;
  display: flex;
  flex-flow: column;
  gap: 30px;

  .selected-causes {
    .selected-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-top: 10px;

      .title {
        margin: 10px;
      }

      .close {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .desc {
    margin: 0;
    padding: 0 0 20px 0;
    font-family: $lato;
    font-style: normal;
    font-weight: 600;
    color: color(grey, text);
    font-size: 12px;
    border-bottom: 1px solid color(cream, grey);
  }

  label,
  label > span,
  .date-container > label {
    color: color(grey, dark);
    font-size: 14px;
    font-weight: 700;
  }

  .date-container {
    .no-drop {
      cursor: no-drop;

      input {
        cursor: no-drop;
      }
    }
  }

  .popupmenu-container {
    display: flex;
    flex-flow: column nowrap;

    .menu-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2px;
      padding: 0 16px;
      line-height: 44px;
      height: 44px;
      background: color(light, secondaryBg);
      border: 1px solid color(grey, border);
      border-radius: 10px;

      &.disabled {
        cursor: no-drop;

        &:hover {
          cursor: no-drop;
        }
      }

      .title {
        font-family: $lato;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .menu-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s ease-in-out;

        &.show {
          transform: rotate(180deg);
        }
      }

      &.show {
        color: color(purple, darker);
      }

      &:hover {
        color: color(purple, darker);
        cursor: pointer;
      }
    }
  }

  .form-row {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;

    .allowed-causes-container {
      margin-top: 8px;
      max-width: 600px;

      .allowed-causes-empty {
        cursor: pointer;
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        border-color: #d0d1d1;
        min-height: 42px;
        display: grid;
        align-items: center;
        color: color(hsl(0, 0, 50%));
        padding: 2px 8px;
      }

      .allowed-cause {
        display: inline-grid;
        align-items: center;
        flex-direction: row;
        background-color: color(grey, pale);
        border-radius: 8px;
        padding: 12px 20px;
        font-weight: 700;
        gap: 8px;

        .allowed-cause-name {
          font-family: $lato;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
        }

        i {
          padding-left: 8px;
          padding-top: 2px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .col {
      flex: 1;

      input {
        width: 100%;
      }
    }
  }
}
