@import '../../../styles/variables.scss';

@keyframes newDonation {
  0% {
    background-color: color(blue, lighter);
  }

  100% {
    background-color: #f5f6f7;
  }
}

.TeamStreamer {
  flex: 1;

  .mobile-donate {
    border-top: 1px solid #00000029;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 9999;
    width: 100%;
    background-color: #fff;
    height: 70px;
    justify-content: center;
    align-items: center;
    display: none;

    a {
      font-weight: 500;
      text-decoration: none;
      display: flex;
      border: 0px;
      font-size: 16px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      color: color(dark, fontColor);
      background-color: color(purple);
      overflow: hidden;
      min-height: 40px;
      box-shadow: $box-shadow;
      min-width: 140px;
      max-width: 300px;
      background-color: color(purple, dark);
      flex: 1;
      margin: 0px 20px;

      &.manage {
        background-color: color(purple, dark);
        margin-left: 10px;
      }

      &:hover {
        background-color: color(purple);
      }
    }
  }

  .Team-content {
    max-width: $wrap-size;
    margin: 0px auto;
    flex: 1;
    position: relative;

    .main,
    .progress-bar-wrap {
      background-color: #fff;
      padding: 20px;
      border-radius: 15px;
      box-shadow: $box-shadow;
    }

    .progress-bar-wrap {
      .progress-bar-bg {
        position: relative;
        border: 2px solid #416aae;
        background-color: #012939;
        border-radius: 10px;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0px 5px;

        .progress-bar {
          background: transparent linear-gradient(88deg, #008599 0%, #734fd5 100%) 0% 0% no-repeat
            padding-box;
          width: 0%;
          height: 30px;
          border-radius: 10px;
        }

        .amt,
        .goal {
          position: absolute;
          color: #fff;
          font-weight: 700;
          font-size: 19px;
        }

        .amt {
          top: 9px;
          left: 12px;
        }

        .goal {
          top: 9px;
          right: 12px;
        }
      }
    }

    .mobile-tabs {
      display: none;
      padding: 0px;
      margin: 0px;
      justify-content: center;
      align-items: center;
      list-style-type: none;
      height: 41px;
      box-shadow: $box-shadow;
      margin-top: 25px;
      border-top: #00000029;
      position: relative;

      li {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 41px;
        border-bottom: 4px solid #fff;
        padding-top: 4px;

        &:hover {
          cursor: pointer;
        }

        &.active {
          font-weight: 700;
          border-bottom: 4px solid color(purple, dark);
        }
      }
    }

    .main {
      margin-top: 20px;
      min-height: 50vh;
      display: flex;

      .title {
        font-size: 24px;
        font-weight: 700;
        padding: 0px;
        margin: 20px 0px 10px;

        &:first-child {
          margin-top: 0px;
        }
      }

      .details {
        .about,
        .charity,
        .members {
          margin: 40px 40px 0px 0px;

          p {
            font-size: 16px;
            color: #484b4d;
            line-height: 26px;
          }
        }

        .members {
          ul {
            list-style-type: none;
            padding: 0px;
            margin: 0px;
            display: flex;
            flex-wrap: wrap;

            li {
              margin-right: 40px;
              margin-top: 10px;

              &.live {
                .member {
                  &:hover {
                    cursor: pointer;

                    .displayName {
                      color: color(purple, dark);
                    }
                  }
                }
              }

              .member {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                border-radius: 5px;

                .live-tag {
                  display: flex;
                  flex-flow: row nowrap;
                  align-items: center;
                  padding: 2px;
                  width: 47px;
                  height: 20px;
                  border-radius: 15px;
                  background-color: color(grey, lighter);
                  position: absolute;
                  right: -15px;
                  top: 0;

                  .live-dot {
                    margin-right: auto;
                    width: 15px;
                    height: 15px;
                    background-color: #eb3224;
                    border-radius: 50%;
                  }

                  .live-text {
                    font-weight: 500;
                    font-size: 12px;
                    margin-right: 3px;
                  }
                }

                &:hover {
                  cursor: pointer;
                }

                .displayName {
                  font-weight: 700;
                  font-size: 16px;
                  margin-top: 15px;
                }

                .raised {
                  color: #829096;
                  font-size: 15px;
                }
              }
            }
          }
        }

        .about {
          .title {
            display: flex;
            flex: 1;
            align-items: center;

            i {
              padding-left: 10px;
              font-size: 16px;

              &:hover {
                color: color(purple, dark);
                cursor: pointer;
              }
            }
          }

          .ql-bubble {
            .ql-editor {
              padding: 0px;
            }
          }

          p {
            font-size: 16px;
            color: #484b4d;
            line-height: 26px;
          }
        }

        .details-inner {
          .detail-items {
            .detail {
              display: flex;

              .icon {
                background-color: #d4f1f5;
                color: color(purple, dark);
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
              }

              span {
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 1px;
              }

              .detail-val {
                font-size: 16px;
                a {
                  text-decoration: none;
                  color: #484b4d;

                  &:hover {
                    color: color(purple, dark);
                  }
                }
              }
            }
          }
        }
      }

      .donations {
        max-width: 350px;
        min-width: 350px;

        ul {
          list-style-type: none;
          padding: 0px;
          margin: 0px;

          .empty {
            font-size: 16px;
            color: #484b4d;
            line-height: 26px;
          }

          .donation {
            background-color: #f5f6f7;
            border-radius: 10px;
            padding: 20px;
            margin-bottom: 20px;
            min-height: 52px;

            &.new {
              animation-name: newDonation;
              animation-duration: 500ms;
            }

            .info {
              display: flex;
              flex: 1;

              .portrait {
                padding-right: 15px;

                .Portrait {
                  .inner {
                    background-color: #b6c5cb;
                    border: 2px solid #ffffff;

                    .profile {
                      background-color: #ffffff;
                      opacity: 1;
                    }
                  }
                }
              }

              .name {
                flex: 1;
                font-size: 14px;

                span {
                  font-weight: 700;
                  margin-right: 4px;
                }
              }

              .amt {
                font-size: 19px;
              }

              .name,
              .amt {
                color: #484b4d;
              }
            }

            .msg {
              color: color(light, fontColor);
              font-size: 14px;
              line-height: 21px;
              padding-left: 67px;
              padding-right: 20px;
              margin-top: -20px;
              font-weight: 500;
            }
          }
        }
      }

      .details {
        flex: 1;
      }
    }
  }
}

@media (max-width: 1240px) {
  .event {
    .Team {
      background-color: #fff;

      .back-splash {
        .curve {
          background-color: #fff;
        }
      }

      &.content-creator {
        .stream-embed {
          border-radius: 0px;
          margin-top: 20px;
        }

        .sub-header {
          margin-top: 120px;
          position: relative;
          height: auto;
          top: 0px;

          &.fixed {
            position: relative;
            top: 0px;
            background-color: transparent;
            box-shadow: none;
          }

          .sub-header-inner {
            max-width: 100%;
            display: flex;
            flex-direction: column;
            background-color: transparent;
            box-shadow: none;

            .row {
              &.right,
              &.left {
                padding: 0px;
              }

              &.right {
                position: absolute;
                top: -100px;
                right: 20px;

                .text-buttons {
                  display: none;
                }

                .Button.share button {
                  background-color: #fff;
                }
              }

              &.left {
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .streamer-details {
                  justify-content: center;
                  align-items: center;
                  margin-top: 10px;
                  padding: 0px;

                  h1 {
                    margin-top: 5px;
                  }
                }
              }
            }
          }
        }

        .TeamStreamer {
          .mobile-donate {
            display: flex;
          }

          .Team-content {
            .mobile-tabs {
              display: flex;
            }

            .main {
              margin-top: 0px;
              box-shadow: none;
              border-top: 1px solid #00000029;
              border-radius: 0px;
              flex-direction: column;

              .about {
                margin-right: 0px;
              }

              .details-inner {
                .title {
                  display: none;
                }
              }

              .donations {
                min-width: 0px;
                max-width: 100%;
                margin-top: 20px;

                .title {
                  display: none;
                }
              }
            }
          }

          .progress-bar-wrap {
            box-shadow: none;
            padding: 0px 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .event {
    .Team {
      .back-splash {
        height: 220px;
      }
    }
  }
}

@media (max-width: 600px) {
  .event {
    .Team {
      .back-splash {
        height: 200px;
      }
    }
  }
}

@media (max-width: 500px) {
  .event {
    .Team {
      &.content-creator {
        .TeamStreamer {
          margin-bottom: 70px;

          .mobile-donate {
            bottom: 71px;
          }
        }
      }
    }
  }
}
