@import '../../../../../styles/variables.scss';

.DatePickerFieldContainer {
  display: flex;
  // flex: 1;
  flex-direction: column;
}

.DatePickerDialogPortal {
  display: flex;
  background-color: white;
  border: #d0d1d1 1px solid;
  border-radius: 8px;
  box-shadow: $box-shadow;

  .date-picker-selected {
    background-color: #2f2956;
    color: white;
  }
}
