@import '../../styles/variables.scss';
.CreateAGroup {
  display: flex;
  flex: 1;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  overflow-y: auto;

  .form-page {
    .more-info {
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 1000px) {
  .CreateAGroup {
    flex-direction: column;
    height: 100vh;
    overflow: hidden;

    .FormSection {
      min-height: unset;
      padding-bottom: 90px;

      .form-page {
        max-height: calc(100vh - 340px);

        #scrollableDiv {
          max-height: unset;
          overflow-y: unset;

          .filter-wrapper {
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .CreateAGroup {
    .InfoSection {
      .claim-charities-info {
        padding: 0;
      }
    }
  }
}
