@import '../../styles/variables.scss';

.CreateProfile {
  flex: 1;
  margin-top: -70px;
  justify-content: center;
  background-color: color(light, secondaryBg);

  .CreateProfile-hero {
    height: 80vh;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    position: relative;
    padding-bottom: 80px;
    background-image: url('../../assets/teamwork_2.png');
    background-attachment: fixed;
    background-position: center center;

    &:before {
      content: ' ';
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      opacity: 0.75;
      position: absolute;
      background: transparent linear-gradient(180deg, #008599 0%, #5c4dd0 100%) 0% 0% no-repeat
        padding-box;
    }

    h1 {
      padding: 0px 0px 30px 0px;
      margin: 0px;
      text-align: center;
      font-size: 38px;
    }

    form {
      position: relative;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
      border-radius: $border-radius;
      padding: 40px;
      width: 480px;
      background-color: color(light, secondaryBg);
      color: color(light, fontColor);

      .TextField,
      .Dropdown {
        .input-wrap {
          border-radius: $border-radius;
        }
      }

      .row {
        padding-bottom: 25px;
      }

      .actions {
        display: flex;
        flex: 1;

        .Button,
        button {
          display: flex;
          flex: 1;
        }
      }
    }

    .curve {
      background-color: color(light, secondaryBg);
    }
  }

  .metrics {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateY(-5px);
    padding-bottom: 80px;
    max-width: $wrap-size;
    margin: 0px auto;
    text-align: center;
    border-bottom: 2px solid color(light, primaryBg);

    h2 {
      font-size: 34px;
      font-weight: 700;
      padding: 0px 150px 30px 150px;
      margin: 0px;
      line-height: 48px;
    }

    p {
      font-size: 24px;
      font-weight: 300;
      margin: 0px;
      padding: 0px;
    }
  }

  .why {
    max-width: $wrap-size;
    margin: 0px auto;
    padding: 60px 0px;

    &.small {
      .why-inner {
        padding: 20px 0px;
      }
    }

    &.large {
      .why-inner {
        padding: 40px 0px;

        .half {
          .circle {
            max-height: 150px;
            min-height: 150px;
            max-width: 150px;
            min-width: 150px;
          }
        }
      }
    }

    h3 {
      text-align: center;
      font-size: 34px;
      font-weight: 400;
      color: color(purple, dark);
      padding: 0px 0px 60px 0px;
      margin: 0px;
    }

    .why-actions {
      padding-top: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .why-inner {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-bottom: 60px;

      .half {
        display: flex;
        flex: 1;

        &:first-child {
          padding-right: 80px;
        }

        &:last-child {
          padding-left: 80px;
        }

        .title {
          font-size: 26px;
          font-weight: 700;
        }

        p {
          padding: 15px 0px 0px;
          margin: 0px;
          line-height: 31px;
          font-weight: 300;
          color: color(grey);
        }

        .circle {
          max-height: 60px;
          min-height: 60px;
          max-width: 60px;
          min-width: 60px;
          border-radius: 50%;
          background-color: color(light, primaryBg);
          margin-right: 20px;
        }
      }
    }
  }

  .banner {
    img {
      width: 100%;
      height: autp;
    }
  }

  .community {
    border-top: 2px solid color(light, primaryBg);
    max-width: $wrap-size;
    margin: 0px auto;
    padding: 100px 0px;

    .community-inner {
      max-width: $wrap-size;
      display: flex;
      margin: 0px auto;
      border-radius: $border-radius;
      overflow: hidden;
      background-color: color(grey, lighter);

      .community-image {
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .community-image,
      .community-content {
        width: 50%;
      }

      .community-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3,
        p {
          padding: 0px 70px;
          margin: 0px;
        }

        h3 {
          color: color(purple, dark);
          font-weight: 500;
          margin: 0px 0px 20px 0px;
          font-size: 38px;
        }

        p {
          font-size: 16px;
          line-height: 28px;
          font-weight: 300;
        }
      }
    }
  }

  .footer-cta {
    background-color: color(grey, lighter);
    padding: 100px 0px;

    .footer-cta-inner {
      max-width: $wrap-size;
      margin: 0px auto;
      text-align: center;

      h3 {
        color: color(light, fontColor);
        margin: 0px;
        padding: 0px 0px 40px 0px;
        font-size: 28px;
      }

      .Button button {
        background-color: color(purple, dark);
        color: color(dark, fontColor);
        margin: 0px auto;

        &:hover {
          background-color: color(purple, darker);
        }
      }
    }
  }
}
