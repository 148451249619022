@import '../../../styles/variables.scss';

.ContactSupport {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  padding: 0px 40px 40px;
  max-height: 850px;
  overflow: auto;

  .row-details {
    display: flex;
    justify-content: space-between;
    margin: 12px 0px;
    .detail-section {
      background-color: #f5f6f7;
      flex: 1;
      border-radius: 12px;
      margin-right: 12px;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;

      &:first-of-type {
        flex: 2;
      }

      .title {
        font-weight: 700;
        padding: 20px 0px;
        border-bottom: 1px solid color(grey, light);
        margin: 0px 20px;
      }

      .phone,
      .email {
        padding: 10px 20px 0px;
        font-size: 14px;
        color: color(grey);
        line-height: 21px;
      }

      .address {
        padding: 10px 20px;
        font-size: 14px;
        color: color(grey);
        line-height: 21px;
      }

      &:last-child {
        margin-right: 0px;
      }

      .detail-items {
        height: 100%;
        overflow: auto;

        .item {
          display: flex;
          align-items: flex-start;
          flex-direction: row;
          justify-content: space-between;
          background: #fff;
          padding: 20px;
          margin: 15px;
          border-radius: 8px;

          .actions,
          .item-qty,
          .item-price,
          .portrait-container,
          .item-name {
            flex: 2;
            margin: 0 10px;
            div:first-of-type {
              font-size: 14px;
              margin-bottom: 2px;
              color: color(grey, base);

              &:last-of-type {
                font-size: 16px;
              }
            }
          }

          .item-qty {
            text-align: center;
          }

          .actions {
            flex: 1;
            position: relative;
            cursor: pointer;

            .ContextMenu {
              display: none;
              position: absolute;
              top: 55px;
              right: 10px;
              box-shadow: $box-shadow;
              border-radius: 20px;

              li {
                cursor: pointer;

                &.default-role {
                  display: none;
                }
              }

              i {
                font-size: 16px;
                padding-right: 8px;
              }

              &:hover {
                display: block;
              }
            }

            i.fa-ellipsis-h-alt {
              font-size: 24px;
              height: 40px;
              width: 40px;
              background-color: color(blue, lightest);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;

              &:hover {
                cursor: pointer;
                color: color(purple, dark);
              }
            }

            i.fa-ellipsis-h-alt:hover + .ContextMenu {
              display: block;
            }
          }
        }
      }
    }
  }

  .Modal {
    &.activity-log {
      .content {
        background: #f5f6f7;
        height: 500px;
        max-width: 800px;
      }
    }

    &.refund-modal {
      .content {
        max-width: 500px;
      }
      .refund {
        padding: 15px;

        .refund-info {
          .sub-header {
            padding-bottom: 10px;
            border-bottom: 1px solid #e6e6e6;
            color: color(purple, dark);
            margin-top: 25px;
            font-size: 18px;
          }

          .info {
            .info-part {
              margin: 15px 0;
              span {
                font-weight: 700;
              }
            }
          }

          .refund-details {
            margin-top: 15px;
            display: flex;
            flex-direction: row;

            .TextField {
              flex: 2;
              margin-right: 8px;
            }

            .Dropdown {
              flex: 1;
            }
          }

          .refund-actions {
            display: flex;
            justify-content: flex-end;
            margin: 10px 0;
          }
        }
      }
    }

    .purchase-item-form {
      padding: 40px;
      width: 100%;
      overflow-y: auto;

      .Portrait {
        margin-right: 20px;
      }

      .question {
        margin-bottom: 40px;

        .answer {
          width: 100%;
        }

        .label {
          padding-bottom: 20px;
          font-size: 19px;
          font-weight: 700;
        }

        .Checkbox label {
          justify-content: flex-start;
          margin-bottom: 20px;

          input {
            margin: 0px;
          }
        }
      }
    }
  }

  .purchase-modal {
    .content {
      background-color: transparent;
      display: flex;
      flex: 1;

      .Checkout {
        background-color: transparent;

        .Checkout-inner {
          margin-top: 0px;

          .cart-details,
          .checkout-details,
          .admin-store {
            min-width: 680px;
            max-height: 60vh;
            overflow: auto;
          }

          .cart-details,
          .checkout-details {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            box-shadow: none;
          }
        }
      }
    }
  }
}
