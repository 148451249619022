@import '../../../styles/variables.scss';

@mixin bannerType($type, $color, $backgroundColor) {
  &.#{$type} {
    background-color: $backgroundColor;

    .icon {
      margin-top: 3px;
      color: $color;
    }

    .action-button {
      background-color: $color;
    }
  }
}

.Banner {
  display: flex;
  flex-direction: row;
  height: 60px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  align-items: center;
  padding: 0 30px;

  .icon {
    margin-right: 12px;
    margin-top: 4px;
  }

  @include bannerType('success', #57c0ac, #f7fcfb);
  @include bannerType('warning', #f1b94d, #fefcf6);
  @include bannerType('failure', #eb645e, #fef7f7);
  @include bannerType('info', #5e96eb, #f7fafe);

  .title {
    font-weight: bold;
    white-space: nowrap;
    min-width: 140px;
  }

  .content {
    margin-left: 10px;
  }

  .action-button {
    margin-left: auto;
    color: white;
    height: 35px;
    padding: 0 20px;
    border-radius: 22px;
    white-space: nowrap;
    border-color: transparent;

    cursor: pointer;
  }
}

.banner-view {
  .Banner {
    cursor: default;
    padding: 0 15px;
  }

  .content {
    font-size: 14px;
    margin: 12px 15px 12px 16px;
  }

  .icon {
    margin-right: 9px;
  }

  .action-button {
    padding: 4px 18px;
    white-space: nowrap;
    pointer-events: all;
  }
}

@media (max-width: 1100px) {
  .banner-view {
    .content {
      font-size: 12.5px;
      height: 50px;
      display: flex;
      overflow: hidden;
      justify-content: center;
      flex-flow: column wrap;
      margin: 14px 15px 14px 16px;
    }
  }
}
